import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetMedicalQuestionCategoryResponse, MedicalQuestionCategoriesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalQuestionCategoriesApiClient, ModalService)
export class PartialMedicalQuestionCategories extends BasePartialView {
    public categories: GetMedicalQuestionCategoryResponse[] = [];

    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly categoriesApi: MedicalQuestionCategoriesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [_, __] = await Promise.all([
                    this.loadViewsFromUrl({
                        open: async (view: string, id: string) => {
                            if (isNotDefined(view)) return;

                            if (view.includes('Edit') && isNotEmpty(id)) this.edit(new GetMedicalQuestionCategoryResponse({ id }), false);
                            else if (view.includes('Create')) this.create(false);
                        }
                    }),
                    this.getData(false, true)
                ]);
                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalQuestionCategories.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async create(updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.CreateMedicalQuestionCategory.whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok) {
                    await this.getData(true);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl
            })
        });
    }

    public async edit(category: GetMedicalQuestionCategoryResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.EditMedicalQuestionCategory.with({ id: category.id }).whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                    await this.getData(true);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl
            })
        });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true, false, 250);

        element.isLoading = false;
    }

    public async delete(category: GetMedicalQuestionCategoryResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-question-categories.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-question-categories.questions.delete.message') //
                    .replace('{entity}', `<span>'${category.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.categoriesApi.delete(category.id, this.authenticated.workspace.id);
                            await this.getData();
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-question-categories.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-question-categories.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${category.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            await this.errorHandler.handle('[delete-medical-question-categories]', e);
                        }
                    }
                }
            })
        );
    }

    private async getData(reset: boolean = false, initial: boolean = false, timeout: number = 0): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (reset) {
                this.skip = 0;
                this.categories = [];
                this.endOfList = false;
                if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
            }

            this.isLoading = true;
            setTimeout(async () => {
                try {
                    const response = await this.categoriesApi.search(this.authenticated.workspace.id, isNotEmpty(this.query) ? this.query : undefined, this.pageSize, this.skip);

                    if (!reset && response.data.empty() && !initial) {
                        this.endOfList = true;
                        this.isLoading = false;
                        resolve();
                        return;
                    }

                    this.skip += Number(this.pageSize);
                    this.categories = [...this.categories, ...response.data];
                    this.isLoading = false;
                    resolve();
                } catch (e) {
                    reject(e);
                }
            }, timeout);
        });
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
