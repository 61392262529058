<div class="flex relative color-picker-${guid}">
    <button
        click.trigger="toggleVisibility()"
        class="focus:outline-none gap-3 flex-1 flex justify-between group items-center hover:bg-gray-50 active:bg-gray-100 border-gray-300 text-sm border rounded-lg px-3 py-2">
        <div class="flex gap-3 items-center">
            <div class="h-5 w-5 min-w-[1.25rem] flex-initial rounded-full border-gray-200 border" css="background-color: ${value}"></div>
            <input class="w-full focus:outline-none group-hover:bg-gray-50 group-active:bg-gray-100 h-full" type="text" value.bind="value" />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    </button>
    <div if.bind="isOpen" class="flex flex-col absolute border-gray-300 z-50 top-[45px] bg-white rounded-lg border w-60 min-h-[200px] shadow-lg">
        <div class="p-3 flex-1 rounded-t-lg">
            <div show.bind="view === 'palette' && showPalette" class="grid grid-cols-12 gap-2 flex-1">
                <button
                    repeat.for="color of palette"
                    class="col-span-2 h-7 w-7 rounded-lg focus:outline-none border border-white"
                    css="background-color: ${color}"
                    click.trigger="setColor(color)"
                    !border-gray-600.class="value === color"></button>
            </div>
            <div show.bind="view === 'picker'" ref="picker" class="flex justify-center flex-1"></div>
        </div>
        <div if.bind="showPalette" class="flex border-t border-gray-300 text-sm">
            <button bg-gray-100.class="view === 'palette'" click.trigger="changeView('palette')" class="flex-1 py-2 rounded-bl-lg border-r border-x-gray-300 hover:bg-gray-50 active:bg-gray-100">
                <span t="translation:ux.color-picker.buttons.palette"></span>
            </button>
            <button bg-gray-100.class="view === 'picker'" click.trigger="changeView('picker')" class="flex-1 py-2 hover:bg-gray-50 rounded-br-lg active:bg-gray-100">
                <span t="translation:ux.color-picker.buttons.picker"></span>
            </button>
        </div>
    </div>
</div>
