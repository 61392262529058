<import from="converters/translate" />

<div class="${columns} flex flex-col">
    <div class="flex items-center justify-between rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-800">
        <div class="flex min-w-0 flex-1 items-center justify-between">
            <div class="flex min-w-0 items-center gap-x-3">
                <span class="text-gray-500" t="translation:global.labels.step"></span>
                <span class="block truncate">${ item.step.question | translate:language }</span>
            </div>
            <div class="flex w-32 justify-end">
                <template if.bind="itemResult.hasResult">
                    <span class="text-xs text-red-700" if.bind="itemResult.hasPositiveResult" t="translation:global.labels.positive"></span>
                    <span class="text-xs text-green-700" else t="translation:global.labels.negative"></span>
                </template>
                <span else class="text-xs text-gray-800" else t="translation:global.labels.not-filled-in"></span>
            </div>
        </div>
    </div>
</div>
