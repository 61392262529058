<import from="converters/translate" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between mb-3">
                <p class="text-sm font-medium" t="translation:partial-views.manage-users.labels.title"></p>
                <ux-button click.trigger="invite()" padding.bind="false" size="xs">
                    <div class="flex p-1">
                        <svg class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </ux-button>
            </div>
            <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full px-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <ul class="flex flex-col flex-1">
                    <li if.bind="invitations.length > 0" class="pt-3 pb-2">
                        <p class="font-medium text-gray-500 uppercase text-xxs" t="translation:global.labels.invitations"></p>
                    </li>
                    <li repeat.for="invitation of invitations" class="group">
                        <a
                            click.trigger="edit(invitation, 'invitation')"
                            href="javascript:"
                            border-b.class="!$last"
                            pt-3.class="!$first || invitations.length === 0"
                            class="flex items-center justify-between pb-3 border-gray-200">
                            <div class="flex items-center gap-x-2">
                                <ux-avatar
                                    size="xs"
                                    email.bind="invitation.email"
                                    name.bind="invitation.createdFor.name"
                                    color.bind="invitation.createdFor.data.avatarColor"
                                    shade.bind="invitation.createdFor.data.avatarShade">
                                </ux-avatar>
                                <div class="flex flex-col">
                                    <p if.bind="invitation.createdFor" class="text-sm font-semibold leading-tight group-hover:text-gray-600">${ invitation.createdFor?.name }</p>
                                    <span class="text-xs leading-tight text-gray-500">${invitation.email}</span>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 size-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-500 group-hover:text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </div>
                        </a>
                    </li>
                    <li if.bind="invitations.length > 0" class="pt-3 pb-2 mt-2">
                        <p class="font-medium text-gray-500 uppercase text-xxs" t="translation:global.labels.users"></p>
                    </li>
                    <li repeat.for="user of users" class="group">
                        <a
                            click.trigger="edit(user, 'user')"
                            href="javascript:"
                            border-b.class="!$last"
                            pt-3.class="!$first || invitations.length === 0"
                            class="flex items-center justify-between w-full gap-3 pb-3 border-gray-200">
                            <div class="flex items-center flex-1 gap-x-2">
                                <ux-avatar size="xs" email.bind="user.email" name.bind="user.displayName" color.bind="user.avatar.color" shade.bind="user.avatar.shade"></ux-avatar>
                                <div class="flex flex-col flex-1">
                                    <p class="text-sm font-semibold leading-tight group-hover:text-gray-600">${ user.displayName }</p>
                                    <span class="text-xs leading-tight text-gray-500">${user.email}</span>
                                </div>
                                <div class="flex flex-col items-center justify-center w-20">
                                    <ux-badge if.bind="checkRole(user, UserRoles.Admin) && !checkRole(user, UserRoles.Owner)" type="warning">
                                        <span class="text-xxs" t="translation:global.labels.admin"></span>
                                    </ux-badge>
                                    <ux-badge if.bind="checkRole(user, UserRoles.Owner)" type="success">
                                        <span class="text-xxs" t="translation:global.labels.owner"></span>
                                    </ux-badge>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-500 group-hover:text-black">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-5 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
