<import from="converters/date" />
<import from="converters/new-line-to-break" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <ux-header padding="py-0 px-8">
                <div class="flex h-[58px] items-center justify-between">
                    <p class="text-sm font-medium" t="translation:partial-views.clinical-pathways.labels.prosoftware-report"></p>
                </div>
            </ux-header>
            <template if.bind="baseLoaded">
                <div class="flex flex-1 flex-col gap-3 overflow-y-auto px-8 pb-7 pt-5">
                    <div class="flex flex-col">
                        <ux-label class="mb-1">
                            <span t="translation:global.labels.created-at"></span>
                        </ux-label>
                        <div class="flex h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 px-3 py-2 text-sm">${report.createdAt | date}</div>
                    </div>
                    <div class="flex flex-col">
                        <ux-label class="mb-1">
                            <span t="translation:global.labels.created-by"></span>
                        </ux-label>
                        <div class="flex h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 px-3 py-2 text-sm">${report.practitioner ?? '-'}</div>
                    </div>
                    <div class="flex flex-1 flex-col">
                        <ux-label class="mb-1">
                            <span t="translation:global.labels.report"></span>
                        </ux-label>
                        <div py-3.class="report.value" py-1.class="!report.value" class="flex-1 rounded-lg border border-gray-300 px-4 text-sm" innerhtml.bind="report.value | newLineToBreak"></div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-1 items-center justify-center px-8">
                    <div class="grid max-w-lg flex-1 grid-cols-1 gap-5">
                        <div class="flex flex-1 flex-col space-y-3">
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </template>
            <ux-footer>
                <div class="flex flex-1 justify-end">
                    <ux-button click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
