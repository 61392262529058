<import from="converters/date" />
<import from="converters/time-ago" />
<import from="converters/declaration-status-to-string" />
<import from="converters/declaration-status-to-color" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col gap-x-2 overflow-hidden" css.bind="styles">
        <div ref="scrollContainer" class="flex h-full flex-1 flex-col gap-1 overflow-y-auto scroll-smooth px-6 pb-6">
            <div class="sticky top-0 z-10 border-b border-gray-200 bg-white pb-3 pt-6">
                <div class="mb-3 flex items-center justify-between">
                    <p class="text-sm font-medium" t="translation:partial-views.declarations.logs.title"></p>
                    <ux-button click.trigger="check()" disabled.bind="isChecking" mode="outline" loading.bind="isChecking" color="dark">
                        <span t="translation:partial-views.declarations.buttons.status-check"></span>
                    </ux-button>
                </div>
                <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>
            </div>
            <template if.bind="baseLoaded">
                <ul role="list" class="mt-4 space-y-3">
                    <li repeat.for="log of logs" class="relative flex gap-x-6">
                        <div if.bind="!$last" class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
                            <div class="w-px bg-gray-200"></div>
                        </div>
                        <div if.bind="log.oldStatus !== log.newStatus" class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 text-yellow-400">
                                <path
                                    fill-rule="evenodd"
                                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div else class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                        </div>
                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                            <span class="font-medium text-gray-900">${log.createdBy.name}</span>
                            <span t="translation:partial-views.declarations.messages.checked-1"></span>
                            <a href="javascript:" click.trigger="edit(log.declaration)" class="font-medium text-primary underline">${log.declaration.name}</a>
                            <span t="translation:partial-views.declarations.messages.checked-2"></span>.
                            <template if.bind="log.oldStatus === log.newStatus"> <span t="translation:partial-views.declarations.messages.status-nochange"></span>. </template>
                            <template else>
                                <span t="translation:partial-views.declarations.messages.status-change"></span>
                                <span class="font-medium text-gray-800">${log.oldStatus | declarationStatusToString | t }</span>
                                <span t="translation:partial-views.declarations.messages.status-to">${log.newStatus | declarationStatusToString | t }</span>
                                <span class="font-medium text-gray-800">${log.newStatus | declarationStatusToString | t }</span>
                            </template>
                        </p>
                        <ux-tooltip content="${log.createdAt | date:'dd-MM-yyyy HH:mm'}">
                            <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${log.createdAt | timeAgo:false:false:true}</time>
                        </ux-tooltip>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="logs.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-1 flex-col gap-y-2 pt-4">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex w-full flex-col space-y-5 py-1">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <ux-footer>
            <div justify-between.class="logs.length > 0" justify-end.class="logs.length === 0" class="flex flex-1 gap-x-2">
                <ux-button if.bind="logs.length > 0" color="danger" disabled.bind="isLoading || isChecking" click.trigger="clear()">
                    <span class="pointer-events-none" t="translation:partial-views.declarations.buttons.clear"></span>
                </ux-button>
                <ux-button color="primary" disabled.bind="isLoading || isChecking" click.trigger="close()">
                    <span class="pointer-events-none" t="global.buttons.close"></span>
                </ux-button>
            </div>
        </ux-footer>
    </section>
</template>
