<import from="converters/delta-to-html" />

<template if.bind="baseLoaded">
    <div class="flex flex-1 w-full pl-8 pr-5 pb-7 pt-7">
        <div class="flex flex-col">
            <div class="flex-1 w-2 bg-primary"></div>
            <div class="w-2 flex-1 bg-[#46ADF9]"></div>
            <div class="w-2 flex-1 bg-[#F4C237]"></div>
            <div class="w-2 flex-1 bg-[#F25366]"></div>
        </div>
        <div class="flex flex-1 min-w-full pl-10 prose prose-headings:my-0 prose-p:my-0 prose-img:my-0 prose-video:my-0">
            <template if.bind="noContent">
                <div class="flex items-center justify-center flex-1">
                    <span t="translation:partial-views.information-sheet.messages.no-content-available"></span>
                </div>
            </template>
            <template else>
                <div class="flex-1" innerhtml.bind="content | deltaToHtml"></div>
            </template>
        </div>
    </div>
</template>
<div else class="flex flex-1 pt-10">
    <div class="flex flex-col flex-1 space-y-3">
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
            </div>
        </ux-skeleton>
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
            </div>
        </ux-skeleton>
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
            </div>
        </ux-skeleton>
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
            </div>
        </ux-skeleton>
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
            </div>
        </ux-skeleton>
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
            </div>
        </ux-skeleton>
        <ux-skeleton show-background="true" type="container">
            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
            </div>
        </ux-skeleton>
    </div>
</div>
