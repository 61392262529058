import { HealthcareCodeTypes } from '@wecore/sdk-healthcare';

export class HealthcareCodeTypeToStringValueConverter {
    public toView(type: HealthcareCodeTypes): string {
        switch (type) {
            case HealthcareCodeTypes.Independent:
                return 'translation:global.labels.independent';
            case HealthcareCodeTypes.Underlying:
                return 'translation:global.labels.underlying';
            case HealthcareCodeTypes.Set:
                return 'translation:global.labels.set';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
