import { BlobStorageAttachment, MedicalReferral, MedicalReferralSystems, MedicalReferralTypes } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';
import { SelectedFile } from '../../../../../../models/selected-file';

@containerless
export class WidgetPatientIntakeDropzone {
    @bindable() public referral: MedicalReferral;
    @bindable() public validation: any;
    @bindable() public upload: SelectedFile;
    @bindable() public attachment: BlobStorageAttachment;
    @bindable() public onFilesSelected: (files: File[]) => void;
    @bindable() public onFileRemoved: () => void;

    public MedicalReferralTypes: typeof MedicalReferralTypes = MedicalReferralTypes;
    public MedicalReferralSystems: typeof MedicalReferralSystems = MedicalReferralSystems;

    public handleFilesSelected = (files: File[]): void => {
        this.onFilesSelected(files);
    };

    public handleFileRemoved(): void {
        this.onFileRemoved();
    }
}
