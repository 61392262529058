import { MedicalQuestionScoreResultRange, ResultTypes } from '@wecore/sdk-healthcare';
import { isFunction, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { setTranslation } from '../../infra/utilities';

@containerless
export class ComponentResultRangeSelector {
    @bindable() public results: MedicalQuestionScoreResultRange[];
    @bindable() public validation: any[];
    @bindable() public language: string;
    @bindable() public manageTranslationsFor: (property: string, required: boolean, index: number) => void;

    public ResultTypes: typeof ResultTypes = ResultTypes;

    public attached(): void {
        if (isNotDefined(this.results)) this.results = [];
        if (this.results.empty()) this.add();
    }

    public add(): void {
        this.results.push(
            new MedicalQuestionScoreResultRange({
                description: setTranslation({}, this.language)
            })
        );

        this.validation.push({
            start: true,
            end: true,
            description: true
        });
    }

    public remove(index: number): void {
        this.results.splice(index, 1);
        this.validation.splice(index, 1);

        if (this.results.empty()) this.add();
    }

    public manageTranslations(index: number): void {
        if (isFunction(this.manageTranslationsFor)) this.manageTranslationsFor('description', true, index);
    }
}
