import { I18N } from '@aurelia/i18n';
import iro from '@jaames/iro';
import { guid, isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';

@containerless
@inject(I18N)
export class UxColorPicker {
    @bindable() public value: string;
    @bindable() public showPalette: boolean = true;

    public guid: string = guid();
    public picker: HTMLDivElement;
    public view: 'palette' | 'picker' = 'palette';

    public palette: string[] = [
        // 200
        '#fecaca',
        '#fed7aa',
        '#fde68a',
        '#fef08a',
        '#d9f99d',
        '#bbf7d0',
        '#a7f3d0',
        '#99f6e4',
        '#a5f3fc',
        '#bae6fd',
        '#bfdbfe',
        '#c7d2fe',
        '#ddd6fe',
        '#e9d5ff',
        '#f5d0fe',
        '#fbcfe8',
        '#fecdd3',
        // 300
        '#fca5a5',
        '#fdba74',
        '#fcd34d',
        '#bef264',
        '#86efac',
        '#5eead4',
        '#67e8f9',
        '#93c5fd',
        '#d8b4fe',
        '#f9a8d4',
        '#60a5fa',
        '#d6d3d1',
        '#cbd5e1'
    ];

    private isOpen: boolean = false;
    private colorPicker: iro.ColorPicker;
    private clickHandler: (e: MouseEvent) => void;
    private keyboardHandler: (e: KeyboardEvent) => void;

    public constructor(
        public t: I18N //
    ) {}

    public attached(): void {
        if (!this.showPalette) this.view = 'picker';

        this.clickHandler = (e) => this.handleGlobalClick(e);
        this.keyboardHandler = (e) => this.handleGlobalKey(e);
        document.addEventListener('click', this.clickHandler);
        document.addEventListener('keydown', this.keyboardHandler);
    }

    public detaching(): void {
        document.removeEventListener('click', this.clickHandler);
        document.removeEventListener('keydown', this.keyboardHandler);
    }

    public open(): void {
        this.isOpen = true;
        if (isNotDefined(this.colorPicker)) this.initPicker();
    }

    public close(): void {
        this.isOpen = false;
        this.colorPicker.base.remove();
        this.colorPicker = null;
        if (this.showPalette) this.view = 'palette';
    }

    public toggleVisibility(): void {
        if (this.isOpen) this.close();
        else this.open();
    }

    public changeView(view: 'palette' | 'picker'): void {
        this.view = view;
    }

    public setColor(color: string): void {
        this.value = color;

        if (isDefined(this.colorPicker)) this.colorPicker.color.hexString = color;
    }

    private initPicker(): void {
        this.colorPicker = iro.ColorPicker(this.picker, {
            width: 150,
            color: this.value
        });
        this.colorPicker.on('color:change', (color: iro.Color) => {
            this.value = color.hexString;
        });
    }

    private handleGlobalKey(e: KeyboardEvent): void {
        if (!this.isOpen) return;
        if (e.key === 'Escape') this.close();
    }

    private handleGlobalClick(e: MouseEvent): void {
        if (!this.isOpen) return;

        const picker = (e.target as HTMLElement).closest(`.color-picker-${this.guid}`);
        if (isNotDefined(picker)) this.close();
    }
}
