<import from="converters/translate" />

<ux-combobox
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    rounded.bind="rounded"
    show-footer.bind="true"
    set-value-on-select.bind="setValueOnSelect"
    onselect.trigger="handleSelect($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="category of categories" value.bind="category.id">${ category.translations | translate:language } (${category.percentage}%)</ux-combobox-option>
</ux-combobox>
