import { IEventAggregator, bindable, inject } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';

@inject(Element, IEventAggregator)
export class UxDropdownOption {
    @bindable() public href = 'javascript:';

    private parent: string;

    public constructor(
        private readonly element: HTMLElement, //
        private readonly events: IEventAggregator
    ) {}

    public bound(): void {
        setTimeout(() => {
            this.element.addEventListener('click', this.handleClicked);
        });
        setTimeout(() => {
            this.parent = this.element.getAttribute('parent');
            this.element.removeAttribute('parent');
        });
    }

    public detaching(): void {
        this.element.removeEventListener('click', this.handleClicked);
    }

    private handleClicked = (e: MouseEvent): void => {
        console.log('wtf');
        this.events.publish(UxEvents.UxDropdownOptionClicked, {
            parent: this.parent
        });
    };
}
