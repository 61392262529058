<import from="./template-task-list/template-task-list" />
<import from="converters/sort" />

<template class="relative !flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-1 gap-x-2 overflow-hidden" css.bind="styles">
        <div class="relative flex h-full flex-1 flex-col overflow-y-auto pt-8">
            <div ref="container" class="flex flex-1 flex-col gap-5 px-8 pb-8">
                <template if.bind="baseLoaded">
                    <!-- My tasks list -->
                    <template-task-list
                        component.ref="references[0]"
                        lists.bind="references"
                        can-edit.bind="false"
                        expanded.bind="pageState.values.lists['personal'].expanded"
                        t="[name]translation:partial-views.tasks.labels.my-tasks"
                        tasks.bind="myTasks"
                        has-role.bind="hasRole"
                        state.two-way="tasksState"
                        is-personal.bind="true"
                        selected.two-way="selected"
                        on-new-task.bind="handleNewPersonalTask"
                        on-expanded-change.bind="handleExpandedChange"
                        on-edit-task.bind="handleEditTask"
                        open-user-posts.bind="openUserPosts"
                        workspace.bind="authenticated.workspace.id"
                        language.bind="language">
                    </template-task-list>
                    <template-task-list
                        if.bind="hasRole(UserRoles.ReadHealthcareTaskLists)"
                        repeat.for="list of lists | sort:'displayOrder'"
                        component.ref="references[$index + 1]"
                        lists.bind="references"
                        on-drag-start.bind="handleListDragStart"
                        on-drag.bind="handleListDrag"
                        on-drag-end.bind="handleListDragEnd"
                        on-drag-canceled.bind="handleListDragCanceled"
                        expanded.bind="pageState.values.lists[list.id].expanded"
                        list.bind="list"
                        has-role.bind="hasRole"
                        state.two-way="tasksState"
                        selected.two-way="selected"
                        name.bind="list.name[language]"
                        on-edit.bind="handleEditList"
                        open-user-posts.bind="openUserPosts"
                        on-expanded-change.bind="handleExpandedChange"
                        on-new-task.bind="handleNewTask"
                        on-edit-task.bind="handleEditTask"
                        workspace.bind="authenticated.workspace.id"
                        language.bind="language">
                    </template-task-list>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col gap-10">
                                <ux-skeleton repeat.for="i of 3" show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div
                if.bind="baseLoaded && (hasRole(UserRoles.WriteHealthcareTasks) || hasRole(UserRoles.WriteHealthcareTaskLabels) || hasRole(UserRoles.WriteHealthcareTaskLabels))"
                class="sticky bottom-0 z-10 flex justify-between gap-2 border-t border-gray-200 bg-white px-8 py-4">
                <div class="flex">
                    <ux-button if.bind="hasRole(UserRoles.WriteHealthcareTaskLists)" click.trigger="handleCreateList()">
                        <span t="translation:partial-views.tasks.buttons.new-list"></span>
                    </ux-button>
                </div>
                <div class="flex gap-2">
                    <template if.bind="selected.length > 0">
                        <ux-dropdown placement="top-right">
                            <div au-slot="label" class="flex gap-1">
                                <p class="text-sm" t="translation:global.buttons.actions"></p>
                                <span>(${selected.length})</span>
                            </div>
                            <ux-dropdown-option click.trigger="archive()">
                                <span t="translation:global.buttons.archive"></span>
                            </ux-dropdown-option>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteHealthcareTasks)" click.trigger="delete()">
                                <span class="text-red-500" t="translation:global.buttons.delete"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </template>
                    <template if.bind="hasRole(UserRoles.WriteHealthcareTaskLabels)">
                        <ux-button click.trigger="handleManageLabels(HealthcareTaskLabelTypes.Priority)" mode="outline" color="dark">
                            <span t="translation:partial-views.tasks.buttons.manage-priorities"></span>
                        </ux-button>
                        <ux-button click.trigger="handleManageLabels(HealthcareTaskLabelTypes.Status)" mode="outline" color="dark">
                            <span t="translation:partial-views.tasks.buttons.manage-statuses"></span>
                        </ux-button>
                        <ux-button click.trigger="handleManageLabels(HealthcareTaskLabelTypes.Label)" mode="outline" color="dark">
                            <span t="translation:partial-views.tasks.buttons.manage-labels"></span>
                        </ux-button>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
