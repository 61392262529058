import { GetMedicalWidgetResponse, MedicalExaminationTemplateItem, MedicalExaminationTemplateItemStep, MedicalTherapyProtocol } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';

export class GetUsedWidgetIdsValueConverter {
    public toView(items: MedicalExaminationTemplateItem[] | MedicalTherapyProtocol[], type: 'therapy' | 'examination'): any[] {
        if (isNotDefined(items)) return items;

        const widgetIdsToSkip: string[] = [];
        const allSteps = items.selectMany<MedicalExaminationTemplateItem, MedicalExaminationTemplateItemStep>((x) => x.stepsToTake);

        const evaluateWidget = (widget: GetMedicalWidgetResponse): void => {
            if (isDefined(widget)) {
                // If widget is not allowed multiple times add it to the
                if (!widget.multiplePerExaminationAllowed) widgetIdsToSkip.push(widget.id);
            }
        };

        if (type === 'examination') {
            for (const step of allSteps) {
                if (isDefined(step.widget)) evaluateWidget(step.attributes.widget);
            }
        } else {
            // TODO: Fix this
            // Check every treatment step for widgets and evaluate them.
            // const steps: MedicalTherapyEvaluationItem[] = items.selectMany((x: MedicalTherapyProtocol) => x.evaluations.selectMany((y: MedicalTherapyEvaluation) => y.stepsToTake));
            // for (const step of steps) {
            //     if (isDefined(step.widget)) evaluateWidget(step.widget);
            // }
        }

        return widgetIdsToSkip;
    }
}
