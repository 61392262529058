import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { CreateVatCategoryRequest, VatCategoriesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, VatCategoriesApiClient, ModalService)
export class PartialManageVatCategoriesCreate extends BasePartialView {
    public request: CreateVatCategoryRequest = new CreateVatCategoryRequest();
    public validation = {
        name: true,
        percentage: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly categoriesApi: VatCategoriesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.translations = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageVatCategoriesCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['translations'], this.request, 1);
                await this.categoriesApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-vat-categories.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-vat-categories.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.translations[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[create-vat-category]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.name = validateTranslation(this.request.translations, this.language);
        this.validation.percentage = isDefined(this.request.percentage) && isOfType(Number(this.request.percentage), 'number');

        return validateState(this.validation);
    }
}
