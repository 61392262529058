import { I18N } from '@aurelia/i18n';
import { GetContactResponse } from '@wecore/sdk-crm';
import { bindable, containerless, inject } from 'aurelia';
import { ContactTypes } from '../../../../enums/contact-types';
import { checkForCustomDescriptions } from '../../../../infra/utilities';

@containerless
@inject(I18N)
export class TabContactsAddresses {
    @bindable() public contact: GetContactResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
    @bindable() public type: ContactTypes;

    public descriptions: { [key: string]: string }[];

    public constructor(
        public t: I18N //
    ) {}

    public bound(): void {
        const descriptions = [
            {
                nl: this.t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.home-address', { lng: 'en' })
            },
            {
                nl: this.t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];

        this.descriptions = checkForCustomDescriptions(this.t, this.language, descriptions, this.contact, 'address');
    }
}
