import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ContactType, ContactTypeSettings, ContactTypesApiClient } from '@wecore/sdk-crm';
import {
    Label,
    LabelProperties,
    LabelTypes,
    LabelsApiClient,
    MedicalEquipment,
    MedicalEquipmentApiClient,
    Medication,
    MedicationSideEffect,
    MedicationsApiClient,
    Profession,
    ProfessionsApiClient,
    Sport,
    SportsApiClient
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    ModalService,
    MedicationsApiClient,
    SportsApiClient,
    ProfessionsApiClient,
    LabelsApiClient,
    MedicalEquipmentApiClient,
    ContactTypesApiClient
)
export class PartialMasterDataManage extends BasePartialView {
    public items: any[] = [];
    public input: UxInput;
    public validation = { name: true, unique: true };

    public masterData: {
        route: string;
        name: string;
        description: string;
        type: string;
        api: string;
    };

    public hasResults: boolean = false;
    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;

    private types: any = {
        sport: Sport,
        medicalEquipment: MedicalEquipment,
        label: Label,
        medication: Medication,
        profession: Profession,
        contactType: ContactType
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly modalService: ModalService,
        // Api are used dynamically in below code and
        // the names should always be in plural form (e.g. medicalEquipmentsApi).
        private readonly medicationsApi: MedicationsApiClient,
        private readonly sportsApi: SportsApiClient,
        private readonly professionsApi: ProfessionsApiClient,
        private readonly labelsApi: LabelsApiClient,
        private readonly medicalEquipmentApi: MedicalEquipmentApiClient,
        private readonly contactTypesApi: ContactTypesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.masterData = view.data.item;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                await this.getData({ reset: false, initial: true });

                this.loadViewsFromUrl({
                    open: async (view: string, id: string) => {
                        if (isNotDefined(view) && isNotDefined(id)) return;

                        const item = this.items.find((x) => x.id === id);
                        if (isNotDefined(item)) return;

                        this.manageFields(item);
                    }
                });

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    setTimeout(() => {
                        if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                    });
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMasterDataManage.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public manageTranslationsFor(index: number): void {
        this.manageTranslations({
            translations: this.items[index].translations,
            callback: (updatedTranslations: any) => {
                this.items[index].translations = updatedTranslations;
            },
            required: true,
            type: 'input'
        });
    }

    public addItem(): void {
        resetValidation(this.validation);
        if (this.items.some((x) => x.editMode)) return;

        let item: any = {
            translations: {},
            editMode: true,
            externalReferences: {}
        };

        switch (this.masterData.type) {
            case 'label':
                item.properties = new LabelProperties({
                    type: LabelTypes.Patient
                });
                break;
            case 'medication':
                item.properties = [] as MedicationSideEffect[];
                break;
            case 'contactType':
                item.properties = new ContactTypeSettings({
                    locked: false
                });
                break;
        }

        this.items = [item, ...this.items];
        this.hasResults = this.items.length > 0;
    }

    public async handleSave(item: any, index: number): Promise<void> {
        // Give the input some time to complete the binding.
        setTimeout(async () => {
            resetValidation(this.validation);

            this.validation.name = isNotEmpty(this.items[index].translations[this.language]);
            this.validation.unique = this.items.every((x, i) => {
                // Ignore the current item.
                if (index === i) return true;
                if (isNotDefined(x.translations[this.language])) return true;
                return x.translations[this.language].toLowerCase() !== this.items[index].translations[this.language]?.toLowerCase();
            });

            const isValid = validateState(this.validation);
            if (isValid && isNotDefined(item.id)) {
                item.isLoading = true;
                await this[this.masterData.api].create(
                    this.authenticated.workspace.id,
                    new this.types[this.masterData.type]({
                        translations: this.items[index].translations
                    })
                );
                item.isLoading = false;
                await this.getData();
            } else if (isValid && isDefined(item.id)) {
                item.isLoading = true;
                await this[this.masterData.api].update(item.id, this.authenticated.workspace.id, this.items[index]);
                item.isLoading = false;
                // await this.getData();
            }

            this.removeChildViews();
            this.getData({ reset: true });
        });
    }

    public async handleDelete(item: any, index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.master-data.questions.delete-item.title'),
                message: this.t
                    .tr('translation:partial-views.master-data.questions.delete-item.message') //
                    .replace('{entity}', `<span>'${item.translations[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        item.isLoading = true;
                        await this[this.masterData.api].delete(item.id, this.authenticated.workspace.id);
                        item.isLoading = false;

                        this.getData({ reset: true });
                    }
                }
            })
        );
    }

    public async handleKeyUp(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const index = event.detail.values.data;
        const e = event.detail.innerEvent as KeyboardEvent;
        if (e.key === 'Enter') await this.handleSave(this.items[index], index);
    }

    public cancelEdit(index: number, item: any): void {
        if (isNotDefined(item.id)) {
            this.items.splice(index, 1);
        } else item.editMode = false;
    }

    public toggleEditMode(item: any): void {
        const itemInEditMode = this.items.find((x) => x.editMode);

        if (isDefined(itemInEditMode))
            if (isDefined(itemInEditMode.id)) itemInEditMode.editMode = false;
            else {
                this.items.splice(this.items.length - 1, 1);
            }

        item.editMode = !item.editMode;
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;
        this.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData({ reset: true });

        element.isLoading = false;
        this.isLoading = false;
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async manageFields(item: any): Promise<Promise<void>> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.ManageMasterDataFields.with({ masterData: this.masterData, item, id: item.id }) //
                .whenClosed(async (result: PartialViewResults, __: any) => {
                    if (result === PartialViewResults.Ok) await this.getData({ reset: true });
                }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    private async getData(options?: {
        reset?: boolean; //
        initial?: boolean;
    }): Promise<void> {
        const defaults = {
            reset: false,
            initial: false,
            ...options
        };

        if (defaults.reset) {
            this.skip = 0;
            this.items = [];
            this.endOfList = false;
            if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
        }

        this.isLoading = true;
        const response = await this[this.masterData.api].search(
            this.authenticated.workspace.id, //
            isNotEmpty(this.query) ? this.query : undefined,
            this.pageSize,
            this.skip,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        ); //

        if (defaults.initial) this.hasResults = response.data.length > 0;

        if (!defaults.reset && response.data.empty() && !defaults.initial) {
            this.endOfList = true;
            this.isLoading = false;
            return;
        }

        this.skip += Number(this.pageSize);
        this.items = [...this.items, ...response.data];
        this.isLoading = false;
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;
        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
