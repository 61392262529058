import { I18N } from '@aurelia/i18n';
import { GetUserResponse } from '@wecore/sdk-core';
import { Genders } from '@wecore/sdk-crm';
import { GetPatientResponse, UserEntityReference } from '@wecore/sdk-healthcare';
import { bindable, containerless, inject } from 'aurelia';
import { checkForCustomDescriptions } from '../../../../infra/utilities';

@containerless
@inject(I18N)
export class TabPatientsGeneral {
    @bindable() public patient: GetPatientResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
    @bindable() public currentUser: GetPatientResponse;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public emailDescriptions: { [key: string]: string }[];
    public linkDescriptions: { [key: string]: string }[];
    public phoneDescriptions: { [key: string]: string }[];
    public Genders: typeof Genders = Genders;

    public test: Date;

    public constructor(
        private readonly t: I18N //
    ) {}

    public attaching(): void {
        this.emailDescriptions = [
            { nl: this.t.tr('translation:global.labels.work', { lng: 'nl' }), en: this.t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: this.t.tr('translation:global.labels.private', { lng: 'nl' }), en: this.t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), en: this.t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.linkDescriptions = [
            { nl: this.t.tr('translation:global.labels.website', { lng: 'nl' }), en: this.t.tr('translation:global.labels.website', { lng: 'en' }) },
            { nl: 'Twitter', en: 'Twitter' },
            { nl: 'LinkedIn', en: 'LinkedIn' },
            { nl: 'Facebook', en: 'Facebook' },
            { nl: 'Instagram', en: 'Instagram' },
            { nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), en: this.t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.phoneDescriptions = [
            { nl: this.t.tr('translation:global.labels.work', { lng: 'nl' }), en: this.t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: this.t.tr('translation:global.labels.private', { lng: 'nl' }), en: this.t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: this.t.tr('translation:global.labels.mobile', { lng: 'nl' }), en: this.t.tr('translation:global.labels.mobile', { lng: 'en' }) },
            { nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), en: this.t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.emailDescriptions = checkForCustomDescriptions(this.t, this.language, this.emailDescriptions, this.patient, 'email');
        this.phoneDescriptions = checkForCustomDescriptions(this.t, this.language, this.phoneDescriptions, this.patient, 'phone');
        this.linkDescriptions = checkForCustomDescriptions(this.t, this.language, this.linkDescriptions, this.patient, 'link');
    }

    public handleUserSelected = async (user: GetUserResponse): Promise<void> => {
        this.patient.headPractitioner = new UserEntityReference({
            id: user.id,
            name: user.displayName
        });
    };
}
