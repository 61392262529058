<import from="converters/translate" />

<div class="col-span-12 flex flex-col">
    <div class="flex items-center justify-between rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-800">
        <div class="flex min-w-0 flex-col">
            <div class="flex min-w-0 items-center gap-x-3">
                <span class="text-gray-500" t="translation:global.labels.question"></span>
                <span if.bind="nested" class="block truncate">${ step.question.name | translate:language }</span>
                <span else class="block truncate">${ registration.question.name | translate:language }</span>
            </div>
        </div>
    </div>
</div>
