<import from="converters/translate" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between">
                <p class="text-sm font-medium" t="translation:global.labels.insurances"></p>
                <div class="flex space-x-1">
                    <button class="flex items-center justify-center p-1 bg-gray-100 rounded-full hover:bg-gray-200" click.trigger="close()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                            <path
                                fill-rule="evenodd"
                                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full px-6 pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <div class="flex flex-col gap-1 mt-3">
                    <div repeat.for="insurance of patient.insurances" class="flex flex-col px-4 py-2 rounded-lg bg-gray-50">
                        <div class="flex items-center justify-between gap-3">
                            <div class="flex items-center min-w-0 gap-1 text-sm">
                                <div if.bind="insurance.status === InsuranceStatuses.Active" class="w-2 h-2 text-sm bg-green-500 rounded-full"></div>
                                <div if.bind="insurance.status === InsuranceStatuses.Inactive" class="w-2 h-2 text-sm bg-red-500 rounded-full"></div>
                                <div if.bind="insurance.status === InsuranceStatuses.Unauthorized" class="w-2 h-2 text-sm bg-red-500 rounded-full"></div>
                                <span class="truncate">${insurance.name}</span>
                                <span class="flex flex-nowrap">
                                    <span>(</span>
                                    <span
                                        class="whitespace-nowrap"
                                        if.bind="insurance.type === InsuranceTypes.Supplementary"
                                        t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary"></span>
                                    <span
                                        class="whitespace-nowrap"
                                        if.bind="insurance.type === InsuranceTypes.SupplementaryPlusDental"
                                        t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary-dental">
                                    </span>
                                    <span class="whitespace-nowrap" if.bind="insurance.type === InsuranceTypes.Awbz" t="translation:partial-views.patient-cov-check.labels.insurance-type-awbz"></span>
                                    <span
                                        class="whitespace-nowrap"
                                        if.bind="insurance.type === InsuranceTypes.Basic"
                                        t="translation:partial-views.patient-cov-check.labels.insurance-type-basic"></span>
                                    <span
                                        class="whitespace-nowrap"
                                        if.bind="insurance.type === InsuranceTypes.MainInsurance"
                                        t="translation:partial-views.patient-cov-check.labels.insurance-type-main"></span>
                                    <span
                                        class="whitespace-nowrap"
                                        if.bind="insurance.type === InsuranceTypes.Dental"
                                        t="translation:partial-views.patient-cov-check.labels.insurance-type-dental"></span>
                                    <span>)</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex gap-1 text-sm text-gray-500">
                            <p if.bind="insurance.insurer">${insurance.insurer.translations | translate:language}</p>
                            <div class="flex gap-1">
                                <div class="flex">
                                    <span if.bind="insurance.insurer">(</span>
                                    <span>${insurance.startDate | date}</span>
                                    <span if.bind="!insurance.endDate">)</span>
                                </div>
                                <div if.bind="insurance.endDate" class="flex">
                                    <span class="pr-1"> - </span>
                                    <span>${insurance.endDate | date}</span>
                                    <span if.bind="insurance.insurer">)</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-1 mt-2 text-xs text-primary">
                            <span t="translation:partial-views.declaration-performances.labels.last-checked"></span>
                            <span>${ insurance.addedAt | date:'dd-MM-yyyy HH:mm'}</span>
                        </div>
                    </div>
                    <div if.bind="patient.insurances.length === 0" class="flex justify-center py-5">
                        <p class="text-sm" t="translation:partial-views.patient-insurances.messages.no-results"></p>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-2 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
