<template>
    <div class="flex h-full flex-col">
        <div class="group relative flex flex-1 flex-col border-b-2 border-white hover:border-gray-100">
            <div if.bind="active" class="absolute -bottom-0.5 left-1/2 h-0.5 w-full -translate-x-1/2 transform rounded-t-full bg-primary"></div>
            <a href.bind="href" target.bind="target" class="inline-flex flex-1 items-center px-1 pt-1 text-sm font-medium">
                <au-slot />
            </a>
        </div>
    </div>
</template>
