<import from="./ux-button.scss"></import>

<template click.trigger="handleClick($event)" w-full.class="type === 'full'" class="flex items-center" no-border.class="!border">
    <button
        disabled.bind="disabled || loading"
        has-padding.class="padding"
        w-full.class="type === 'full'"
        transparent.class="transparent"
        h-full.class="hasOptions"
        rounded-full.class="rounded"
        class="${mode} ${color} ${size} ${type} relative z-0 inline-flex items-center justify-center overflow-hidden outline-none ring-offset-1 transition duration-150 ease-in-out focus:z-10 focus:ring-1">
        <div if.bind="loading" class="absolute top-0 z-10 w-full btn-loader-bar">
            <div class="loader relative w-full overflow-hidden bg-transparent before:absolute before:left-[-200px] before:block before:w-[200px]"></div>
        </div>
        <span css="min-width: ${minContentWidth}px;" if.bind="textWhenLoading && loading">${textWhenLoading}</span>
        <span ref="content" text-transparent.class="textWhenLoading && loading" absolute.class="textWhenLoading && loading" flex.class="!loading" class="flex items-center">
            <au-slot />
        </span>
    </button>
</template>
