import { MedicalExaminationActionFlow, MedicalExaminationActionItem, MedicalExaminationActionItemTypes } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';
import { generateColumns } from '../../../../../../../../infra/utilities';

@containerless
export class TemplateActionCategory {
    @bindable() public stepResult: any;
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public language: string;

    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public columns: string;
    public expanded: boolean = false;

    public bound(): void {
        this.setColumns();
    }

    public collapseOrExpand(): void {
        this.expanded = !this.expanded;
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.id) || [] //
        );
    };
}
