import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';

import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { PartialViewResults } from '../../../../../enums/partial-view-results';
import { CustomEvents } from '../../../../../infra/events';
import { PartialViews } from '../../../../../infra/partial-views';
import { EventDetails } from '../../../../../models/event-details';
import { PartialView } from '../../../../../models/partial-view';
import { ViewOptions } from '../../../../../models/view-options';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { UxTextarea } from '../../../../../ux/ux-textarea/ux-textarea';

@containerless
@inject(IEventAggregator)
export class WidgetCourseOfComplaints {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public scrollToView: (partial: PartialView) => Promise<void>;
    @bindable() public widgets: WidgetRegistration[] = [];

    public suggestionsAreShown: boolean = false;
    public text: string;
    private textarea: UxTextarea;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.String;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.CourseOfComplaints,
                onSave: async (): Promise<void> => {},
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }

    public async handleCourseOfComplaintChanged(e: CustomEvent<EventDetails<UxTextarea, any>>): Promise<void> {
        const text = e.detail.values.value;
        this.text = text;

        const results = this.record.results.map((x) => x.differentialDiagnosis);

        if (!this.suggestionsAreShown) {
            await this.addPartial(
                PartialViews.MedicalSuggestions.with({
                    results,
                    text, //
                    record: this.record
                }).whenClosed(async (_: PartialViewResults, __: any) => {
                    this.suggestionsAreShown = false;
                }),
                new ViewOptions({
                    scrollToView: false,
                    markItem: true,
                    replace: false
                })
            );
            this.suggestionsAreShown = true;
        }
        // else this.scrollToView(PartialViews.MedicalSuggestions);

        this.events.publish(CustomEvents.MedicalRecordsCourseOfComplaintsChanged, text);
    }

    public async showSuggestions(): Promise<void> {
        const results = this.record.results.map((x) => x.differentialDiagnosis);
        if (!this.suggestionsAreShown) {
            await this.addPartial(
                PartialViews.MedicalSuggestions.with({
                    results,
                    text: this.textarea.value,
                    record: this.record
                }).whenClosed(async (__: PartialViewResults, _: any) => {
                    this.suggestionsAreShown = false;
                }),
                new ViewOptions({
                    scrollToView: false,
                    markItem: true,
                    replace: true
                })
            );
            this.suggestionsAreShown = true;
        }
    }
}
