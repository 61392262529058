import { MedicalExaminationActionFlow, MedicalExaminationActionItem } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';
import { generateColumns } from '../../../../../../../../infra/utilities';

@containerless
export class TemplateActionStep {
    @bindable() public stepResult: any;
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public language: string;

    public columns: string;
    public itemResult: any;

    public bound(): void {
        this.setColumns();
        this.itemResult = this.stepResult.items.find((x: any) => x.stepId === this.item.id);
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.id) || [] //
        );
    };
}
