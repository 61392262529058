<import from="components/component-duplicator/component-duplicator" />
<import from="bx/bx-contact-type-selector/bx-contact-type-selector" />

<section class="flex flex-col flex-1 px-6 overflow-y-auto">
    <div class="sticky top-0 z-10 pt-6 pb-4 bg-white border-b border-gray-300">
        <div class="flex justify-center py-5" if.bind="isEdit">
            <ux-avatar size="xxl" email.bind="contact.emails[0].value" name.bind="contact.displayName" color.bind="contact.avatar.color" shade.bind="contact.avatar.shade"></ux-avatar>
        </div>
        <div class="grid grid-cols-12 gap-2">
            <div class="flex flex-col col-span-2">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.contacts.labels.initials"></span>
                </ux-label>
                <ux-input value.two-way="contact.initials" t="[placeholder]translation:partial-views.contacts.placeholders.add-initials"></ux-input>
            </div>
            <div class="flex flex-col col-span-4">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.contacts.labels.firstname"></span>
                </ux-label>
                <ux-input value.two-way="contact.firstName" t="[placeholder]translation:partial-views.contacts.placeholders.add-firstname"></ux-input>
            </div>
            <div class="flex flex-col col-span-2">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.contacts.labels.lastname-affix"></span>
                </ux-label>
                <ux-input value.two-way="contact.lastNameAffix" t="[placeholder]translation:partial-views.contacts.placeholders.add-lastname-affix"></ux-input>
            </div>
            <div class="flex flex-col col-span-4">
                <div class="flex items-center space-x-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.contacts.labels.lastname"></span>
                    </ux-label>
                    <span class="flex items-center h-3 text-red-500">*</span>
                </div>
                <ux-input valid.bind="validation.lastName" value.two-way="contact.lastName" t="[placeholder]translation:partial-views.contacts.placeholders.add-lastname"></ux-input>
            </div>
            <div class="flex flex-col col-span-6">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.contacts.labels.middlename"></span>
                </ux-label>
                <ux-input value.two-way="contact.middleName" t="[placeholder]translation:partial-views.contacts.placeholders.add-middlename"></ux-input>
            </div>
            <div class="flex flex-col col-span-6">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.contacts.labels.birthname"></span>
                </ux-label>
                <ux-input value.two-way="contact.birthName" t="[placeholder]translation:partial-views.contacts.placeholders.add-birthname"></ux-input>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-2 pb-5 mt-4">
        <div class="flex flex-col col-span-6">
            <ux-label class="mb-1">
                <span t="translation:partial-views.contacts.labels.type"></span>
            </ux-label>
            <bx-contact-type-selector
                on-select.bind="handleTypeSelected"
                language.bind="language"
                disabled.bind="lockType"
                workspace.bind="workspace"
                t="[placeholder]translation:partial-views.contacts.placeholders.select-contact-type"
                value.bind="contact.typeV2.id">
            </bx-contact-type-selector>
        </div>
        <div class="flex flex-col col-span-6">
            <ux-label class="mb-1">
                <span t="translation:partial-views.contacts.labels.number"></span>
            </ux-label>
            <ux-input value.two-way="contact.number" t="[placeholder]translation:partial-views.contacts.placeholders.number"></ux-input>
        </div>
        <div class="flex flex-col col-span-6">
            <ux-label class="mb-1">
                <span t="translation:partial-views.contacts.labels.sex"></span>
            </ux-label>
            <ux-select value.two-way="contact.gender">
                <ux-select-option value.bind="Genders.Male" >
                    <span t="translation:global.labels.male"></span>
                </ux-select-option>
                <ux-select-option value.bind="Genders.Female" >
                    <span t="translation:global.labels.female"></span>
                </ux-select-option>
            </ux-select>
        </div>
        <div class="flex flex-col col-span-6">
            <ux-label class="mb-1">
                <span t="translation:global.labels.dob"></span>
            </ux-label>
            <ux-datepicker mode="input" value.two-way="contact.dateOfBirth" t="[placeholder]translation:partial-views.contacts.placeholders.select-dob"></ux-datepicker>
        </div>
        <div class="col-span-12">
            <ux-body-divider></ux-body-divider>
        </div>
        <div class="flex flex-col col-span-12 space-y-5">
            <component-duplicator
                t="[label]translation:partial-views.contacts.labels.enter-emails"
                type="email"
                descriptions.bind="emailDescriptions"
                items.two-way="contact.emails"
                language.bind="language"
                validation.two-way="validation.emails">
            </component-duplicator>
            <component-duplicator
                t="[label]translation:partial-views.contacts.labels.enter-phones"
                type="phone"
                descriptions.bind="phoneDescriptions"
                items.two-way="contact.phones"
                mask="000000000"
                language.bind="language"
                validation.two-way="validation.phones">
            </component-duplicator>
            <component-duplicator
                t="[label]translation:partial-views.contacts.labels.enter-links"
                type="link"
                descriptions.bind="linkDescriptions"
                items.two-way="contact.links"
                language.bind="language"
                validation.two-way="validation.links">
            </component-duplicator>
        </div>
    </div>
</section>
