import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AppointmentTypesApiClient,
    EmailTemplateEntityReference,
    GetAllExaminationRoomsResponseItem,
    GetEmailTemplateResponse,
    HealthcareCode,
    HealthcarePrice,
    UpdateAppointmentTypeRequest
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, cloneDeep, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { SchedulerSettings } from '../../../models/scheduler-settings';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AppointmentTypesApiClient, ModalService)
export class PartialManageAppointmentTypesEdit extends BasePartialView {
    public typeId: string;
    public type: UpdateAppointmentTypeRequest;
    public settings: SchedulerSettings;
    public validation = {
        name: true,
        duration: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly typesApi: AppointmentTypesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.typeId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.settings = this.state.schedulerSettings;
                this.type = await this.typesApi.getById(this.typeId, this.authenticated.workspace.id);

                if (isNotDefined(this.type.prices)) this.type.prices = [];
                if (isNotDefined(this.type.codes)) this.type.codes = [];

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManageAppointmentTypesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name'], this.type, 1);
                await this.typesApi.update(this.typeId, this.authenticated.workspace.id, this.type);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-appointment-types.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-appointment-types.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.type.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[edit-appointment-type]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-appointment-types.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-appointment-types.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.type.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.typesApi.delete(this.typeId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-appointment-types.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-appointment-types.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.type.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-appointment-type]', e);
                        }
                    }
                }
            })
        );
    }

    public handleDurationInput(e: CustomEvent<EventDetails<UxInput, any>>): void {
        let value = Number(e.detail.values.value);
        // Make sure the value is closest to the slot size.
        if (value % this.settings.slotSize !== 0) value = Math.round(value / this.settings.slotSize) * this.settings.slotSize;
        if (value < this.settings.slotSize) value = this.settings.slotSize;

        this.type.duration = value;
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.type[property],
            callback: (updatedTranslations: any) => {
                this.type[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleRoomSelected = (_: GetAllExaminationRoomsResponseItem[], action: 'added' | 'deleted', room: GetAllExaminationRoomsResponseItem): void => {
        if (action === 'added') {
            this.type.requiredRooms.push(room);
        } else {
            const index = this.type.requiredRooms.findIndex((x) => x.id === room.id);
            if (index >= 0) this.type.requiredRooms.splice(index, 1);
        }
    };

    public async createOrEditPrice(index: number = -1): Promise<void> {
        const price = index > -1 ? this.type.prices[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.type.prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.type.prices[data.index] = data.price;
                    else this.type.prices.push(data.price);

                    this.type.prices = [
                        ...(this.type.prices.length > 0 ? [this.type.prices.shift()] : []), //
                        ...this.type.prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removePrice(index: number): Promise<void> {
        this.type.prices.splice(index, 1);
    }

    public formatDescription(index: number): string {
        let description = '';
        if (isDefined(this.type.prices[index].periodStart)) description += format(this.type.prices[index].periodStart, 'dd-MM-yyyy');
        if (isDefined(this.type.prices[index].periodStart)) description += ' - ';
        if (isDefined(this.type.prices[index].periodEnd)) description += format(this.type.prices[index].periodEnd, 'dd-MM-yyyy');

        if (isDefined(this.type.prices[index].insurer)) description += ` (${this.type.prices[index].insurer.translations[this.language]})`;

        return description;
    }

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.type.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.type.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.type.codes[data.index] = data.code;
                    this.type.codes = [
                        ...(this.type.codes.length > 0 ? [this.type.codes.shift()] : []), //
                        ...this.type.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.type.codes.splice(index, 1);
    }

    public handleTemplateSelected = (template: GetEmailTemplateResponse): void => {
        if (isDefined(template))
            this.type.templateForConfirmation = new EmailTemplateEntityReference({
                id: template.id,
                translations: template.name
            });
        else this.type.templateForConfirmation = null;
    };

    private validate(): boolean {
        this.validation.name = validateTranslation(this.type.name, this.language);

        if (isDefined(this.type.duration)) {
            this.validation.duration = this.type.duration >= 5;
        }

        return validateState(this.validation);
    }
}
