import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ContactsApiClient, Email, Genders, GetContactResponse } from '@wecore/sdk-crm';
import { EmailEntityTypes } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ContactsApiClient, ModalService)
export class PartialContactsCard extends BasePartialView {
    public contact: GetContactResponse;
    public expanded: boolean = false;
    public view: 'EntityContactMoments';
    public closeable: boolean = false;
    public Genders: typeof Genders = Genders;

    private contactId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly contactsApi: ContactsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.contactId = view.data.id;
        this.closeable = view.data.closeable;
        this.view = view.data.view;

        this.loadViewsFromUrl({
            open: async (view: string, entityId: string) => {
                if (isNotDefined(view)) {
                    if (isDefined(this.view)) this.open(this.view);
                    return;
                }
                if (isNotEmpty(entityId)) this.changeTo(PartialViews[view].with({ id: entityId, type: 'Contact' }, false));
                else this.changeTo(PartialViews[view].with({ id: this.contactId, type: 'Contact', closeable: true }), false);

                this.view = view as any;
            }
        });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [contact] = await Promise.all([
                    this.contactsApi.getById(this.contactId, this.authenticated.workspace.id) //
                ]);

                this.subscriptions.push(
                    this.events.subscribe(CustomEvents.ContactUpdated, async () => {
                        this.contact = await this.contactsApi.getById(this.contactId, this.authenticated.workspace.id);
                    })
                );
                this.contact = contact;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialContactsCard.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async edit(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditContact.with({ id: this.contact.id }) //
                .whenClosed(async (result: PartialViewResults, contact: GetContactResponse) => {
                    if (result === PartialViewResults.Ok) {
                        this.contact = contact;
                    }
                    if (result === PartialViewResults.Deleted)
                        await super.remove({
                            result: PartialViewResults.Deleted,
                            updateUrl: true
                        });
                }),
            options: new ViewOptions({
                scrollToView: true, //
                markItem: false,
                replace: true,
                index: this.partial.index + 1
            })
        });
    }

    public async sendEmailTo(email: Email): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.SendEmailToEntity.with({
                email, //
                entityId: this.contact.id,
                entityType: EmailEntityTypes.Contact,
                view: 'ContactCard'
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true })
        });
    }

    public async open(view: 'EntityContactMoments'): Promise<void> {
        await super.removeChildViews();
        switch (view) {
            case 'EntityContactMoments':
                await this.addPartialView({
                    view: this.partial.base, //
                    partial: PartialViews.EntityContactMoments.with({ id: this.contactId, type: 'Contact', closeable: true }).whenClosed(() => (this.view = null)),
                    options: new ViewOptions({ index: this.partial.index + 1, markItem: true })
                });
                break;
        }
        this.view = view;
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.contacts.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.contacts.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.contact.displayName}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.contactsApi.delete(this.contactId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.contacts.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.contacts.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.contact.displayName}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[contacts-card/delete]', e);
                        }
                    }
                }
            })
        );
    }
}
