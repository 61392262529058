<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <p class="font-semibold">Zorgmail</p>
            <span class="text-sm text-gray-500" t="translation:partial-views.integrations-zorgmail.messages.configure-settings"></span>
            <div class="mt-3 flex items-center justify-between border-t border-gray-200 pt-4">
                <ux-label>
                    <span t="translation:partial-views.integrations-zorgmail.labels.enabled"></span>
                </ux-label>
                <ux-toggle
                    checked.two-way="workspace.integrations.zorgmail.settings.enabled"
                    label-active.bind="'translation:global.labels.yes' | t"
                    label-inactive.bind="'translation:global.labels.no' | t"
                    size="md"
                    color="success">
                </ux-toggle>
            </div>
        </ux-header>
        <div class="flex h-full w-full flex-1 flex-col overflow-y-auto px-8 pb-8">
            <div if.bind="baseLoaded" class="mt-3 flex flex-col gap-3">
                <div class="flex flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.integrations-zorgmail.labels.username"></span>
                    </ux-label>
                    <ux-input value.two-way="workspace.integrations.zorgmail.settings.username"></ux-input>
                </div>
                <div class="flex flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.integrations-zorgmail.labels.password"></span>
                    </ux-label>
                    <ux-input value.two-way="workspace.integrations.zorgmail.settings.ediPassword"></ux-input>
                </div>
                <div class="flex flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.integrations-zorgmail.labels.hosted-key"></span>
                    </ux-label>
                    <ux-input value.two-way="workspace.integrations.zorgmail.settings.smtpPassword"></ux-input>
                </div>

                <ux-body-divider margin="mb-2 mt-3"></ux-body-divider>
                <div class="flex flex-col">
                    <ux-label>
                        <span t="translation:partial-views.integrations-zorgmail.labels.sync-for-accounts"></span>
                    </ux-label>
                    <span class="mb-2 text-xs text-gray-500" t="translation:partial-views.integrations-zorgmail.messages.sync-account-roles"></span>
                    <ux-multi-selector
                        options.bind="toSet()"
                        show-footer.bind="false"
                        force-remove.bind="true"
                        data="account"
                        autocomplete.bind="true"
                        values.two-way="workspace.integrations.zorgmail.rolesToSyncAsAccounts"
                        t="[placeholder]translation:partial-views.integrations-zorgmail.placeholders.select-roles">
                    </ux-multi-selector>
                </div>
                <div class="flex flex-col">
                    <ux-label>
                        <span t="translation:partial-views.integrations-zorgmail.labels.sync-for-contacts"></span>
                    </ux-label>
                    <span class="mb-2 text-xs text-gray-500" t="translation:partial-views.integrations-zorgmail.messages.sync-contact-roles"></span>
                    <ux-multi-selector
                        options.bind="toSet()"
                        show-footer.bind="false"
                        force-remove.bind="true"
                        data="account"
                        autocomplete.bind="true"
                        values.two-way="workspace.integrations.zorgmail.rolesToSyncAsContacts"
                        t="[placeholder]translation:partial-views.integrations-zorgmail.placeholders.select-roles">
                    </ux-multi-selector>
                </div>
                <template if.bind="workspace.integrations.zorgmail.information">
                    <ux-body-divider margin="mt-3 mb-1"></ux-body-divider>
                    <div class="flex flex-col">
                        <ux-label class="mb-1">
                            <span t="translation:partial-views.integrations-zorgmail.labels.information"></span>
                        </ux-label>
                        <div class="rounded-lg bg-gray-50 p-5">
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.id"></span>
                                <span>${workspace.integrations.zorgmail.information.id}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.created-at"></span>
                                <span>${workspace.integrations.zorgmail.information.createdAt | date}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.modified-at"></span>
                                <span>${workspace.integrations.zorgmail.information.modifiedAt | date}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.display-name"></span>
                                <span>${workspace.integrations.zorgmail.information.displayName}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.gender"></span>
                                <span>${workspace.integrations.zorgmail.information.gender}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.role"></span>
                                <span>${workspace.integrations.zorgmail.information.role}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.department"></span>
                                <span>${workspace.integrations.zorgmail.information.department ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.integrations-zorgmail.labels.pr-agb-code"></span>
                                <span>${workspace.integrations.zorgmail.information.prAgbCode ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.integrations-zorgmail.labels.zv-agb-code"></span>
                                <span>${workspace.integrations.zorgmail.information.zvAgbCode ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.integrations-zorgmail.labels.organization-id"></span>
                                <span>${workspace.integrations.zorgmail.information.organizationId ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.integrations-zorgmail.labels.organization"></span>
                                <span>${workspace.integrations.zorgmail.information.organization ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.integrations-zorgmail.labels.organization-type"></span>
                                <span>${workspace.integrations.zorgmail.information.organizationType ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.country"></span>
                                <span>${workspace.integrations.zorgmail.information.country ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.end-date"></span>
                                <span if.bind="workspace.integrations.zorgmail.information.endDate">${workspace.integrations.zorgmail.information.endDate | date}</span>
                                <span else>N/A</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.locality"></span>
                                <span>${workspace.integrations.zorgmail.information.locality ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.deleted"></span>
                                <span>${workspace.integrations.zorgmail.information.deleted ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.house-number"></span>
                                <span>${workspace.integrations.zorgmail.information.houseNumber ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.version"></span>
                                <span>${workspace.integrations.zorgmail.information.version ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.initials"></span>
                                <span>${workspace.integrations.zorgmail.information.initials ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.prefix"></span>
                                <span>${workspace.integrations.zorgmail.information.prefix ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.phone-number"></span>
                                <span>${workspace.integrations.zorgmail.information.telephoneNumber ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.integrations-zorgmail.labels.transaction-id"></span>
                                <span>${workspace.integrations.zorgmail.information.transactionId ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.last-name"></span>
                                <span>${workspace.integrations.zorgmail.information.lastName ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.street"></span>
                                <span>${workspace.integrations.zorgmail.information.street ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.email"></span>
                                <span>${workspace.integrations.zorgmail.information.email ?? 'N/A'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.zip-code"></span>
                                <span>${workspace.integrations.zorgmail.information.zipCode ?? 'N/A'}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <ux-body-divider margin="mt-3 mb-1"></ux-body-divider>
                    <div class="flex flex-col">
                        <div class="mt-1">
                            <ux-alert color="warning">
                                <div class="mt-0.5 flex flex-col gap-1">
                                    <span class="text-xs font-bold" t="translation:partial-views.integrations-zorgmail.labels.no-information"></span>
                                    <div class="flex flex-col gap-3">
                                        <span class="text-xs" t="translation:partial-views.integrations-zorgmail.messages.no-information"></span>
                                        <ux-button
                                            loading.bind="isSyncing"
                                            disabled.bind="!workspace.integrations.zorgmail.settings.enabled || isLoading || isSyncing"
                                            size="xs"
                                            color="warning"
                                            click.trigger="syncAddressBook()">
                                            <span class="pointer-events-none" t="translation:partial-views.integrations-zorgmail.buttons.sync-address-book"></span>
                                        </ux-button>
                                    </div>
                                </div>
                            </ux-alert>
                        </div>
                    </div>
                </template>
            </div>
            <div else class="flex flex-col pt-6">
                <ux-skeleton show-background.bind="false" type="container">
                    <div class="flex w-full flex-col space-y-5 py-1">
                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                        <ux-skeleton type="line"></ux-skeleton>
                    </div>
                </ux-skeleton>
            </div>
        </div>
        <div if.bind="baseLoaded" class="flex items-center border-t border-gray-200 px-4 py-3">
            <div justify-between.class="workspace.integrations.zorgmail.information" justify-end.class="!workspace.integrations.zorgmail.information" class="flex w-full items-center gap-x-2">
                <ux-button
                    if.bind="workspace.integrations.zorgmail.information"
                    size="sm"
                    loading.bind="isSyncing"
                    disabled.bind="!workspace.integrations.zorgmail.settings.enabled || isLoading || isSyncing"
                    color="primary"
                    click.trigger="syncAddressBook()">
                    <span class="pointer-events-none" t="translation:partial-views.integrations-zorgmail.buttons.sync-address-book"></span>
                </ux-button>

                <div class="flex gap-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || isSyncing" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading || isSyncing"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
