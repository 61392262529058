import { bindable } from 'aurelia';

export class TemplateFilter {
    @bindable() public key: string;
    @bindable() public value: string;
    @bindable() public type: string;
    @bindable() public index: number;
    @bindable() public replace: boolean = true;
    @bindable() public onRemove: (type: string, index: number) => void;

    public remove(): void {
        this.onRemove(this.type, this.index);
    }

    public display(value: string): string {
        if (!this.replace) return value;
        return value.replace(/[^a-zA-Z0-9\s]/g, '').replace(/ /g, '-');
    }
}
