import {
    GetMedicalRecordRegistrationResponse,
    MedicalExaminationActionFlow,
    MedicalExaminationTemplateItemStep,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem,
    MedicalTherapyExecutionFlow,
    MedicalTherapyPlanFlow
} from '@wecore/sdk-healthcare';

export interface IFlattenedExaminationStep {
    item: any;
    registration: GetMedicalRecordRegistrationResponse;
    isAnswerable: boolean;
    isAnswered: boolean;
    isAnsweredAndValidated: boolean;
    isVisible: boolean;
    isRequired: boolean;
    flow: MedicalExaminationActionFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow | MedicalTherapyEvaluationFlow;
    type:
        | 'action'
        | 'action-category'
        | 'action-step'
        | 'questionnaire'
        | 'questionnaire-question'
        | 'question'
        | 'widget'
        | 'therapy-plan'
        | 'therapy-plan-category'
        | 'therapy-plan-step'
        | 'therapy-execution'
        | 'therapy-execution-category'
        | 'therapy-execution-step'
        | 'evaluation';
    isFromEvaluation: boolean;
    container?: MedicalExaminationTemplateItemStep | GetMedicalRecordRegistrationResponse | MedicalTherapyEvaluationItem;
    categories?: string[];
}

export class FlattenedExaminationStep {
    public item: any;
    public registration: GetMedicalRecordRegistrationResponse;
    public isAnswerable: boolean = false;
    public isAnswered: boolean = false;
    public isAnsweredAndValidated: boolean = false;
    public isVisible: boolean = false;
    public isRequired: boolean = false;
    public flow: MedicalExaminationActionFlow | GetMedicalRecordRegistrationResponse;
    public type:
        | 'action'
        | 'action-category'
        | 'action-step'
        | 'questionnaire'
        | 'questionnaire-question'
        | 'question'
        | 'widget'
        | 'therapy-plan'
        | 'therapy-plan-category'
        | 'therapy-plan-step'
        | 'therapy-execution'
        | 'therapy-execution-category'
        | 'therapy-execution-step'
        | 'evaluation';
    public isFromEvaluation: boolean = false;
    public container: MedicalExaminationTemplateItemStep | GetMedicalRecordRegistrationResponse | MedicalTherapyEvaluationItem;
    public categories: string[];

    public constructor(data: IFlattenedExaminationStep) {
        this.item = data?.item;
        this.registration = data?.registration;
        this.isAnswerable = data?.isAnswerable;
        this.isAnswered = data?.isAnswered;
        this.isAnsweredAndValidated = data?.isAnsweredAndValidated;
        this.isVisible = data?.isVisible;
        this.isRequired = data?.isRequired;
        this.flow = data?.flow;
        this.type = data?.type;
        this.isFromEvaluation = data?.isFromEvaluation;
        this.container = data?.container;
        this.categories = data?.categories;
    }
}
