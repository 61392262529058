<import from="./ux-tabs.scss" />

<template class="flex overflow-hidden">
    <div class="flex overflow-hidden" w-full.class="stacked">
        <div class="overflow-hidden" border-b.class="mode === 'light'" border-gray-200.class="mode === 'light'" w-full.class="stacked">
            <nav class="ux-tabs -mb-px flex gap-x-1 overflow-x-auto" stacked.class="stacked" md:flex-col.class="stacked" md:gap-y-1.class="stacked" aria-label="Tabs">
                <au-slot />
            </nav>
        </div>
    </div>
</template>
