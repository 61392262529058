import { I18N } from '@aurelia/i18n';
import { Medication, MedicationsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(MedicationsApiClient, I18N, ErrorHandler)
export class BxMedicationSelector {
    @bindable() public value: string;
    @bindable() public selected: Medication;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public onSelect: (medication: Medication, data: any) => void;

    public combobox: UxCombobox;
    public medications: Medication[];

    public constructor(
        private readonly medicationsApi: MedicationsApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public handleClear(): void {
        if (isFunction(this.onSelect)) this.onSelect(null, this.data);
    }

    public async attaching(): Promise<void> {
        try {
            if (isNotEmpty(this.value)) {
                this.medicationsApi.getById(this.value, this.workspace).then((medication) => {
                    // If the selected item is not found, use the selected object which can
                    // be provided by the user and used to display the object name in the combobox.
                    // This can be handy in case the item has been deleted.
                    if (isNotDefined(medication)) medication = this.selected;
                    this.medicationsApi.search(this.workspace, '').then((response) => {
                        const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
                        this.medications = isDefined(medication) ? [medication].concat(data.filter((x) => x.id !== this.value)) : data;
                        if (isDefined(this.combobox)) this.combobox.isLoading = false;
                    });
                });
            } else {
                this.medicationsApi.search(this.workspace, '').then((response) => {
                    this.medications = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
                    if (isDefined(this.combobox)) this.combobox.isLoading = false;
                });
            }
        } catch (e) {
            this.errorHandler.handle('BxMedicationSelector.attached', e);
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.medicationsApi.search(this.workspace, isNotEmpty(query) ? query : '');
            this.medications = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxMedicationSelector.handleSearch', e);
        }
    }

    public async handleSelect(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        if (e.detail.values.customInput as boolean) return;
        const option = e.detail.values as UxComboboxOption;

        const medicaiton = this.medications.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(medicaiton, this.data);
    }

    public async create(): Promise<void> {
        this.combobox.isLoading = true;
        const text = await this.combobox.getText();
        if (isEmpty(text)) {
            this.combobox.isLoading = false;
            return;
        }

        const medication = await this.medicationsApi.create(
            this.workspace,
            new Medication({
                translations: {
                    [this.language]: text
                }
            })
        );

        const response = await this.medicationsApi.search(this.workspace, '');
        const filtered = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
        this.medications = [medication, ...filtered.filter((x) => x.id !== medication.id)];

        this.combobox.setValue(medication.id);

        if (isFunction(this.onSelect)) this.onSelect(medication, this.data);
        this.combobox.isLoading = false;
        this.combobox.close();
    }
}
