<section class="flex flex-col">
    <div class="flex items-center justify-between" class="mb-1">
        <div class="flex items-center space-x-1">
            <ux-label>${ label }</ux-label>
            <span if.bind="required" class="flex h-3 items-center text-red-500">*</span>
        </div>
        <ux-button if.bind="results.length > 0" click.trigger="add()" padding.bind="false" size="xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </ux-button>
    </div>
    <div if.bind="results.length > 0" class="flex flex-col space-y-5 sm:gap-y-2">
        <div class="item mt-2 flex flex-col gap-y-2 rounded-lg bg-gray-50 p-5" repeat.for="result of results">
            <div class="flex items-center gap-x-2">
                <div class="flex flex-1 flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:components.result-range-selector.labels.enter-start"></span>
                    </ux-label>
                    <ux-input valid.bind="validation[$index].start" type="number" value.two-way="result.start" t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                </div>
                <div class="flex flex-1 flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:components.result-range-selector.labels.enter-end"></span>
                    </ux-label>
                    <div class="flex">
                        <ux-input
                            rounded="left"
                            valid.bind="validation[$index].end"
                            type="number"
                            value.two-way="result.end"
                            t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                        <button
                            class.bind="validation[$index].end ? 'border-gray-300' : 'border-red-500'"
                            class="relative -left-[2px] inline-flex h-[38px] items-center rounded-r-lg border bg-white text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1 sm:px-2"
                            click.trigger="remove($index)">
                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <ux-label class="mb-1">
                    <span t="global.labels.description"></span>
                </ux-label>
                <ux-textarea
                    valid.bind="validation[$index].description"
                    action.bind="c => manageTranslations($index)"
                    value.two-way="result.description[language]"
                    rows="1"
                    t="[placeholder]partial-views.medical-examinations.placeholders.description">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                    </svg>
                </ux-textarea>
            </div>
        </div>
    </div>
    <!-- <div else class="flex flex-col items-center justify-center py-5 gap-y-2">
            <p class="text-xs text-gray-500" t="translation:components.choices-selector.messages.no-options-selected"></p>
            <button click.trigger="add()" class="flex items-center px-4 py-2 text-gray-800 border border-gray-300 rounded-full ring-gray-400 ring-offset-2 focus:outline-none focus:ring-1 active:text-gray-500">
                <span class="text-xs" t="translation:components.choices-selector.buttons.add-an-option"></span>
            </button>
        </div> -->
</section>
