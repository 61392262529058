<import from="converters/join" />
<import from="converters/file-name" />
<import from="converters/file-size" />
<import from="converters/blob-to-url" />
<import from="converters/file-to-icon" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div id="scroll-container-uploads" ref="container" class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div
                        class.bind="hasScrolled ? 'border-b': ''"
                        class.bind="view === 'upload' ? 'pb-5': ''"
                        class.bind="view === 'library' ? 'pb-1': ''"
                        class="sticky top-0 z-10 flex flex-col border-gray-200 bg-white pt-8">
                        <div class="mb-3 flex">
                            <button click.trigger="changeView('library')" class="${ view === 'library' ? 'bg-gray-300' : '' } flex-1 rounded-l-lg border border-gray-200 py-1">
                                <span class="text-sm" t="translation:partial-views.upload-files.labels.media-library"></span>
                            </button>
                            <button click.trigger="changeView('upload')" class="${ view === 'upload' ? 'bg-gray-300' : '' } flex-1 rounded-r-lg border-y border-r border-gray-200 py-1">
                                <span class="text-sm" t="translation:partial-views.upload-files.labels.upload"></span>
                            </button>
                        </div>

                        <ux-dropzone
                            show.bind="view === 'upload' && (type === 'single' ? uploads.length === 0 : true)"
                            component.ref="dropzone"
                            disabled.bind="type === 'single' && uploads.length > 0"
                            accepted-files.bind="contentTypes | join:','"
                            valid.bind="validation.uploads"
                            max-files.bind="type === 'single' ? 1 : 0"
                            on-files-selected.bind="filesSelected">
                            <div class="flex flex-1 flex-col items-center justify-center gap-1 p-6">
                                <svg class="h-8 w-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.25 14.25C6.25 14.25 4.75 14 4.75 12C4.75 10.2869 6.07542 8.88339 7.75672 8.75897C7.88168 6.5239 9.73368 4.75 12 4.75C14.2663 4.75 16.1183 6.5239 16.2433 8.75897C17.9246 8.88339 19.25 10.2869 19.25 12C19.25 14 17.75 14.25 17.75 14.25"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    <path d="M14.25 15.25L12 12.75L9.75 15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 19.25V13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <span class="text-center text-sm" t="translation:global.messages.drop-files"></span>
                            </div>
                        </ux-dropzone>
                    </div>
                    <template if.bind="view === 'upload'">
                        <div class="flex flex-1 flex-col space-y-5 pl-1 pt-2">
                            <ul role="list" class="${ type === 'single' ? 'grid-cols-1': 'grid-cols-2' } mt-3 grid gap-5 px-1">
                                <li class="" repeat.for="upload of uploads">
                                    <a
                                        id="upload-${$index}"
                                        href="javascript:"
                                        class.bind="type === 'single' ? 'aspect-w-full aspect-h-30' : ''"
                                        class.bind="type === 'multiple' ? 'aspect-w-10 aspect-h-7' : ''"
                                        class="group block w-full cursor-default overflow-hidden rounded-lg ring-2 ring-gray-300 ring-offset-2">
                                        <div if.bind="upload.progress > 0" class="absolute flex h-full w-full items-center justify-center bg-white opacity-75">
                                            <span class="text-gray-800">${ upload.progress }%</span>
                                        </div>
                                        <template if.bind="isImage(upload.file.type)">
                                            <ux-image
                                                height="${ type === 'single' ? '20rem': '7rem' }"
                                                width="${ type === 'single' ? '100%': '300px' }"
                                                size="cover"
                                                src.bind="upload.file | blobToUrl">
                                            </ux-image>
                                        </template>
                                        <template if.bind="isVideo(upload.file.type)">
                                            <ux-video
                                                height="${ type === 'single' ? '20rem': '7rem' }"
                                                width="${ type === 'single' ? '100%': '300px' }"
                                                size="cover"
                                                src.bind="upload.file | blobToUrl">
                                            </ux-video>
                                        </template>
                                        <template if.bind="!isImage(upload.file.type) && !isVideo(upload.file.type)">
                                            <div class="${ type === 'single' ? 'h-[20rem]': 'h-[7rem]' } flex min-w-16 items-center justify-center rounded-lg bg-gray-100">
                                                <span class="text-indigo-600" innerhtml.bind="upload.extension | fileToIcon:'w-8 h-8'"></span>
                                            </div>
                                        </template>
                                    </a>
                                    <div class="flex min-w-0 items-center space-x-1">
                                        <div class="flex min-w-0 flex-1 flex-col">
                                            <p class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">${ upload.name | fileName }${ upload.extension }</p>
                                            <p class="pointer-events-none block text-sm font-medium text-gray-500">${ upload.file.size | fileSize }</p>
                                        </div>
                                        <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                            <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                </svg>
                                            </div>
                                            <ux-dropdown-option click.trigger="deleteFromUploads($index)">
                                                <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                                            </ux-dropdown-option>
                                        </ux-dropdown>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template else>
                        <div class="flex flex-1 flex-col space-y-5 pb-5 pl-1 pt-2">
                            <ul if.bind="media.length > 0" role="list" class="grid grid-cols-2 gap-5 px-1">
                                <li repeat.for="item of media">
                                    <a
                                        id="media-attachment-${$index}"
                                        class.bind="isSelected(item.attachment) ? 'ring-indigo-600 hover:ring-indigo-400' : 'ring-gray-300'"
                                        href="javascript:"
                                        click.trigger="select($index)"
                                        class="aspect-w-10 aspect-h-10 group relative block w-full overflow-hidden rounded-lg ring-2 ring-offset-2">
                                        <template if.bind="isImage(item.attachment.contentType)">
                                            <div class="h-[10rem] min-w-16 rounded bg-cover bg-center bg-no-repeat" style="background-image: url(${item.url})"></div>
                                        </template>
                                        <template if.bind="isVideo(item.attachment.contentType)">
                                            <video controls="1" loop="0">
                                                <source src.bind="item.url" type.bind="item.attachment.contentType" />
                                            </video>
                                        </template>
                                        <template if.bind="!isImage(item.attachment.contentType) && !isVideo(item.attachment.contentType)">
                                            <div class="flex h-[10rem] min-w-16 items-center justify-center rounded-lg bg-gray-100">
                                                <span class="text-indigo-600" innerhtml.bind="item.attachment.extension | fileToIcon:'w-8 h-8'"></span>
                                            </div>
                                        </template>
                                    </a>
                                    <div class="flex min-w-0 items-center space-x-1">
                                        <div class="flex min-w-0 flex-1 flex-col">
                                            <p class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">${ item.attachment.name | fileName }${item.attachment.extension}</p>
                                            <p class="pointer-events-none block text-sm font-medium text-gray-500">${ item.attachment.size | fileSize }</p>
                                        </div>
                                        <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                            <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                </svg>
                                            </div>
                                            <ux-dropdown-option click.trigger="deleteFromMediaLibrary(item, $index)">
                                                <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                                            </ux-dropdown-option>
                                        </ux-dropdown>
                                    </div>
                                </li>
                            </ul>
                            <div if.bind="media.length === 0" class="flex flex-1 items-center justify-center">
                                <p class="text-sm text-gray-800" t="translation:partial-views.upload-files.messages.no-media-available"></p>
                            </div>
                        </div>
                    </template>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center">
                        <ux-spinner size="xs"></ux-spinner>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex items-center justify-between border-t border-gray-200 bg-white py-4">
                    <div class="flex flex-col">
                        <ux-toggle
                            if.bind="hasImages"
                            size="xl"
                            checked.two-way="useThumbnails"
                            label-active.bind="'translation:global.labels.thumbnails' | t"
                            label-inactive.bind="'translation:global.labels.thumbnails' | t"
                            size="md"
                            color="primary">
                        </ux-toggle>
                        <ux-button else size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="embedLink()">
                            <span class="pointer-events-none block" t="global.buttons.embed-link"></span>
                        </ux-button>
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none block" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button if.bind="view === 'library'" size="sm" color="success" disabled.bind="isLoading || selection.length === 0" loading.bind="isLoading" click.trigger="selectMedia()">
                            <span class="pointer-events-none block" t="global.buttons.select"></span>
                        </ux-button>
                        <ux-button else size="sm" color="success" disabled.bind="isLoading || uploads.length === 0" loading.bind="isLoading" click.trigger="upload()">
                            <span class="pointer-events-none block" t="global.buttons.upload"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
