import { SortDirection } from '@wecore/sdk-core';
import { isEmpty, isNotDefined } from '@wecore/sdk-utilities';

export class SortValueConverter {
    public toView(items: any[], property: string, direction: SortDirection = SortDirection.Asc): any[] {
        if (isNotDefined(items)) return [];
        if (isEmpty(property)) throw new Error('SortValueConverter: property is required');
        if (direction === SortDirection.Asc) return items.orderBy((x) => x[property]);
        return items.orderByDescending((x) => x[property]);
    }
}
