<import from="converters/translate" />
<import from="./template-action-category/template-action-category" />
<import from="./template-action-step/template-action-step" />

<div class="col-span-12 flex flex-col">
    <div class="flex items-center justify-between rounded-lg bg-gray-100 pr-3 text-sm font-medium text-gray-800">
        <a href="javascript:" click.trigger="collapseOrExpand()" class="flex min-w-0 flex-1 flex-col py-2 pl-3">
            <div class="flex flex-1 items-center justify-between">
                <div class="flex min-w-0 items-center space-x-1">
                    <template if.bind="expanded">
                        <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                        </svg>
                    </template>
                    <template else>
                        <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                        </svg>
                    </template>
                    <div class="flex min-w-0 items-center gap-x-3">
                        <span class="text-gray-500" t="translation:global.labels.action"></span>
                        <span class="block truncate">${ registration.action.name | translate:language }</span>
                    </div>
                </div>
                <span class="text-xs text-red-700" if.bind="itemResult.hasPositiveResult" t="translation:global.labels.positive"></span>
                <span class="text-xs text-green-700" else t="translation:global.labels.negative"></span>
            </div>
        </a>
    </div>
    <template if.bind="expanded">
        <div class="ml-1 mt-1 grid grid-cols-12 gap-1 border-l-2 border-gray-300 pl-2">
            <template repeat.for="stepToTake of registration.action.stepsToTake">
                <template if.bind="stepToTake.type === MedicalExaminationActionItemTypes.Category">
                    <template-action-category step-result.bind="stepResult" item.bind="stepToTake" flow.bind="registration.action.flow" language.bind="language"> </template-action-category>
                </template>
                <template if.bind="stepToTake.type === MedicalExaminationActionItemTypes.Step">
                    <template-action-step step-result.bind="stepResult" item.bind="stepToTake" flow.bind="registration.action.flow" language.bind="language"> </template-action-step>
                </template>
            </template>
        </div>
    </template>
</div>
