import { DeclarationPerformanceStatuses } from '@wecore/sdk-healthcare';

export class DeclarationPerformanceStatusToColorValueConverter {
    public toView(type: DeclarationPerformanceStatuses, forBackground: boolean = false): string {
        switch (type) {
            case DeclarationPerformanceStatuses.Draft:
                return forBackground ? 'bg-primary' : 'info';
            case DeclarationPerformanceStatuses.IncludedInDeclaration:
            case DeclarationPerformanceStatuses.PartiallyAccepted:
                return forBackground ? 'bg-yellow-500' : 'warning';
            case DeclarationPerformanceStatuses.SentToVecozo:
            case DeclarationPerformanceStatuses.DownloadedForInfomedics:
                return forBackground ? 'bg-purple-500' : 'secondary';
            case DeclarationPerformanceStatuses.Rejected:
            case DeclarationPerformanceStatuses.RejectedByVecozo:
                return forBackground ? 'bg-red-500' : 'danger';
            case DeclarationPerformanceStatuses.Accepted:
                return forBackground ? 'bg-green-500' : 'success';
        }
    }
}
