<template class="flex flex-col flex-1">
    <div ref="toolbar" if.bind="showToolbar" show.bind="isReacting || hasFiles" invalid.class="!validation.content || !valid" class="toolbar-${guid}">
        <div id="toolbar-container" class="${guid}">
            <!-- <span class="ql-formats">
                        <select class="ql-font"></select>
                             <select className="ql-font" defaultValue="arial">
                                <option value="arial">Arial</option>
                                <option value="comic-sans">Comic Sans</option>
                                <option value="courier-new">Courier New</option>
                                <option value="georgia">Georgia</option>
                                <option value="helvetica">Helvetica</option>
                                <option value="lucida">Lucida</option>
                            </select>
                        <select class="ql-size"></select>
                    </span> -->
            <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
            </span>
            <!-- <span class="ql-formats">
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                </span> -->
            <!-- <span class="ql-formats">
                        <button class="ql-script" value="sub"></button>
                        <button class="ql-script" value="super"></button>
                    </span> -->
            <span class="ql-formats">
                <!-- <button class="ql-header" value="1"></button>
                    <button class="ql-header" value="2"></button> -->
                <!-- <select class="ql-header">
                        <option value="1">Heading 1</option>
                        <option value="2">Heading 2</option>
                        <option value="3">Heading 3</option>
                        <option value="4">Heading 4</option>
                        <option value="5">Heading 5</option>
                        <option value="6">Heading 6</option>
                        <option value>Default</option>
                    </select> -->
                <button class="ql-blockquote"></button>
                <button class="ql-code-block"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
            </span>
            <span class="ql-formats">
                <!-- <button class="ql-direction" value="rtl"></button> -->
                <!-- 
                Enabling this causes the following error when the post is edited once and then trying to edit again:
                TypeError: Cannot read property 'innerHTML' of null at IconPicker.selectItem (quill.js:9693) 
                -->
                <select class="ql-align"></select>
            </span>
            <span class="ql-formats">
                <button class="ql-link"></button>
                <!-- <button class="ql-image"></button>
                    <button class="ql-video"></button> 
                    <button class="ql-formula"></button> -->
            </span>
            <span class="ql-formats">
                <button class="ql-clean"></button>
                <!-- But you can also add your own -->
                <button class="text-red-500" click.trigger="clear()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </span>
        </div>
    </div>
    <div ref="editor" show.bind="isReacting || hasFiles" border-top.class="!showToolbar" no-border.class="hasFiles" invalid.class="!validation.content || !valid"></div>
    <button
        show.bind="!isReacting && !hasFiles"
        click.trigger="focus()"
        class="min-h-[44px] min-w-0 flex items-center text-sm flex-1 rounded-lg border active:bg-gray-200 px-3 focus:outline-none border-gray-300 bg-gray-50 hover:bg-gray-100">
        <span class="block truncate" t="translation:partial-views.user-posts.placeholders.write-reply"></span>
    </button>
</template>
