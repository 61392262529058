<import from="converters/translate" />
<import from="converters/get-ids" />
<import from="converters/currency" />
<import from="bx/bx-examination-room-multi-selector/bx-examination-room-multi-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-50 flex flex-col gap-3 border-b border-gray-200 bg-white pb-3 pt-5">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.manage-appointment-types.create.title"></p>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="request.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.manage-appointment-types.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                    </div>
                    <div class="flex flex-1 flex-col gap-3 pb-5 pt-3">
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-appointment-types.labels.duration"></span>
                            </ux-label>
                            <ux-input
                                type="number"
                                value.two-way="request.duration"
                                oninput.trigger="handleDurationInput($event)"
                                allow.clear.bind="true"
                                valid.bind="validation.duration"
                                t="[placeholder]translation:partial-views.manage-appointment-types.placeholders.add-duration"></ux-input>
                            <p if.bind="!validation.duration" class="text-xs text-red-500" t="translation:partial-views.manage-appointment-types.messages.invalid-duration"></p>
                        </div>
                        <div class="flex h-32 flex-col rounded-xl p-5" css="background-color: ${request.color.background}; color: ${request.color.text}">
                            <div class="flex gap-1 text-sm">
                                <span>12345</span>
                                <span>John Doe</span>
                            </div>
                            <div class="flex gap-1 text-xs">
                                <span>09:00 - 09:45</span>
                            </div>
                            <div class="flex gap-1 text-xs">
                                <span>${request.name[language]}</span>
                            </div>
                        </div>
                        <div class="flex gap-2">
                            <div class="flex flex-1 flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-appointment-types.labels.background-color"></span>
                                </ux-label>
                                <ux-color-picker value.two-way="request.color.background"></ux-color-picker>
                            </div>
                            <div class="flex flex-1 flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-appointment-types.labels.text-color"></span>
                                </ux-label>
                                <ux-color-picker value.two-way="request.color.text" show-palette.bind="false"></ux-color-picker>
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-appointment-types.labels.linked-rooms"></span>
                            </ux-label>
                            <bx-examination-room-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleRoomSelected"
                                language.bind="language"
                                t="[placeholder]translation:partial-views.manage-appointment-types.placeholders.select-linked-rooms"
                                values.bind="request.rooms | getIds">
                            </bx-examination-room-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-appointment-types.labels.appointment-confirmation"></span>
                            </ux-label>
                            <bx-email-template-selector
                                allow-clear.bind="true"
                                on-select.bind="handleTemplateSelected"
                                language.bind="language"
                                types.bind="['Other', 'Patient']"
                                value.bind="type.templateForConfirmation.id"
                                workspace.bind="authenticated.workspace.id"
                                t="[placeholder]translation:partial-views.manage-appointment-types.placeholders.select-email-template">
                            </bx-email-template-selector>
                        </div>
                        <div class="flex flex-col">
                            <div mb-2.class="request.prices.length > 0" mb-1.class="request.prices.length === 0" class="flex items-center justify-between">
                                <ux-label>
                                    <span t="translation:partial-views.manage-appointment-types.labels.prices"></span>
                                </ux-label>
                                <ux-button if.bind="request.prices.length > 0" click.trigger="createOrEditPrice()" padding.bind="false" size="xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </ux-button>
                            </div>
                            <div class="flex" if.bind="request.prices.length === 0">
                                <button
                                    click.trigger="createOrEditPrice()"
                                    type="button"
                                    class="relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                    <svg class="h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <span class="block text-sm font-semibold text-gray-900" t="translation:partial-views.manage-appointment-types.buttons.add-price"></span>
                                </button>
                            </div>
                            <ul else class="flex flex-col gap-1">
                                <li class="flex" repeat.for="price of request.prices">
                                    <ux-card>
                                        <div class="relative flex items-center rounded-lg border-l border-t border-gray-200">
                                            <button
                                                click.trigger="createOrEditPrice($index)"
                                                class="flex h-full flex-1 items-center gap-6 rounded-l-lg px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                <div class="flex items-center text-sm">
                                                    <p class="mr-3 text-gray-800">${price.netValue | currency}</p>
                                                    <p class="text-xs text-gray-500">${formatDescription($index)}</p>
                                                </div>
                                            </button>
                                            <button
                                                click.trigger="removePrice($index)"
                                                class="flex w-10 items-center justify-center rounded-r-lg border-l border-gray-200 bg-white p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100"
                                                click.trigger="delete($index)">
                                                <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </ux-card>
                                </li>
                            </ul>
                        </div>

                        <div class="flex flex-col">
                            <div mb-2.class="request.codes.length > 0" mb-1.class="request.codes.length === 0" class="flex items-center justify-between">
                                <ux-label>
                                    <span t="translation:global.labels.healthcare-codes"></span>
                                </ux-label>
                                <ux-button if.bind="request.codes.length > 0" click.trigger="addCode()" padding.bind="false" size="xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </ux-button>
                            </div>
                            <ul if.bind="request.codes.length > 0" class="flex flex-col space-y-1">
                                <li repeat.for="code of request.codes" class="flex w-full">
                                    <div class="relative flex flex-1 items-center rounded-lg bg-gray-50">
                                        <button
                                            click.trigger="editCode($index)"
                                            class="flex h-full flex-1 items-center gap-6 rounded-l-lg border-y border-l border-gray-200 px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                            <div class="flex items-center text-sm">${code.system}/${code.value}</div>
                                        </button>
                                        <button
                                            click.trigger="removeCode($index)"
                                            class="flex w-10 items-center justify-center rounded-r-lg border border-gray-200 bg-gray-50 p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                            </ul>
                            <button
                                else
                                click.trigger="addCode($index)"
                                type="button"
                                class="text-gray-800.class relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                                </svg>

                                <span class="block text-sm font-semibold" t="translation:partial-views.medical-step-settings.buttons.add-code"></span>
                            </button>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-[999] flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
