<import from="components/component-medications-selector/component-medications-selector" />
<import from="components/component-medical-equipments-selector/component-medical-equipments-selector" />
<import from="bx/bx-medical-history-selector/bx-medical-history-selector" />

<section class="flex flex-col flex-1 px-6 pt-6 overflow-y-auto">
    <div class="grid grid-cols-12 gap-2">
        <div class="flex flex-col flex-1 col-span-12 space-y-5">
            <bx-medical-history-selector
                registrations.two-way="patient.medicalHistory"
                validation.bind="validation.medicalHistory"
                manage-translations.bind="manageTranslations"
                workspace.bind="workspace"
                language.bind="language">
            </bx-medical-history-selector>
            <component-medications-selector
                registrations.two-way="patient.medications"
                validation.bind="validation.medications"
                manage-translations.bind="manageTranslations"
                workspace.bind="workspace"
                language.bind="language">
            </component-medications-selector>
            <component-medical-equipments-selector
                registrations.two-way="patient.medicalEquipment"
                validation.bind="validation.medicalEquipment"
                manage-translations.bind="manageTranslations"
                workspace.bind="workspace"
                language.bind="language">
            </component-medical-equipments-selector>
        </div>
    </div>
</section>
