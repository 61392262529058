<section class="overflow-hidden confirm-modal min-h-64">
    <ux-dialog class="flex flex-col w-screen overflow-hidden rounded-none md:h-auto md:rounded-lg">
        <div class="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen p-4 text-center md:items-center">
                <div class="fixed inset-0 transition-opacity bg-gray-500/50" aria-hidden="true"></div>
                <span class="hidden h-full sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
                <div class="inline-block w-full p-6 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-2xl sm:p-6 sm:align-middle">
                    <div class="absolute top-0 right-0 hidden pt-5 pr-4 sm:block">
                        <button
                            click.trigger="cancel()"
                            type="button"
                            class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2">
                            <span class="sr-only">Close</span>
                            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div class="${ result === 'success' ? 'bg-green-100' : 'bg-red-100' } mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                class="${ result === 'success' ? 'text-green-500' : 'text-red-500' } h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <div if.bind="result === 'success'" class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title" t="translation:modals.save-result.labels.title-success"></h3>
                            <div class="mt-2">
                                <p class="pr-3 text-sm text-gray-500" t="translation:modals.save-result.messages.success"></p>
                            </div>
                        </div>
                        <div else class="flex flex-col mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title" t="translation:modals.save-result.labels.title-error"></h3>
                            <div class="mt-2">
                                <p class="pr-3 text-sm text-gray-500" t="translation:modals.save-result.messages.error"></p>
                            </div>
                            <div class="flex flex-col gap-2 p-4 mt-3 mb-5 text-sm text-red-800 rounded-lg bg-red-50">
                                <div>
                                    <span if.bind="step" class="font-bold">${step}</span>
                                    <span>${error.message}</span>
                                </div>
                                <div class="pt-2 border-t border-red-100">${error.stack}</div>
                            </div>
                            <div class="flex flex-col gap-2">
                                <ux-button disabled.bind="sent" click.trigger="sendError()" loading.bind="sending" color="primary">
                                    <span t="translation:modals.save-result.buttons.send"></span>
                                </ux-button>
                                <span if.bind="sent" class="text-sm text-green-600" t="translation:modals.save-result.messages.error-sent"></span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5 gap-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                        <ux-button color="primary" loading.bind="isLoading" click.trigger="ok()">
                            <span t="translation:global.buttons.close"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </ux-dialog>
</section>
