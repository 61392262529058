import { PartialViewResults } from '../enums/partial-view-results';
import { View } from './view';

export interface IPartialView {
    name: string;
    displayName: string;
    route?: string;
    parents?: string[];
    width: number;
    minWidth: number;
    maxWidth: number;
    component: any;
    allowMultiple: boolean;
    addedByBaseView?: boolean;
    canAdjustWidth?: boolean;
}

export class PartialView {
    /**
     * A unique identifier for the partial view.
     */
    public id: string;

    /**
     * The non unique ID of the partial view.
     */
    public name: string;

    /**
     * The display name of the partial view.
     */
    public displayName: string;

    /**
     * The route of the partial view.
     */
    public route: string;

    /**
     * The the name of the parent of the partial view.
     */
    public parents: string[];

    /**
     * The fixed width of the partial view.
     */
    public width: number;

    /**
     * The minimum width of the partial view.
     */
    public minWidth: number;

    /**
     * The maximum width of the partial view.
     */
    public maxWidth: number;

    /**
     * Optional view data.
     */
    public data: any;

    /**
     * The index of the view in the view engine.
     */
    public index: number;

    /**
     * A flag indicating whether or not this partial view can be added to view engine multiple times.
     */
    public allowMultiple: boolean;

    /**
     * A reference to the base view the partial view is added to.
     */
    public base: View;

    /**
     * A reference to the base view or partial view that added the partial view to the view engine.
     */
    public addedBy: View | PartialView;

    /**
     * A flag indicating whether or not the partial view was added by the base view.
     */
    public addedByBaseView: boolean;

    /**
     * A flag indicating whether or not the partial view can be adjusted in width.
     */
    public canAdjustWidth: boolean = false;

    /**
     * A callback that is triggered when the view is closed and removed from the view engine.
     */
    public handleWhenClosed: (result: PartialViewResults, data: any) => Promise<void>;

    public component: any;

    public constructor(data: IPartialView) {
        this.name = data.name;
        this.parents = data.parents || [];
        this.displayName = data.displayName;
        this.route = data.route;
        this.width = data.width;
        this.minWidth = data.minWidth;
        this.maxWidth = data.maxWidth;
        this.allowMultiple = data.allowMultiple;
        this.addedByBaseView = data.addedByBaseView;
        this.component = data.component;
        this.canAdjustWidth = data.canAdjustWidth ?? false;
    }

    public with(data: any): PartialView {
        this.data = data;
        return this;
    }

    public whenClosed(cb: (result: PartialViewResults, data: any) => Promise<void>): PartialView {
        this.handleWhenClosed = cb;
        return this;
    }

    public setRoute(route: string): PartialView {
        this.route = route;
        return this;
    }
}
