<template class="my-0 flex">
    <div class="relative flex flex-1 justify-center">
        <div if.bind="active" class="absolute left-0 top-1/2 h-8 w-1 -translate-y-1/2 transform rounded-r-full bg-primary"></div>
        <a
            href.bind="href"
            target.bind="target"
            class="flex h-[2.75rem] w-[2.75rem] items-center justify-center rounded-xl text-gray-800"
            bg-gray-100.class="active"
            hover:bg-gray-100.class="!active"
            href.bind="href">
            <au-slot />
        </a>
    </div>
</template>
