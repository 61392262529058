import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AnatomicalRegionEntityReference,
    CreateDifferentialDiagnosisRequest,
    DifferentialDiagnosesApiClient,
    DifferentialDiagnosisCategoryEntityReference,
    DifferentialDiagnosisEntityReference,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisCategoryResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationPhaseResponse,
    HealthcareCode,
    InformationSheet,
    MedicalExaminationPhaseEntityReference
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, DifferentialDiagnosesApiClient)
export class PartialDifferentialDiagnosesCreate extends BasePartialView {
    public request: CreateDifferentialDiagnosisRequest = new CreateDifferentialDiagnosisRequest({
        displayOrder: 0,
        keywords: [],
        codes: [],
        healthcareSectors: [],
        distinguishFrom: [],
        occursWith: [],
        synonyms: []
    });
    public validation = {
        name: true,
        displayOrder: true,
        phase: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.description = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
                await super.handleBaseLoaded();
            })
            .catch((x) => this.errorHandler.handle('PartialDifferentialDiagnosesCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.request.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public handleRegionSelected = async (region: GetAnatomicalRegionResponse): Promise<void> => {
        this.request.anatomicalRegion = new AnatomicalRegionEntityReference({
            id: region.id,
            translations: region.name
        });
    };

    public handleCategoriesChanged = async (categories: GetDifferentialDiagnosisCategoryResponse[]): Promise<void> => {
        this.request.categories = categories.map(
            (c) =>
                new DifferentialDiagnosisCategoryEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public handleDistinguishFromChanged = async (dds: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        this.request.distinguishFrom = dds.map(
            (c) =>
                new DifferentialDiagnosisEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public handleOccursWithChanged = async (dds: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        this.request.occursWith = dds.map(
            (c) =>
                new DifferentialDiagnosisEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.request.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.request.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.request.codes[data.index] = data.code;
                    this.request.codes = [
                        ...(this.request.codes.length > 0 ? [this.request.codes.shift()] : []), //
                        ...this.request.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.request.codes.splice(index, 1);
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.request, 1);
                await this.diagnosesApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.differential-diagnoses.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.differential-diagnoses.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[create-differential-diagnosis-list]', e);
            }
        }
    }

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl: false
            })
        });
    }

    public toSet(synonyms: string[]): { value: string; text: string; data?: any }[] {
        return synonyms.map((x: string) => ({ value: x, text: x }));
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        this.validation.phase = isDefined(this.request.phase);

        return validateState(this.validation);
    }
}
