import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    BodySides,
    DifferentialDiagnosesApiClient,
    GetDifferentialDiagnosisResponse,
    GetMedicalRecordResponse,
    GetMedicalTherapyResponse,
    GetMedicalTreatmentProtocolResponse,
    MedicalExaminationActionEntityReference,
    MedicalExaminationActionsApiClient,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalQuestionEntityReference,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient,
    MedicalRecordQueueItem,
    MedicalRecordQueueItemActions,
    MedicalRecordQueueItemTypes,
    MedicalRecordsApiClient,
    MedicalTherapiesApiClient,
    MedicalTreatmentProtocolsApiClient,
    SuggestDifferentialDiagnosesRequest,
    SuggestDifferentialDiagnosesResponseItem
} from '@wecore/sdk-healthcare';
import { guid } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    DifferentialDiagnosesApiClient,
    MedicalTreatmentProtocolsApiClient,
    MedicalTherapiesApiClient,
    MedicalExaminationActionsApiClient,
    MedicalQuestionsApiClient,
    MedicalQuestionnairesApiClient,
    MedicalRecordsApiClient,
    ModalService
)
export class PartialMedicalRecordsQueue extends BasePartialView {
    public MedicalExaminationTemplateItemStepTypes: typeof MedicalExaminationTemplateItemStepTypes = MedicalExaminationTemplateItemStepTypes;
    public MedicalRecordQueueItemActions: typeof MedicalRecordQueueItemActions = MedicalRecordQueueItemActions;
    public items: {
        diagnosis: GetDifferentialDiagnosisResponse;
        suggestion: SuggestDifferentialDiagnosesResponseItem;
        steps: MedicalExaminationTemplateItemStep[];
        side: BodySides;
        expanded: boolean;
        protocols: {
            protocol: GetMedicalTreatmentProtocolResponse;
            therapies: GetMedicalTherapyResponse[];
        }[];
    }[] = [];

    private recordId: string;
    private record: GetMedicalRecordResponse;
    private queue: MedicalRecordQueueItem[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient,
        private readonly protocolsApi: MedicalTreatmentProtocolsApiClient,
        private readonly therapiesApi: MedicalTherapiesApiClient,
        private readonly actionsApi: MedicalExaminationActionsApiClient,
        private readonly questionsApi: MedicalQuestionsApiClient,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient,
        private readonly recordsApi: MedicalRecordsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.recordId = view.data.id;
        this.queue = view.data.queue ?? [];
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const ids = this.queue.filter((x) => x.entityType === MedicalRecordQueueItemTypes.DifferentialDiagnosis).map((x) => x.entityId);
                const [record, suggestions] = await Promise.all([
                    this.recordsApi.getById(this.recordId, this.authenticated.workspace.id),
                    this.diagnosesApi.suggest(this.authenticated.workspace.id, ids.length, 0, new SuggestDifferentialDiagnosesRequest({ ids }))
                ]);
                this.record = record;

                // Fetch the full data of the selected diagnoses and their protocols.
                const diagnosisIds = suggestions.map((x) => x.id);
                const [diagnoses, protocols] = await Promise.all([
                    this.diagnosesApi.search(this.authenticated.workspace.id, '', diagnosisIds.length, 0, undefined, undefined, undefined, diagnosisIds),
                    this.protocolsApi.search(this.authenticated.workspace.id, '', diagnosisIds.length * 25, 0, undefined, undefined, undefined, diagnosisIds)
                ]);

                // Get all the full data of the therapies linked in the protocols
                const therapyIds = protocols.data //
                    .selectMany((x: GetMedicalTreatmentProtocolResponse) => x.therapies)
                    .map((x) => x.therapy.id);
                const therapies = await this.therapiesApi.search(this.authenticated.workspace.id, '', therapyIds.length, 0, undefined, undefined, undefined, therapyIds);

                /**
                 * Fetches the full data of all the linked steps.
                 * @param suggestion The suggested suggestion holding the linked ids.
                 */
                const prepareSuggestions = async (queueItem: MedicalRecordQueueItem, suggestion: SuggestDifferentialDiagnosesResponseItem): Promise<void> => {
                    // Get the full data of the diagnosis.
                    const diagnosis = diagnoses.data.find((x) => x.id === suggestion.id);
                    // Filter out the different type of linked ids.
                    const actionIds = suggestion.linked.filter((x) => x.type === 'MedicalExaminationAction').map((x) => x.id);
                    const questionIds = suggestion.linked.filter((x) => x.type === 'MedicalQuestion').map((x) => x.id);
                    const questionnaireIds = suggestion.linked.filter((x) => x.type === 'MedicalQuestionnaire').map((x) => x.id);
                    // Fetch the full data of each of the linked ids.
                    const [actions, questions, questionnaires] = await Promise.all([
                        this.actionsApi.search(this.authenticated.workspace.id, undefined, actionIds.length, 0, undefined, undefined, undefined, actionIds),
                        this.questionsApi.search(this.authenticated.workspace.id, undefined, questionIds.length, 0, undefined, undefined, undefined, undefined, undefined, questionIds),
                        this.questionnairesApi.search(this.authenticated.workspace.id, undefined, questionnaireIds.length, 0, undefined, undefined, undefined, questionnaireIds)
                    ]);

                    const item: {
                        diagnosis: GetDifferentialDiagnosisResponse;
                        suggestion: SuggestDifferentialDiagnosesResponseItem;
                        steps: MedicalExaminationTemplateItemStep[];
                        expanded: boolean;
                        side: BodySides;
                        protocols: {
                            protocol: GetMedicalTreatmentProtocolResponse;
                            therapies: GetMedicalTherapyResponse[];
                        }[];
                    } = {
                        diagnosis, //
                        suggestion,
                        steps: [],
                        side: queueItem.side,
                        expanded: false,
                        protocols: protocols.data //
                            .filter((protocol) => protocol.differentialDiagnosis.id === diagnosis.id)
                            .map((protocol) => ({
                                protocol,
                                therapies: protocol.therapies.map((item) => therapies.data.find((x) => x.id === item.therapy.id))
                            }))
                    };

                    item.steps = [
                        // First add the previously added steps.
                        ...item.steps, //
                        // Map each fetched action to an examination step.
                        ...actions.data.map(
                            (action) =>
                                new MedicalExaminationTemplateItemStep({
                                    id: guid(),
                                    attributes: { action },
                                    type: MedicalExaminationTemplateItemStepTypes.Action,
                                    action: new MedicalExaminationActionEntityReference({
                                        id: action.id,
                                        translations: action.name
                                    }),
                                    isDynamicallyAdded: true
                                })
                        ),
                        // Map each fetched question to an examination step.
                        ...questions.data.map(
                            (question) =>
                                new MedicalExaminationTemplateItemStep({
                                    id: guid(),
                                    attributes: { question },
                                    type: MedicalExaminationTemplateItemStepTypes.Question,
                                    question: new MedicalQuestionEntityReference({
                                        id: question.id,
                                        translations: question.name
                                    }),
                                    isDynamicallyAdded: true
                                })
                        ),
                        // Map each fetched quesionnaire to an examination step.
                        ...questionnaires.data.map(
                            (questionnaire) =>
                                new MedicalExaminationTemplateItemStep({
                                    id: guid(),
                                    attributes: { questionnaire },
                                    type: MedicalExaminationTemplateItemStepTypes.Questionnaire,
                                    questionnaire: new MedicalQuestionEntityReference({
                                        id: questionnaire.id,
                                        translations: questionnaire.name
                                    }),
                                    isDynamicallyAdded: true
                                })
                        )
                    ];

                    this.items.push(item);
                };

                // Fetch the full data of the linked entities for all the selected suggestions.
                await Promise.all(
                    this.queue.map((item) => {
                        const suggestion = suggestions.find((x) => x.id === item.entityId);
                        return prepareSuggestions(item, suggestion);
                    }) //
                );

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalRecordsQueue.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
    }

    public toggleSteps(index: number): void {
        this.items[index].expanded = !this.items[index].expanded;
    }

    public async processQueue(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.process-queue.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.process-queue.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.process'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        // Note: The processing of the queue is done in the Save() method
                        // of the medical record partial view.
                        this.events.publish(CustomEvents.MedicalRecordUpdated, {
                            // Do NOT override the record by provided the record here. The record
                            // might have a local queue that is not yet processed. So by fetching
                            // the record in the attached method we could miss the local queue.
                            // record: this.record
                        });
                        this.remove({ result: PartialViewResults.Ok });
                    }
                }
            })
        );
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }
}
