<import from="converters/healthcare-sector-to-string" />
<import from="converters/get-values" />

<div class="flex w-full flex-col">
    <div class="mb-1 flex flex-col">
        <ux-combobox
            focused.two-way="focused"
            allow-custom-input.bind="mode === 'input'"
            set-value-on-select.bind="false"
            placeholder.bind="placeholder"
            component.ref="selector"
            show-footer.bind="showFooter"
            search-placeholder.bind="searchPlaceholder"
            disabled.bind="disabled"
            valid.bind="valid"
            multiple.bind="true"
            autocomplete.bind="autocomplete"
            onselect.trigger="handleSelected($event)"
            onsearch.trigger="handleSearch($event)"
            onclose.trigger="handleClose($event)">
            <ux-combobox-option selected.bind="values.some((x) => x === option.value)" repeat.for="option of options" value.bind="option.value">
                <span if.bind="itemTemplate" innerhtml.bind="itemTemplate(option)"></span>
                <span else>${ option.text }</span>
            </ux-combobox-option>
            <div if.bind="showFooter" au-slot="footer" class="flex w-full items-center gap-x-1 px-3 py-2">
                <svg class="h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                <p if.bind="focused !== -1 && options.length > 0" class="text-xs text-gray-500" t="translation:global.messages.press-enter-to-add-option"></p>
                <p else class="text-xs text-gray-500" t="translation:global.messages.press-enter-to-add-text"></p>
            </div>
        </ux-combobox>
    </div>
    <ul class="flex flex-col gap-1" if.bind="allOptions">
        <li repeat.for="value of values">
            <template if.bind="onValueClick">
                <div class="flex flex-1 items-center gap-1">
                    <button
                        bg-gray-50.class="background === 'gray'"
                        hover:bg-gray-100.class="background === 'gray'"
                        active:bg-gray-200.class="background === 'gray'"
                        bg-white.class="background === 'white'"
                        hover:bg-gray-100.class="background === 'white'"
                        active:bg-gray-200.class="background === 'white'"
                        click.trigger="handleValueClicked(value)"
                        class="h-[38px] min-w-0 flex-1 rounded-lg border border-gray-300 px-3 text-left text-sm focus:outline-none">
                        <span class="block truncate" innerhtml.bind="getText(value)"></span>
                    </button>
                    <template if.bind="!disabled || allowClearWhileDisabled">
                        <button click.trigger="remove($index, value)" class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500 hover:bg-gray-100 active:bg-gray-200">
                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        </button>
                    </template>
                </div>
            </template>
            <template else>
                <div class="group flex min-w-0 justify-between gap-x-1 text-left text-sm">
                    <div
                        bg-gray-50.class="background === 'gray'"
                        bg-white.class="background === 'white'"
                        h-[38px].class="itemTemplate === null || itemTemplate === undefined"
                        class="flex flex-1 items-center truncate rounded-lg border border-gray-300 px-3 py-2">
                        <span if.bind="itemTemplate" class="w-full" innerhtml.bind="itemTemplate(getOption(value))"></span>
                        <span class="truncate" else innerhtml.bind="getText(value)"></span>
                    </div>
                    <template if.bind="!disabled || allowClearWhileDisabled">
                        <button
                            click.trigger="remove($index, value)"
                            h-[38px].class="itemTemplate === null || itemTemplate === undefined"
                            class="items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500 hover:bg-gray-100 active:bg-gray-200">
                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        </button>
                    </template>
                </div>
            </template>
        </li>
        <li if.bind="showNoResult" class="py-1" if.bind="values.length === 0">
            <p class="text-left text-sm" t="translation:global.messages.no-selection-made"></p>
        </li>
    </ul>
</div>
