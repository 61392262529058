import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient, BlobStorageAttachment, RenameAttachmentRequest } from '@wecore/sdk-attachments';
import { GetMedicalRecordResponse } from '@wecore/sdk-healthcare';
import { isDefined, isFunction } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { format, intervalToDuration } from 'date-fns';
import WaveSurfer from 'wavesurfer.js';
import { ModalRenameValue } from '../../../../../../modals/modal-rename-value/modal-rename-value';
import { SelectedFile } from '../../../../../../models/selected-file';
import { ModalService } from '../../../../../../services/service.modals';

@containerless
@inject(ModalService, I18N, AttachmentsApiClient)
export class TemplateRecording {
    @bindable() public id: string;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public workspace: string;
    @bindable() public upload: SelectedFile;
    @bindable() public attachment: BlobStorageAttachment;
    @bindable() public url: string;
    @bindable() public onUploadDelete: (upload: SelectedFile) => Promise<void>;
    @bindable() public onAttachmentDelete: (attachment: BlobStorageAttachment) => Promise<void>;

    public state: 'ready' | 'pauzed' | 'playing' = 'ready';
    public speed: number = 2;
    public duration: string;
    public current: string;
    private waveform: WaveSurfer;

    public constructor(
        private readonly modalService: ModalService,
        private readonly t: I18N,
        private readonly attachmentsApi: AttachmentsApiClient //
    ) {}

    public attached(): void {
        const url = isDefined(this.upload) ? URL.createObjectURL(this.upload.file) : this.url;
        const waveform = WaveSurfer.create({
            container: `#recording-${this.id}`,
            waveColor: '#F4C237',
            progressColor: '#F25366',
            height: 50,
            url,
            autoScroll: true,
            hideScrollbar: true,
            barWidth: 2,
            barGap: 1,
            barRadius: 2,
            audioRate: 1
        });

        waveform.on('finish', () => (this.state = 'ready'));
        waveform.on('audioprocess', () => {
            if (waveform.isPlaying()) {
                this.current = this.formatTimestamp(waveform.getCurrentTime());
                this.duration = this.formatTimestamp(waveform.getDuration());
            }
        });

        waveform.on('ready', () => {
            this.current = this.formatTimestamp(0);
            this.duration = this.formatTimestamp(waveform.getDuration());
        });

        this.waveform = waveform;
    }

    public handleDelete(): void {
        if (isFunction(this.onUploadDelete)) this.onUploadDelete(this.upload);
        if (isFunction(this.onAttachmentDelete)) this.onAttachmentDelete(this.attachment);
    }

    public toggleStartOrPauze(): void {
        if (this.state === 'ready' || this.state === 'pauzed') {
            this.state = 'playing';
            this.waveform.play();
        } else {
            this.state = 'pauzed';
            this.waveform.pause();
        }
    }

    public async rename(): Promise<void> {
        const res = await this.modalService.open(ModalRenameValue, {
            value: isDefined(this.upload) ? this.upload.name : this.attachment.name
        });

        if (res.status === 'cancel') return;
        const name = res.value as string;
        if (isDefined(this.upload)) {
            this.upload.name = `${name}${this.upload.extension}`;
            this.upload.statusLabel = this.t
                .tr('translation:partial-views.clinical-pathways.labels.status-waiting') //
                .replace('{entity}', `<span class="font-medium block mx-1">${name}</span>`);
        } else {
            this.attachment.name = name;

            const index = this.record.attachments.findIndex((a) => a.id === this.attachment.id);
            const attachment = await this.attachmentsApi.rename(
                this.record.id, //
                this.attachment.id,
                this.workspace,
                AttachmentEntities.MedicalRecords,
                new RenameAttachmentRequest({ name })
            );
            this.record.attachments[index] = attachment;
        }
    }

    public handleSpeedChange(): void {
        const speeds = [0.25, 0.5, 1, 1.5, 2, 3];
        const speed = speeds[Number(this.speed)];
        this.waveform.setPlaybackRate(speed, true);
    }

    private formatTimestamp(time: number): string {
        // Format the current time to mm:ss. In case of hours, format to HH:mm:ss
        const duration = intervalToDuration({
            start: 0,
            end: time * 1000
        });
        const formatString = duration.hours > 0 ? 'H:mm:ss' : 'm:ss';
        return format(new Date(0, 0, 0, duration.hours, duration.minutes, duration.seconds, 0), formatString);
    }
}
