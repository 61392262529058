import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { CreateHealthcareTaskLabelRequest, HealthcareTaskLabelTypes, HealthcareTaskLabelsApiClient } from '@wecore/sdk-healthcare';
import { isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareTaskLabelsApiClient)
export class PartialTaskLabelsCreate extends BasePartialView {
    public request: CreateHealthcareTaskLabelRequest;
    public HealthcareTaskLabelTypes: typeof HealthcareTaskLabelTypes = HealthcareTaskLabelTypes;
    public validation = {
        name: true,
        displayOrder: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly labelsApi: HealthcareTaskLabelsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request = new CreateHealthcareTaskLabelRequest({
            name: setTranslation({}, this.language),
            description: setTranslation({}, this.language),
            backgroundColor: '#e5e7eb',
            textColor: '#1f2937',
            displayOrder: 0,
            type: view.data?.type ?? HealthcareTaskLabelTypes.Label
        });
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialTaskLabelsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['title', 'description'], this.request, 1);
                await this.labelsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr(`translation:partial-views.tasks.notifications-${this.request.type.toLowerCase()}.save-successful.title`),
                    this.t
                        .tr(`translation:partial-views.tasks.notifications-${this.request.type.toLowerCase()}.save-successful.message`) //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle(`[create-healthcare-task-label-${this.request.type}]`, e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        return validateState(this.validation);
    }
}
