import { MedicalResult, ResultTypes } from '@wecore/sdk-healthcare';
import { guid, isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { cloneDeep } from '../../infra/utilities';

@containerless
export class ComponentChoicesSelector {
    @bindable() public choices: MedicalResult[];
    @bindable() public validation: any[];
    @bindable() public label: string;
    @bindable() public required: boolean = false;
    @bindable() public showScores: boolean = false;
    @bindable() public type: ResultTypes;
    @bindable() public onChoiceChanged: () => void;
    @bindable() public onChoiceRemoved: () => void;

    public container: HTMLDivElement;
    public isDragging: boolean = false;
    public ResultTypes: typeof ResultTypes = ResultTypes;
    public elements: HTMLDivElement[] = [];

    public element: HTMLDivElement;
    public index: number;

    private moveCb: (e: MouseEvent) => void;
    private moveEndCb: (e: MouseEvent) => void;
    private moveKeyCb: (e: KeyboardEvent) => void;
    private debounce: NodeJS.Timeout;
    private placeholder: HTMLDivElement;
    private displayOrderIncrement: number = 100;

    public attached(): void {
        if (this.choices.empty()) this.add();

        // If all choices have a displayOrder of 0, we need to set them.
        if (this.choices.every((x) => x.displayOrder === 0)) {
            this.choices.forEach((x, i) => (x.displayOrder = (i + 1) * this.displayOrderIncrement));
        }

        const placeholder = document.createElement('div');
        placeholder.id = 'placeholder-choices';
        placeholder.classList.add('col-span-12', 'border', 'border-dashed', 'mt-2', 'ml-2', 'rounded-lg', 'border-gray-300', 'h-[38px]');
        this.placeholder = placeholder;
    }

    public add(): void {
        const choice = new MedicalResult({
            id: guid(),
            valueType: this.type,
            displayOrder:
                isDefined(this.choices) && this.choices.any()
                    ? this.choices.orderByDescending((x: MedicalResult) => x.displayOrder)[0].displayOrder + this.displayOrderIncrement
                    : this.displayOrderIncrement
        });

        this.validation.push({
            id: choice.id,
            value: true,
            score: true,
            numeric: true,
            unique: true
        });

        this.choices.push(choice);

        // Force array to re-render.
        this.choices = [
            ...(this.choices.length > 0 ? [this.choices.shift()] : []), //
            ...cloneDeep(this.choices)
        ];

        this.handleChoiceChanged();
    }

    public remove(index: number): void {
        const choice = this.choices[index];
        this.choices.splice(index, 1);

        // Match choice by id and remove it from the validation array.
        const vIndex = this.validation.findIndex((x) => x.id === choice.id);
        this.validation.splice(vIndex, 1);

        if (this.choices.empty()) this.add();

        if (isFunction(this.onChoiceRemoved)) this.onChoiceRemoved();
    }

    public clear(): void {
        this.choices = [];
        this.validation = [];

        this.handleChoiceChanged();
    }

    public handleChoiceChanged(): void {
        if (isFunction(this.onChoiceChanged)) this.onChoiceChanged();
    }

    public handleDragStart = async (e: MouseEvent): Promise<void> => {
        // Prevent other events from firing. E.g. the drag and select events.
        e.preventDefault();
        // Clone the appointment element.
        const clone = this.element.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${this.index}`;
        clone.style.position = 'absolute';
        clone.style.zIndex = '10000';
        clone.style.opacity = '90%';
        clone.style.width = `${this.element.clientWidth}px`;
        // Set the left position relative to the container left position and mouse position.
        clone.style.top = `${e.clientY - 40}px`;
        clone.style.left = `${e.clientX}px`;
        // Add the clone to the active container.
        document.body.appendChild(clone);
    };

    public handleDrag = async (e: MouseEvent): Promise<void> => {
        // Find the clone.
        const clone = document.querySelector(`#clone-${this.index}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;
        // Set the left position relative to the container left position and mouse position.
        clone.style.top = `${e.clientY - 45}px`;
        clone.style.left = `${e.clientX + 16}px`;
        // Find the element after which the clone should be placed.
        const afterElement = this.getDragAfterElement(this.container, e.clientY);
        // Add the placeholder while hovering to indicate where the clone will be placed.
        if (isNotDefined(afterElement)) this.container.appendChild(this.placeholder);
        else this.container.insertBefore(this.placeholder, afterElement);
        // Hide the original element after adding the placeholder.
        this.element.classList.add('hidden');
    };

    public handleDragEnd = async (_: MouseEvent): Promise<void> => {
        // Remove the clone.
        document.querySelector(`#clone-${this.index}`)?.remove();
        // Use the placeholder to find the correct choice.
        const placeholder = this.container.querySelector(`#placeholder-choices`);
        // Get the elements after the placeholder and before the placeholder.
        const previousElement = placeholder.previousElementSibling as HTMLDivElement;
        const nextElement = placeholder.nextElementSibling as HTMLDivElement;

        const previous = Number(previousElement?.getAttribute('data-order') ?? 0);
        const next = Number(nextElement?.getAttribute('data-order') ?? 0);
        let displayOrder = 0;
        if (next > 0) displayOrder = (previous + next) / 2;
        else displayOrder = Number(previous) + this.displayOrderIncrement;

        this.choices[this.index].displayOrder = displayOrder;

        this.choices = [
            ...(this.choices.length > 0 ? [this.choices.shift()] : []), //
            ...cloneDeep(this.choices)
        ];

        // We don't need the placeholder anymore.
        placeholder.remove();
    };

    public handleDragCanceled = async (e: KeyboardEvent): Promise<void> => {
        if (e.key === 'Escape') {
            this.isDragging = false;
            document.querySelector(`#clone-${this.index}`)?.remove();
            document.querySelector(`#placeholder-list`)?.remove();

            this.element = null;
            this.index = null;
        }
    };

    public async handleMoveMouseDown(e: MouseEvent, index: number): Promise<void> {
        // Only left click.
        if (e.button !== 0) return;
        // We must distinguish between a click and a drag.
        // Record the initial mouse position.
        const startX = e.clientX;
        const startY = e.clientY;
        const handleMouseMove = async (event: MouseEvent): Promise<void> => {
            // Calculate the current mouse position.
            const currentX = event.clientX;
            const currentY = event.clientY;
            // Check if the mouse has moved more than 5 pixels in either direction
            if (Math.abs(currentX - startX) > 5 || Math.abs(currentY - startY) > 5) {
                this.isDragging = true;
                // Remove the event listener that listens for the initial mouse down event.
                // Otherwise the event listener will be called multiple times.
                document.removeEventListener('mousemove', handleMouseMove);
                // Lets start the dragging process.
                // this.element.classList.add('!cursor-move');

                this.element = this.elements[index];
                this.index = index;

                await this.handleDragStart(e);
                // Save the callbacks otherwise removeEventListener won't work.

                this.moveCb = (e) => this.handleMoveMouseMove(e);
                this.moveEndCb = (e) => this.handleMoveMouseUp(e);
                this.moveKeyCb = (e) => this.handleMoveKeyEvent(e);
                // Add the event listeners.
                document.addEventListener('mousemove', this.moveCb);
                document.addEventListener('mouseup', this.moveEndCb, { once: true });
                document.addEventListener('keydown', this.moveKeyCb, { once: true });
            }
        };

        const handleMouseUp = async () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            // Check if the task is already being dragged, otherwise the task is clicked.
            if (this.isDragging) return;
            // Clicked
        };

        // Lets listen for the mouse move event.
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    private getDragAfterElement(container: any, y: number): HTMLElement {
        const draggableElements = [...container.querySelectorAll(`.draggable-list:not(.dragging)`)];
        return draggableElements.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();
                const offset = y - box.top - box.height / 2;
                if (offset < 0 && offset > closest.offset) return { offset, element: child };
                else return closest;
            },
            { offset: Number.NEGATIVE_INFINITY }
        ).element;
    }

    private async handleMoveMouseMove(e: MouseEvent): Promise<void> {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
            if (this.isDragging) await this.handleDrag(e);
        });
    }

    private async handleMoveMouseUp(e: MouseEvent): Promise<void> {
        this.isDragging = false;
        await this.handleDragEnd(e);
        if (isDefined(this.element)) {
            this.element.classList.remove('!cursor-move');
            this.element.classList.remove('hidden');
        }

        document.removeEventListener('mousemove', this.moveCb);
        document.removeEventListener('mouseup', this.moveEndCb);
        document.removeEventListener('keydown', this.moveKeyCb);
    }

    private async handleMoveKeyEvent(e: KeyboardEvent): Promise<void> {
        if (!this.isDragging) return;

        await this.handleDragCanceled(e);

        if (e.key === 'Escape' && isDefined(this.element)) {
            this.element.classList.remove('hidden');
            this.element.classList.remove('!cursor-move');

            document.removeEventListener('mousemove', this.moveCb);
            document.removeEventListener('mouseup', this.moveEndCb);
            document.removeEventListener('keydown', this.moveKeyCb);
        }
    }
}
