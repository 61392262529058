<import from="converters/translate" />
<import from="converters/delta-to-html" />
<import from="converters/time-ago" />
<import from="../template-post-input/template-post-input" />

<template>
    <div class="flex flex-col gap-3">
        <div class="flex w-full items-start gap-3">
            <ux-avatar
                size="sm"
                name.bind="post.createdBy.name"
                email.bind="post.createdBy.data.email"
                color.bind="post.createdBy.data.avatarColor"
                shade.bind="post.createdBy.data.avatarShade"></ux-avatar>
            <div flex-1.class="editMode" class="flex flex-col">
                <template-post-input
                    component.ref="input"
                    show-toolbar.bind="false"
                    show.bind="editMode"
                    is-visible.bind="editMode"
                    on-shift-enter.bind="handleSave"
                    on-escape.bind="handleCancel"
                    edit.bind="true"
                    content.bind="post.content"
                    is-reacting.bind="true"
                    workspace.bind="workspace"
                    language.bind="language">
                </template-post-input>
                <div if.bind="editMode" class="flex justify-end gap-2">
                    <ux-button click.trigger="handleCancel()" size="xs" color="dark" mode="outline">
                        <span t="translation:global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button click.trigger="handleSave()" size="xs" color="primary">
                        <span t="translation:global.buttons.save"></span>
                    </ux-button>
                </div>
                <div if.bind="!editMode" class="flex flex-col gap-1 rounded-lg bg-gray-100 px-4 py-3">
                    <p class="text-xs text-primary">${post.createdBy.name}</p>
                    <div
                        class="prose flex min-w-full flex-1 text-sm prose-headings:my-0 prose-p:my-0 prose-blockquote:mb-0 prose-pre:mb-0 prose-ol:mb-0 prose-ul:mb-0 prose-li:my-0 prose-img:my-0 prose-video:my-0">
                        <div if.bind="post" class="flex-1" innerhtml.bind="post.content | deltaToHtml"></div>
                    </div>
                </div>
                <div if.bind="!editMode" class="mt-1 flex items-center">
                    <div class="flex items-center gap-1 text-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p class="w-6 text-xs">${post.createdAt | timeAgo:true:false:true & signal:'update-timeago'}</p>
                    </div>
                    <p if.bind="authenticated.id === post.createdBy.id" class="mx-2 text-sm text-gray-400">|</p>
                    <ux-button click.trigger="edit()" if.bind="authenticated.id === post.createdBy.id" size="xs" color="dark" mode="outline" padding.bind="false" border.bind="false">
                        <span class="px-1 text-xs text-gray-400 hover:text-primary" t="translation:global.buttons.edit"></span>
                    </ux-button>
                    <p if.bind="authenticated.id === post.createdBy.id" class="mx-2 text-sm text-gray-400">|</p>
                    <ux-button click.trigger="delete()" if.bind="authenticated.id === post.createdBy.id" size="xs" color="dark" mode="outline" padding.bind="false" border.bind="false">
                        <span class="px-1 text-xs text-gray-400 hover:text-red-500" t="translation:global.buttons.delete"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </div>
</template>
