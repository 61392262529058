<import from="converters/sort-by-language" />
<import from="components/component-delta-content-viewer/component-delta-content-viewer" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col gap-y-2 overflow-hidden" css.bind="styles">
        <div class="flex border-b border-gray-200 p-3">
            <ux-tabs if.bind="mode === 'edit'">
                <ux-tab active.bind="lang === contentLanguage" click.trigger="changeLanguage(lang)" repeat.for="lang of languages">
                    <span t="global.languages.${lang}"></span>
                </ux-tab>
            </ux-tabs>
        </div>
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div ref="container" class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <template if.bind="mode === 'edit'">
                        <div class="${ hasScrolled && mode ==='preview' ? 'border-b' : ''} sticky top-0 z-10 flex flex-col border-gray-200 bg-white pt-2">
                            <div class="mb-3 flex">
                                <button click.trigger="changeView('editor')" class="${ view === 'editor' ? 'bg-gray-300' : '' } flex-1 rounded-l-lg border border-gray-200 py-1">
                                    <span class="text-sm" t="translation:global.labels.editor"></span>
                                </button>
                                <button click.trigger="changeView('preview')" class="${ view === 'preview' ? 'bg-gray-300' : '' } flex-1 rounded-r-lg border-y border-r border-gray-200 py-1">
                                    <span class="text-sm" t="translation:global.labels.preview"></span>
                                </button>
                            </div>
                            <div show.bind="view === 'editor'" id="toolbar">
                                <div id="toolbar-container">
                                    <!-- <span class="ql-formats">
                                            <select class="ql-font"></select>
                                                 <select className="ql-font" defaultValue="arial">
                                                    <option value="arial">Arial</option>
                                                    <option value="comic-sans">Comic Sans</option>
                                                    <option value="courier-new">Courier New</option>
                                                    <option value="georgia">Georgia</option>
                                                    <option value="helvetica">Helvetica</option>
                                                    <option value="lucida">Lucida</option>
                                                </select>
                                            <select class="ql-size"></select>
                                        </span> -->
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color"></select>
                                        <select class="ql-background"></select>
                                    </span>
                                    <!-- <span class="ql-formats">
                                            <button class="ql-script" value="sub"></button>
                                            <button class="ql-script" value="super"></button>
                                        </span> -->
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <select class="ql-header">
                                            <option value="1">Heading 1</option>
                                            <option value="2">Heading 2</option>
                                            <option value="3">Heading 3</option>
                                            <option value="4">Heading 4</option>
                                            <option value="5">Heading 5</option>
                                            <option value="6">Heading 6</option>
                                            <option value>Default</option>
                                        </select>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction" value="rtl"></button>
                                        <select class="ql-align"></select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                        <button if.bind="config.images" class="ql-image"></button>
                                        <button if.bind="config.videos" class="ql-video"></button>
                                        <!-- <button class="ql-formula"></button> -->
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-clean"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <!-- But you can also add your own -->
                                        <button class="text-red-500" click.trigger="clearHtml()">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="${ view === 'preview' ? 'border': '' } mb-5 flex flex-1 flex-col rounded-lg border-gray-200">
                            <div show.bind="view === 'editor'" ref="editor"></div>
                            <component-delta-content-viewer
                                if.bind="view === 'preview'"
                                content.bind="sheet.value[contentLanguage]"
                                workspace.bind="authenticated.workspace.id"></component-delta-content-viewer>
                        </div>
                    </template>
                    <template else>
                        <component-delta-content-viewer content.bind="sheet.value[contentLanguage]" workspace.bind="authenticated.workspace.id"></component-delta-content-viewer>
                    </template>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center">
                        <ux-spinner size="xs"></ux-spinner>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <template if.bind="mode === 'edit'">
                        <ux-button
                            size="sm"
                            color="primary"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.close"></span>
                        </ux-button>
                    </template>
                    <template else>
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.close"></span>
                        </ux-button>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
