<template class="w-flex w-full">
    <div class="relative flex">
        <div
            class="z-0 flex w-full items-center border p-1 pr-3"
            border-gray-300.class="valid"
            border-red-500.class="!valid"
            bg-white.class="!disabled"
            bg-gray-100.class="disabled"
            rounded-md.class="rounded === 'full' && (!allowClear || (allowClear && isNotDefined(value))) && !action"
            rounded-r-md.class="rounded === 'right' && (!allowClear || (allowClear && isNotDefined(value))) && !action"
            rounded-l-md.class="(rounded === 'left' && (allowClear || !allowClear)) || (rounded === 'full' && allowClear && isDefined(value)) || action"
            z-10.class="hasFocus"
            ring-1.class="hasFocus"
            ring-offset-1.class="hasFocus"
            ring-gray-400.class="hasFocus && valid"
            ring-red-400.class="hasFocus && !valid">
            <input
                data-1p-ignore.bind="blockPasswordManager"
                ref="input"
                disabled.bind="disabled"
                placeholder.bind="placeholder"
                input.trigger="handleOnInput($event)"
                change.trigger="handleOnChange($event)"
                keyup.trigger="handleKeyUp($event)"
                keydown.trigger="handleKeyDown($event)"
                blur.trigger="handleBlur($event)"
                focus.trigger="handleFocus($event)"
                type.bind="isCurrency ? 'number' : type"
                min.bind="min"
                max.bind="max"
                minlength.bind="minLength"
                maxlength.bind="maxLength"
                step.bind="isCurrency ? 'any' : step"
                value.bind="value"
                spellcheck.bind="spellcheck"
                lang.bind="language"
                class="w-full rounded-sm border-none py-1 pl-3 text-left text-sm transition duration-150 ease-in-out focus:outline-none sm:text-sm sm:leading-5"
                bg-gray-100.class="disabled"
                hover:cursor-not-allowed.class="disabled" />
            <span if.bind="loading" class="mr-1 flex items-center">
                <spinner size="xxs"></spinner>
            </span>
            <svg if.bind="!loading && !valid" class="h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
        </div>
        <button
            if.bind="action"
            disabled.bind="disabled"
            click.trigger="action()"
            class="-ml-px border bg-white p-2 ring-offset-1 focus:z-20 focus:outline-none focus:ring-1"
            rounded-r-md.class="rounded === 'right' || (rounded === 'full' && !allowClear) || (allowClear && isNotDefined(value)) || disabled"
            border-gray-300.class="valid"
            text-gray-800.class="valid"
            ring-gray-400.class="valid"
            hover:text-gray-600.class="valid"
            border-red-500.class="!valid"
            ring-red-500.class="!valid"
            text-red-500.class="!valid"
            hover:text-red-600.class="!valid"
            bg-gray-100.class="disabled"
            hover:cursor-not-allowed.class="disabled">
            <au-slot />
        </button>
        <button
            if.bind="allowClear && isDefined(value) && !disabled"
            click.trigger="clear($event)"
            disabled.bind="disabled"
            class="-ml-px border p-2 ring-offset-1 focus:z-20 focus:outline-none focus:ring-1"
            rounded-r-md.class="rounded === 'right' || rounded === 'full'"
            border-gray-300.class="valid"
            ring-gray-400.class="valid"
            border-red-500.class="!valid"
            ring-red-500.class="!valid"
            bg-gray-100.class="disabled"
            bg-white.class="!disabled"
            hover:cursor-not-allowed.class="disabled"
            bg-white.class="!disabled">
            <svg class="h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
        </button>
    </div>
</template>
