<import from="converters/format-address" />
<import from="converters/translate" />
<import from="components/component-map/component-map" />

<div class.bind="mode === 'horizontal' ? 'border': ''" class="grid grid-cols-12 rounded-lg border-gray-300">
    <div class.bind="mode === 'horizontal' ? 'col-span-6 p-5' : 'col-span-12 mb-2'" class="flex flex-col">
        <ux-combobox
            if.bind="maxAmount !== 1 || (addresses.length === 0 && maxAmount === 1)"
            component.ref="combobox"
            disabled.bind="addresses.length === maxAmount"
            set-value-on-select.bind="false"
            valid.bind="valid"
            onsearch.trigger="handleSearch($event)"
            onselect.trigger="handleSelect($event)"
            t="[placeholder]translation:components.address-selector.placeholders.enter-address-housenumber-zipcode">
            <ux-combobox-option repeat.for="suggestion of suggestions" value.bind="suggestion.id">
                <span innerhtml.bind="suggestion.highlighted"></span>
            </ux-combobox-option>
        </ux-combobox>
        <div mt-2.class="maxAmount !== 1 || (addresses.length === 0 && maxAmount === 1)" class="flex flex-col gap-y-2">
            <div if.bind="addresses.length === 0" class="flex flex-col rounded-lg bg-gray-100 p-3">
                <p class="text-sm" t="translation:global.messages.no-addresses-added"></p>
            </div>
            <template repeat.for="address of addresses">
                <div class="flex flex-col rounded-lg bg-gray-100 p-3">
                    <ux-select if.bind="useLabels" onselect.trigger="handleChange($event)" data.bind="$index" no-clear.bind="true" value.two-way="address.translations[language]">
                        <ux-select-option
                            repeat.for="description of descriptions"
                            skip-setting-value.bind="$index === descriptions.length - 1"
                            value.bind="description[language]"
                            data.bind="description">
                            ${ description | translate:language }
                        </ux-select-option>
                    </ux-select>
                    <div mt-2.class="useLabels" class="flex items-center justify-between">
                        <p class="text-xs">
                            ${address.street} ${address.houseNumber}${address.houseNumberSuffix || ''} <br />
                            ${address.zipCode} ${address.city}, ${ 'translation:global.countries.' + address.country | t}
                        </p>
                        <button class="0 border-gray-30 h-[38px] items-center rounded-lg border bg-white p-2 text-red-500 hover:bg-red-50 active:bg-red-100" click.trigger="delete($index)">
                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div class.bind="mode === 'horizontal' ? 'col-span-6 border-l' : 'col-span-12'" class="border-gray-300">
        <component-map
            language.bind="language"
            rounded.bind="mode === 'horizontal' ? 'rounded-r-lg' : 'rounded-lg'"
            height.bind="mode === 'horizontal' ? 200 : mapHeight"
            component.ref="map"></component-map>
    </div>
</div>
