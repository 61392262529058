import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    AnatomicalRegionEntityReference,
    AnatomicalRegionsApiClient,
    BodySides,
    DifferentialDiagnosisEntityReference,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItem,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalRecordRegistrationTypes,
    MedicalRecordResult,
    MedicalRecordResultStatuses,
    MedicalResult,
    MedicalWidgetTypes,
    ResultCheck,
    ResultMatcher,
    ResultMatcherTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { runOperator } from '../../../../../infra/utilities';
import { EventDetails } from '../../../../../models/event-details';
import { PartialView } from '../../../../../models/partial-view';
import { ViewOptions } from '../../../../../models/view-options';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { UxSelectOption } from '../../../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../../../ux/ux-select/ux-select';

@containerless
@inject(IEventAggregator, AnatomicalRegionsApiClient)
export class WidgetExaminationConclusion {
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public widgets: WidgetRegistration[] = [];

    public baseLoaded: boolean = false;
    public MedicalRecordResultStatuses: typeof MedicalRecordResultStatuses = MedicalRecordResultStatuses;
    public MedicalExaminationTemplateItemStepTypes: typeof MedicalExaminationTemplateItemStepTypes = MedicalExaminationTemplateItemStepTypes;
    public BodySides: typeof BodySides = BodySides;
    public calculatedResults: {
        id: string;
        result: MedicalRecordResult;
        diagnosis: GetDifferentialDiagnosisResponse;
        steps: MedicalExaminationTemplateItemStep[];

        percentageCompleted: number;
        conclusionAccuracy: number;
        expanded: boolean;
        addedByStep: boolean;
        items: {
            container: string;
            stepId: string;
            required: boolean;
            hasResult: boolean;
            hasPositiveResult: boolean;
        }[];
    }[] = [];

    public ketenzorg: GetMedicalRecordRegistrationResponse;

    public constructor(
        private events: IEventAggregator, //
        private readonly regionsApi: AnatomicalRegionsApiClient
    ) {}

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.None;

        // Try to find the ketenzorg widget registation.
        this.ketenzorg = Object.values(this.registrations)
            .filter((x: GetMedicalRecordRegistrationResponse) => x.type === MedicalRecordRegistrationTypes.Widget) //
            .find((x: GetMedicalRecordRegistrationResponse) => x.widget.type === MedicalWidgetTypes.Ketenzorg) as GetMedicalRecordRegistrationResponse;

        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.ExaminationConclusion,
                onSave: async (): Promise<void> => {
                    return new Promise((resolve, reject) => {
                        try {
                            // When examination steps are suggested and added to the template, it might happend that
                            // the registrations are not available. This means the results will be empty.
                            // Don't worry, the next time this function is called, the registrations will be available.
                            if (this.calculatedResults.empty()) {
                                resolve();
                                return;
                            }

                            // Determine the conclusion results for each of the differential diagnoses.
                            // The results are saved in 'this.items'
                            this.determineResults(this.record).then((results) => {
                                // For each of the differential diagnoses (stored in 'record.results') we nee to save
                                // the conclusion accuracy and the percentage completed.
                                for (const result of this.record.results) {
                                    // Find the calculated result that matched the differential diagnosis of the current record result.
                                    const calculatedResult = results.find((x: any) => x.diagnosis.id === result.differentialDiagnosis.id);

                                    // If a differentials diagnosis has no connected steps
                                    // we can not calculate anything.
                                    if (calculatedResult.items.empty()) continue;

                                    // Save the result of the current step (which is always the topLevel item).
                                    const topLevel = calculatedResult.items.find((x: any) => x.topLevel);
                                    const registration = this.registrations[topLevel.container] as GetMedicalRecordRegistrationResponse;

                                    registration.hasPositiveResult = topLevel.hasPositiveResult;
                                    // Save the percentages to the medical record.
                                    result.conclusionAccuracy = Number(calculatedResult.conclusionAccuracy);
                                    result.percentageCompleted = Number(calculatedResult.percentageCompleted);
                                }

                                resolve();
                            });
                        } catch (e) {
                            reject(e);
                        }
                    });
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {
                    // if (this.baseLoaded) {
                    //     this.baseLoaded = false;
                    //     this.determineResults(this.record).then((results) => {
                    //         // this.calculatedResults = [];
                    //         this.calculatedResults = results;
                    //         setTimeout(() => (this.baseLoaded = true), 250);
                    //     });
                    // }
                },
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );

        // This is to prevent the function from running multiple times.
        // On first load, the bound() function is called and the refresh() function
        // above is called. This will make sure the function only runs once at a time.
        this.determineResults(this.record).then((results) => {
            this.calculatedResults = [];
            this.calculatedResults = results;
            this.baseLoaded = true;
        });
    }

    public toggleSteps(index: number): void {
        this.calculatedResults[index].expanded = !this.calculatedResults[index].expanded;
    }

    public async handleStatusSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value as MedicalRecordResultStatuses;
        const index = e.detail.data as number;

        const item = this.calculatedResults[index];
        const indexOfResult = this.record.results.findIndex((x) => x.id === item.id);
        this.record.results[indexOfResult].status = value;

        this.events.publish(CustomEvents.ExaminationResultsChanged, this.record.results[index]);
    }

    public async handleGlobalSelect(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value as MedicalRecordResultStatuses;
        this.record.results.forEach((result) => (result.status = value));

        this.events.publish(CustomEvents.ExaminationResultsChanged);
    }

    public handleRegionSelected = async (region: GetAnatomicalRegionResponse, index: number): Promise<void> => {
        if (isNotDefined(region)) this.record.results[index].location = null;
        else
            this.record.results[index].location = new AnatomicalRegionEntityReference({
                id: region.id,
                translations: region.name
            });
    };

    public async handleSideSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value as BodySides;
        const index = e.detail.data as number;
        if (isDefined(value)) this.record.results[index].side = value;
    }

    /**
     * This function determines the result of each selected
     * differential diagnosis of a medical record.
     * @param record The medical record holding the conclusion data.
     */
    private async determineResults(record: GetMedicalRecordResponse): Promise<any> {
        const currentSteps = this.flattenSteps(record);

        // Try to match and of the values of the medical recrd results to an anatomical region by name.
        const names = this.record.results.filter((x) => isDefined(x.value)).map((x) => x.value);
        const regions = await this.regionsApi.search(this.workspace, '', names.length, 0, undefined, undefined, undefined, undefined, names);

        const calculated = [];
        for (const result of record.results) {
            // Filter the steps that so that we only have the ones that are related to the current diagnosis.
            const steps = currentSteps.filter((step) => {
                const registration = this.registrations[step.id] as GetMedicalRecordRegistrationResponse;
                // When examination steps are suggested and added to the template, it might happen that
                // the registration is not available. In that case, we skip the step.
                // Don't worry, the entire examination will be reloaded after the record saved
                // So this function will be called again.
                if (isNotDefined(registration)) return false;

                // If the action/question or questionnaire is related to the current differential diagnosis,
                // add it to the list of steps.
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        if (isNotDefined(registration.action.differentialDiagnoses)) return false;
                        return registration.action.differentialDiagnoses.some((x: DifferentialDiagnosisEntityReference) => x.id === result.differentialDiagnosis.id);
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        if (isNotDefined(registration.questionnaire.differentialDiagnoses)) return false;
                        return registration.questionnaire.differentialDiagnoses.some((x: DifferentialDiagnosisEntityReference) => x.id === result.differentialDiagnosis.id);
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        if (isNotDefined(registration.question.differentialDiagnoses)) return false;
                        return registration.question.differentialDiagnoses.some((x: DifferentialDiagnosisEntityReference) => x.id === result.differentialDiagnosis.id);
                    default:
                        return false;
                }
            });

            // Now we have every step that is related to the current differential diagnosis
            // We can now map the steps to a flat list and calculate the percentages.
            const mapped = this.mapAndFlatten(steps);

            // When a differential diagnosis (e.g. result) is added by a step it saves that value
            // that added the result. So we are going try to match that value (if available) to the
            // a anatomical region. If we find a match, we will use that as the location for medical record result.
            let location: AnatomicalRegionEntityReference;
            if (isDefined(result.value)) {
                const region = regions.data.find((x) => x.name[this.language] === result.value);
                if (isDefined(region)) {
                    location = new AnatomicalRegionEntityReference({
                        id: region.id,
                        translations: region.name
                    });
                    result.location = location;
                }
            }

            calculated.push({
                id: result.id,
                diagnosis: result.differentialDiagnosis,
                result,
                expanded: false,
                steps,
                percentageCompleted: isNotEmpty(result.addedByStep) ? result.percentageCompleted : this.calculatePercentageCompleted(mapped, false),
                conclusionAccuracy: isNotEmpty(result.addedByStep) ? result.conclusionAccuracy : this.calculateConclusionAccuracy(mapped),
                addedByStep: isNotEmpty(result.addedByStep),
                items: mapped
            });
        }

        // Make sure the array is overwritten and not appended to.
        // This because the refresh() function is also called multiple times.
        return calculated;
    }

    /**
     * This function determines whether or not steps have a positive or negative result.
     * It will loop through each step and validate the result and saves the result to a mapped object.
     * @param steps
     * @returns
     */
    private mapAndFlatten(steps: MedicalExaminationTemplateItemStep[]): {
        topLevel: boolean;
        container: string;
        stepId: string;
        copiedFrom: string;
        required: boolean;
        hasResult: boolean;
        hasPositiveResult: boolean;
    }[] {
        const mapped: {
            topLevel: boolean;
            container: string;
            stepId: string;
            copiedFrom: string;
            required: boolean;
            hasResult: boolean;
            hasPositiveResult: boolean;
        }[] = [];
        for (const step of steps) {
            // First get the registration (that contains the possible entered data) of the current step.
            const registration = this.registrations[step.id] as GetMedicalRecordRegistrationResponse;
            // This functions recursively loops through all the steps and adds them to the mapped object.
            const getSteps = (items: MedicalExaminationActionItem[], container: MedicalExaminationTemplateItemStep): void => {
                for (const item of items) {
                    if (item.type === MedicalExaminationActionItemTypes.Category) getSteps(item.category.stepsToTake, container);
                    else {
                        const required = this.flow.required.find((x) => x.key === item.id);
                        mapped.push({
                            topLevel: false,
                            container: step.id,
                            stepId: item.id,
                            copiedFrom: item.copiedFrom,
                            required: isDefined(required) && required.value,
                            hasResult: this.hasResult(item.step.result, item.step.results),
                            hasPositiveResult: item.step.isMultipleChoice //
                                ? this.whenNotAllExpectedResultsAreSelected(item.step.norms, item.step.results)
                                : this.isNotAMatch(item.step.norm, item.step.result, item.step.resultMatcher)
                        });
                    }
                }
            };

            // Based on the type of the current step we add the step to the mapped object.
            switch (step.type) {
                case MedicalExaminationTemplateItemStepTypes.Action:
                    // First recursively get all the steps of this action.
                    // They are added to the mapped list.
                    getSteps(registration.action.stepsToTake, step);
                    // Now get all the substeps of this action from the mapped list.
                    const subSteps = mapped.filter((x) => x.container === step.id);
                    // Add a top level object for the current action.
                    mapped.push({
                        topLevel: true,
                        container: step.id,
                        stepId: step.id,
                        copiedFrom: null,
                        required: false,
                        // Top level steps never have a result.
                        hasResult: false,
                        // Check each of the substeps and evaluate the result for this entire action
                        // by providing the substeps and the rules to use when evaluating the action.
                        // NOTE that the substeps are already individually evaluated.
                        hasPositiveResult: this.evaluateActionStepResults(subSteps, registration.action.resultCheck)
                    });
                    break;
                case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                    for (const item of registration.questionnaire.questions) {
                        // Evaluate each question of the questionnaire.
                        const required = this.flow.required.find((x) => x.key === item.id);
                        mapped.push({
                            // Because the question are part of a questionnaire, they are not top level.
                            topLevel: false,
                            container: step.id,
                            stepId: step.id,
                            copiedFrom: null,
                            required: isDefined(required) && required.value,
                            hasResult: this.hasResult(item.question.givenAnswer, item.question.givenAnswers),
                            // Evaluate the result of the question.
                            hasPositiveResult: item.question.isMultipleChoice
                                ? this.whenNotAllExpectedResultsAreSelected(item.question.expectedAnswers, item.question.givenAnswers)
                                : this.isNotAMatch(item.question.expectedAnswer, item.question.givenAnswer, item.question.resultMatcher)
                        });
                    }
                    // Add a top level object for the current questionnaire.
                    mapped.push({
                        topLevel: true,
                        container: step.id,
                        stepId: step.id,
                        copiedFrom: null,
                        required: false,
                        hasResult: false,
                        hasPositiveResult: false
                    });
                    break;
                case MedicalExaminationTemplateItemStepTypes.Question:
                    const required = this.flow.required.find((x) => x.key === step.id);
                    mapped.push({
                        // Because the question is not part of a questionnaire, it is top level.
                        topLevel: true,
                        container: step.id,
                        stepId: step.id,
                        copiedFrom: null,
                        required: isDefined(required) && required.value,
                        hasResult: this.hasResult(registration.question.givenAnswer, registration.question.givenAnswers),
                        // Evaluate the result of the question.
                        hasPositiveResult: registration.question.isMultipleChoice
                            ? this.whenNotAllExpectedResultsAreSelected(registration.question.expectedAnswers, registration.question.givenAnswers)
                            : this.isNotAMatch(registration.question.expectedAnswer, registration.question.givenAnswer, registration.question.resultMatcher)
                    });
                    break;
            }
        }

        return mapped;
    }

    /**
     * Calculates the percentage of completed steps for a single selected differential diagnosis.
     * @param mapped The steps mapped to a flat list.
     * @param required A flag that indicates wheter or not to calculate the required steps or just the completed steps.
     * @returns
     */
    private calculatePercentageCompleted(
        mapped: {
            required: boolean;
            hasResult: boolean;
            topLevel: boolean;
        }[],
        required: boolean
    ): number {
        // Return percentage of completed steps with two decimals.
        if (required) {
            const requiredSteps = mapped.filter((x) => !x.topLevel && x.required);
            if (requiredSteps.empty()) return 0;
            const completedRequiredSteps = requiredSteps.filter((x) => x.hasResult);
            return Math.round((completedRequiredSteps.length / requiredSteps.length) * 10000) / 100;
        } else {
            const completedSteps = mapped.filter((x) => !x.topLevel && x.hasResult);
            if (completedSteps.empty()) return 0;
            return Math.round((completedSteps.length / mapped.filter((x) => !x.topLevel).length) * 10000) / 100;
        }
    }

    /**
     * Calculates the conclusion accuracy for single selected differential diagnosis.
     * @param steps The steps that are flattened and mapped.
     * @returns
     */
    private calculateConclusionAccuracy(
        steps: {
            topLevel: boolean;
            hasPositiveResult: boolean;
        }[]
    ): number {
        const topLevel = steps.filter((x) => x.topLevel);
        if (topLevel.empty()) return 0;
        const positive = topLevel.filter((x) => x.hasPositiveResult);
        return Math.round((positive.length / topLevel.length) * 10000) / 100;
    }

    /**
     * This flattens all the steps of each phase to one list.
     * @returns A list of all steps of the medical record.
     */
    private flattenSteps(record: GetMedicalRecordResponse): MedicalExaminationTemplateItemStep[] {
        return record.examination.template.phases //
            .selectMany<MedicalExaminationTemplateItem, MedicalExaminationTemplateItemStep>((x) => x.stepsToTake);
    }

    /**
     * Checks if an expected result doesn't match the actual result.
     * @param expected The expected value.
     * @param result The result to check
     * @param matcher The matching rules.
     */
    private isNotAMatch(expected: MedicalResult, result: MedicalResult, matcher: ResultMatcher): boolean {
        // If no result is found, return false.
        if (!this.hasResult(result, undefined)) return false;
        // Match the value based on the type of matcher.
        switch (matcher.type) {
            case ResultMatcherTypes.SimpleComparison:
                // If no expected value is provided, return false.
                if (isNotDefined(expected)) return false;
                // If the expected value is not equal to the actual value, return true.
                return expected.value !== result.value;
            case ResultMatcherTypes.BetweenRange:
                // If the provided result doesnt fall within the expected range, return true.
                return Number(result.value) < matcher.minRange || Number(result.value) > matcher.maxRange;
            default:
                return false;
        }
    }

    /**
     * Checks whether or not all expected results selected.
     * @param expected The expected results.
     * @param results The actual results.
     */
    private whenNotAllExpectedResultsAreSelected(expected: MedicalResult[], results: MedicalResult[]): boolean {
        // If no results are found, return false.
        if (!this.hasResult(undefined, results)) return false;
        // If we have no expected result amnswer, return false.
        if (isNotDefined(expected)) return false;
        // If one of the expected result is not found in the actual result, return true.
        return expected.some((expected) => results.every((result) => result.value !== expected.value));
    }

    /**
     * Checks whether or not a step has a result
     * @param result The result value of a step (in case of single answer mode).
     * @param results The results value of a step (in case of multiple choice mode).
     * @returns
     */
    private hasResult(result: MedicalResult, results: MedicalResult[]): boolean {
        return (isDefined(result) && isDefined(result.value) && isNotEmpty(result.value)) || (isDefined(results) && results.any());
    }

    /**
     * This functions evaluates the results of all the steps of an action against provided rules.
     * @param steps The flattened steps of an action.
     * @param resultCheck The rules to use when evaluating the action.
     * @returns
     */
    private evaluateActionStepResults(steps: any[], resultCheck: ResultCheck): boolean {
        // Set a result flag for this action.
        let hasPositiveResult = false;
        // Look through each of the rules and evaluate the result of the action.
        for (let index = 0; index < resultCheck.items.length; index++) {
            const requirement = resultCheck.items[index];
            // Find the step that matches the current requirement.
            // Note that this individual step is already evaluated (e.g. step.hasPositiveResult).
            const step = steps.find((item: any) => item.stepId === requirement.stepId || item.copiedFrom === requirement.stepId);
            if (isNotDefined(step)) continue;

            // Now update the result flag based on the operator (from the requirement) and step result.
            hasPositiveResult =
                index > 0 //
                    ? (runOperator(requirement.operator, hasPositiveResult, step.hasPositiveResult) as boolean)
                    : step.hasPositiveResult;
        }
        // Return the result of the entire action.
        return hasPositiveResult;
    }
}
