<import from="converters/sort-by-language" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 flex-col gap-y-3 pb-5 pt-8">
                        <div class="flex flex-col" repeat.for="lan of languages | sortByLanguage:language">
                            <ux-label class="${ language !== lan ? 'mb-1' : '' } leading-tight" t="global.languages.${lan}"></ux-label>
                            <p class="mb-1 text-xxs font-medium leading-tight text-gray-400" if.bind="language === lan" t="partial-views.templates.messages.current-language"></p>
                            <ux-textarea rows="1" allow-clear.bind="lan !== language || !required" value.two-way="translations[lan]" t="[placeholder]global.placeholders.add-translation">
                            </ux-textarea>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center">
                        <ux-spinner size="xs"></ux-spinner>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <!-- <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button> -->
                    <ux-button
                        size="sm"
                        color="primary"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
