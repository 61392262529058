import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment, GetContactResponse } from '@wecore/sdk-crm';
import {
    ContactEntityReference,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalReferral,
    MedicalReferralSystems,
    MedicalReferralTypes,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { cloneDeep } from '../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { EventDetails } from '../../../../../models/event-details';
import { SelectedFile } from '../../../../../models/selected-file';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { ModalService } from '../../../../../services/service.modals';
import { UxRadioGroup } from '../../../../../ux/ux-radio-group/ux-radio-group';
import { UxSelectOption } from '../../../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../../../ux/ux-select/ux-select';

@containerless
@inject(I18N, ModalService)
export class WidgetPatientIntake {
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile, attachment: BlobStorageAttachment) => void;

    public referral: MedicalReferral;
    public MedicalReferralTypes: typeof MedicalReferralTypes = MedicalReferralTypes;
    public MedicalReferralSystems: typeof MedicalReferralSystems = MedicalReferralSystems;
    public upload: SelectedFile;
    public attachment: BlobStorageAttachment;
    private hasReferral: boolean = true;

    public constructor(
        public t: I18N, //
        private modalService: ModalService
    ) {}

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        this.referral = isDefined(this.record.referral)
            ? cloneDeep(this.record.referral)
            : new MedicalReferral({
                  type: MedicalReferralTypes.InstandAccess
              });
        this.hasReferral = isDefined(this.referral.referredBy);

        // Lookup full attachment
        if (isNotEmpty(this.referral.attachment)) this.attachment = this.record.attachments.find((a) => a.id === this.referral.attachment);

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.None;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.IntakeRefferal,
                onSave: async (): Promise<void> => {
                    // Make sure we have a referral object.
                    if (isNotDefined(this.record.referral))
                        this.record.referral = new MedicalReferral({
                            type: MedicalReferralTypes.InstandAccess
                        });
                    // Referall object already exists and could be
                    // populated with the uploaded attachment id.
                    // So don't overwrite it.
                    this.record.referral.system = this.referral.system;
                    this.record.referral.type = this.referral.type;
                    this.record.referral.referredBy = this.referral.referredBy;
                },
                validate: (_: WidgetResult, validation: any): boolean => {
                    if (this.referral.type === MedicalReferralTypes.InstandAccess) return true;

                    validation.referredBy = isDefined(this.referral.referredBy);
                    validation.system = isDefined(this.referral.system);
                    if (validation.system)
                        validation.file =
                            this.referral.system === MedicalReferralSystems.Zorgdomein //
                                ? true
                                : isDefined(this.upload) || isDefined(this.attachment);

                    return validation.referredBy && validation.file && validation.system;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }

    public beforeTypeChange = async (): Promise<boolean> => {
        return new Promise<boolean>(async (resolve) => {
            if (isNotDefined(this.referral.referredBy)) {
                resolve(true);
                return;
            }

            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:components.widgets.patient-intake.questions.delete-referral.title'),
                    message: this.t.tr('translation:components.widgets.patient-intake.questions.delete-referral.message'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            this.referral.referredBy = null;

                            switch (this.referral.type) {
                                case MedicalReferralTypes.ByGeneralPractitioner:
                                    this.referral.system = MedicalReferralSystems.Zorgdomein;
                                    break;
                                case MedicalReferralTypes.BySpecialist:
                                case MedicalReferralTypes.ByOther:
                                    this.referral.system = MedicalReferralSystems.Pdf;
                                    break;
                                case MedicalReferralTypes.InstandAccess:
                                default:
                                    this.referral.system = null;
                                    break;
                            }

                            if (isDefined(this.upload) || isDefined(this.attachment)) this.handleFileRemoved();
                            resolve(true);
                        } else resolve(false);
                    }
                })
            );
        });
    };

    public async handleReferralTypeChanged(e: CustomEvent<EventDetails<UxRadioGroup, any>>): Promise<void> {
        this.referral.type = e.detail.values as MedicalReferralTypes;

        switch (this.referral.type) {
            case MedicalReferralTypes.ByGeneralPractitioner:
                this.referral.system = MedicalReferralSystems.Zorgdomein;
                break;
            case MedicalReferralTypes.BySpecialist:
            case MedicalReferralTypes.ByOther:
                this.referral.system = MedicalReferralSystems.Pdf;
                break;
            case MedicalReferralTypes.InstandAccess:
            default:
                this.referral.system = null;
                break;
        }
    }

    public handleContactSelected = (contact: GetContactResponse): void => {
        this.referral.referredBy = new ContactEntityReference({
            id: contact.id,
            name: contact.displayName
        });
    };

    public async handleSystemChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const system = e.detail.values.value as MedicalReferralSystems;
        if ((isDefined(this.attachment) || this.upload) && system === MedicalReferralSystems.Zorgdomein) {
            // Add timeout to prevent dropzone flicker.
            setTimeout(() => this.handleFileRemoved(), 200);
        }
    }

    public handleFilesSelected = (files: File[]): void => {
        for (const file of files) {
            const upload = new SelectedFile({
                id: guid(),
                step: this.step.id,
                type: 'referral',
                file,
                progress: 0,
                loader: null,
                statusLabel: this.t
                    .tr('translation:partial-views.clinical-pathways.labels.status-waiting')
                    .replace('{entity}', `<span class="font-medium block mx-1">${file.name.toLowerCase()}</span>`),
                extension: `.${file.name.split('.').pop()}`,
                name: file.name,
                registration: this.registration.id,
                isLoading: false
            });

            this.upload = upload;
            this.onFileSelected(upload);
        }
    };

    public handleFileRemoved = (): void => {
        this.onFileRemoved(this.upload, this.attachment);
        this.referral.attachment = null;
        this.attachment = null;
        this.upload = null;
    };
}
