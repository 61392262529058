import { Store, connectTo } from '@aurelia/store-v1';
import { GetMedicalTherapyResponse, MedicalTherapyPlanItem, MedicalTherapyPlanItemStepCategory, MedicalTherapyPlanItemTypes } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';

import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../infra/events';
import { copyItem } from '../../../../infra/store/actions/copy-paste';
import { State } from '../../../../infra/store/state';
import { addCategory, addStep, collapseSteps, generateColumns, moveStep, pasteItem } from '../../../../infra/utilities';
import { CopyTherapyPlanItem } from '../../../../models/copy-therapy-plan-item';
import { EventDetails } from '../../../../models/event-details';
import { UxInput } from '../../../../ux/ux-input/ux-input';

@connectTo<State>()
@containerless
@inject(Store<State>, IEventAggregator)
export class TemplateTherapyPlanCategory {
    @bindable() public therapy: GetMedicalTherapyResponse;
    @bindable() public array: MedicalTherapyPlanItem[];
    @bindable() public parent: MedicalTherapyPlanItemStepCategory;
    @bindable() public parentIndex: number;
    @bindable() public therapyItem: MedicalTherapyPlanItem;
    @bindable() public validation: any;
    @bindable() public level: number = 0;
    @bindable() public index: number;
    @bindable() public language: string;
    @bindable() public toplevel: boolean = false;
    @bindable() public manageTranslations: (property: string, required: boolean, index: number) => void;
    @bindable() public openSettings: (index: number, nested: boolean, type: 'plan' | 'execution') => Promise<void>;
    @bindable() public remove: (index: number, category: MedicalTherapyPlanItemStepCategory, validation: any[], type: 'plan' | 'execution') => void;
    @bindable() public edit: (index: number, array: MedicalTherapyPlanItem[], validationItems: any[], type: 'plan' | 'execution') => Promise<void>;

    public state: State;
    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    public editMode: boolean = false;
    public hasConnectedCategories: boolean = false;
    public hasVisibilityRequirements: boolean = false;
    private timer: any;
    public columns: string;
    private subscriptions: any[];

    public constructor(
        public store: Store<State>, //
        private readonly events: IEventAggregator
    ) {}

    public attached(): void {
        if (isNotDefined(this.therapyItem.attributes)) this.therapyItem.attributes = {};
        if (!this.therapyItem.category.name[this.language]) this.editMode = true;
        this.setColumns();
        this.checkIfHasSettings();
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepSettingsChanged, () => {
                this.setColumns();
                this.checkIfHasSettings();
            }),
            this.events.subscribe(CustomEvents.TherapyCategoryChanged, (data: { stepId: string; expand: boolean }) => {
                const shouldChange = this.therapy.plan.flow.connectedCategories //
                    .filter((x) => x.key === this.therapyItem.id)
                    .some((x) => x.value === data.stepId);
                if (shouldChange) this.therapyItem.attributes.expanded = data.expand;
            })
        ];
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(e: MouseEvent): void {
        if (e.detail === 1) {
            this.timer = setTimeout(() => {
                this.therapyItem.attributes.expanded = !this.therapyItem.attributes.expanded;
                this.events.publish(CustomEvents.TherapyCategoryChanged, {
                    stepId: this.therapyItem.id, //
                    expand: this.therapyItem.attributes.expanded
                });
            }, 250);
        } else {
            clearTimeout(this.timer);
            this.toggleEditMode();
        }
    }

    public async copyOrCut(command: 'copy'): Promise<void> {
        await this.store.dispatch(
            copyItem,
            new CopyTherapyPlanItem({
                item: this.therapyItem,
                array: this.array,
                index: this.index,
                validationItem: this.validation[this.index],
                validation: this.validation,
                command
            })
        );
    }

    public toggleEditMode(): void {
        if (this.editMode) {
            if (!this.therapyItem.category.name[this.language]) {
                this.validation[this.index].name = false;
                return;
            } else this.validation[this.index].name = true;
        }

        this.editMode = !this.editMode;
    }

    public async handleInputChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.toggleEditMode();
    }

    public pasteItem = async (position: 'before' | 'after' | 'end', pasteInToChildren: boolean): Promise<void> => {
        const copyItem = this.state.clipboard.therapyPlanItem;
        if (isNotDefined(copyItem)) return;

        const arrayToUse = pasteInToChildren ? this.therapyItem.category.stepsToTake : this.array;
        const validationArrayToUse = pasteInToChildren ? this.validation[this.index].steps : this.validation;

        let indexToUse = this.index;
        // The item gets a new ID during the paste action.
        if (position === 'after') indexToUse++;
        if (position === 'end') indexToUse = -1;

        await pasteItem(
            this.store, //
            this.state,
            arrayToUse,
            validationArrayToUse,
            indexToUse,
            this.therapy.plan.flow,
            'MedicalTherapyPlanItem'
        );
    };

    public moveItem(direction: 'up' | 'down'): void {
        moveStep(this.array, this.validation, direction, this.index);
        this.events.publish(CustomEvents.TherapyStepsChanged);
    }

    public addNewCategory = (index: number = -1): void => {
        collapseSteps(this.array);
        addCategory(this.array, this.validation, index, this.language, 'MedicalTherapyPlanItem');
    };

    public addNewStep = (index: number = -1): void => {
        collapseSteps(this.array);
        addStep(this.array, this.validation, index, this.language, 'MedicalTherapyPlanItem');
    };

    public addCategoryToSteps = (index: number = -1): void => {
        this.therapyItem.attributes.expanded = true;
        addCategory(this.therapyItem.category.stepsToTake, this.validation[this.index].steps, index, this.language, 'MedicalTherapyPlanItem');
    };

    public addStepToSteps = (index: number = -1): void => {
        this.therapyItem.attributes.expanded = true;
        addStep(this.therapyItem.category.stepsToTake, this.validation[this.index].steps, index, this.language, 'MedicalTherapyPlanItem');
    };

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.therapy.plan.flow.breakpoints.filter((x) => x.id === this.therapyItem.id) || [] //
        );
    };

    private checkIfHasSettings(): void {
        this.hasConnectedCategories = this.therapy.plan.flow.connectedCategories?.filter((x) => x.key === this.therapyItem.id).any() || false;
        this.hasVisibilityRequirements = this.therapy.plan.flow.visibilityRequirements?.filter((x) => x.id === this.therapyItem.id).any() || false;
    }
}
