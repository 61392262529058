<import from="converters/translate" />
<import from="converters/filter-breakpoints" />
<import from="converters/currency" />
<import from="converters/get-ids" />
<import from="converters/operator-to-string" />
<import from="converters/side-to-string" />
<import from="converters/keys" />
<import from="converters/sort" />
<import from="converters/flatten-action-steps" />
<import from="components/component-breakpoints-selector/component-breakpoints-selector" />
<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />
<import from="bx/bx-action-multi-selector/bx-action-multi-selector" />

<import from="bx/bx-insurer-selector/bx-insurer-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex h-full flex-1 flex-col overflow-hidden">
            <section class="flex h-full flex-col overflow-hidden">
                <div class="flex gap-1 border-b border-gray-200 px-7 py-5">
                    <h6 t="translation:partial-views.medical-step-settings.labels.settings-for"></h6>
                    <h6 class="lowercase" t.bind="label"></h6>
                    <h6 class="flex gap-1 font-semibold">
                        <span>${name}</span>
                        <span if.bind="stepCategoryName"> (${stepCategoryName})</span>
                    </h6>
                </div>
                <div class="flex h-full overflow-hidden">
                    <div if.bind="baseLoaded" class="flex h-full flex-1 flex-col overflow-hidden pr-5 sm:flex-row sm:pr-0">
                        <div class="flex w-[238px] overflow-hidden border-r border-gray-200 px-7 py-5">
                            <ul class="flex items-center gap-x-3 sm:flex-col sm:items-start sm:space-x-0 sm:space-y-3">
                                <li class="group" repeat.for="key of tabs | keys" show.bind="tabs[key].configured">
                                    <a
                                        class="${ tabs[key].valid ? tabs[key].active ? 'text-gray-900 font-semibold': 'text-gray-500 group-hover:text-gray-900': tabs[key].active ? 'text-red-500 font-semibold':'text-red-500 group-hover:text-red-600' }"
                                        href="javascript:"
                                        click.trigger="setActiveTab(key)">
                                        <div class="flex items-center space-x-1">
                                            <span class="text-sm">${ tabs[key].name }</span>
                                            <svg if.bind="!tabs[key].valid" class="h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex flex-1 flex-col overflow-hidden">
                            <!-- General Tab -->
                            <div class="flex flex-1 flex-col gap-3 overflow-y-auto px-7 py-5" if.bind="tabs.general.active">
                                <template if.bind="hasSetting('required') && !isCategory">
                                    <div class="flex items-center justify-between">
                                        <ux-label>
                                            <span t="translation:partial-views.medical-step-settings.labels.required"></span>
                                        </ux-label>
                                        <ux-toggle
                                            onchange.trigger="handleRequiredChanged($event)"
                                            checked.bind="required !== undefined && required !== null"
                                            label-active.bind="'translation:global.labels.yes' | t"
                                            label-inactive.bind="'translation:global.labels.no' | t"
                                            size="md"
                                            color="primary">
                                        </ux-toggle>
                                    </div>
                                </template>
                                <template if.bind="hasSetting('repeatDuringEvaluation') && (isAction || isQuestion || isQuestionnaire)">
                                    <div class="flex items-center justify-between">
                                        <ux-label>
                                            <span t="translation:partial-views.medical-step-settings.labels.repeat"></span>
                                        </ux-label>
                                        <ux-toggle
                                            onchange.trigger="handleRepeatDuringEvaluationChanged($event)"
                                            checked.bind="repeatDuringEvaluation !== undefined && repeatDuringEvaluation !== null"
                                            label-active.bind="'translation:global.labels.yes' | t"
                                            label-inactive.bind="'translation:global.labels.no' | t"
                                            size="md"
                                            color="primary">
                                        </ux-toggle>
                                    </div>
                                </template>
                                <template if.bind="hasSetting('model') && model">
                                    <div class="flex flex-col">
                                        <ux-label class="mb-1">
                                            <span t="partial-views.medical-step-settings.labels.select-model-box"></span>
                                        </ux-label>
                                        <ux-select
                                            allow-clear="true"
                                            onselect.trigger="handleModelBoxSelected($event)"
                                            onclear.trigger="handleModelBoxSelected($event)"
                                            t="[placeholder]partial-views.medical-step-settings.placeholders.select-model-box"
                                            value.bind="modelBox.value.id">
                                            <ux-select-option repeat.for="box of model.boxes" value.bind="box.id"> ${ box.name | translate:language } </ux-select-option>
                                        </ux-select>
                                    </div>
                                </template>
                                <template if.bind="hasSetting('columns')">
                                    <div class="col-span-12 flex items-center justify-between">
                                        <component-breakpoints-selector id.bind="item.id" validation.bind="validation.breakpoints" breakpoints.two-way="breakpoints"> </component-breakpoints-selector>
                                    </div>
                                </template>
                                <template if.bind="hasSetting('sides') && isCategory">
                                    <div class="flex flex-col">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.medical-step-settings.labels.bodyside"></span>
                                        </ux-label>
                                        <ux-select allow-clear.bind="true" onselect.trigger="handleSideSelected($event)" onclear.trigger="handleSideSelected($event)" value.to-view="side.value">
                                            <ux-select-option repeat.for="side of BodySides | keys" value.bind="side"> ${ side | sideToString | t } </ux-select-option>
                                        </ux-select>
                                        <p class="mt-2 text-xs text-gray-500" t="translation:partial-views.medical-step-settings.messages.bodyside"></p>
                                    </div>
                                </template>
                                <template if.bind="hasSetting('categories') && isCategory && categoriesOptions && connectedCategories">
                                    <div class="flex flex-col">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.medical-step-settings.labels.link-categories"></span>
                                        </ux-label>
                                        <ux-multi-selector
                                            disabled.bind="connectedCategories.length > 0"
                                            options.bind="categoriesOptions"
                                            allow-clear-while-disabled.bind="true"
                                            on-selected.bind="handleCategorySelected"
                                            on-removed.bind="handleCategoryRemoved"
                                            show-footer.bind="false"
                                            force-remove.bind="true"
                                            autocomplete.bind="true"
                                            values.bind="connectedCategories.map((x) => x.value)"
                                            t="[placeholder]translation:partial-views.medical-step-settings.placeholders.select-categories">
                                        </ux-multi-selector>
                                    </div>
                                </template>
                                <template if.bind="hasSetting('steps') && isStep && stepOptions">
                                    <div class="flex min-w-0 flex-col">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.medical-step-settings.labels.linked-steps"></span>
                                        </ux-label>
                                        <ux-multi-selector
                                            disabled.bind="connectedSteps.length > 0"
                                            options.bind="stepOptions"
                                            allow-clear-while-disabled.bind="true"
                                            on-selected.bind="handleStepSelected"
                                            on-removed.bind="handleStepRemoved"
                                            show-footer.bind="false"
                                            force-remove.bind="true"
                                            autocomplete.bind="true"
                                            values.bind="connectedSteps.map((x) => x.value)"
                                            t="[placeholder]translation:partial-views.medical-step-settings.placeholders.linked-steps">
                                        </ux-multi-selector>
                                    </div>
                                </template>
                            </div>
                            <!-- Display configuration -->
                            <div class="flex flex-1 flex-col gap-3 overflow-y-auto px-7 py-5" if.bind="tabs.visibility.active">
                                <div class="col-item-12 flex flex-col">
                                    <div class="flex justify-between">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.medical-step-settings.labels.visibility-requirements"></span>
                                        </ux-label>
                                        <ux-button if.bind="visibilityRequirements.length > 0" click.trigger="addVisiblityRequirement()" padding.bind="false" size="xs">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                        </ux-button>
                                    </div>
                                    <template if.bind="visibilityRequirements.length > 0">
                                        <ul class="mt-2 flex flex-col">
                                            <li repeat.for="requirement of visibilityRequirements">
                                                <template if.bind="$index > 0">
                                                    <div class="flex justify-center">
                                                        <div class="flex w-[100px] flex-col items-center gap-y-2 py-2">
                                                            <ux-select value.two-way="requirement.operator">
                                                                <ux-select-option value.bind="Operators.And">${ Operators.And | operatorToString | t }</ux-select-option>
                                                                <ux-select-option value.bind="Operators.Or">${ Operators.Or | operatorToString | t }</ux-select-option>
                                                            </ux-select>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="flex flex-col rounded-lg border border-gray-200">
                                                    <ux-card>
                                                        <div class="group flex w-full flex-col gap-y-3 rounded-lg p-5">
                                                            <div class="flex flex-col">
                                                                <ux-label if.bind="$index === 0" class="mb-1" t="translation:partial-views.medical-step-settings.labels.display-when-first"></ux-label>
                                                                <ux-label else class="mb-1" t="translation:partial-views.medical-step-settings.labels.display-when"></ux-label>

                                                                <div class="flex">
                                                                    <ux-combobox
                                                                        data.bind="$index"
                                                                        rounded="left"
                                                                        autocomplete.bind="true"
                                                                        valid.bind="validation.visibilityRequirements[$index].step"
                                                                        onselect.trigger="handleStepToCompareSelected($event)"
                                                                        used.bind="[item.step.id, item.question.id]"
                                                                        t="[placeholder]partial-views.medical-step-settings.placeholders.select-linked-step"
                                                                        value.bind="requirement.questionToCompare?.id || requirement.stepToCompare?.id">
                                                                        <ux-combobox-option repeat.for="itemToCompare of itemsToCompare" value.bind="itemToCompare.value">
                                                                            ${ itemToCompare.text }
                                                                        </ux-combobox-option>
                                                                    </ux-combobox>
                                                                    <button
                                                                        class.bind="validation.visibilityRequirements[$index].step ? 'border-gray-200' : 'border-red-500'"
                                                                        class="inline-flex items-center rounded-r-lg border-y border-r bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                                                        click.trigger="removeVisibilityRequirement($index)">
                                                                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                stroke-width="2"
                                                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="flex flex-col">
                                                                <ux-label class="mb-1">
                                                                    <span t="translation:partial-views.medical-step-settings.labels.has-result"></span>
                                                                </ux-label>
                                                                <ux-combobox
                                                                    component.ref="resultSelectors[$index]"
                                                                    disabled.bind="!visibilityRequirements[$index].questionToCompare && !visibilityRequirements[$index].stepToCompare"
                                                                    autocomplete="true"
                                                                    data.bind="$index"
                                                                    valid.bind="validation.visibilityRequirements[$index].result"
                                                                    onselect.trigger="handleResultSelected($event)"
                                                                    t="[placeholder]partial-views.medical-step-settings.placeholders.select-result"
                                                                    value.bind="visibilityRequirements[$index].resultToCompare.value">
                                                                    <ux-combobox-option
                                                                        repeat.for="choice of visibilityRequirements[$index].attributes.choices | sort:'displayOrder'"
                                                                        value.bind="choice.value">
                                                                        ${ choice.value }
                                                                    </ux-combobox-option>
                                                                </ux-combobox>
                                                            </div>
                                                        </div>
                                                    </ux-card>
                                                </div>
                                            </li>
                                        </ul>
                                    </template>
                                    <div else>
                                        <div class="flex flex-col items-center justify-center gap-3 rounded-lg border border-gray-200 py-8">
                                            <p class="text-sm" t="translation:partial-views.medical-step-settings.messages.no-visibility-requirements"></p>
                                            <ux-button size="xs" click.trigger="addVisiblityRequirement()">
                                                <span t="translation:partial-views.medical-step-settings.buttons.add-visibility-requirement"></span>
                                            </ux-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Differential diagnosis -->
                            <div class="flex flex-1 flex-col gap-3 overflow-y-auto px-7 py-5" if.bind="tabs.dds.active">
                                <div class="col-item-12 flex flex-col">
                                    <div class="flex justify-between">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.medical-step-settings.labels.dd-requirements"></span>
                                        </ux-label>
                                        <ux-button if.bind="ddRequirements.length > 0" click.trigger="addDdRequirement()" padding.bind="false" size="xs">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                        </ux-button>
                                    </div>
                                    <template if.bind="ddRequirements.length > 0">
                                        <ul class="mt-2 flex flex-col gap-2">
                                            <li repeat.for="requirement of ddRequirements" class="flex rounded-lg border border-gray-200">
                                                <ux-card>
                                                    <div class="group flex w-full flex-col gap-y-3 rounded-lg p-5">
                                                        <div class="flex flex-col">
                                                            <ux-label class="mb-1">
                                                                <span t="translation:partial-views.medical-step-settings.labels.select-answer"></span>
                                                            </ux-label>
                                                            <div class="flex">
                                                                <ux-combobox
                                                                    data.bind="$index"
                                                                    rounded="left"
                                                                    autocomplete.bind="true"
                                                                    valid.bind="validation.ddRequirements[$index].answer"
                                                                    onselect.trigger="handleDdAnswerSelected($event)"
                                                                    t="[placeholder]partial-views.medical-step-settings.placeholders.select-dd-answer"
                                                                    used.bind="stepChoices.filter((x) => ddRequirements.some((y) => y.resultToCompare.value === x.value)).map((x) => x.value)"
                                                                    value.bind="requirement.resultToCompare.value">
                                                                    <ux-combobox-option repeat.for="choice of stepChoices" value.bind="choice.value"> ${ choice.value } </ux-combobox-option>
                                                                </ux-combobox>
                                                                <button
                                                                    class.bind="validation.ddRequirements[$index].answer ? 'border-gray-200' : 'border-red-500'"
                                                                    class="inline-flex items-center rounded-r-lg border-y border-r bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                                                    click.trigger="removeDdRequirement($index)">
                                                                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-col">
                                                            <ux-label class="mb-1">
                                                                <span t="translation:partial-views.medical-step-settings.labels.select-dd"></span>
                                                            </ux-label>
                                                            <bx-diagnosis-multi-selector
                                                                values.bind="requirement.differentialDiagnoses | getIds"
                                                                workspace.bind="authenticated.workspace.id"
                                                                language.bind="language"
                                                                valid.bind="validation.ddRequirements[$index].dd"
                                                                data.bind="$index"
                                                                on-change.bind="handleDdsChanged"
                                                                t="[placeholder]translation:partial-views.medical-step-settings.placeholders.select-dd">
                                                            </bx-diagnosis-multi-selector>
                                                        </div>
                                                    </div>
                                                </ux-card>
                                            </li>
                                        </ul>
                                    </template>
                                    <div else>
                                        <div class="flex flex-col items-center justify-center gap-3 rounded-lg border border-gray-200 py-8">
                                            <p class="text-sm" t="translation:partial-views.medical-step-settings.messages.no-dd-requirements"></p>
                                            <ux-button size="xs" click.trigger="addDdRequirement()">
                                                <span t="translation:partial-views.medical-step-settings.buttons.add-dd-requirement"></span>
                                            </ux-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Actions -->
                            <div class="flex flex-1 flex-col gap-3 overflow-y-auto px-7 py-5" if.bind="tabs.actions.active">
                                <div class="col-item-12 flex flex-col">
                                    <div class="flex justify-between">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.medical-step-settings.labels.action-requirements"></span>
                                        </ux-label>
                                        <ux-button if.bind="actionRequirements.length > 0" click.trigger="addActionRequirement()" padding.bind="false" size="xs">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                        </ux-button>
                                    </div>
                                    <template if.bind="actionRequirements.length > 0">
                                        <ul class="mt-2 flex flex-col gap-2">
                                            <li repeat.for="requirement of actionRequirements" class="flex rounded-lg border border-gray-200">
                                                <ux-card>
                                                    <div class="group flex w-full flex-col gap-y-3 rounded-lg p-5">
                                                        <div class="flex flex-col">
                                                            <ux-label class="mb-1">
                                                                <span t="translation:partial-views.medical-step-settings.labels.select-answer"></span>
                                                            </ux-label>
                                                            <div class="flex">
                                                                <ux-combobox
                                                                    data.bind="$index"
                                                                    rounded="left"
                                                                    autocomplete.bind="true"
                                                                    valid.bind="validation.actionRequirements[$index].answer"
                                                                    onselect.trigger="handleActionAnswerSelected($event)"
                                                                    t="[placeholder]partial-views.medical-step-settings.placeholders.select-action-answer"
                                                                    used.bind="stepChoices.filter((x) => actionRequirements.some((y) => y.resultToCompare.value === x.value)).map((x) => x.value)"
                                                                    value.bind="requirement.resultToCompare.value">
                                                                    <ux-combobox-option repeat.for="choice of stepChoices" value.bind="choice.value"> ${ choice.value } </ux-combobox-option>
                                                                </ux-combobox>
                                                                <button
                                                                    class.bind="validation.actionRequirements[$index].answer ? 'border-gray-200' : 'border-red-500'"
                                                                    class="inline-flex items-center rounded-r-lg border-y border-r bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                                                    click.trigger="removeActionRequirement($index)">
                                                                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-col">
                                                            <ux-label class="mb-1">
                                                                <span t="translation:partial-views.medical-step-settings.labels.select-action"></span>
                                                            </ux-label>
                                                            <bx-action-multi-selector
                                                                values.bind="requirement.actions | getIds"
                                                                workspace.bind="authenticated.workspace.id"
                                                                language.bind="language"
                                                                valid.bind="validation.actionRequirements[$index].action"
                                                                data.bind="$index"
                                                                used.bind="[active.id]"
                                                                on-change.bind="handleActionsChanged"
                                                                t="[placeholder]translation:partial-views.medical-step-settings.placeholders.select-action">
                                                            </bx-action-multi-selector>
                                                        </div>
                                                    </div>
                                                </ux-card>
                                            </li>
                                        </ul>
                                    </template>
                                    <div else>
                                        <div class="flex flex-col items-center justify-center gap-3 rounded-lg border border-gray-200 py-8">
                                            <p class="text-sm" t="translation:partial-views.medical-step-settings.messages.no-action-requirements"></p>
                                            <ux-button size="xs" click.trigger="addActionRequirement()">
                                                <span t="translation:partial-views.medical-step-settings.buttons.add-action-requirement"></span>
                                            </ux-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Prices -->
                            <div class="flex flex-1 flex-col gap-3 overflow-y-auto px-7 py-5" if.bind="tabs.prices.active">
                                <div class="col-item-12 flex flex-col">
                                    <div class="flex justify-between">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.prices"></span>
                                        </ux-label>
                                        <ux-button if.bind="prices.length > 0" click.trigger="addPrice()" padding.bind="false" size="xs">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                        </ux-button>
                                    </div>
                                    <template if.bind="prices.length > 0">
                                        <ul class="mt-2 flex flex-col gap-2">
                                            <li repeat.for="price of prices" class="flex rounded-lg border border-gray-200">
                                                <div class="flex w-full flex-1 flex-col rounded-lg border-b-2 border-r-2 border-gray-200 bg-white shadow-sm">
                                                    <div class="flex flex-col gap-3 p-5">
                                                        <div class="flex flex-col">
                                                            <ux-label class="mb-1">
                                                                <span t="translation:partial-views.medical-step-settings.labels.select-answer"></span>
                                                            </ux-label>
                                                            <div class="flex">
                                                                <ux-combobox
                                                                    data.bind="$index"
                                                                    rounded="left"
                                                                    autocomplete.bind="true"
                                                                    valid.bind="validation.prices[$index].answer"
                                                                    onselect.trigger="handlePriceAnswerSelected($event)"
                                                                    t="[placeholder]partial-views.medical-step-settings.placeholders.select-dd-answer"
                                                                    used.bind="stepChoices.filter((x) => prices.some((y) => y.resultToCompare.value === x.value)).map((x) => x.value)"
                                                                    value.bind="price.resultToCompare.value">
                                                                    <ux-combobox-option repeat.for="choice of stepChoices" value.bind="choice.value"> ${ choice.value } </ux-combobox-option>
                                                                </ux-combobox>
                                                                <button
                                                                    class.bind="validation.prices[$index].answer ? 'border-gray-200' : 'border-red-500'"
                                                                    class="inline-flex items-center rounded-r-lg border-y border-r bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                                                    click.trigger="removePrice($index)">
                                                                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-col">
                                                            <ux-label class="mb-1">
                                                                <span t="translation:partial-views.medical-step-settings.labels.invoice-line"></span>
                                                            </ux-label>
                                                            <ux-textarea
                                                                value.two-way="price.invoiceDescription"
                                                                rows="1"
                                                                valid.bind="validation.prices[$index].invoiceDescription"
                                                                t="[placeholder]translation:partial-views.medical-step-settings.placeholders.invoice-line">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                                                </svg>
                                                            </ux-textarea>
                                                        </div>
                                                        <div class="flex flex-col">
                                                            <div class="mb-3 flex items-center justify-between">
                                                                <ux-label>
                                                                    <span t="translation:global.labels.healthcare-codes"></span>
                                                                </ux-label>
                                                                <ux-button if.bind="price.codes.length > 0" click.trigger="addCode($index)" padding.bind="false" size="xs">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                                    </svg>
                                                                </ux-button>
                                                            </div>
                                                            <ul if.bind="price.codes.length > 0" class="flex flex-col space-y-1">
                                                                <li repeat.for="code of price.codes" class="flex w-full">
                                                                    <div class="relative flex flex-1 items-center rounded-lg bg-gray-50">
                                                                        <button
                                                                            click.trigger="editCode($parent.$index, $index)"
                                                                            class="flex h-full flex-1 items-center gap-6 rounded-l-lg border-y border-l border-gray-200 px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                                            <div class="flex items-center text-sm">${code.system}/${code.value}</div>
                                                                        </button>
                                                                        <button
                                                                            click.trigger="removeCode($parent.$index, $index)"
                                                                            class="flex w-10 items-center justify-center rounded-r-lg border border-gray-200 bg-gray-50 p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                                                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="2"
                                                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <button
                                                                else
                                                                click.trigger="addCode($index)"
                                                                type="button"
                                                                class="text-gray-800.class relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                                                                </svg>

                                                                <span class="block text-sm font-semibold" t="translation:partial-views.medical-step-settings.buttons.add-code"></span>
                                                            </button>
                                                        </div>
                                                        <div class="flex flex-col">
                                                            <div class="mb-2 flex items-center justify-between">
                                                                <ux-label class="mb-1">
                                                                    <span t="translation:global.labels.prices"></span>
                                                                </ux-label>
                                                                <ux-button if.bind="price.values.length > 0" click.trigger="createOrEditPrice($index)" padding.bind="false" size="xs">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                                    </svg>
                                                                </ux-button>
                                                            </div>
                                                            <div class="flex flex-col gap-2">
                                                                <ul if.bind="price.values.length > 0" class="flex w-full flex-col gap-1">
                                                                    <li class="flex w-full" repeat.for="item of price.values">
                                                                        <div class="relative flex flex-1 items-center rounded-lg bg-gray-50">
                                                                            <let description.bind="formatDescription(item)"></let>
                                                                            <button
                                                                                click.trigger="createOrEditPrice($parent.$index, $index)"
                                                                                class="flex h-full flex-1 items-center gap-6 rounded-l-lg border-y border-l border-gray-200 px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                                                <div class="flex items-center text-sm">
                                                                                    <p class="mr-3 text-gray-800">${item.netValue | currency}</p>
                                                                                    <p class="text-xs text-gray-500">${description}</p>
                                                                                </div>
                                                                            </button>
                                                                            <button
                                                                                click.trigger="removePriceValue($parent.$index, $index)"
                                                                                class="flex w-10 items-center justify-center rounded-r-lg border border-gray-200 bg-gray-50 p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                                                                <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                        stroke-width="2"
                                                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <button
                                                                    else
                                                                    click.trigger="createOrEditPrice($index)"
                                                                    type="button"
                                                                    border-gray-300.class="validation.prices[$index].values"
                                                                    hover:border-gray-400.class="validation.prices[$index].values"
                                                                    active:border-gray-500.class="validation.prices[$index].values"
                                                                    text-gray-800.class="validation.prices[$index].values"
                                                                    border-red-500.class="!validation.prices[$index].values"
                                                                    hover:border-red-600.class="!validation.prices[$index].values"
                                                                    active:border-red-700.class="!validation.prices[$index].values"
                                                                    text-red-500.class="!validation.prices[$index].values"
                                                                    class="relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed p-3 text-center focus:outline-none">
                                                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                    </svg>
                                                                    <span class="block text-sm font-semibold" t="translation:partial-views.medical-therapies.buttons.add-price"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </template>
                                    <div else>
                                        <div class="flex flex-col items-center justify-center gap-3 rounded-lg border border-gray-200 py-8">
                                            <p class="text-sm" t="translation:partial-views.medical-step-settings.messages.no-prices"></p>
                                            <ux-button size="xs" click.trigger="addPrice()">
                                                <span t="translation:partial-views.medical-step-settings.buttons.add-price"></span>
                                            </ux-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div else class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
                <div if.bind="baseLoaded" class="flex items-center border-t border-gray-200 px-5 py-4">
                    <div class="flex w-full items-center justify-end gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="primary"
                            disabled.bind="isLoading || deleting"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.process"></span>
                        </ux-button>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>
