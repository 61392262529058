import { MedicalExaminationTemplateItemStep } from '@wecore/sdk-healthcare';

export interface ICopyTemplateItem {
    item: MedicalExaminationTemplateItemStep;
    array: MedicalExaminationTemplateItemStep[];
    index: number;
    validation: any[];
    validationItem: any;
    command: 'copy' | 'cut';
}

export class CopyTemplateItem {
    public item: MedicalExaminationTemplateItemStep;
    public array: MedicalExaminationTemplateItemStep[];
    public index: number;
    public validation: any[];
    public validationItem: any;
    public command: 'copy' | 'cut';

    public constructor(data: ICopyTemplateItem) {
        this.item = data?.item;
        this.array = data?.array;
        this.index = data?.index;
        this.validation = data?.validation;
        this.validationItem = data?.validationItem;
        this.command = data?.command;
    }
}
