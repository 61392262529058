import { I18N } from '@aurelia/i18n';
import { GetPatientResponse } from '@wecore/sdk-healthcare';
import { bindable, containerless, inject } from 'aurelia';
import { checkForCustomDescriptions } from '../../../../infra/utilities';

@containerless
@inject(I18N)
export class TabPatientsAddresses {
    @bindable() public patient: GetPatientResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;

    public descriptions: { [key: string]: string }[];

    public constructor(
        public t: I18N //
    ) {}

    public bound(): void {
        const descriptions = [
            {
                nl: this.t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.home-address', { lng: 'en' })
            },
            {
                nl: this.t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];

        this.descriptions = checkForCustomDescriptions(this.t, this.language, descriptions, this.patient, 'address');
    }
}
