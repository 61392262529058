import {
    GetMedicalQuestionnaireResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalQuestionnairesApiClient,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { PartialViews } from '../../../../../infra/partial-views';
import { generateColumns, getSettingsId } from '../../../../../infra/utilities';
import { FlattenedExaminationStep } from '../../../../../models/flattened-examination-step';
import { PartialView } from '../../../../../models/partial-view';
import { SelectedFile } from '../../../../../models/selected-file';
import { StepState } from '../../../../../models/step-state';
import { ViewOptions } from '../../../../../models/view-options';

@containerless
@inject(MedicalQuestionnairesApiClient, IEventAggregator)
export class TemplateQuestionnaire {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public flow: MedicalExaminationFlow | MedicalTherapyEvaluationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep | MedicalTherapyEvaluationItem;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public phaseIndex: number;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public remove: (stepId: string) => Promise<void>;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;

    public columns: string = 'col-span-12';
    public recent: GetMedicalQuestionnaireResponse;
    public shouldBeDisplayed: boolean = false;
    public isAnswered: boolean = false;
    public required: boolean = false;
    private subscriptions: any[] = [];

    public constructor(
        private readonly questionnairesApi: MedicalQuestionnairesApiClient, //
        private events: IEventAggregator
    ) {}

    public attached(): void {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === getSettingsId(this.step)) || [] //
        );
        if (isNotDefined(this.step.attributes)) this.step.attributes = {};
        if (isNotDefined(this.states[this.step.id]))
            this.states[this.step.id] = new StepState({
                stepId: this.step.id, //
                expanded: false,
                answered: false
            });

        this.questionnairesApi.getById(this.step.questionnaire.id, this.workspace).then((questionnaire) => (this.recent = questionnaire));

        // Subscribe to the ExaminationStepAnswered event so that we can evaluate
        // whether or not to show the current questionnaire when a step is answered.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => {
                this.evaluateSettings();
                this.checkForAnsweredQuestions();
            })
        ];

        // Evaluate the requirements on load of the questionnaire.
        this.evaluateSettings();
        this.checkForAnsweredQuestions();
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.states[this.step.id].expanded = !this.states[this.step.id].expanded;
        if (this.states[this.step.id].expanded) {
            if (isNotDefined(this.record.examination.template.phases[this.phaseIndex])) return;

            const steps = this.record.examination.template.phases[this.phaseIndex].stepsToTake.map((x) => x.id);
            for (const step of steps) {
                if (step !== this.step.id) this.states[step].expanded = false;
            }
        }
    }

    public async openInformationSheet(): Promise<void> {
        await this.addPartial(
            PartialViews.InformationSheet.with({
                config: {
                    mode: 'view',
                    language: this.language
                },
                sheet: this.recent.informationSheet
            }),
            new ViewOptions({
                scrollToView: true,
                markItem: true,
                replace: true
            })
        );
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.step.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;
        this.required = flattened?.isRequired ?? false;

        if (!this.shouldBeDisplayed) {
            for (const item of this.registration.questionnaire.questions) {
                item.question.givenAnswer.value = null;
                item.question.givenAnswers = [];

                // TODO: delete the files of the questions is there are any?
            }
        }
    }

    private checkForAnsweredQuestions(): void {
        // When dynamically adding a questionnaire, the registration is not yet set.
        // This will be set after the reload is fully done.
        if (isNotDefined(this.registration)) return;

        this.isAnswered = this.registration.questionnaire.questions.some(
            (item) =>
                (isDefined(item.question.givenAnswer) && isDefined(item.question.givenAnswer.value) && isNotEmpty(item.question.givenAnswer.value)) || //
                (isDefined(item.question.givenAnswers) && item.question.givenAnswers.any()) ||
                (isDefined(item.question.attachments) && item.question.attachments.any()) ||
                (isDefined(item.attributes) && isDefined(item.attributes.uploads) && item.attributes.uploads.any())
        );
    }
}
