import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient, BlobStorageAttachment } from '@wecore/sdk-attachments';
import { GetUserPostResponse } from '@wecore/sdk-core';
import { guid, isDefined, isFunction, serveBlob } from '@wecore/sdk-utilities';
import { bindable, inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { ErrorHandler } from '../../../infra/error-handler';
import { uploadAttachment } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { AuthenticationService } from '../../../services/service.authentication';
import { ModalService } from '../../../services/service.modals';

@inject(ErrorHandler, AureliaConfiguration, ModalService, AttachmentsApiClient, I18N, AuthenticationService)
export class TemplatePostAttachments {
    @bindable() public post: GetUserPostResponse;
    @bindable() public uploads: any[];
    @bindable() public attachments: BlobStorageAttachment[];
    @bindable() public canDelete: boolean = false;
    @bindable() public workspace: string;
    @bindable() public borderBottom: boolean = true;
    @bindable() public roundedBottom: boolean = true;
    @bindable() public borderTop: boolean = false;
    @bindable() public onAttachmentDelete: (index: number) => Promise<void>;

    public picker: HTMLInputElement;

    public constructor(
        private readonly errorHandler: ErrorHandler, //
        private readonly config: AureliaConfiguration,
        private readonly modalService: ModalService,
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly t: I18N,
        private readonly auth: AuthenticationService
    ) {}

    public select(): void {
        this.picker.click();
    }

    public handleFileSelected = (e: Event): void => {
        const target = e.target as HTMLInputElement;

        for (const file of target.files) {
            const upload = {
                id: guid(),
                progress: 0,
                isUploading: false,
                file,
                extension: `.${file.name.split('.').pop()}`,
                name: file.name
            };
            this.uploads.push(upload);
        }

        this.picker.value = null;
    };

    public async startUploadFor(postId: string): Promise<boolean> {
        let success = true;
        for (let index = 0; index < this.uploads.length; index++) {
            const upload = this.uploads[index];
            this.uploads[index].isUploading = true;
            const result = await this.upload(postId, upload, index);
            if (!result) success = false;
        }

        return success;
    }

    private async upload(postId: string, upload: any, index: number): Promise<BlobStorageAttachment> {
        try {
            return await uploadAttachment(
                postId, //
                this.workspace,
                upload,
                AttachmentEntities.UserPosts,
                (percentage: number) => (this.uploads[index].progress = percentage),
                this.isImage(upload.file.type),
                this.config,
                this.auth
            );
        } catch (e) {
            this.errorHandler.handle('upload-user-post-attachment', { e, upload });
        }
    }

    public deleteFromUploads(index: number): void {
        this.uploads.splice(index, 1);
    }

    public async deleteAttachment(index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.user-posts.questions.delete-attachment.title'),
                message: this.t.tr('translation:partial-views.user-posts.questions.delete-attachment.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        if (isFunction(this.onAttachmentDelete)) await this.onAttachmentDelete(index);
                    }
                }
            })
        );
    }

    public async download(index: number): Promise<void> {
        const attachment = this.attachments[index];
        if (isDefined(attachment)) {
            const blob = await this.attachmentsApi.download(this.post.id, attachment.id, this.workspace, AttachmentEntities.UserPosts);
            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
        }
    }

    private isImage(contentType: string): boolean {
        const imageTypes = [
            'image/jpg', //
            'image/jpeg',
            'image/png'
        ];
        return imageTypes.some((x) => x === contentType);
    }
}
