import { Operators } from '@wecore/sdk-healthcare';

export class OperatorToStringValueConverter {
    public toView(operator: Operators): string {
        switch (operator) {
            case Operators.Or:
                return 'translation:global.labels.operators.or';
            case Operators.And:
                return 'translation:global.labels.operators.and';
        }
    }
}
