import { GetUserPostResponse, UserPostsApiClient, UserRoles } from '@wecore/sdk-core';
import { GetHealthcareTaskListResponse, GetHealthcareTaskResponse } from '@wecore/sdk-healthcare';
import { isDefined, isFunction } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { addDays, startOfDay } from 'date-fns';
import { CustomEvents } from '../../../infra/events';
import { TemplateTaskList } from '../template-task-list/template-task-list';

@containerless
@inject(UserPostsApiClient, IEventAggregator)
export class TemplateTask {
    @bindable() public list: GetHealthcareTaskListResponse;
    @bindable() public task: GetHealthcareTaskResponse;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public isLast: boolean = false;
    @bindable() public loading: boolean = false;
    @bindable() public index: number;
    @bindable() public selected: GetHealthcareTaskResponse[];
    @bindable() public state: { isDraggingTask: boolean; isDraggingList: boolean };
    @bindable() public lists: TemplateTaskList[];
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public onSelect: (task: GetHealthcareTaskResponse) => Promise<void>;
    @bindable() public onEdit: (task: GetHealthcareTaskResponse) => Promise<void>;
    @bindable() public openUserPosts: (task: GetHealthcareTaskResponse) => Promise<void>;
    @bindable() public onDragStart: (e: Event, task: GetHealthcareTaskResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDrag: (e: Event, task: GetHealthcareTaskResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDragEnd: (e: Event, task: GetHealthcareTaskResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDragCanceled: (e: KeyboardEvent, task: GetHealthcareTaskResponse) => Promise<void>;

    public pointOfTime: 'future' | 'past' | 'closed' | 'warning' = 'future';
    public posts: GetUserPostResponse[];
    public baseLoaded: boolean = false;
    public subscriptions: IDisposable[] = [];
    public container: HTMLDivElement;
    public UserRoles: typeof UserRoles = UserRoles;

    private moveCb: (e: MouseEvent) => void;
    private moveEndCb: (e: MouseEvent) => void;
    private moveKeyCb: (e: KeyboardEvent) => void;
    private debounce: NodeJS.Timeout;

    public constructor(
        private readonly postsApi: UserPostsApiClient, //
        private readonly events: IEventAggregator
    ) {}

    public async bound(): Promise<void> {
        if (this.loading) return;

        if (isDefined(this.task.deadline)) {
            if (this.task.isClosed) this.pointOfTime = 'closed';
            else {
                // First if the deadline is in the future and more than 14 days away
                if (startOfDay(this.task.deadline) >= addDays(startOfDay(new Date()), 14)) this.pointOfTime = 'future';
                // Then if the deadline is in the future and less than 14 days away
                else if (startOfDay(this.task.deadline) >= startOfDay(new Date())) this.pointOfTime = 'warning';
                // Then if the deadline is in the past
                else this.pointOfTime = 'past';
            }
        }

        await this.getPosts();
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.UserPostsCreated, () => this.getPosts()), //
            this.events.subscribe(CustomEvents.UserPostsDeleted, () => this.getPosts()), //
            this.events.subscribe(CustomEvents.UserPostsReplied, () => this.getPosts())
        ];

        this.baseLoaded = true;
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public handleEdit(): void {
        if (isFunction(this.onEdit)) this.onEdit(this.task);
    }

    public selectTask(): void {
        if (this.selected.some((x) => x.id === this.task.id)) this.selected = this.selected.filter((x) => x.id !== this.task.id);
        else this.selected = [...this.selected, this.task];

        if (isFunction(this.onSelect)) this.onSelect(this.task);
    }

    public openPosts(): void {
        if (isFunction(this.openUserPosts)) this.openUserPosts(this.task);
    }

    public async handleMoveMouseDown(e: MouseEvent): Promise<void> {
        // Only left click.
        if (e.button !== 0) return;
        // We must distinguish between a click and a drag.
        // Record the initial mouse position.
        const startX = e.clientX;
        const startY = e.clientY;
        const handleMouseMove = async (event: MouseEvent): Promise<void> => {
            // Calculate the current mouse position.
            const currentX = event.clientX;
            const currentY = event.clientY;
            // Check if the mouse has moved more than 5 pixels in either direction
            if (Math.abs(currentX - startX) > 5 || Math.abs(currentY - startY) > 5) {
                this.state.isDraggingTask = true;
                // Remove the event listener that listens for the initial mouse down event.
                // Otherwise the event listener will be called multiple times.
                document.removeEventListener('mousemove', handleMouseMove);
                // Lets start the dragging process.
                this.container.classList.add('!cursor-move');
                await this.onDragStart(e, this.task, this.container);
                // Save the callbacks otherwise removeEventListener won't work.

                this.moveCb = (e) => this.handleMoveMouseMove(e);
                this.moveEndCb = (e) => this.handleMoveMouseUp(e);
                this.moveKeyCb = (e) => this.handleMoveKeyEvent(e);
                // Add the event listeners.
                document.addEventListener('mousemove', this.moveCb);
                document.addEventListener('mouseup', this.moveEndCb, { once: true });
                document.addEventListener('keydown', this.moveKeyCb, { once: true });
            }
        };

        const handleMouseUp = async () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            // Check if the task is already being dragged, otherwise the task is clicked.
            if (this.state.isDraggingTask) return;
            // Clicked/
        };

        // Lets listen for the mouse move event.
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    private async handleMoveMouseMove(e: MouseEvent): Promise<void> {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
            if (this.state.isDraggingTask) await this.onDrag(e, this.task, this.container);
        });
    }

    private async handleMoveMouseUp(e: MouseEvent): Promise<void> {
        this.state.isDraggingTask = false;
        this.container.classList.remove('!cursor-move');
        await this.onDragEnd(e, this.task, this.container);
        if (isDefined(this.container)) this.container.classList.remove('hidden');

        document.removeEventListener('mousemove', this.moveCb);
        document.removeEventListener('mouseup', this.moveEndCb);
        document.removeEventListener('keydown', this.moveKeyCb);
    }

    private async handleMoveKeyEvent(e: KeyboardEvent): Promise<void> {
        if (!this.state.isDraggingTask) return;

        await this.onDragCanceled(e, this.task);

        if (e.key === 'Escape' && isDefined(this.container)) {
            this.container.classList.remove('hidden');
            this.container.classList.remove('!cursor-move');

            document.removeEventListener('mousemove', this.moveCb);
            document.removeEventListener('mouseup', this.moveEndCb);
            document.removeEventListener('keydown', this.moveKeyCb);
        }
    }

    private async getPosts(): Promise<void> {
        const [posts] = await Promise.all([
            this.postsApi.search(this.workspace, 500, 0, undefined, undefined, undefined, undefined, [this.task.id], undefined, true) //
        ]);
        this.posts = posts.data;
    }
}
