<ux-combobox
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    onselect.trigger="handleSelect($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="item of items" value.bind="item.id">${ item.displayName }</ux-combobox-option>
</ux-combobox>
