import { PriorityTypes } from '@wecore/sdk-healthcare';

export class PriorityTypeToStringValueConverter {
    public toView(type: PriorityTypes): string {
        switch (type) {
            case PriorityTypes.Low:
                return 'translation:global.labels.priority-types.low';
            case PriorityTypes.Medium:
                return 'translation:global.labels.priority-types.medium';
            case PriorityTypes.High:
                return 'translation:global.labels.priority-types.high';
            case PriorityTypes.Urgent:
                return 'translation:global.labels.priority-types.urgent';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
