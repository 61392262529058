export interface IViewOptions {
    /**
     * The index on where to added the partial view to.
     */
    index?: number;

    /**
     * A flag indicating whether or not to scroll to the partial view after adding it to the view engine.
     */
    scrollToView?: boolean;

    /**
     * A flag indicating whether or not replace the partial view in case it already exsist in the view engine.
     */
    replace?: boolean;

    /**
     * A flag indicating whether or not to temporary mark the partial view in de the partival view router
     * after adding to the view engine.
     */
    markItem?: boolean;

    /**
     * A flag indicating whether or not to update the url with the route of the view that is opened.
     */
    updateUrl?: boolean;

    /**
     * A flag indicating whether or not the partial view was added by the base view.
     */
    addedByBaseView?: boolean;
}

export class ViewOptions {
    /**
     * The index on where to added the partial view to.
     */
    public index: number;

    /**
     * A flag indicating whether or not to scroll to the partial view after adding it to the view engine.
     */
    public scrollToView: boolean = true;

    /**
     * A flag indicating whether or not replace the partial view in case it already exsist in the view engine.
     */
    public replace: boolean = false;

    /**
     * A flag indicating whether or not to temporary mark the partial view in de the partival view router
     * after adding to the view engine.
     */
    public markItem: boolean = false;

    /**
     * A flag indicating whether or not to update the url with the route of the view that is opened.
     */
    public updateUrl?: boolean = true;

    /**
     * A flag indicating whether or not the partial view was added by the base view.
     */
    public addedByBaseView?: boolean = false;

    public constructor(data: IViewOptions) {
        this.scrollToView = data?.scrollToView ?? true;
        this.index = data?.index;
        this.markItem = data?.markItem;
        this.replace = data?.replace;
        this.updateUrl = data?.updateUrl ?? true;
        this.addedByBaseView = data?.addedByBaseView ?? false;
    }
}
