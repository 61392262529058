<import from="converters/translate" />
<import from="converters/mark-words" />
<import from="converters/keys" />
<import from="converters/side-to-string" />
<import from="bx/bx-anatomical-region-selector/bx-anatomical-region-selector" />
<import from="./templates/template-action/template-action" />
<import from="./templates/template-question/template-question" />
<import from="./templates/template-questionnaire/template-questionnaire" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="${ isCollecting ? '' : 'pt-[5px]'} flex flex-col" css.bind="styles">
        <ux-bar-loader if.bind="isCollecting"></ux-bar-loader>
        <div class="flex flex-1 gap-0.5 overflow-hidden">
            <div class="flex w-[500px] flex-col overflow-hidden pt-5">
                <div pr-4.class="items.length > 0" pr-8.class="items.length === 0" ref="scrollContainer" class="flex flex-1 flex-col overflow-y-auto pl-8">
                    <div class="sticky top-0 z-10 flex flex-col gap-1 border-b border-gray-200 bg-white pb-2">
                        <div class="flex items-start justify-between gap-x-3">
                            <p class="font-medium text-gray-400" t="translation:partial-views.medical-suggestions.title"></p>
                        </div>
                        <div class="mt-2 flex">
                            <ux-input
                                debounce="450"
                                allow-clear="true"
                                oninput.trigger="handleSearch($event)"
                                onclear.trigger="handleSearch($event)"
                                t="[placeholder]translation:partial-views.medical-suggestions.placeholders.search">
                            </ux-input>
                        </div>
                    </div>
                    <div class="flex flex-1 flex-col">
                        <ul if.bind="baseLoaded" class="mt-1 flex flex-col space-y-1.5">
                            <li repeat.for="suggestion of suggestions" class="flex border-b border-gray-200 text-sm">
                                <!-- <let added.bind="isAlreadyAdded(suggestion)"></let>
                                <div if.bind="added" class="flex min-h-[3rem] flex-1 items-center justify-between pb-2 pt-1">
                                    <div class="flex items-center justify-between flex-1">
                                        <div class="flex flex-col">
                                            <p class="font-bold">${suggestion.name | translate:language}</p>
                                            <p class="text-xs text-red-500" t="translation:partial-views.medical-suggestions.messages.already-added"></p>
                                        </div>
                                    </div>
                                </div> -->
                                <a href="javascript:" click.trigger="handleSuggestionSelected(suggestion)" class="flex min-h-[3rem] flex-1 items-center justify-between pb-2 pt-1">
                                    <div class="flex flex-1 items-center justify-between">
                                        <div class="flex flex-col">
                                            <p class="font-bold">${suggestion.name | translate:language}</p>
                                            <template if.bind="text">
                                                <p if.bind="suggestion.keywords && suggestion.keywords.length > 0" class="text-xs" innerhtml.bind="suggestion.keywords | markWords:text"></p>
                                                <p
                                                    if.bind="suggestion.synonyms && suggestion.synonyms.length > 0"
                                                    class="text-xs"
                                                    innerhtml.bind="suggestion.synonyms | markWords:text:'text-purple-500'"></p>
                                            </template>
                                            <template if.bind="query">
                                                <p if.bind="suggestion.keywords && suggestion.keywords.length > 0" class="text-xs" innerhtml.bind="suggestion.keywords | markWords:query"></p>
                                                <p
                                                    if.bind="suggestion.synonyms && suggestion.synonyms.length > 0"
                                                    class="text-xs"
                                                    innerhtml.bind="suggestion.synonyms | markWords:query:'text-purple-500'"></p>
                                            </template>
                                            <template if.bind="!query && !text">
                                                <p if.bind="suggestion.keywords && suggestion.keywords.length > 0" class="text-xs" innerhtml.bind="suggestion.keywords | markWords:query"></p>
                                                <p
                                                    if.bind="suggestion.synonyms && suggestion.synonyms.length > 0"
                                                    class="text-xs"
                                                    innerhtml.bind="suggestion.synonyms | markWords:query:'text-purple-500'"></p>
                                            </template>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li if.bind="isLoading" class="flex justify-center py-5">
                                <ux-spinner size="xs"></ux-spinner>
                            </li>
                            <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                                <span class="text-sm" t="global.labels.end-of-list"></span>
                            </li>
                            <li class="mt-2 flex items-center justify-center rounded-lg bg-gray-100 p-3" if.bind="suggestions.length === 0">
                                <p class="text-sm" t="translation:partial-views.medical-suggestions.messages.no-suggestions-found"></p>
                            </li>
                        </ul>
                        <div else class="mt-3">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex w-full flex-col space-y-5 py-1">
                                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div if.bind="items.length > 0 || isCollecting" class="flex h-full flex-1 flex-col overflow-hidden pb-4 pr-8 pt-8">
                <div class="w-full flex-1 overflow-y-auto rounded-lg bg-gray-50 p-8">
                    <template repeat.for="item of items">
                        <div class="my-2 flex flex-col rounded-lg border border-gray-200 bg-white p-5">
                            <div class="flex flex-col gap-1 border-gray-200 bg-white pb-2">
                                <div class="flex items-start justify-between">
                                    <div class="flex flex-col">
                                        <span class="text-xs text-gray-500" t="translation:global.labels.differential-diagnosis"></span>
                                        <p class="font-medium text-gray-800">${item.suggestion.name | translate:language}</p>
                                    </div>
                                    <div class="flex gap-1">
                                        <ux-button mode="outline" color="danger" padding.bind="false" click.trigger="removeStep($index)">
                                            <div class="flex items-center justify-center p-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 text-red-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </ux-button>
                                        <ux-button mode="outline" color="dark" padding.bind="false" click.trigger="toggleSteps($index)">
                                            <div -rotate-180.class="!item.expanded" class="flex items-center justify-center p-1 transition-all duration-150">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                </svg>
                                            </div>
                                        </ux-button>
                                    </div>
                                </div>
                                <div class="mt-4 flex flex-col">
                                    <ux-label class="mb-1">
                                        <span t="translation:partial-views.medical-suggestions.labels.bodyside"></span>
                                    </ux-label>
                                    <ux-select
                                        allow-clear.bind="true"
                                        data.bind="$index"
                                        onselect.trigger="handleSideSelected($event)"
                                        onclear.trigger="handleSideSelected($event)"
                                        value.to-view="side.value">
                                        <ux-select-option repeat.for="side of BodySides | keys" value.bind="side"> ${ side | sideToString | t } </ux-select-option>
                                    </ux-select>
                                </div>
                                <div class="mt-2 flex flex-col">
                                    <ux-label class="mb-1">
                                        <span t="translation:partial-views.medical-suggestions.labels.location"></span>
                                    </ux-label>
                                    <bx-anatomical-region-selector
                                        on-select.bind="handleRegionSelected"
                                        language.bind="language"
                                        allow-clear="true"
                                        data.bind="$index"
                                        workspace.bind="authenticated.workspace.id"
                                        t="[placeholder]translation:partial-views.medical-suggestions.placeholders.select-location"
                                        value.to-view="result.location.id">
                                    </bx-anatomical-region-selector>
                                </div>
                                <p class="mt-1 text-xs text-gray-600" t="translation:partial-views.medical-suggestions.labels.added-steps"></p>
                            </div>
                            <div if.bind="item.expanded" class="mt-1 flex flex-col">
                                <template if.bind="item.steps.length > 0">
                                    <div class="grid w-full grid-cols-12 gap-1">
                                        <template repeat.for="step of item.steps">
                                            <template-action if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Action" step.bind="step" language.bind="language"> </template-action>
                                            <template-question if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Question" step.bind="step" language.bind="language"> </template-question>
                                            <template-questionnaire if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire" step.bind="step" language.bind="language">
                                            </template-questionnaire>
                                        </template>
                                    </div>
                                </template>
                                <template else>
                                    <div>
                                        <div class="rounded-lg bg-red-100 p-3">
                                            <p class="text-xs text-red-800" t="translation:partial-views.medical-suggestions.messages.no-steps-to-add"></p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div else class="mt-1 flex items-center gap-1 rounded-lg bg-gray-100 px-3 py-1.5 text-sm">
                                <div class="flex flex-1 items-center gap-1">
                                    <p class="font-medium text-gray-800">${item.steps.length}</p>
                                    <p if.bind="item.steps.length === 1" class="font-medium lowercase text-gray-800" t="translation:global.labels.step"></p>
                                    <p else class="font-medium lowercase text-gray-800" t="translation:global.labels.steps"></p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template if.bind="!items || items.length === 0 || isCollecting">
                        <div class="flex h-full flex-1 flex-col items-center justify-center px-32">
                            <ux-spinner size="xs" if.bind="isCollecting"></ux-spinner>
                            <div else class="text-center text-xs text-gray-400">
                                <p if.bind="text" t="translation:partial-views.medical-suggestions.messages.by-text"></p>
                                <p else t="translation:partial-views.medical-suggestions.messages.by-query"></p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="flex justify-end gap-x-2 border-t border-gray-200 bg-white px-8 py-3">
            <div class="flex gap-x-2">
                <ux-button size="sm" color="dark" mode="outline" click.trigger="cancel()">
                    <span class="pointer-events-none" t="global.buttons.cancel"></span>
                </ux-button>
                <ux-button size="sm" color="primary" disabled.bind="items.length === 0" click.trigger="addToExamination()">
                    <span class="pointer-events-none" t="translation:partial-views.medical-suggestions.buttons.add-to-examination"></span>
                </ux-button>
            </div>
        </div>
    </section>
</template>
