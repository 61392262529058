import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    Phone,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, resetValidation } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { cloneDeep } from '../../../../../infra/utilities';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
@inject(I18N)
export class WidgetPatientPhones {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public descriptions: { [key: string]: string }[];

    public constructor(
        public t: I18N //
    ) {
        this.descriptions = [
            {
                nl: t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.private', { lng: 'nl' }),
                en: t.tr('translation:global.labels.private', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.mobile', { lng: 'nl' }),
                en: t.tr('translation:global.labels.mobile', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];
    }

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        this.validation.values = this.patient.phones.map((x) => ({
            valid: true,
            email: true,
            phone: true
        }));

        // Combine default descriptions with custom descriptions
        // that are used by the user.
        const descriptions = [
            ...this.descriptions, //
            ...this.patient.phones.map((x) => x.translations)
        ] // Filter out the custom option
            .filter((x) => x[this.language] !== this.t.tr('translation:global.labels.custom', { lng: this.language }))
            .orderBy((x) => x[this.language]);

        // Re-add the custom option on the end and make
        // sure there aren't any duplicates.
        this.descriptions = [
            ...descriptions,
            {
                nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), //
                en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ].distinct((x) => x[this.language]);

        // Make sure every phone number has a default calling code.
        this.registration.widget.result.value = cloneDeep(this.patient.phones).map((phone) => {
            if (isNotDefined(phone.callingCode)) phone.callingCode = '31';
            return phone;
        });

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Array;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientPhoneNumbers,
                onSave: async (): Promise<void> => {
                    if (isNotDefined(this.registration.widget.result.value)) return;
                    // Filter out the phone numbers that don't have a value entered.
                    this.registration.widget.result.value = this.registration.widget.result.value
                        .filter((phone: Phone) => {
                            const cleaned = phone.value?.replace(/_/g, '');
                            return isDefined(cleaned) && isNotEmpty(cleaned);
                        })
                        .map(Phone.fromJS);
                    // Save the phone numbers to the patient and
                    // let all listeners know the patient has been updated.
                    this.patient.phones = this.registration.widget.result.value;
                },
                validate: (result: WidgetResult, validation: any): boolean => {
                    for (let index = 0; index < result.value.length; index++) {
                        // Reset the validation.
                        resetValidation(validation.values[index]);
                        // Remove mask placeholder characters.
                        const phone = result.value[index].value?.replace(/_/g, '') || undefined;
                        // Only validate if user entered something.
                        if (isNotDefined(phone) || isEmpty(phone)) continue;
                        // Validate the phone number
                        validation.values[index].phone = phone.length === 9;
                        validation.values[index].valid = validation.values[index].phone;
                    }

                    return validation.values.every((x: any) => x.valid);
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
