<template>
    <div class="flex h-[60px] w-screen border-b-1 border-gray-200 bg-white">
        <div class="flex h-full">
            <au-slot name="logo" />
        </div>
        <div class="h-full flex-1">
            <au-slot />
        </div>
        <div>
            <au-slot name="side" />
        </div>
    </div>
</template>
