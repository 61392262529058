import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { Address, Email, Phone } from '@wecore/sdk-crm';
import { CreatePracticeLocationRequest, ExaminationRoom, PracticeLocationsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, cloneDeep, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PracticeLocationsApiClient, ModalService)
export class PartialManagePracticeLocationsCreate extends BasePartialView {
    public addresses: Address[] = [];
    public emails: Email[] = [];
    public phones: Phone[] = [];
    public request: CreatePracticeLocationRequest = new CreatePracticeLocationRequest({
        rooms: []
    });
    public validation = {
        name: true,
        rooms: true,
        phones: [],
        emails: []
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly locationsApi: PracticeLocationsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.applicationName = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManagePracticeLocationsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'applicationName'], this.request, 1);
                await this.locationsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-practice-locations.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-practice-locations.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[create-practice-location]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleAddressSelected = (address: Address): void => {
        address.translations = { nl: 'Werk', en: 'Work' };
        this.request.address = address;
    };

    public handlePhoneChanged = (phone: Phone) => {
        const cleaned = phone.value?.replace(/_/g, '');
        if (isNotDefined(cleaned) || isEmpty(cleaned)) this.request.phone = null;
        else {
            phone.translations = { nl: 'Werk', en: 'Work' };
            this.request.phone = phone;
        }
    };

    public handleEmailChanged = (email: Email) => {
        if (isNotDefined(email.value) || isEmpty(email.value)) this.request.email = null;
        else {
            email.translations = { nl: 'Werk', en: 'Work' };
            this.request.email = email;
        }
    };

    public async createOrEditRoom(index: number = -1): Promise<void> {
        const room = index > -1 ? this.request.rooms[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.PracticeLocationsRoom.with({ room: cloneDeep(room), index }).whenClosed(async (result: PartialViewResults, data: { room: ExaminationRoom; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.request.rooms[data.index] = data.room;
                    else this.request.rooms.push(data.room);

                    this.request.rooms = [
                        ...(this.request.rooms.length > 0 ? [this.request.rooms.shift()] : []), //
                        ...this.request.rooms
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removeRoom(index: number): Promise<void> {
        const room = this.request.rooms[index];
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-practice-locations.questions.delete-room.title'),
                message: this.t
                    .tr('partial-views.manage-practice-locations.questions.delete-room.message') //
                    .replace('{entity}', `<span>'${room.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.request.rooms.splice(index, 1);
                    }
                }
            })
        );
    }

    private validate(): boolean {
        resetValidation(this.validation);
        resetValidation(this.validation.phones[0]);
        resetValidation(this.validation.emails[0]);

        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.rooms = this.request.rooms.any();

        if (isDefined(this.request.email)) {
            this.validation.emails[0].email =
                isNotEmpty(this.request.email.value) &&
                isValid(
                    this.request.email.value.toLowerCase(),
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/
                );
            this.validation.emails[0].valid = this.validation.emails[0].email;
        }

        if (isDefined(this.request.phone)) {
            const phone = this.request.phone.value?.replace(/_/g, '') || undefined;
            // Validate the phone number
            this.validation.phones[0].phone = phone.length === 9;
            this.validation.phones[0].valid = this.validation.phones[0].phone;
        }

        return validateState(this.validation);
    }
}
