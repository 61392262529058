<import from="converters/currency" />
<import from="converters/date" />
<import from="converters/time-ago" />
<import from="converters/invoice-status-to-string" />
<import from="converters/declaration-performance-status-to-string" />
<import from="converters/declaration-send-method-to-string" />
<import from="converters/healthcare-code-type-to-string" />
<import from="converters/invoice-status-to-color" />
<import from="bx/bx-healthcare-product-selector/bx-healthcare-product-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="relative flex flex-col overflow-hidden" css.bind="styles">
        <div if.bind="isLoading" class="absolute top-0 z-50 flex w-full">
            <ux-bar-loader></ux-bar-loader>
        </div>
        <div class="flex flex-1 overflow-hidden">
            <div w-[500px].class="!collapsed" w-[100px].class="collapsed" class="flex flex-col px-6 pb-10 overflow-y-auto border-r border-gray-200">
                <div border-b.class="!collapsed" class="sticky top-0 z-20 pb-3 bg-white border-gray-200 pt-11">
                    <div if.bind="collapsed" class="flex flex-col gap-5 pt-3">
                        <p class="[text-orientation: upright] w-10 text-2xl font-semibold [writing-mode:vertical-rl]" t="translation:global.labels.activity"></p>
                        <ux-button click.trigger="toggleCollapse()" mode="outline" color="dark">
                            <span class="py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                            </span>
                        </ux-button>
                    </div>
                    <div else class="flex items-center justify-between">
                        <p class="flex text-2xl font-semibold" t="translation:global.labels.activity"></p>
                        <ux-button click.trigger="toggleCollapse()" mode="outline" color="dark">
                            <span class="py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                </svg>
                            </span>
                        </ux-button>
                    </div>
                </div>
                <div if.bind="!collapsed" class="flex flex-col mt-5">
                    <template if.bind="baseLoaded">
                        <ul role="list" class="space-y-6">
                            <li repeat.for="activity of activities" class="relative flex gap-x-2">
                                <template
                                    if.bind="activity.name === 'GenerateHealthcareInvoiceFromMedicalRecord'
                                      || (activity.name === 'CreditHealthcareInvoice' && activity.body.id === invoice.id)">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.created"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'CreditHealthcareInvoice' && activity.body.id !== invoice.id">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.credited"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'UpdateHealthcareInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.modified"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'DownloadRestitutionInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 rotate-90 text-primary">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.download-restitution"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'DownloadRemainingInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 rotate-90 text-primary">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.download-remaining"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'DownloadCreditInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 rotate-90 text-primary">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.download-credit"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendRestitutionInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="flex h-[19.5px] w-[19.5px] items-center justify-center rounded-full bg-primary text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-2.5 w-2.5">
                                                <path
                                                    d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.sent-restitution"></span>
                                        <span class="text-primary">${activity.body.email[Object.keys(activity.body.email)[0]]}.</span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendRemainingInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="flex h-[19.5px] w-[19.5px] items-center justify-center rounded-full bg-primary text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-2.5 w-2.5">
                                                <path
                                                    d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.sent-remaining"></span>
                                        <span class="text-primary">${activity.body.email[Object.keys(activity.body.email)[0]]}.</span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendCreditInvoice'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="flex h-[19.5px] w-[19.5px] items-center justify-center rounded-full bg-primary text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-2.5 w-2.5">
                                                <path
                                                    d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.sent-credit"></span>
                                        <span class="text-primary">${activity.body.email[Object.keys(activity.body.email)[0]]}.</span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'ChangeStatusOfHealthcareInvoice' && activity.body.status === HealthcareInvoiceStatuses.Paid">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-green-500">
                                            <path
                                                fill-rule="evenodd"
                                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.marked-as-paid"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'CreateDeclarationPerformances'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.performances"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'CreateDeclarations'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.added-to-declaration"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendDeclaration'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="flex h-[19.5px] w-[19.5px] items-center justify-center rounded-full bg-primary text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-2.5 w-2.5">
                                                <path
                                                    d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.declaration-sent"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'CheckStatusOfDeclaration'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-primary">
                                            <path
                                                fill-rule="evenodd"
                                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.declaration-changed"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'DownloadDeclarationForInfomedics'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 rotate-90 text-primary">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.healthcare-invoices.activities.download-infomedics"></span>
                                    </p>
                                </template>
                                <ux-tooltip content="${activity.createdAt | date:'dd-MM-yyyy HH:mm'}">
                                    <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${activity.createdAt | timeAgo:false:false:true}</time>
                                </ux-tooltip>
                            </li>
                            <li if.bind="activities.length === 0" class="flex items-center justify-center p-3">
                                <p class="text-sm" t="translation:global.labels.no-activities-found"></p>
                            </li>
                            <!-- <li class="relative flex gap-x-4">
                            <div class="absolute top-0 left-0 flex justify-center w-6 h-6">
                                <div class="w-px bg-gray-200"></div>
                            </div>
                            <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                <svg class="w-6 h-6 text-indigo-600" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                    <path
                                        fill-rule="evenodd"
                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">Alex Curren</span> paid the invoice.</p>
                            <time datetime="2023-01-24T09:20" class="flex-none py-0.5 text-xs leading-5 text-gray-500">1d ago</time>
                        </li> -->
                        </ul>
                    </template>
                    <template else>
                        <ux-skeleton show-background.bind="false" type="container">
                            <div class="flex flex-col w-full gap-y-3">
                                <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            </div>
                        </ux-skeleton>
                    </template>
                </div>
            </div>
            <div class="flex flex-col w-full h-full p-10 overflow-y-auto">
                <div class="flex flex-wrap items-center justify-between gap-6 mb-4">
                    <h2 if.bind="invoice.type === HealthcareInvoiceTypes.Debit || !baseLoaded" class="text-2xl font-semibold" t="translation:global.labels.invoice"></h2>
                    <h2 else class="text-2xl font-semibold" t="translation:global.labels.credit-invoice"></h2>
                    <div class="flex items-center gap-2">
                        <ux-badge if.bind="baseLoaded" class="mt-0.5" type="${invoice.status | invoiceStatusToColor}">
                            <span>${invoice.status | invoiceStatusToString | t}</span>
                        </ux-badge>
                        <ux-dropdown
                            if.bind="(!performances || performances.length === 0) 
                                        && baseLoaded
                                        && hasRole(UserRoles.WriteHealthcareInvoices)
                                        && invoice.status === HealthcareInvoiceStatuses.Draft 
                                        || invoice.status === HealthcareInvoiceStatuses.PartiallyCompensated
                                        || invoice.status === HealthcareInvoiceStatuses.RemainingSent
                                        || invoice.status === HealthcareInvoiceStatuses.CreditSent
                                        || invoice.status === HealthcareInvoiceStatuses.RestitutionSent
                                        || invoice.status === HealthcareInvoiceStatuses.CompensationRejected"
                            border.bind="false"
                            type="full"
                            color="dark"
                            width="w-72"
                            size="sm"
                            padding.bind="false"
                            mode="outline"
                            placement="bottom-right">
                            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path
                                        fill="currentColor"
                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                </svg>
                            </div>
                            <template if.bind="invoice.type === HealthcareInvoiceTypes.Debit">
                                <ux-dropdown-option if.bind="invoice.status === HealthcareInvoiceStatuses.Draft" click.trigger="preview('restitution')">
                                    <span class="text-sm" t="translation:partial-views.healthcare-invoices.buttons.preview-restitution"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option
                                    if.bind="invoice.status === HealthcareInvoiceStatuses.PartiallyCompensated || invoice.status === HealthcareInvoiceStatuses.CompensationRejected"
                                    click.trigger="preview('remaining')">
                                    <span class="text-sm" t="translation:partial-views.healthcare-invoices.buttons.preview-remaining"></span>
                                </ux-dropdown-option>
                            </template>
                            <template if.bind="invoice.type === HealthcareInvoiceTypes.Credit">
                                <ux-dropdown-option if.bind="invoice.status === HealthcareInvoiceStatuses.Draft" click.trigger="preview('credit')">
                                    <span class="text-sm" t="translation:partial-views.healthcare-invoices.buttons.preview-credit"></span>
                                </ux-dropdown-option>
                            </template>
                            <ux-dropdown-option
                                if.bind="invoice.status === HealthcareInvoiceStatuses.RestitutionSent || invoice.status === HealthcareInvoiceStatuses.RemainingSent || invoice.status === HealthcareInvoiceStatuses.CreditSent"
                                click.trigger="markAsPaid()">
                                <span class="text-sm" t="translation:partial-views.healthcare-invoices.buttons.mark-as-paid"></span>
                            </ux-dropdown-option>
                            <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteHealthcareInvoices) && invoice.status === HealthcareInvoiceStatuses.Draft "></ux-dropdown-divider>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteHealthcareInvoices) && invoice.status === HealthcareInvoiceStatuses.Draft" click.trigger="delete()">
                                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </div>
                </div>
                <hr />
                <div class="flex flex-wrap items-center justify-between gap-6 py-4">
                    <h5 gap-3.class="!baseLoaded" gap-1.class="baseLoaded" class="flex items-center text-base font-normal">
                        <div class="flex"><span class="font-bold" t="translation:partial-views.healthcare-invoices.labels.invoice-number"></span><span class="font-bold">:</span></div>
                        <template if.bind="baseLoaded">
                            <div class="flex items-center gap-1 text-gray-600" if.bind="invoice.status === HealthcareInvoiceStatuses.Draft">
                                <span t="translation:global.labels.draft"></span>
                                <span>#${invoice.conceptNumber}</span>
                            </div>
                            <span else>${invoice.trackingNumber}</span>
                        </template>
                        <ux-skeleton else show-background.bind="false" type="container">
                            <div class="flex w-[100px] flex-col justify-end gap-y-3">
                                <ux-skeleton type="line"></ux-skeleton>
                            </div>
                        </ux-skeleton>
                    </h5>
                </div>
                <hr />
                <div>
                    <let
                        locked.bind="invoice.status === HealthcareInvoiceStatuses.Declared                                
                                || invoice.status === HealthcareInvoiceStatuses.PartiallyCompensated
                                || invoice.status === HealthcareInvoiceStatuses.CompensationRejected
                                || invoice.status === HealthcareInvoiceStatuses.RestitutionSent
                                || invoice.status === HealthcareInvoiceStatuses.RemainingSent
                                || invoice.status === HealthcareInvoiceStatuses.CreditSent
                                || invoice.status === HealthcareInvoiceStatuses.Paid
                                || invoice.status === HealthcareInvoiceStatuses.Overdue"></let>

                    <table class="w-full mt-5 mb-5 text-sm border-collapse table-auto">
                        <thead>
                            <tr class="text-center text-white bg-primary">
                                <th if.bind="!locked || !baseLoaded" class="max-w-[42px w-[42px] border-s-2 border-white text-base font-semibold uppercase">
                                    <div if.bind="baseLoaded && hasRole(UserRoles.WriteDeclarationPerformances)" class="flex justify-center h-full">
                                        <ux-checkbox use-negative-colors.bind="true" checked.two-way="selectedAll" onchange.trigger="handleSelectAll($event)"></ux-checkbox>
                                    </div>
                                </th>
                                <th class="py-3 font-semibold uppercase border-white border-s-2" t="translation:global.labels.description"></th>
                                <th class="py-3 font-semibold uppercase border-white border-s-2" t="translation:partial-views.healthcare-invoices.labels.codes"></th>
                                <th class="py-3 font-semibold uppercase border-white border-s-2" t="translation:global.labels.price"></th>
                                <th class="py-3 font-semibold uppercase border-white border-s-2" t="translation:partial-views.healthcare-invoices.labels.vat"></th>
                                <th class="py-3 font-semibold uppercase border-white border-s-2" t="translation:partial-views.healthcare-invoices.labels.quantity"></th>
                                <th class="py-3 font-semibold uppercase border-white border-s-2" t="translation:partial-views.healthcare-invoices.labels.total"></th>
                                <th if.bind="!locked || !baseLoaded" class="w-[42px] max-w-[42px] border-s-2 border-white py-4 text-base font-semibold uppercase"></th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <template if.bind="baseLoaded" repeat.for="line of invoice.lines">
                                <tr bg-gray-50.class="$even">
                                    <!-- 
                                        Note: This comparison is also done in the view model for the canSelect() function.
                                         If you change this comparison, also change the comparison in the view model .
                                    -->
                                    <let
                                        lineperformance.bind="performances.find((x) => (x.invoiceLineId === line.id && !x.additionalChargeId && x.merges.length === 0) || (x.merges.length > 1 && x.merges.includes(line.id)))"></let>
                                    <td
                                        if.bind="!locked"
                                        pb-3.class="line.additionalCharges.length === 0"
                                        pb-1.class="line.additionalCharges.length > 0"
                                        class="w-[42px] max-w-[42px] border-s-2 border-white pt-3 text-base font-medium">
                                        <div class="flex justify-center h-full">
                                            <ux-checkbox
                                                if.bind="hasRole(UserRoles.WriteDeclarationPerformances) && !line.credited && (invoice.status === HealthcareInvoiceStatuses.Draft 
                                                        || invoice.status === HealthcareInvoiceStatuses.Paid 
                                                        || (invoice.status === HealthcareInvoiceStatuses.PartiallyDeclared && !lineperformance)
                                                        || invoice.status === HealthcareInvoiceStatuses.Compensated)"
                                                disabled.bind="!canSelect(line, null)"
                                                model.bind="line"
                                                checked.bind="selection"
                                                onchange.trigger="handleItemChecked($event)">
                                            </ux-checkbox>
                                        </div>
                                    </td>
                                    <td
                                        px-3.class="invoice.status === HealthcareInvoiceStatuses.Draft"
                                        px-6.class="invoice.status !== HealthcareInvoiceStatuses.Draft"
                                        pb-4.class="line.additionalCharges.length === 0"
                                        pb-2.class="line.additionalCharges.length > 0"
                                        class="w-[300px] max-w-[300px] border-s-2 border-white pt-3 text-base">
                                        <div if.bind="line.productId" class="flex flex-col">
                                            <let linecode.bind="line.codes.find((x) => x.system === 'Vektis')"></let>
                                            <span class="font-medium">${line.description}</span>
                                            <template if.bind="linecode">
                                                <span class="text-xs text-gray-500">${linecode.invoiceDescription}</span>
                                                <span text-red-500.class="!line.groupId" class="items-center flex-auto mt-1 text-xs">
                                                    <span
                                                        if.bind="line.groupId"
                                                        class="${groups[line.groupId] ? groups[line.groupId] : '' }"
                                                        t="translation:partial-views.healthcare-invoices.labels.join-number"></span>
                                                    <span if.bind="line.groupId" class="${groups[line.groupId] ? groups[line.groupId] : '' }">${line.groupId}</span>
                                                    <span if.bind="!line.groupId" t="translation:partial-views.healthcare-invoices.messages.no-group-id"></span>
                                                </span>
                                            </template>
                                            <template if.bind="line.performedBy">
                                                <div mt-1.class="!line.groupId && !line.credited" class="text-xs auto-flex">
                                                    <span t="translation:partial-views.healthcare-invoices.labels.treated-on"></span>
                                                    <span>${line.productDate | date:'dd-MM-yyyy'}</span>
                                                    <span t="translation:partial-views.healthcare-invoices.labels.by"></span>
                                                    <span>${line.performedBy.name}</span>
                                                </div>
                                            </template>
                                            <template if.bind="line.credited">
                                                <div class="my-1 text-xs text-red-500 auto-flex">
                                                    <span t="translation:partial-views.healthcare-invoices.labels.is-credited"></span>
                                                    <span>${line.creditedIn.name}</span>
                                                </div>
                                            </template>
                                            <template if.bind="lineperformance">
                                                <div class="mt-2 flex flex-col rounded-lg bg-[#2d2d2d]">
                                                    <a
                                                        click.trigger="togglePerformance(line.id + lineperformance.id)"
                                                        pb-2.class="!states[line.id + lineperformance.id].expanded"
                                                        pt-2.class="!states[line.id + lineperformance.id].expanded"
                                                        pt-3.class="states[line.id + lineperformance.id].expanded"
                                                        href="javascript:"
                                                        class="flex max-h-[28px] items-center justify-between gap-1 px-3 text-[#ccc] hover:text-white">
                                                        <div class="flex items-center gap-1">
                                                            <p class="text-xs" t="translation:global.labels.performance"></p>
                                                            <div if.bind="lineperformance.merges.length > 1" class="flex items-center text-xs">
                                                                <span>(</span>
                                                                <p class="lowercase" t="translation:global.labels.merged"></p>
                                                                <span>)</span>
                                                            </div>
                                                        </div>
                                                        <svg
                                                            if.bind="states[line.id + lineperformance.id].expanded"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="size-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                        </svg>
                                                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                        </svg>
                                                    </a>
                                                    <div
                                                        if.bind="states[line.id + lineperformance.id].expanded"
                                                        pb-2.class="!states[line.id + lineperformance.id].expanded"
                                                        pb-3.class="states[line.id + lineperformance.id].expanded"
                                                        class="mt-2 px-3 text-[#ccc]">
                                                        <div class="flex flex-col pt-2 border-t border-gray-500">
                                                            <div class="flex justify-between gap-1 text-xs">
                                                                <span class="flex">
                                                                    <span t="translation:global.labels.created-at"></span>
                                                                    <span>:</span>
                                                                </span>
                                                                <span>${lineperformance.createdAt | date:'dd-MM-yyyy HH:mm'}</span>
                                                            </div>
                                                            <div class="flex justify-between gap-1 text-xs">
                                                                <span class="flex">
                                                                    <span t="translation:global.labels.created-by"></span>
                                                                    <span>:</span>
                                                                </span>
                                                                <span>${lineperformance.createdBy.name}</span>
                                                            </div>
                                                            <div class="flex justify-between gap-1 text-xs">
                                                                <span class="flex">
                                                                    <span t="translation:global.labels.send-method"></span>
                                                                    <span>:</span>
                                                                </span>
                                                                <span>${lineperformance.sendMethod | declarationSendMethodToString | t}</span>
                                                            </div>
                                                            <div class="flex justify-between gap-1 text-xs">
                                                                <span class="flex">
                                                                    <span t="translation:global.labels.status"></span>
                                                                    <span>:</span>
                                                                </span>
                                                                <span>${lineperformance.status | declarationPerformanceStatusToString | t}</span>
                                                            </div>
                                                            <div if.bind="lineperformance.awardedAmountInclTaxFinancial > 0" class="flex justify-between gap-1 text-xs">
                                                                <span class="flex">
                                                                    <span t="translation:global.labels.compensated"></span>
                                                                    <span>:</span>
                                                                </span>
                                                                <span>${lineperformance.awardedAmountInclTaxFinancial | currency}</span>
                                                            </div>
                                                            <div if.bind="lineperformance.merges.length > 0" class="flex flex-col min-w-0 gap-1 pt-2 mt-2 text-xs border-t border-gray-500">
                                                                <span class="flex flex-nowrap">
                                                                    <span t="translation:global.labels.merged-with"></span>
                                                                    <span>:</span>
                                                                </span>
                                                                <ul class="pl-5 list-disc">
                                                                    <li repeat.for="id of lineperformance.merges.filter((id) => id !== line.id)" class="text-xs">
                                                                        <let mergeitem.bind="findMergedItem(id)"></let>
                                                                        <p class="truncate">${mergeitem.description}</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <bx-healthcare-product-selector
                                            else
                                            data.bind="$index"
                                            on-select.bind="handleProductSelected"
                                            language.bind="language"
                                            workspace.bind="authenticated.workspace.id"
                                            value.bind="line.productId"
                                            valid.bind="validation.lines[$index].product"
                                            t="[placeholder]translation:partial-views.healthcare-invoices.placeholders.select-product">
                                        </bx-healthcare-product-selector>
                                    </td>
                                    <td
                                        pb-3.class="line.additionalCharges.length === 0"
                                        pb-1.class="line.additionalCharges.length > 0"
                                        class="w-[100px] max-w-[100px] border-s-2 border-white px-3 pt-3 text-center">
                                        <template if.bind="line.isAutoGenerated">
                                            <div if.bind="linecode" class="flex flex-col">
                                                <span class="font-medium">${linecode.list} &#8226; ${linecode.value}</span>
                                                <span class="text-xs text-gray-500">${linecode.type | healthcareCodeTypeToString | t}</span>
                                            </div>
                                            <p else class="text-center">-</p>
                                        </template>
                                        <template else>
                                            <template if.bind="line.productId">
                                                <template if.bind="line.codes.length === 0">
                                                    <div class="flex items-center justify-center">
                                                        <ux-button click.trigger="addCode($index)" padding.bind="false" size="xs">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </ux-button>
                                                    </div>
                                                </template>
                                                <div else class="flex flex-col">
                                                    <span class="font-medium">${linecode.list} &#8226; ${linecode.value}</span>
                                                    <span class="text-xs text-gray-500">${linecode.type | healthcareCodeTypeToString | t}</span>
                                                </div>
                                            </template>
                                            <p else class="text-center">-</p>
                                        </template>
                                    </td>
                                    <td
                                        pb-3.class="line.additionalCharges.length === 0"
                                        pb-1.class="line.additionalCharges.length > 0"
                                        class="w-[100px] max-w-[100px] border-s-2 border-white px-3 pt-3 font-medium">
                                        <template if.bind="locked">
                                            <div class="text-center">
                                                <span if.bind="line.productId"> ${line.productPrice.netValue | currency} </span>
                                                <p else class="text-center">-</p>
                                            </div>
                                        </template>
                                        <template else>
                                            <ux-input
                                                disabled.bind="lineperformance || invoice.type === HealthcareInvoiceTypes.Credit"
                                                if.bind="line.productId"
                                                value.two-way="line.productPrice.netValue"
                                                valid.bind="validation.lines[$index].price"
                                                type="number"
                                                select-text-on-focus.bind="true"
                                                oninput.trigger="handlePriceChanged($event)">
                                            </ux-input>
                                            <p else class="text-center">-</p>
                                        </template>
                                    </td>
                                    <td
                                        pb-3.class="line.additionalCharges.length === 0"
                                        pb-1.class="line.additionalCharges.length > 0"
                                        class="w-[80px] max-w-[80px] border-s-2 border-white px-3 pt-3 text-center font-medium">
                                        <span if.bind="line.productId">
                                            <template if.bind="line.isAutoGenerated">
                                                <span>${line.productPrice.vat.percentage}%</span>
                                            </template>
                                            <template else>
                                                <ux-input
                                                    min="0"
                                                    value.two-way="line.productPrice.vat.percentage"
                                                    type="number"
                                                    validation.bind="validation.lines[$index].vat"
                                                    data.bind="$index"
                                                    select-text-on-focus.bind="true"
                                                    oninput.trigger="handleVatChanged($event)">
                                                </ux-input>
                                            </template>
                                        </span>
                                        <p else class="text-center">-</p>
                                    </td>
                                    <td
                                        pb-3.class="line.additionalCharges.length === 0"
                                        pb-1.class="line.additionalCharges.length > 0"
                                        class="w-[80px] max-w-[80px] border-s-2 border-white px-3 pt-3 font-medium">
                                        <template if.bind="locked">
                                            <div class="text-center">
                                                <span if.bind="line.productId"> ${line.quantity} </span>
                                                <p else class="text-center">-</p>
                                            </div>
                                        </template>
                                        <template else>
                                            <ux-input
                                                disabled.bind="lineperformance  || invoice.type === HealthcareInvoiceTypes.Credit"
                                                if.bind="line.productId"
                                                valid.bind="validation.lines[$index].quantity"
                                                value.two-way="line.quantity"
                                                type="number"
                                                select-text-on-focus.bind="true"
                                                oninput.trigger="handleQuantityChanged($event)">
                                            </ux-input>
                                            <p else class="text-center">-</p>
                                        </template>
                                    </td>
                                    <td
                                        pb-3.class="line.additionalCharges.length === 0"
                                        pb-1.class="line.additionalCharges.length > 0"
                                        class="w-[120px] max-w-[120px] border-s-2 border-white px-3 pt-3 text-center font-medium">
                                        <span if.bind="line.productId"> ${getLineTotal(line) | currency} </span>
                                        <p else class="text-center">-</p>
                                    </td>
                                    <td if.bind="!locked" class="w-[42px] max-w-[42px] border-s-2 border-white text-base font-medium">
                                        <div class="flex justify-center h-full">
                                            <ux-dropdown
                                                if.bind="hasRole(UserRoles.WriteHealthcareInvoices) && !lineperformance && invoice.status === HealthcareInvoiceStatuses.Draft && invoice.type === HealthcareInvoiceTypes.Debit"
                                                transparent.bind="true"
                                                border.bind="false"
                                                type="full"
                                                color="dark"
                                                size="sm"
                                                padding.bind="false"
                                                mode="outline"
                                                placement="bottom-right">
                                                <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                    </svg>
                                                </div>
                                                <ux-dropdown-option if.bind="!line.isAutoGenerated && line.codes.length > 0" click.trigger="removeCode($index)">
                                                    <span t="translation:partial-views.healthcare-invoices.buttons.delete-code"></span>
                                                </ux-dropdown-option>
                                                <ux-dropdown-option if.bind="linecode.type === HealthcareCodeTypes.Underlying" click.trigger="linkLine($index)">
                                                    <span class="text-sm" t="translation:global.buttons.link"></span>
                                                </ux-dropdown-option>
                                                <ux-dropdown-divider if.bind="!line.isAutoGenerated && line.codes.length > 0 || linecode.type === HealthcareCodeTypes.Underlying"></ux-dropdown-divider>
                                                <ux-dropdown-option click.trigger="deleteLine( $index)">
                                                    <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                </ux-dropdown-option>
                                            </ux-dropdown>
                                        </div>
                                    </td>
                                </tr>
                                <tr if.bind="line.additionalCharges.length > 0" repeat.for="charge of line.additionalCharges" bg-gray-50.class="$parent.$even">
                                    <td if.bind="!locked" pb-3.class="$last" pb-1.class="!$last" class="w-[42px] max-w-[42px] border-s-2 border-white pt-1 text-base font-medium">
                                        <let
                                            chargeperformance.bind="performances.find((x) => x.invoiceLineId === line.id && (x.additionalChargeId === charge.id || x.merges.includes(charge.id)))"></let>
                                        <div class="flex justify-center h-full">
                                            <ux-checkbox
                                                if.bind="!chargeperformance && !line.credited"
                                                disabled.bind="!canSelect(line, charge)"
                                                model.bind="charge"
                                                checked.bind="selection"
                                                onchange.trigger="handleItemChecked($event)">
                                            </ux-checkbox>
                                        </div>
                                    </td>
                                    <td
                                        pl-5.class="invoice.status === HealthcareInvoiceStatuses.Draft"
                                        pl-8.class="invoice.status !== HealthcareInvoiceStatuses.Draft"
                                        pb-3.class="$last"
                                        class="w-[300px] max-w-[300px] border-s-2 border-white py-0 pr-6 text-sm">
                                        <let
                                            chargeperformance.bind="performances.find((x) => x.invoiceLineId === line.id && (x.additionalChargeId === charge.id || x.merges.includes(charge.id)))"></let>
                                        <div class="relative flex items-center pt-1 pb-1 pl-6 border-l-2 border-gray-300">
                                            <svg
                                                class="absolute left-[-4px] top-[4px] h-5 w-5 text-gray-300"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                            <div class="flex flex-col flex-1 min-w-0">
                                                <let chargecode.bind="charge.codes.find((x) => x.system === 'Vektis')"></let>
                                                <span class="font-medium">${charge.description}</span>
                                                <template if.bind="chargecode">
                                                    <span class="text-xs text-gray-500">${chargecode.invoiceDescription}</span>
                                                    <!-- <span mt-1.class="!charge.groupId" text-red-500.class="!charge.groupId" class="items-center flex-auto text-xs">
                                                        <span
                                                            if.bind="charge.groupId"
                                                            class="${groups[charge.groupId] ? groups[charge.groupId] : '' }"
                                                            t="translation:partial-views.healthcare-invoices.labels.join-number"></span>
                                                        <span if.bind="charge.groupId" class="${groups[charge.groupId] ? groups[charge.groupId] : '' }">${charge.groupId}</span>
                                                        <span if.bind="!charge.groupId" t="translation:partial-views.healthcare-invoices.messages.no-group-id"></span>
                                                    </span> -->
                                                </template>
                                                <template if.bind="chargeperformance">
                                                    <div class="mt-2 flex flex-col rounded-lg bg-[#2d2d2d]">
                                                        <a
                                                            click.trigger="togglePerformance(charge.id + chargeperformance.id)"
                                                            pb-2.class="!states[charge.id + chargeperformance.id].expanded"
                                                            pt-2.class="!states[charge.id + chargeperformance.id].expanded"
                                                            pt-3.class="states[charge.id + chargeperformance.id].expanded"
                                                            href="javascript:"
                                                            class="flex max-h-[28px] items-center justify-between gap-1 px-3 text-[#ccc] hover:text-white">
                                                            <div class="flex items-center gap-1">
                                                                <p class="text-xs" t="translation:global.labels.performance"></p>
                                                                <div if.bind="chargeperformance.merges.length > 1" class="flex items-center text-xs">
                                                                    <span>(</span>
                                                                    <p class="lowercase" t="translation:global.labels.merged"></p>
                                                                    <span>)</span>
                                                                </div>
                                                            </div>
                                                            <svg
                                                                if.bind="states[charge.id + chargeperformance.id].expanded"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="size-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div
                                                            if.bind="states[charge.id + chargeperformance.id].expanded"
                                                            pb-2.class="!states[charge.id + chargeperformance.id].expanded"
                                                            pb-3.class="states[charge.id + chargeperformance.id].expanded"
                                                            class="mt-2 px-3 text-[#ccc]">
                                                            <div class="flex flex-col pt-2 border-t border-gray-500">
                                                                <div class="flex justify-between gap-1 text-xs">
                                                                    <span class="flex">
                                                                        <span t="translation:global.labels.created-at"></span>
                                                                        <span>:</span>
                                                                    </span>
                                                                    <span>${chargeperformance.createdAt | date:'dd-MM-yyyy HH:mm'}</span>
                                                                </div>
                                                                <div class="flex justify-between gap-1 text-xs">
                                                                    <span class="flex">
                                                                        <span t="translation:global.labels.created-by"></span>
                                                                        <span>:</span>
                                                                    </span>
                                                                    <span>${chargeperformance.createdBy.name}</span>
                                                                </div>
                                                                <div class="flex justify-between gap-1 text-xs">
                                                                    <span class="flex">
                                                                        <span t="translation:global.labels.send-method"></span>
                                                                        <span>:</span>
                                                                    </span>
                                                                    <span>${chargeperformance.sendMethod | declarationSendMethodToString | t}</span>
                                                                </div>
                                                                <div class="flex justify-between gap-1 text-xs">
                                                                    <span class="flex">
                                                                        <span t="translation:global.labels.status"></span>
                                                                        <span>:</span>
                                                                    </span>
                                                                    <span>${chargeperformance.status | declarationPerformanceStatusToString | t}</span>
                                                                </div>
                                                                <div if.bind="chargeperformance.awardedAmountInclTaxFinancial > 0" class="flex justify-between gap-1 text-xs">
                                                                    <span class="flex">
                                                                        <span t="translation:global.labels.compensated"></span>
                                                                        <span>:</span>
                                                                    </span>
                                                                    <span>${chargeperformance.awardedAmountInclTaxFinancial | currency}</span>
                                                                </div>
                                                                <div if.bind="chargeperformance.merges.length > 0" class="flex flex-col min-w-0 gap-1 pt-2 mt-2 text-xs border-t border-gray-500">
                                                                    <span class="flex flex-nowrap">
                                                                        <span t="translation:global.labels.merged-with"></span>
                                                                        <span>:</span>
                                                                    </span>
                                                                    <ul class="pl-5 list-disc">
                                                                        <li repeat.for="id of chargeperformance.merges.filter((id) => id !== charge.id)" class="text-xs">
                                                                            <let mergeitem.bind="findMergedItem(id)"></let>
                                                                            <p class="truncate">${mergeitem.description}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </td>
                                    <td pb-3.class="$last" pb-1.class="!$last" class="w-[50px] max-w-[50px] border-s-2 border-white px-3 pt-1 text-center text-sm">
                                        <div if.bind="chargecode" class="flex flex-col">
                                            <span class="font-medium">${chargecode.list} &#8226; ${chargecode.value}</span>
                                            <span class="text-xs text-gray-500">${chargecode.type | healthcareCodeTypeToString | t}</span>
                                        </div>
                                        <p else class="text-center">-</p>
                                    </td>
                                    <td pb-3.class="$last" pb-1.class="!$last" class="w-[100px] max-w-[100px] border-s-2 border-white px-3 pt-1 text-sm font-medium">
                                        <div if.bind="locked" class="text-center">
                                            <span>${charge.price.netValue | currency}</span>
                                        </div>
                                        <ux-input
                                            else
                                            value.two-way="charge.price.netValue"
                                            disabled.bind="chargeperformance || invoice.type === HealthcareInvoiceTypes.Credit"
                                            type="number"
                                            select-text-on-focus.bind="true"
                                            oninput.trigger="handlePriceChanged($event)">
                                        </ux-input>
                                    </td>
                                    <td pb-3.class="$last" pb-1.class="!$last" class="w-[50px] max-w-[50px] border-s-2 border-white px-3 pt-1 text-center text-sm font-medium">
                                        ${charge.price.vat.percentage}%
                                    </td>
                                    <td pb-3.class="$last" pb-1.class="!$last" class="w-[80px] max-w-[80px] border-s-2 border-white px-3 pt-1 text-sm font-medium">
                                        <div if.bind="locked" class="text-center">
                                            <span>${charge.quantity}</span>
                                        </div>
                                        <ux-input
                                            else
                                            value.two-way="charge.quantity"
                                            disabled.bind="chargeperformance || invoice.type === HealthcareInvoiceTypes.Credit"
                                            type="number"
                                            select-text-on-focus.bind="true"
                                            oninput.trigger="handleQuantityChanged($event)">
                                        </ux-input>
                                        <!-- 
                                            For now the charge is always 1 because we dont have use-cases where 
                                            a charge should be counted twice. 
                                        -->
                                        <!-- <p class="text-center">-</p> -->
                                    </td>
                                    <td pb-3.class="$last" pb-1.class="!$last" class="w-[120px] max-w-[120px] border-s-2 border-white px-3 text-center text-sm font-medium">
                                        ${(charge.price.netValue * charge.quantity) | currency}
                                    </td>
                                    <td if.bind="!locked" pb-3.class="$last" pb-1.class="!$last" class="h-full w-[30px] max-w-[30] border-s-2 border-white pt-1 font-medium">
                                        <div class="flex items-center justify-center h-full">
                                            <ux-dropdown
                                                if.bind="!chargeperformancechargeperformance && invoice.status === HealthcareInvoiceStatuses.Draft && invoice.type === HealthcareInvoiceTypes.Debit"
                                                transparent.bind="true"
                                                border.bind="false"
                                                type="full"
                                                color="dark"
                                                size="sm"
                                                padding.bind="false"
                                                mode="outline"
                                                placement="bottom-right">
                                                <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                    </svg>
                                                </div>
                                                <ux-dropdown-divider if.bind="chargecode.type === HealthcareCodeTypes.Underlying"></ux-dropdown-divider>
                                                <ux-dropdown-option click.trigger="deleteCharge($parent.$parent.$index, $index)">
                                                    <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                </ux-dropdown-option>
                                            </ux-dropdown>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template else>
                                <tr repeat.for="i of 8" bg-gray-100.class="$even">
                                    <td repeat.for="i of 8" px-5.class="$index !== 0 && $index !== 7" px-2.class="$index === 0 || $index === 7" class="py-5 font-medium border-white border-s-2">
                                        <ux-skeleton show-background.bind="false" type="container">
                                            <div class="flex flex-col justify-end w-full gap-y-3">
                                                <ux-skeleton type="line"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </td>
                                </tr>
                            </template>
                            <template if.bind="baseLoaded">
                                <tr>
                                    <td colspan="8" class="py-4 pb-0 pr-4 text-base font-normal border border-white text-end">
                                        <div class="flex items-center justify-between gap-3">
                                            <div class="pl-1">
                                                <a
                                                    if.bind="hasRole(UserRoles.WriteHealthcareInvoices) && !locked 
                                                            && invoice.status === HealthcareInvoiceStatuses.Draft
                                                            && invoice.type === HealthcareInvoiceTypes.Debit"
                                                    click.trigger="addLine()"
                                                    href="javascript:"
                                                    class="text-sm underline text-primary"
                                                    t="translation:partial-views.healthcare-invoices.buttons.add-invoice-line"></a>
                                            </div>
                                            <div class="flex justify-end">
                                                <div class="flex gap-6">
                                                    <div class="flex font-bold">
                                                        <span t="translation:partial-views.healthcare-invoices.labels.sub-total"></span>
                                                        <span>:</span>
                                                    </div>
                                                    <p class="w-32">${invoice.totals.grossTotal | currency}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr repeat.for="total of invoice.totals.vatTotals" if.bind="total.percentage > 0">
                                    <td colspan="8" class="px-4 pb-0 text-base font-normal border border-white text-end">
                                        <div class="flex justify-end">
                                            <div class="flex gap-6">
                                                <div class="flex gap-1 font-bold">
                                                    <span t="translation:partial-views.healthcare-invoices.labels.vat"></span>
                                                    <span> (${total.percentage}%):</span>
                                                </div>
                                                <p class="w-32">${total.vatTotal | currency}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <template if.bind="invoice.awardedAmountInclTaxFinancial > 0">
                                    <tr>
                                        <td colspan="8" class="px-4 pb-0 text-base font-normal border border-white text-end">
                                            <div class="flex justify-end">
                                                <div class="flex gap-6">
                                                    <div class="flex font-bold">
                                                        <span t="translation:partial-views.healthcare-invoices.labels.total"></span>
                                                        <span>:</span>
                                                    </div>
                                                    <p class="w-32">${invoice.totals.netTotal | currency}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="8" class="px-4 pb-0 text-base font-normal border-white text-end">
                                            <div class="flex justify-end">
                                                <div class="flex gap-6">
                                                    <div class="flex font-bold">
                                                        <span t="translation:partial-views.healthcare-invoices.labels.compensated-by-insurer"></span>
                                                        <span>:</span>
                                                    </div>
                                                    <p class="w-32">${invoice.awardedAmountInclTaxFinancial | currency}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="8" class="px-4 pb-0 text-base font-normal border border-white text-end">
                                            <div class="flex justify-end">
                                                <div class="flex font-bold">
                                                    <div class="flex gap-6 mt-1">
                                                        <div class="flex pt-1">
                                                            <span t="translation:partial-views.healthcare-invoices.labels.still-to-pay"></span>
                                                            <span>:</span>
                                                        </div>
                                                        <div class="flex items-center pt-1 border-t border-black">
                                                            <p class="w-32">${(invoice.totals.netTotal - invoice.awardedAmountInclTaxFinancial) | currency}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr else>
                                    <td colspan="8" class="px-4 pb-0 text-base font-normal border-t border-white text-end">
                                        <div class="flex justify-end">
                                            <div class="flex font-bold">
                                                <div class="flex gap-6 mt-1">
                                                    <div class="flex pt-1">
                                                        <span t="translation:partial-views.healthcare-invoices.labels.total"></span>
                                                        <span>:</span>
                                                    </div>
                                                    <div class="flex items-center pt-1 border-t border-black">
                                                        <p class="w-32">${(invoice.totals.netTotal - invoice.awardedAmountInclTaxFinancial) | currency}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template else>
                                <tr>
                                    <td colspan="8" class="py-4 pb-0 pl-4 text-base font-normal border border-white text-end">
                                        <div class="flex justify-end">
                                            <div class="w-[100px]">
                                                <ux-skeleton show-background.bind="false" type="container">
                                                    <div class="flex w-[100px] flex-col justify-end gap-y-3 pt-2">
                                                        <ux-skeleton type="line" padding="pl-2"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pl-5"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pl-2"></ux-skeleton>
                                                    </div>
                                                </ux-skeleton>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ux-footer>
            <div class="flex items-center justify-between flex-1 gap-x-2">
                <div if.bind="validation.valid">
                    <div if.bind="invoice.attachments.length > 0" class="flex gap-1">
                        <ux-button repeat.for="attachment of invoice.attachments" mode="outline" color="dark" click.trigger="showDocument(attachment)">
                            <div class="flex items-center gap-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                </svg>
                                <span>${attachment.name}${attachment.extension}</span>
                            </div>
                        </ux-button>
                    </div>
                    <template if.bind="invoice.status === HealthcareInvoiceStatuses.Draft || invoice.status === HealthcareInvoiceStatuses.PartiallyDeclared">
                        <template if.bind="selection.length > 0">
                            <ux-button color="dark" click.trigger="declare()">
                                <div class="flex items-center gap-1">
                                    <span t="translation:partial-views.healthcare-invoices.buttons.declare"></span>
                                    <span>(${selection.length})</span>
                                </div>
                            </ux-button>
                        </template>
                        <template else>
                            <div class="flex items-center h-full text-sm">
                                <ux-badge type="warning">
                                    <p class="my-[3px] px-5" t="translation:partial-views.healthcare-invoices.messages.declare-with-codes"></p>
                                </ux-badge>
                            </div>
                        </template>
                    </template>
                    <template
                        if.bind="invoice.status === HealthcareInvoiceStatuses.Compensated || (invoice.type === HealthcareInvoiceTypes.Debit && invoice.status === HealthcareInvoiceStatuses.Paid)">
                        <ux-button if.bind="selection.length > 0" color="danger" click.trigger="credit()">
                            <div class="flex items-center gap-1">
                                <span t="translation:global.buttons.credit"></span>
                                <span>(${selection.length})</span>
                            </div>
                        </ux-button>
                    </template>
                </div>
                <template else>
                    <ux-badge if.bind="!validation.missingGroupId" type="danger">
                        <span class="my-[3px] px-5" t="translation:partial-views.healthcare-invoices.messages.missing-group-id"></span>
                    </ux-badge>
                </template>
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                    <ux-button
                        if.bind="baseLoaded && hasRole(UserRoles.WriteHealthcareInvoices) && !locked && invoice.status === HealthcareInvoiceStatuses.Draft"
                        size="sm"
                        color="success"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
