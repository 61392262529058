import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { CreateInsurerRequest, Email, InsurersApiClient, Phone } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, InsurersApiClient)
export class PartialManageInsurersCreate extends BasePartialView {
    public request: CreateInsurerRequest = new CreateInsurerRequest({
        addresses: [],
        phones: [],
        emails: []
    });
    public validation = {
        name: true,
        emails: [],
        phones: []
    };

    public addressDescriptions: { [key: string]: string }[];
    public emailDescriptions: { [key: string]: string }[];
    public phoneDescriptions: { [key: string]: string }[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly insurersApi: InsurersApiClient
    ) {
        super(cache, errorHandler, events, store, t);

        this.addressDescriptions = [
            {
                nl: t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                en: t.tr('translation:global.labels.home-address', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];

        this.emailDescriptions = [
            { nl: t.tr('translation:global.labels.work', { lng: 'nl' }), en: t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.private', { lng: 'nl' }), en: t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.phoneDescriptions = [
            { nl: t.tr('translation:global.labels.work', { lng: 'nl' }), en: t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.private', { lng: 'nl' }), en: t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.mobile', { lng: 'nl' }), en: t.tr('translation:global.labels.mobile', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageInsurersCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                // Clean values that are not valid.
                this.request.emails = this.request.emails.filter((email: Email) => {
                    const cleaned = email.value?.replace(/_/g, '');
                    return isDefined(cleaned) && isNotEmpty(cleaned);
                });

                this.request.phones = this.request.phones.filter((phone: Phone) => {
                    const cleaned = phone.value?.replace(/_/g, '');
                    return isDefined(cleaned) && isNotEmpty(cleaned);
                });

                cleanTranslatables(['name'], this.request, 1);
                await this.insurersApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-insurers.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-insurers.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[create-insurer]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);

        for (let index = 0; index < this.request.emails.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.emails[index]);

            const email = this.request.emails[index].value;
            // Only validate if user entered something.
            if (isNotDefined(email) || isEmpty(email)) continue;
            // Validate the phone number
            this.validation.emails[index].email =
                isNotEmpty(email) &&
                isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);
            this.validation.emails[index].valid = this.validation.emails[index].email;
        }

        for (let index = 0; index < this.request.phones.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.phones[index]);
            // Remove mask placeholder characters.
            const phone = this.request.phones[index].value?.replace(/_/g, '') || undefined;
            // Only validate if user entered something.
            if (isNotDefined(phone) || isEmpty(phone)) continue;
            // Validate the phone number
            this.validation.phones[index].phone = phone.length === 9;
            this.validation.phones[index].valid = this.validation.phones[index].phone;
        }

        return validateState(this.validation);
    }
}
