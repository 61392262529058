import { I18N } from '@aurelia/i18n';
import { IRouter } from '@aurelia/router';
import { Store } from '@aurelia/store-v1';
import { Genders, GetRedactedWorkspaceResponse, GetUnsignedVersionsResponse, LegalEntities, UserExtensionHealthcare, UserExtensions } from '@wecore/sdk-core';
import {
    AddUserToWorkspaceRequest,
    GetAuthenticatedResponse,
    GetMessageResponse,
    GetStorageInformationResponse,
    GetWorkspaceResponse,
    InvitationsApiClient,
    LegalApiClient,
    LegalDocumentVersions,
    MessagesApiClient,
    UserRoles,
    UserSettings,
    UsersApiClient,
    WorkspacesApiClient
} from '@wecore/sdk-management';
import { hasMinLength, isDefined, isNotDefined, isNotEmpty, isValid, resetValidation } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { AuthenticationService } from '../../services/service.authentication';
import { ModalService } from '../../services/service.modals';

@inject(
    CacheService,
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    UsersApiClient,
    LegalApiClient,
    ModalService,
    AuthenticationService,
    IRouter,
    MessagesApiClient,
    InvitationsApiClient,
    WorkspacesApiClient
)
export class PartialUserSettings extends BasePartialView {
    public tabs: any;
    public user: GetAuthenticatedResponse;
    public validation: any = {
        valid: true,
        firstName: true,
        lastName: true,
        email: true,
        emailValid: true,
        password: true,
        repeat: true,
        unique: true,
        match: true,
        length: true,
        currentPassword: true,
        currentPasswordValid: true,
        token: true
    };
    public Genders: typeof Genders = Genders;
    public versions: LegalDocumentVersions;
    public storage: GetStorageInformationResponse;
    public fetching: boolean = false;
    public fileInput: HTMLInputElement;
    public avatarSrc: string;
    public password: string;
    public repeatPassword: string;
    public currentPassword: string;
    public token: string;
    public qr: string;
    public loadingMfa: boolean = false;
    public unsigned: GetUnsignedVersionsResponse;
    public invitations: GetMessageResponse[];

    private avatarToUpload: File;
    private originalEmail: string;
    private shouldUpdateExternal: boolean = false;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly usersApi: UsersApiClient,
        private readonly legalApi: LegalApiClient,
        private readonly modalService: ModalService,
        private readonly auth: AuthenticationService,
        private readonly router: IRouter,
        private readonly messagesApi: MessagesApiClient,
        private readonly invitationsApi: InvitationsApiClient,
        private readonly workspacesApi: WorkspacesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });

        const tab = view.data.tab;

        this.tabs = {
            general: {
                name: this.t.tr('translation:global.labels.general'),
                active: isNotDefined(tab) || tab === 'general',
                valid: true
            },
            authentication: {
                name: this.t.tr('translation:partial-views.user-settings.labels.authentication'),
                active: tab === 'authentication',
                valid: true
            },
            workspaces: {
                name: this.t.tr('translation:global.labels.workspaces'),
                active: tab === 'workspaces',
                valid: true
            },
            agreements: {
                name: this.t.tr('translation:global.labels.agreements'),
                active: tab === 'agreements',
                valid: true
            }
        };
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [user, versions, unsigned, invitations] = await Promise.all([
                    this.usersApi.getAuthenticated(), //
                    this.legalApi.getDocumentVersions(),
                    this.legalApi.getUnsignedVersions(),
                    this.messagesApi.myMessages(100, 0, ['Invitation'])
                ]);

                this.user = user;
                this.originalEmail = user.email;
                this.versions = versions;
                this.unsigned = unsigned;
                this.invitations = invitations.data;

                this.getAvatar();

                if (isNotDefined(this.user.extensions)) this.user.extensions = new UserExtensions();
                if (isNotDefined(this.user.extensions.healthcare)) this.user.extensions.healthcare = new UserExtensionHealthcare();
                if (isNotDefined(this.user.settings)) this.user.settings = new UserSettings();
                if (isNotDefined(this.user.settings.language)) this.user.settings.language = 'nl';

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialUserSettings.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public setActiveTab(key: string): void {
        for (const prop in this.tabs) this.tabs[prop].active = false;
        this.tabs[key].active = true;
    }

    public async save(): Promise<void> {
        this.isLoading = true;
        const valid = await this.validate();
        if (valid) {
            try {
                await this.usersApi.update(this.user.id, this.user);

                if (isDefined(this.avatarToUpload)) {
                    const filename = `avatar.${this.avatarToUpload.name.split('.').pop()}`.toLowerCase();
                    await this.deleteOldAvatar();
                    await this.usersApi.uploadAttachment(this.user.id, true, {
                        data: this.avatarToUpload,
                        fileName: filename
                    });
                    this.avatarToUpload = null;
                }

                this.user = await this.usersApi.getAuthenticated();

                // Force cache update.
                await this.cache.getAuthenticatedUser(true);
                this.t.setLocale(this.user.settings.language);
                this.events.publish(CustomEvents.UserAvatarChanged);
                if (this.shouldUpdateExternal) {
                    await this.auth.updateUser(this.user, (e) => {
                        this.errorHandler.handle('[user-settings/user]', e);
                    });
                    if (isNotEmpty(this.password)) {
                        await this.auth.updatePassword(this.password, (e) => {
                            this.errorHandler.handle('[user-settings/password]', e);
                        });
                    }
                }

                this.password = null;
                this.repeatPassword = null;
                this.currentPassword = null;

                this.notifications.show(
                    this.t.tr('translation:partial-views.user-settings.notifications.save-successful.title'),
                    this.t.tr('translation:partial-views.user-settings.notifications.save-successful.message'),
                    { type: 'success', duration: 3000 }
                );
            } catch (e) {
                await this.errorHandler.handle('[user-settings/edit]', e);
            }
        }
        this.isLoading = false;
    }

    public async showDocument(document: string, version: string): Promise<void> {
        const attachment = this.user.attachments.find(
            (x) => x.name.includes(document) && x.name.includes(`${version.replace(/\./g, '')}`) && !x.name.includes('signature') //
        );
        if (isNotDefined(attachment)) return;
    }

    public hasSignedVersion(document: 'termsAndConditions' | 'processingAgreements' | 'eulas', version: string): boolean {
        if (isNotDefined(this.user) || isNotDefined(this.user.legal)) return false;
        return this.user.legal[document].some((x: any) => x.version === version);
    }

    public async sign(document: 'terms-and-conditions' | 'eula', version: string): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.SignDocument.with({
                companyId: this.user.id,
                entity: LegalEntities.Individual,
                documentUrl: `https://wecorecdn.blob.core.windows.net/docs/legal/${version}/${document}.pdf`,
                documentType: document,
                version
            }).whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok) {
                    this.versions = null;
                    this.user = await this.usersApi.getAuthenticated();
                    this.versions = await this.legalApi.getDocumentVersions();
                    this.unsigned = await this.legalApi.getUnsignedVersions();
                    this.events.publish(CustomEvents.LegalDocumentSigned);
                }
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public selectAvatar(): void {
        this.fileInput.click();
    }

    public async deleteAvatar(): Promise<void> {
        const remove = () => {
            this.avatarSrc = null;
            this.avatarToUpload = null;
            this.fileInput.value = null;
        };

        if (this.user.attachments.some((x) => x.name.includes('avatar')))
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.user-settings.questions.delete-avatar.title'),
                    message: this.t.tr('translation:partial-views.user-settings.questions.delete-avatar.message'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            remove();
                            this.deleteOldAvatar();
                        }
                    }
                })
            );
        else remove();
    }

    public handleFilesSelected(e: Event): void {
        const file = this.fileInput.files[0];
        this.avatarSrc = URL.createObjectURL(file);
        this.avatarToUpload = file;
        this.fileInput.value = null;
    }

    public async enroll(): Promise<void> {
        this.loadingMfa = true;
        const response = await this.auth.enrollMfa((error: any) => {
            this.errorHandler.handle('user-settings/enroll-mfa', error);
            this.loadingMfa = false;
        });
        if (isDefined(response)) {
            this.qr = response.totp.qr_code;
            this.user.factorId = response.id;
        }
        this.loadingMfa = false;
    }

    public async unenroll(): Promise<void> {
        await this.modalService.confirmWithPassword(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.user-settings.questions.unenroll.title'),
                message: this.t.tr('translation:partial-views.user-settings.questions.unenroll.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.loadingMfa = true;
                        await this.auth.unenrollMfa(this.user.factorId, (error: any) => {
                            this.errorHandler.handle('user-settings/unenroll', error);
                            this.loadingMfa = false;
                        });
                        this.user.factorId = null;
                        await this.usersApi.update(this.user.id, this.user);
                        this.qr = null;
                        this.loadingMfa = false;
                    }
                }
            })
        );
    }

    public async verify(): Promise<void> {
        const valid = this.validate();
        if (!valid) return;
        this.loadingMfa = true;

        const succeeded = await this.auth.verifyAndChallengeMfa(this.user.factorId, this.token, (error: any) => {
            this.errorHandler.handle('verify-and-challenge-mfa', error);
            this.loadingMfa = false;
        });

        if (succeeded) {
            await this.usersApi.update(this.user.id, this.user);
            this.notifications.show(
                this.t.tr('translation:partial-views.user-settings.notifications.success-mfa.title'), //
                this.t.tr('translation:partial-views.user-settings.notifications.success-mfa.message'),
                {
                    type: 'success',
                    duration: 3000
                }
            );
            this.qr = null;
            this.loadingMfa = false;
        }
    }

    public async removeFromWorkspace(workspace: GetRedactedWorkspaceResponse): Promise<void> {
        await this.modalService.confirmWithPassword(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.manage-practice.questions.remove-from-workspace.title'),
                message: this.t
                    .tr('translation:partial-views.manage-practice.questions.remove-from-workspace.message') //
                    .replace('{{workspace}}', `<span class="font-semibold">${workspace.name}</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            this.isLoading = true;
                            await this.usersApi.removeFromWorkspace(this.user.id, workspace.id);
                            await this.refreshAuthenticated();
                            this.isLoading = false;

                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-practice.notifications.succesfull-removed.title'),
                                this.t
                                    .tr('translation:partial-views.manage-practice.notifications.succesfull-removed.message') //
                                    .replace('{{workspace}}', `<span class="font-semibold">${workspace.name}</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.errorHandler.handle('user-settings/remove-from-workspace', e);
                        }
                    }
                }
            })
        );
    }

    public open(workspace: GetWorkspaceResponse): void {
        window.location.href = `/${workspace.id}`;
    }

    public onboard(): void {
        this.router.load('/onboarding');
    }

    public isNotOwner(workspace: GetWorkspaceResponse): boolean {
        const configuration = workspace.userConfiguration.find((x) => x.user?.id === this.authenticated.user.id);
        if (isNotDefined(configuration)) return true;
        return configuration.roles.every((r) => r !== UserRoles.Owner);
    }

    public async acceptInvitation(message: GetMessageResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.manage-users.questions.accept-invitation.title'),
                message: this.t
                    .tr('translation:partial-views.manage-users.questions.accept-invitation.message') //
                    .replace('{user}', message.createdBy.name),
                btnOk: this.t.tr('translation:global.buttons.accept'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.workspacesApi.addUser(
                                message.workspace,
                                new AddUserToWorkspaceRequest({
                                    userId: this.authenticated.user.id
                                })
                            );
                            await this.invitationsApi.accept(message.id, message.workspace);

                            const [invitations] = await Promise.all([
                                this.messagesApi.myMessages(100, 0, ['Invitation']), //
                                this.refreshAuthenticated()
                            ]);

                            this.invitations = invitations.data;

                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-users.notifications.accept-invitation-successful.title'), //
                                this.t
                                    .tr(`translation:partial-views.manage-users.notifications.accept-invitation-successful.message`) //
                                    .replace('{user}', message.createdBy.name),
                                {
                                    type: 'success',
                                    duration: 3000
                                }
                            );
                        } catch (e) {
                            this.errorHandler.handle('user-settings/accept-invite', e);
                        }
                    }
                }
            })
        );
    }

    public async declineInvitation(message: GetMessageResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.manage-users.questions.decline-invitation.title'),
                message: this.t
                    .tr('translation:partial-views.manage-users.questions.decline-invitation.message') //
                    .replace('{user}', message.createdBy.name),
                btnOk: this.t.tr('translation:global.buttons.decline'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.invitationsApi.decline(message.id, message.workspace);

                            const res = await this.messagesApi.myMessages(100, 0, ['Invitation']);
                            this.invitations = res.data;

                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-users.notifications.decline-invitation-successful.title'), //
                                this.t
                                    .tr(`translation:partial-views.manage-users.notifications.decline-invitation-successful.message`) //
                                    .replace('{user}', message.createdBy.name),
                                {
                                    type: 'success',
                                    duration: 3000
                                }
                            );
                        } catch (e) {
                            this.errorHandler.handle('user-settings/decline-invitation', e);
                        }
                    }
                }
            })
        );
    }

    private async deleteOldAvatar(): Promise<void> {
        const avatars = this.user.attachments.filter((x) => x.name.includes('avatar'));
        for (const avatar of avatars) {
            await this.usersApi.deleteAttachment(this.user.id, avatar.id);
            this.user.attachments = this.user.attachments.filter((x) => x.id !== avatar.id);
            this.events.publish(CustomEvents.UserAvatarChanged);
        }
    }

    private async getAvatar(): Promise<void> {
        const avatar = this.user.attachments.find((x) => x.name.includes('avatar'));
        if (isDefined(avatar)) {
            const blob = await this.usersApi.downloadAttachment(this.user.id, avatar.id);
            if (isDefined(blob) && isDefined(blob.data)) {
                this.avatarSrc = URL.createObjectURL(blob.data);
            }
        }
    }

    private async validate(): Promise<boolean> {
        resetValidation(this.validation);
        this.shouldUpdateExternal = false;

        this.validation.firstName = isNotEmpty(this.user.firstName);
        this.validation.lastName = isNotEmpty(this.user.lastName);

        const res = await this.usersApi.checkEmail(this.user.email);
        this.validation.unique = !res.exists;

        let shouldValidateCurrentPassword = false;
        // Check if the email has changed.
        if (this.originalEmail !== this.user.email && this.validation.unique) {
            shouldValidateCurrentPassword = true;

            // Validate the email
            this.validation.email = isDefined(this.user.email) && isNotEmpty(this.user.email);
            this.validation.emailValid = isValid(
                this.user.email.toLowerCase(),
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/
            );
        }

        // Check if the password has been changed.
        if (isNotEmpty(this.password) || isNotEmpty(this.repeatPassword)) {
            shouldValidateCurrentPassword = true;

            this.validation.password = isValid(this.password, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/);
            this.validation.length = hasMinLength(this.password, 10);

            this.validation.repeat = isNotEmpty(this.repeatPassword);
            if (this.validation.repeat) this.validation.match = this.password === this.repeatPassword;
        }

        if (shouldValidateCurrentPassword) {
            this.validation.currentPassword = isNotEmpty(this.currentPassword);
            if (
                this.validation.currentPassword &&
                // And if all the other fields are valid.
                this.validation.email &&
                this.validation.emailValid &&
                this.validation.password &&
                this.validation.repeat &&
                this.validation.match &&
                this.validation.length &&
                this.validation.currentPassword
            ) {
                const isCurrentPassword = await this.auth.validatePassword(this.currentPassword, (e) => {
                    this.errorHandler.handle('[user-settings/check-password]', e);
                });

                this.validation.currentPasswordValid = isCurrentPassword;

                this.shouldUpdateExternal = isCurrentPassword;
            }
        }

        this.tabs.general.valid = this.validation.firstName && this.validation.lastName;

        this.tabs.authentication.valid =
            this.validation.email && //
            this.validation.emailValid &&
            this.validation.password &&
            this.validation.repeat &&
            this.validation.match &&
            this.validation.length &&
            this.validation.currentPassword &&
            this.validation.currentPasswordValid &&
            this.validation.unique;

        this.validation.valid =
            this.tabs.general.valid && //
            this.tabs.authentication.valid &&
            this.tabs.workspaces.valid &&
            this.tabs.agreements.valid;

        return this.validation.valid;
    }
}
