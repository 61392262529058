export enum Broadcasts {
    OnConnected = 'OnConnected',
    OnReconnected = 'OnReconnected',
    OnReconnecting = 'OnReconnecting',
    OnDisconnecting = 'OnDisconnecting',
    OnDisconnected = 'OnDisconnected',
    AppointmentCreated = 'AppointmentCreated',
    AppointmentUpdated = 'AppointmentUpdated',
    AppointmentDeleted = 'AppointmentDeleted',
    DeclarationStatusLog = 'DeclarationStatusLog'
}
