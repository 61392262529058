import { guid, isDefined, isEmpty, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject, slotted } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(IEventAggregator, Element)
export class UxSelect {
    @bindable() public value: string;
    @bindable() public data: any;
    @bindable() public debounce: number = 400;
    @bindable() public disabled: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public allowClear: boolean = false;
    @bindable() public rounded: 'left' | 'right' | 'full' = 'full';
    @bindable() public padding: 'small' | 'default' = 'default';
    @bindable() public isLoading: boolean = false;
    @bindable() public placeholder: string = 'Selecteer een optie';
    @bindable() public showFooter: boolean = false;
    @bindable() public allowCustomInput: boolean = false;
    @bindable() public focused: number = -1;
    @bindable() public offset: number = 250;
    @bindable() public used: string[] = [];
    @bindable() public setValueOnSelect: boolean = true;

    @(slotted() as any) options: HTMLElement[];

    public isVisible: boolean = false;
    public html: string;
    public noResults: boolean = false;
    public hasFocus: boolean = false;
    public container: HTMLDivElement;
    public placement: 'top' | 'bottom' = 'bottom';

    private subscriptions: IDisposable[];
    private allowHoverChange: boolean = true;
    private btn: HTMLButtonElement;
    private id: string = guid();
    private focusTimeOut: any;
    private onClick: (e: MouseEvent) => void;
    private onSelect: (e: KeyboardEvent) => void;

    public constructor(
        private readonly events: IEventAggregator,
        private readonly host: HTMLElement
    ) {}

    public bound(): void {
        // Listen to all select option clicks and
        // hide the select if one the option, which
        // is a child of this select, is clicked.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(
                UxEvents.UxSelectOptionClicked,
                (event: {
                    value: string; //
                    text: string;
                    data: any;
                    byEnter: boolean;
                    parent: string;
                }) => {
                    if (event.parent !== this.id) return;
                    this.handleSelect(event.value, event.text, event.data, event.byEnter);
                }
            ),
            this.events.subscribe(UxEvents.UxSelectOpened, (data: { id: string }) => {
                if (data.id === this.id) return;
                // Hide other select.
                this.isVisible = false;
            }),
            this.events.subscribe(UxEvents.UxSelectOptionHovered, (data: { id: string; index: number }) => {
                if (data.id !== this.id) return;
                this.focused = data.index;
                this.focusOption(false);
            })
        ];

        this.onSelect = (e: KeyboardEvent) => this.handleKeyDown(e);

        // Listen to all global clicks and hide the select
        // if the click is not on this select.
        this.onClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const combobox = target.closest('ux-select');
            const parent = target.getAttribute('data-parent');
            if (isNotDefined(combobox) && isNotDefined(parent)) {
                this.isVisible = false;
            }
        };

        document.addEventListener('click', this.onClick);
        document.addEventListener('keydown', this.onSelect);
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        document.removeEventListener('click', this.onClick);
        document.removeEventListener('keydown', this.onSelect);
    }

    public async open(): Promise<void> {
        this.isVisible = true;
        this.setFocusToValue();
    }

    public async close(): Promise<void> {
        this.isVisible = false;
    }

    public async clear(): Promise<void> {
        this.html = null;
        this.value = null;
        this.focused = 0;

        this.emit(
            UxEvents.OnClear,
            new EventDetails<UxSelect, void>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: null
            })
        );
    }

    public async setValue(value: string): Promise<void> {
        const option = this.options.find((x) => x.getAttribute('data-value') === value?.toString());
        if (isDefined(option)) {
            const content = option.querySelector('[data-type="content"]');
            this.html = content?.innerHTML.trim();
        }

        this.value = value;
    }

    public handleFocus(): void {
        this.hasFocus = true;
    }

    public handleBlur(): void {
        this.hasFocus = false;
    }

    public toggleVisibility(_: MouseEvent): void {
        if (isDefined(this.container)) {
            const pos = this.container.getBoundingClientRect();
            const offsetBottom = window.innerHeight - pos.bottom;
            // Decide whether the dropdown should place on the top or bottom.
            if (offsetBottom < this.offset) this.placement = 'top';
            else this.placement = 'bottom';
        } else this.placement = 'bottom';

        this.isVisible = !this.isVisible;
        if (!this.isVisible) return;

        this.updateOptions();

        this.events.publish(UxEvents.UxSelectOpened, { id: this.id });
    }

    public handleClear(): void {
        this.clear();
    }

    /**
     * Fired when @slotted options changes.
     */
    public optionsChanged(): void {
        if (isDefined(this.value)) this.setValue(this.value);
        this.updateOptions();
    }

    /**
     * Fired when @value changes.
     */
    public valueChanged(): void {
        this.setValue(this.value);
        this.updateOptions();
    }

    public isDefined(value: any): boolean {
        return isDefined(value);
    }

    public isNotDefined(value: any): boolean {
        return isNotDefined(value);
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }

    private handleSelect(value: string, text: string, data: any, byEnter: boolean = false): void {
        if (this.setValueOnSelect) {
            this.html = text;
            this.value = value;
        }

        this.emit(
            UxEvents.OnSelect,
            new EventDetails<UxSelect, any>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: { value, text, data, customInput: false }
            })
        );

        if (byEnter && isDefined(this.btn)) this.btn.blur();
        this.close();

        // if (this.allowCustomInput)
        this.focused = -1;
    }

    private updateOptions(): void {
        // Mark all options with the parent id.
        for (let index = 0; index < this.options.length; index++) {
            const option = this.options[index];

            option.setAttribute('parent', this.id);
            option.setAttribute(
                'hide',
                this.used
                    .filter((x) => isDefined(x))
                    .some((x) => x.toString() === option.getAttribute('data-value').toString())
                    ?.toString()
            );
            const value = option.getAttribute('data-value');
            option.setAttribute('selected', (value?.toString() === this.value?.toString()).toString());
            option.setAttribute('index', index.toString());
        }

        this.noResults = this.options.every((x) => x.getAttribute('hide') === 'true') || this.options.length === 0;
    }

    private handleKeyDown(event: KeyboardEvent): void {
        // If the input is focused and arrow up or down is pressed
        // show the options dropdown and focus first or last item.
        if (this.hasFocus && (event.key === 'ArrowUp' || event.key === 'ArrowDown') && !this.isVisible) {
            this.open();
            if (event.key === 'ArrowUp') this.focused = this.options.length - 1;
            else this.focused = 0;
            this.focusOption();
            this.scrollToFocused();
            return;
        }

        // Hide the options when the escape button is pressed.
        if (event.key === 'Escape') this.close();

        // If we have no options, navigation is not needed.
        if (this.options.length === 0) return;

        // Don't listen to key strokes if the options arent visible.
        if (!this.isVisible) return;

        // Disable the hover focus change while we are navigating.
        this.allowHoverChange = false;

        // If the 'ArrayDown' key is pressed navigate to the previous item.
        if (event.key === 'ArrowUp') {
            // Prevent other elements from scrolling
            event.preventDefault();

            if (this.focused === -1) {
                this.focused = this.options.length - 1;
            } else if (this.focused === 0) {
                if (this.allowCustomInput) this.focused = -1;
                else this.focused = this.options.length - 1;
            } else this.focused--;
            this.focusOption();
            this.scrollToFocused();
        }

        // If the ArrowUp key is pressed and the focused index
        // is not bigger than the amout of results, focus the next item.
        if (event.key === 'ArrowDown') {
            // Prevent other elements from scrolling
            event.preventDefault();
            if (this.focused === -1) {
                this.focused = 0;
            } else if (this.focused === this.options.length - 1) {
                if (this.allowCustomInput) this.focused = -1;
                else this.focused = 0;
            } else this.focused++;

            this.focusOption();
            this.scrollToFocused();
        }

        // Scroll to first or last option with Home/End keys.
        if (event.key === 'Home' || event.key === 'End') {
            // Prevent other elements from scrolling
            event.preventDefault();
            this.focused = event.key === 'Home' ? 0 : this.options.length - 1;
            this.focusOption();
            this.scrollToFocused();
        }

        // To make sure the scroll is smooth when a bit
        // until allowing changing the focused item with mouse again.
        clearTimeout(this.focusTimeOut);
        this.focusTimeOut = setTimeout(async () => (this.allowHoverChange = true), 250);
    }

    private focusOption(scrollToFocused: boolean = true): void {
        if (this.allowCustomInput) return;
        // if (isEmpty(this.value) || this.allowCustomInput) return;

        for (let index = 0; index < this.options.length; index++) {
            const option = this.options[index];
            const isFocused = index === this.focused;
            option.setAttribute('focused', isFocused.toString());
        }

        if (scrollToFocused) this.scrollToFocused();
    }

    private setFocusToValue(): void {
        if (isEmpty(this.value) || this.allowCustomInput) return;
        for (const option of this.options) {
            const isFocused = option.getAttribute('data-value') === this.value.toString();
            if (isFocused) option.setAttribute('focused', isFocused.toString());
        }
        this.scrollToFocused();
    }

    private scrollToFocused(smooth: boolean = true): void {
        const element = this.options.find((x) => x.getAttribute('focused') === 'true');
        if (isDefined(element)) element.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'nearest', inline: 'start' });
    }
}
