<import from="converters/keys"></import>
<import from="converters/healthcare-code-type-to-string"></import>

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 flex-col gap-y-3 pb-5 pt-8">
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.healthcare-codes.labels.system"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-select value.two-way="code.system" valid.bind="validation.system" t="[placeholder]translation:partial-views.healthcare-codes.placeholders.system">
                                <ux-select-option value="Vektis">Vektis</ux-select-option>
                            </ux-select>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.list"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-select value.two-way="code.list" valid.bind="validation.list" t="[placeholder]translation:partial-views.healthcare-codes.placeholders.select-list">
                                <ux-select-option value="080">Podotherapie (080)</ux-select-option>
                            </ux-select>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.healthcare-codes.labels.code"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-input value.two-way="code.value" valid.bind="validation.value" t="[placeholder]translation:partial-views.healthcare-codes.placeholders.code"></ux-input>
                        </div>
                        <div class="col-span-4 flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.type"> </span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-select valid.bind="validation.type" value.two-way="code.type">
                                <ux-select-option repeat.for="type of HealthcareCodeTypes | keys" value.bind="type">
                                    <div class="flex items-center space-x-1.5">
                                        <span>${type | healthcareCodeTypeToString | t} </span>
                                    </div>
                                </ux-select-option>
                            </ux-select>
                        </div>
                        <div class="flex flex-col">
                            <ux-label>
                                <span t="translation:partial-views.healthcare-codes.labels.invoice-description"></span>
                            </ux-label>
                            <p class="mb-1 text-xs text-gray-500" t="translation:partial-views.healthcare-codes.messages.invoice-description"></p>
                            <ux-input value.two-way="code.invoiceDescription" t="[placeholder]translation:partial-views.healthcare-codes.placeholders.invoice-description"></ux-input>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.healthcare-codes.labels.url"></span>
                            </ux-label>
                            <ux-input value.two-way="code.url" t="[placeholder]translation:partial-views.healthcare-codes.placeholders.url"></ux-input>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="code.description[language]"
                                rows="8"
                                t="[placeholder]translation:partial-views.healthcare-codes.placeholders.description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center">
                        <ux-spinner size="xs"></ux-spinner>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="primary"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
