import { I18N } from '@aurelia/i18n';
import { ContactsApiClient, GetContactResponse } from '@wecore/sdk-crm';
import { isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ContactTypes } from '../../enums/contact-types';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(ContactsApiClient, I18N, ErrorHandler)
export class BxContactSelector {
    @bindable() public value: string;
    @bindable() public selected: GetContactResponse;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public types: ContactTypes[];
    @bindable() public setValueOnSelect: boolean = true;
    @bindable() public onSelect: (contact: GetContactResponse, data: any) => void;
    @bindable() public open: () => void;

    public combobox: UxCombobox;
    public contacts: GetContactResponse[];

    public constructor(
        private readonly contactsApi: ContactsApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = this.t.tr('global.placeholders.search');
    }

    public async attaching(): Promise<void> {
        try {
            if (isNotEmpty(this.value)) {
                this.contactsApi.getById(this.value, this.workspace).then((contact) => {
                    // If the selected item is not found, use the selected object which can
                    // be provided by the user and used to display the object name in the combobox.
                    // This can be handy in case the item has been deleted.
                    if (isNotDefined(contact)) contact = this.selected;
                    this.contactsApi.search(this.workspace, '', 100, 0, undefined, undefined, this.types).then((response) => {
                        const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
                        this.contacts = isDefined(contact) ? [contact].concat(data.filter((x) => x.id !== this.value)) : data;
                        if (isDefined(this.combobox)) this.combobox.isLoading = false;
                    });
                });
            } else {
                this.contactsApi.search(this.workspace, '', 100, 0, undefined, undefined, this.types).then((response) => {
                    this.contacts = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
                    if (isDefined(this.combobox)) this.combobox.isLoading = false;
                });
            }
        } catch (e) {
            this.errorHandler.handle('BxContactSelector.attached', e);
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.contactsApi.search(this.workspace, isNotEmpty(query) ? query : '', 100, 0, undefined, undefined, this.types);
            this.contacts = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxContactSelector.handleSearch', e);
        }
    }

    public handleSelect(e: CustomEvent<EventDetails<UxCombobox, UxComboboxOption>>): void {
        const option = e.detail.values;
        const contact = this.contacts.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(contact, this.data);
    }

    public addNew(): void {
        if (isFunction(this.open)) this.open();
    }
}
