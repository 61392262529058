import { I18N } from '@aurelia/i18n';
import { GetUserResponse, UsersApiClient } from '@wecore/sdk-core';
import { isDefined, isEmpty, isFunction, isNotDefined, isNotEmpty, isZero } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { UxMultiSelector } from '../../ux/ux-multi-selector/ux-multi-selector';

@containerless
@inject(UsersApiClient, I18N, ErrorHandler)
export class BxUserMultiSelector {
    @bindable() public values: string[];
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public data: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public used: string[] = [];
    @bindable() public onChange: (options: GetUserResponse[], mutation: 'added' | 'deleted', changed: GetUserResponse, data: any) => void;
    @bindable() public onClear: () => void;
    @bindable() public onValueClick: (option: { value: string; text: string; data?: any }) => Promise<void>;

    public users: GetUserResponse[];
    public options: { text: string; value: string; data?: any }[];
    public selector: UxMultiSelector;
    private selection: GetUserResponse[] = [];

    public constructor(
        private readonly usersApi: UsersApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = this.t.tr('global.labels.search');
    }

    public async attached(): Promise<void> {
        try {
            if (isDefined(this.values) && this.values.any()) {
                const response = await this.usersApi.search(this.workspace, undefined, this.values.length, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.values);
                this.selection = response.data;
            }

            const response = await this.usersApi.search(this.workspace, '', 50, 0);
            // Combine the selection with the response so that the getOption()
            // in the ux-multi-selector.ts can find the correct option.
            this.users = [
                // Make sure the selection is sorted in the same order as the values.
                ...this.selection.sort((a, b) => {
                    const indexA = this.values.indexOf(a.id);
                    const indexB = this.values.indexOf(b.id);
                    return indexA - indexB;
                }),
                ...response.data
            ];

            this.map();
        } catch (e) {
            this.errorHandler.handle('BxUserMultiSelector.attached', e);
        }
    }

    public handleSearch = async (query: string): Promise<void> => {
        try {
            const response = await this.usersApi.search(this.workspace, isNotEmpty(query) ? query : '', 50, 0);
            this.users = response.data;

            this.map();
        } catch (e) {
            this.errorHandler.handle('BxUserMultiSelector.handleSearch', e);
        }
    };

    public handleSelected = async (option: { text: string; value: string; data?: any }): Promise<void> => {
        const user = this.users.find((x) => x.id === option.value);

        this.selection = [...this.selection, user];
        if (isFunction(this.onChange)) this.onChange(this.selection, 'added', user, this.data);
    };

    public handleRemoved = async (option: { text: string; value: string; data?: any }): Promise<void> => {
        const index = this.selection.findIndex((x) => x.id === option.value);
        let deleted: GetUserResponse;

        if (index > -1) {
            deleted = this.selection[index];
            this.selection.splice(index, 1);
            const indexOfValue = this.values.findIndex((x) => x === deleted.id);
            this.values.splice(indexOfValue, 1);
        }

        if (isFunction(this.onChange)) this.onChange(this.selection, 'deleted', deleted, this.data);
    };

    public handleClear(): void {
        if (isFunction(this.onClear)) this.onClear();
    }

    public renderItem = (item: any): string => {
        const user = item.data as GetUserResponse;
        return `<div class="flex items-center space-x-1.5">
                    <div class="${this.getBackgroundColor(user)} flex h-6 w-6 items-center justify-center rounded-full">
                        <span class="${this.getTextColor(user)} text-xs">${this.getInititals(user)}</span>
                    </div>
                    <span>${user.displayName}</span>
                </div>`;
    };

    public getBackgroundColor(user: GetUserResponse): string {
        if (isNotDefined(user.avatar) || isEmpty(user.avatar.color) || isNotDefined(user.avatar.shade) || isZero(user.avatar.shade)) return 'bg-gray-200 border border-solid border-gray-300';
        if (user.avatar.color === 'white') return `bg-${user.avatar.color}-${user.avatar.shade} border border-solid border-gray-300`;
        return `bg-${user.avatar.color}-${user.avatar.shade}`;
    }

    public getTextColor(user: GetUserResponse): string {
        if (isNotDefined(user.avatar) || isEmpty(user.avatar.color) || isNotDefined(user.avatar.shade) || isZero(user.avatar.shade)) return 'text-gray-700';

        if (user.avatar.shade < 500) return `text-${user.avatar.color}-900`;
        else return 'text-white';
    }

    public getInititals(user: GetUserResponse): string {
        let lastInitial = '';
        let firstInitial = '';

        if (isNotEmpty(user.displayName)) {
            const filtered = user.displayName.replace(/[^a-zA-Z ]/gm, '').trim();
            const displayNameSplit = filtered.split(' ');
            firstInitial = displayNameSplit[0].charAt(0);

            if (displayNameSplit.length > 1) lastInitial = displayNameSplit[displayNameSplit.length - 1].charAt(0);
        }

        return `${firstInitial}${lastInitial}`.toUpperCase();
    }

    private map(): void {
        this.options = this.users.map((x) => ({ text: x.displayName, value: x.id, data: x }));
    }
}
