import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialMasterData extends BasePartialView {
    public active: {
        route: string; //
        name: string;
        description: string;
        type: string;
        api: string;
    };
    public items: {
        route: string;
        name: string;
        description: string;
        type: string;
        api: string;
    }[] = [
        {
            route: 'sports',
            name: 'translation:partial-views.master-data.titles.sports',
            description: 'translation:partial-views.master-data.descriptions.sports',
            type: 'sport',
            api: 'sportsApi'
        },
        {
            route: 'medications',
            name: 'translation:partial-views.master-data.titles.medications',
            description: 'translation:partial-views.master-data.descriptions.medications',
            type: 'medication',
            api: 'medicationsApi'
        },
        {
            route: 'professions',
            name: 'translation:partial-views.master-data.titles.professions',
            description: 'translation:partial-views.master-data.descriptions.professions',
            type: 'profession',
            api: 'professionsApi'
        },
        {
            route: 'medical-equipment',
            name: 'translation:partial-views.master-data.titles.medical-equipment',
            description: 'translation:partial-views.master-data.descriptions.medical-equipment',
            type: 'medicalEquipment',
            api: 'medicalEquipmentApi'
        },
        {
            route: 'labels',
            name: 'translation:partial-views.master-data.titles.labels',
            description: 'translation:partial-views.master-data.descriptions.labels',
            type: 'label',
            api: 'labelsApi'
        },
        {
            route: 'contact-types',
            name: 'translation:partial-views.master-data.titles.contact-types',
            description: 'translation:partial-views.master-data.descriptions.contact-types',
            type: 'contactType',
            api: 'contactTypesApi'
        }
    ];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.loadViewsFromUrl({
                    open: async (view: string, id: string) => {
                        const item = this.items.find((i) => i.route === id) ?? this.items[0];
                        this.manage({ item });
                    }
                });
            })
            .catch((x) => this.errorHandler.handle('PartialMasterData.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async manage(options: {
        item: {
            route: string; //
            name: string;
            description: string;
            type: string;
            api: string;
        };
        updateUrl?: boolean;
    }): Promise<void> {
        const defaults = {
            updateUrl: true,
            ...options
        };

        this.active = defaults.item;

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.ManageMasterData.with({ item: defaults.item, id: defaults.item.route }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl: defaults.updateUrl
            })
        });
    }
}
