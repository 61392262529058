import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { AccountsApiClient, BlobStorageAttachment, ContactsApiClient, GetAccountOrContactResponse, GetAccountResponse, GetContactResponse, Phone } from '@wecore/sdk-crm';
import {
    AccountEntityReference,
    ContactEntityReference,
    EmailEntityTypes,
    EmailTemplateEntityReference,
    EmailTemplateTokensApiClient,
    EmailsApiClient,
    GetEmailTemplateResponse,
    GetEmailTemplateTokenResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalRecordEntityReference,
    MedicalRecordsApiClient,
    PatientEntityReference,
    PatientsApiClient,
    SendEmailRequest
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import Quill from 'quill';
import Delta from 'quill-delta';
import { BxMedicalRecordSelector } from '../../bx/bx-medical-record-selector/bx-medical-record-selector';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { languages } from '../../infra/languages';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cloneDeep, findAndReplaceEmailTokens } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';
import { UxTextarea } from '../../ux/ux-textarea/ux-textarea';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    ModalService,
    MediaLibraryApiClient,
    EmailsApiClient,
    ContactsApiClient,
    AccountsApiClient,
    EmailTemplateTokensApiClient,
    PatientsApiClient,
    MedicalRecordsApiClient
)
export class PartialSendEmailToEntity extends BasePartialView {
    public email: string;
    public editor: HTMLDivElement;
    public recordSelector: BxMedicalRecordSelector;
    public request: SendEmailRequest = new SendEmailRequest({
        to: {},
        bcc: {},
        cc: {},
        useZorgmail: false,
        isEdifact: false
    });
    public entity: GetAccountOrContactResponse;
    public validation: any = {
        subject: true,
        content: true,
        patient: true,
        record: true,
        email: true
    };
    public view: 'AccountCard' | 'ContactCard';
    public shouldUseZorgmail: boolean = false;
    public isZorgmailAddress: boolean = false;
    public patient: GetPatientResponse;
    public record: GetMedicalRecordResponse;
    public canUseZorgmail: boolean = false;

    private account: GetAccountResponse;
    private contact: GetContactResponse;
    private quill: Quill;
    private entityId: string;
    private patientId: string;
    private recordId: string;
    private entityType: EmailEntityTypes;
    private addTokenTo: 'subject' | 'editor';
    private subjectCursorStart: number;
    private subjectCursorEnd: number;
    private tokens: GetEmailTemplateTokenResponse[];
    private template: GetEmailTemplateResponse;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly modalService: ModalService,
        private readonly mediaLibrary: MediaLibraryApiClient,
        private readonly emailsApi: EmailsApiClient,
        private readonly contactsApi: ContactsApiClient,
        private readonly accountsApi: AccountsApiClient,
        private readonly tokensApi: EmailTemplateTokensApiClient,
        private readonly patientsApi: PatientsApiClient,
        private readonly recordsApi: MedicalRecordsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.email = view.data.email.value;
        this.entityId = view.data.entityId;
        this.entityType = view.data.entityType;
        this.view = view.data.view;
        this.patientId = view.data.patient;
        this.recordId = view.data.record;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const getEntity = async (): Promise<void> => {
                    switch (this.entityType) {
                        case EmailEntityTypes.Account:
                            this.account = await this.accountsApi.getById(this.entityId, this.authenticated.workspace.id);
                            this.entity = new GetAccountOrContactResponse({
                                id: this.account.id,
                                displayName: this.account.displayName,
                                emails: this.account.emails,
                                type: this.account.type,
                                typeV2: null,
                                workspace: this.account.workspace,
                                number: this.account.number,
                                entityType: 'Account'
                            });
                            this.request.account = new AccountEntityReference({ id: this.entity.id, name: this.entity.displayName });
                            break;
                        case EmailEntityTypes.Contact:
                            this.contact = await this.contactsApi.getById(this.entityId, this.authenticated.workspace.id);
                            this.entity = new GetAccountOrContactResponse({
                                id: this.contact.id,
                                displayName: this.contact.displayName,
                                emails: this.contact.emails,
                                type: this.contact.type,
                                typeV2: null,
                                workspace: this.contact.workspace,
                                number: this.contact.number?.toString(),
                                entityType: 'Contact'
                            });
                            this.request.contact = new ContactEntityReference({ id: this.entity.id, name: this.entity.displayName });
                            break;
                    }
                };

                const [tokens] = await Promise.all([
                    this.tokensApi.search('', 500, 0, undefined, undefined, undefined, [this.entityType, EmailEntityTypes.Other, EmailEntityTypes.Patient]), //
                    getEntity()
                ]);

                if (isDefined(this.patientId)) this.patient = await this.patientsApi.getById(this.patientId, this.authenticated.workspace.id);
                if (isDefined(this.recordId)) this.record = await this.recordsApi.getById(this.recordId, this.authenticated.workspace.id);
                this.tokens = tokens.data;

                if (isDefined(this.patient)) this.request.patient = new PatientEntityReference({ id: this.patient.id, name: this.patient.displayName });
                if (isDefined(this.record)) this.request.record = new MedicalRecordEntityReference({ id: this.record.id, translations: this.record.examination.name });

                this.canUseZorgmail = this.request.useZorgmail =
                    isDefined(this.authenticated.workspace.integrations) && //
                    isDefined(this.authenticated.workspace.integrations.zorgmail) &&
                    this.authenticated.workspace.integrations.zorgmail.settings.enabled;

                this.request.useZorgmail = this.shouldUseZorgmail = this.isZorgmailAddress = this.canUseZorgmail && this.email.includes('@lms.lifeline.nl');
                this.request.isEdifact = isDefined(this.patient);

                this.baseLoaded = true;
                await super.handleBaseLoaded();

                setTimeout(async () => {
                    if (isDefined(this.editor)) await this.initEditor();
                    if (isDefined(this.quill)) this.quill.focus();
                });
            })
            .catch((x) => this.errorHandler.handle('PartialSendEmailToEntity.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async send(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.send-email-to-entity.questions.send.title'),
                    message: this.t.tr('translation:partial-views.send-email-to-entity.questions.send.message'),
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.send'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                this.request.to[this.entity.displayName] = this.email;
                                // Only send the OPS array as JSON string content.
                                const content = this.getContents();
                                this.request.content = JSON.stringify(content.ops);
                                await this.emailsApi.send(this.authenticated.workspace.id, this.request);
                                this.events.publish(CustomEvents.EmailSent, this.request);
                                this.notifications.show(
                                    this.t.tr('translation:partial-views.send-email-to-entity.notifications.send-successful.title'),
                                    this.t.tr('translation:partial-views.send-email-to-entity.notifications.send-successful.message'),
                                    {
                                        type: 'success',
                                        duration: 3000
                                    }
                                );
                                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                            } catch (e) {
                                this.errorHandler.handle('send-email', e);
                            }
                        }
                    }
                })
            );
        }
    }

    public async clearHtml(confirm: boolean = true): Promise<void> {
        const callback = () => {
            if (isDefined(this.quill)) this.quill.setText('');
        };

        if (confirm)
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.information-sheet.questions.delete.title'),
                    message: this.t.tr('translation:partial-views.information-sheet.questions.delete.message'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) callback();
                    }
                })
            );
        else callback();
    }

    public handleTemplateSelected = (template: GetEmailTemplateResponse): void => {
        this.setTemplate(template);
    };

    public handlePatientSelected = async (patient: GetPatientResponse): Promise<void> => {
        this.request.record = null;
        this.record = null;

        if (isNotDefined(patient)) {
            this.patient = null;
            this.request.patient = null;
            this.request.isEdifact = false;
            return;
        }

        this.request.patient = new PatientEntityReference({
            id: patient.id,
            name: patient.displayName
        });
        this.patient = patient;
        this.request.isEdifact = this.isZorgmailAddress;

        //  Replace any patient tokens in the subject.
        if (isNotEmpty(this.request.subject)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.request.subject, this.patient, EmailEntityTypes.Patient, this.language) as string;

        // Replace any patient tokens in the content.
        const content = this.getContents();
        for (const item of content.ops) {
            if (isDefined(item.insert) && typeof item.insert === 'string') {
                item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.patient, EmailEntityTypes.Patient, this.language);
            }
        }
        this.setContents(content);

        this.setTemplate(this.template, false);
        if (isDefined(this.recordSelector)) this.recordSelector.refresh(this.patient.id);
    };

    public handleEntitySelected = async (item: GetAccountOrContactResponse): Promise<void> => {
        if (item.entityType === 'Account') {
            this.account = await this.accountsApi.getById(item.id, this.authenticated.workspace.id);
            this.request.account = new AccountEntityReference({
                id: item.id,
                name: item.displayName
            });
        } else {
            this.contact = await this.contactsApi.getById(item.id, this.authenticated.workspace.id);
            this.request.contact = new ContactEntityReference({
                id: item.id,
                name: item.displayName
            });
        }

        this.entity = item;
        this.email = this.entity.emails.find((x) => x.isZorgmail)?.value || this.entity.emails.find((x) => x.isPrimary)?.value || this.entity.emails[0]?.value;
        this.setTemplate(this.template);
    };

    public handleEmailSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        const value = e.detail.values.value;

        this.isZorgmailAddress = value.includes('@lms.lifeline.nl');
        this.request.useZorgmail = this.isZorgmailAddress || isDefined(this.request.patient);
        this.request.isEdifact = this.request.useZorgmail && isDefined(this.request.patient) && this.isZorgmailAddress;
        if (this.isZorgmailAddress && isDefined(this.template)) {
            if (isDefined(this.account)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.template.subject, this.account, EmailEntityTypes.Account, this.language) as string;
            if (isDefined(this.contact)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.template.subject, this.contact, EmailEntityTypes.Contact, this.language) as string;
        } else if (this.request.useZorgmail) this.setSubject();
    }

    public handleSubjectBlur(e: CustomEvent<EventDetails<UxTextarea, any>>): void {
        this.subjectCursorStart = (e.detail.element as any).textarea.selectionStart || 0;
        this.subjectCursorEnd = (e.detail.element as any).textarea.selectionEnd || 0;
        this.addTokenTo = 'subject';
    }

    public handleTokenSelected = (token: GetEmailTemplateTokenResponse): void => {
        if (isNotDefined(token)) return;
        resetValidation(this.validation);

        let entity: GetPatientResponse | GetAccountResponse | GetContactResponse;

        switch (token.type) {
            case EmailEntityTypes.Account:
                entity = this.account;
                break;
            case EmailEntityTypes.Contact:
                entity = this.contact;
                break;
            case EmailEntityTypes.Patient:
                entity = this.patient;
                break;
            case EmailEntityTypes.Other:
                entity = this.authenticated.user;
                break;
        }

        const replaced = isDefined(entity)
            ? findAndReplaceEmailTokens(
                  this.tokens,
                  token.value, //
                  entity,
                  token.type,
                  this.language
              )
            : token.value;

        if (this.addTokenTo === 'subject') {
            const value = this.request.subject || '';
            const newValue = value.substring(0, this.subjectCursorStart) + replaced + value.substring(this.subjectCursorEnd, value.length);
            this.request.subject = newValue;
        } else {
            const selection = this.quill.getSelection(true);
            this.quill.insertText(selection.index, replaced);
        }
    };

    public handleRecordSelected = (record: GetMedicalRecordResponse): void => {
        if (isNotDefined(record)) {
            this.record = null;
            this.request.record = null;
            return;
        }

        this.record = record;
        this.request.record = new MedicalRecordEntityReference({
            id: record.id,
            translations: record.examination.name
        });
    };

    public async handleZorgmailSettingChanged(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (checked && !this.request.isEdifact && !this.isZorgmailAddress) this.setSubject();
        else {
            if (isDefined(this.template)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.template.subject, this.entity, this.entityType, this.language) as string;
            else this.request.subject = null;
            this.request.isEdifact = false;
        }
    }

    private setSubject(): void {
        const phone = this.entity.phones.find((p: Phone) => p.isPrimary) || this.entity.phones[0];
        this.request.subject = `SMS ${isDefined(phone) ? `+${phone.callingCode}${phone.value}` : ''}`;
    }

    private getContents(): any {
        return this.quill.getContents();
    }

    private async setContents(content: any): Promise<void> {
        content = await this.refreshAttachmentsUrls(content);
        this.quill.setContents(content);
    }

    private async refreshAttachmentsUrls(content: any): Promise<any> {
        if (isNotDefined(content) || isNotDefined(content.ops)) return content;
        const checks = content.ops
            .filter((item: any) => isDefined(item.insert.imageAttachment) || (isDefined(item.insert.videoAttachment) && isDefined(item.insert.videoAttachment.attachment)))
            .map((item: any) => {
                return new Promise<void>(async (resolve) => {
                    const container = item.insert.imageAttachment || item.insert.videoAttachment;
                    // Check if URL still has correct authorization.
                    var request = new XMLHttpRequest();
                    request.open('GET', container.src, true);
                    request.send();
                    request.onload = async () => {
                        if (request.status !== 200) {
                            const url = await this.mediaLibrary.getUrl(container.attachment, this.authenticated.workspace.id, container.thumbnail || true);
                            container.src = url;
                        }
                        resolve();
                    };
                });
            });

        await Promise.all(checks);
        return content;
    }

    private initEditor(): Promise<void> {
        return new Promise((resolve) => {
            this.quill = new Quill(this.editor, {
                modules: {
                    // toolbar: [
                    //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    //     ['blockquote', 'code-block'],
                    //     [{ list: 'ordered' }, { list: 'bullet' }],
                    //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    //     [{ direction: 'rtl' }], // text direction
                    //     [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    //     // [{ font: [] }],
                    //     [{ align: [] }],
                    //     ['link', 'image'],
                    //     ['clean'] // remove formatting button
                    // ]
                    toolbar: {
                        container: `#toolbar-${this.partial.id}`,
                        handlers: {
                            image: this.handleImageSelected,
                            video: this.handleVideoSelected
                        }
                    }
                },
                theme: 'snow'
            });

            this.quill.on('selection-change', (range) => {
                if (isDefined(range)) this.addTokenTo = 'editor';
            });

            resolve();
        });
    }

    private handleVideoSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.entityId,
                entityType: this.entityType,
                // language: this.contentLanguage,
                type: 'single',
                contentTypes: ['video/x-flv', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnails: boolean }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'videoAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0].attachment?.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px'
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private handleImageSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.entityId,
                entityType: this.entityType,
                // language: this.contentLanguage,
                type: 'single',
                contentTypes: ['image/png', 'image/jpg', 'image/jpeg']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnail: boolean; language: string }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'imageAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0]?.attachment.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px',
                            thumbnail: response.thumbnail
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private setTemplate(template: GetEmailTemplateResponse, resetSubject: boolean = true): void {
        if (isNotDefined(template)) {
            if (this.request.useZorgmail && !this.request.isEdifact && !this.isZorgmailAddress) this.setSubject();
            else if (resetSubject) this.request.subject = null;

            this.request.template = null;
            this.template = null;
            this.clearHtml(false);
            return;
        }

        resetValidation(this.validation);

        this.template = template;
        this.request.template = new EmailTemplateEntityReference({
            id: template.id, //
            translations: template.name
        });

        this.clearHtml(false);

        // Only set the subject if we are not using Zorgmail.
        if (this.isZorgmailAddress || !this.request.useZorgmail) {
            this.request.subject = findAndReplaceEmailTokens(this.tokens, template.subject, this.authenticated.user, EmailEntityTypes.Other, this.language) as string;
            if (isDefined(this.account)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.request.subject, this.account, EmailEntityTypes.Account, this.language) as string;
            if (isDefined(this.contact)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.request.subject, this.contact, EmailEntityTypes.Contact, this.language) as string;
            if (isDefined(this.patient)) this.request.subject = findAndReplaceEmailTokens(this.tokens, this.request.subject, this.patient, EmailEntityTypes.Patient, this.language) as string;
        }

        // Sort by active language first.
        const langs = languages().sort((a, _) => (a === this.language ? -1 : 0));
        // Combine all languages into one delta.
        let delta = new Delta();
        for (let index = 0; index < langs.length; index++) {
            const lang = langs[index];
            // Do nothing if we don't have content for this language.
            if (isNotDefined(template.content[lang])) continue;
            // Add divider if we have more than one language.
            if (index > 0)
                delta = delta.concat(
                    new Delta().insert(
                        `\n ---------------------- ${this.t.tr(`translation:global.languages.${lang}`, {
                            lng: lang
                        })} ----------------------\n\n`
                    )
                );

            // Replace tokens in content.
            const content = cloneDeep(template.content[lang]);

            for (const item of content.ops) {
                if (isDefined(item.insert) && typeof item.insert === 'string') {
                    item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.authenticated.user, EmailEntityTypes.Other, this.language);
                    if (isDefined(this.account)) item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.account, EmailEntityTypes.Account, this.language);
                    if (isDefined(this.contact)) item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.contact, EmailEntityTypes.Contact, this.language);
                    if (isDefined(this.patient)) item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.patient, EmailEntityTypes.Patient, this.language);
                }
            }

            // Convert content to delta.
            const temp = new Delta(content);
            // Merge the delta with the temp delta.
            delta = delta.concat(temp);
        }
        this.setContents(delta);
    }

    private validate(): boolean {
        const content = this.getContents();
        this.validation.content = isDefined(content) && content.length() > 1;
        this.validation.subject = isDefined(this.request.subject) && isNotEmpty(this.request.subject);

        this.validation.email =
            isDefined(this.email) &&
            isNotEmpty(this.email) &&
            isValid(this.email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);

        return validateState(this.validation);
    }
}
