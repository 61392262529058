import { HealthcareInsuranceTypes } from '@wecore/sdk-healthcare';

export class InsuranceTypeToStringValueConverter {
    public toView(type: HealthcareInsuranceTypes): string {
        switch (type) {
            case HealthcareInsuranceTypes.BasicInsurance:
                return 'translation:global.labels.basic-insurance';
            case HealthcareInsuranceTypes.AdditionalInsurance:
                return 'translation:global.labels.additional-insurance';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
