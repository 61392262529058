import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import { GetAuthenticatedResponse } from '@wecore/sdk-core';
import { ContactType, ContactTypesApiClient, GetContactResponse } from '@wecore/sdk-crm';
import {
    ContactEntityReference,
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { ContactTypes } from '../../../../../enums/contact-types';
import { PartialViewResults } from '../../../../../enums/partial-view-results';
import { PartialViews } from '../../../../../infra/partial-views';
import { cloneDeep } from '../../../../../infra/utilities';
import { PartialView } from '../../../../../models/partial-view';
import { ViewOptions } from '../../../../../models/view-options';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
@inject(ContactTypesApiClient)
export class WidgetPatientGeneralPractitioner {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public currentUser: GetAuthenticatedResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public widgets: WidgetRegistration[] = [];

    public contact: ContactEntityReference;
    public ContactTypes: typeof ContactTypes = ContactTypes;
    public type: ContactType;

    public constructor(
        private readonly typesApi: ContactTypesApiClient //
    ) {}

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;
        this.contact = cloneDeep(this.patient.generalPractitioner);

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Dynamic;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientGeneralPractitioner,
                onSave: async (): Promise<void> => {
                    if (isNotDefined(this.contact)) return;
                    this.registration.widget.result.value = ContactEntityReference.fromJS(this.contact);
                    // Save the general practitioner to the patient and
                    // let all listeners know the patient has been updated.
                    this.patient.generalPractitioner = this.registration.widget.result.value;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );

        const response = await this.typesApi.search(this.workspace, undefined, 10, 0, undefined, undefined, undefined, [ContactTypes.GeneralPractitioner]); //
        this.type = response.data[0];

        // setTimeout(() => {
        //     this.createGp();
        // }, 500);
    }

    public handleContactSelected = async (contact: GetContactResponse): Promise<void> => {
        if (isDefined(contact))
            this.contact = new ContactEntityReference({
                id: contact.id,
                name: contact.displayName
            });
        else this.contact = null;
    };

    public createGp = async (): Promise<void> => {
        await this.addPartial(
            PartialViews.CreateContact.with({
                type: ContactTypes.GeneralPractitioner,
                lockType: true
            }).whenClosed(async (_: PartialViewResults, __: any) => {
                // ??
            }),
            new ViewOptions({
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl: false
            })
        );
    };
}
