import { I18N } from '@aurelia/i18n';
import { IRoute, IRouteableComponent } from '@aurelia/router';
import { Store } from '@aurelia/store-v1';
import { StorageKeys, getFromStorage } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { appVersion } from './infra/app-version';
import { CacheService } from './infra/cache-service';
import { ErrorHandler } from './infra/error-handler';
import { Routes } from './infra/routes';
import { clearItem, clearQuestionItem, clearTemplateItem, clearTherapyEvaluationItem, copyItem, copyQuestionItem, copyTemplateItem, copyTherapyEvaluationItem } from './infra/store/actions/copy-paste';
import { setLanguage } from './infra/store/actions/languages';
import { State } from './infra/store/state';
import { setWorkspace } from './infra/utilities';
import { AuthenticationService } from './services/service.authentication';

@inject(AuthenticationService, ErrorHandler, AureliaConfiguration, CacheService, I18N, Store<State>)
export class MyApp implements IRouteableComponent {
    static routes: IRoute[] = Routes.globalRoutes();
    public isAuthenticated: boolean = false;

    private state: State;

    public constructor(
        private readonly authService: AuthenticationService, //
        private readonly errorHandler: ErrorHandler,
        private readonly config: AureliaConfiguration,
        private readonly cache: CacheService,
        private readonly i18n: I18N,
        private readonly store: Store<State>
    ) {}

    public async attached(): Promise<void> {
        this.isAuthenticated = await this.authService.isAuthenticated();

        // Start by setting the workspace from the local storage, if present.
        this.cache.workspace = setWorkspace(getFromStorage(StorageKeys.workspace), 'my-app');

        // Configure the rollbar service for error handling.
        await this.errorHandler.configure({
            environment: this.config.get('environment.name'),
            accessToken: this.config.get('rollbar.accessToken'),
            enabled: this.config.get('rollbar.enabled'),
            version: appVersion(),
            payload: {}
        });

        // Register rollbar payload if authenticated
        if (this.isAuthenticated) {
            const authenticated = await this.cache.getAuthenticatedUser();

            this.store.registerAction('setLanguage', setLanguage);
            this.store.registerAction('copyItem', copyItem);
            this.store.registerAction('clearItem', clearItem);
            this.store.registerAction('copyTemplateItem', copyTemplateItem);
            this.store.registerAction('clearTemplateItem', clearTemplateItem);
            this.store.registerAction('copyQuestionItem', copyQuestionItem);
            this.store.registerAction('clearQuestionItem', clearQuestionItem);
            this.store.registerAction('copyEvaluationItem', copyTherapyEvaluationItem);
            this.store.registerAction('clearEvaluationItem', clearTherapyEvaluationItem);

            // Set current language
            await this.i18n.setLocale(authenticated.settings.language);
            await this.store.dispatch(setLanguage, authenticated.settings.language);

            await this.errorHandler.payload({
                person: {
                    id: authenticated.id,
                    user: authenticated.displayName,
                    workspace: getFromStorage(StorageKeys.workspace)
                }
            });
        }
    }
}
