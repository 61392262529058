import { BodySides } from '@wecore/sdk-healthcare';

export class SideToStringValueConverter {
    public toView(side: BodySides): string {
        switch (side) {
            case BodySides.Left:
                return 'translation:global.labels.sides.left';
            case BodySides.Right:
                return 'translation:global.labels.sides.right';
            case BodySides.Both:
                return 'translation:global.labels.sides.both';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
