<import from="converters/translate" />
<import from="converters/truncate" />
<import from="converters/sort-medical-history" />
<import from="converters/month-to-string" />
<import from="converters/medical-history-type-to-string" />
<import from="converters/priority-background-color" />
<import from="converters/priority-text-color" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <template if.bind="baseLoaded">
            <div class="flex flex-1 h-full gap-8 pl-5">
                <div class="w-48 pt-4 pr-5 border-r border-gray-200">
                    <nav class="space-y-1">
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md">
                            <span class="truncate" t="translation:global.buttons.dashboard"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:global.buttons.activity"></span>
                        </button>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'EntityContactMoments' ? 'bg-gray-100 text-gray-900': 'text-gray-600  hover:text-gray-900'"
                            click.trigger="open('EntityContactMoments')"
                            class="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.accounts.buttons.contact-moments"></span>
                        </a>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.accounts.buttons.relations"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.accounts.buttons.attachments"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.accounts.buttons.notes"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.accounts.buttons.tasks"></span>
                        </button>
                    </nav>
                </div>
                <div class="flex flex-col flex-1 w-full h-full pt-6 pr-8 overflow-y-auto">
                    <div class="sticky top-0 z-10 flex justify-end w-full pt-1 bg-white">
                        <ux-button-group full-width.bind="false">
                            <ux-dropdown if.bind="account.emails.length > 0" placement="bottom-right" type="full" color="dark" size="sm" padding.bind="false" mode="outline" width="w-[400px]">
                                <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </div>
                                <ux-dropdown-option repeat.for="email of account.emails" color="dark" click.trigger="sendEmailTo(email)">
                                    <div class="flex items-center space-x-1">
                                        <span>${email.translations | translate} &bull; ${email.value}</span>
                                        <svg if.bind="email.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <ux-dropdown if.bind="account.phones.length > 0" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right" width="w-64">
                                <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                    </svg>
                                </div>
                                <ux-dropdown-option repeat.for="phone of account.phones" color="dark" href="tel:${phone.callingCode}${phone.value}">
                                    <div class="flex items-center space-x-1">
                                        <span>${phone.translations | translate} &bull; +${phone.callingCode}${phone.value}</span>
                                        <svg if.bind="phone.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <ux-dropdown
                                if.bind="hasRole(UserRoles.WriteAccounts) || hasRole(UserRoles.DeleteAccounts)"
                                type="full"
                                color="dark"
                                padding.bind="false"
                                size="sm"
                                mode="outline"
                                placement="bottom-right">
                                <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2">
                                    <svg class="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                        <path
                                            fill="currentColor"
                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                    </svg>
                                </div>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.WriteAccounts)" color="dark" click.trigger="edit()">
                                    <span t="translation:global.buttons.edit"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-divider if.bind="hasRole(UserRoles.WriteAccounts) && hasRole(UserRoles.DeleteAccounts)"></ux-dropdown-divider>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteAccounts)" color="dark" click.trigger="delete()">
                                    <span class="text-red-500 pointer-events-none" t="translation:global.buttons.delete"></span>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <ux-button if.bind="closeable" padding.bind="false" click.trigger="close()" color="dark" size="sm" mode="outline">
                                <div class="pointer-events-none flex h-[38px] items-center px-2 text-red-500">
                                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>
                                </div>
                            </ux-button>
                        </ux-button-group>
                    </div>
                    <div class="${ expanded ? 'grid-cols-2' : 'grid-cols-1'} mt-5 grid gap-3.5">
                        <div class="flex flex-col col-span-1">
                            <div class="flex flex-col items-center justify-center col-span-1">
                                <div class="border-8 border-gray-100 rounded-full">
                                    <ux-avatar size="xl" name.bind="account.displayName" color.bind="account.avatar.color" shade.bind="account.avatar.shade"></ux-avatar>
                                </div>
                                <h4 class="mt-2 text-xl font-bold">${account.displayName}</h4>
                                <p class="text-gray-500">${account.number}</p>
                            </div>
                            <div class="grid gap-2 mt-5 grid-col-1">
                                <div class="flex flex-col w-full p-5 space-y-1 text-sm rounded-lg bg-gray-50">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.addresses"></p>
                                    <div class="flex items-start justify-between" repeat.for="address of account.addresses">
                                        <p class="flex items-center text-gray-600 gap-x-1">
                                            <span if.bind="address.translations">${address.translations | translate:language}</span>
                                            <span else>${address.type}</span>
                                            <svg if.bind="address.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <p class="font-medium text-right">
                                            ${address.street} ${address.houseNumber}${address.houseNumberSuffix || ''} <br />
                                            ${address.zipCode} ${address.city}<br />
                                            ${ 'translation:global.countries.' + address.country | t}
                                        </p>
                                    </div>
                                    <p if.bind="account.addresses.length === 0" class="text-gray-500" t="translation:global.messages.no-addresses-added"></p>
                                </div>
                                <div class="flex flex-col w-full p-5 space-y-1 text-sm rounded-lg bg-gray-50">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.phone-numbers"></p>
                                    <div repeat.for="phone of account.phones" class="flex items-center justify-between">
                                        <p class="flex items-center text-gray-600 gap-x-1">
                                            <span if.bind="phone.translations">${phone.translations | translate:language}</span>
                                            <span else>${phone.description}</span>
                                            <svg if.bind="phone.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <ux-anchor size="sm" link="tel:+${phone.callingCode}${phone.value}">+${phone.callingCode}${phone.value}</ux-anchor>
                                    </div>
                                    <p if.bind="account.phones.length === 0" class="text-gray-500" t="translation:global.messages.no-phones-added"></p>
                                </div>
                                <div class="flex flex-col w-full p-5 space-y-1 text-sm rounded-lg bg-gray-50">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.emails"></p>
                                    <div repeat.for="email of account.emails" class="flex items-center justify-between">
                                        <p class="flex items-center text-gray-600 gap-x-1">
                                            <span if.bind="email.translations">${email.translations | translate:language}</span>
                                            <span else>${email.description}</span>
                                            <svg if.bind="email.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <ux-anchor size="sm" click.trigger="sendEmailTo(email)">${email.value}</ux-anchor>
                                    </div>
                                    <p if.bind="account.emails.length === 0" class="text-gray-500" t="translation:global.messages.no-emails-added"></p>
                                </div>
                                <div class="flex flex-col w-full p-5 space-y-1 text-sm rounded-lg bg-gray-50">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.web-links"></p>
                                    <div repeat.for="link of account.links" class="flex items-center justify-between">
                                        <p class="flex items-center min-w-0 text-gray-600 gap-x-1">
                                            <span if.bind="link.translations">${link.translations | translate:language}</span>
                                            <span else>${link.description}</span>
                                            <svg if.bind="link.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <ux-anchor target="_blank" size="sm" link="${link.protocol}${link.value}">${link.protocol}${link.value | truncate:30}</ux-anchor>
                                    </div>
                                    <p if.bind="account.links.length === 0" class="text-gray-500" t="translation:global.messages.no-links-added"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>
