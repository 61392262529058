import { isFunction } from '@wecore/sdk-utilities';
import { bindable } from 'aurelia';

export class UxDropzone {
    @bindable() public maxFiles: number = 0;
    @bindable() public valid: boolean = true;
    @bindable() public acceptedFiles: string;
    @bindable() public onFilesSelected: (files: File[]) => void;

    public dropzone: HTMLDivElement;
    public input: HTMLInputElement;

    private events: string[] = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'];
    private handleBlock = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };
    private handleDragOver = (e: DragEvent) => {
        this.dropzone.classList.add('bg-gray-50');
    };
    private handleDragLeave = (e: DragEvent) => {
        this.dropzone.classList.remove('bg-gray-50');
    };
    private handleDrop = (e: DragEvent) => {
        this.dropzone.classList.remove('bg-gray-50');
        if (isFunction(this.onFilesSelected)) this.onFilesSelected(Array.from(e.dataTransfer.files));
    };

    public bound(): void {
        this.events.forEach((event) => this.dropzone.addEventListener(event, this.handleBlock));
        this.dropzone.addEventListener('dragover', this.handleDragOver, false);
        this.dropzone.addEventListener('dragleave', this.handleDragLeave, false);
        this.dropzone.addEventListener('drop', this.handleDrop, false);
        this.dropzone.addEventListener('click', () => this.input.click());
    }

    public detaching(): void {
        this.events.forEach((event) => this.dropzone.removeEventListener(event, this.handleBlock));
        this.dropzone.removeEventListener('dragover', this.handleDragOver, false);
        this.dropzone.removeEventListener('dragleave', this.handleDragLeave, false);
        this.dropzone.removeEventListener('drop', this.handleDrop, false);
        this.dropzone.removeEventListener('click', () => this.input.click());
    }

    public handleFilesSelected(e: Event): void {
        if (isFunction(this.onFilesSelected)) this.onFilesSelected(Array.from(this.input.files));
    }
}
