import { guid, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject, slotted } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';

@inject(IEventAggregator)
export class UxDropdown {
    @bindable() public color: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark' = 'primary';
    @bindable() public mode: 'solid' | 'outline' = 'solid';
    @bindable() public type: 'default' | 'full' | 'sm:full' = 'default';
    @bindable() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
    @bindable() public disabled: boolean = false;
    @bindable() public padding: boolean = true;
    @bindable() public border: boolean = true;
    @bindable() public transparent: boolean = false;
    @bindable() public rounded: boolean = false;
    @bindable() public width: string = 'w-60';
    @bindable() public placement: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-richt' = 'bottom-left';

    @(slotted('ux-dropdown-option') as any) options: HTMLElement[];

    public isVisible: boolean = false;
    private handleGlobalClick: (e: MouseEvent) => void;
    private subscriptions: IDisposable[];
    private id: string = guid();

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public bound(): void {
        // Listen to all global clicks and hide the dropdown
        // if the click is not on this dropdown.
        this.handleGlobalClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const dropdown = target.closest('ux-dropdown');
            if (isNotDefined(dropdown)) this.isVisible = false;
        };

        // Listen to all dropdown option clicks and
        // hide the dropdown if one the option, which
        // is a child of this dropdown, is clicked.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(UxEvents.UxDropdownOptionClicked, (data: { parent: string }) => {
                if (data.parent !== this.id) return;
                this.isVisible = false;
            }),
            this.events.subscribe(UxEvents.UxDropdownOpened, (data: { id: string }) => {
                if (data.id === this.id) return;
                // Hide other dropdowns.
                this.isVisible = false;
            })
        ];

        document.addEventListener('click', this.handleGlobalClick);
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        document.removeEventListener('click', this.handleGlobalClick);
    }

    public toggleVisibility(_: MouseEvent): void {
        this.isVisible = !this.isVisible;
        if (!this.isVisible) return;

        // Mark all options with the parent id.
        for (const option of this.options) option.setAttribute('parent', this.id);
        this.events.publish(UxEvents.UxDropdownOpened, { id: this.id });
    }
}
