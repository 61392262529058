import { MedicalResult, MedicalTherapyPlanFlow, MedicalTherapyPlanItem, MedicalTherapyPlanItemTypes, TherapyDefaultValues } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { generateColumns } from '../../../../../infra/utilities';
import { StepState } from '../../../../../models/step-state';

@containerless
@inject(IEventAggregator)
export class TemplateTherapyCategory {
    @bindable() public flow: MedicalTherapyPlanFlow;
    @bindable() public item: MedicalTherapyPlanItem;
    @bindable() public def: TherapyDefaultValues;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public getFlat: (id: string) => {
        stepId: string;
        item: MedicalTherapyPlanItem;
        choices: MedicalResult[];
    };

    public columns: string;
    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    private subscriptions: IDisposable[];

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        if (isNotDefined(this.item.attributes)) this.item.attributes = { expanded: false };
        this.setColumns();

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.TherapyCategoryChanged, (data: { stepId: string; expand: boolean }) => {
                // Find all connected categories where the key matches the id of
                // this current step and where the values matches the id of the
                // the category that is clicked.
                const shouldChange = this.flow.connectedCategories //
                    .filter((x) => x.key === this.item.id)
                    .some((x) => x.value === data.stepId);
                // If we have any settings, we know that the expanded and height state
                // of the  current category needs to change.
                if (shouldChange) this.item.attributes.expanded = data.expand;
            })
        ];
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.item.attributes.expanded = !this.item.attributes.expanded;
        this.events.publish(CustomEvents.TherapyCategoryChanged, {
            stepId: this.item.id, //
            expand: this.item.attributes.expanded
        });
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints.filter((x) => x.id === this.item.id) || [] //
        );
    };
}
