<ux-multi-selector
    if.bind="options"
    placeholder.bind="placeholder"
    component.ref="selector"
    on-search.bind="handleSearch"
    on-selected.bind="handleSelected"
    search-placeholder.bind="searchPlaceholder"
    on-removed.bind="handleRemoved"
    show-footer.bind="false"
    language.bind="language"
    autocomplete.bind="false"
    disabled.bind="disabled"
    valid.bind="valid"
    values.two-way="values"
    options.two-way="options">
</ux-multi-selector>
