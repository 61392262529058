import { HealthcareInvoiceStatuses } from '@wecore/sdk-healthcare';

export class InvoiceStatusToColorValueConverter {
    public toView(type: HealthcareInvoiceStatuses, forBackground: boolean = false): string {
        switch (type) {
            case HealthcareInvoiceStatuses.Draft:
                return forBackground ? 'bg-primary' : 'info';
            case HealthcareInvoiceStatuses.Overdue:
            case HealthcareInvoiceStatuses.CompensationRejected:
                return forBackground ? 'bg-red-500' : 'danger';
            case HealthcareInvoiceStatuses.Paid:
            case HealthcareInvoiceStatuses.Compensated:
                return forBackground ? 'bg-green-500' : 'success';
            case HealthcareInvoiceStatuses.Declared:
            case HealthcareInvoiceStatuses.PartiallyDeclared:
                return forBackground ? 'bg-purple-600' : 'secondary';
            default:
                return forBackground ? 'bg-yellow-500' : 'warning';
        }
    }
}
