<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/schedule-particular-to-string" />
<import from="bx/bx-practice-location-selector/bx-practice-location-selector" />
<import from="bx/bx-user-selector/bx-user-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 flex-col gap-y-3 pb-5 pt-8">
                        <div class="flex gap-1 font-medium text-gray-400">
                            <p t="translation:partial-views.manage-schedules.timeslot.title"></p>
                            <span>- ${room}</span>
                        </div>
                        <div class="flex flex-col gap-3">
                            <div class="flex flex-col">
                                <div class="flex items-center gap-1">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.practitioner"></span>
                                    </ux-label>
                                    <span class="flex h-3 items-center text-red-500">*</span>
                                </div>
                                <bx-user-selector
                                    on-select.bind="handleUserSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]translation:partial-views.manage-schedules.placeholders.select-practitioner;[placeholder-search]translation:global.placeholders.search"
                                    valid.bind="validation.practitioner"
                                    value.to-view="slot.practitioner.id">
                                </bx-user-selector>
                            </div>
                            <div class="flex gap-1">
                                <div class="flex flex-1 flex-col">
                                    <div class="flex items-center gap-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.start"></span>
                                        </ux-label>
                                        <span class="flex h-3 items-center text-red-500">*</span>
                                    </div>
                                    <ux-input
                                        mask="00:00"
                                        oninput.trigger="handleTimeInput($event)"
                                        data.bind="'start'"
                                        value.two-way="slot.start"
                                        valid.bind="validation.start && validation.range && validation.overlap"
                                        t="[placeholder]translation:global.labels.start">
                                    </ux-input>
                                </div>
                                <div class="flex flex-1 flex-col">
                                    <div class="flex items-center gap-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.end"></span>
                                        </ux-label>
                                        <span class="flex h-3 items-center text-red-500">*</span>
                                    </div>
                                    <ux-input
                                        mask="00:00"
                                        oninput.trigger="handleTimeInput($event)"
                                        data.bind="'end'"
                                        value.two-way="slot.end"
                                        valid.bind="validation.end && validation.range && validation.overlap"
                                        t="[placeholder]translation:global.labels.end">
                                    </ux-input>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-schedules.labels.particulars"></span>
                                </ux-label>
                                <ux-select value.two-way="slot.particulars" allow-clear.bind="true" t="[placeholder]translation:global.placeholders.select-option">
                                    <ux-select-option repeat.for="particular of ScheduleTimeSlotParticulars | keys" value.bind="particular">
                                        <div class="flex items-center space-x-1.5">
                                            <span>${particular | scheduleParticularToString | t} </span>
                                        </div>
                                    </ux-select-option>
                                </ux-select>
                                <p class="mt-2 text-xs text-gray-500" t="translation:partial-views.manage-schedules.messages.valid-particulars"></p>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-schedules.labels.valid-from"></span>
                                </ux-label>
                                <ux-datepicker
                                    onclear.trigger="handleDateCleared($event, 'from')"
                                    allow-clear.bind="true"
                                    mode="input"
                                    value.two-way="slot.validFrom"
                                    t="[placeholder]translation:global.placeholders.select-date">
                                </ux-datepicker>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-schedules.labels.valid-until"></span>
                                </ux-label>
                                <ux-datepicker
                                    onclear.trigger="handleDateCleared($event, 'until')"
                                    allow-clear.bind="true"
                                    mode="input"
                                    value.two-way="slot.validUntil"
                                    t="[placeholder]translation:global.placeholders.select-date">
                                </ux-datepicker>
                            </div>
                            <div class="flex flex-col text-xs text-red-500">
                                <p if.bind="!validation.range" t="translation:partial-views.manage-schedules.messages.invalid-start-end-time"></p>
                                <p if.bind="!validation.overlap" t="translation:partial-views.manage-schedules.messages.overlapping-start-end-time"></p>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="primary"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.process"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
