import { MedicalExaminationActionFlow, MedicalExaminationActionItem } from '@wecore/sdk-healthcare';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { generateColumns } from '../../../../../infra/utilities';

@containerless
@inject(IEventAggregator)
export class TemplateActionStep {
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public language: string;

    @bindable() public openSettings: (index: number, nested: boolean) => Promise<void>;

    public columns: string;
    private subscription: any;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.setColumns();
        this.subscription = this.events.subscribe(CustomEvents.ExaminationStepSettingsChanged, this.setColumns);
    }

    public detached(): void {
        if (isDefined(this.subscription)) this.subscription.dispose();
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.id) || [] //
        );
    };
}
