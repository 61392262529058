<import from="./ux-button-options.scss" />

<template class="relative inline-flex flex-1 text-left">
    <div class="trigger">
        <au-slot />
    </div>
    <div>
        <ux-button
            class="h-full"
            has-options.bind="true"
            component.ref="trigger"
            border.bind="false"
            padding.bind="false"
            click.trigger="toggleVisibility($event)"
            disabled.bind="disabled"
            color.bind="color"
            size.bind="size"
            type.bind="type"
            mode.bind="mode">
            <div class="flex items-center px-2">
                <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
            </div>
        </ux-button>
        <div
            if.bind="isVisible"
            bottom-[42px].class="placement === 'top-left' || placement === 'top-right'"
            left-0.class="placement === 'top-left' || placement === 'bottom-left'"
            right-0.class="placement === 'top-right' || placement === 'bottom-right'"
            ref="dropdown"
            class="absolute z-50 mt-2 flex w-56 min-w-[15rem] origin-top-right flex-col rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1">
            <au-slot name="options" />
        </div>
    </div>
</template>
