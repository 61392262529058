import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

export class GetPropertyValueConverter {
    public toView(items: any[], properties: string): any[] {
        if (isNotDefined(items)) return [];

        if (isNotEmpty(properties)) {
            if (properties.includes(':')) {
                const [array, property] = properties.split(':');
                items = items.selectMany((x) => x[array]).map((x) => x[property]);
            } else items = items.map((x) => x[properties]);
        }

        return items.filter((x) => isDefined(x));
    }
}
