<import from="converters/sort-by-language" />
<import from="bx/bx-user-multi-selector/bx-user-multi-selector" />
<import from="bx/bx-invoice-multi-selector/bx-invoice-multi-selector" />
<import from="bx/bx-patient-multi-selector/bx-patient-multi-selector" />
<import from="bx/bx-insurer-multi-selector/bx-insurer-multi-selector" />
<import from="bx/bx-declaration-multi-selector/bx-declaration-multi-selector" />
<import from="templates/template-filter/template-filter" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-10 flex flex-col gap-2 border-b border-gray-200 bg-white pb-3 pt-8">
                        <div class="flex items-center justify-between">
                            <p class="font-medium text-gray-800" t="translation:partial-views.filters.labels.title"></p>
                        </div>
                        <ux-body-divider if.bind="settings.start || settings.end" margin="mb-1"></ux-body-divider>
                        <div if.bind="settings.start || settings.end" class="flex flex-wrap gap-1">
                            <ux-button click.trigger="selectPeriod('yesterday')" mode="outline" color="dark" size="xs">
                                <span t="translation:global.labels.yesterday"></span>
                            </ux-button>
                            <ux-button click.trigger="selectPeriod('today')" mode="outline" color="dark" size="xs">
                                <span t="translation:global.labels.today"></span>
                            </ux-button>
                            <ux-button click.trigger="selectPeriod('tomorrow')" mode="outline" color="dark" size="xs">
                                <span t="translation:global.labels.tomorrow"></span>
                            </ux-button>
                            <template if.bind="settings.end">
                                <ux-button click.trigger="selectPeriod('previous-week')" mode="outline" color="dark" size="xs">
                                    <span t="translation:global.labels.previous-week"></span>
                                </ux-button>
                                <ux-button click.trigger="selectPeriod('this-week')" mode="outline" color="dark" size="xs">
                                    <span t="translation:global.labels.this-week"></span>
                                </ux-button>
                                <ux-button click.trigger="selectPeriod('next-week')" mode="outline" color="dark" size="xs">
                                    <span t="translation:global.labels.next-week"></span>
                                </ux-button>
                                <ux-button click.trigger="selectPeriod('previous-month')" mode="outline" color="dark" size="xs">
                                    <span t="translation:global.labels.previous-month"></span>
                                </ux-button>
                                <ux-button click.trigger="selectPeriod('this-month')" mode="outline" color="dark" size="xs">
                                    <span t="translation:global.labels.this-month"></span>
                                </ux-button>
                                <ux-button click.trigger="selectPeriod('next-month')" mode="outline" color="dark" size="xs">
                                    <span t="translation:global.labels.next-month"></span>
                                </ux-button>
                            </template>
                        </div>
                    </div>
                    <div class="mt-3 flex flex-1 flex-col gap-3 pb-5">
                        <div if.bind="settings.start" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.period-start"></span>
                            </ux-label>
                            <ux-datepicker
                                data="start"
                                value.to-view="filters.start"
                                onselect.trigger="handleDateChanged($event)"
                                onclear.trigger="handleDateCleared($event)"
                                allow-clear.bind="true"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-start">
                            </ux-datepicker>
                        </div>
                        <div if.bind="settings.end" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.period-end"></span>
                            </ux-label>
                            <ux-datepicker
                                data="end"
                                value.to-view="filters.end"
                                onselect.trigger="handleDateChanged($event)"
                                onclear.trigger="handleDateCleared($event)"
                                allow-clear.bind="true"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-end">
                            </ux-datepicker>
                        </div>
                        <div if.bind="settings.practitioners" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.practitioners"></span>
                            </ux-label>
                            <bx-user-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                on-change.bind="handleUserSelected"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-practitioners"
                                values.to-view="filters.practitioners.map((x) => x.key)">
                            </bx-user-multi-selector>
                        </div>
                        <div if.bind="settings.invoices" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.invoices"></span>
                            </ux-label>
                            <bx-invoice-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                on-change.bind="handleInvoiceSelected"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-invoices"
                                values.to-view="filters.invoices.map((x) => x.key)">
                            </bx-invoice-multi-selector>
                        </div>
                        <div if.bind="settings.declarations" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.declarations"></span>
                            </ux-label>
                            <bx-declaration-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                on-change.bind="handleDeclarationSelected"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-declarations"
                                values.to-view="filters.declarations.map((x) => x.key)">
                            </bx-declaration-multi-selector>
                        </div>
                        <div if.bind="settings.declarationSendMethods" class="flex flex-col">
                            <ux-label>
                                <span t="translation:partial-views.filters.labels.declaration-send-methods"></span>
                            </ux-label>
                            <ux-multi-selector
                                options.bind="toSet('DeclarationSendMethods')"
                                on-selected.bind="handleSendMethodSelected"
                                on-removed.bind="handleSendMethodRemoved"
                                show-footer.bind="false"
                                force-remove.bind="true"
                                autocomplete.bind="true"
                                values.to-view="filters.declarationSendMethods.map((x) => x.key)"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-declaration-send-methods">
                            </ux-multi-selector>
                        </div>
                        <div if.bind="settings.declarationStatuses" class="flex flex-col">
                            <ux-label>
                                <span t="translation:partial-views.filters.labels.declaration-statuses"></span>
                            </ux-label>
                            <ux-multi-selector
                                options.bind="toSet('DeclarationStatuses')"
                                on-selected.bind="handleDeclarationStatusSelected"
                                on-removed.bind="handleDeclarationStatusRemoved"
                                show-footer.bind="false"
                                force-remove.bind="true"
                                autocomplete.bind="true"
                                values.to-view="filters.declarationStatuses.map((x) => x.key)"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-statuses">
                            </ux-multi-selector>
                        </div>
                        <div if.bind="settings.performanceStatuses" class="flex flex-col">
                            <ux-label>
                                <span t="translation:partial-views.filters.labels.performance-statuses"></span>
                            </ux-label>
                            <ux-multi-selector
                                options.bind="toSet('DeclarationPerformanceStatuses')"
                                on-selected.bind="handlePerformanceStatusSelected"
                                on-removed.bind="handlePeformanceStatusRemoved"
                                show-footer.bind="false"
                                force-remove.bind="true"
                                autocomplete.bind="true"
                                values.to-view="filters.performanceStatuses.map((x) => x.key)"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-statuses">
                            </ux-multi-selector>
                        </div>
                        <div if.bind="settings.performanceTypes" class="flex flex-col">
                            <ux-label>
                                <span t="translation:partial-views.filters.labels.performance-types"></span>
                            </ux-label>
                            <ux-multi-selector
                                options.bind="toSet('DeclarationPerformanceTypes')"
                                on-selected.bind="handlePerformanceTypeSelected"
                                on-removed.bind="handlePeformanceTypeRemoved"
                                show-footer.bind="false"
                                force-remove.bind="true"
                                autocomplete.bind="true"
                                values.to-view="filters.performanceTypes.map((x) => x.key)"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-types">
                            </ux-multi-selector>
                        </div>
                        <div if.bind="settings.patients" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.patients"></span>
                            </ux-label>
                            <bx-patient-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                on-change.bind="handlePatientSelected"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-patients"
                                values.to-view="filters.patients.map((x) => x.key)">
                            </bx-patient-multi-selector>
                        </div>
                        <div if.bind="settings.insurers" class="flex flex-col">
                            <ux-label>
                                <span t="translation:global.labels.insurers"></span>
                            </ux-label>
                            <bx-insurer-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                on-change.bind="handleInsurerSelected"
                                t="[placeholder]translation:partial-views.filters.placeholders.select-insurers"
                                values.to-view="filters.insurers.map((x) => x.key)">
                            </bx-insurer-multi-selector>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center">
                        <ux-spinner size="xs"></ux-spinner>
                    </div>
                </template>
                <div justify-between.class="hasFilters" justify-end.class="!hasFilters" class="sticky bottom-0 z-10 flex items-center gap-x-2 border-t border-gray-200 bg-white py-4">
                    <a if.bind="hasFilters" click.trigger="reset()" href="javascript:" class="text-sm text-primary underline">
                        <span t="translation:partial-views.filters.buttons.reset-filter"></span>
                    </a>
                    <div class="flex items-center gap-2">
                        <ux-button size="sm" mode="outline" color="dark" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="primary"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.filter"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
