<import from="converters/get-values" />

<section class="mb-2 flex w-full flex-col">
    <div class="flex items-center justify-between" class="mb-1">
        <div class="flex flex-col">
            <ux-label>
                <span t="translation:components.breakpoints-selector.labels.breakpoints"></span>
            </ux-label>
        </div>
        <ux-button if.bind="breakpoints.length > 0" click.trigger="add()" padding.bind="false" size="xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </ux-button>
    </div>
    <template if.bind="breakpoints.length > 0">
        <div class="item mt-2 flex flex-col items-start justify-between gap-y-2 sm:flex-row sm:space-x-2 sm:space-y-0" repeat.for="breakpoint of breakpoints">
            <div class="flex flex-1">
                <ux-select value.two-way="breakpoint.screenSize" valid.bind="validation[$index].screenSize" used.bind="breakpoints | getValues:'screenSize'">
                    <ux-select-option repeat.for="size of sizes" value.bind="size">${ size }</ux-select-option>
                </ux-select>
            </div>
            <div class="flex flex-1">
                <ux-select rounded="left" valid.bind="validation[$index].width" value.two-way="breakpoint.width">
                    <ux-select-option value="1">1</ux-select-option>
                    <ux-select-option value="2">2</ux-select-option>
                    <ux-select-option value="3">3</ux-select-option>
                    <ux-select-option value="4">4</ux-select-option>
                    <ux-select-option value="5">5</ux-select-option>
                    <ux-select-option value="6">6</ux-select-option>
                    <ux-select-option value="7">7</ux-select-option>
                    <ux-select-option value="8">8</ux-select-option>
                    <ux-select-option value="9">9</ux-select-option>
                    <ux-select-option value="10">10</ux-select-option>
                    <ux-select-option value="11">11</ux-select-option>
                    <ux-select-option value="12">12</ux-select-option>
                </ux-select>
                <button
                    class.bind="true ? 'border-gray-300' : 'border-red-500'"
                    class="inline-flex h-[38px] items-center rounded-r-lg border-y border-r bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-30 focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                    click.trigger="remove($index)">
                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </template>
    <p class="mt-3 text-xs text-gray-500" t="translation:components.breakpoints-selector.messages.breakpoints"></p>
    <!-- <div else class="flex flex-col items-center justify-center py-5 gap-y-2">
            <p class="text-xs text-gray-500" t="translation:components.choices-selector.messages.no-options-selected"></p>
            <button click.trigger="add()" class="flex items-center px-4 py-2 text-gray-800 border border-gray-300 rounded-full ring-gray-400 ring-offset-2 focus:outline-none focus:ring-1 active:text-gray-500">
                <span class="text-xs" t="translation:components.choices-selector.buttons.add-an-option"></span>
            </button>
        </div> -->
</section>
