import { DeclarationStatuses } from '@wecore/sdk-healthcare';

export class DeclarationStatusToStringValueConverter {
    public toView(type: DeclarationStatuses): string {
        switch (type) {
            case DeclarationStatuses.Draft:
                return 'translation:global.labels.draft';
            case DeclarationStatuses.FailedInternalValidation:
                return 'translation:global.labels.declaration-statuses.failed-internal-validation';
            case DeclarationStatuses.FailedToCheck:
                return 'translation:global.labels.declaration-statuses.failed-to-check';
            case DeclarationStatuses.FailedToSend:
                return 'translation:global.labels.declaration-statuses.failed-to-send';
            case DeclarationStatuses.SentToVecozo:
                return 'translation:global.labels.declaration-statuses.sent-to-vecozo';
            case DeclarationStatuses.FailedToConfirm:
                return 'translation:global.labels.declaration-statuses.failed-to-confirm';
            case DeclarationStatuses.SentToInsuranceCompany:
                return 'translation:global.labels.declaration-statuses.sent-to-insurance-company';
            case DeclarationStatuses.Rejected:
                return 'translation:global.labels.rejected';
            case DeclarationStatuses.RejectedByVecozo:
                return 'translation:global.labels.declaration-statuses.rejected-by-vecozo';
            case DeclarationStatuses.PartiallyAccepted:
                return 'translation:global.labels.declaration-statuses.partially-accepted';
            case DeclarationStatuses.Archived:
                return 'translation:global.labels.archived';
            case DeclarationStatuses.Accepted:
                return 'translation:global.labels.awarded';
            case DeclarationStatuses.DownloadedForInfomedics:
                return 'translation:global.labels.declaration-statuses.downloaded-for-infomedics';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
