<import from="converters/healthcare-sector-to-string" />

<div class="flex flex-col">
    <div class="mb-1 flex flex-col">
        <ux-input component.ref="input" onkeydown.trigger="handleOnInput($event)" placeholder.bind="placeholder"></ux-input>
    </div>
    <ul class="flex flex-col gap-1">
        <li repeat.for="tag of tags">
            <div class="group flex min-w-0 justify-between gap-x-1 text-left text-sm">
                <div
                    bg-gray-50.class="background === 'gray'"
                    bg-white.class="background === 'white'"
                    h-[38px].class="itemTemplate === null || itemTemplate === undefined"
                    class="flex flex-1 items-center truncate rounded-lg border border-gray-300 px-3 py-2">
                    <p>${tag}</p>
                </div>
                <template if.bind="!disabled">
                    <button
                        click.trigger="handleRemove($index)"
                        h-[38px].class="itemTemplate === null || itemTemplate === undefined"
                        class="items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500 hover:bg-gray-100 active:bg-gray-200">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </template>
            </div>
        </li>
        <li if.bind="showNoResult" class="py-1" if.bind="values.length === 0">
            <p class="text-left text-sm" t="translation:global.messages.no-selection-made"></p>
        </li>
    </ul>
</div>
