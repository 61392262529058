import { I18N } from '@aurelia/i18n';
import { GetMedicalRecordRegistrationResponse, GetMedicalRecordResponse, MedicalTherapyExecutionItemTypes } from '@wecore/sdk-healthcare';
import { bindable, containerless, inject } from 'aurelia';
import { FlattenedExaminationStep } from '../../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../../models/selected-file';
import { StepState } from '../../../../../../../models/step-state';

@containerless
@inject(I18N)
export class TemplateTreatmentItem {
    @bindable() public recordFlattened: FlattenedExaminationStep[];
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public xScrollContainer: string;
    @bindable() public validation: any;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public state: StepState;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public item: GetMedicalRecordRegistrationResponse;
    @bindable() public selection: GetMedicalRecordRegistrationResponse[];
    @bindable() public onDelete: (item: GetMedicalRecordRegistrationResponse) => void;
    @bindable() public onDuplicate: (item: GetMedicalRecordRegistrationResponse) => void;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public expanded: boolean = false;

    public MedicalTherapyExecutionItemTypes: typeof MedicalTherapyExecutionItemTypes = MedicalTherapyExecutionItemTypes;
    public validationIndex: any;

    public constructor(
        private t: I18N //
    ) {}

    public bound(): void {
        this.validationIndex = this.validation.executions.findIndex((x: any) => x.id === this.item.id);
    }

    public collapseOrExpand(): void {
        this.expanded = !this.expanded;
    }
}
