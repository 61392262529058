<import from="converters/sort-medical-history" />
<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/date" />
<import from="converters/month-to-string" />
<import from="converters/side-to-string" />
<import from="converters/medical-history-type-to-string" />
<import from="converters/priority-background-color" />
<import from="converters/priority-text-color" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <template if.bind="baseLoaded">
            <div class="flex h-full flex-1 gap-8 pl-5">
                <div class="w-48 border-r border-gray-200 pr-5 pt-4">
                    <nav class="space-y-1">
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:global.buttons.dashboard"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:global.buttons.activity"></span>
                        </button>
                        <a
                            if.bind="hasRole(UserRoles.ReadMedicalRecords)"
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'ClinicalPathways' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('ClinicalPathways')"
                            class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.clinical-pathways"></span>
                        </a>
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.appointments"></span>
                        </button>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientInvoices' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientInvoices')"
                            class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                            aria-current="page">
                            <span class="truncate" t="translation:global.buttons.invoices"></span>
                        </a>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientContactMoments' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientContactMoments')"
                            class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.contact-moments"></span>
                        </a>
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.relations"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.attachments"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.notes"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.tasks"></span>
                        </button>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientInsurances' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientInsurances')"
                            class="flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.insurances"></span>
                        </a>
                    </nav>
                </div>
                <div class="flex h-full w-full flex-1 flex-col overflow-y-auto pr-8 pt-6">
                    <div class="sticky top-0 z-10 flex w-full justify-end bg-white pt-1">
                        <ux-button-group full-width.bind="false">
                            <ux-button click.trigger="toggleWidth()" padding.bind="false" color="dark" size="sm" mode="outline">
                                <div if.bind="expanded" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                </div>
                                <div else class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </div>
                            </ux-button>
                            <ux-dropdown type="full" color="dark" size="sm" padding.bind="false" mode="outline" if.bind="patient.emails.length > 0" placement="bottom-right" width="w-72">
                                <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </div>
                                <ux-dropdown-option repeat.for="email of patient.emails" color="dark" click.trigger="sendEmailTo(email)">
                                    <div class="flex items-center space-x-1">
                                        <span>${email.translations | translate} &bull; ${email.value}</span>
                                        <svg if.bind="email.isPrimary" class="h-4 w-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <ux-dropdown type="full" color="dark" size="sm" padding.bind="false" mode="outline" if.bind="patient.phones.length > 0" placement="bottom-right" width="w-64">
                                <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                    </svg>
                                </div>
                                <ux-dropdown-option repeat.for="phone of patient.phones" color="dark" href="tel:${phone.callingCode}${phone.value}">
                                    <div class="flex items-center space-x-1">
                                        <span>${phone.translations | translate} &bull; +${phone.callingCode}${phone.value}</span>
                                        <svg if.bind="phone.isPrimary" class="h-4 w-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <ux-dropdown
                                if.bind="hasRole(UserRoles.WritePatients) || hasRole(UserRoles.DeletePatients)"
                                type="full"
                                color="dark"
                                padding.bind="false"
                                size="sm"
                                mode="outline"
                                placement="bottom-right">
                                <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2">
                                    <svg class="h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                        <path
                                            fill="currentColor"
                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                    </svg>
                                </div>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.WritePatients)" color="dark" click.trigger="covCheck()">
                                    <span t="translation:partial-views.patients.buttons.cov"></span>
                                </ux-dropdown-option>
                                <!-- <ux-dropdown-option color="dark">BSN</ux-dropdown-option>
                                <ux-dropdown-option color="dark">DMV</ux-dropdown-option> -->
                                <ux-dropdown-option if.bind="hasRole(UserRoles.WritePatients)" color="dark" click.trigger="edit()">
                                    <span t="translation:global.buttons.edit"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-divider if.bind="hasRole(UserRoles.WritePatients) && hasRole(UserRoles.DeletePatients)"></ux-dropdown-divider>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.DeletePatients)" color="dark" click.trigger="delete()">
                                    <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <ux-button if.bind="closeable" padding.bind="false" click.trigger="close()" color="dark" size="sm" mode="outline">
                                <div class="pointer-events-none flex h-[38px] items-center px-2 text-red-500">
                                    <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>
                                </div>
                            </ux-button>
                        </ux-button-group>
                    </div>
                    <div class="${ expanded ? 'grid-cols-2' : 'grid-cols-1'} mt-5 grid gap-3.5">
                        <div class="col-span-1 flex flex-col">
                            <div class="col-span-1 flex flex-col items-center justify-center">
                                <div class="rounded-full border-8 border-gray-100">
                                    <ux-avatar size="xl" name.bind="patient.displayName" color.bind="patient.avatar.color" shade.bind="patient.avatar.shade"></ux-avatar>
                                </div>
                                <template if.bind="patient.labels.length > 0">
                                    <div class="mt-2 flex w-full justify-center gap-2">
                                        <template repeat.for="item of patient.labels">
                                            <ux-badge background.bind="item.priority | priorityBackgroundColor:true" text.bind="item.priority | priorityTextColor">
                                                <div class="flex flex-nowrap items-center gap-x-1 p-1 font-bold">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                    </svg>
                                                    <span class="flex-nowrap uppercase">${item.label.translations | translate}</span>
                                                </div>
                                            </ux-badge>
                                        </template>
                                    </div>
                                </template>
                                <h4 class="mt-2 text-xl font-bold">
                                    ${patient.firstName || ''} ${patient.lastNameAffix || ''} ${patient.lastName} <span if.bind="patient.initials">(${patient.initials})</span>
                                </h4>
                                <p class="text-gray-500">${patient.number || ''}</p>
                            </div>
                            <div class="mt-5 flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                <div class="flex items-center justify-between">
                                    <p class="font-medium text-gray-600" t="translation:partial-views.patients.labels.head-practitioner"></p>
                                    <p if.bind="headPractitioner" class="flex items-center space-x-1.5 font-medium">
                                        <ux-avatar
                                            size="xxs"
                                            name.bind="headPractitioner.displayName"
                                            color.bind="headPractitioner.avatar.color"
                                            shade.bind="headPractitioner.avatar.shade"></ux-avatar>
                                        <span> ${patient.headPractitioner.name}</span>
                                    </p>
                                    <p else class="font-medium">N/A</p>
                                </div>
                                <div class="flex items-center justify-between">
                                    <p class="font-medium text-gray-600" t="translation:partial-views.patients.labels.citizens-service-number"></p>
                                    <p class="font-medium">${patient.citizensServiceNumber || 'N/A'}</p>
                                </div>
                                <div class="flex items-center justify-between">
                                    <p class="font-medium text-gray-600" t="translation:global.labels.dob"></p>
                                    <p class="font-medium">${patient.dateOfBirth | date} (${getAge(patient.dateOfBirth)})</p>
                                </div>
                                <div repeat.for="address of patient.addresses" class="flex items-start justify-between">
                                    <p class="font-medium text-gray-600" t="translation:global.labels.address"></p>
                                    <p class="text-right font-medium">
                                        ${address.street} ${address.houseNumber} <br />
                                        ${address.zipCode}, ${address.city}
                                    </p>
                                </div>
                                <!-- <div class="flex items-center justify-between py-1">
                                    <p class="font-medium text-gray-600">Huisarts</p>
                                    <button click.trigger="showContact()" class="flex items-center px-3 py-1 bg-gray-100 rounded-full gap-x-2 hover:bg-gray-200">
                                        <span>Dr. W.O. van de Peppel</span>
                                    </button>
                                </div> -->
                                <div repeat.for="phone of patient.phones" class="flex items-center justify-between">
                                    <p class="font-medium text-gray-600">${phone.translations | translate:language }</p>
                                    <ux-anchor size="sm" link="tel:${phone.callingCode}${phone.value}">+${phone.callingCode}${phone.value}</ux-anchor>
                                </div>
                                <div repeat.for="email of patient.emails" class="flex items-center justify-between">
                                    <p class="font-medium text-gray-600">${email.translations | translate:language }</p>
                                    <ux-anchor click.trigger="sendEmailTo(email)" size="sm">${email.value}</ux-anchor>
                                </div>
                            </div>
                            <div class="mt-3 flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                <div class="flex items-start justify-between">
                                    <p class="font-medium text-gray-600" t="translation:global.labels.medications"></p>
                                    <ul>
                                        <li repeat.for="item of patient.medications" class="text-right font-medium">${item.medication.translations | translate:language}</li>
                                        <li class="text-gray-500" if.bind="patient.medications.length === 0">N/A</li>
                                    </ul>
                                </div>
                                <div class="mt-2">
                                    <p class="font-medium text-gray-600" t="translation:partial-views.patients.labels.medical-history"></p>
                                    <div repeat.for="type of MedicalHistoryItemTypes | keys" class="grid grid-cols-12 items-start justify-between gap-3">
                                        <p class="col-span-5 text-gray-500">${ type | medicalHistoryTypeToString | t}</p>
                                        <ul class="col-span-7">
                                            <li if.bind="getHistoryLength(patient.medicalHistory, type) === 0">
                                                <p class="text-right text-gray-500" t="translation:partial-views.patients.messages.no-medical-history"></p>
                                            </li>
                                            <li
                                                repeat.for="item of patient.medicalHistory | sortMedicalHistory:MedicalHistoryItemTypes[type]:language"
                                                class="flex min-w-0 justify-end text-right font-medium">
                                                <span class="block truncate text-right text-gray-600">${item.name | translate:language}</span>
                                                <span class="ml-1 text-right" if.bind="item.year || item.month || item.side">
                                                    (<span if.bind="item.side">${item.side | sideToString | t}</span><span if.bind="item.month || item.year">/</span
                                                    ><span if.bind="item.month">${item.month | monthToString | t}</span><span if.bind="item.month && item.year">/</span
                                                    ><span if.bind="item.year">${item.year}</span>)
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex items-start justify-between">
                                    <p class="font-medium text-gray-600" t="translation:global.labels.insurances"></p>
                                    <ul class="flex flex-col items-end">
                                        <li repeat.for="insurance of patient.insurances" class="text-right font-medium text-gray-600">
                                            <div class="flex items-center gap-1.5">
                                                <div if.bind="insurance.status === InsuranceStatuses.Active" class="h-2 w-2 rounded-full bg-green-500"></div>
                                                <div else class="h-2 w-2 rounded-full bg-red-500"></div>
                                                <div class="flex min-w-0 items-center gap-1 text-sm">
                                                    <span class="truncate">${insurance.name}</span>
                                                    <span class="flex flex-nowrap">
                                                        <span>(</span>
                                                        <span
                                                            class="whitespace-nowrap"
                                                            if.bind="insurance.type === InsuranceTypes.Supplementary"
                                                            t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary"></span>
                                                        <span
                                                            class="whitespace-nowrap"
                                                            if.bind="insurance.type === InsuranceTypes.SupplementaryPlusDental"
                                                            t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary-dental">
                                                        </span>
                                                        <span
                                                            class="whitespace-nowrap"
                                                            if.bind="insurance.type === InsuranceTypes.Awbz"
                                                            t="translation:partial-views.patient-cov-check.labels.insurance-type-awbz"></span>
                                                        <span
                                                            class="whitespace-nowrap"
                                                            if.bind="insurance.type === InsuranceTypes.Basic"
                                                            t="translation:partial-views.patient-cov-check.labels.insurance-type-basic"></span>
                                                        <span
                                                            class="whitespace-nowrap"
                                                            if.bind="insurance.type === InsuranceTypes.MainInsurance"
                                                            t="translation:partial-views.patient-cov-check.labels.insurance-type-main"></span>
                                                        <span
                                                            class="whitespace-nowrap"
                                                            if.bind="insurance.type === InsuranceTypes.Dental"
                                                            t="translation:partial-views.patient-cov-check.labels.insurance-type-dental"></span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="text-gray-500" if.bind="patient.insurances.length === 0">N/A</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1 flex flex-col space-y-3">
                            <div if.bind="expanded" class="col-span-1 flex flex-col space-y-3">
                                <div class="flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Gewicht</p>
                                        <p class="font-medium">${patient.weight || 0}kg</p>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Lengte</p>
                                        <p class="font-medium">${patient.length || 0}cm</p>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Lengte</p>
                                        <p class="font-medium"><span t="translation:global.labels.size"></span> ${patient.shoeSize}</p>
                                    </div>
                                    <div class="flex items-start justify-between">
                                        <p class="font-medium text-gray-600" t="translation:global.labels.professions"></p>
                                        <ul>
                                            <li repeat.for="item of patient.professions" class="text-right font-medium">${item.profession.translations | translate:language}</li>
                                            <li class="text-gray-500" if.bind="patient.professions.length === 0">N/A</li>
                                        </ul>
                                    </div>
                                    <div class="flex items-start justify-between">
                                        <p class="font-medium text-gray-600" t="translation:global.labels.sports"></p>
                                        <ul>
                                            <li repeat.for="item of patient.sports" class="text-right font-medium">${item.sport.translations | translate:language}</li>
                                            <li class="text-gray-500" if.bind="patient.sports.length === 0">N/A</li>
                                        </ul>
                                    </div>
                                    <div class="flex items-start justify-between">
                                        <p class="font-medium text-gray-600" t="translation:global.labels.medical-equipment"></p>
                                        <ul>
                                            <li repeat.for="item of patient.medicalEquipment" class="text-right font-medium">${item.equipment.translations | translate:language}</li>
                                            <li class="text-gray-500" if.bind="patient.medicalEquipment.length === 0">N/A</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.addresses"></p>
                                    <div class="flex items-start justify-between" repeat.for="address of patient.addresses">
                                        <p class="flex items-center gap-x-1 text-gray-600">
                                            <span>${address.translations | translate:language}</span>
                                            <svg if.bind="address.isPrimary" class="h-4 w-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <p class="text-right font-medium">
                                            ${address.street} ${address.houseNumber}${address.houseNumberSuffix} <br />
                                            ${address.zipCode} ${address.city}<br />
                                            ${ 'translation:global.countries.' + address.country | t}
                                        </p>
                                    </div>
                                    <p if.bind="patient.addresses.length === 0" class="text-gray-500" t="translation:global.messages.no-addresses-added"></p>
                                </div>
                                <div class="flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.phone-numbers"></p>
                                    <div repeat.for="phone of patient.phones" class="flex items-center justify-between">
                                        <p class="flex items-center gap-x-1 text-gray-600">
                                            <span>${phone.translations | translate:language}</span>
                                            <svg if.bind="phone.isPrimary" class="h-4 w-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <ux-anchor size="sm" link="tel:+${phone.callingCode}${phone.value}">+${phone.callingCode}${phone.value}</ux-anchor>
                                    </div>
                                    <p if.bind="patient.phones.length === 0" class="text-gray-500" t="translation:global.messages.no-phones-added"></p>
                                </div>
                                <div class="flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.emails"></p>
                                    <div repeat.for="email of patient.emails" class="flex items-center justify-between">
                                        <p class="flex items-center gap-x-1 text-gray-600">
                                            <span>${email.translations | translate:language}</span>
                                            <svg if.bind="email.isPrimary" class="h-4 w-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <ux-anchor size="sm" click.trigger="sendEmailTo(email)">${email.value}</ux-anchor>
                                    </div>
                                    <p if.bind="patient.emails.length === 0" class="text-gray-500" t="translation:global.messages.no-emails-added"></p>
                                </div>
                                <div class="flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <p class="font-bold text-gray-800" t="translation:global.labels.web-links"></p>
                                    <div repeat.for="link of patient.links" class="flex items-center justify-between">
                                        <p class="flex items-center gap-x-1 text-gray-600">
                                            <span>${link.translations | translate:language}</span>
                                            <svg if.bind="link.isPrimary" class="h-4 w-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </p>
                                        <ux-anchor target="_blank" size="sm" link="${link.protocol}${link.value}">${link.protocol}${link.value}</ux-anchor>
                                    </div>
                                    <p if.bind="patient.links.length === 0" class="text-gray-500" t="translation:global.messages.no-links-added"></p>
                                </div>
                                <div class="mt-3 flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <p class="font-bold text-gray-800">Hulpverleners</p>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Wondzorg</p>
                                        <button click.trigger="showContact()" class="flex items-center gap-x-2 rounded-full bg-gray-100 px-3 py-1 hover:bg-gray-200">
                                            <span>S. Nefkens</span>
                                        </button>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Fysiotherapie</p>
                                        <button click.trigger="showContact()" class="flex items-center gap-x-2 rounded-full bg-gray-100 px-3 py-1 hover:bg-gray-200">
                                            <span>G.H Vereijken - Korteland</span>
                                        </button>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Psycholoog</p>
                                        <button click.trigger="showContact()" class="flex items-center gap-x-2 rounded-full bg-gray-100 px-3 py-1 hover:bg-gray-200">
                                            <span>J. van Beekum</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="mt-3 flex w-full flex-col space-y-1 rounded-lg bg-gray-50 p-5 text-sm">
                                    <p class="font-bold text-gray-800">Familie</p>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Vader</p>
                                        <button click.trigger="showContact()" class="flex items-center gap-x-2 rounded-full bg-gray-100 px-3 py-1 hover:bg-gray-200">
                                            <span>E.F. Luitjes</span>
                                        </button>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium text-gray-600">Moeder</p>
                                        <button click.trigger="showContact()" class="flex items-center gap-x-2 rounded-full bg-gray-100 px-3 py-1 hover:bg-gray-200">
                                            <span>H.L. Luitjes - Ravenhorst</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div else class="mt-3 flex flex-1 px-10 py-8">
            <ux-skeleton show-background.bind="false" type="container">
                <div class="flex w-full flex-col space-y-5 py-1">
                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                </div>
            </ux-skeleton>
        </div>
    </section>
</template>
