import { bindable } from 'aurelia';

export class UxButton {
    @bindable() public color: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark' = 'primary';
    @bindable() public mode: 'solid' | 'outline' = 'solid';
    @bindable() public type: 'default' | 'full' | 'sm:full' = 'default';
    @bindable() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
    @bindable() public disabled: boolean = false;
    @bindable() public loading: boolean = false;
    @bindable() public transparent: boolean = false;
    @bindable() public hasOptions: boolean = false;
    @bindable() public padding: boolean = true;
    @bindable() public border: boolean = true;
    @bindable() public rounded: boolean = false;
    @bindable() public textWhenLoading: string;

    public content: HTMLSpanElement;
    public minContentWidth: number;

    public bound(): void {
        // Save the original width of the content.
        setTimeout(() => (this.minContentWidth = this.content?.offsetWidth));
    }

    public async handleClick(e: MouseEvent): Promise<void> {
        if (this.disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
    }
}
