import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalHistoryItem,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
export class WidgetPatientMedicalHistory {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        // Make sure every phone number has a default calling code.
        this.registration.widget.result.value = this.patient.medicalHistory || [];

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Array;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientWebLinks,
                onSave: async (): Promise<void> => {
                    // Save the history to the patient and
                    // let all listeners know the patient has been updated.
                    const values = this.registration.widget.result.value as MedicalHistoryItem[];
                    values.forEach((x) => (x.year = isNaN(x.year) ? undefined : x.year));

                    this.registration.widget.result.value = values;
                    this.patient.medicalHistory = values;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
