<import from="converters/get-ids" />
<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />

<div class="flex flex-col">
    <ux-label>
        <span t="translation:components.widgets.ketenzorg.labels.select-diagnoses"></span>
    </ux-label>
    <p class="mb-2 text-xs text-gray-600" t="translation:components.widgets.ketenzorg.messages.explain-widget"></p>
    <bx-diagnosis-multi-selector
        workspace.bind="workspace"
        on-change.bind="handleDiagnosesChanged"
        language.bind="language"
        categories.bind="registration.widget.settings.ids"
        t="[placeholder]partial-views.medical-examination-actions.placeholders.select-differential-diagnoses"
        values.bind="diagnoses | getIds">
    </bx-diagnosis-multi-selector>
</div>
