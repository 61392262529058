import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { UserRoles } from '@wecore/sdk-core';
import { isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialPracticeSettings extends BasePartialView {
    public active: PartialView;
    public settings: {
        title: string;
        view: PartialView;
        icon: string;
        roles: UserRoles[];
    }[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
        this.settings = [
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.practice'),
                view: PartialViews.ManagePractice,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.users'),
                view: PartialViews.ManageUsers,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.teams'),
                view: PartialViews.ManageTeams,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.practice-locations'),
                view: PartialViews.ManagePracticeLocations,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WritePracticeLocations]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.insurers'),
                view: PartialViews.ManageInsurers,
                icon: `<svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.75 7.75C4.75 6.09315 6.09315 4.75 7.75 4.75H16.25C17.9069 4.75 19.25 6.09315 19.25 7.75V16.25C19.25 17.9069 17.9069 19.25 16.25 19.25H7.75C6.09315 19.25 4.75 17.9069 4.75 16.25V7.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M13.25 7.75H10.75V10.75H7.75V13.25H10.75V16.25H13.25V13.25H16.25V10.75H13.25V7.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteInsurers]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.schedules'),
                view: PartialViews.ManageSchedules,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteSchedules]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.appointment-types'),
                view: PartialViews.ManageAppointmentTypes,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteAppointmentTypes]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.prices'),
                view: PartialViews.ManagePracticePrices,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.vat-categories'),
                view: PartialViews.ManageVatCategories,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteVatCategories]
            },
            {
                title: this.t.tr('translation:partial-views.practice-settings.labels.email-templates'),
                view: PartialViews.ManageEmailTemplates,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                        </svg>`,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteEmailTemplates]
            }
        ];
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                const setAllowedView = async () => {
                    // Find the first view we have access to.
                    let partial: PartialView;
                    outerLoop: for (const setting of this.settings) {
                        for (const role of setting.roles) {
                            if (this.hasRole(role)) {
                                partial = setting.view;
                                break outerLoop;
                            }
                        }
                    }

                    await this.changeToView(partial, true);
                };
                this.loadViewsFromUrl({
                    open: async (view: string) => {
                        if (isNotEmpty(view)) {
                            const partial = PartialViews[view];
                            const setting = this.settings.find((x) => x.view.name === partial.name);
                            if (setting && setting.roles.some((x) => this.hasRole(x))) {
                                await this.changeToView(partial, false);
                            } else setAllowedView();
                        } else {
                            // The default view is the ManagePractice view.
                            // First check if the user has access to this view.
                            // If not, find the first view we have access to.
                            const partial = PartialViews.ManagePractice;
                            const setting = this.settings.find((x) => x.view.name === partial.name);
                            if (setting && setting.roles.some((x) => this.hasRole(x))) {
                                await this.changeToView(partial, false);
                            } else setAllowedView();
                        }
                    }
                });
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPracticeSettings.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async changeToView(view: PartialView, updateUrl: boolean = true): Promise<void> {
        this.active = view;
        await this.changeTo(view, updateUrl);
    }
}
