import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, resetValidation } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
@inject(I18N)
export class WidgetPatientWeight {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public placeholder: string;

    public constructor(
        private readonly t: I18N //
    ) {}

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        if (isDefined(this.patient.weight) && this.patient.weight > 0) this.placeholder = this.patient.weight.toString() + 'kg';
        else this.placeholder = this.t.tr('translation:components.widgets.patient-weight.placeholders.enter-weight');

        this.registration.widget.result.type = WidgetResultTypes.Int;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientWeight,
                onSave: async (): Promise<void> => {
                    if (
                        isNotDefined(this.registration.widget.result) || //
                        isNotDefined(this.registration.widget.result.value) ||
                        isEmpty(this.registration.widget.result.value)
                    )
                        return;

                    const value = Number(this.registration.widget.result.value?.toString().replace(/,/g, '.'));
                    this.registration.widget.result.value = value;
                    this.patient.weight = value;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    resetValidation(this.validation);

                    // Check if the widget is required to answer (information is found on the step container).
                    const required = this.flow.required.find((x) => x.key === this.step.id);

                    // When an result or results are required lets check if we have any.
                    if (isDefined(required) && required.value) {
                        this.validation.result = isDefined(this.registration.widget.result) && isDefined(this.registration.widget.result.value);
                    }

                    if (isDefined(this.registration.widget.result.value) && isNotEmpty(this.registration.widget.result.value)) {
                        this.validation.weight = Number(this.registration.widget.result.value) > 0 && Number(this.registration.widget.result.value) <= 400;
                    }

                    return this.validation.result && this.validation.weight;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
