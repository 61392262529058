export interface IEventDetails<TElement, TValue> {
    element: TElement;
    values: TValue;
    innerEvent: Event;
    data: any;
    plugin?: any;
}

export class EventDetails<TElement, TValue> {
    public element: TElement;
    public values: TValue;
    public innerEvent: Event;
    public data: any;
    public plugin: any;

    constructor(data: IEventDetails<TElement, TValue>) {
        this.element = data?.element;
        this.values = data?.values;
        this.innerEvent = data?.innerEvent;
        this.data = data?.data;
        this.plugin = data?.plugin;
    }
}
