import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetInsurerResponse, HealthcareInsuranceTypes, HealthcarePrice, InsurerEntityReference, PracticeLocationsApiClient, VatCategory } from '@wecore/sdk-healthcare';
import { guid, isDefined, isEmpty, isNotDefined, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { areIntervalsOverlapping, isAfter } from 'date-fns';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { UxDatepicker } from '../../ux/ux-datepicker/ux-datepicker';
import { UxInput } from '../../ux/ux-input/ux-input';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PracticeLocationsApiClient, ModalService)
export class PartialManageHealthcarePrices extends BasePartialView {
    public price: HealthcarePrice;
    public index: number;
    public validation = {
        vat: true,
        type: true,
        netPrice: true,
        grossPrice: true,
        periodStart: true,
        periodEnd: true,
        period: true,
        overlaps: true,
        overlapsInsurer: true
    };
    public HealthcareInsuranceTypes: typeof HealthcareInsuranceTypes = HealthcareInsuranceTypes;
    private allPrices: HealthcarePrice[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.index = view.data.index;
        this.allPrices = view.data.prices;
        this.price =
            view.data.price ??
            new HealthcarePrice({
                id: guid(),
                vatValue: 0
            });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
                await super.handleBaseLoaded();
            })
            .catch((x) => this.errorHandler.handle('PartialManageHealthcarePrices.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            this.remove({ result: PartialViewResults.Ok, data: { price: this.price, index: this.index } });
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.price[property],
            callback: (updatedTranslations: any) => {
                this.price[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleInsurerSelected = async (insurer: GetInsurerResponse): Promise<void> => {
        if (isDefined(insurer))
            this.price.insurer = new InsurerEntityReference({
                id: insurer.id,
                translations: insurer.name
            });
        else this.price.insurer = null;
    };

    public handleCategorySelected = async (category: VatCategory): Promise<void> => {
        this.price.vat = category;

        if (isNotDefined(this.price.grossValue)) return;

        const vat = category.percentage;
        this.price.netValue = Number(((Number(this.price.grossValue) / 100) * (100 + Number(vat))).toFixed(2));
        this.price.vatValue = Number((Number(this.price.netValue) - Number(this.price.grossValue)).toFixed(2));
    };

    public async handleTypeSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value as HealthcareInsuranceTypes;
        this.price.insuranceType = value;
    }

    public handlePeriodCleared(e: CustomEvent<EventDetails<UxDatepicker, any>>, which: 'start' | 'end') {
        if (which === 'start') this.price.periodStart = null;
        else this.price.periodEnd = null;
    }

    public async handleGrossChanged(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const value = event.detail.values?.value;
        if (isNotDefined(value) || isEmpty(value)) return;

        this.price.netValue = (Number(value) / 100) * (100 + this.price.vat.percentage);
        this.price.vatValue = Number((Number(this.price.netValue) - Number(this.price.grossValue)).toFixed(2));
    }

    public async handleNetChanged(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const value = event.detail.values?.value;
        if (isNotDefined(value) || isEmpty(value)) return;

        const vat = this.price.vat?.percentage ?? 0;

        this.price.grossValue = Number(((Number(value) / (100 + Number(vat))) * 100).toFixed(2));
        this.price.vatValue = Number((Number(this.price.netValue) - Number(this.price.grossValue)).toFixed(2));
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.vat = isDefined(this.price.vat);

        if (this.validation.vat) {
            this.validation.type = isDefined(this.price.insuranceType);
            this.validation.netPrice = isDefined(this.price.netValue) && !Number.isNaN(Number(this.price.netValue));
            this.validation.grossPrice = isDefined(this.price.grossValue) && !Number.isNaN(Number(this.price.grossValue));

            if (isDefined(this.price.periodStart) && isNotDefined(this.price.periodEnd)) this.validation.periodEnd = false;
            if (isDefined(this.price.periodEnd) && isNotDefined(this.price.periodStart)) this.validation.periodStart = false;

            if (isDefined(this.price.periodStart) && isDefined(this.price.periodEnd)) {
                // Check if the start date is before the end date.
                this.validation.period = isAfter(this.price.periodEnd, this.price.periodStart);

                // Make sure the period does not overlap with any other price periods.
                for (let index = 0; index < this.allPrices.length; index++) {
                    const price = this.allPrices[index];
                    // Ignore current item.
                    if (price.id == this.price.id) continue;
                    // Check if the period overlaps with any other period with a period for the same insurer.
                    if (isDefined(this.price.insurer) && isDefined(price.insurer)) {
                        this.validation.overlapsInsurer =
                            !areIntervalsOverlapping(
                                { start: this.price.periodStart, end: this.price.periodEnd }, //
                                { start: price.periodStart, end: price.periodEnd }
                            ) && this.price.insurer.id === price.insurer.id;
                    } else if (isNotDefined(price.insurer) && isNotDefined(this.price.insurer))
                        // Only check if period overlaps when both prices don't have an insurer
                        this.validation.overlaps = !areIntervalsOverlapping(
                            { start: this.price.periodStart, end: this.price.periodEnd }, //
                            { start: price.periodStart, end: price.periodEnd }
                        );
                }
            }
        }

        return validateState(this.validation);
    }
}
