import { isDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, inject } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(IEventAggregator, Element)
export class UxPagination {
    @bindable() public currentPage: number;
    @bindable() public maxPage: number;
    @bindable() public isLoading: boolean = false;
    @bindable() public fullWidth: boolean = true;
    @bindable() public data: any;

    private hasFocus: boolean = false;
    private input: HTMLInputElement;

    public constructor(
        public events: IEventAggregator, //
        private readonly host: HTMLElement
    ) {}

    public async setPage(page: number) {
        this.currentPage = page;
    }

    public async setMaxPage(page: number) {
        this.maxPage = page;
    }

    public async handleNextPage(e: Event): Promise<void> {
        this.emit(
            UxEvents.OnNext,
            new EventDetails<UxPagination, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: { page: this.currentPage }
            })
        );
    }

    public async handlePreviousPage(e: Event): Promise<void> {
        this.emit(
            UxEvents.OnPrevious,
            new EventDetails<UxPagination, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: { page: this.currentPage }
            })
        );
    }

    public async handleFocus(): Promise<void> {
        this.hasFocus = true;
    }

    public async handleBlur(): Promise<void> {
        this.hasFocus = false;
    }

    public async handleChange(e: FocusEvent): Promise<void> {
        this.hasFocus = false;
        let value = isNotEmpty(this.input.value) ? Number(this.input.value) : null;

        // Make sure it doesn't allow to surpass the maxpage if defined.
        if (isDefined(this.maxPage) && value > this.maxPage) value = this.maxPage;
        this.currentPage = value;

        this.emit(
            UxEvents.OnChange,
            new EventDetails<UxPagination, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: { page: value }
            })
        );
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }
}
