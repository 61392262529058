import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ClinicalPathwayResult, ClinicalPathwaysApiClient, CreateClinicalPathwayRequest, GetPatientResponse, PatientEntityReference, PatientsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ClinicalPathwaysApiClient, PatientsApiClient)
export class PartialClinicalPathways extends BasePartialView {
    public pathways: ClinicalPathwayResult[] = [];
    public closeable: boolean = false;
    public creating: boolean = false;
    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;
    private patientId: string;
    private patient: GetPatientResponse;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly pathwaysApi: ClinicalPathwaysApiClient,
        private readonly patientsApi: PatientsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.closeable = view.data.closeable || false;
        this.patientId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [patient] = await Promise.all([
                    this.patientsApi.getById(this.patientId, this.authenticated.workspace.id), //
                    this.getData(false, true)
                ]);
                this.patient = patient;
                // Patient must exist before loading URL.
                this.loadViewsFromUrl({
                    open: async (view: string, entityId: string) => {
                        if (isNotDefined(view)) return;
                        if (view.includes('Details') && isNotEmpty(entityId)) {
                            const item = this.pathways.find((x) => x.id === entityId);
                            if (isDefined(item)) this.details(item, false);
                        } else if (view.includes('Create')) this.create();
                    }
                });

                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialClinicalPathways.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public details = async (pathway: ClinicalPathwayResult, updateUrl: boolean = true): Promise<void> => {
        await this.removeChildViews();

        let view: PartialView;

        switch (pathway.type) {
            case 'ProSoftwareReport':
                view = PartialViews.ProSoftwareReport.with({
                    id: pathway.id,
                    refresh: () => this.getData(true, false, false)
                });
                break;
            case 'ProSoftwareMedicalRecord':
                view = PartialViews.ProSoftwareMedicalRecord.with({
                    id: pathway.id,
                    refresh: () => this.getData(true, false, false)
                });
                break;
            case 'ClinicalPathway':
                view = PartialViews.ClinicalPathwaysDetails.with({
                    id: pathway.id,
                    refresh: () => this.getData(true, false, false)
                });
                break;
        }

        await this.addPartialView({
            view: this.partial.base,
            partial: view.whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                    await this.getData(true);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl
            })
        });
    };

    public async create(): Promise<void> {
        this.creating = true;
        await this.removeChildViews();

        try {
            const pathway = await this.pathwaysApi.create(
                this.authenticated.workspace.id,
                new CreateClinicalPathwayRequest({
                    patient: new PatientEntityReference({
                        id: this.patientId,
                        name: this.patient.displayName
                    })
                })
            );

            this.creating = false;
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.ClinicalPathwaysDetails.with({
                    id: pathway.id,
                    refresh: () => this.getData(true, false, false)
                }).whenClosed(async (result: PartialViewResults, _: any) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                        this.getData(true);
                    }
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    replace: true,
                    updateUrl: true
                })
            });

            this.getData(true);
        } catch (e) {
            this.creating = false;
            this.errorHandler.handle('PartialClinicalPathways.create', e);
        }
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true);

        element.isLoading = false;
    }

    public async close(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    private async getData(reset: boolean = false, initial: boolean = false, showLoading: boolean = true): Promise<void> {
        if (reset) {
            this.skip = 0;
            this.pathways = [];
            this.endOfList = false;
            if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
        }

        if (showLoading) this.isLoading = true;
        const response = await this.pathwaysApi.getForPatient(this.patientId, this.authenticated.workspace.id, this.pageSize, this.skip);

        if (!reset && response.data.empty() && !initial) {
            this.endOfList = true;
            this.isLoading = false;
            return;
        }

        this.skip += Number(this.pageSize);
        this.pathways = [...this.pathways, ...response.data];
        if (showLoading) this.isLoading = false;
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
