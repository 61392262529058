import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { Address, GetPatientResponse, PatientsApiClient } from '@wecore/sdk-healthcare';

import { CovResultTypes, GetPatientInsuranceInformationResponse, InsuranceStatuses, InsuranceTypes, VecozoApiClient } from '@wecore/sdk-integrations';
import { isDefined, isNotDefined, isNotEmpty, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { State } from '../../infra/store/state';
import { checkForCustomDescriptions } from '../../infra/utilities';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, VecozoApiClient)
export class PartialPatientCovCheck extends BasePartialView {
    public patient: GetPatientResponse;
    public descriptions: { [key: string]: string }[];
    public dateOfBirth: Date;
    public citizensServiceNumber: string;
    public addresses: Address[] = [];
    public missingInformation: boolean = false;
    public validation: any = {
        dob: true,
        bsn: true,
        address: true
    };
    public lookup: GetPatientInsuranceInformationResponse;
    public CovResultTypes: typeof CovResultTypes = CovResultTypes;
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;

    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly vecozo: VecozoApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.patient = await this.patientsApi.getById(this.patientId, this.authenticated.workspace.id);

                this.dateOfBirth = this.patient.dateOfBirth;
                this.citizensServiceNumber = this.patient.citizensServiceNumber;
                this.addresses = this.patient.addresses;

                const descriptions = [
                    {
                        nl: this.t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                        en: this.t.tr('translation:global.labels.home-address', { lng: 'en' })
                    },
                    {
                        nl: this.t.tr('translation:global.labels.work', { lng: 'nl' }),
                        en: this.t.tr('translation:global.labels.work', { lng: 'en' })
                    },
                    {
                        nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }),
                        en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
                    }
                ];

                this.descriptions = checkForCustomDescriptions(this.t, this.language, descriptions, { addresses: this.addresses } as any, 'address');

                this.missingInformation =
                    isNotDefined(this.dateOfBirth) ||
                    ((isNotDefined(this.addresses) || //
                        this.addresses.length === 0) &&
                        (isNotDefined(this.addresses[0]?.zipCode) || this.addresses[0]?.houseNumber) &&
                        isNotDefined(this.citizensServiceNumber));

                // If all information is entered, check for cov.
                if (!this.missingInformation) await this.covCheck();

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPatientCovCheck.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async execute(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            try {
                this.patient.addresses = this.addresses;
                this.patient.dateOfBirth = this.dateOfBirth;
                this.patient.citizensServiceNumber = this.citizensServiceNumber;

                this.baseLoaded = false;
                this.missingInformation = false;

                await this.patientsApi.update(this.patient.id, this.authenticated.workspace.id, this.patient);
                setTimeout(async () => {
                    this.events.publish(CustomEvents.PatientUpdated, this.patient);
                    await this.covCheck();
                    this.baseLoaded = true;
                }, 250);
            } catch (e) {
                this.errorHandler.handle('update-patient-cov', e);
            }
        }
    }

    public async save(): Promise<void> {
        try {
            this.isLoading = true;
            this.patient.insurances = this.lookup.insurances;
            this.patient.insurances.forEach((x) => (x.addedAt = new Date()));

            await this.patientsApi.update(this.patient.id, this.authenticated.workspace.id, this.patient);
            setTimeout(async () => {
                this.events.publish(CustomEvents.PatientUpdated, this.patient);
                this.remove({ result: PartialViewResults.Ok, updateUrl: true });
            }, 250);
        } catch (e) {
            this.errorHandler.handle('save-cov-insurances', e);
        }
    }

    public async close(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    private async covCheck(): Promise<void> {
        try {
            this.baseLoaded = false;
            this.lookup = await this.vecozo.covCheck(this.patient.id, this.authenticated.workspace.id);
        } catch (e) {
            this.errorHandler.handle('cov-check', e);
        }
    }

    private validate(): boolean {
        this.validation.dob = isDefined(this.dateOfBirth);
        // Or BSN or address needs to be filled in.
        this.validation.bsn = (isDefined(this.citizensServiceNumber) && isNotEmpty(this.citizensServiceNumber)) || (isDefined(this.addresses) && this.addresses.length > 0);
        this.validation.address = (isDefined(this.addresses) && this.addresses.length > 0) || (isDefined(this.citizensServiceNumber) && isNotEmpty(this.citizensServiceNumber));

        return validateState(this.validation);
    }
}
