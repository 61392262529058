import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { HealthcareCode } from '@wecore/sdk-core';
import {
    CreateDeclarationPerformancesRequest,
    CreateDeclarationPerformancesRequestItem,
    DeclarationPerformancesApiClient,
    DeclarationSendMethods,
    GetHealthcareInvoiceResponse,
    GetPatientResponse,
    HealthcareInvoiceCharge,
    HealthcareInvoiceLine,
    HealthcareInvoiceLineTypes,
    HealthcareInvoiceTypes,
    HealthcareInvoicesApiClient,
    Insurance,
    InsuranceStatuses,
    InsuranceTypes,
    PatientsApiClient
} from '@wecore/sdk-healthcare';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { startOfDay } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxDatepicker } from '../../../ux/ux-datepicker/ux-datepicker';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareInvoicesApiClient, PatientsApiClient, DeclarationPerformancesApiClient, ModalService)
export class PartialDeclarationPerformancesCreate extends BasePartialView {
    public request: CreateDeclarationPerformancesRequest;
    public DeclarationSendMethods: typeof DeclarationSendMethods = DeclarationSendMethods;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;
    public HealthcareInvoiceTypes: typeof HealthcareInvoiceTypes = HealthcareInvoiceTypes;
    public HealthcareInvoiceLineTypes: typeof HealthcareInvoiceLineTypes = HealthcareInvoiceLineTypes;
    public validation: any = {
        items: []
    };
    public patient: GetPatientResponse;
    public invoice: GetHealthcareInvoiceResponse;
    public groups: any = {};

    private invoiceId: string;
    private patientId: string;
    private selection: HealthcareInvoiceLine[] | HealthcareInvoiceCharge[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly invoicesApi: HealthcareInvoicesApiClient,
        private readonly patientsApi: PatientsApiClient,
        private readonly performancesApi: DeclarationPerformancesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.PatientUpdated, async () => {
                this.patient = await this.patientsApi.getById(this.patientId, this.authenticated.workspace.id);
            })
        ];

        this.selection = view.data.selection;
        this.invoiceId = view.data.invoice;
        this.patientId = view.data.patient;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [invoice, patient] = await Promise.all([
                    this.invoicesApi.getById(this.invoiceId, this.authenticated.workspace.id),
                    this.patientsApi.getById(this.patientId, this.authenticated.workspace.id)
                ]);
                this.invoice = invoice;
                this.patient = patient;

                this.request = new CreateDeclarationPerformancesRequest({
                    invoiceId: this.invoice.id,
                    items: []
                });

                for (const line of this.invoice.lines) {
                    const lineCode = line.codes.find((x) => x.system === 'Vektis');
                    // Check if the line has charges with the same Vektis code.
                    // If so the totals of the charges should be added to the line.
                    // Which is done in the view itself.
                    const charges = line.additionalCharges.filter((c) => {
                        const code = c.codes.find((x) => x.system === 'Vektis');
                        return `${code.list}${code.value}` === `${lineCode.list}${lineCode.value}`;
                    });

                    if (this.selection.some((s: HealthcareInvoiceLine) => s.id === line.id)) {
                        this.validation.items.push({
                            insurance: true
                        });
                        this.request.items.push(
                            new CreateDeclarationPerformancesRequestItem({
                                lineId: line.id,
                                insuranceId: null,
                                merges: charges.any() ? [line.id, ...charges.map((x) => x.id)] : [],
                                sendMethod: DeclarationSendMethods.Vecozo,
                                startedAt: startOfDay(line.productDate)
                            })
                        );
                    }

                    // First group the additional charges by their Vektis code.
                    // The rule is that charges that have the same vektis code (and by default on the same product date
                    // because the product date is the same for all charges on the same line) can not be declared separately.
                    // That is why we group them together, add one item for the group and show the totals of
                    // the grouped charges in the view (which is calculated in the view).
                    const groups = line.additionalCharges
                        // Make sure that the charge is selected
                        .filter((x) => this.selection.some((s: HealthcareInvoiceCharge) => s.id === x.id))
                        // Filter out the charges that have the same Vektis code as the line.
                        // Because if they have the same code, the totals are already added to the line.
                        .filter((x) => !charges.some((c) => c.id === x.id))
                        .groupBy((x: HealthcareInvoiceCharge) => {
                            const code = x.codes.find((y: HealthcareCode) => y.system === 'Vektis');
                            return `${code.system}-${code.system}`;
                        });

                    for (const group of groups) {
                        this.validation.items.push({
                            insurance: true
                        });
                        this.request.items.push(
                            new CreateDeclarationPerformancesRequestItem({
                                lineId: line.id,
                                insuranceId: null,
                                merges: group[1].map((x: HealthcareInvoiceCharge) => x.id),
                                sendMethod: DeclarationSendMethods.Vecozo,
                                startedAt: startOfDay(line.productDate)
                            })
                        );
                    }
                }
                this.setGroups();

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarationPerformancesCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async covCheck(): Promise<void> {
        super.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.PatientCovCheck.with({ id: this.patientId }).whenClosed(async (result: PartialViewResults) => {
                // Reset all insurances.
                this.request.items.forEach((item) => {
                    item.insuranceId = null;
                });
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: false })
        });
    }

    public selectInsurance(insurance: Insurance): void {
        this.request.items.forEach((item) => {
            item.insuranceId = insurance.id;
            item.insuranceReference = insurance.insurerReference;
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.declaration-performances.questions.create.title'),
                    message: this.t.tr('translation:partial-views.declaration-performances.questions.create.message'),
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.submit'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.performancesApi.createBulk(this.authenticated.workspace.id, this.request);
                                this.notifications.show(
                                    this.t.tr('translation:partial-views.declaration-performances.notifications.creation-successful.title'),
                                    this.t.tr('translation:partial-views.declaration-performances.notifications.creation-successful.message'),
                                    { type: 'success', duration: 3000 }
                                );
                                this.events.publish(CustomEvents.HealthcareInvoicesUpdated, this.invoice);
                                setTimeout(async () => this.remove({ result: PartialViewResults.Ok }), 250);
                            } catch (e) {
                                this.isLoading = false;
                                this.errorHandler.handle('[create-performances]', e);
                            }
                        } else this.isLoading = false;
                    }
                })
            );
        }
    }

    public handleMethodSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>) {
        const value = e.detail.values.value as DeclarationSendMethods;
        this.request.items.forEach((item) => (item.sendMethod = value));
    }

    public handleSingleMethodSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>) {
        // All items with the same group id will have the same insurance.
        const item = e.detail.data as CreateDeclarationPerformancesRequestItem;
        // Find the line and optionally the charge of the current item.
        const line = this.invoice.lines.find((l) => l.id === item.lineId);
        const charge = isDefined(item.additionalChargeId) ? line.additionalCharges.find((c) => c.id === item.additionalChargeId) : null;
        for (const i of this.request.items) {
            // Find the lwine of the current item.
            const iLine = this.invoice.lines.find((l) => l.id === i.lineId);
            const iCharge = isDefined(i.additionalChargeId) ? iLine.additionalCharges.find((c) => c.id === i.additionalChargeId) : null;
            // Let's not compare the item with itself.
            // First check the lines to eachother.
            if (line.id === iLine.id) continue;
            // If the line is the same, check if the charge is the same.
            if (isDefined(charge) && isDefined(iCharge) && charge.id == iCharge.id) continue;
            // Now we know that the line and or the charge is different.
            // Check if the group id is the same. If so, set the same send method.
            if ((isDefined(charge) && isDefined(iCharge) && charge.groupId === iCharge.groupId) || line.groupId === iLine.groupId) {
                i.sendMethod = item.sendMethod;
            }
        }
    }

    public handleInsuranceSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>) {
        const insuranceId = e.detail.values.value;
        const index = e.detail.data.index;
        const insurance = this.patient.insurances.find((i) => i.id === insuranceId);
        this.request.items[index].insuranceReference = insurance.insurerReference;
        // All items with the same group id will have the same insurance.
        const item = e.detail.data.item as CreateDeclarationPerformancesRequestItem;
        // Find the line and optionally the charge of the current item.
        const line = this.invoice.lines.find((l) => l.id === item.lineId);
        const charge = isDefined(item.additionalChargeId) ? line.additionalCharges.find((c) => c.id === item.additionalChargeId) : null;
        for (const i of this.request.items) {
            // Find the line of the current item.
            const iLine = this.invoice.lines.find((l) => l.id === i.lineId);
            const iCharge = isDefined(i.additionalChargeId) ? iLine.additionalCharges.find((c) => c.id === i.additionalChargeId[0]) : null;
            // Let's not compare the item with itself.
            // First check the lines to eachother.
            if (line.id === iLine.id) continue;
            // If the line is the same, check if the charge is the same.
            if (isDefined(charge) && isDefined(iCharge) && charge.id == iCharge.id) continue;
            // Now we know that the line and or the charge is different.
            // Check if the group id is the same. If so, set the same insurance.
            if ((isDefined(charge) && isDefined(iCharge) && charge.groupId === iCharge.groupId) || line.groupId === iLine.groupId) {
                i.insuranceId = item.insuranceId;
                i.insuranceReference = item.insuranceReference;
            }
        }
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok
        });
    }

    public async handleDateChanged(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const date = new Date(e.detail.values.date);
        const index = e.detail.data;

        this.request.items[index].startedAt = startOfDay(date);
    }

    private validate(): boolean {
        if (this.invoice.type === HealthcareInvoiceTypes.Credit) return true;
        for (let i = 0; i < this.request.items.length; i++) {
            const item = this.request.items[i];
            this.validation.items[i].insurance = isDefined(item.insuranceId);
        }
        return this.validation.items.every((x: any) => x.insurance);
    }

    private setGroups(): void {
        this.groups = {};
        const validateItem = (item: HealthcareInvoiceLine | HealthcareInvoiceCharge) =>
            isDefined(item.codes) &&
            // The line should have a VEKTIS code.
            item.codes.some((x) => x.system === 'Vektis') &&
            // It should have group ID
            isDefined(item.groupId);

        const lines = this.invoice.lines.filter(validateItem).map((x) => x.groupId);
        const charges = this.invoice.lines
            .selectMany((x: HealthcareInvoiceLine) => x.additionalCharges)
            .filter(validateItem)
            .map((x) => x.groupId);

        const groups = [...lines, ...charges].groupBy((x: string) => x);
        // Filter out groups that have only one item.
        const groupIds = new Map([...groups].filter(([_, value]) => value.length > 1));

        var colors = ['text-green-700', 'text-purple-700', 'text-yellow-700'];
        var index = 0;
        for (const key of groupIds.keys()) {
            this.groups[key] = colors[index];
            index++;
        }
    }
}
