<import from="components/component-duplicator/component-duplicator" />

<div class="flex flex-col">
    <component-duplicator
        t="[label]translation:components.widgets.patient-emails.labels.enter-emails"
        type="email"
        descriptions.bind="descriptions"
        items.two-way="registration.widget.result.value"
        language.bind="language"
        validation.two-way="validation.values">
    </component-duplicator>
</div>
