<import from="converters/translate" />
<import from="converters/replace-variables" />
<import from="converters/get-values" />
<import from="converters/file-name" />
<import from="converters/file-size" />
<import from="converters/blob-to-url" />
<import from="converters/file-to-icon" />
<import from="converters/sort" />

<div class="${columns} flex flex-col" if.bind="shouldBeDisplayed && questionToUse">
    <let placeholder.bind="getPlaceholder()"></let>
    <div class="flex justify-between">
        <div class="flex flex-1 items-center space-x-1">
            <ux-label>${ questionToUse.content | translate:language }</ux-label>
            <span if.bind="required" class="flex h-3 items-center text-red-500">*</span>
        </div>
        <button if.bind="recent && recent.informationSheet" click.trigger="openInformationSheet()" class="flex h-6 w-6 items-center rounded-full text-blue-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>
        </button>
    </div>
    <p
        class.bind="toManyFiles ? 'text-red-500 font-medium' : 'text-indigo-700'"
        class="text-xs"
        if.bind="questionToUse.answerType === ResultTypes.File && questionToUse.filesAmount && questionToUse.filesAmount > 0"
        innerhtml.bind="'translation:partial-views.clinical-pathways.messages.max-files' | t | replaceVariables:questionToUse.filesAmount"></p>
    <p
        class.bind="wrongFileTypes ? 'text-red-500 font-medium' : 'text-indigo-700'"
        class="text-xs"
        if.bind="questionToUse.answerType === ResultTypes.File && questionToUse.allowedFileTypes && questionToUse.allowedFileTypes.length > 0"
        innerhtml.bind="'translation:partial-views.clinical-pathways.messages.file-types' | t | replaceVariables:getFileTypes()"></p>
    <p
        class="text-xs text-indigo-700"
        if.bind="questionToUse.answerType === ResultTypes.Number && questionToUse.inputRules.min && !questionToUse.inputRules.max"
        innerhtml.bind=" 'translation:partial-views.clinical-pathways.messages.min-value' | t | replaceVariables:questionToUse.inputRules.min"></p>
    <p
        class="text-xs text-indigo-700"
        if.bind="questionToUse.answerType === ResultTypes.Number && questionToUse.inputRules.max && !questionToUse.inputRules.min"
        innerhtml.bind="'translation:partial-views.clinical-pathways.messages.max-value' | t | replaceVariables:questionToUse.inputRules.max"></p>
    <p
        class="text-xs text-indigo-700"
        if.bind="questionToUse.answerType === ResultTypes.Number && questionToUse.inputRules.max && questionToUse.inputRules.min"
        innerhtml.bind="'translation:partial-views.clinical-pathways.messages.min-max-value' | t | replaceVariables:questionToUse.inputRules.min:questionToUse.inputRules.max"></p>
    <div class="mt-2 flex flex-col">
        <template if.bind="questionToUse.answerType === ResultTypes.Text || questionToUse.answerType === ResultTypes.Number">
            <template if.bind="questionToUse.inputType === InputTypes.Selector">
                <template if.bind="questionToUse.isMultipleChoice">
                    <ux-multi-selector
                        options.bind="toSet(questionToUse.choices)"
                        on-selected.bind="handleMultiChoiceSelected"
                        on-removed.bind="handleMultiChoiceRemoved"
                        show-footer.bind="false"
                        force-remove.bind="true"
                        autocomplete.bind="true"
                        values.bind="questionToUse.givenAnswers | getValues:'value'"
                        placeholder.bind="placeholder">
                    </ux-multi-selector>
                </template>
                <template else>
                    <ux-combobox
                        onselect.trigger="handleAnswerSelected($event)"
                        onclear.trigger="handleAnswerCleared($event)"
                        value.two-way="questionToUse.givenAnswer.value"
                        allow-clear.bind="true"
                        placeholder.bind="placeholder"
                        autocomplete.bind="true"
                        valid.bind="validation.answer && validation.min && validation.max">
                        <ux-combobox-option repeat.for="choice of questionToUse.choices | sort:'displayOrder'" value.bind="choice.value"> ${ choice.value }</ux-combobox-option>
                    </ux-combobox>
                </template>
            </template>
            <template if.bind="questionToUse.inputType === InputTypes.FreeEntry">
                <template if.bind="questionToUse.answerType === ResultTypes.Text">
                    <ux-textarea
                        onblur.trigger="handleBlur($event)"
                        onclear.trigger="handleAnswerCleared($event)"
                        rows="1"
                        value.two-way="questionToUse.givenAnswer.value"
                        allow-clear.bind="true"
                        valid.bind="validation.answer && validation.min && validation.max"
                        placeholder.bind="placeholder">
                    </ux-textarea>
                </template>
                <template if.bind="questionToUse.answerType === ResultTypes.Number">
                    <ux-input
                        type="number"
                        onblur.trigger="handleBlur($event)"
                        onclear.trigger="handleAnswerCleared($event)"
                        allow-clear.bind="true"
                        value.two-way="questionToUse.givenAnswer.value"
                        valid.bind="validation.answer  && validation.min && validation.max"
                        placeholder.bind="placeholder">
                    </ux-input>
                </template>
            </template>
            <template if.bind="questionToUse.inputType === InputTypes.RangeSlider">
                <ux-card>
                    <div class="flex flex-1 rounded-lg border border-gray-200 px-6 pb-5 pt-7">
                        <ux-range-slider
                            component.ref="slider"
                            value.two-way="questionToUse.givenAnswer.value"
                            start.bind="questionToUse.slider.startValue"
                            end.bind="questionToUse.slider.endValue"
                            major-increment.bind="questionToUse.slider.majorIncrement"
                            minor-increment.bind="questionToUse.slider.minorIncrement"
                            labels.bind="questionToUse.slider.labels"
                            initial.bind="questionToUse.slider.initialValue"
                            language.bind="language">
                        </ux-range-slider>
                    </div>
                </ux-card>
            </template>
        </template>
        <template if.bind="questionToUse.answerType === ResultTypes.Date">
            <template if.bind="questionToUse.inputType === InputTypes.Datepicker">
                <ux-datepicker value.two-way="questionToUse.givenAnswer.date" valid.bind="validation.answer && validation.min && validation.max" placeholder.bind="placeholder"></ux-datepicker>
            </template>
        </template>
        <template if.bind="questionToUse.answerType === ResultTypes.File">
            <template if.bind="questionToUse.inputType === InputTypes.Filepicker">
                <ux-card>
                    <div class="flex flex-col gap-2 rounded-lg border border-gray-200 p-3">
                        <ux-dropzone
                            ref="dropzone"
                            accepted-files.bind="contentTypes"
                            valid.bind="validation.answer && validation.filesQuantity && validation.fileTypes"
                            max-files.bind="questionToUse.filesAmount > 0 ? questionToUse.filesAmount : 0"
                            on-files-selected.bind="filesSelected">
                            <div class="flex flex-1 flex-col items-center justify-center gap-1 p-6">
                                <svg class="h-8 w-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.25 14.25C6.25 14.25 4.75 14 4.75 12C4.75 10.2869 6.07542 8.88339 7.75672 8.75897C7.88168 6.5239 9.73368 4.75 12 4.75C14.2663 4.75 16.1183 6.5239 16.2433 8.75897C17.9246 8.88339 19.25 10.2869 19.25 12C19.25 14 17.75 14.25 17.75 14.25"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    <path d="M14.25 15.25L12 12.75L9.75 15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 19.25V13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <span class="text-center text-sm" t="translation:global.messages.drop-files"></span>
                            </div>
                        </ux-dropzone>
                        <ul if.bind="uploads.length > 0 || attachments.length > 0" role="list" class="flex flex-col space-y-1">
                            <li repeat.for="upload of uploads" id="upload-${questionToUse.id}-${$index}" class="flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5">
                                <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                                    <template if.bind="isImage(upload.file.type)">
                                        <ux-image height="56px" width="75px" size="cover" src.bind="upload.file | blobToUrl"></ux-image>
                                    </template>
                                    <template else>
                                        <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                                            <span class="text-gray-400" innerhtml.bind="upload.extension | fileToIcon:'w-6 h-6'"></span>
                                        </div>
                                    </template>
                                </div>

                                <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                                    <div class="flex min-w-0 flex-1 flex-col">
                                        <p class="= pointer-events-none block truncate text-sm text-gray-900">${ upload.name | fileName }${ upload.extension }</p>
                                        <p class="pointer-events-none block text-sm text-gray-500">${ upload.file.size | fileSize }</p>
                                    </div>
                                    <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                        <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                            <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                            </svg>
                                        </div>
                                        <ux-dropdown-option click.trigger="deleteFromUploads($index)">
                                            <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                                        </ux-dropdown-option>
                                    </ux-dropdown>
                                </div>
                            </li>
                            <li repeat.for="attachment of attachments" id="attachment-${questionToUse.id}-${$index}" class="flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5">
                                <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                                    <template if.bind="isImage(attachment.contentType)">
                                        <ux-image height="56px" width="75px" size="cover" src.bind="urls[attachment.id]"></ux-image>
                                    </template>
                                    <template else>
                                        <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                                            <span class="text-gray-400" innerhtml.bind="attachment.extension | fileToIcon:'w-6 h-6'"></span>
                                        </div>
                                    </template>
                                </div>
                                <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                                    <div class="flex min-w-0 flex-1 flex-col">
                                        <p class="= pointer-events-none block truncate text-sm text-gray-900">${ attachment.name }${ attachment.extension }</p>
                                        <p class="pointer-events-none block text-sm text-gray-500">${ attachment.size | fileSize }</p>
                                    </div>
                                    <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                        <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                            <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                            </svg>
                                        </div>
                                        <ux-dropdown-option click.trigger="open(attachment)">
                                            <span class="pointer-events-none" t="translation:global.buttons.open"></span>
                                        </ux-dropdown-option>
                                        <ux-dropdown-option click.trigger="download(attachment)">
                                            <span class="pointer-events-none" t="translation:global.buttons.download"></span>
                                        </ux-dropdown-option>
                                        <ux-dropdown-divider></ux-dropdown-divider>
                                        <ux-dropdown-option click.trigger="deleteAttachment($index)">
                                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                        </ux-dropdown-option>
                                    </ux-dropdown>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ux-card>
            </template>
        </template>
        <p
            if.bind="!validation.min"
            class="mt-1 text-xs text-red-500"
            innerhtml.bind="'translation:partial-views.clinical-pathways.messages.min-value-error' | t | replaceVariables:questionToUse.inputRules.min"></p>
        <p
            if.bind="!validation.max"
            class="mt-1 text-xs text-red-500"
            innerhtml.bind="'translation:partial-views.clinical-pathways.messages.max-value-error' | t | replaceVariables:questionToUse.inputRules.max"></p>
    </div>
</div>
<template if.bind="!questionToUse">
    <div class="${columns} flex items-center justify-between rounded-lg bg-red-100 px-3 py-2 text-sm font-medium text-red-600">
        <div class="flex min-w-0 flex-1 items-center gap-x-3">
            <span class="text-gray-500" t="translation:global.labels.question"></span>
            <div class="flex min-w-0 flex-1 justify-between">
                <span class="text-gray-800">${ step.question.translations | translate:language }</span>
                <div class="flex min-w-0 gap-x-3">
                    <span class="block truncate" t="translation:global.messages.deleted-question"></span>
                    <button
                        click.trigger="remove(step.id)"
                        class="flex h-5 w-5 items-center justify-center rounded-full text-red-500 ring-red-500 ring-offset-1 hover:text-red-600 focus:outline-none focus:ring-1 active:text-red-700">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
