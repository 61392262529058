<template>
    <section class="flex items-center justify-between flex-1 flex-items">
        <div
            class="input-container relative flex h-[38px] min-w-[54px] items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-2"
            ring-1.class="hasFocus"
            ring-gray-400.class="hasFocus"
            ring-offset-1.class="hasFocus">
            <!-- <input
                    ref="input"
                    type="number"
                    class="h-[38px] text-center text-sm focus:outline-none"
                    mr-1.class="maxPage"
                    text-right.class="maxPage"
                    w-10.class="maxPage"
                    text-center.class="maxPage"
                    w-6.class="currentPage <= 9"
                    w-5.class="currentPage > 9"
                    w-8.class="currentPage > 99"
                    w-9.class="currentPage > 799"
                    w-10.class="currentPage > 999 || hasFocus"
                    value.bind="currentPage"
                    onkeyup.trigger="handleKeyUp($event)"
                    onchange.trigger="handleChange($event)"
                    onfocus.trigger="handleFocus()"
                    onblur.trigger="handleBlur()" /> -->

            <div if.bind="isLoading" class="absolute left-[calc(50%-6px)]">
                <ux-spinner size="xxxs"></ux-spinner>
            </div>
            <span text-transparent.class="isLoading" class="block text-sm text-right">${currentPage}</span>
            <span if.bind="maxPage" class="block text-sm" text-transparent.class="isLoading"> / ${maxPage} </span>
        </div>
        <au-slot />
        <div class="flex space-x-1">
            <button
                disabled.bind="currentPage === 1 || isLoading"
                class="px-1 py-2 border border-gray-300 rounded-lg ring-gray-400 ring-offset-1 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-100"
                click.trigger="handlePreviousPage($event)">
                <svg class="w-6 h-4 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                </svg>
            </button>
            <button
                class="px-1 py-2 border border-gray-300 rounded-lg ring-gray-400 ring-offset-1 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-100"
                disabled.bind="currentPage >= maxPage || isLoading"
                click.trigger="handleNextPage($event)">
                <svg class="w-6 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
            </button>
        </div>
    </section>
</template>
