// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ux-avatar-group:hover ux-tooltip:not(:first-child) {margin-left: -0.25rem
}ux-avatar-group:hover ux-avatar:not(ux-tooltip):not(:first-child) {margin-left: -0.25rem
}ux-avatar-group au-tooltip {transition-property: all;transition-duration: 200ms;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}ux-avatar-group au-tooltip:not(:first-child) {margin-left: -1rem
}ux-avatar-group ux-avatar div {--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);--tw-ring-opacity: 1;--tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))
}ux-avatar-group ux-avatar:not(ux-tooltip) {transition-property: all;transition-duration: 200ms;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}ux-avatar-group ux-avatar:not(ux-tooltip):not(:first-child) {margin-left: -1rem
}`, "",{"version":3,"sources":["webpack://./src/ux/ux-avatar-group/ux-avatar-group.scss"],"names":[],"mappings":"AAIQ,oDAAA;AAAA,CAOE,mEAAA;AAAA,CAON,4BAAA,wBAAA,CACA,0BAAA,CACA;AAFA,CAKE,8CAAA;AAAA,CAMA,+BAAA,2GAAA,CAAA,yGAAA,CAAA,4FAAA,CACA,oBAAA,CAAA;AADA,CAKA,2CAAA,wBAAA,CACA,0BAAA,CACA;AAFA,CAKE,6DAAA;AAAA","sourcesContent":["ux-avatar-group {\r\n  &:hover {\r\n    ux-tooltip {\r\n      &:not(:first-child) {\r\n        @apply -ml-1;\r\n      }\r\n    }\r\n\r\n    ux-avatar {\r\n      &:not(ux-tooltip) {\r\n        &:not(:first-child) {\r\n          @apply -ml-1;\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  au-tooltip {\r\n    @apply transition-all;\r\n    @apply duration-200;\r\n    @apply ease-in-out;\r\n\r\n    &:not(:first-child) {\r\n      @apply -ml-4;\r\n    }\r\n  }\r\n\r\n  ux-avatar {\r\n    div {\r\n      @apply ring-1;\r\n      @apply ring-white;\r\n    }\r\n\r\n    &:not(ux-tooltip) {\r\n      @apply transition-all;\r\n      @apply duration-200;\r\n      @apply ease-in-out;\r\n\r\n      &:not(:first-child) {\r\n        @apply -ml-4;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
