import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetPatientResponse, InsuranceStatuses, InsuranceTypes, PatientsApiClient } from '@wecore/sdk-healthcare';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient)
export class PartialPatientInsurances extends BasePartialView {
    public patient: GetPatientResponse;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;

    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.patient = await this.patientsApi.getById(this.patientId, this.authenticated.workspace.id);
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPatientInsurances.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }
}
