import { ResultMatcherTypes } from '@wecore/sdk-healthcare';

export class ResultMatcherTypeToStringValueConverter {
    public toView(type: ResultMatcherTypes): string {
        switch (type) {
            case ResultMatcherTypes.SimpleComparison:
                return 'translation:global.labels.simple-comparison';
            case ResultMatcherTypes.BetweenRange:
                return 'translation:global.labels.between-range';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
