<import from="converters/translate" />
<import from="converters/date" />
<import from="./templates/template-terms-and-conditions/template-terms-and-conditions" />
<import from="./templates/template-processing-agreement/template-processing-agreement" />
<import from="./templates/template-eula/template-eula" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-col overflow-y-auto">
            <div class="sticky top-0 z-20 flex items-center justify-center gap-1 border-b border-gray-200 bg-white px-5 py-2">
                <ux-tabs>
                    <ux-tab active.bind="view === 'document'" click.trigger="setViewTo('document')" padding="px-2 py-1">
                        <span class="text-xs" t="translation:global.labels.document"></span>
                    </ux-tab>
                    <ux-tab active.bind="view === 'sign'" valid.bind="validation.valid" click.trigger="setViewTo('sign')" icon="size-4" padding="px-2 py-1">
                        <span class="text-xs" t="translation:global.labels.signing"></span>
                    </ux-tab>
                </ux-tabs>
            </div>
            <div class="flex flex-1">
                <template if.bind="baseLoaded">
                    <div show.bind="view === 'document'" class="flex flex-1 justify-center bg-gray-50 p-10">
                        <ux-pdf-preview scale="1.5" src.bind="documentUrl"></ux-pdf-preview>
                    </div>
                    <div show.bind="view === 'sign'" class="flex flex-1 flex-col p-10">
                        <template-terms-and-conditions
                            if.bind="documentType === 'terms-and-conditions'"
                            component.ref="termsAndConditions"
                            is-loading.bind="isLoading"
                            company.bind="company"
                            entity.bind="entity"
                            version.bind="version">
                        </template-terms-and-conditions>
                        <template-processing-agreement
                            if.bind="documentType === 'processing-agreement'"
                            component.ref="processingAgreement"
                            is-loading.bind="isLoading"
                            company.bind="company"
                            version.bind="version"
                            entity.bind="entity">
                        </template-processing-agreement>
                        <template-eula if.bind="documentType === 'eula'" component.ref="eula" is-loading.bind="isLoading" version.bind="version"> </template-eula>
                        <div class="flex gap-10">
                            <div class="w-96"></div>
                            <div class="mt-5 flex flex-1 flex-col">
                                <label class="mb-2 text-sm text-gray-600" t="translation:partial-views.sign-document.messages.place-signature"></label>
                                <div border-red-500.class="!validation.signature" border-gray-200.class="validation.signature" class="relative h-[300px] rounded-lg border border-gray-100 bg-gray-50">
                                    <a
                                        if.bind="signatureAdded && !isLoading"
                                        class="absolute right-[5px] top-0 p-2 lowercase text-primary shadow-none"
                                        href="javascript:"
                                        click.trigger="clearSignature()">
                                        <small t="translation:partial-views.sign-document.labels.again"></small>
                                    </a>
                                    <canvas ref="signatureCanvas" class="h-full w-full"></canvas>
                                </div>
                                <span class="mt-2 block text-xs text-gray-500" t="translation:partial-views.sign-document.messages.place-signature-tip"></span>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center px-8">
                        <div class="flex w-full rounded-sm bg-white px-60 py-60">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <ux-footer>
            <div class="flex flex-1 items-center justify-end gap-x-2">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color.bind="view === 'document' ? 'primary' : 'success'"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="sign()"
                        t="[text-when-loading]translation:global.labels.loading-states.signing">
                        <span class="pointer-events-none" t="global.buttons.sign"></span>
                    </ux-button>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
