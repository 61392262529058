import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    ColumnBreakpoint,
    DifferentialDiagnosesRequirements,
    DifferentialDiagnosisEntityReference,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalQuestionResponse,
    InformationSheet,
    MedicalExaminationActionRequirements,
    MedicalExaminationPhaseEntityReference,
    MedicalExaminationStepVisibilityRequirement,
    MedicalQuestion,
    MedicalQuestionRegistration,
    MedicalQuestionnaireFlow,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient,
    Operators,
    StringBooleanKeyValuePair,
    StringMedicalExaminationModelBoxKeyValuePair,
    StringStringKeyValuePair,
    UpdateMedicalQuestionnaireRequest
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { clearQuestionItem, copyQuestionItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import { addToSpecificIndex, cleanTranslatables, cloneDeep, generateColumns, removeItemSettings, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { CopyQuestionItem } from '../../../models/copy-question-item';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { StepState } from '../../../models/step-state';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxCombobox } from '../../../ux/ux-combobox/ux-combobox';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalQuestionnairesApiClient, MedicalQuestionsApiClient, ModalService)
export class PartialMedicalQuestionnairesEdit extends BasePartialView {
    public questionnaireId: string;
    public questionnaire: UpdateMedicalQuestionnaireRequest;
    public questions: GetMedicalQuestionResponse[];
    public Operators: typeof Operators = Operators;
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        questions: [],
        any: true,
        results: []
    };
    public states: any = {};

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient,
        private readonly questionsApi: MedicalQuestionsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.questionnaireId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [questionnaire, questions] = await Promise.all([
                    this.questionnairesApi.getById(this.questionnaireId, this.authenticated.workspace.id), //
                    this.questionsApi.search(this.authenticated.workspace.id, '', 500, 0, undefined, undefined, undefined, [this.questionnaireId])
                ]);
                this.questionnaire = questionnaire;
                this.questions = questions.data;
                if (isNotDefined(this.questionnaire.description)) this.questionnaire.description = setTranslation({}, this.language);
                if (isNotDefined(this.questionnaire.flow))
                    this.questionnaire.flow = new MedicalQuestionnaireFlow({
                        breakpoints: [],
                        required: [],
                        visibilityRequirements: []
                    });

                this.setValidation();

                this.subscriptions.push(
                    this.events.subscribe(CustomEvents.QuestionnaireItemsChanged, (item: MedicalQuestionRegistration) => {
                        this.setColumns(item);
                        this.checkIfHasSettings(item);

                        this.questionnaire.questions = [
                            ...(this.questionnaire.questions.length > 0 ? [this.questionnaire.questions.shift()] : []), //
                            ...cloneDeep(this.questionnaire.questions)
                        ];
                    })
                );

                this.baseLoaded = true;
                await super.handleBaseLoaded();
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalQuestionnairesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
        this.store.dispatch(clearQuestionItem);
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.questionnaire.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public openSettings = async (item: MedicalQuestionRegistration, categoryId: string): Promise<void> => {
        if (isNotDefined(this.questionnaire.flow.required)) this.questionnaire.flow.required = [];
        if (isNotDefined(this.questionnaire.flow.breakpoints)) this.questionnaire.flow.breakpoints = [];
        if (isNotDefined(this.questionnaire.flow.visibilityRequirements)) this.questionnaire.flow.visibilityRequirements = [];

        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.questionnaire.flow,
            item,
            active: this.questionnaire,
            settings: ['required', 'columns', 'visibility'],
            label: 'global.labels.question',
            name: item.question.name[this.language],
            categoryId
        }).whenClosed(
            async (
                result: PartialViewResults,
                response: {
                    breakpoints: ColumnBreakpoint[];
                    required: StringBooleanKeyValuePair;
                    repeatDuringEvaluation: StringBooleanKeyValuePair;
                    visibilityRequirements: MedicalExaminationStepVisibilityRequirement[];
                    ddRequirements: DifferentialDiagnosesRequirements[];
                    actionRequirements: MedicalExaminationActionRequirements[];
                    modelBox: StringMedicalExaminationModelBoxKeyValuePair;
                    categories: StringStringKeyValuePair[];
                }
            ) => {
                if (result === PartialViewResults.Ok) {
                    // Remove old breakpoints and add new ones.
                    this.questionnaire.flow.breakpoints = [
                        ...this.questionnaire.flow.breakpoints.filter((x) => x.id !== item.id), //
                        ...response.breakpoints.map(ColumnBreakpoint.fromJS)
                    ];

                    // Remove old requirements and and new one if present.
                    this.questionnaire.flow.required = this.questionnaire.flow.required.filter((x) => x.key !== item.id);
                    if (isDefined(response.required))
                        this.questionnaire.flow.required.push(
                            StringBooleanKeyValuePair.fromJS(response.required) //
                        );

                    // Remove old visibilityRequirements and add new ones.
                    this.questionnaire.flow.visibilityRequirements = [
                        ...this.questionnaire.flow.visibilityRequirements.filter((x) => x.id !== item.id), //
                        ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                    ];

                    this.events.publish(CustomEvents.QuestionnaireItemsChanged, item);
                }
            }
        );

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public setColumns = (item: MedicalQuestionRegistration): string => {
        return generateColumns(
            this.questionnaire.flow.breakpoints.filter((x) => x.id === item.id) || [] //
        );
    };

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description', 'scoreResultRanges:description'], this.questionnaire, 2);
                await this.questionnairesApi.update(this.questionnaireId, this.authenticated.workspace.id, this.questionnaire);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-questionnaires.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-questionnaires.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.questionnaire.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.questionnaire.description)) this.questionnaire.description = setTranslation({}, this.language);
                for (const result of this.questionnaire.scoreResultRanges) {
                    if (isNotDefined(result.description)) result.description = setTranslation({}, this.language);
                }
                this.isLoading = false;
                await this.errorHandler.handle('[edit-medical-questionnaires]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-questionnaires.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-questionnaires.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.questionnaire.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.questionnairesApi.delete(this.questionnaireId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-questionnaires.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-questionnaires.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.questionnaire.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-questionnaires-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor = (property: string, required: boolean = false, index: number = null): void => {
        this.manageTranslations({
            translations: isDefined(index) ? this.questionnaire.scoreResultRanges[index][property] : this.questionnaire[property],
            callback: (updatedTranslations: any) => {
                if (isDefined(index)) this.questionnaire.scoreResultRanges[index][property] = updatedTranslations;
                else this.questionnaire[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.questionnaire.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.questionnaire.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public addItem = (index: number): void => {
        addToSpecificIndex(
            this.validation.questions,
            {
                question: true,
                valid: true
            },
            index
        );
        addToSpecificIndex(
            this.questionnaire.questions, //
            new MedicalQuestionRegistration({
                id: guid(),
                attributes: {}
            }),
            index
        );
    };

    public async handleQuestionSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        const value = e.detail.values.value;
        const question = this.questions.find((x) => x.id === value);

        this.questionnaire.questions[index].question = MedicalQuestion.fromJS(question);
        // Force array to refresh.
        this.questionnaire.questions = [
            ...(this.questionnaire.questions.length > 0 ? [this.questionnaire.questions.shift()] : []), //
            ...cloneDeep(this.questionnaire.questions)
        ];
    }

    public async handleCalculateChanged(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (!checked) this.questionnaire.scoreResultRanges = [];
    }

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        this.questionnaire.differentialDiagnoses = diagnoses.map(
            (d) =>
                new DifferentialDiagnosisEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public async copyOrCut(command: 'copy' | 'cut', index: number): Promise<void> {
        await this.store.dispatch(
            copyQuestionItem,
            new CopyQuestionItem({
                item: this.questionnaire.questions[index],
                array: this.questionnaire.questions,
                index: index,
                validationItem: this.validation.questions[index],
                validation: this.validation.questions,
                command
            })
        );
    }

    public async pasteItem(index: number = -1): Promise<void> {
        const itemToCopy = this.state.clipboard.questionItem;
        if (isNotDefined(itemToCopy)) return;

        // If we paste after the current item e.g.
        // the provided index is bigger than the current
        // index, substract 1 from the index to get the item
        // on the correct spot.
        if (index > itemToCopy.index) index--;

        // Create a copy without any references.
        // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
        const parsed = JSON.parse(JSON.stringify(itemToCopy.item));
        const copy = MedicalQuestionRegistration.fromJS(Object.assign({}, parsed));
        // If we're copying, make sure the item ids are unique.
        if (itemToCopy.command === 'copy') copy.id = guid();

        // Wait for the old array to update.
        setTimeout(async () => {
            addToSpecificIndex(this.questionnaire.questions, copy, index);
            // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
            const parsed = JSON.parse(JSON.stringify(itemToCopy.validationItem));
            addToSpecificIndex(this.validation.questions, Object.assign({}, parsed), index);
            // Remove the copied item from the state.
            await this.store.dispatch(clearQuestionItem);
        });
    }

    public removeItem(index: number): void {
        const item = this.questionnaire.questions[index];

        this.questionnaire.questions.splice(index, 1);
        this.validation.questions.splice(index, 1);

        removeItemSettings(this.questionnaire.flow, item);

        this.questionnaire.questions = [
            ...(this.questionnaire.questions.length > 0 ? [this.questionnaire.questions.shift()] : []), //
            ...this.questionnaire.questions
        ];
    }

    public moveItem(direction: 'up' | 'down', currentIndex: number): void {
        let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex > this.questionnaire.questions.length - 1) newIndex = this.questionnaire.questions.length - 1;

        const stepToMove = this.questionnaire.questions[currentIndex];
        const validationToMove = this.validation.questions[currentIndex];

        this.questionnaire.questions.splice(currentIndex, 1);
        this.validation.questions.splice(currentIndex, 1);

        this.questionnaire.questions.splice(newIndex, 0, stepToMove);
        this.validation.questions.splice(newIndex, 0, validationToMove);
    }

    public getIndex(index: number, position: 'before' | 'after'): number {
        if (position === 'before') {
            return index;
        } else {
            if (index === 0 && this.questionnaire.questions.length === 1) return -1;
            return index + 1;
        }
    }

    public async refreshTemplate(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.medical-questionnaires.questions.refresh-templates.title'),
                message: this.t.tr('translation:partial-views.medical-questionnaires.questions.refresh-templates.message'),
                btnOk: this.t.tr('translation:global.buttons.update'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.questionnairesApi.refreshTemplate(this.questionnaireId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-questionnaires.notifications.refreshed-templates-successfully.title'),
                                this.t.tr('translation:partial-views.medical-questionnaires.notifications.refreshed-templates-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[refresh-medical-examination]', e);
                        }
                    }
                }
            })
        );
    }

    private setValidation(): void {
        for (const question of this.questionnaire.questions) {
            this.states[question.id] = new StepState({ stepId: question.id, expanded: false });
            this.validation.questions.push({
                question: true,
                valid: true
            });
        }
        for (const _ of this.questionnaire.scoreResultRanges) {
            this.validation.results.push({
                description: true,
                start: true,
                end: true
            });
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        const query = e.detail.values;
    }

    public toggleExpansion(id: string): void {
        this.questionnaire.questions.filter((x) => x.id !== id).forEach((x) => (this.states[x.id].expanded = false));
        this.states[id].expanded = !this.states[id].expanded;
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.questionnaire.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.questionnaire.displayOrder) && isOfType(Number(this.questionnaire.displayOrder), 'number');
        this.validation.phase = isDefined(this.questionnaire.phase);
        this.validation.any = isDefined(this.questionnaire.questions) && this.questionnaire.questions.any();

        for (let qI = 0; qI < this.questionnaire.questions.length; qI++) {
            // Always reset validation for each item.
            resetValidation(this.validation.questions[qI]);

            const item = this.questionnaire.questions[qI];
            this.validation.questions[qI].question = isDefined(item.question);
            this.validation.questions[qI].valid = this.validation.questions[qI].question;
        }

        for (let index = 0; index < this.questionnaire.scoreResultRanges.length; index++) {
            // Always reset validation for each item.
            resetValidation(this.validation.results[index]);
            if (this.questionnaire.calculateScoreTotal) {
                const item = this.questionnaire.scoreResultRanges[index];
                this.validation.results[index].description = validateTranslation(item.description, this.language);
                this.validation.results[index].start = isNotEmpty(item.start) && isOfType(Number(item.start), 'number');
                this.validation.results[index].end = isNotEmpty(item.end) && isOfType(Number(item.end), 'number');
            }
        }

        return (
            validateState(this.validation) && //
            this.validation.questions.every((x) => x.valid) &&
            this.validation.results.every(validateState)
        );
    }

    private checkIfHasSettings = (item: MedicalQuestionRegistration): void => {
        if (isNotDefined(item.attributes)) item.attributes = {};

        item.attributes.hasVisibilityRequirements = this.questionnaire.flow.visibilityRequirements?.filter((x) => x.id === item.id).any() || false;
        item.attributes.isRequired = this.questionnaire.flow.required?.filter((x) => x.key === item.id).any() || false;
    };
}
