import { MedicalEquipment, MedicalEquipmentEntityReference, MedicalEquipmentRegistration } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';

@containerless
export class ComponentMedicalEquipmentsSelector {
    @bindable() public registrations: MedicalEquipmentRegistration[];
    @bindable() public validation: any[];
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public attached(): void {
        if (this.registrations.empty()) this.addItem();
    }

    public addItem(): void {
        this.registrations.push(
            new MedicalEquipmentRegistration({
                comments: {}
            })
        );

        this.validation.push({
            valid: true,
            equipment: true
        });
    }

    public deleteItem(index: number): void {
        this.registrations.splice(index, 1);
        this.validation.splice(index, 1);
    }

    public handleItemSelected = async (equipment: MedicalEquipment, index: number): Promise<void> => {
        this.registrations[index].equipment = new MedicalEquipmentEntityReference({
            id: equipment.id,
            translations: equipment.translations
        });
    };

    public manageTranslationsFor(index: number): void {
        this.manageTranslations(
            this.registrations[index].comments, //
            (translations: any) => (this.registrations[index].comments = translations),
            'textarea',
            false
        );
    }
}
