import { TemplateActionStep } from '../partials/partial-medical-examinations/templates/template-action/template-action-step/template-action-step';
import { TemplateTherapyStep as ProtocolTemplateTherapyStep } from '../partials/partial-medical-treatment-protocols/partial-medical-treatment-protocols-default-values/templates/template-therapy-step/template-therapy-step';

export interface IStepState {
    stepId: string;
    expanded: boolean;
    answered?: boolean;
    isNew?: boolean;
    observer?: ResizeObserver;
    other?: any;
    model?: ProtocolTemplateTherapyStep | TemplateActionStep;
    level?: {
        stepId: string;
        copiedFrom: string;
        value: number;
        container: string;
    };
}

export class StepState {
    public stepId: string;
    public expanded: boolean;
    public answered: boolean;
    public isNew: boolean;
    public observer: ResizeObserver;
    public other: any;
    public model: ProtocolTemplateTherapyStep | TemplateActionStep;
    public level?: {
        stepId: string;
        copiedFrom: string;
        value: number;
        container: string;
    };

    public constructor(data: IStepState) {
        this.stepId = data.stepId;
        this.expanded = data.expanded;
        this.isNew = data.isNew;
        this.answered = data.answered;
        this.observer = data.observer;
        this.other = data.other ?? {};
        this.model = data.model;
        this.level = data.level;
    }
}
