import { SortDirection } from '@wecore/sdk-healthcare';
import { isFunction } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';

@containerless
export class TemplateTaskListSort {
    @bindable() public field: string;
    @bindable() public activeField: string;
    @bindable() public direction: SortDirection;
    @bindable() public onClick: (field: string) => void;

    public SortDirection: typeof SortDirection = SortDirection;

    public handleClick() {
        if (isFunction(this.onClick)) this.onClick(this.field);
    }
}
