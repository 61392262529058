import { guid, isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';

@inject(Element, IEventAggregator)
export class UxRadio {
    @bindable() public value: string;
    @bindable() public beforeChange: () => Promise<boolean>;

    public name: string;
    public disabled: boolean;
    public model: any;
    public checked: boolean;
    public index: number;
    public last: boolean;
    public alignment: string;
    public color: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info';
    public padding: string = 'px-4 py-2.5';
    public rounded: 'all' | 'first-last' = 'first-last';
    public input: HTMLInputElement;

    private parent: string;
    private subscriptions: IDisposable[];
    private id: string = guid();
    private observer: MutationObserver;

    public constructor(
        private readonly host: HTMLElement, //
        private readonly events: IEventAggregator
    ) {}

    public bound(): void {
        setTimeout(() => {
            this.name = this.host.getAttribute('name');
            this.input.name = this.name;
            this.parent = this.host.getAttribute('parent');
            this.index = Number(this.host.getAttribute('index'));
            this.rounded = this.host.getAttribute('rounded') as 'all' | 'first-last';
            this.last = this.host.getAttribute('last') === 'true';
            this.checked = this.host.getAttribute('value') === this.value;

            this.padding = this.host.getAttribute('padding');
            this.color = this.host.getAttribute('color') as 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info';
            this.alignment = this.host.getAttribute('alignment');

            this.input.checked = this.checked;
        });

        // When the select is opened and the user is filtering the options,
        // the attribute 'hide' is added to the options that do not match the filter.
        // This observer is responsible for updating the 'hide' property of the option.
        this.observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type !== 'attributes' || isNotDefined(this.host)) return;
                if (mutation.attributeName === 'name') {
                    this.name = this.host.getAttribute('name');
                    this.input.name = this.name;
                }
                if (mutation.attributeName === 'parent') this.parent = this.host.getAttribute('parent');
                if (mutation.attributeName === 'index') this.index = Number(this.host.getAttribute('index'));
                if (mutation.attributeName === 'rounded') this.rounded = this.host.getAttribute('rounded') as 'all' | 'first-last';
                if (mutation.attributeName === 'last') this.last = this.host.getAttribute('last') === 'true';
                if (mutation.attributeName === 'value') {
                    this.checked = this.host.getAttribute('value') === this.value;
                    this.input.checked = this.checked;
                }
                if (mutation.attributeName === 'padding') this.padding = this.host.getAttribute('padding');
                if (mutation.attributeName === 'color') this.color = this.host.getAttribute('color') as 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info';
                if (mutation.attributeName === 'alignment') this.alignment = this.host.getAttribute('alignment');
            }
        });
        this.observer.observe(this.host, { attributes: true });

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(UxEvents.UxRadioChanged, (data: { id: string; parent: string }) => {
                if (data.id === this.id) return;
                if (data.parent !== this.parent) return;

                this.checked = false;
            })
        ];
    }

    public detaching(): void {
        this.observer.disconnect();
        this.subscriptions.forEach((s) => s.dispose());
    }

    public async handleChange(e: Event): Promise<void> {
        this.checked = this.input.checked;

        let proceed: boolean = true;

        if (isDefined(this.beforeChange) && !this.checked) {
            e.preventDefault();
            proceed = await this.beforeChange();
        }

        if (proceed) {
            this.events.publish(UxEvents.UxRadioChanged, { id: this.id, parent: this.parent, value: this.value });
        }
    }
}
