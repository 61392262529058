import { GetMedicalRecordRegistrationResponse, MedicalRecordRegistrationEntityReference } from '@wecore/sdk-healthcare';

export class SortTherapyPlansOrExecutionsValueConverter {
    public toView(
        plans: MedicalRecordRegistrationEntityReference[],
        registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
        type: 'therapyPlan' | 'therapyExecution' = 'therapyPlan'
    ): Map<string, string[]> {
        // Group the data by "therapy" first, then by "results"
        const grouped = new Map();

        plans
            .map((x) => x.id)
            .forEach((id) => {
                const therapy = registrations[id].therapy.id;
                const results = registrations[id][type].results.map((x) => x.resultId).join('/');

                if (!grouped.has(therapy)) grouped.set(therapy, new Map());
                if (!grouped.get(therapy).has(results)) grouped.get(therapy).set(results, []);

                grouped.get(therapy).get(results).push(id);
                grouped
                    .get(therapy)
                    .get(results)
                    .sort((a: string, b: string) => (registrations[b].createdAt as any) - (registrations[a].createdAt as any));
            });

        return grouped;
    }
}
