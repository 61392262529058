import { DayOfWeek } from '@wecore/sdk-healthcare';

export class DayToStringValueConverter {
    public toView(day: DayOfWeek): string {
        switch (day) {
            case DayOfWeek.Monday:
                return 'translation:global.days.long.monday';
            case DayOfWeek.Tuesday:
                return 'translation:global.days.long.tuesday';
            case DayOfWeek.Wednesday:
                return 'translation:global.days.long.wednesday';
            case DayOfWeek.Thursday:
                return 'translation:global.days.long.thursday';
            case DayOfWeek.Friday:
                return 'translation:global.days.long.friday';
            case DayOfWeek.Saturday:
                return 'translation:global.days.long.saturday';
            case DayOfWeek.Sunday:
                return 'translation:global.days.long.sunday';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
