import { IDialogController } from '@aurelia/dialog';
import { isFunction } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { ConfirmationOptions } from '../../models/confirmation-options';

@inject(IDialogController, ErrorHandler)
export class ModalConfirm {
    public options: ConfirmationOptions;
    public isLoading: boolean = false;

    public constructor(
        private readonly controller: IDialogController, //
        private readonly errorHandler: ErrorHandler
    ) {}

    public activate(params: ConfirmationOptions): void {
        this.options = params;
    }

    public async ok(): Promise<void> {
        if (this.options.showLoading) this.isLoading = true;
        try {
            if (isFunction(this.options.callback)) await this.options.callback(true, null);
            await this.controller.ok();
        } catch (e) {
            await this.errorHandler.handle(`[confirm/callback] ${this.options.title}`, e);
            if (this.options.showLoading) this.isLoading = false;

            throw e;
        }
    }

    public async cancel(): Promise<void> {
        if (isFunction(this.options.callback)) await this.options.callback(false, null);
        await this.controller.cancel();
    }
}
