<button
    bg-primary.class="field === activeField && direction"
    text-white.class="field === activeField && direction"
    text-black.class="field !== activeField || !direction"
    bg-gray-200.class="field !== activeField || !direction"
    hidden.class="field !== activeField || !direction"
    rotate-180.class="field === activeField && direction === SortDirection.Desc"
    click.trigger="handleClick()"
    style="left: calc(50% - 11px)"
    class="absolute top-[-11px] z-50 flex h-[22px] w-[22px] flex-col items-center rounded-full p-1 transition-all duration-200 group-hover:flex">
    <div class="flex flex-col">
        <!-- Closed -->
        <svg class="relative top-[-3px] h-[14px] w-[14px]" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
            <path fill="currentColor" d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
        </svg>

        <template if.bind="field !== activeField || !direction">
            <svg class="relative top-[-11px] h-[14px] w-[14px]" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path fill="currentColor" d="M320 240L160 384 0 240l0-48 320 0 0 48z" />
            </svg>
        </template>
        <!-- Open -->
        <template if.bind="field === activeField && direction">
            <svg class="relative top-[-11px] h-[14px] w-[14px]" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path
                    fill="currentColor"
                    d="M160 329.4L249.4 240 70.6 240 160 329.4zm22.6 45.3c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z" />
            </svg>
        </template>
    </div>
</button>
