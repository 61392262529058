import { isNotDefined } from '@wecore/sdk-utilities';

export class FileToIconValueConverter {
    public toView(filename: string, size: string = 'w-7 h-7'): string {
        if (isNotDefined(filename)) return 'file-exclamation';
        const extension = filename.split('.').pop().toLowerCase();
        switch (extension) {
            case 'doc':
            case 'docx':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor"  d="M177.1 250.1l-44 110L111.7 252.9c-1.719-8.656-10.11-14.33-18.83-12.55C84.2 242 78.58 250.5 80.31 259.1l32 160c1.391 6.938 7.172 12.14 14.22 12.8c7.25 .6562 13.7-3.422 16.33-10L192 299.1l49.14 122.9C243.6 428.1 249.5 432 256 432c.4844 0 .9687-.0156 1.469-.0625c7.047-.6562 12.83-5.859 14.22-12.8l32-160c1.734-8.672-3.891-17.09-12.55-18.83c-8.781-1.812-17.11 3.875-18.83 12.55l-21.45 107.2l-44-110C201.1 237.9 182 237.9 177.1 250.1zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448z"/>
                        </svg>`;
            case 'xls':
            case 'xlsx':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM192 308.1L149.1 246.8c-5.062-7.25-15.06-9-22.28-3.938c-7.25 5.062-9 15.05-3.938 22.28L172.5 336L122.9 406.8c-5.062 7.234-3.312 17.22 3.938 22.28C129.6 431.1 132.8 432 135.1 432c5.047 0 10.02-2.375 13.12-6.828L192 363.9l42.89 61.27C237.1 429.6 242.1 432 248 432c3.172 0 6.375-.9375 9.156-2.891c7.25-5.062 9-15.05 3.938-22.28L211.5 336l49.58-70.83c5.062-7.234 3.312-17.22-3.938-22.28s-17.22-3.312-22.28 3.938L192 308.1z"/>
                        </svg>`;
            case 'ppt':
            case 'pptx':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM128 240C119.2 240 112 247.2 112 256v176C112 440.8 119.2 448 128 448s16-7.156 16-16V384h56c39.69 0 72-32.3 72-72S239.7 240 200 240H128zM240 312c0 22.06-17.94 40-40 40H144V272h56C222.1 272 240 289.9 240 312z"/>
                        </svg>`;
            case 'xml':
            case 'ts':
            case 'html':
            case 'css':
            case 'scss':
            case 'less':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M155.3 276.7c-6.25-6.25-16.38-6.25-22.62 0l-48 48c-6.25 6.25-6.25 16.38 0 22.62l48 48C135.8 398.4 139.9 400 144 400s8.188-1.562 11.31-4.688c6.25-6.25 6.25-16.38 0-22.62L118.6 336l36.69-36.69C161.6 293.1 161.6 282.9 155.3 276.7zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.66 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.35 0 64-28.66 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.562 8.666 3.844 12.12 7.297l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM228.7 299.3L265.4 336l-36.69 36.69c-6.25 6.25-6.25 16.38 0 22.62C231.8 398.4 235.9 400 240 400s8.188-1.562 11.31-4.688l48-48c6.25-6.25 6.25-16.38 0-22.62l-48-48c-6.25-6.25-16.38-6.25-22.62 0S222.4 293.1 228.7 299.3z"/>
                        </svg>`;
            case 'csv':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M384 448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448H32C32 465.7 46.33 480 64 480H320C337.7 480 352 465.7 352 448H384zM240 192C213.5 192 192 170.5 192 144V32H64C46.33 32 32 46.33 32 64V224H0V64C0 28.65 28.65 0 64 0H220.1C232.8 0 245.1 5.057 254.1 14.06L369.9 129.9C378.9 138.9 384 151.2 384 163.9V224H352V192H240zM347.3 152.6L231.4 36.69C229.4 34.62 226.8 33.18 224 32.48V144C224 152.8 231.2 160 240 160H351.5C350.8 157.2 349.4 154.6 347.3 152.6zM96 256C118.1 256 136 273.9 136 296V304C136 312.8 128.8 320 120 320C111.2 320 104 312.8 104 304V296C104 291.6 100.4 288 96 288H80C75.58 288 72 291.6 72 296V376C72 380.4 75.58 384 80 384H96C100.4 384 104 380.4 104 376V368C104 359.2 111.2 352 120 352C128.8 352 136 359.2 136 368V376C136 398.1 118.1 416 96 416H80C57.91 416 40 398.1 40 376V296C40 273.9 57.91 256 80 256H96zM152 302.4C152 276.8 172.8 256 198.4 256H216C224.8 256 232 263.2 232 272C232 280.8 224.8 288 216 288H198.4C190.4 288 184 294.4 184 302.4C184 307.5 186.8 312.3 191.2 314.9L216.6 329.4C231.1 337.6 240 352.1 240 369.6C240 395.2 219.2 416 193.6 416H168C159.2 416 152 408.8 152 400C152 391.2 159.2 384 168 384H193.6C201.6 384 208 377.6 208 369.6C208 364.5 205.2 359.7 200.8 357.1L175.4 342.6C160.9 334.4 152 319 152 302.4zM280 303.6C280 326.6 285.5 349.2 296 369.5C306.5 349.2 312 326.6 312 303.6V272C312 263.2 319.2 256 328 256C336.8 256 344 263.2 344 272V303.6C344 338.3 333.7 372.3 314.4 401.2L309.3 408.9C306.3 413.3 301.4 416 296 416C290.6 416 285.7 413.3 282.7 408.9L277.6 401.2C258.3 372.3 248 338.3 248 303.6V272C248 263.2 255.2 256 264 256C272.8 256 280 263.2 280 272V303.6z"/>
                        </svg>`;
            case 'txt':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64v-277.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM96 272C96 280.8 103.2 288 112 288h160C280.8 288 288 280.8 288 272S280.8 256 272 256h-160C103.2 256 96 263.2 96 272zM272 320h-160C103.2 320 96 327.2 96 336S103.2 352 112 352h160c8.838 0 16-7.164 16-16S280.8 320 272 320zM272 384h-160C103.2 384 96 391.2 96 400S103.2 416 112 416h160c8.838 0 16-7.164 16-16S280.8 384 272 384z"/>
                        </svg>`;
            case 'png':
            case 'jpeg':
            case 'jpg':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M190.3 285.7l-26.36 40.67c-12-14.92-37.75-13.73-48.31 2.531l-46.69 72.02c-5.984 9.266-6.531 21.09-1.453 30.84C72.67 441.8 82.83 448 93.1 448h196c11.17 0 21.33-6.219 26.55-16.23c5.094-9.797 4.531-21.62-1.484-30.86l-74.66-115.2C229.3 268.5 201.4 268.5 190.3 285.7zM286.7 416L95.77 416l44.89-66.95l9.922 15.3c5.906 9.094 20.97 9.094 26.84 0l37.91-58.48L286.7 416zM96 280c13.25 0 24-10.75 24-24c0-13.26-10.75-24-24-24S72 242.7 72 256C72 269.3 82.75 280 96 280zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448z"/>
                        </svg>`;
            case 'zip':
            case 'rar':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64v-277.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM144 128h-64C71.16 128 64 135.2 64 144S71.16 160 80 160h64C152.8 160 160 152.8 160 144S152.8 128 144 128zM144 64h-64C71.16 64 64 71.16 64 80S71.16 96 80 96h64C152.8 96 160 88.84 160 80S152.8 64 144 64zM144 192h-64C71.16 192 64 199.2 64 208S71.16 224 80 224h64C152.8 224 160 216.8 160 208S152.8 192 144 192zM144 256h-64C71.16 256 64 263.2 64 272V320c0 26.47 21.53 48 48 48S160 346.5 160 320V272C160 263.2 152.8 256 144 256zM128 320c0 8.828-7.188 16-16 16S96 328.8 96 320V288h32V320z"/>
                        </svg>`;
            case 'mp4':
            case 'wmv':
            case 'mov':
            case 'qt':
            case 'webm':
            case 'mkv':
            case 'gif':
            case 'avi':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M379.1 146c-3.186-7.688-7.871-14.76-13.87-20.76l-106.5-106.5c-6-6.004-13.07-10.69-20.76-13.88C230.3 1.688 221.1 0 213.5 0H64C46.33 0 30.33 7.164 18.75 18.75S0 46.33 0 64l.0065 384c0 17.67 7.158 33.67 18.74 45.25S46.33 512 64.01 512H320c17.67 0 33.67-7.164 45.26-18.75S384 465.7 384 448V170.5C384 162 382.3 153.7 379.1 146zM224 34.08c2.238 .7813 4.404 1.742 6.443 2.938c2.039 1.199 3.949 2.633 5.676 4.359l106.5 106.5c1.727 1.727 3.16 3.637 4.355 5.676C348.2 155.6 349.1 157.8 349.9 160H240c-4.406 0-8.406-1.793-11.3-4.688C225.8 152.4 224 148.4 224 144V34.08zM352 448c0 8.82-3.588 16.82-9.383 22.62C336.8 476.4 328.8 480 320 480H64c-8.822 0-16.82-3.59-22.62-9.383C35.59 464.8 32 456.8 32 448V64c0-8.82 3.59-16.82 9.383-22.62C47.18 35.59 55.18 32 64 32h128v112c0 13.23 5.383 25.23 14.07 33.93S226.8 192 240 192H352V448zM230.6 265.4C224.8 259.6 216.8 256 208 256H96C87.16 256 79.16 259.6 73.37 265.4C67.58 271.2 64 279.2 64 288v96c0 8.836 3.582 16.84 9.373 22.62C79.16 412.4 87.16 416 96 416h112c8.836 0 16.84-3.582 22.63-9.375c5.023-5.023 7.902-11.87 8.705-19.32l59.61 19.87C300.6 407.7 302.3 408 304 408c3.312 0 6.594-1.031 9.359-3.016C317.5 401.1 320 397.1 320 392v-112c0-5.141-2.469-9.969-6.641-12.98C309.2 264 303.8 263.2 298.9 264.8L239.3 284.7C238.5 277.2 235.7 270.4 230.6 265.4zM208 384H96V288h112V384zM240 318.2L288 302.2v67.59l-48-15.99V318.2z"/>
                        </svg>`;
            case 'mp3':
            case 'wav':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64v-277.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM182.8 249.5C177.2 246.9 170.5 247.8 165.8 251.7L122.2 288H80C71.16 288 64 295.2 64 304v64C64 376.8 71.16 384 80 384h42.22l43.53 36.3C168.7 422.7 172.3 424 176 424c2.312 0 4.625-.5 6.781-1.516C188.4 419.9 192 414.2 192 408v-144C192 257.8 188.4 252.1 182.8 249.5zM160 373.8l-21.75-18.14C135.4 353.3 131.8 352 128 352H96v-32h32c3.75 0 7.375-1.312 10.25-3.703L160 298.2V373.8zM288 336c0 31.42-15.38 60.86-41.16 78.83c-7.25 5.062-9.031 15.03-3.969 22.28c3.125 4.453 8.105 6.844 13.14 6.844c3.188 0 6.363-.9375 9.145-2.875C299.5 417.1 320 377.9 320 336s-20.51-81.1-54.86-105.1c-7.156-4.984-17.18-3.297-22.27 3.969c-5.062 7.25-3.27 17.22 3.98 22.28C272.6 275.2 288 304.6 288 336zM234.1 287.6c-6.844-5.547-16.91-4.594-22.5 2.266c-5.594 6.844-4.594 16.92 2.25 22.52C220.2 317.6 224 326.4 224 336s-3.781 18.42-10.12 23.61c-6.844 5.594-7.844 15.67-2.25 22.52C214.8 386 219.4 387.8 224 387.8c3.562 0 7.156-1.023 10.12-3.445C247.8 373.2 256 355.1 256 336S247.8 298.8 234.1 287.6z"/>
                        </svg>`;
            case 'pdf':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M384 448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448H32C32 465.7 46.33 480 64 480H320C337.7 480 352 465.7 352 448H384zM240 192C213.5 192 192 170.5 192 144V32H64C46.33 32 32 46.33 32 64V224H0V64C0 28.65 28.65 0 64 0H220.1C232.8 0 245.1 5.057 254.1 14.06L369.9 129.9C378.9 138.9 384 151.2 384 163.9V224H352V192H240zM347.3 152.6L231.4 36.69C229.4 34.62 226.8 33.18 224 32.48V144C224 152.8 231.2 160 240 160H351.5C350.8 157.2 349.4 154.6 347.3 152.6zM88 256C118.9 256 144 281.1 144 312C144 342.9 118.9 368 88 368H80V400C80 408.8 72.84 416 64 416C55.16 416 48 408.8 48 400V272C48 263.2 55.16 256 64 256H88zM112 312C112 298.7 101.3 288 88 288H80V336H88C101.3 336 112 325.3 112 312zM160 272C160 263.2 167.2 256 176 256H200C226.5 256 248 277.5 248 304V368C248 394.5 226.5 416 200 416H176C167.2 416 160 408.8 160 400V272zM192 384H200C208.8 384 216 376.8 216 368V304C216 295.2 208.8 288 200 288H192V384zM336 256C344.8 256 352 263.2 352 272C352 280.8 344.8 288 336 288H304V320H336C344.8 320 352 327.2 352 336C352 344.8 344.8 352 336 352H304V400C304 408.8 296.8 416 288 416C279.2 416 272 408.8 272 400V272C272 263.2 279.2 256 288 256H336z"/>
                        </svg>`;
            case 'json':
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 384 512">
                            <path fill="currentColor" d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zm91.3 299.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-48 48c-6.2 6.2-6.2 16.4 0 22.6l48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L118.6 336l36.7-36.7zm144 25.4l-48-48c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L265.4 336l-36.7 36.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l48-48c6.2-6.2 6.2-16.4 0-22.6z"/>
                        </svg>`;
            default:
                return `<svg class="${size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM192 392c-13.25 0-24 10.75-24 24s10.75 24 24 24c13.26 0 24-10.75 24-24S205.3 392 192 392zM191.1 368C200.8 368 208 360.8 208 352V240C208 231.2 200.8 224 192 224S176 231.2 176 240V352C176 360.8 183.1 368 191.1 368z"/>
                        </svg>`;
        }
    }
}
