export interface ISelectedFile {
    id: string;
    step: string;
    type: 'referral' | 'step' | 'widget';
    file: any;
    progress: number;
    loader: any;
    statusLabel: string;
    extension: string;
    name: string;
    registration: string;
    isLoading: boolean;
}

export class SelectedFile {
    public id: string;
    public step: string;
    public registration: string;
    public type: 'referral' | 'step' | 'widget';
    public file: any;
    public progress: number;
    public loader: any;
    public status: 'waiting' | 'done' | 'failed';
    public statusLabel: string;
    public extension: string;
    public name: string;
    public isLoading: boolean = false;

    public constructor(data: ISelectedFile) {
        this.id = data?.id;
        this.registration = data?.registration;
        this.isLoading = data?.isLoading;
        this.step = data?.step;
        this.type = data?.type;
        this.file = data?.file;
        this.progress = data?.progress;
        this.loader = data?.loader;
        this.status = 'waiting';
        this.statusLabel = data?.statusLabel;
        this.extension = data?.extension;
        this.name = data?.name;
    }
}
