<import from="converters/translate" />

<div class="${columns} flex flex-col">
    <div class="flex items-center justify-between rounded-lg bg-gray-100 px-3 py-1.5 text-sm font-medium text-gray-800">
        <div class="flex min-w-0 items-center gap-x-3">
            <span class="text-gray-500" t="translation:global.labels.step"></span>
            <span class="block truncate">${ item.step.question | translate:language }</span>
        </div>
    </div>
</div>
