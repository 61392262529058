import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    Link,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { cloneDeep } from '../../../../../infra/utilities';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
@inject(I18N)
export class WidgetPatientLinks {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public descriptions: { [key: string]: string }[];

    public constructor(
        public t: I18N //
    ) {
        this.descriptions = [
            {
                nl: t.tr('translation:global.labels.website', { lng: 'nl' }),
                en: t.tr('translation:global.labels.website', { lng: 'en' })
            },
            {
                nl: 'Twitter',
                en: 'Twitter'
            },
            {
                nl: 'LinkedIn',
                en: 'LinkedIn'
            },
            {
                nl: 'Facebook',
                en: 'Facebook'
            },
            {
                nl: 'Instagram',
                en: 'Instagram'
            },
            {
                nl: t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];
    }

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        this.validation.values = this.patient.links.map(() => ({
            valid: true,
            email: true,
            phone: true
        }));

        // Combine default descriptions with custom descriptions
        // that are used by the user.
        const descriptions = [
            ...this.descriptions, //
            ...this.patient.links.map((x) => x.translations)
        ] // Filter out the custom option
            .filter((x) => x[this.language] !== this.t.tr('translation:global.labels.custom', { lng: this.language }))
            .orderBy((x) => x[this.language]);

        // Re-add the custom option on the end and make
        // sure there aren't any duplicates.
        this.descriptions = [
            ...descriptions,
            {
                nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), //
                en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ].distinct((x) => x[this.language]);

        // Make sure every phone number has a default calling code.
        this.registration.widget.result.value = cloneDeep(this.patient.links);

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Array;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientWebLinks,
                onSave: async (): Promise<void> => {
                    if (isNotDefined(this.registration.widget.result.value)) return;

                    // Filter out the links that don't have a value entered.
                    this.registration.widget.result.value = this.registration.widget.result.value
                        .filter((link: Link) => {
                            return isDefined(link.value) && isNotEmpty(link.value);
                        })
                        .map(Link.fromJS);
                    // Save the links to the patient and
                    // let all listeners know the patient has been updated.
                    this.patient.links = this.registration.widget.result.value;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
