<import from="converters/date" />
<import from="converters/translate" />

<div css="min-width: ${settings.minColumnWidth}px;" class="flex-1 items-center py-2 pr-[8px]">
    <div class="flex min-w-0 flex-1 flex-col">
        <p if.bind="user && !forceDates" class="text-sm font-semibold text-gray-800">${user.displayName}</p>
        <div if.bind="wrapper && !forceDates" class="flex min-w-0 flex-col">
            <p class="truncate text-sm font-semibold text-gray-800">${wrapper.room.name | translate:language}</p>
            <p if.bind="wrapper.location.applicationName" class="truncate text-xs text-gray-500">${wrapper.location.applicationName | translate:language}</p>
            <p else class="truncate text-xs text-gray-500">${wrapper.location.name | translate:language}</p>
        </div>

        <div if.bind="forceDates || (!user && !wrapper)" class.bind="isToday? 'text-primary' : 'text-gray-500'" class="flex gap-2">
            <p class="text-sm capitalize">${date | date:'EEEEEE'}</p>
            <p class="text-sm font-bold capitalize">${date | date:'dd'}</p>
        </div>
    </div>
</div>
