export interface IKeyValuePair {
    key: string;
    value: string;
}

export class KeyValuePair {
    public key: string;
    public value: string;

    public constructor(data: IKeyValuePair) {
        this.key = data?.key;
        this.value = data?.value;
    }
}
