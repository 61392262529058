import { I18N } from '@aurelia/i18n';
import { GetAccountResponse } from '@wecore/sdk-crm';
import { bindable, containerless, inject } from 'aurelia';
import { checkForCustomDescriptions } from '../../../../infra/utilities';

@containerless
@inject(I18N)
export class TabAccountsGeneral {
    @bindable() public account: GetAccountResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
    @bindable() public lockType: boolean;

    public emailDescriptions: { [key: string]: string }[];
    public linkDescriptions: { [key: string]: string }[];
    public phoneDescriptions: { [key: string]: string }[];
    public baseLoaded: boolean = false;

    public constructor(
        public t: I18N //
    ) {
        this.emailDescriptions = [
            { nl: t.tr('translation:global.labels.work', { lng: 'nl' }), en: t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.private', { lng: 'nl' }), en: t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.linkDescriptions = [
            { nl: t.tr('translation:global.labels.website', { lng: 'nl' }), en: t.tr('translation:global.labels.website', { lng: 'en' }) },
            { nl: 'Twitter', en: 'Twitter' },
            { nl: 'LinkedIn', en: 'LinkedIn' },
            { nl: 'Facebook', en: 'Facebook' },
            { nl: 'Instagram', en: 'Instagram' },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.phoneDescriptions = [
            { nl: t.tr('translation:global.labels.work', { lng: 'nl' }), en: t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.private', { lng: 'nl' }), en: t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.mobile', { lng: 'nl' }), en: t.tr('translation:global.labels.mobile', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];
    }

    public attached(): void {
        this.emailDescriptions = checkForCustomDescriptions(this.t, this.language, this.emailDescriptions, this.account, 'email');
        this.phoneDescriptions = checkForCustomDescriptions(this.t, this.language, this.phoneDescriptions, this.account, 'phone');
        this.linkDescriptions = checkForCustomDescriptions(this.t, this.language, this.linkDescriptions, this.account, 'link');

        this.baseLoaded = true;
    }
}
