<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header padding="py-5 px-8">
            <p class="font-semibold leading-tight" t="translation:partial-views.practice-settings.labels.title"></p>
            <span class="text-sm leading-tight text-gray-500" t="translation:partial-views.practice-settings.messages.select-settings"></span>
        </ux-header>
        <div class="flex flex-col w-full h-full px-6 pb-6 overflow-y-auto">
            <ul if.bind="baseLoaded" class="flex flex-col flex-1 gap-1 pt-6">
                <li class="flex w-full group" repeat.for="setting of settings" if.bind="setting.roles.some((r) => hasRole(r))">
                    <a
                        href="javascript:"
                        bg-gray-100.class="setting.view.name === active.name"
                        click.trigger="changeToView(setting.view)"
                        class="flex items-center justify-between flex-1 px-3 py-2 rounded-xl group-hover:bg-gray-100">
                        <div class="flex items-center gap-3 text-gray-700">
                            <span class="${setting.view.name === active.name ? 'text-primary' : ''} flex w-8 items-center justify-center" innerhtml.bind="setting.icon"></span>
                            <p class="text-sm" t.bind="setting.title"></p>
                        </div>
                        <svg
                            if.bind="setting.view.name === active.name"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-gray-500 group-hover:text-black">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>
