<section class="flex flex-col">
    <div class="flex items-center justify-between" class="mb-1">
        <div class="flex items-center space-x-1">
            <ux-label>${ label }</ux-label>
            <span if.bind="required" class="flex h-3 items-center text-red-500">*</span>
        </div>
        <ux-button click.trigger="add()" padding.bind="false" size="xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </ux-button>
    </div>
    <div if.bind="labels.length > 0" class="mt-2 flex w-full flex-col gap-1">
        <div class="flex flex-col" repeat.for="label of labels">
            <div class="flex gap-1">
                <div class="flex flex-1 items-center gap-1">
                    <div class="flex w-32 flex-col">
                        <ux-textarea
                            rows="1"
                            valid.bind="validation[$index].value && validation[$index].valueValid && validation[$index].unique"
                            type="text"
                            value.two-way="label.sliderValues"
                            t="[placeholder]translation:global.labels.value">
                        </ux-textarea>
                    </div>
                    <div class="flex flex-1 flex-col">
                        <ux-textarea
                            action.bind="c => manageTranslationsFor($index, true)"
                            rows="1"
                            value.two-way="label.value[language]"
                            rounded="left"
                            valid.bind="validation[$index].label"
                            t="[placeholder]translation:components.component-slider-labels-selector.placeholders.enter-label">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                            </svg>
                        </ux-textarea>
                    </div>
                </div>
                <div class="col-span-1 flex">
                    <button
                        class.bind="validation[$index].label ? 'border-gray-300' : 'border-red-500'"
                        class="relative -left-[1px] inline-flex items-center rounded-r-lg border bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                        click.trigger="remove($index)">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <p if.bind="!validation[$index].valueValid" class="text-xs text-red-500" t="translation:components.component-slider-labels-selector.messages.invalid-label-value"></p>
            <p if.bind="!validation[$index].unique" class="text-xs text-red-500" t="translation:components.component-slider-labels-selector.messages.invalid-label-value-unique"></p>
        </div>
    </div>
</section>
