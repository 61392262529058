import { MedicalHistoryItem, MedicalHistoryItemTypes } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';

export class SortMedicalHistoryValueConverter {
    public toView(items: MedicalHistoryItem[], type: MedicalHistoryItemTypes, language: string): MedicalHistoryItem[] {
        if (isNotDefined(items)) return [];
        return (
            items
                .filter((x) => x.type === type)
                // This has the following sorting rules:
                // 1. Sort by year, newest first.
                // 2. If years are the same, sort by month, newest first.
                // 3. If months are the same, sort alphabetically by name.
                // 4. If years and months are both null, sort alphabetically by name.
                // 5. If years are null, sort after years that are not null.
                // 6. If months are null, sort after months that are not null.
                // 7. If month is not null and year is null, sort before months that are null.
                .sort((a, b) => {
                    if (a.year === null && b.year === null) {
                        // Compare by month next, and sort alphabetically by name if months are the same
                        if (a.month === null && b.month === null) {
                            if (a.name[language] < b.name[language]) return -1;
                            if (a.name[language] > b.name[language]) return 1;
                            return 0;
                        } else if (a.month === null) return 1; // A has no month, so sort it after B
                        else if (b.month === null) return -1; // B has no month, so sort it after A
                        else {
                            // Sort by month, newest first
                            if (Number(a.month) > Number(b.month)) return -1;
                            if (Number(a.month) < Number(b.month)) return 1;
                            // Months are the same, so compare names
                            if (a.name[language] < b.name[language]) return -1;
                            if (a.name[language] > b.name[language]) return 1;
                            return 0;
                        }
                    } else if (a.year === null) return 1; // A has no year, so sort it after B
                    else if (b.year === null) return -1; // B has no year, so sort it after A
                    else {
                        // Sort by year, newest first
                        if (a.year > b.year) return -1;
                        if (a.year < b.year) return 1;
                        // Years are the same, so compare months next
                        if (a.month === null && b.month === null) {
                            if (a.name[language] < b.name[language]) return -1;
                            if (a.name[language] > b.name[language]) return 1;
                            return 0;
                        } else if (a.month === null) {
                            return 1; // A has no month, so sort it after B
                        } else if (b.month === null) {
                            return -1; // B has no month, so sort it after A
                        } else {
                            // Sort by month, newest first
                            if (Number(a.month) > Number(b.month)) return -1;
                            if (Number(a.month) < Number(b.month)) return 1;
                            // Months are the same, so compare names
                            if (a.name[language] < b.name[language]) return -1;
                            if (a.name[language] > b.name[language]) return 1;
                            return 0;
                        }
                    }
                })
        );
    }
}
