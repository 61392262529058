<import from="converters/translate" />
<import from="converters/date" />

<div
    data-order.bind="task.displayOrder"
    data-type="task"
    mousedown.trigger="handleMoveMouseDown($event)"
    ref="container"
    if.bind="baseLoaded && !loading"
    hover:bg-blue-50.class="hasRole(UserRoles.WriteHealthcareTasks)"
    class="draggable-task group relative col-span-12 grid h-[38px] grid-cols-12">
    <div css="background-color: ${list.color}" class="absolute left-0 z-[1] h-[38px] w-[8px]"></div>
    <div class="col-span-6 flex h-[38px] items-center border-b border-r border-gray-300 pl-[8px]">
        <div class="flex items-center justify-center w-10 h-full border-r border-gray-300">
            <button
                if.bind="hasRole(UserRoles.WriteHealthcareTasks) || hasRole(UserRoles.DeleteHealthcareTasks)"
                click.trigger="selectTask()"
                class="flex items-center justify-center w-full h-full min-w-0 gap-3 text-sm focus:outline-none active:bg-blue-100">
                <input type="checkbox" checked.bind="selected.some((x) => x.id === task.id)" />
            </button>
        </div>
        <div class="flex flex-1 h-full min-w-0">
            <template if.bind="hasRole(UserRoles.WriteHealthcareTasks)">
                <button click.trigger="handleEdit()" class="flex items-center justify-start flex-1 w-full h-full min-w-0 gap-3 px-4 text-sm focus:outline-none active:bg-blue-100">
                    <span class="truncate">${task.title | translate:language}</span>
                    <div class="flex gap-1">
                        <div
                            repeat.for="label of task.labels"
                            class="flex flex-nowrap items-center justify-center rounded-full px-2 py-0.5"
                            css="background-color: ${label.backgroundColor}; color: ${label.textColor}">
                            <p class="text-xs whitespace-nowrap">${label.name[language]}</p>
                        </div>
                    </div>
                </button>
            </template>
            <template else>
                <div class="flex items-center justify-start flex-1 w-full h-full min-w-0 gap-3 px-4 text-sm">
                    <span class="truncate">${task.title | translate:language}</span>
                    <div class="flex gap-1">
                        <div
                            repeat.for="label of task.labels"
                            class="flex flex-nowrap items-center justify-center rounded-full px-2 py-0.5"
                            css="background-color: ${label.backgroundColor}; color: ${label.textColor}">
                            <p class="text-xs whitespace-nowrap">${label.name[language]}</p>
                        </div>
                    </div>
                </div>
            </template>

            <button click.trigger="openPosts()" class="flex items-center justify-center w-12 h-full text-sm text-gray-500 border-l border-gray-300 focus:outline-none active:bg-blue-100">
                <svg if.bind="posts.length === 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 18.25C15.866 18.25 19.25 16.1552 19.25 11.5C19.25 6.84483 15.866 4.75 12 4.75C8.13401 4.75 4.75 6.84483 4.75 11.5C4.75 13.2675 5.23783 14.6659 6.05464 15.7206C6.29358 16.0292 6.38851 16.4392 6.2231 16.7926C6.12235 17.0079 6.01633 17.2134 5.90792 17.4082C5.45369 18.2242 6.07951 19.4131 6.99526 19.2297C8.0113 19.0263 9.14752 18.722 10.0954 18.2738C10.2933 18.1803 10.5134 18.1439 10.7305 18.1714C11.145 18.224 11.5695 18.25 12 18.25Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path d="M9.75 12H14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M12 9.75V14.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <div else class="relative flex items-center justify-center w-full h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                    </svg>
                    <div class="absolute flex items-center justify-center w-4 h-4 text-white rounded-full bottom-1 right-1 bg-primary text-xxs">
                        <span>${posts.length}</span>
                    </div>
                </div>
            </button>
        </div>
    </div>
    <div class="col-span-2 flex h-[38px] items-center justify-center border-b border-r border-gray-300 p-2">
        <ux-avatar-group>
            <ux-avatar
                repeat.for="user of task.assignedTo"
                size="xxs"
                name.bind="user.name"
                email.bind="user.data.email"
                color.bind="user.data.avatarColor"
                shade.bind="user.data.avatarShade"></ux-avatar>
        </ux-avatar-group>
    </div>
    <div class="col-span-2 flex h-[38px] items-center border-b border-r border-gray-300 px-2">
        <div if.bind="task.deadline" class="relative flex items-center flex-1 text-sm">
            <div class="absolute left-1">
                <svg if.bind="pointOfTime === 'future'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg if.bind="pointOfTime === 'past'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-red-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
                <svg if.bind="pointOfTime === 'warning'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-yellow-500">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
                <svg if.bind="pointOfTime === 'closed'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <div class="flex justify-center flex-1">
                <span>${ task.deadline | date:'d MMM'}</span>
            </div>
        </div>
    </div>
    <div class="col-span-1 h-[38px] border-b border-r border-gray-300">
        <div if.bind="task.status" css="background-color: ${task.status.backgroundColor}; color: ${task.status.textColor}" class="flex items-center justify-center h-full text-sm">
            <span>${task.status.name | translate:language}</span>
        </div>
        <div else class="flex flex-1 h-full bg-gray-100"></div>
    </div>
    <div class="col-span-1 h-[38px] border-b border-gray-300">
        <div if.bind="task.priority" css="background-color: ${task.priority.backgroundColor}; color: ${task.priority.textColor}" class="flex items-center justify-center h-full text-sm">
            <span>${task.priority.name | translate:language}</span>
        </div>
        <div else class="flex flex-1 h-full bg-gray-100"></div>
    </div>
</div>
<div else class="col-span-12 grid h-[38px] grid-cols-12">
    <div class="relative col-span-6 flex h-[38px] items-center border-b border-r border-gray-300 px-6">
        <div css="background-color: ${list.color}" class="absolute left-0 z-[1] h-[38px] w-[8px]"></div>
        <ux-skeleton show-background="true" type="container">
            <ux-skeleton type="line"></ux-skeleton>
        </ux-skeleton>
    </div>
    <div class="col-span-2 flex h-[38px] items-center justify-center border-b border-r border-gray-300 px-6 py-2">
        <ux-skeleton show-background="true" type="container">
            <ux-skeleton type="line"></ux-skeleton>
        </ux-skeleton>
    </div>
    <div class="col-span-2 flex h-[38px] items-center border-b border-r border-gray-300 px-6">
        <ux-skeleton show-background="true" type="container">
            <ux-skeleton type="line"></ux-skeleton>
        </ux-skeleton>
    </div>
    <div class="col-span-1 flex h-[38px] items-center border-b border-r border-gray-300 px-6">
        <ux-skeleton show-background="true" type="container">
            <ux-skeleton type="line"></ux-skeleton>
        </ux-skeleton>
    </div>
    <div class="col-span-1 flex h-[38px] items-center border-b border-gray-300 px-6">
        <ux-skeleton show-background="true" type="container">
            <ux-skeleton type="line"></ux-skeleton>
        </ux-skeleton>
    </div>
</div>
