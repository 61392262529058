import { isEmpty, isNotDefined, isNotEmpty, isZero } from '@wecore/sdk-utilities';
import { bindable } from 'aurelia';
import md5 from 'md5';

export class UxAvatar {
    @bindable() public name: string;
    @bindable() public email: string;
    @bindable() public url: string;
    @bindable() public border: boolean = false;
    @bindable() public label: string;
    @bindable() public size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'md';
    @bindable() public color: string;
    @bindable() public shade: number;
    @bindable() public bgPosition: string = 'center center';
    @bindable() public bgSize: 'cover' | 'contain' = 'cover';
    @bindable() public bgRepeat: string = 'no-repeat';

    public src: string;
    public type: 'text' | 'image';

    public bound() {
        this.refresh();
    }

    public nameChanged(): void {
        this.refresh();
    }

    public emailChanged(): void {
        this.refresh();
    }

    public urlChanged(): void {
        this.refresh();
    }

    /**
     * Refreshes the avatar.
     */
    public async refresh(): Promise<void> {
        const setInitials = () => {
            this.type = 'text';
            this.src = this.getInititals();
        };

        if (isNotEmpty(this.label)) {
            this.type = 'text';
            return;
        }

        if (isNotEmpty(this.url)) {
            this.src = this.url;
            this.type = 'image';
        } else if (isNotEmpty(this.email)) {
            // Clean up the email address
            const cleaned = this.email.trim().toLowerCase();
            // Create hash from cleaned email address
            const hash = md5(cleaned);
            // Create and return gravatar url
            const avatar = `https://www.gravatar.com/avatar/${hash}.jpg?d=404`;
            const image = new Image();

            image.src = avatar;
            image.onload = () => {
                this.src = avatar;
                this.type = 'image';
            };
            image.onerror = () => setInitials();
        } else if (isNotEmpty(this.name)) setInitials();
    }

    public getBackgroundColor(): string {
        if (isEmpty(this.color) || isNotDefined(this.shade) || isZero(this.shade)) return 'bg-gray-200 border border-solid border-gray-300';
        if (this.color === 'white') return `bg-${this.color}-${this.shade} border border-solid border-gray-300`;

        return `bg-${this.color}-${this.shade}`;
    }

    public getTextColor(): string {
        if (isEmpty(this.color) || isNotDefined(this.shade) || isZero(this.shade)) return 'text-gray-700';

        if (this.shade < 500) return `text-${this.color}-900`;
        else return 'text-white';
    }

    public getInititals(): string {
        this.type = 'text';
        let lastInitial = '';
        let firstInitial = '';

        if (isNotEmpty(this.name)) {
            const filtered = this.name.replace(/[^a-zA-Z ]/gm, '').trim();
            const displayNameSplit = filtered.split(' ');
            firstInitial = displayNameSplit[0].charAt(0);

            if (displayNameSplit.length > 1) lastInitial = displayNameSplit[displayNameSplit.length - 1].charAt(0);
        }

        return `${firstInitial}${lastInitial}`.toUpperCase();
    }
}
