import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient, BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, serveBlob } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { fileIsImage } from '../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { EventDetails } from '../../../../../models/event-details';
import { SelectedFile } from '../../../../../models/selected-file';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { ModalService } from '../../../../../services/service.modals';
import { UxDropzone } from '../../../../../ux/ux-dropzone/ux-dropzone';

@containerless
@inject(I18N, AttachmentsApiClient, ModalService)
export class WidgetZebrisTreadmill {
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public xScrollContainer: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile, attachment: BlobStorageAttachment) => void;

    public uploads: SelectedFile[] = [];
    public attachments: BlobStorageAttachment[] = [];
    public urls: any = {};

    public constructor(
        private readonly t: I18N, //
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly modalService: ModalService
    ) {}

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        if (isNotDefined(this.registration.widget.result.value))
            this.registration.widget.result.value = {
                attachmentIds: []
            };

        const attachments =
            this.registration.widget.result.value.attachmentIds //
                .map((id: string) => this.record.attachments.find((y) => y.id === id))
                .filter((x: BlobStorageAttachment) => isDefined(x)) || [];
        Promise.all(
            attachments.map(async (x: BlobStorageAttachment) => {
                const id = isDefined(x.thumbnail) ? x.thumbnail.id : x.id;
                const url = await this.attachmentsApi.getUrl(this.record.id, id, this.workspace, AttachmentEntities.MedicalRecords);
                this.urls[x.id] = url;
            })
        ).then(() => (this.attachments = attachments));

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Dynamic;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.ZebrisTreadmill,
                onSave: async (): Promise<void> => {},
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (attachment: BlobStorageAttachment): Promise<void> => {
                    this.registration.widget.result.value.attachmentIds.push(attachment.id);
                }
            })
        );
    }

    public handleFileSelected = (e: CustomEvent<EventDetails<UxDropzone, any>>): void => {
        const file = e.detail.values.file;

        const upload = new SelectedFile({
            id: guid(),
            step: this.step.id,
            type: 'referral',
            file,
            progress: 0,
            loader: null,
            statusLabel: this.t.tr('translation:partial-views.clinical-pathways.labels.status-waiting').replace('{entity}', `<span class="font-medium block mx-1">${file.name.toLowerCase()}</span>`),
            extension: `.${file.name.split('.').pop()}`,
            name: file.name,
            registration: this.registration.id,
            isLoading: false
        });

        this.uploads.push(upload);
        this.onFileSelected(upload);
    };

    public handleFilesSelected = (files: File[]): void => {
        for (const file of files) {
            const upload = new SelectedFile({
                id: guid(),
                step: this.step.id,
                type: 'referral',
                file,
                progress: 0,
                loader: null,
                statusLabel: this.t
                    .tr('translation:partial-views.clinical-pathways.labels.status-waiting')
                    .replace('{entity}', `<span class="font-medium block mx-1">${file.name.toLowerCase()}</span>`),
                extension: `.${file.name.split('.').pop()}`,
                name: file.name,
                registration: this.registration.id,
                isLoading: false
            });

            this.uploads.push(upload);
            this.onFileSelected(upload);
        }
    };

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public deleteFromUploads(index: number): void {
        const file = this.uploads[index];

        this.uploads.splice(index, 1);

        this.onFileRemoved(file, null);
    }

    public async deleteAttachment(index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const attachment = this.attachments[index];
                        const i = this.registration.widget.result.value.attachmentIds.findIndex((id: string) => id === attachment.id);
                        this.registration.widget.result.value.attachmentIds.splice(i, 1);

                        this.attachments.splice(index, 1);

                        this.onFileRemoved(null, attachment);
                    }
                }
            })
        );
    }

    public async openMenu(event: MouseEvent, index: number, type: 'attachment' | 'upload'): Promise<void> {
        await this[`${type}Menus`][index].show(event);
    }

    public async open(attachment: BlobStorageAttachment): Promise<void> {
        const url = await this.attachmentsApi.getUrl(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
        window.open(url, '_blank');
    }

    public async download(attachment: BlobStorageAttachment): Promise<void> {
        if (isDefined(attachment)) {
            const blob = await this.attachmentsApi.download(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
        }
    }
}
