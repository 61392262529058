<import from="./ux-spinner.scss" />

<template>
    <svg
        class="${color} stroke-current"
        size-3.class="size === 'xxxs'"
        size-4.class="size === 'xxs'"
        size-5.class="size === 'xs'"
        size-6.class="size === 'sm'"
        size-7.class="size === 'md'"
        size-10.class="size === 'lg'"
        size-14.class="size === 'xl'"
        viewBox="0 0 50 50"
        fill="currentColor">
        <circle stroke="currentColor" class="path ${color}" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
</template>
