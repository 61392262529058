import { isNotDefined } from '@wecore/sdk-utilities';
import numeral from 'numeral';

export class CurrencyValueConverter {
    public toView(value: string | number, symbol: boolean = true): string {
        if (isNotDefined(value)) value = 0;

        value = Number(value);
        numeral.locale('nl');
        if (symbol) return numeral(value).format('$0,0.00');
        else return numeral(value).format('0,0.00');
    }
}
