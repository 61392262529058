import { DialogDefaultConfiguration } from '@aurelia/dialog';
import { I18nConfiguration } from '@aurelia/i18n';
import { RouterConfiguration } from '@aurelia/router';
import { SVGAnalyzer } from '@aurelia/runtime-html';
import { StoreConfiguration } from '@aurelia/store-v1';
import { AttachmentsApiClient, MediaLibraryApiClient } from '@wecore/sdk-attachments';
import {
    ActivitiesApiClient,
    AppSettingsApiClient,
    ApplicationErrorsApiClient,
    AppsApiClient,
    FileTypesApiClient,
    LegalApiClient as LegalApiClientCore,
    RelationsApiClient,
    SearchApiClient,
    TeamsApiClient as TeamsApiClientCore,
    UserPostsApiClient,
    UsersApiClient as UsersApiClientCore,
    WorkspacesApiClient
} from '@wecore/sdk-core';
import { AccountsAndContactsApiClient, AccountsApiClient, ContactTypesApiClient, ContactsApiClient } from '@wecore/sdk-crm';
import {
    AnatomicalRegionsApiClient,
    AppointmentTypesApiClient,
    AppointmentsApiClient,
    ClinicalPathwaysApiClient,
    DeclarationPerformancesApiClient,
    DeclarationStatusLogsApiClient,
    DeclarationsApiClient,
    DifferentialDiagnosesApiClient,
    DifferentialDiagnosisCategoriesApiClient,
    EmailTemplateTokensApiClient,
    EmailTemplatesApiClient,
    EmailsApiClient,
    HealthcareInvoicesApiClient,
    HealthcarePricesApiClient,
    HealthcareTaskLabelsApiClient,
    HealthcareTaskListsApiClient,
    HealthcareTasksApiClient,
    ImportsApiClient,
    InsurersApiClient,
    KeywordsApiClient,
    LabelsApiClient,
    MedicalEquipmentApiClient,
    MedicalExaminationActionCategoriesApiClient,
    MedicalExaminationActionsApiClient,
    MedicalExaminationModelsApiClient,
    MedicalExaminationPhasesApiClient,
    MedicalExaminationsApiClient,
    MedicalHistoryApiClient,
    MedicalQuestionCategoriesApiClient,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient,
    MedicalRecordRegistrationsApiClient,
    MedicalRecordsApiClient,
    MedicalTherapiesApiClient,
    MedicalTreatmentProtocolsApiClient,
    MedicalWidgetsApiClient,
    MedicationsApiClient,
    PatientsApiClient,
    PracticeLocationsApiClient,
    ProfessionsApiClient,
    SchedulesApiClient,
    SportsApiClient,
    VatCategoriesApiClient
} from '@wecore/sdk-healthcare';
import { JamesSoftwareApiClient, KvkApiClient, MoneybirdApiClient, PdokApiClient, VecozoApiClient, VoxelcareApiClient, ZorgmailApiClient } from '@wecore/sdk-integrations';
import {
    InvitationsApiClient,
    LegalApiClient as LegalApiClientManagement,
    WorkspacesApiClient as ManagementWorkspacesApiClient,
    MessagesApiClient,
    PublicUsersApiClient,
    TeamsApiClient as TeamsApiClientManagement,
    UsersApiClient as UsersApiClientManagement
} from '@wecore/sdk-management';
import { validateBrowser } from '@wecore/sdk-utilities';
import Aurelia from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import Fetch from 'i18next-fetch-backend';
import * as numeral from 'numeral';
import { initialState } from './infra/store/state';
import { registerSdk } from './infra/utilities';
import { MyApp } from './my-app';
import { AuthenticationService } from './services/service.authentication';
import { UxAlert } from './ux/ux-alert/ux-alert';
import { UxAnchor } from './ux/ux-anchor/ux-anchor';
import { UxAvatarGroup } from './ux/ux-avatar-group/ux-avatar-group';
import { UxAvatar } from './ux/ux-avatar/ux-avatar';
import { UxBadge } from './ux/ux-badge/ux-badge';
import { UxBarLoader } from './ux/ux-bar-loader/ux-bar-loader';
import { UxBodyDivider } from './ux/ux-body-divider/ux-body-divider';
import { UxButtonGroup } from './ux/ux-button-group/ux-button-group';
import { UxButtonOptions } from './ux/ux-button-options/ux-button-options';
import { UxButton } from './ux/ux-button/ux-button';
import { UxCardFooter } from './ux/ux-card-footer/ux-card-footer';
import { UxCardHeader } from './ux/ux-card-header/ux-card-header';
import { UxCard } from './ux/ux-card/ux-card';
import { UxCheckbox } from './ux/ux-checkbox/ux-checkbox';
import { UxColorPicker } from './ux/ux-color-picker/ux-color-picker';
import { UxComboboxOption } from './ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from './ux/ux-combobox/ux-combobox';
import { UxDatepicker } from './ux/ux-datepicker/ux-datepicker';
import { UxDropdownDivider } from './ux/ux-dropdown-divider/ux-dropdown-divider';
import { UxDropdownHeader } from './ux/ux-dropdown-header/ux-dropdown-header';
import { UxDropdownOption } from './ux/ux-dropdown-option/ux-dropdown-option';
import { UxDropdown } from './ux/ux-dropdown/ux-dropdown';
import { UxDropzone } from './ux/ux-dropzone/ux-dropzone';
import { UxFooter } from './ux/ux-footer/ux-footer';
import { UxHeader } from './ux/ux-header/ux-header';
import { UxHighlight } from './ux/ux-highlight/ux-highlight';
import { UxImage } from './ux/ux-image/ux-image';
import { UxInput } from './ux/ux-input/ux-input';
import { UxLabel } from './ux/ux-label/ux-label';
import { UxMultiSelector } from './ux/ux-multi-selector/ux-multi-selector';
import { UxNavigationItem } from './ux/ux-navigation-item/ux-navigation-item';
import { UxNavigation } from './ux/ux-navigation/ux-navigation';
import { UxPagination } from './ux/ux-pagination/ux-pagination';
import { UxPdfPreview } from './ux/ux-pdf-preview/ux-pdf-preview';
import { UxProgressBar } from './ux/ux-progress-bar/ux-progress-bar';
import { UxRadioGroup } from './ux/ux-radio-group/ux-radio-group';
import { UxRadio } from './ux/ux-radio/ux-radio';
import { UxRangeSlider } from './ux/ux-range-slider/ux-range-slider';
import { UxSelectOption } from './ux/ux-select-option/ux-select-option';
import { UxSelect } from './ux/ux-select/ux-select';
import { UxSkeleton } from './ux/ux-skeleton/ux-skeleton';
import { UxSpinner } from './ux/ux-spinner/ux-spinner';
import { UxTab } from './ux/ux-tab/ux-tab';
import { UxTableBody } from './ux/ux-table-body/ux-table-body';
import { UxTableCell } from './ux/ux-table-cell/ux-table-cell';
import { UxTableHeader } from './ux/ux-table-header/ux-table-header';
import { UxTableRow } from './ux/ux-table-row/ux-table-row';
import { UxTable } from './ux/ux-table/ux-table';
import { UxTabs } from './ux/ux-tabs/ux-tabs';
import { UxTags } from './ux/ux-tags/ux-tags';
import { UxTextarea } from './ux/ux-textarea/ux-textarea';
import { UxToggle } from './ux/ux-toggle/ux-toggle';
import { UxTooltip } from './ux/ux-tooltip/ux-tooltip';
import { UxTopBarItem } from './ux/ux-top-bar-item/ux-top-bar-item';
import { UxTopBarNavItem } from './ux/ux-top-bar-nav-item/ux-top-bar-nav-item';
import { UxTopBar } from './ux/ux-top-bar/ux-top-bar';
import { UxVideo } from './ux/ux-video/ux-video';

// Make sure the user is using a supported browser.
validateBrowser();

const aurelia = Aurelia.register(
    RouterConfiguration.customize({
        useUrlFragmentHash: false,
        useHref: false,
        title: '${componentTitles}${appTitleSeparator}Wezorg'
    })
)
    .register(
        I18nConfiguration.customize((options) => {
            options.initOptions = {
                plugins: [Fetch],
                backend: {
                    loadPath: '/locales/{{lng}}/{{ns}}.json'
                },
                lng: 'nl',
                fallbackLng: 'en',
                debug: false
            };
        })
    )
    .register(SVGAnalyzer)
    .register(
        DialogDefaultConfiguration.customize((settings) => {
            settings.lock = true;
            settings.startingZIndex = 9999;
        })
    )
    .register([
        UxAlert,
        UxAnchor,
        UxAvatar,
        UxAvatarGroup,
        UxBadge,
        UxBarLoader,
        UxBodyDivider,
        UxButton,
        UxButtonGroup,
        UxButtonOptions,
        UxCard,
        UxCardFooter,
        UxCardHeader,
        UxCheckbox,
        UxColorPicker,
        UxCombobox,
        UxComboboxOption,
        UxDropdown,
        UxDropdownDivider,
        UxDropdownHeader,
        UxDropdownOption,
        UxDatepicker,
        UxDropzone,
        UxFooter,
        UxHeader,
        UxHighlight,
        UxImage,
        UxInput,
        UxLabel,
        UxMultiSelector,
        UxNavigation,
        UxNavigationItem,
        UxPagination,
        UxPdfPreview,
        UxProgressBar,
        UxRadio,
        UxRadioGroup,
        UxRangeSlider,
        UxSelect,
        UxSelectOption,
        UxSkeleton,
        UxSpinner,
        UxTabs,
        UxTab,
        UxTags,
        UxTable,
        UxTableBody,
        UxTableCell,
        UxTableHeader,
        UxTableRow,
        UxTextarea,
        UxToggle,
        UxTooltip,
        UxTopBar,
        UxTopBarItem,
        UxTopBarNavItem,
        UxVideo
    ])
    .register(StoreConfiguration.withInitialState(initialState));

const config = aurelia.container.get(AureliaConfiguration);
await config.loadConfig();
const auth = aurelia.container.get(AuthenticationService);

numeral.register('locale', 'nl', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'mln',
        billion: 'mrd',
        trillion: 'bln'
    },
    ordinal: (nbmr) => {
        const remainder = nbmr % 100;
        return (nbmr !== 0 && remainder <= 1) || remainder === 8 || remainder >= 20 ? 'ste' : 'de';
    },
    currency: {
        symbol: '€ '
    }
});

registerSdk(aurelia, config.get('api.core'), '1.0.0', auth, [
    RelationsApiClient, //
    UsersApiClientCore,
    WorkspacesApiClient,
    ActivitiesApiClient,
    AppsApiClient,
    RelationsApiClient,
    SearchApiClient,
    AppSettingsApiClient,
    FileTypesApiClient,
    UserPostsApiClient,
    ApplicationErrorsApiClient,
    TeamsApiClientCore,
    LegalApiClientCore
]);
registerSdk(aurelia, config.get('api.management'), '1.0.0', auth, [
    ManagementWorkspacesApiClient, //
    LegalApiClientManagement,
    UsersApiClientManagement,
    InvitationsApiClient,
    TeamsApiClientManagement,
    PublicUsersApiClient,
    MessagesApiClient
]);
registerSdk(aurelia, config.get('api.crm'), '1.0.0', auth, [
    ContactsApiClient, //
    AccountsApiClient,
    ContactTypesApiClient,
    AccountsAndContactsApiClient
]);
registerSdk(aurelia, config.get('api.integrations'), '1.0.0', auth, [
    VoxelcareApiClient, //
    VecozoApiClient,
    JamesSoftwareApiClient,
    ZorgmailApiClient,
    MoneybirdApiClient,
    PdokApiClient,
    KvkApiClient
]);
registerSdk(aurelia, config.get('api.attachments'), '1.0.0', auth, [
    AttachmentsApiClient, //
    MediaLibraryApiClient
]);
registerSdk(aurelia, config.get('api.healthcare'), '1.0.0', auth, [
    PatientsApiClient, //
    MedicalRecordsApiClient,
    MedicalExaminationsApiClient,
    MedicalExaminationActionsApiClient,
    MedicalExaminationModelsApiClient,
    MedicalExaminationPhasesApiClient,
    MedicalWidgetsApiClient,
    DifferentialDiagnosesApiClient,
    EmailTemplatesApiClient,
    MedicalQuestionsApiClient,
    MedicalQuestionnairesApiClient,
    MedicalTherapiesApiClient,
    MedicalTreatmentProtocolsApiClient,
    DifferentialDiagnosisCategoriesApiClient,
    MedicalExaminationActionCategoriesApiClient,
    MedicalQuestionCategoriesApiClient,
    KeywordsApiClient,
    AnatomicalRegionsApiClient,
    MedicalRecordRegistrationsApiClient,
    MedicalHistoryApiClient,
    MedicationsApiClient,
    SportsApiClient,
    LabelsApiClient,
    MedicalEquipmentApiClient,
    ProfessionsApiClient,
    EmailTemplateTokensApiClient,
    EmailsApiClient,
    AppointmentsApiClient,
    AppointmentTypesApiClient,
    PracticeLocationsApiClient,
    SchedulesApiClient,
    InsurersApiClient,
    HealthcareTasksApiClient,
    HealthcareTaskListsApiClient,
    HealthcareTaskLabelsApiClient,
    HealthcarePricesApiClient,
    VatCategoriesApiClient,
    HealthcareInvoicesApiClient,
    DeclarationsApiClient,
    DeclarationPerformancesApiClient,
    DeclarationStatusLogsApiClient,
    ClinicalPathwaysApiClient,
    ImportsApiClient
]);

aurelia.app(MyApp).start();
