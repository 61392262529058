import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import { GetAuthenticatedResponse } from '@wecore/sdk-core';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    LabelRegistration,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { cleanTranslatables, cloneDeep, setTranslation } from '../../../../../infra/utilities';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
export class WidgetPatientLabels {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public currentUser: GetAuthenticatedResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public options: { [key: string]: string }[];

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        this.registration.widget.result.value = cloneDeep(this.patient.labels || []);

        for (const value of this.registration.widget.result.value) {
            if (isNotDefined(value.comments)) value.comments = setTranslation(value.comments, this.language);
            this.validation.values.push({
                valid: true,
                label: true
            });
        }

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Array;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientLabels,
                onSave: async (): Promise<void> => {
                    for (const value of this.registration.widget.result.value) cleanTranslatables(['comments'], value, 1);

                    this.registration.widget.result.value = this.registration.widget.result.value //
                        .filter((x: LabelRegistration) => isDefined(x.label))
                        .map(LabelRegistration.fromJS);

                    // Save the labels to the patient and
                    // let all listeners know the patient has been updated.
                    this.patient.labels = this.registration.widget.result.value;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
