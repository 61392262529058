import { EmailEntityTypes } from '@wecore/sdk-healthcare';

export class TokenTypeToStringValueConverter {
    public toView(type: EmailEntityTypes): string {
        switch (type) {
            case EmailEntityTypes.Patient:
                return 'translation:global.labels.token-types.patient';
            case EmailEntityTypes.Contact:
                return 'translation:global.labels.token-types.contact';
            case EmailEntityTypes.Account:
                return 'translation:global.labels.token-types.account';
            case EmailEntityTypes.Other:
                return 'translation:global.labels.token-types.other';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
