<import from="converters/translate" />
<import from="./template-therapy-execution-category" />
<import from="../template-therapy-execution-step/template-therapy-execution-step" />

<div class="${columns} flex flex-col">
    <div
        class.bind="state.clipboard.therapyExecutionItem && state.clipboard.therapyExecutionItem.item.id === therapyItem.id ? 'bg-gray-200' : 'bg-gray-100'"
        class="${ editMode ? 'space-x-4' : '' } flex items-center justify-between rounded-lg pr-3 text-sm font-medium text-gray-800">
        <template if.bind="editMode">
            <div class="flex items-center flex-1 py-2 pl-3">
                <ux-input
                    rounded="left"
                    autofocus.bind="true"
                    onkeydown.trigger="handleInputChanged($event)"
                    action.bind="c => manageTranslations('category:name', true, index, array)"
                    value.two-way="therapyItem.category.name[language]"
                    valid.bind="validation[index].name"
                    t="[placeholder]translation:partial-views.medical-therapies.placeholders.category-name">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                    </svg>
                </ux-input>
                <button
                    class.bind="validation[index].name ? 'border-gray-300 text-green-500 focus:ring-gray-400' : 'border-red-500 text-red-500 focus:ring-red-400'"
                    class="inline-flex h-[38px] items-center rounded-r-lg border-y border-r bg-white transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-offset-1 sm:px-2"
                    click.trigger="toggleEditMode()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </div>
        </template>
        <template else>
            <a href="javascript:" click.trigger="collapseOrExpand($event)" class="flex items-center justify-between flex-1 min-w-0 py-2 pl-3 pr-2 gap-x-1">
                <div class="flex items-center min-w-0 gap-1">
                    <div class="flex min-w-[1.2rem]">
                        <template if.bind="therapyItem.attributes.expanded">
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                            </svg>
                        </template>
                        <template else>
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                            </svg>
                        </template>
                    </div>
                    <div class="flex items-center min-w-0 gap-x-3">
                        <!-- <span class="text-gray-500" t="translation:global.labels.category"></span> -->
                        <span class.bind="validation[index].valid && validation[index].childrenValid ? 'text-gray-800' : 'text-red-500'" class="block truncate">
                            ${ therapyItem.category.name | translate:language }
                        </span>
                    </div>
                </div>
                <div class="flex gap-0.5">
                    <div if.bind="hasConnectedCategories" class="h-2 w-2 rounded-sm bg-[#F4C237]"></div>
                    <div if.bind="hasVisibilityRequirements" class="w-2 h-2 rounded-sm bg-primary"></div>
                </div>
            </a>
        </template>
        <ux-dropdown border.bind="false" transparent.bind="true" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                    <path
                        fill="currentColor"
                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                </svg>
            </div>
            <ux-dropdown-header>
                <span t="translation:global.labels.category"></span>
            </ux-dropdown-header>
            <ux-dropdown-option click.trigger="toggleEditMode()">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.change-name"></span>
            </ux-dropdown-option>
            <ux-dropdown-option if.bind="index !== 0" click.trigger="moveItem('up')">
                <span class="text-sm" t="global.buttons.move-up"></span>
            </ux-dropdown-option>
            <ux-dropdown-option if.bind="index < array.length - 1" click.trigger="moveItem('down')">
                <span class="text-sm" t="global.buttons.move-down"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="copyOrCut('copy')">
                <span class="text-sm" t="global.buttons.copy"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="copyOrCut('cut')">
                <span class="text-sm" t="global.buttons.cut"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addNewCategory(index)">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.add-therapy-category-before"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addNewCategory(index + 1)">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.add-therapy-category-after"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addNewStep(index)">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.add-therapy-step-before"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addNewStep(index + 1)">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.add-therapy-step-after"></span>
            </ux-dropdown-option>
            <template if.bind="state.clipboard.therapyExecutionItem && (state.clipboard.therapyExecutionItem.item.id !== therapyItem.id || state.clipboard.therapyExecutionItem.command === 'copy')">
                <ux-dropdown-option click.trigger="pasteItem('before', false)">
                    <span class="text-sm" t="translation:global.buttons.paste-before"></span>
                </ux-dropdown-option>
                <ux-dropdown-option click.trigger="pasteItem('after', false)">
                    <span class="text-sm" t="translation:global.buttons.paste-after"></span>
                </ux-dropdown-option>
            </template>
            <ux-dropdown-option click.trigger="openSettings(therapyItem, parent?.id, 'execution')">
                <span class="text-sm" t="global.buttons.settings"></span>
            </ux-dropdown-option>
            <ux-dropdown-divider></ux-dropdown-divider>
            <ux-dropdown-option click.trigger="remove(index, parent, validation, 'execution')">
                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
            </ux-dropdown-option>
            <ux-dropdown-divider></ux-dropdown-divider>
            <ux-dropdown-header>
                <span t="translation:global.labels.category-steps"></span>
            </ux-dropdown-header>
            <ux-dropdown-option click.trigger="addCategoryToSteps()">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.add-therapy-sub-category"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addStepToSteps()">
                <span class="text-sm" t="translation:partial-views.medical-therapies.buttons.add-therapy-sub-step"></span>
            </ux-dropdown-option>
            <template if.bind="state.clipboard.therapyExecutionItem && (state.clipboard.therapyExecutionItem.item.id !== therapyItem.id || state.clipboard.therapyExecutionItem.command === 'copy')">
                <ux-dropdown-option click.trigger="pasteItem('end', true)">
                    <span class="text-sm" t="translation:global.buttons.paste"></span>
                </ux-dropdown-option>
            </template>
        </ux-dropdown>
    </div>
    <template if.bind="therapyItem.attributes.expanded">
        <div class="grid grid-cols-12 gap-1 pl-2 mt-1 ml-1 border-l-2 border-gray-300">
            <template repeat.for="item of therapyItem.category.stepsToTake">
                <template if.bind="item.type === MedicalTherapyExecutionItemTypes.Category">
                    <template-therapy-execution-category
                        therapy.bind="therapy"
                        array.bind="therapyItem.category.stepsToTake"
                        therapy-item.bind="item"
                        manage-translations.bind="manageTranslations"
                        remove.bind="remove"
                        open-setting.bind="openSettings"
                        level.bind="level + 1"
                        add-category.bind="addStep"
                        add-step.bind="addStep"
                        index.bind="$index"
                        open-settings.bind="openSettings"
                        parent.bind="therapyItem.category"
                        parent-index.bind="index"
                        language.bind="language"
                        validation.two-way="validation[index].steps"
                        edit.bind="edit">
                    </template-therapy-execution-category>
                </template>
                <template else>
                    <template-therapy-execution-step
                        therapy.bind="therapy"
                        array.bind="therapyItem.category.stepsToTake"
                        therapy-item.bind="item"
                        level.bind="level + 1"
                        manage-translations.bind="manageTranslations"
                        remove.bind="remove"
                        index.bind="$index"
                        open-settings.bind="openSettings"
                        parent.bind="therapyItem.category"
                        parent-index.bind="index"
                        language.bind="language"
                        validation.two-way="validation[index].steps"
                        edit.bind="edit">
                    </template-therapy-execution-step>
                </template>
            </template>
            <p if.bind="therapyItem.category.stepsToTake.length === 0" class="col-span-12 my-1 text-sm text-gray-600" t="translation:partial-views.medical-therapies.labels.no-steps"></p>
        </div>
    </template>
</div>
