import { DialogController, IDialogController } from '@aurelia/dialog';

import { ApplicationErrorsApiClient, LogMedicalRecordErrorRequest } from '@wecore/sdk-core';
import { inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';

@inject(IDialogController, ErrorHandler, ApplicationErrorsApiClient)
export class ModalSaveResult {
    public result: 'success' | 'error';
    public error: any;
    public payload: any;
    public sent: boolean = false;
    public step: string;
    public sending: boolean = false;
    private workspace: string;

    public constructor(
        private readonly controller: DialogController,
        private readonly errorHandler: ErrorHandler,
        private readonly errorsApi: ApplicationErrorsApiClient //
    ) {}

    public activate(params: any): void {
        this.result = params.result;
        this.error = params.error;
        this.payload = params.payload;
        this.step = params.step;
        this.workspace = params.workspace;
    }

    public async sendError(): Promise<void> {
        this.sending = true;
        try {
            await this.errorsApi.logMedicalRecordError(
                this.workspace,
                new LogMedicalRecordErrorRequest({
                    error: this.error.message,
                    stackTrace: this.error.stack,
                    record: this.payload.record,
                    registrations: this.payload.registrations,
                    step: this.step
                })
            );

            this.sent = true;
            this.sending = false;
        } catch (e) {
            this.errorHandler.handle('modal-save-result', e);
            this.sending = false;
        }
    }

    public async ok(): Promise<void> {
        await this.controller.ok();
    }

    public async cancel(): Promise<void> {
        await this.controller.cancel();
    }
}
