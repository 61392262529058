import { connectTo } from '@aurelia/store-v1';
import { MedicalTherapyEvaluationFlow, MedicalTherapyEvaluationItem } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../infra/events';
import { State } from '../../../../infra/store/state';
import { generateColumns } from '../../../../infra/utilities';

@connectTo<State>()
@containerless
@inject(IEventAggregator)
export class TemplateQuestionnaire {
    @bindable() public step: MedicalTherapyEvaluationItem;
    @bindable() public flow: MedicalTherapyEvaluationFlow;
    @bindable() public language: string;
    @bindable() public index: number;
    @bindable() public amountOfSteps: number;

    @bindable() public addStep: (index: number) => Promise<void>;
    @bindable() public removeStep: (index: number) => Promise<void>;
    @bindable() public moveStep: (direction: string, index: number) => Promise<void>;
    @bindable() public copyOrCut: (command: string, index: number) => Promise<void>;
    @bindable() public pasteItem: (index: number) => Promise<void>;
    @bindable() public openSettings: (index: number, nested: boolean) => Promise<void>;

    public columns: string;
    public state: State;
    public isRequired: boolean = false;
    public hasVisibilityRequirements: boolean = false;
    private subscription: any;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        if (isNotDefined(this.step.attributes)) this.step.attributes = {};
        this.step.attributes.expanded = false;

        this.setColumns();
        this.checkIfHasSettings();
        this.subscription = this.events.subscribe(CustomEvents.ExaminationStepSettingsChanged, () => {
            this.setColumns();
            this.checkIfHasSettings();
        });
    }

    public detached(): void {
        if (isDefined(this.subscription)) this.subscription.dispose();
    }

    public collapseOrExpand(): void {
        this.step.attributes.expanded = !this.step.attributes.expanded;
    }

    public getIndex(position: 'before' | 'after'): number {
        if (position === 'before') {
            return this.index;
        } else {
            if (this.index === 0 && this.amountOfSteps === 1) return -1;
            return this.index + 1;
        }
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.step.id) || [] //
        );
    };

    private checkIfHasSettings(): void {
        this.isRequired = this.flow.required?.filter((x) => x.key === this.step.id).any() || false;
        this.hasVisibilityRequirements = this.flow.visibilityRequirements?.filter((x) => x.id === this.step.id).any() || false;
    }
}
