import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AnatomicalRegionEntityReference,
    AnatomicalRegionsApiClient,
    DifferentialDiagnosesApiClient,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationActionResponse,
    MedicalExaminationActionsApiClient,
    UpdateAnatomicalRegionRequest
} from '@wecore/sdk-healthcare';
import { isNotDefined, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AnatomicalRegionsApiClient, ModalService, DifferentialDiagnosesApiClient, MedicalExaminationActionsApiClient)
export class PartialAnatomicalRegionsEdit extends BasePartialView {
    public regionId: string;
    public region: UpdateAnatomicalRegionRequest;
    public validation = {
        name: true,
        displayOrder: true
    };
    public diagnoses: GetDifferentialDiagnosisResponse[];
    public actions: GetMedicalExaminationActionResponse[];
    public isLinked: boolean = false;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly regionsApi: AnatomicalRegionsApiClient,
        private readonly modalService: ModalService,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient,
        private readonly actionsApi: MedicalExaminationActionsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.regionId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [region, diagnoses, actions] = await Promise.all([
                    this.regionsApi.getById(this.regionId, this.authenticated.workspace.id), //
                    this.diagnosesApi.search(this.authenticated.workspace.id, '', 250, 0, undefined, undefined, undefined, undefined, [this.regionId]),
                    this.actionsApi.search(this.authenticated.workspace.id, '', 250, 0, undefined, undefined, undefined, undefined, undefined, [this.regionId])
                ]);

                this.region = region;
                if (isNotDefined(this.region.description)) this.region.description = setTranslation({}, this.language);

                this.diagnoses = diagnoses.data;
                this.actions = actions.data;
                this.isLinked = this.diagnoses.any() || this.actions.any();

                if (this.isLinked) {
                    this.changeStyle({
                        width: '1000px',
                        minWidth: '1000px'
                    });
                    this.scrollTo(this.partial);
                }

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialAnatomicalRegionsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handleRegionsChanged = async (regions: GetAnatomicalRegionResponse[]): Promise<void> => {
        this.region.subRegions = regions.map(
            (region) =>
                new AnatomicalRegionEntityReference({
                    id: region.id,
                    translations: region.name
                })
        );
    };

    public async openView(id: string, type: 'diagnosis' | 'action'): Promise<void> {
        await this.removeChildViews();
        const callback = async (result: PartialViewResults, _: any): Promise<void> => {
            if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                const [diagnoses, actions] = await Promise.all([
                    this.diagnosesApi.search(this.authenticated.workspace.id, '', 100, 0, undefined, undefined, undefined, undefined, [this.regionId]),
                    this.actionsApi.search(this.authenticated.workspace.id, '', 100, 0, undefined, undefined, undefined, undefined, undefined, [this.regionId])
                ]);
                this.diagnoses = diagnoses.data;
                this.actions = actions.data;
            }
        };

        await this.addPartialView({
            view: this.partial.base,
            partial:
                type === 'diagnosis'
                    ? PartialViews.EditDifferentialDiagnosis.with({ id }).whenClosed(callback) //
                    : PartialViews.EditMedicalExaminationAction.with({ id }).whenClosed(callback),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl: false
            })
        });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.region, 1);
                await this.regionsApi.update(this.regionId, this.authenticated.workspace.id, this.region);
                this.notifications.show(
                    this.t.tr('translation:partial-views.anatomical-regions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.anatomical-regions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.region.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.region.description)) this.region.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[edit-anatomical-regions]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.anatomical-regions.questions.delete.title'),
                message: this.t
                    .tr('partial-views.anatomical-regions.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.region.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.regionsApi.delete(this.regionId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.anatomical-regions.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.anatomical-regions.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.region.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-differential-diagnosis-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.region[property],
            callback: (updatedTranslations: any) => {
                this.region[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.region.name, this.language);
        return validateState(this.validation);
    }
}
