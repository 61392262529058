import { connectTo } from '@aurelia/store-v1';
import { GetMedicalRecordRegistrationResponse, MedicalExaminationActionItemTypes, MedicalExaminationTemplateItemStep } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';
import { State } from '../../../../../../../infra/store/state';

@containerless
@connectTo<State>()
export class TemplateAction {
    @bindable() public stepResult: any;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public language: string;

    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public columns: string;
    public expanded: boolean = false;
    public itemResult: any;

    public attached(): void {
        this.itemResult = this.stepResult.items.find((x: any) => x.stepId === this.step.id);
    }

    public collapseOrExpand(): void {
        this.expanded = !this.expanded;
    }
}
