import { ExaminationRoom, GetPracticeLocationResponse } from '@wecore/sdk-healthcare';

export interface ISchedulerRoom {
    room: ExaminationRoom;
    location: GetPracticeLocationResponse;
}

export class SchedulerRoomWrapper {
    public room: ExaminationRoom;
    public location: GetPracticeLocationResponse;

    public constructor(data: ISchedulerRoom) {
        this.room = data?.room;
        this.location = data?.location;
    }
}
