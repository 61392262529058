import { MedicalHistoryItemTypes } from '@wecore/sdk-healthcare';

export class MedicalHistoryTypeToStringValueConverter {
    public toView(type: MedicalHistoryItemTypes): string {
        switch (type) {
            case MedicalHistoryItemTypes.Disorder:
                return 'translation:components.medical-history-selector.labels.disorder';
            case MedicalHistoryItemTypes.Injury:
                return 'translation:components.medical-history-selector.labels.injury';
            case MedicalHistoryItemTypes.Surgery:
                return 'translation:components.medical-history-selector.labels.surgery';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
