import * as signalR from '@microsoft/signalr';
import { UserEntityReference } from '@wecore/sdk-core';
import { ExaminationRoom, GetAppointmentResponse } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { parseISO } from 'date-fns';
import { AuthenticationService } from '../../services/service.authentication';
import { getToken } from '../utilities';
import { Broadcasters } from './broadcasters';
import { Broadcasts } from './broadcasts';

@inject(AureliaConfiguration, IEventAggregator, AuthenticationService)
export class SchedulerBroadcaster {
    private connection: signalR.HubConnection;

    public constructor(
        private readonly config: AureliaConfiguration, //
        private readonly events: IEventAggregator,
        private readonly auth: AuthenticationService
    ) {}

    public async start(workspace: string): Promise<void> {
        const baseUrl = this.config.get('api.healthcare');
        const token = await getToken(this.auth);

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/hubs/scheduler?workspace=${workspace}`, {
                accessTokenFactory: () => token,
                headers: { workspace }
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        this.connection.on(Broadcasts.OnConnected, (connectionId: string) => {
            this.events.publish(Broadcasts.OnConnected, {
                broadcaster: Broadcasters.SchedulerBroadcaster,
                connectionId
            });
        });

        this.connection.onreconnecting((error) => {
            this.events.publish(Broadcasts.OnReconnecting, {
                broadcaster: Broadcasters.SchedulerBroadcaster,
                state: this.connection.state,
                error
            });
        });

        this.connection.onreconnected((error) => {
            this.events.publish(Broadcasts.OnReconnected, {
                broadcaster: Broadcasters.SchedulerBroadcaster,
                state: this.connection.state,
                error
            });
        });

        this.connection.onclose((error) => {
            this.events.publish(Broadcasts.OnDisconnected, {
                broadcaster: Broadcasters.SchedulerBroadcaster,
                state: this.connection.state,
                error
            });
        });

        this.connection.on(Broadcasts.AppointmentCreated, (_: string, appointment: GetAppointmentResponse) => {
            this.events.publish(Broadcasts.AppointmentCreated, { appointment: GetAppointmentResponse.fromJS(appointment) });
        });

        this.connection.on(
            Broadcasts.AppointmentUpdated,
            (
                _: string,
                appointment: GetAppointmentResponse, //
                oldStart: string,
                oldEnd: string,
                oldRoom: ExaminationRoom,
                oldPractitioner: UserEntityReference
            ) => {
                this.events.publish(Broadcasts.AppointmentUpdated, {
                    appointment: GetAppointmentResponse.fromJS(appointment),
                    // Make sure to create new Date objects from the oldStart and oldEnd dates
                    // so that we know they are not UTC but in the correct timezone.
                    oldStart: parseISO(oldStart),
                    oldEnd: parseISO(oldEnd),
                    oldRoom: ExaminationRoom.fromJS(oldRoom),
                    oldPractitioner: UserEntityReference.fromJS(oldPractitioner)
                });
            }
        );

        this.connection.on(Broadcasts.AppointmentDeleted, (_: string, appointment: GetAppointmentResponse) => {
            this.events.publish(Broadcasts.AppointmentDeleted, { appointment: GetAppointmentResponse.fromJS(appointment) });
        });

        await this.connection.start();
    }

    public async stop(): Promise<void> {
        if (isNotDefined(this.connection)) return;
        await this.connection.stop();
    }

    public async status(): Promise<'Disconnected' | 'Connecting' | 'Connected' | 'Disconnecting' | 'Reconnecting'> {
        if (isNotDefined(this.connection)) return 'Disconnected';
        return this.connection.state;
    }
}
