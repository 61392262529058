import {
    GetMedicalExaminationActionResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationActionsApiClient,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem
} from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { PartialViews } from '../../../../../infra/partial-views';
import { generateColumns, setTranslation } from '../../../../../infra/utilities';
import { EventDetails } from '../../../../../models/event-details';
import { FlattenedExaminationStep } from '../../../../../models/flattened-examination-step';
import { PartialView } from '../../../../../models/partial-view';
import { SelectedFile } from '../../../../../models/selected-file';
import { StepState } from '../../../../../models/step-state';
import { ViewOptions } from '../../../../../models/view-options';
import { UxInput } from '../../../../../ux/ux-input/ux-input';

@containerless
@inject(MedicalExaminationActionsApiClient, IEventAggregator)
export class TemplateAction {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public container: GetMedicalRecordRegistrationResponse;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public flow: MedicalExaminationFlow | MedicalTherapyEvaluationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep | MedicalTherapyEvaluationItem;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public phaseIndex: number;
    @bindable() public index: number;
    @bindable() public showOptions: boolean = true;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public remove: (stepId: string) => Promise<void>;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
    @bindable() public duplicate: (parent: number, index: number, step: MedicalExaminationTemplateItemStep) => Promise<void>;

    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public columns: string = 'col-span-12';
    public recent: GetMedicalExaminationActionResponse;
    public editLabelMode: boolean = false;
    public isAnswered: boolean = false;
    public shouldBeDisplayed: boolean = false;
    public required: boolean = false;
    public level: number = 0;
    private subscriptions: any[] = [];

    public constructor(
        private readonly actionsApi: MedicalExaminationActionsApiClient, //
        private readonly events: IEventAggregator
    ) {}

    public attached(): void {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.step.id) || [] //
        );

        if (isNotDefined(this.step.attributes)) this.step.attributes = {};
        if (isNotDefined(this.states[this.step.id]))
            this.states[this.step.id] = new StepState({
                stepId: this.step.id,
                expanded: false,
                answered: false
            });

        if (this.step instanceof MedicalExaminationTemplateItemStep && isNotDefined(this.step.label)) this.step.label = setTranslation({}, this.language);
        this.actionsApi.getById(this.step.action.id, this.workspace).then((action) => (this.recent = action));

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => {
                this.evaluateSettings();
                this.checkForAnsweredSteps();
            })
        ];

        this.evaluateSettings();
        this.checkForAnsweredSteps();
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.states[this.step.id].expanded = !this.states[this.step.id].expanded;
        if (this.states[this.step.id].expanded) {
            if (isNotDefined(this.record.examination.template.phases[this.phaseIndex])) return;

            const steps = this.record.examination.template.phases[this.phaseIndex].stepsToTake.map((x) => x.id);
            for (const step of steps) {
                if (step !== this.step.id) this.states[step].expanded = false;
            }
        }
    }

    public async openInformationSheet(): Promise<void> {
        await this.addPartial(
            PartialViews.InformationSheet.with({
                config: {
                    mode: 'view',
                    language: this.language
                },
                sheet: this.recent.informationSheet
            }),
            new ViewOptions({
                scrollToView: true,
                markItem: true,
                replace: true
            })
        );
    }

    public async handleInputChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.editLabelMode = false;
    }

    public addLabel(): void {
        this.editLabelMode = true;
    }

    public duplicateAction(): void {
        if (this.step instanceof MedicalTherapyEvaluationItem) return;

        this.duplicate(this.phaseIndex, this.index, this.step);
    }

    public manageTranslationsFor(): void {
        if (this.step instanceof MedicalTherapyEvaluationItem) return;

        this.manageTranslations(
            this.step.label, //
            (translations: any) => {
                if (this.step instanceof MedicalTherapyEvaluationItem) return;

                this.step.label = translations;
                this.editLabelMode = false;
            },
            'input',
            false
        );
    }

    public deleteStep(): void {
        if (isFunction(this.remove)) this.remove(this.step.id);
    }

    private checkForAnsweredSteps(): void {
        // When duplicating an action, the registration
        // doesn't exist yet. So we skip it. When duplicating an action
        // the view is reloaded and the registration will exist.
        if (isNotDefined(this.registration)) return;

        const setState = (stepId: string, answered: boolean, level?: { stepId: string; copiedFrom: string; value: number; container: string }) => {
            if (isNotDefined(this.states[stepId]))
                this.states[stepId] = new StepState({
                    stepId,
                    expanded: false,
                    answered: false,
                    level
                });
            this.states[stepId].answered = answered;
        };

        const checkIfAnswered = (items: MedicalExaminationActionItem[], level: number = 1): boolean => {
            let answered = false;
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    const isAnswered = checkIfAnswered(item.category.stepsToTake, level + 1) || false;
                    setState(item.id, isAnswered, {
                        stepId: item.id,
                        value: level,
                        container: this.step.id,
                        copiedFrom: item.copiedFrom
                    });
                    if (!answered) answered = isAnswered;
                } else {
                    const isAnswered =
                        (isDefined(item.step.result) && isDefined(item.step.result.value) && isNotEmpty(item.step.result.value)) || //
                        (isDefined(item.step.results) && item.step.results.any()) ||
                        (isDefined(item.step.attachments) && item.step.attachments.any()) ||
                        (isDefined(item.step.attributes) && isDefined(item.step.attributes.uploads) && item.step.attributes.uploads.any());
                    if (!answered) answered = isAnswered;
                }
            }
            return answered;
        };

        this.isAnswered = checkIfAnswered(this.registration.action.stepsToTake);
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.step.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;
        this.required = flattened?.isRequired ?? false;

        if (!this.shouldBeDisplayed) {
            const reset = (items: MedicalExaminationActionItem[]): void => {
                for (const item of items) {
                    if (item.type === MedicalExaminationActionItemTypes.Category) reset(item.category.stepsToTake);
                    else {
                        item.step.result.value = null;
                        item.step.results = [];

                        // TODO: Delete files if there are any?
                    }
                }
            };
            reset(this.registration.action.stepsToTake);
        }
    }
}
