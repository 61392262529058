import { connectTo } from '@aurelia/store-v1';
import { MedicalExaminationTemplateItemStep } from '@wecore/sdk-healthcare';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { State } from '../../../../infra/store/state';

@connectTo<State>()
@containerless
@inject(IEventAggregator)
export class TemplateQuestion {
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public language: string;
    @bindable() public nested: boolean = false;
}
