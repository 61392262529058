<import from="converters/translate" />
<import from="converters/get-ids" />
<import from="bx/bx-user-multi-selector/bx-user-multi-selector" />
<import from="bx/bx-patient-multi-selector/bx-patient-multi-selector" />
<import from="bx/bx-task-label-selector/bx-task-label-selector" />
<import from="bx/bx-label-multi-selector/bx-label-multi-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-2 overflow-hidden" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div ref="scrollContainer" class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div border-b.class="hasScrolled" class="sticky top-0 z-50 pt-8 pb-3 bg-white border-gray-200 top">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.tasks.create.title"></p>
                            <p if.bind="list" css="color: ${list.color}" class="font-medium">${list.title | translate:language}</p>
                        </div>
                        <div class="flex flex-col mt-3">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.tasks.labels.title"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('title', true)"
                                rows="1"
                                autofocus.bind="true"
                                focus-delay="400"
                                value.two-way="request.title[language]"
                                valid.bind="validation.title"
                                t="[placeholder]translation:partial-views.tasks.placeholders.add-title">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                    </div>
                    <div class="flex flex-col flex-1 gap-3 pt-3 pb-5">
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="request.description[language]"
                                rows="8"
                                t="[placeholder]translation:partial-views.tasks.placeholders.add-description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.tasks.labels.assigned-to"></span>
                            </ux-label>
                            <bx-user-multi-selector
                                disabled.bind="request.isPersonal"
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleUserSelected"
                                language.bind="language"
                                t="[placeholder]translation:partial-views.tasks.placeholders.assigned-users"
                                values.bind="request.assignedTo | getIds">
                            </bx-user-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.tasks.labels.deadline"></span>
                            </ux-label>
                            <ux-datepicker
                                onselect.trigger="handleDeadlineSelected($event)"
                                value.bind="request.deadline"
                                onclear.trigger="handleDeadlineCleared($event, 'end')"
                                allow-clear.bind="true"
                                t="[placeholder]translation:partial-views.tasks.placeholders.deadline">
                            </ux-datepicker>
                        </div>
                        <div if.bind="baseLoaded && hasRole(UserRoles.ReadPatients)" class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.tasks.labels.patients"></span>
                            </ux-label>
                            <bx-patient-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handlePatientSelected"
                                language.bind="language"
                                t="[placeholder]translation:partial-views.tasks.placeholders.patients"
                                values.bind="request.patients | getIds">
                            </bx-patient-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.priority"></span>
                            </ux-label>
                            <bx-task-label-selector
                                on-select.bind="handlePrioritySelected"
                                language.bind="language"
                                type.bind="HealthcareTaskLabelTypes.Priority"
                                workspace.bind="authenticated.workspace.id"
                                allow-clear.bind="true"
                                value.two-way="request.priority.id"
                                t="[placeholder]translation:partial-views.tasks.placeholders.select-priority">
                            </bx-task-label-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.status"></span>
                            </ux-label>
                            <bx-task-label-selector
                                on-select.bind="handleStatusSelected"
                                language.bind="language"
                                type.bind="HealthcareTaskLabelTypes.Status"
                                workspace.bind="authenticated.workspace.id"
                                allow-clear.bind="true"
                                value.two-way="request.status.id"
                                t="[placeholder]translation:partial-views.tasks.placeholders.select-status">
                            </bx-task-label-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.tasks.labels.labels"></span>
                            </ux-label>
                            <bx-label-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleLabelSelected"
                                language.bind="language"
                                type.bind="HealthcareTaskLabelTypes.Label"
                                t="[placeholder]translation:partial-views.tasks.placeholders.labels"
                                values.bind="request.labels | getIds">
                            </bx-label-multi-selector>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <ux-footer>
                <div class="flex justify-end flex-1 gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading || deleting"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
