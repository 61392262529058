<import from="converters/translate" />
<import from="bx/bx-address-selector/bx-address-selector" />
<import from="components/component-duplicator/component-duplicator" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-50 flex flex-col gap-3 pt-5 pb-3 bg-white border-b border-gray-200">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.manage-practice-locations.create.title"></p>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-practice-locations.labels.full-name"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="request.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.manage-practice-locations.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                    </div>
                    <div class="flex flex-col flex-1 gap-3 pt-3 pb-5">
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-practice-locations.labels.application-name"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('applicationName', true)"
                                rows="1"
                                value.two-way="request.applicationName[language]"
                                t="[placeholder]partial-views.manage-practice-locations.placeholders.application-name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                            <p class="mt-2 text-xs text-gray-500" t="translation:partial-views.manage-practice-locations.messages.application-name"></p>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-practice-locations.labels.renth-per-month"></span>
                            </ux-label>
                            <ux-input type="number" value.two-way="request.rentPerMonth" t="[placeholder]translation:partial-views.manage-practice-locations.placeholders.rent"></ux-input>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.address"></span>
                            </ux-label>
                            <bx-address-selector
                                descriptions.bind="descriptions"
                                language.bind="language"
                                on-select.bind="handleAddressSelected"
                                map-height.bind="75"
                                max-amount.bind="1"
                                use-labels.bind="false"
                                mode="vertical"
                                addresses.bind="addresses"
                                t="[label]translation:partial-views.manage-practice-locations.labels.address">
                            </bx-address-selector>
                        </div>
                        <div class="flex flex-col">
                            <component-duplicator
                                t="[label]translation:global.labels.phone-number"
                                type="phone"
                                mask="000000000"
                                items.two-way="phones"
                                max-amount.bind="1"
                                use-labels.bind="false"
                                language.bind="language"
                                on-value-changed.bind="handlePhoneChanged"
                                validation.two-way="validation.phones">
                            </component-duplicator>
                        </div>
                        <div class="flex flex-col">
                            <component-duplicator
                                t="[label]translation:global.labels.email"
                                type="email"
                                items.two-way="emails"
                                max-amount.bind="1"
                                use-labels.bind="false"
                                language.bind="language"
                                on-value-changed.bind="handleEmailChanged"
                                validation.two-way="validation.emails">
                            </component-duplicator>
                        </div>
                        <div class="flex flex-col">
                            <div mb-2.class="request.rooms.length > 0" mb-1.class="request.rooms.length === 0" class="flex items-center justify-between">
                                <ux-label>
                                    <span t="translation:partial-views.manage-practice-locations.labels.rooms"></span>
                                </ux-label>
                                <ux-button if.bind="request.rooms.length > 0" click.trigger="createOrEditRoom()" padding.bind="false" size="xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </ux-button>
                            </div>
                            <div class="flex" if.bind="request.rooms.length === 0">
                                <button
                                    border-gray-300.class="validation.rooms"
                                    border-red-500.class="!validation.rooms"
                                    hover:border-gray-400.class="validation.rooms"
                                    hover:border-red-700.class="!validation.rooms"
                                    click.trigger="createOrEditRoom()"
                                    type="button"
                                    class="relative block w-full p-12 text-center border-2 border-dashed rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 active:ring-primary">
                                    <svg
                                        text-gray-400.class="validation.rooms"
                                        text-red-500.class="!validation.rooms"
                                        class="w-12 h-12 mx-auto"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512">
                                        <path
                                            fill="currentColor"
                                            d="M288 480H96V86.2c0-7.5 5.1-13.9 12.4-15.6l166-38.3c.8-.2 1.7-.3 2.5-.3C283 32 288 37 288 43.1V480zm32 0V43.1C320 19.3 300.7 0 276.9 0c-3.3 0-6.5 .4-9.7 1.1l-166 38.3C79.4 44.4 64 63.8 64 86.2V480H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 80 96 288h32V480zM232 288c13.2 0 24-14.4 24-32s-10.8-32-24-32s-24 14.4-24 32s10.7 32 24 32zM464 64H352V96H464c8.8 0 16 7.2 16 16V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H512V112c0-26.5-21.5-48-48-48z" />
                                    </svg>
                                    <span
                                        text-gray-900.class="validation.rooms"
                                        text-red-500.class="!validation.rooms"
                                        class="block mt-2 text-sm font-semibold"
                                        t="translation:partial-views.manage-practice-locations.buttons.add-room"></span>
                                </button>
                            </div>
                            <ul else class="flex flex-col gap-1">
                                <li class="flex" repeat.for="room of request.rooms">
                                    <ux-card>
                                        <div class="relative flex items-center border-t border-l border-gray-200 rounded-lg">
                                            <button
                                                click.trigger="createOrEditRoom($index)"
                                                class="flex items-center flex-1 gap-6 px-6 py-3 border-r border-gray-200 rounded-l-lg ring-primary ring-offset-1 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-gray-100">
                                                <svg class="w-6 h-6 text-primary" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M288 480H96V86.2c0-7.5 5.1-13.9 12.4-15.6l166-38.3c.8-.2 1.7-.3 2.5-.3C283 32 288 37 288 43.1V480zm32 0V43.1C320 19.3 300.7 0 276.9 0c-3.3 0-6.5 .4-9.7 1.1l-166 38.3C79.4 44.4 64 63.8 64 86.2V480H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 80 96 288h32V480zM232 288c13.2 0 24-14.4 24-32s-10.8-32-24-32s-24 14.4-24 32s10.7 32 24 32zM464 64H352V96H464c8.8 0 16 7.2 16 16V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H512V112c0-26.5-21.5-48-48-48z" />
                                                </svg>
                                                <div class="flex flex-col items-start">
                                                    <div class="flex gap-1 text-sm">
                                                        <p class="text-gray-400" t="translation:partial-views.manage-practice-locations.labels.room"></p>
                                                        <p class="text-gray-800">${room.name | translate:language}</p>
                                                    </div>
                                                </div>
                                            </button>
                                            <button
                                                click.trigger="removeRoom($index)"
                                                class="flex h-[48px] w-10 items-center justify-center rounded-r-lg bg-white p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100"
                                                click.trigger="delete($index)">
                                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </ux-card>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-[999] flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
