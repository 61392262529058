<import from="bx/bx-email-template-token-selector/bx-email-template-token-selector" />
<import from="bx/bx-email-template-selector/bx-email-template-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div ref="scrollContainer" class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-40 flex justify-between pt-8 bg-white border-gray-200">
                        <div class="grid grid-cols-12 gap-3">
                            <div class="flex flex-col col-span-4">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="translation:partial-views.send-email-to-patient.labels.email-address"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-select valid.bind="validation.email" value.two-way="email">
                                    <ux-select-option repeat.for="email of patient.emails" value.bind="email.value">${email.value}</ux-select-option>
                                </ux-select>
                            </div>
                            <div class="flex flex-col col-span-8">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="translation:partial-views.send-email-to-patient.labels.subject"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    rows="1"
                                    value.two-way="request.subject"
                                    onblur.trigger="handleSubjectBlur($event)"
                                    valid.bind="validation.subject"
                                    t="[placeholder]translation:partial-views.send-email-to-patient.placeholders.subject">
                                </ux-textarea>
                                <p
                                    if.bind="request.useZorgmail && !request.isEdifact"
                                    class="mt-1 text-xs text-primary"
                                    t="translation:partial-views.send-email-to-patient.messages.add-phonenumber-in-subject"></p>
                            </div>
                            <div class="col-span-6">
                                <bx-email-template-selector
                                    allow-clear.bind="true"
                                    on-select.bind="handleTemplateSelected"
                                    language.bind="language"
                                    value.bind="template.id"
                                    types.bind="['Other', 'Patient']"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]translation:partial-views.send-email-to-patient.placeholders.select-template">
                                </bx-email-template-selector>
                            </div>
                            <div class="col-span-6">
                                <bx-email-template-token-selector
                                    on-select.bind="handleTokenSelected"
                                    language.bind="language"
                                    types.bind="['Other', 'Patient']"
                                    t="[placeholder]translation:partial-views.send-email-to-patient.placeholders.select-template-token">
                                </bx-email-template-token-selector>
                            </div>
                            <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.send-email-to-patient.messages.add-token"></p>
                            <div class.bind="validation.content ? '' : 'invalid'" class="col-span-12" id="toolbar-${partial.id}">
                                <div id="toolbar-container">
                                    <!-- <span class="ql-formats">
                                            <select class="ql-font"></select>
                                                 <select className="ql-font" defaultValue="arial">
                                                    <option value="arial">Arial</option>
                                                    <option value="comic-sans">Comic Sans</option>
                                                    <option value="courier-new">Courier New</option>
                                                    <option value="georgia">Georgia</option>
                                                    <option value="helvetica">Helvetica</option>
                                                    <option value="lucida">Lucida</option>
                                                </select>
                                            <select class="ql-size"></select>
                                        </span> -->
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color"></select>
                                        <select class="ql-background"></select>
                                    </span>
                                    <!-- <span class="ql-formats">
                                            <button class="ql-script" value="sub"></button>
                                            <button class="ql-script" value="super"></button>
                                        </span> -->
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <select class="ql-header">
                                            <option value="1">Heading 1</option>
                                            <option value="2">Heading 2</option>
                                            <option value="3">Heading 3</option>
                                            <option value="4">Heading 4</option>
                                            <option value="5">Heading 5</option>
                                            <option value="6">Heading 6</option>
                                            <option value>Default</option>
                                        </select>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction" value="rtl"></button>
                                        <select class="ql-align"></select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                        <!-- <button class="ql-image"></button>
                                            <button class="ql-video"></button> -->
                                        <!-- <button class="ql-formula"></button> -->
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-clean"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <!-- But you can also add your own -->
                                        <button class="text-red-500" click.trigger="clearHtml()">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-1 pb-5">
                        <div class.bind="validation.content ? '' : 'invalid'" class="!border-t-0" ref="editor"></div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10 pb-5">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex items-center justify-between gap-x-2">
                        <ux-toggle
                            if.bind="canUseZorgmail"
                            disabled.bind="settings.forceZorgmail"
                            checked.two-way="request.useZorgmail"
                            onchange.trigger="handleZorgmailSettingChanged($event)"
                            label-active.bind="'translation:global.labels.yes' | t"
                            label-inactive.bind="'translation:global.labels.no' | t"
                            size="md"
                            color="info">
                        </ux-toggle>
                        <ux-label if.bind="canUseZorgmail" t="translation:partial-views.send-email-to-patient.labels.send-via-zorgmail"></ux-label>
                    </div>
                    <div class="flex gap-x-3">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button size="sm" color="success" disabled.bind="isLoading || deleting" loading.bind="isLoading" click.trigger="send()">
                            <span class="pointer-events-none" t="global.buttons.send"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
