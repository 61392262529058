import { IRouter } from '@aurelia/router';
import { AuthError, AuthResponse, Session, SupabaseClient, User, createClient } from '@supabase/supabase-js';
import { GetUserResponse } from '@wecore/sdk-core';
import { StorageKeys, deleteFromStorage, isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { ErrorHandler } from '../infra/error-handler';

@inject(IRouter, AureliaConfiguration, ErrorHandler)
export class AuthenticationService {
    private readonly client: SupabaseClient;
    private readonly domain: string = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

    public constructor(
        private readonly router: IRouter, //
        private readonly config: AureliaConfiguration
    ) {
        this.client = createClient(this.config.get('supabase.url'), this.config.get('supabase.token'), {
            auth: {
                persistSession: true
            }
        });
    }

    public async isAuthenticated(): Promise<boolean> {
        // const response = await this.client.auth.getUser();
        const response = await this.getSession();
        if (isNotDefined(response.data.session)) return false;

        return new Date().getTime() < Number(response.data.session.expires_at) * 1000;
    }

    public async login(
        email: string,
        password: string,
        onError: (error: any) => void
    ): Promise<{
        success: boolean;
        hasMfa: boolean;
        data: {
            user: User;
            session: Session;
        };
    }> {
        let { data, error } = await this.client.auth.signInWithPassword({
            email: email,
            password: password
        });
        if (isDefined(error)) {
            onError(error);
            return { success: false, hasMfa: false, data: null };
        }

        // Check for possible MFA requirement.
        const check = await this.client.auth.mfa.getAuthenticatorAssuranceLevel();
        if (isDefined(check.error)) {
            onError(check.error);
            return { success: false, hasMfa: false, data: null };
        }

        // If no MFA is required, (current- and nextLevel both are 'aal1')
        // or when MFA is already verified (current- and nextLevel both are 'aal2'),
        // or when MFA is disabled by user (stale JWT) (currentLevel is 'aal2' and nextLevel us 'aal1'),
        // See https://supabase.com/docs/guides/auth/auth-mfa
        // set the session and return.
        if (
            (check.data.currentLevel === 'aal1' && check.data.nextLevel === 'aal1') || //
            (check.data.currentLevel === 'aal2' && check.data.nextLevel === 'aal2') ||
            (check.data.currentLevel === 'aal2' && check.data.nextLevel === 'aal1')
        ) {
            return { success: true, hasMfa: false, data: null };
        }
        // When currentLevel is 'aal1' and nextLevel us 'aal2'
        // force the user to enter MFA code and set sesions if verified.
        else return { success: true, hasMfa: true, data };
    }

    public async requestPasswordReset(email: string, onError: (error: any) => void): Promise<boolean> {
        const { data, error } = await this.client.auth.resetPasswordForEmail(email, {
            redirectTo: `${this.domain}/reset-password`
        });
        if (isDefined(error)) {
            onError(error);
            return false;
        }

        return true;
    }

    public async getSession(): Promise<{
        data: {
            session: Session;
        };
        error: AuthError;
    }> {
        const session = (await this.client.auth.getSession()) as {
            data: {
                session: Session;
            };
            error: AuthError;
        };
        return session;
    }

    public async checkSession(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const { data, error } = await this.client.auth.refreshSession();
            if (isDefined(error)) {
                resolve(false);
                return;
            }
            resolve(true);
        });
    }

    public async updatePassword(password: string, onError: (error: any) => void): Promise<boolean> {
        const { data, error } = await this.client.auth.updateUser({ password });
        if (isDefined(error)) {
            onError(error);
            return false;
        }
        return true;
    }

    public async checkForAuthStateChange(timeoutIn: number = 5000): Promise<void> {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => reject('no_session'), timeoutIn);
            this.client.auth.onAuthStateChange((event: string, session: Session) => {
                if (event === 'PASSWORD_RECOVERY') {
                    clearTimeout(timeout);
                    // this.setSession({ user: session.user, session: session });
                    resolve();
                }
            });
        });
    }

    public async enrollMfa(onError: (error: any) => void): Promise<{
        id: string
        type: 'totp'
        totp: {
          qr_code: string
          secret: string
          uri: string
        }
        friendly_name?: string
      }> {
        const { data, error } = await this.client.auth.mfa.enroll({
            factorType: 'totp',
            issuer: 'wezorg.com',
            friendlyName: 'WEZORG'
        });

        if (isDefined(error)) {
            onError(error);
            return null;
        }
        return data as any;
    }

    public async unenrollMfa(factorId: string, onError: (error: any) => void): Promise<boolean> {
        const { data, error } = await this.client.auth.mfa.unenroll({
            factorId
        });

        if (isDefined(error)) {
            onError(error);
            return false;
        }

        return true;
    }

    public async validatePassword(password: string, onError: (error: any) => void): Promise<boolean> {
        const { data, error } = await this.client.rpc('verify_user_password', {
            password: password ?? ''
        });
        if (isDefined(error)) {
            onError(error);
            return false;
        }
        return data as boolean;
    }

    public async updateUser(user: GetUserResponse, onError: (error: any) => void): Promise<void> {
        const { error } = await this.client.auth.updateUser({
            email: user.email
        });

        if (isDefined(error)) {
            onError(error);
            return;
        }
    }

    public register(email: string, password: string): Promise<AuthResponse> {
        return this.client.auth.signUp({
            email,
            password,
            options: {
                emailRedirectTo: `https://connect.wezorg.com/login`
            }
        });
    }

    public async verifyAndChallengeMfa(factorId: string, token: string, onError: (error: any) => void): Promise<boolean> {
        const { data, error } = await this.client.auth.mfa.challengeAndVerify({
            factorId,
            code: token
        });

        if (isDefined(error)) {
            onError(error);
            return false;
        }
        return true;
    }

    public logout(): void {
        deleteFromStorage(StorageKeys.accessToken);
        deleteFromStorage(StorageKeys.expiresAt);
        deleteFromStorage(StorageKeys.refreshToken);
        deleteFromStorage(StorageKeys.workspace);
        deleteFromStorage(StorageKeys.pageStates);

        this.client.auth.signOut();
        this.router.load('/login');
    }
}
