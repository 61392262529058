import { I18N } from '@aurelia/i18n';
import { isDefined, RollbarOptions } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import Rollbar from 'rollbar';
import { NotificationService } from '../services/service.notifications';

@inject(I18N)
export class ErrorHandler {
    private rollbar: Rollbar;

    public constructor(
        private readonly t: I18N //
    ) {}

    public async configure(options: RollbarOptions): Promise<void> {
        this.rollbar = new Rollbar({
            accessToken: options.accessToken,
            environment: options.environment,
            enabled: options.enabled,
            captureUncaught: true,
            captureUnhandledRejections: true,
            autoInstrument: {
                network: true,
                log: true,
                dom: true,
                navigation: true,
                connectivity: true,
                // We can not log the request and response bodies because of GDPR
                // and other privacy regulations.
                networkRequestBody: false,
                networkResponseBody: false,
                networkResponseHeaders: true
            },
            codeVersion: options.version,
            code_version: options.version,
            payload: {
                ...options.payload,
                client: {
                    source_map_enabled: true,
                    code_version: options.version,
                    send_message_trace: true,
                    environment: options.environment,

                    // Optionally have Rollbar guess which frames the error was
                    // thrown from when the browser does not provide line
                    // and column numbers.
                    guess_uncaught_frames: true
                }
            }
        });
    }

    public async payload(payload: any): Promise<void> {
        if (isDefined(this.rollbar)) this.rollbar.configure({ payload });
    }

    public error(message: string, error: any): void {
        if (isDefined(this.rollbar)) this.rollbar.error(message, error);
    }

    public debug(message: string, obj?: any): void {
        if (isDefined(this.rollbar)) this.rollbar.debug(message, obj);
    }

    public async handle(location: string, incomingError: any, debug: any = null): Promise<void> {
        const json = JSON.stringify(incomingError);
        const notifications = new NotificationService();
        const formatId = (id: string): string => `<br><br> <span class="aurelia-text-xs aurelia-font-semibold">Request ID: ${id}</span>`;

        const onGenericError = async (apiError: any, id: string = ''): Promise<void> => {
            if (isDefined(notifications))
                await notifications.show(
                    this.t.tr('translation:global.errors.default-title'), //
                    this.t.tr('translation:global.errors.default-message').replace('{id}', formatId(id)),
                    { type: 'danger' }
                );
            console.log(location, {
                incomingError,
                apiError,
                id,
                debug
            });
            this.error(location, {
                json,
                incomingError,
                apiError,
                id,
                debug
            });

            throw incomingError;
        };

        try {
            const apiError = JSON.parse(incomingError.response);
            switch (Number(apiError.httpStatusCode)) {
                case 403:
                    await notifications.show(
                        this.t.tr('translation:global.errors.default-title'), //
                        this.t.tr('translation:global.errors.default-rights'),
                        {
                            type: 'warning'
                        }
                    );
                    break;
                default:
                    switch (apiError.code) {
                        case 205:
                            await notifications.show('Helaas, niet gelukt.', `Een van de bovenliggende mappen is vergrendeld. ${formatId(apiError.requestId)}`, { type: 'danger' });
                            break;
                        case 203:
                        case 207:
                            await notifications.show(
                                this.t.tr('translation:global.errors.default-title'), //
                                this.t.tr('translation:global.errors.default-invalid').replace('{id}', formatId(apiError.requestId)),
                                {
                                    type: 'danger'
                                }
                            );
                            this.error(location, {
                                json,
                                incomingError,
                                apiError
                            });
                            break;
                        default:
                            await onGenericError(apiError, apiError.requestId);
                            break;
                    }
                    break;
            }
        } catch (e) {
            await onGenericError(e, 'Unavailable');
        }
    }
}
