import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    Email,
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { cloneDeep } from '../../../../../infra/utilities';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
@inject(I18N)
export class WidgetPatientEmails {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public descriptions: { [key: string]: string }[];

    public constructor(
        public t: I18N //
    ) {
        this.descriptions = [
            {
                nl: t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.private', { lng: 'nl' }),
                en: t.tr('translation:global.labels.private', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];
    }

    public bound(): void {
        if (isNotDefined(this.registration)) return;

        this.validation.values = this.patient.emails.map((x) => ({
            valid: true,
            email: true,
            phone: true
        }));

        // Combine default descriptions with custom descriptions
        // that are used by the user.
        const descriptions = [
            ...this.descriptions, //
            ...this.patient.emails.map((x) => x.translations)
        ] // Filter out the custom option
            .filter((x) => x[this.language] !== this.t.tr('translation:global.labels.custom', { lng: this.language }))
            .orderBy((x) => x[this.language]);

        // Re-add the custom option on the end and make
        // sure there aren't any duplicates.
        this.descriptions = [
            ...descriptions,
            {
                nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), //
                en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ].distinct((x) => x[this.language]);

        // Add current emails.

        this.registration.widget.result.value = cloneDeep(this.patient.emails);
        this.registration.widget.result.type = WidgetResultTypes.Array;

        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientEmailAdresses,
                onSave: async (): Promise<void> => {
                    if (isNotDefined(this.registration.widget.result.value)) return;
                    // Filter out the phone numbers that don't have a value entered.
                    this.registration.widget.result.value = this.registration.widget.result.value.filter((email: Email) => {
                        const cleaned = email.value?.replace(/_/g, '');
                        return isDefined(cleaned) && isNotEmpty(cleaned);
                    });
                    // Save the phone numbers to the patient and
                    // let all listeners know the patient has been updated.
                    this.patient.emails = this.registration.widget.result.value.map(Email.fromJS);
                },
                validate: (result: WidgetResult, validation: any): boolean => {
                    for (let index = 0; index < result.value.length; index++) {
                        // Reset the validation.
                        resetValidation(validation.values[index]);
                        // Remove mask placeholder characters.
                        const email = result.value[index].value;
                        // Only validate if user entered something.
                        if (isNotDefined(email) || isEmpty(email)) continue;
                        // Validate the phone number
                        validation.values[index].email =
                            isNotEmpty(email) &&
                            isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);
                        validation.values[index].valid = validation.values[index].email;
                    }

                    return validation.values.every((x: any) => x.valid);
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
