<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/label-type-to-string" />
<import from="bx/bx-keywords-selector/bx-keywords-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header if.bind="baseLoaded">
            <div class="flex justify-between">
                <div class="flex gap-x-1 font-medium text-gray-500">
                    <span if.bind="masterData.type === 'medication'" t="translation:partial-views.master-data.labels.side-effects"></span>
                    <span if.bind="masterData.type === 'label'" t="translation:partial-views.master-data.labels.label"></span>
                    <span if.bind="item.translations" class="text-gray-800">${ item.translations | translate }</span>
                    <span else class="text-gray-800">${ item.name }</span>
                </div>
                <ux-button if.bind="masterData.type === 'medication'" click.trigger="addItem()" padding.bind="false" size="xs">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </ux-button>
            </div>
            <div if.bind="!medication.valid " class="mb-3 mt-1 flex items-center gap-x-1 text-xs text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
                <p t="translation:partial-views.master-data.messages.invalid-property"></p>
            </div>
        </ux-header>
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div ref="scrollContainer" class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 flex-col">
                        <template if.bind="masterData.type === 'medication'">
                            <ul>
                                <li class="mt-3 text-sm" if.bind="medication.items.length === 0">
                                    <p t="translation:partial-views.master-data.messages.side-effects"></p>
                                </li>
                                <li class="space-y-1 border-b border-gray-200 py-3" repeat.for="item of medication.items">
                                    <ux-label>
                                        <span t="translation:partial-views.master-data.labels.side-effect"></span>
                                    </ux-label>
                                    <div class="flex">
                                        <ux-textarea
                                            action.bind="c => manageTranslationsFor($index)"
                                            valid.bind="medication.validation[$index].description"
                                            value.two-way="item.description[language]"
                                            rows="1"
                                            rounded="left"
                                            t="[placeholder]translation:partial-views.master-data.placeholders.enter-description">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                            </svg>
                                        </ux-textarea>
                                        <button
                                            class.bind="medication.validation[$index].description ? 'border-gray-300' : 'border-red-500'"
                                            class="inline-flex min-h-[38px] items-center rounded-r-lg border-y border-r bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-30 focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                            click.trigger="deleteItem($index)">
                                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <bx-keywords-selector
                                        t="[placeholder]translation:partial-views.master-data.placeholders.keywords"
                                        workspace.bind="authenticated.workspace.id"
                                        values.two-way="item.keywords">
                                    </bx-keywords-selector>
                                </li>
                            </ul>
                        </template>
                        <template if.bind="masterData.type === 'label'">
                            <div class="mt-2 flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.master-data.labels.select-label-type"></span>
                                </ux-label>
                                <ux-select value.two-way="label.type">
                                    <ux-select-option repeat.for="type of LabelTypes | keys" value.bind="type"> ${type | labelTypeToString | t} </ux-select-option>
                                </ux-select>
                            </div>
                        </template>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading || deleting"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
