import { bindable, slotted } from 'aurelia';

export class UxTab {
    @bindable() public active: boolean = false;
    @bindable() public href: string = 'javascript:';
    @bindable() public mode: 'light' | 'dark' = 'dark';
    @bindable() public valid: boolean = true;
    @bindable() public padding: string = 'py-2 px-4';
    @bindable() public icon: string = 'size-5';

    @(slotted() as any) slot: HTMLElement[];

    public hasIcon: boolean = false;

    public slotChanged(): void {
        this.hasIcon = true;
    }
}
