import { GetContactResponse } from '@wecore/sdk-crm';
import { bindable, containerless } from 'aurelia';

@containerless
export class TabContactsConnections {
    @bindable() public contact: GetContactResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
}
