<import from="converters/keys" />
<import from="converters/insurance-type-to-string" />
<import from="bx/bx-vat-category-selector/bx-vat-category-selector" />
<import from="bx/bx-insurer-selector/bx-insurer-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 flex-col gap-y-3 pb-5 pt-8">
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.vat-category"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <bx-vat-category-selector
                                allow-clear.bind="true"
                                on-select.bind="handleCategorySelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                value.two-way="price.vat.id"
                                valid.bind="validation.vat"
                                t="[placeholder]translation:partial-views.manage-healthcare-prices.placeholders.select-vat">
                            </bx-vat-category-selector>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label>
                                    <span t="translation:partial-views.manage-healthcare-prices.labels.gross-value"> </span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-input
                                type="number"
                                oninput.trigger="handleGrossChanged($event)"
                                disabled.bind="!price.vat"
                                valid.bind="validation.grossPrice"
                                value.two-way="price.grossValue"
                                t="[placeholder]translation:partial-views.manage-healthcare-prices.placeholders.enter-price">
                            </ux-input>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label>
                                    <span t="translation:partial-views.manage-healthcare-prices.labels.net-value"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-input
                                type="number"
                                oninput.trigger="handleNetChanged($event)"
                                disabled.bind="!price.vat"
                                valid.bind="validation.netPrice"
                                select-text-on-focus.bind="true"
                                value.two-way="price.netValue"
                                t="[placeholder]translation:partial-views.manage-healthcare-prices.placeholders.enter-price">
                            </ux-input>
                        </div>
                        <div class="flex flex-col">
                            <ux-label>
                                <span t="translation:partial-views.manage-healthcare-prices.labels.vat-value"></span>
                            </ux-label>
                            <ux-input type="number" disabled.bind="true" value.two-way="price.vatValue"> </ux-input>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label>
                                    <span t="translation:partial-views.manage-healthcare-prices.labels.type"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <p class="mb-1 text-xs text-gray-500" t="translation:partial-views.manage-healthcare-prices.messages.type"></p>
                            <ux-select
                                disabled.bind="!price.vat"
                                value.two-way="price.insuranceType"
                                valid.bind="validation.type"
                                t="[placeholder]translation:partial-views.manage-healthcare-prices.placeholders.select-type">
                                <ux-select-option repeat.for="type of HealthcareInsuranceTypes | keys" value.bind="type"> ${ type | insuranceTypeToString | t } </ux-select-option>
                            </ux-select>
                        </div>
                        <div class="col-span-6 flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-healthcare-prices.labels.period-start"></span>
                            </ux-label>
                            <ux-datepicker
                                onclear.trigger="handlePeriodCleared($event, 'start')"
                                allow-clear.bind="true"
                                disabled.bind="!price.vat"
                                mode="input"
                                valid.bind="validation.periodStart && validation.overlaps && validation.overlapsInsurer"
                                value.two-way="price.periodStart"
                                t="[placeholder]translation:global.placeholders.select-date">
                            </ux-datepicker>
                        </div>
                        <div class="col-span-6 flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-healthcare-prices.labels.period-end"></span>
                            </ux-label>
                            <ux-datepicker
                                onclear.trigger="handlePeriodCleared($event, 'end')"
                                allow-clear.bind="true"
                                disabled.bind="!price.vat"
                                mode="input"
                                valid.bind="validation.periodEnd && validation.overlaps && validation.overlapsInsurer"
                                value.two-way="price.periodEnd"
                                t="[placeholder]translation:global.placeholders.select-date">
                            </ux-datepicker>
                        </div>
                        <p if.bind="!validation.period" class="col-span-12 text-xs text-red-500" t="translation:partial-views.manage-healthcare-prices.messages.invalid-period"></p>
                        <p if.bind="!validation.overlaps" class="col-span-12 text-xs text-red-500" t="translation:partial-views.manage-healthcare-prices.messages.period-overlaps"></p>
                        <p if.bind="!validation.overlapsInsurer" class="col-span-12 text-xs text-red-500" t="translation:partial-views.manage-healthcare-prices.messages.period-overlaps-insurer"></p>
                        <div class="col-span-6 flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.insurer"></span>
                            </ux-label>
                            <bx-insurer-selector
                                allow-clear.bind="true"
                                disabled.bind="!price.vat"
                                on-select.bind="handleInsurerSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                value.two-way="price.insurer.id"
                                t="[placeholder]translation:partial-views.manage-healthcare-prices.placeholders.select-insurer">
                            </bx-insurer-selector>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="primary"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.process"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
