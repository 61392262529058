import { DialogController, IDialogController } from '@aurelia/dialog';
import { isDefined, isNotEmpty, validateState } from '@wecore/sdk-utilities';

import { inject } from 'aurelia';
import { EventDetails } from '../../models/event-details';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(IDialogController)
export class ModalRenameValue {
    public value: string;
    public validation: any = { value: true };

    public constructor(
        private readonly controller: DialogController //
    ) {}

    public activate(params: any): void {
        this.value = params.value;
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) await this.controller.ok(this.value);
    }

    public validate(): boolean {
        this.validation.value = isDefined(this.value) && isNotEmpty(this.value);
        return validateState(this.validation);
    }

    public async handleKeydown(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.save();
    }

    public async cancel(): Promise<void> {
        await this.controller.cancel();
    }
}
