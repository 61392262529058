<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="converters/join" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full px-6 overflow-y-auto scroll-smooth">
            <div class="sticky top-0 pt-6 pb-2 bg-white border-b border-gray-200">
                <div class="flex items-center justify-between mb-3">
                    <p class="text-sm font-medium" t="translation:partial-views.add-treatment-therapies.labels.select-actions"></p>
                </div>
                <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>

                <div if.bind="results && results.length > 0" class="flex flex-col pt-5 pb-4 mt-3 border-t border-gray-200">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.add-treatment-therapies.labels.select-results"></span>
                    </ux-label>
                    <ux-multi-selector
                        options.bind="toSet()"
                        show-footer.bind="false"
                        force-remove.bind="true"
                        autocomplete.bind="true"
                        item-template.bind="getExtra"
                        values.two-way="selectedResults"
                        t="[placeholder]translation:partial-views.connect-therapies.placeholders.select-diagnoses">
                    </ux-multi-selector>
                </div>
            </div>
            <template if.bind="baseLoaded">
                <ul class="flex flex-col flex-1">
                    <li repeat.for="therapy of therapies" class="group">
                        <let added.bind="isAlreadyAdded(therapy)"></let>
                        <template if.bind="added !== null && added !== undefined">
                            <div class="flex items-center justify-between py-3 border-b border-gray-200">
                                <div class="flex flex-col flex-1">
                                    <p class="text-sm font-semibold">${ added.name | translate:language }</p>
                                    <div class="flex gap-1 text-xs text-red-500">
                                        <p t="translation:partial-views.add-treatment-therapies.labels.is-already-added-in"></p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <a else click.trigger="select(therapy)" id.bind="therapy.id" href="javascript:" class="flex items-center justify-between py-3 border-b border-gray-200">
                            <div class="flex flex-col flex-1">
                                <p class="text-sm font-semibold group-hover:text-gray-600">${ therapy.name | translate:language }</p>
                            </div>
                            <template if.bind="selected.some((x) => x.id === therapy.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </template>
                        </a>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="templates.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-4 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
            <div class="sticky bottom-0 z-50 flex justify-end py-4 bg-white border-t border-gray-200 gap-x-2">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button size="sm" color="primary" disabled.bind="isLoading || deleting || selected.length === 0" loading.bind="isLoading" click.trigger="add()">
                        <span class="pointer-events-none" t="global.buttons.add"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
