import { I18N } from '@aurelia/i18n';
import { GetDifferentialDiagnosisResponse, GetMedicalTherapyResponse, MedicalTherapiesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotEmpty } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { UxMultiSelector } from '../../ux/ux-multi-selector/ux-multi-selector';

@containerless
@inject(MedicalTherapiesApiClient, I18N, ErrorHandler)
export class BxTherapyMultiSelector {
    @bindable() public values: string[];
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public data: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public onChange: (options: GetMedicalTherapyResponse[], mutation: 'added' | 'deleted', changed: GetMedicalTherapyResponse, data: any) => void;
    @bindable() public onClear: () => void;
    @bindable() public onValueClick: (option: { value: string; text: string; data?: any }) => Promise<void>;

    public therapies: GetMedicalTherapyResponse[];
    public options: { text: string; value: string; data?: any }[];
    public selector: UxMultiSelector;
    private selection: GetMedicalTherapyResponse[] = [];

    public constructor(
        private readonly therapiesApi: MedicalTherapiesApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = this.t.tr('global.labels.search');
    }

    public async attached(): Promise<void> {
        try {
            if (isDefined(this.values) && this.values.any()) {
                const response = await this.therapiesApi.search(this.workspace, undefined, this.values.length, 0, undefined, undefined, undefined, this.values);
                this.selection = response.data;
            }

            const response = await this.therapiesApi.search(this.workspace, '', 50, 0);
            // Combine the selection with the response so that the getOption()
            // in the ux-multi-selector.ts can find the correct option.
            this.therapies = [
                // Make sure the selection is sorted in the same order as the values.
                ...this.selection.sort((a, b) => {
                    const indexA = this.values.indexOf(a.id);
                    const indexB = this.values.indexOf(b.id);
                    return indexA - indexB;
                }),
                ...response.data
            ];

            this.map();
        } catch (e) {
            this.errorHandler.handle('BxTherapyMultiSelector.attached', e);
        }
    }

    public handleSearch = async (query: string): Promise<void> => {
        try {
            const response = await this.therapiesApi.search(this.workspace, isNotEmpty(query) ? query : '', 50, 0);
            this.therapies = response.data.filter((x) => !this.values.some((id) => id === x.id));

            this.map();
        } catch (e) {
            this.errorHandler.handle('BxTherapyMultiSelector.handleSearch', e);
        }
    };

    public handleSelected = async (option: { text: string; value: string; data?: any }): Promise<void> => {
        const therapy = this.therapies.find((x) => x.id === option.value);
        this.selection = [...this.selection, therapy];
        this.onChange(this.selection, 'added', therapy, this.data);
    };

    public handleRemoved = async (option: { text: string; value: string; data?: any }): Promise<void> => {
        const index = this.selection.findIndex((x) => x.id === option.value);

        let deleted: GetDifferentialDiagnosisResponse;
        if (index > -1) {
            deleted = this.selection[index];
            this.selection.splice(index, 1);
            const indexOfValue = this.values.findIndex((x) => x === deleted.id);
            this.values.splice(indexOfValue, 1);
        }
        this.onChange(this.selection, 'deleted', deleted, this.data);
    };

    public handleClear(): void {
        if (isFunction(this.onClear)) this.onClear();
    }

    private map(): void {
        this.options = this.therapies.map((x) => ({ text: x.name[this.language], value: x.id }));
    }
}
