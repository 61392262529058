import { HealthcareSectors } from '@wecore/sdk-healthcare';

export class HealthcareSectorToStringValueConverter {
    public toView(sector: HealthcareSectors): string {
        switch (sector) {
            case HealthcareSectors.Dietician:
                return 'translation:global.labels.dietician';
            case HealthcareSectors.OccupationalTherapy:
                return 'translation:global.labels.occupational-therapy';
            case HealthcareSectors.SkinTherapy:
                return 'translation:global.labels.skin-therapy';
            case HealthcareSectors.SpeechTherapy:
                return 'translation:global.labels.speech-therapy';
            case HealthcareSectors.ExerciseTherapy:
                return 'translation:global.labels.exercise-therapy';
            case HealthcareSectors.Optometrist:
                return 'translation:global.labels.optometrist';
            case HealthcareSectors.Orthopedics:
                return 'translation:global.labels.orthopedics';
            case HealthcareSectors.Podiatry:
                return 'translation:global.labels.podiatry';
            case HealthcareSectors.PhysioTherapy:
                return 'translation:global.labels.physio-therapy';
            case HealthcareSectors.MedicalImagingAndRadiation:
                return 'translation:global.labels.medical-imaging-and-radiation';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
