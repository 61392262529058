import { DeclarationStatuses } from '@wecore/sdk-healthcare';

export class DeclarationStatusToColorValueConverter {
    public toView(type: DeclarationStatuses, mode: 'badge' | 'background' | 'text' = 'badge'): string {
        switch (type) {
            case DeclarationStatuses.Draft:
                return mode === 'background' ? 'bg-primary' : mode === 'text' ? 'text-primary' : 'info';
            case DeclarationStatuses.SentToVecozo:
            case DeclarationStatuses.SentToInsuranceCompany:
            case DeclarationStatuses.DownloadedForInfomedics:
                return mode === 'background' ? 'bg-purple-500' : mode === 'text' ? 'text-purple-500' : 'secondary';
            case DeclarationStatuses.FailedInternalValidation:
            case DeclarationStatuses.RejectedByVecozo:
            case DeclarationStatuses.FailedToConfirm:
            case DeclarationStatuses.FailedToSend:
            case DeclarationStatuses.FailedToCheck:
            case DeclarationStatuses.Rejected:
                return mode === 'background' ? 'bg-red-500' : mode === 'text' ? 'text-red-500' : 'danger';
            case DeclarationStatuses.PartiallyAccepted:
                return mode === 'background' ? 'bg-yellow-500' : mode === 'text' ? 'text-yellow-500' : 'warning';
            case DeclarationStatuses.Archived:
                return mode === 'background' ? 'bg-gray-800' : mode === 'text' ? 'text-gray-800' : 'primary';
            case DeclarationStatuses.Accepted:
                return mode === 'background' ? 'bg-green-500' : mode === 'text' ? 'text-green-500' : 'success';
        }
    }
}
