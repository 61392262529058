import { GetMedicalRecordRegistrationResponse, GetMedicalRecordResponse, MedicalTherapyExecutionItem, MedicalTherapyExecutionItemTypes } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../../../infra/events';
import { generateColumns, getSettingsId } from '../../../../../../../infra/utilities';
import { FlattenedExaminationStep } from '../../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../../models/selected-file';
import { StepState } from '../../../../../../../models/step-state';

@containerless
@inject(IEventAggregator)
export class TemplateTherapyCategory {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public item: MedicalTherapyExecutionItem;
    @bindable() public category: MedicalTherapyExecutionItem;
    @bindable() public validation: any;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;

    public columns: string;
    public MedicalTherapyExecutionItemTypes: typeof MedicalTherapyExecutionItemTypes = MedicalTherapyExecutionItemTypes;
    public shouldBeDisplayed: boolean = false;
    private subscriptions: any[] = [];

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.columns = generateColumns(
            this.registration.therapyExecution.value.flow.breakpoints?.filter((x) => x.id === getSettingsId(this.item)) || [] //
        );
        if (isNotDefined(this.item.attributes)) this.item.attributes = {};
        if (isNotDefined(this.states[this.item.id]))
            this.states[this.item.id] = new StepState({
                stepId: this.item.id,
                expanded: false,
                answered: false
            });

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings()),
            this.events.subscribe(CustomEvents.ExaminationTherapyCategoryChangedExpanded, (data: { stepId: string; expand: boolean; container: string }) => {
                const shouldChange =
                    // Only categories in the same container should be affected.
                    data.container === this.registration.id &&
                    this.registration.therapyExecution.value.flow.connectedCategories //
                        .filter((x) => x.key === getSettingsId(this.item))
                        .some((x) => x.value === data.stepId);
                if (shouldChange) this.states[this.item.id].expanded = data.expand;
            })
        ];

        // Evaluate the requirements on load of the therapy step.
        this.evaluateSettings();
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.states[this.item.id].expanded = !this.states[this.item.id].expanded;
        this.events.publish(CustomEvents.ExaminationTherapyCategoryChangedExpanded, {
            stepId: getSettingsId(this.item), //
            expand: this.states[this.item.id].expanded,
            // Make sure we only affect categories in the same container.
            container: this.registration.id
        });
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.item.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;

        // TODO: Delete step values of the steps below this category (including any files)?
    }
}
