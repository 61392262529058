import * as signalR from '@microsoft/signalr';
import { DeclarationStatusLog } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { AuthenticationService } from '../../services/service.authentication';
import { getToken } from '../utilities';
import { Broadcasters } from './broadcasters';
import { Broadcasts } from './broadcasts';

@inject(AureliaConfiguration, IEventAggregator, AuthenticationService)
export class VecozoBroadcaster {
    private connection: signalR.HubConnection;

    public constructor(
        private readonly config: AureliaConfiguration, //
        private readonly events: IEventAggregator,
        private readonly auth: AuthenticationService
    ) {}

    public async start(workspace: string): Promise<void> {
        const baseUrl = this.config.get('api.integrations');
        const token = await getToken(this.auth);

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}/hubs/vecozo?workspace=${workspace}`, {
                accessTokenFactory: () => token,
                headers: { workspace }
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        this.connection.on(Broadcasts.OnConnected, (connectionId: string) => {
            this.events.publish(Broadcasts.OnConnected, {
                broadcaster: Broadcasters.VecozoBroadcaster,
                connectionId
            });
        });

        this.connection.onreconnecting((error) => {
            this.events.publish(Broadcasts.OnReconnecting, {
                broadcaster: Broadcasters.VecozoBroadcaster,
                state: this.connection.state,
                error
            });
        });

        this.connection.onreconnected((error) => {
            this.events.publish(Broadcasts.OnReconnected, {
                broadcaster: Broadcasters.VecozoBroadcaster,
                state: this.connection.state,
                error
            });
        });

        this.connection.onclose((error) => {
            this.events.publish(Broadcasts.OnDisconnected, {
                broadcaster: Broadcasters.VecozoBroadcaster,
                state: this.connection.state,
                error
            });
        });

        this.connection.on(Broadcasts.DeclarationStatusLog, (_: string, log: DeclarationStatusLog) => {
            this.events.publish(Broadcasts.DeclarationStatusLog, { log: DeclarationStatusLog.fromJS(log) });
        });

        await this.connection.start();
    }

    public async stop(): Promise<void> {
        if (isNotDefined(this.connection)) return;
        await this.connection.stop();
    }

    public async status(): Promise<'Disconnected' | 'Connecting' | 'Connected' | 'Disconnecting' | 'Reconnecting'> {
        if (isNotDefined(this.connection)) return 'Disconnected';
        return this.connection.state;
    }
}
