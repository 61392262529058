<template class="flex">
    <template if.bind="src">
        <div
            border.class="border"
            class="image rounded-md border-gray-300"
            css="
                background-image: url('${src}'); 
                background-position: ${bgPosition}; 
                background-repeat: ${bgRepeat}; 
                background-size: ${bgSize}; 
                height: ${height}; 
                width: ${width};
                object-fit: contain';"></div>
    </template>
</template>
