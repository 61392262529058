import { I18N } from '@aurelia/i18n';
import { KeywordsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotEmpty } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { UxMultiSelector } from '../../ux/ux-multi-selector/ux-multi-selector';

@containerless
@inject(KeywordsApiClient, I18N, ErrorHandler)
export class BxKeywordsSelector {
    @bindable() public values: string[];
    @bindable() public valid: boolean = true;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public onChange: (keywords: string[]) => void;
    @bindable() public onClear: () => void;

    public selector: UxMultiSelector;
    public options: { text: string; value: string; data?: any }[] = [];

    public constructor(
        private readonly keywordsApi: KeywordsApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.placeholder = t.tr('global.placeholders.keywords');
        this.searchPlaceholder = this.t.tr('global.labels.search');
    }

    public async setSelected(values: string[]): Promise<void> {
        await this.selector.setSelected(values);
    }

    public async attached(): Promise<void> {
        setTimeout(async () => {
            if (isDefined(this.selector)) {
                // if (isDefined(this.values) && this.values.any()) await this.selector.setSelected(this.values);
                this.selector.isLoading = false;
            }
        }, 250);
    }

    public handleSearch = async (query: string): Promise<void> => {
        try {
            if (isDefined(this.selector)) this.selector.isLoading = true;
            const response = await this.keywordsApi.search(this.workspace, isNotEmpty(query) ? query : '', 25, 0);

            this.options = response.data.map((keyword) => ({ text: keyword, value: keyword, data: null }));
            if (isDefined(this.selector)) this.selector.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxKeywordsSelector.handleSearch', e);
        }
    };

    public handleClear(): void {
        if (isFunction(this.onClear)) this.onClear();
    }
}
