import { InputTypes } from '@wecore/sdk-healthcare';

export class InputTypeToStringValueConverter {
    public toView(type: InputTypes): string {
        switch (type) {
            case InputTypes.FreeEntry:
                return 'global.labels.free-entry';
            case InputTypes.Datepicker:
                return 'global.labels.date-picker';
            case InputTypes.Filepicker:
                return 'global.labels.file-picker';
            case InputTypes.Selector:
                return 'global.labels.selection';
            case InputTypes.RangeSlider:
                return 'global.labels.range-slider';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
