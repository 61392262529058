import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { FileTypesApiClient, GetFileTypeResponse } from '@wecore/sdk-core';
import {
    AffixPositions,
    DifferentialDiagnosesApiClient,
    DifferentialDiagnosisEntityReference,
    FileType,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalQuestionCategoryResponse,
    GetMedicalQuestionnaireResponse,
    InformationSheet,
    InputRules,
    InputTypes,
    MedicalExaminationPhaseEntityReference,
    MedicalQuestionCategoryEntityReference,
    MedicalQuestionnaireEntityReference,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient,
    MedicalResult,
    ResultMatcherTypes,
    ResultTypes,
    SliderSettings,
    UpdateMedicalQuestionRequest
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { ComponentChoicesSelector } from '../../../components/component-choices-selector/component-choices-selector';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, cloneDeep, getFileTypeTranslation, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxCombobox } from '../../../ux/ux-combobox/ux-combobox';
import { UxMultiSelector } from '../../../ux/ux-multi-selector/ux-multi-selector';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    MedicalQuestionsApiClient,
    MedicalQuestionnairesApiClient,
    FileTypesApiClient,
    DifferentialDiagnosesApiClient,
    ModalService
)
export class PartialMedicalQuestionsEdit extends BasePartialView {
    public questionnaires: GetMedicalQuestionnaireResponse[];
    public diagnosis: GetDifferentialDiagnosisResponse[];
    public ResultTypes: typeof ResultTypes = ResultTypes;
    public InputTypes: typeof InputTypes = InputTypes;
    public AffixPositions: typeof AffixPositions = AffixPositions;
    public ResultMatcherTypes: typeof ResultMatcherTypes = ResultMatcherTypes;
    public inputTypesToShow: InputTypes[] = [InputTypes.FreeEntry, InputTypes.Selector];
    public inputTypeSelector: UxSelect;
    public fileTypeSelector: UxCombobox;
    public choicesSelector: ComponentChoicesSelector;
    public expectedAnswer: UxSelect;
    public expectedAnswers: UxMultiSelector;
    public defaultValue: UxSelect;
    public defaultValues: UxMultiSelector;
    public fileTypes: GetFileTypeResponse[];
    public questionId: string;
    public question: UpdateMedicalQuestionRequest;
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        content: true,
        expectedAnswer: true,
        expectedAnswers: true,
        inputAmount: true,
        inputAmountValid: true,
        filesAmount: true,
        choices: [],
        numericChoices: true,
        choice: true,
        minRange: true,
        maxRange: true,
        minValue: true,
        maxValue: true,
        startValue: true,
        endValue: true,
        endValueValid: true,
        defaultValue: true,
        initialValue: true,
        initialValueValid: true,
        majorIncrement: true,
        majorIncrementValid: true,
        minorIncrement: true,
        minorIncrementValid: true,
        labels: []
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly questionsApi: MedicalQuestionsApiClient,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient,
        private readonly fileTypesApi: FileTypesApiClient,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.questionId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [question, questionnaires, fileTypes, diagnoses] = await Promise.all([
                    this.questionsApi.getById(this.questionId, this.authenticated.workspace.id), //
                    this.getAllQuestionnaires(),
                    this.fileTypesApi.getAll(250),
                    this.diagnosesApi.getAll(this.authenticated.workspace.id, 1000)
                ]);
                this.question = question;
                this.questionnaires = questionnaires;
                this.fileTypes = fileTypes.data;
                this.diagnosis = diagnoses.data;

                if (isNotDefined(this.question.placeholder)) this.question.placeholder = setTranslation({}, this.language);
                if (isNotDefined(this.question.expectedAnswer)) this.question.expectedAnswer = new MedicalResult();
                if (isNotDefined(this.question.defaultValue)) this.question.defaultValue = new MedicalResult();
                this.selectInputTypes(this.question.answerType, false);

                for (const choice of this.question.choices) {
                    // Because the ID property was added later, we have to
                    // make sure all items have an ID property.
                    if (isNotDefined(choice.id)) choice.id = guid();
                    this.validation.choices.push({
                        id: choice.id,
                        value: true,
                        score: true,
                        numeric: true,
                        unique: true
                    });
                }

                for (const _ of this.question.slider.labels) {
                    this.validation.labels.push({
                        value: true,
                        valueValid: true,
                        unique: true,
                        label: true
                    });
                }

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalQuestionsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async handleInputAmountChanged(): Promise<void> {
        // Reset the expected changes when input amount changes.
        this.question.expectedAnswer.value = null;
        this.question.expectedAnswers = [];
        this.question.defaultValue.value = null;
        this.question.defaultValues = [];

        // Reset validation
        this.validation.expectedAnswer = true;
        this.validation.expectedAnswers = true;
        this.validation.inputAmount = true;
        this.validation.inputAmountValid = true;

        if (isDefined(this.expectedAnswer)) {
            await this.expectedAnswers.clear();
            this.expectedAnswers.refresh(
                this.toSet(this.question.choices, 'Default') //
            );
        }
        if (isDefined(this.defaultValues)) {
            await this.defaultValues.clear();
            this.defaultValues.refresh(
                this.toSet(this.question.choices, 'Default') //
            );
        }
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.question.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public handleQuestionnaireSelected = async (questionnaires: GetMedicalQuestionnaireResponse[]): Promise<void> => {
        this.question.questionnaires = questionnaires.map(
            (questionnaire) =>
                new MedicalQuestionnaireEntityReference({
                    id: questionnaire.id,
                    translations: questionnaire.name
                })
        );
    };

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        // Copy over the keywords used for the diagnoses
        // whilst keeping the manually added ones.
        this.question.keywords = [
            ...this.question.keywords, //
            ...(diagnoses.any() //
                ? diagnoses.selectMany<GetDifferentialDiagnosisResponse, string>((x) => x.keywords)
                : [])
        ].distinct((x) => x);
        this.question.differentialDiagnoses = diagnoses.map(
            (d) =>
                new DifferentialDiagnosisEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public handleCategoryChanged = async (categories: GetMedicalQuestionCategoryResponse[]): Promise<void> => {
        this.question.categories = categories.map(
            (d) =>
                new MedicalQuestionCategoryEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public handleChoicesChanged = async (): Promise<void> => {
        this.question.expectedAnswer.value = null;
        this.question.expectedAnswers = [];
        this.question.defaultValue.value = null;
        this.question.defaultValues = [];

        setTimeout(async () => {
            if (isDefined(this.expectedAnswer)) {
                await this.expectedAnswers.clear();
                this.expectedAnswers.refresh(
                    this.toSet(this.question.choices, 'Default') //
                );
            }
            if (isDefined(this.defaultValues)) {
                await this.defaultValues.clear();
                this.defaultValues.refresh(
                    this.toSet(this.question.choices, 'Default') //
                );
            }
        }, 250);
    };

    public handleExpectedAnswersSelected = (option: { value: string; text: string }) => {
        const value = this.question.choices.find((x) => x.value === option.value);
        this.question.expectedAnswers.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleExpectedAnswersRemoved = (_: { value: string; text: string }, index: number) => {
        this.question.expectedAnswers.splice(index, 1);
    };

    public async handleExpectedAnswerSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const details = e.detail.values;
        if (isNotDefined(details)) this.question.expectedAnswer.value = null;
        else
            this.question.expectedAnswer = MedicalResult.fromJS(
                cloneDeep(this.question.choices.find((x) => x.value === details.value)) //
            );
    }

    public handleDefaultValueSelected = (option: { value: string; text: string }) => {
        const value = this.question.choices.find((x) => x.value === option.value);
        this.question.defaultValues.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleDefaultValueRemoved = (_: { value: string; text: string }, index: number) => {
        this.question.defaultValues.splice(index, 1);
    };

    public async handleDefaultChoiceSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const details = e.detail.values;
        if (isNotDefined(details)) this.question.defaultValue.value = null;
        else
            this.question.defaultValue = MedicalResult.fromJS(
                cloneDeep(this.question.choices.find((x) => x.value === details.value)) //
            );
    }

    public async handleAnswerTypeSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const type = e.detail.values.value as ResultTypes;

        this.question.choices = [];
        if (isDefined(this.expectedAnswer)) {
            await this.expectedAnswers.clear();
            this.expectedAnswers.refresh(
                this.toSet(this.question.choices, 'Default') //
            );
        }
        if (isDefined(this.defaultValues)) {
            await this.defaultValues.clear();
            this.defaultValues.refresh(
                this.toSet(this.question.choices, 'Default') //
            );
        }

        // Reset certain certain when were switching the answer type.
        this.inputTypesToShow = null;
        this.question.filesAmount = 0;
        this.question.allowedFileTypes = [];
        this.question.inputAmount = 1;
        this.question.expectedAnswer.value = null;
        this.question.expectedAnswers = [];
        this.question.defaultValue.value = null;
        this.question.defaultValues = [];
        this.question.affix = null;
        this.question.affixPosition = AffixPositions.Suffix;
        this.question.resultMatcher.type = ResultMatcherTypes.SimpleComparison;
        this.question.resultMatcher.maxRange = null;
        this.question.resultMatcher.minRange = null;
        this.question.inputRules = new InputRules();
        this.question.slider = new SliderSettings({
            minorIncrement: 0,
            labels: []
        });
        this.question.isMultipleChoice = false;

        this.selectInputTypes(type, true);
    }

    public async handleInputTypeSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const type = e.detail.values.value;
        // Reset certain values when were switching the input type.
        this.question.filesAmount = 0;
        this.question.allowedFileTypes = [];
        this.question.choices = [];
        this.question.inputAmount = 1;
        this.question.expectedAnswer.value = null;
        this.question.expectedAnswers = [];
        this.question.defaultValue.value = null;
        this.question.defaultValues = [];
        this.question.affix = null;
        this.question.affixPosition = AffixPositions.Suffix;
        this.question.resultMatcher.type = ResultMatcherTypes.SimpleComparison;
        this.question.resultMatcher.maxRange = null;
        this.question.resultMatcher.minRange = null;
        this.question.inputRules = new InputRules();
        this.question.slider = new SliderSettings({
            minorIncrement: 0,
            labels: []
        });
        this.question.isMultipleChoice = false;

        if (type === InputTypes.Selector) {
            setTimeout(() => {
                if (isDefined(this.choicesSelector) && this.question.choices.empty()) this.choicesSelector.add();
            }, 150);
        } else this.question.choices = [];
    }

    public handleFileTypeSelected = (option: { value: string; text: string }): void => {
        const type = this.fileTypes.find((x) => x.id === option.value);
        this.question.allowedFileTypes.push(type);
    };

    public handleFileTypeRemoved = (option: { value: string; text: string }): void => {
        const index = this.question.allowedFileTypes.findIndex((x) => x.id === option.value);
        if (index > -1) this.question.allowedFileTypes.splice(index, 1);
    };

    public async handleResultMatcherSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        this.question.resultMatcher.minRange = null;
        this.question.resultMatcher.maxRange = null;
        this.question.expectedAnswer.value = null;
        this.question.expectedAnswers = [];
        this.question.defaultValue.value = null;
        this.question.defaultValues = [];
    }

    public async removeFileType(type: GetFileTypeResponse): Promise<void> {
        const index = this.question.allowedFileTypes.findIndex((x) => x.id === type.id);
        this.question.allowedFileTypes.splice(index, 1);

        // Force the 'used' binding to update.
        this.question.allowedFileTypes = [
            ...(this.question.allowedFileTypes.length > 0 ? [this.question.allowedFileTypes.shift()] : []), //
            ...this.question.allowedFileTypes
        ];
        // this.request.allowedFileTypes = this.request.allowedFileTypes.changed();
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['content', 'placeholder'], this.question, 1);
                if (isDefined(this.question.expectedAnswer) && isNotDefined(this.question.expectedAnswer.value)) this.question.expectedAnswer = null;
                if (isDefined(this.question.defaultValue) && isNotDefined(this.question.defaultValue.value)) this.question.defaultValue = null;
                await this.questionsApi.update(this.questionId, this.authenticated.workspace.id, this.question);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-questions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-questions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.question.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.question.placeholder)) this.question.placeholder = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[edit-medical-question]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.question[property],
            callback: (updatedTranslations: any) => {
                this.question[property] = updatedTranslations;
            },
            required
        });
    }

    public manageTranslationsForLabels = (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean): void => {
        this.manageTranslations({ translations, callback, required });
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.question.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.question.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    private async getAllQuestionnaires(): Promise<GetMedicalQuestionnaireResponse[]> {
        const take = 25;
        let skip = 0;

        let questionnaires: GetMedicalQuestionnaireResponse[] = [];

        let batch = await this.questionnairesApi.getAll(this.authenticated.workspace.id, take, skip);

        while (batch.data.length > 0) {
            questionnaires = questionnaires.concat(batch.data);
            skip += take;
            batch = await this.questionnairesApi.getAll(this.authenticated.workspace.id, take, skip);
        }

        return questionnaires;
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-questions.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-questions.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.question.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.questionsApi.delete(this.questionId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-questions.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-questions.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.question.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-question-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-questions.questions.copy.title'),
                message: this.t.tr('partial-views.medical-questions.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.questionsApi.copy(this.questionId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-questions.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.medical-questions.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-medical-question]', e);
                        }
                    }
                }
            })
        );
    }

    public toSet(choices: FileType[] | MedicalResult[], type: 'Default' | 'FileTypes'): { value: string; text: string; data?: any }[] {
        if (type === 'FileTypes') return choices.map((x: FileType) => ({ value: x.id, text: getFileTypeTranslation(x.name) }));
        return choices.map((x: MedicalResult) => ({ value: x.value, text: isDefined(x.value) ? x.value : this.t.tr('translation:global.messages.missing-value') }));
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.name = validateTranslation(this.question.name, this.language);
        this.validation.content = validateTranslation(this.question.content, this.language);
        this.validation.phase = isDefined(this.question.phase);
        this.validation.displayOrder = isNotEmpty(this.question.displayOrder) && isOfType(Number(this.question.displayOrder), 'number');
        this.validation.inputAmount = isNotEmpty(this.question.inputAmount) && isOfType(Number(this.question.inputAmount), 'number');
        this.validation.filesAmount = isNotEmpty(this.question.filesAmount) && isOfType(Number(this.question.filesAmount), 'number');

        if (this.question.resultMatcher.type === ResultMatcherTypes.BetweenRange) {
            this.validation.minRange = isNotEmpty(this.question.resultMatcher.minRange) && isOfType(Number(this.question.resultMatcher.minRange), 'number');
            this.validation.maxRange = isNotEmpty(this.question.resultMatcher.maxRange) && isOfType(Number(this.question.resultMatcher.maxRange), 'number');
        }

        if (this.question.inputType === InputTypes.Selector) {
            for (let index = 0; index < this.question.choices.length; index++) {
                const choice = this.question.choices[index];

                // Match the choice with the correct validation object by matching the id.
                // We do this because the user can change the order of the choices and so
                // we have to make sure the validation object matches the correct choice.
                const vIndex = this.validation.choices.findIndex((x: any) => x.id === choice.id);

                this.validation.choices[vIndex].value = isNotEmpty(choice.value);
                this.validation.choices[vIndex].unique = this.question.choices.filter((x) => x.value?.toLowerCase() === choice.value?.toLowerCase()).length === 1;
                this.validation.choices[vIndex].numeric =
                    this.question.answerType === ResultTypes.Number //
                        ? !isNaN(Number(choice.value))
                        : true;
                if (this.question.calculateScore) this.validation.choices[vIndex].score = isDefined(choice.score) && !isNaN(Number(choice.score));
            }

            this.validation.numericChoices = this.validation.choices.every((x) => x.numeric);

            // if (Number(this.request.inputAmount) === 1) this.validation.expectedAnswer = isNotEmpty(this.request.expectedAnswer);
            this.validation.inputAmountValid =
                // Only validate the input amount when more than 1 choices are given.
                Number(this.question.inputAmount) < 2 //
                    ? true
                    : Number(this.question.inputAmount) < this.question.choices.length;

            if (isDefined(this.question.expectedAnswers) && this.question.expectedAnswers.any()) {
                this.validation.expectedAnswers =
                    isDefined(this.question.expectedAnswers) &&
                    (Number(this.question.inputAmount) >= 2 //
                        ? this.question.expectedAnswers.length === Number(this.question.inputAmount)
                        : true);
            }
        }

        if (this.question.inputType === InputTypes.RangeSlider) {
            this.validation.startValue = isNotEmpty(this.question.slider.startValue) && isOfType(Number(this.question.slider.startValue), 'number');
            this.validation.endValue = isNotEmpty(this.question.slider.endValue) && isOfType(Number(this.question.slider.endValue), 'number');
            this.validation.majorIncrement = isNotEmpty(this.question.slider.majorIncrement) && isOfType(Number(this.question.slider.majorIncrement), 'number');

            if (this.validation.majorIncrement) {
                this.validation.majorIncrementValid = Number(this.question.slider.majorIncrement) < Number(this.question.slider.endValue);
            }

            if (this.validation.startValue && this.validation.endValue) {
                this.validation.endValueValid = Number(this.question.slider.endValue) > Number(this.question.slider.startValue);

                if (isDefined(this.question.slider.initialValue)) {
                    this.validation.initialValue = isNotEmpty(this.question.slider.initialValue) && isOfType(Number(this.question.slider.initialValue), 'number');
                    this.validation.initialValueValid =
                        Number(this.question.slider.initialValue) >= Number(this.question.slider.startValue) && Number(this.question.slider.initialValue) <= Number(this.question.slider.endValue);
                }
            }

            if (isDefined(this.question.slider.majorIncrement)) {
                this.validation.minorIncrement = isNotEmpty(this.question.slider.minorIncrement) && isOfType(Number(this.question.slider.minorIncrement), 'number');

                if (this.validation.minorIncrement) {
                    this.validation.minorIncrementValid = Number(this.question.slider.minorIncrement) < Number(this.question.slider.majorIncrement);
                }

                for (let index = 0; index < this.question.slider.labels.length; index++) {
                    const label = this.question.slider.labels[index];

                    this.validation.labels[index].label = validateTranslation(label.value, this.language);
                    this.validation.labels[index].value = isDefined(label.sliderValues);

                    if (this.validation.labels[index].value) {
                        // this.validation.labels[index].unique = this.question.slider.labels.filter((x) => x.sliderValue === label.sliderValue).length === 1;
                        // this.validation.labels[index].valueValid =
                        //     Number(label.sliderValue) >= Number(this.question.slider.startValue) && Number(label.sliderValue) <= Number(this.question.slider.endValue);
                    }
                }
            }
        }

        return validateState(this.validation) && this.validation.choices.every(validateState) && this.validation.labels.every(validateState);
    }

    private selectInputTypes(type: ResultTypes, change: boolean): void {
        let inputType: InputTypes = InputTypes.FreeEntry;

        switch (type) {
            case ResultTypes.Text:
                this.inputTypesToShow = [InputTypes.FreeEntry, InputTypes.Selector];
                inputType = InputTypes.FreeEntry;
                break;
            case ResultTypes.Number:
                this.inputTypesToShow = [InputTypes.FreeEntry, InputTypes.Selector, InputTypes.RangeSlider];
                inputType = InputTypes.FreeEntry;
                break;
            case ResultTypes.File:
                this.inputTypesToShow = [InputTypes.Filepicker];
                inputType = InputTypes.Filepicker;
                break;
            case ResultTypes.Date:
                this.inputTypesToShow = [InputTypes.Datepicker];
                inputType = InputTypes.Datepicker;
                break;
        }

        if (change) this.question.inputType = inputType;
        if (isDefined(this.inputTypeSelector)) this.inputTypeSelector.setValue(inputType);
    }
}
