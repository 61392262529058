<template class="w-full">
    <div class="relative w-full pt-1">
        <div class="mb-2 flex items-center justify-between">
            <div if.bind="showBadge">
                <ux-badge type="${color}">
                    <span lowercase.class="lowercase" class="text-xxs">
                        <au-slot />
                    </span>
                </ux-badge>
            </div>
            <div if.bind="showPercentage" class="text-right">
                <span
                    class="inline-block text-xs font-semibold"
                    text-gray-600.class="color === 'primary'"
                    text-purple-600.class="color === 'secondary'"
                    text-green-600.class="color === 'success'"
                    text-blue-600.class="color === 'info'"
                    text-yellow-600.class="color === 'warning'"
                    text-pink-600.class="color === 'pink'"
                    text-red-600.class="color === 'danger'">
                    ${progress}%
                </span>
            </div>
        </div>
        <div
            class="text-x flex h-2 overflow-hidden rounded"
            bg-gray-200.class="color === 'primary'"
            bg-purple-200.class="color === 'secondary'"
            bg-green-200.class="color === 'success'"
            bg-blue-200.class="color === 'info'"
            bg-yellow-200.class="color === 'warning'"
            bg-pink-200.class="color === 'pink'"
            bg-red-200.class="color === 'danger'">
            <div
                style="width: ${progress}%;"
                class="flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none"
                bg-gray-500.class="color === 'primary'"
                bg-purple-500.class="color === 'secondary'"
                bg-green-500.class="color === 'success'"
                bg-blue-500.class="color === 'info'"
                bg-yellow-500.class="color === 'warning'"
                bg-pink-500.class="color === 'pink'"
                bg-red-500.class="color === 'danger'"></div>
        </div>
    </div>
</template>
