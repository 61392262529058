import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { PartialView } from '../../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialHealthcareInvoicesLineSelection extends BasePartialView {
    public choices: { label: string; groupId: string }[] = [];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.choices = view.data.choices;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialHealthcareInvoicesLineSelection.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok
        });
    }

    public select(choice: { label: string; groupId: string }): void {
        super.remove({
            result: PartialViewResults.Ok,
            data: choice.groupId
        });
    }
}
