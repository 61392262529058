<import from="converters/priority-type-to-string" />
<import from="converters/priority-background-color" />
<import from="converters/keys" />
<import from="bx/bx-label-selector/bx-label-selector" />

<div class="flex flex-col">
    <div class="flex items-center justify-between">
        <ux-label>
            <span t="translation:components.labels-selector.labels.select-labels"></span>
        </ux-label>
        <ux-button click.trigger="addItem()" padding.bind="false" size="xs">
            <div class="flex p-1">
                <svg class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </div>
        </ux-button>
    </div>
    <ul class="mt-2 flex flex-col space-y-1">
        <li class="rounded-lg bg-gray-100 p-3" if.bind="registrations === 0">
            <p class="text-sm" t="translation:components.labels-selector.messages.no-labels-selected"></p>
        </li>
        <li class="grid grid-cols-12 gap-1" repeat.for="item of registrations">
            <div class="col-span-6">
                <bx-label-selector
                    types.bind="['Patient']"
                    on-select.bind="handleLabelSelected"
                    data.bind="$index"
                    language.bind="language"
                    workspace.bind="workspace"
                    t="[placeholder]translation:components.labels-selector.placeholders.select-label"
                    valid.bind="validation[$index].label"
                    selected.bind="item.label"
                    value.two-way="item.label.id">
                </bx-label-selector>
            </div>
            <div class="col-span-6 flex space-x-1">
                <ux-select value.two-way="item.priority">
                    <ux-select-option repeat.for="type of PriorityTypes | keys" value.bind="type">
                        <div class="flex items-center space-x-1.5">
                            <div class="${ type | priorityBackgroundColor } h-5 w-1 rounded-lg"></div>
                            <span>${type | priorityTypeToString | t} </span>
                        </div>
                    </ux-select-option>
                </ux-select>
                <button class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500" click.trigger="deleteItem($index)">
                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button>
            </div>
            <div class="col-span-12">
                <ux-textarea
                    action.bind="c => manageTranslationsFor($index)"
                    rows="1"
                    value.two-way="item.comments[language]"
                    t="[placeholder]translation:components.labels-selector.placeholders.enter-comments">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                    </svg>
                </ux-textarea>
            </div>
        </li>
    </ul>
</div>
