import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

export class DeltaToHtmlValueConverter {
    public toView(delta: any): string {
        if (isNotDefined(delta) || isNotDefined(delta.ops)) return '';
        const converter = new QuillDeltaToHtmlConverter(delta.ops, { encodeHtml: true });
        // Register the tags for custom blots.
        converter.renderCustomWith((ops) => {
            const values = ops.insert.value;
            if (ops.insert.type === 'imageAttachment') {
                return `<img alt="${values.alt}" width="${values.width}" height="${values.height}" src="${values.src}" data-attachment="${values.attachment}" />`;
            }
            if (ops.insert.type === 'videoAttachment') {
                if (isDefined(values.attachment)) {
                    return `<video alt="${values.alt}" width="${values.width}" height="${values.height}" controls="1" loop="0">
                            <source src="${values.src}" type="video/mp4" data-attachment="${values.attachment}" />
                        </video>`;
                } else {
                    const controls: number = 0,
                        loop: number = 0,
                        branding: number = 1,
                        autoplay: number = 0,
                        fullscreen: number = 0;

                    return `<iframe allowfullscreen="${fullscreen}" frameborder="0" data-allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" alt="${values.alt}" width="${
                        values.width
                    }" height="${values.height}" src="${`${values.src}?controls=${controls}&loop=${loop}&modestbranding=${branding}&autoplay=${autoplay}&output=embed`}"></iframe>`;
                }
            }
            return '';
        });
        // Convert delta to HTML.
        return converter.convert();
    }
}
