import { AppTypes, AppsApiClient, GetAppResponse, GetMyTeamResponseItem, GetRedactedWorkspaceResponse, GetUserResponse, TeamsApiClient, UsersApiClient, WorkspacesApiClient } from '@wecore/sdk-core';
import { GetWorkspaceResponse } from '@wecore/sdk-management';
import { StorageKeys, getFromStorage, isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { setWorkspace } from './utilities';

@inject(UsersApiClient, WorkspacesApiClient, AppsApiClient, TeamsApiClient)
export class CacheService {
    public activeApp: AppTypes;
    public workspace: string = setWorkspace(getFromStorage(StorageKeys.workspace), 'storage');
    private authenticatedUser: GetUserResponse;
    private workspaces: GetRedactedWorkspaceResponse[];
    private teams: GetMyTeamResponseItem[];
    private apps: GetAppResponse[];

    public constructor(
        private readonly usersApi: UsersApiClient, //
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly appsApi: AppsApiClient,
        private readonly teamsApi: TeamsApiClient
    ) {}

    public async getAuthenticatedUser(force: boolean = false): Promise<GetUserResponse> {
        if (isDefined(this.authenticatedUser) && !force) return this.authenticatedUser;
        this.authenticatedUser = await this.usersApi.getAuthenticated();
        return this.authenticatedUser;
    }

    public async getAuthenticatedWorkspace(force: boolean = false): Promise<GetWorkspaceResponse | GetRedactedWorkspaceResponse> {
        if (isNotDefined(this.workspaces) || force) await this.getAllWorkspaces(force);
        return (this.workspaces as GetRedactedWorkspaceResponse[]).find((x) => x.id === this.workspace);
    }

    public async getAuthenticateTeams(force: boolean = false): Promise<GetMyTeamResponseItem[]> {
        if (isNotDefined(this.teams) || force) await this.getMyTeams(force);
        return this.teams;
    }

    public async getAllWorkspaces(force: boolean = false): Promise<GetRedactedWorkspaceResponse[]> {
        if (isNotDefined(this.workspaces) || force) this.workspaces = await this.workspacesApi.getAll();
        return this.workspaces;
    }

    public async getMyTeams(force: boolean = false): Promise<GetMyTeamResponseItem[]> {
        if (isNotDefined(this.workspace)) return [];
        if (isNotDefined(this.teams) || force) this.teams = await this.teamsApi.getMyTeams(this.workspace);
        return this.teams;
    }

    public async getAllApps(force: boolean = false): Promise<GetAppResponse[]> {
        if (isNotDefined(this.apps) || force) {
            const response = await this.appsApi.getAll(this.workspace, 1000);
            this.apps = response.data;
        }
        return this.apps;
    }

    public clear(): void {
        this.authenticatedUser = null;
    }
}
