import { connectTo } from '@aurelia/store-v1';
import { MedicalExaminationActionItemTypes, MedicalExaminationFlow, MedicalExaminationTemplateItemStep } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { State } from '../../../../../infra/store/state';

@connectTo<State>()
@containerless
@inject(IEventAggregator)
export class TemplateAction {
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public language: string;

    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;

    public attached(): void {
        if (isNotDefined(this.step.attributes.action.flow)) this.step.attributes.action.flow = new MedicalExaminationFlow();
        if (isNotDefined(this.step.attributes)) this.step.attributes = {};
        this.step.attributes.expanded = false;
    }

    public collapseOrExpand(): void {
        this.step.attributes.expanded = !this.step.attributes.expanded;
    }
}
