export class FileSizeValueConverter {
    public toView(size: number): string {
        const gb = (size / 1024 / 1024 / 1024).toFixed(2);
        if (gb !== '0.00') return `${gb} GB`;
        const mb = (size / 1024 / 1024).toFixed(2);
        if (mb !== '0.00') return `${mb} MB`;
        const kb = (size / 1024).toFixed(2);
        if (kb !== '0.00') return `${kb} kb`;
        return `${size} bytes`;
    }
}
