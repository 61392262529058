import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient } from '@wecore/sdk-attachments';
import { BlobStorageAttachment } from '@wecore/sdk-crm';
import {
    FileType,
    GetMedicalQuestionResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    InputTypes,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalQuestion,
    MedicalQuestionnaireFlow,
    MedicalQuestionRegistration,
    MedicalQuestionsApiClient,
    MedicalResult,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem,
    ResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, serveBlob } from '@wecore/sdk-utilities';

import { bindable, containerless, IEventAggregator, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { PartialViews } from '../../../../../infra/partial-views';
import { fileIsImage, generateColumns, generateResultPlaceholder, getFileTypeTranslation, getSettingsId } from '../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { FlattenedExaminationStep } from '../../../../../models/flattened-examination-step';
import { PartialView } from '../../../../../models/partial-view';
import { SelectedFile } from '../../../../../models/selected-file';
import { StepState } from '../../../../../models/step-state';
import { ViewOptions } from '../../../../../models/view-options';
import { ModalService } from '../../../../../services/service.modals';
import { UxRangeSlider } from '../../../../../ux/ux-range-slider/ux-range-slider';

@containerless
@inject(I18N, AttachmentsApiClient, ModalService, IEventAggregator, MedicalQuestionsApiClient)
export class TemplateQuestion {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public flow: MedicalExaminationFlow | MedicalTherapyEvaluationFlow | MedicalQuestionnaireFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep | MedicalTherapyEvaluationItem | MedicalQuestionRegistration;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile, attachment: BlobStorageAttachment) => void;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public remove: (stepId: string) => Promise<void>;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public nested: boolean = false;

    public originalValue: string;
    public originalValues: string[] = [];
    public isDirty: boolean = false;
    public required: boolean = false;
    public ResultTypes: typeof ResultTypes = ResultTypes;
    public InputTypes: typeof InputTypes = InputTypes;
    public uploads: any[] = [];
    public contentTypes: string;
    public columns: string = 'col-span-12';
    public attachments: BlobStorageAttachment[] = [];
    public urls: any = {};
    public toManyFiles: boolean = false;
    public wrongFileTypes: boolean = false;
    public shouldBeDisplayed: boolean = false;
    public questionToUse: MedicalQuestion;
    public recent: GetMedicalQuestionResponse;
    public slider: UxRangeSlider;

    private subscriptions: any[] = [];

    public constructor(
        public t: I18N, //
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly modalService: ModalService,
        private readonly events: IEventAggregator,
        private readonly questionsApi: MedicalQuestionsApiClient
    ) {}

    public attached(): void {
        if (isNotDefined(this.step.attributes)) this.step.attributes = {};
        if (isNotDefined(this.states[this.step.id]))
            this.states[this.step.id] = new StepState({
                stepId: this.step.id,
                expanded: false,
                answered: false
            });
        // The question should always be on the registration. The only time it is not
        // and it is on the step, is when the question is part of a questionnaire.
        this.questionToUse = (this.registration?.question || this.step.question) as MedicalQuestion;

        // Generate the configured column width for the current question.
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === getSettingsId(this.step)) || [] //
        );

        if (isNotDefined(this.questionToUse)) return;

        // Create a description string of all allowed file types.
        this.contentTypes = this.questionToUse?.allowedFileTypes?.selectMany((x: FileType) => x.contentTypes).join(',');

        // Find previously selected attachments if any.
        const attachments = this.questionToUse.attachments?.map((id) => this.record.attachments.find((y) => y.id === id)).filter((x) => isDefined(x)) || [];
        Promise.all(
            attachments.map(async (x) => {
                const id = isDefined(x.thumbnail) ? x.thumbnail.id : x.id;
                const url = await this.attachmentsApi.getUrl(this.record.id, id, this.workspace, AttachmentEntities.MedicalRecords);
                this.urls[x.id] = url;
            })
        ).then(() => (this.attachments = attachments));

        this.originalValue = this.questionToUse.givenAnswer?.value;
        this.originalValues = this.questionToUse.givenAnswers?.map((x) => x.value);

        this.questionsApi.getById(this.step.question.id, this.workspace).then((question) => (this.recent = question));
        this.states[this.step.id].answered = this.uploads.any() || this.attachments.any();

        this.subscriptions = [
            // Subscribe to the ExaminationStepAnswered event so that we can evaluate
            // whether or not to show the current question when a step is answered.
            this.subscriptions.push(this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings())) //
        ];

        // Evaluate the requirements on load of the question.
        this.evaluateSettings();
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public getPlaceholder(): string {
        const generated = generateResultPlaceholder(this.t, this.questionToUse.inputType, this.questionToUse.answerType);
        return isDefined(this.questionToUse.placeholder) ? this.questionToUse.placeholder[this.language] || generated : generated;
    }

    public handleMultiChoiceSelected = (option: { value: string; text: string }) => {
        this.questionToUse.givenAnswers.push(new MedicalResult({ value: option.value }));
        this.handleStepAnswered();
    };

    public handleMultiChoiceRemoved = (_: { value: string; text: string }, index: number) => {
        this.questionToUse.givenAnswers.splice(index, 1);
        this.handleStepAnswered();
    };

    public handleAnswerSelected(): void {
        setTimeout(() => {
            this.handleStepAnswered();
        });
    }

    public filesSelected = (files: File[]): void => {
        if (this.questionToUse.filesAmount > 0 && this.uploads.length >= this.questionToUse.filesAmount) {
            this.toManyFiles = true;
            return;
        }

        if (
            isDefined(this.questionToUse.allowedFileTypes) &&
            this.questionToUse.allowedFileTypes.any() &&
            files.some((file) => this.questionToUse.allowedFileTypes.every((x) => x.contentTypes.every((type) => type !== file.type)))
        ) {
            this.wrongFileTypes = true;
            return;
        }

        for (const file of files) {
            const upload = new SelectedFile({
                id: guid(),
                step: this.step.id,
                type: 'step',
                file,
                progress: 0,
                loader: null,
                statusLabel: this.t
                    .tr('translation:partial-views.clinical-pathways.labels.status-waiting')
                    .replace('{entity}', `<span class="font-medium block mx-1">${file.name.toLowerCase()}</span>`),
                extension: `.${file.name.split('.').pop()}`,
                name: file.name,
                registration: this.registration.id,
                isLoading: false
            });

            this.uploads.push(upload);

            // Add the uploads to a temporary array. This way we can check if the step is answered or not.
            if (isNotDefined(this.step.attributes)) this.step.attributes = { uploads: [] };
            if (isNotDefined(this.step.attributes.uploads)) this.step.attributes.uploads = [];
            this.step.attributes.uploads.push(upload.id);

            this.onFileSelected(upload);
        }

        this.states[this.step.id].answered = this.uploads.any() || this.attachments.any();

        // Allow the cleared answer value some time to be cleared before publishing the event.
        // DO NOT change timeout time.
        setTimeout(() => {
            this.handleStepAnswered();
        });
    };

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public deleteFromUploads(index: number): void {
        const file = this.uploads[index];

        this.uploads.splice(index, 1);

        this.onFileRemoved(file, null);
        this.states[this.step.id].answered = this.uploads.any() || this.attachments.any();

        // Remove from temp uploads
        if (isDefined(this.step.attributes?.uploads)) {
            this.step.attributes.uploads.splice(index, 1);
        }

        setTimeout(() => this.handleStepAnswered());
    }

    public async deleteAttachment(index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const attachment = this.attachments[index];
                        const i = this.questionToUse.attachments.findIndex((id: string) => id === attachment.id);
                        this.questionToUse.attachments.splice(i, 1);

                        this.attachments.splice(index, 1);

                        this.onFileRemoved(null, attachment);
                        this.states[this.step.id].answered = this.uploads.any() || this.attachments.any();

                        setTimeout(() => this.handleStepAnswered());
                    }
                }
            })
        );
    }

    public async openMenu(event: MouseEvent, index: number, type: 'attachment' | 'upload'): Promise<void> {
        await this[`${type}Menus`][index].show(event);
    }

    public getFileTypes(): string {
        return this.questionToUse.allowedFileTypes.map((x) => this.t.tr(getFileTypeTranslation(x.name))).join(', ');
    }

    public async open(attachment: BlobStorageAttachment): Promise<void> {
        const url = await this.attachmentsApi.getUrl(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
        window.open(url, '_blank');
    }

    public async download(attachment: BlobStorageAttachment): Promise<void> {
        if (isDefined(attachment)) {
            const blob = await this.attachmentsApi.download(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
        }
    }

    public collapseOrExpand(): void {
        this.step.attributes.expanded = !this.step.attributes.expanded;
    }

    public handleBlur(): void {
        if ((this.questionToUse.inputType === InputTypes.FreeEntry && this.questionToUse.givenAnswers.any()) || isDefined(this.questionToUse.givenAnswer?.value))
            setTimeout(() => {
                this.handleStepAnswered();
            });
    }

    public async openInformationSheet(): Promise<void> {
        await this.addPartial(
            PartialViews.InformationSheet.with({
                config: {
                    mode: 'view',
                    language: this.language
                },
                sheet: this.recent.informationSheet
            }),
            new ViewOptions({
                scrollToView: true,
                markItem: true,
                replace: true
            })
        );
    }

    public toSet(choices: MedicalResult[]): { value: string; text: string; data?: any }[] {
        return choices.map((x) => ({ value: x.value, text: x.value }));
    }

    public handleSelectionCleared(): void {
        this.questionToUse.givenAnswers = [];

        // Allow the cleared answer value some time to be cleared before publishing the event.
        // DO NOT change timeout time.
        setTimeout(() => {
            this.handleStepAnswered();
        });
    }

    public handleAnswerCleared(): void {
        // Allow the given answer value some time to be set to the
        // answered question before publishing the event.
        setTimeout(() => {
            this.handleStepAnswered();
        });
    }

    private handleStepAnswered(): void {
        this.loading(true);
        // Let all listening steps that the current question has been answered.
        this.events.publish(CustomEvents.ExaminationReFlatten, {
            step: this.step,
            category: null
        });
        this.loading(false);
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.step.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;
        this.required = flattened?.isRequired ?? false;

        if (!this.shouldBeDisplayed) {
            this.questionToUse.givenAnswer.value = null;
            this.questionToUse.givenAnswers = [];
            this.removeAllAttachmentsAndUploads();
        }
    }

    private removeAllAttachmentsAndUploads(): void {
        if (this.questionToUse.answerType !== ResultTypes.File) return;

        for (const attachment of this.attachments) {
            const i = this.questionToUse.attachments.findIndex((id: string) => id === attachment.id);
            this.questionToUse.attachments.splice(i, 1);
            this.onFileRemoved(null, attachment);
        }

        for (const upload of this.uploads) this.onFileRemoved(upload, null);

        this.uploads = [];
        this.attachments = [];
        this.states[this.step.id].answered = false;
    }
}
