<import from="converters/date" />

<template class="relative inline-block w-full text-left">
    <div ref="container" class="ng flex h-[38px] w-full">
        <div
            class="relative flex items-center flex-1 min-w-0 pl-3 transition duration-150 ease-in-out border ring-offset-1 focus:outline-none sm:text-sm sm:leading-5"
            ring-1.class="hasFocus || inputFocused"
            border-gray-300.class="valid"
            ring-gray-400.class="valid"
            border-red-500.class="!valid"
            ring-red-600.class="!valid"
            hover:cursor-not-allowed.class="disabled"
            bg-gray-100.class="disabled"
            cursor-default.class="!disabled"
            bg-white.class="!disabled"
            rounded-md.class="(rounded === 'full' && (!allowClear || (allowClear && isNotDefined(value)))) || disabled"
            rounded-r-md.class="(rounded === 'full' && (!allowClear || (allowClear && isNotDefined(value)))) || disabled"
            rounded-l-md.class="(rounded === 'left' && (allowClear || !allowClear)) || (rounded === 'full' && allowClear && isDefined(value) && !disabled)">
            <svg class="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
            <button
                if.bind="mode === 'display'"
                ref="trigger"
                focus.trigger="handleFocus()"
                blur.trigger="handleBlur()"
                click.trigger="toggleVisibility()"
                disabled.bind="disabled"
                class="relative flex items-center w-full min-w-0 py-2 pl-3 pr-10 text-left rounded-md cursor-default focus:outline-none sm:text-sm"
                cursor-default.class="!disabled"
                bg-white.class="!disabled"
                bg-gray-100.class="disabled"
                hover:cursor-not-allowed.class="disabled"
                type="button"
                aria-haspopup="true"
                aria-expanded="false">
                <span class="flex-1 block truncate">
                    <span if.bind="value">${label}</span>
                    <span if.bind="!value" class="text-gray-500">${placeholder}</span>
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <span if.bind="isLoading" class="flex items-center px-1">
                        <ux-spinner size="xxs"></ux-spinner>
                    </span>
                    <svg if.bind="!isLoading && valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-5 text-gray-400">
                        <path
                            fill-rule="evenodd"
                            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <svg if.bind="!valid" class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </span>
            </button>
            <div if.bind="mode === 'input'" class="flex items-center flex-1 pr-2">
                <input
                    ref="trigger"
                    disabled.bind="disabled"
                    type="text"
                    placeholder.bind="placeholder"
                    blur.trigger="handleBlur()"
                    value.to-view="label"
                    focus.trigger="handleFocus()"
                    click.trigger="open()"
                    class="flex-1 w-full py-1 pl-3 text-sm text-left placeholder-gray-500 align-middle transition duration-150 ease-in-out rounded-lg focus:outline-none sm:text-sm sm:leading-5"
                    bg-gray-100.class="disabled"
                    hover:cursor-not-allowed.class="disabled" />
                <span if.bind="isLoading" class="flex items-center px-1">
                    <ux-spinner size="xxs"></ux-spinner>
                </span>
                <svg if.bind="!isLoading && valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-5 text-gray-400">
                    <path
                        fill-rule="evenodd"
                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                        clip-rule="evenodd"></path>
                </svg>
                <svg if.bind="!valid" class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
            </div>
        </div>
        <button
            if.bind="allowClear && isDefined(value) && !disabled"
            disabled.bind="disabled"
            click.trigger="handleClear()"
            class="-ml-px h-[38px] rounded-r-md border p-2 ring-offset-1 focus:z-20 focus:outline-none focus:ring-1"
            border-gray-300.class="valid"
            ring-gray-400.class="valid"
            border-red-500.class="!valid"
            ring-red-500.class="!valid"
            bg-gray-100.class="disabled"
            hover:cursor-not-allowed.class="disabled"
            bg-white.class="!disabled">
            <svg class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
        </button>
    </div>
    <div
        show.bind="isVisible"
        bottom-[45px].class="placement === 'top'"
        ref="dropdown"
        class="absolute z-50 flex flex-col w-full px-2 pt-3 pb-4 mt-2 overflow-auto origin-top-right bg-white rounded-md shadow-lg min-w-80 max-w-64 ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1">
        <div class="flex justify-between pb-2">
            <div class="flex items-center justify-center flex-1 h-full border-r border-gray-300">
                <button click.trigger="handlePreviousMonth()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path
                            fill="currentColor"
                            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                </button>
                <div class="w-16 px-2 font-bold text-center text-gray-700">${currentDate | date:'MMM'}</div>
                <button click.trigger="handleNextMonth()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path
                            fill="currentColor"
                            d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                </button>
            </div>
            <div class="flex items-center justify-center flex-1 h-full">
                <button click.trigger="handlePreviousYear()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path
                            fill="currentColor"
                            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                </button>
                <div class="w-16 px-2 font-bold text-center text-gray-700">${currentDate | date:'yyyy'}</div>
                <button click.trigger="handleNextYear()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path
                            fill="currentColor"
                            d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="flex justify-between px-3">
            <div class="flex-1 inline-block" repeat.for="label of labels">
                <div class="flex items-center justify-center h-full">
                    <span class.bind="$index === 0 ?'border-r' : ''" class="w-full py-2 text-sm leading-tight text-center text-gray-400 border-b border-gray-300"> ${label | t} </span>
                </div>
            </div>
        </div>
        <div if.bind="daysOfCurrentMonth" class="flex flex-col px-3">
            <div repeat.for="week of weeks" class="flex justify-between w-full">
                <div class="flex-1 inline-block">
                    <div class="flex items-center justify-center h-full text-sm leading-tight text-gray-400 border-r border-gray-300">${week}</div>
                </div>
                <div repeat.for="d of rows[$index]" class="inline-flex items-center justify-center flex-1 h-10">
                    <button
                        text-primary.class="!isSelected(d) && isToday(d)"
                        font-bold.class="!isSelected(d) && isToday(d)"
                        bg-primary.class="isSelected(d)"
                        text-white.class="isSelected(d)"
                        text-gray-900.class="!isSelected(d) && !isToday(d) && isInCurrentMonth(d)"
                        text-gray-300.class="!isInCurrentMonth(d)"
                        class="w-8 h-8 text-sm rounded-full ring-primary ring-offset-1 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 active:bg-blue-900"
                        click.trigger="handleSelect($event, d)"
                        tabindex="-1">
                        <div class="flex items-center justify-center h-full leading-tight pointer-events-none">${d | date:'d'}</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center w-full mt-3">
            <button
                tabindex="-1"
                click.trigger="handleSelect($event, today)"
                class="px-4 py-1 text-sm text-gray-600 rounded-full ring-offset-1 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 active:bg-blue-900">
                <span t="translation:global.days.today"></span>
            </button>
        </div>
    </div>
</template>
