<div class="flex flex-col">
    <div class="flex items-center space-x-1">
        <ux-label class="mb-1">
            <span t="translation:components.widgets.patient-weight.labels.enter-weight"></span>
        </ux-label>
        <span if.bind="required" class="flex h-3 items-center text-red-500">*</span>
    </div>
    <ux-input valid.bind="validation.result && validation.weight" type="number" value.two-way="registration.widget.result.value" placeholder.bind="placeholder"> </ux-input>
    <p if.bind="!validation.weight" class="mt-1 text-xs text-red-500" t="translation:components.widgets.patient-weight.messages.invalid-value"></p>
</div>
