import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    ColumnBreakpoint,
    DifferentialDiagnosesRequirements,
    GetMedicalExaminationActionResponse,
    GetMedicalQuestionResponse,
    GetMedicalQuestionnaireResponse,
    GetMedicalWidgetResponse,
    HealthcareCode,
    HealthcarePrice,
    HealthcarePriceRequirements,
    InformationSheet,
    MedicalExaminationActionEntityReference,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationActionRequirements,
    MedicalExaminationStepVisibilityRequirement,
    MedicalQuestionEntityReference,
    MedicalQuestionnaireEntityReference,
    MedicalTherapiesApiClient,
    MedicalTherapyEvaluationItem,
    MedicalTherapyEvaluationItemTypes,
    MedicalTherapyExecutionItem,
    MedicalTherapyExecutionItemStepCategory,
    MedicalTherapyExecutionItemTypes,
    MedicalTherapyPlan,
    MedicalTherapyPlanFlow,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemTypes,
    MedicalWidgetEntityReference,
    StringBooleanKeyValuePair,
    StringMedicalExaminationModelBoxKeyValuePair,
    StringStringKeyValuePair,
    UpdateMedicalTherapyRequest
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { clearItem, clearTherapyEvaluationItem, copyTherapyEvaluationItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import {
    addCategory,
    addStep,
    addToSpecificIndex,
    cleanExpectedResults,
    cleanTranslatables,
    cloneDeep,
    collapseSteps,
    pasteItem,
    removeAllSettings,
    removeItemSettings,
    setExpectedResults,
    setTranslation,
    validateTranslation
} from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { CopyTherapyEvaluationItem } from '../../../models/copy-evaluation-item';
import { CopyTherapyExecutionItem } from '../../../models/copy-therapy-execution-item';
import { CopyTherapyPlanItem } from '../../../models/copy-therapy-plan-item';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalTherapiesApiClient, ModalService)
export class PartialMedicalTherapiesEdit extends BasePartialView {
    public MedicalTherapyExecutionItemTypes: typeof MedicalTherapyExecutionItemTypes = MedicalTherapyExecutionItemTypes;
    public MedicalTherapyEvaluationItemTypes: typeof MedicalTherapyEvaluationItemTypes = MedicalTherapyEvaluationItemTypes;
    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    public therapyId: string;
    public therapy: UpdateMedicalTherapyRequest;
    public validation = {
        name: true,
        displayOrder: true,
        plan: {
            valid: true,
            steps: [],
            any: true,
            validSteps: true
        },
        execution: {
            valid: true,
            steps: [],
            any: true,
            validSteps: true
        },
        evaluation: {
            valid: true,
            steps: []
        }
    };
    public tab: 'plan' | 'execution' | 'evaluation' = 'plan';

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly therapiesApi: MedicalTherapiesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.therapyId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [therapy] = await Promise.all([
                    this.therapiesApi.getById(this.therapyId, this.authenticated.workspace.id, true) //
                ]);
                this.therapy = therapy;

                if (isNotDefined(this.therapy.plan))
                    this.therapy.plan = new MedicalTherapyPlan({
                        stepsToTake: [],
                        flow: new MedicalTherapyPlanFlow({
                            required: [],
                            breakpoints: [],
                            visibilityRequirements: [],
                            connectedCategories: [],
                            connectedSteps: []
                        })
                    });

                if (isNotDefined(this.therapy.execution.flow.connectedSteps)) this.therapy.execution.flow.connectedSteps = [];
                if (isNotDefined(this.therapy.prices)) this.therapy.prices = [];
                if (isNotDefined(this.therapy.codes)) this.therapy.codes = [];

                this.setValidationAndTranslations();

                this.subscriptions.push(this.events.subscribe(CustomEvents.TherapyStepsChanged, () => this.handleItemsChanged()));

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalTherapiesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
        this.store.dispatch(clearItem, 'CopyTherapyItem');
    }

    public handleItemsChanged(): void {
        this.therapy.plan.stepsToTake = [...(this.therapy.plan.stepsToTake.length > 0 ? [this.therapy.plan.stepsToTake.shift()] : []), ...this.therapy.plan.stepsToTake];
        this.validation.plan.steps = [...(this.validation.plan.steps.length > 0 ? [this.validation.plan.steps.shift()] : []), ...this.validation.plan.steps];

        this.therapy.execution.stepsToTake = [...(this.therapy.execution.stepsToTake.length > 0 ? [this.therapy.execution.stepsToTake.shift()] : []), ...this.therapy.execution.stepsToTake];
        this.validation.execution.steps = [...(this.validation.execution.steps.length > 0 ? [this.validation.execution.steps.shift()] : []), ...this.validation.execution.steps];
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.medical-therapies.questions.copy.title'),
                message: this.t.tr('translation:partial-views.medical-therapies.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.therapiesApi.copy(this.therapyId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-therapies.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.medical-therapies.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-medical-therapy]', e);
                        }
                    }
                }
            })
        );
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(close: boolean = true): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.therapy, 3);
                cleanTranslatables(
                    ['stepsToTake:category:name', 'stepsToTake:step:name', 'stepsToTake:step:description', 'stepsToTake:step:question', 'stepsToTake:step:placeholder'],
                    this.therapy.plan,
                    3
                );
                cleanTranslatables(
                    ['stepsToTake:category:name', 'stepsToTake:step:name', 'stepsToTake:step:description', 'stepsToTake:step:question', 'stepsToTake:step:placeholder'],
                    this.therapy.execution,
                    3
                );
                cleanTranslatables(
                    ['stepsToTake:category:name', 'stepsToTake:step:name', 'stepsToTake:step:description', 'stepsToTake:step:question', 'stepsToTake:step:placeholder'],
                    this.therapy.evaluation,
                    3
                );
                cleanExpectedResults(this.therapy.plan.stepsToTake);
                cleanExpectedResults(this.therapy.execution.stepsToTake);
                await this.therapiesApi.update(this.therapyId, this.authenticated.workspace.id, this.therapy);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-therapies.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-therapies.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.therapy.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                if (close) setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                else {
                    setExpectedResults(this.therapy.plan.stepsToTake);
                    setExpectedResults(this.therapy.execution.stepsToTake);
                    this.resetTranslations();
                    this.isLoading = false;
                }
            } catch (e) {
                this.resetTranslations();
                this.isLoading = false;
                await this.errorHandler.handle('[edit-medical-therapies]', e);
            }
        }
    }

    public setActiveTab(tab: 'plan' | 'execution' | 'evaluation'): void {
        this.tab = tab;
    }

    public editTherapyStep = async (index: number, array: MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], validationItems: any[], type: 'plan' | 'execution'): Promise<void> => {
        let typeToUse: 'therapy-plan-item' | 'therapy-execution-item';
        let item: MedicalTherapyPlanItem | MedicalTherapyExecutionItem;
        switch (type) {
            case 'plan':
                typeToUse = 'therapy-plan-item';
                // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
                item = MedicalTherapyPlanItem.fromJS(JSON.parse(JSON.stringify(array[index])));
                break;
            case 'execution':
                typeToUse = 'therapy-execution-item';
                // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
                item = MedicalTherapyExecutionItem.fromJS(JSON.parse(JSON.stringify(array[index])));
                break;
        }

        const validation = JSON.parse(JSON.stringify(validationItems[index]));

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.EditMedicalItem.with({
                item,
                validation,
                type: typeToUse
            }).whenClosed(async (result: PartialViewResults, response: { item: MedicalTherapyPlanItem | MedicalTherapyExecutionItem; validation: any }) => {
                if (result === PartialViewResults.Ok) {
                    switch (type) {
                        case 'plan':
                            array[index] = MedicalTherapyPlanItem.fromJS(response.item);
                            break;
                        case 'execution':
                            array[index] = MedicalTherapyExecutionItem.fromJS(response.item);
                            break;
                    }

                    validationItems[index] = response.validation;

                    // Force array to refresh.
                    switch (type) {
                        case 'plan':
                            this.therapy.plan.stepsToTake = [
                                ...(this.therapy.plan.stepsToTake.length > 0 ? [this.therapy.plan.stepsToTake.shift()] : []), //
                                ...cloneDeep(this.therapy.plan.stepsToTake)
                            ];
                            break;
                        case 'execution':
                            this.therapy.execution.stepsToTake = [
                                ...(this.therapy.execution.stepsToTake.length > 0 ? [this.therapy.execution.stepsToTake.shift()] : []), //
                                ...cloneDeep(this.therapy.execution.stepsToTake)
                            ];
                            break;
                    }
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public manageTranslationsFor = (
        property: string, //
        required: boolean = false,
        index: number = -1,
        stepsToTake: MedicalTherapyExecutionItemStepCategory[] = null
    ): void => {
        let translations: any;
        if (property.includes(':')) {
            const [item, prop] = property.split(':');
            translations = stepsToTake[index][item][prop];
        } else translations = this.therapy[property];

        this.manageTranslations({
            translations,
            callback: (updatedTranslations: any) => {
                if (property.includes(':')) {
                    const [item, prop] = property.split(':');
                    stepsToTake[index][item][prop] = updatedTranslations;
                } else this.therapy[property] = updatedTranslations;
            },
            required,
            type: property.includes('description') ? 'textarea' : 'input'
        });
    };

    public addCategory = (index: number = -1, type: 'plan' | 'execution'): void => {
        let typeToUse: 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem';
        switch (type) {
            case 'plan':
                typeToUse = 'MedicalTherapyPlanItem';
                break;
            case 'execution':
                typeToUse = 'MedicalTherapyExecutionItem';
                break;
        }
        collapseSteps(this.therapy[type].stepsToTake);
        addCategory(this.therapy[type].stepsToTake, this.validation[type].steps, index, this.language, typeToUse);
    };

    public addStep = (index: number = -1, type: 'plan' | 'execution'): void => {
        let typeToUse: 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem';
        switch (type) {
            case 'plan':
                typeToUse = 'MedicalTherapyPlanItem';
                break;
            case 'execution':
                typeToUse = 'MedicalTherapyExecutionItem';
                break;
        }
        collapseSteps(this.therapy[type].stepsToTake);
        addStep(this.therapy[type].stepsToTake, this.validation[type].steps, index, this.language, typeToUse);
    };

    public removeTherapyItem = (index: number, category: MedicalTherapyExecutionItemStepCategory, validation: [], type: 'plan' | 'execution'): void => {
        let item: MedicalTherapyPlanItem | MedicalTherapyExecutionItem;

        if (isDefined(category)) {
            item = category.stepsToTake[index];
            category.stepsToTake.splice(index, 1);
            validation.splice(index, 1);
        } else {
            item = this.therapy[type].stepsToTake[index];

            this.therapy[type].stepsToTake.splice(index, 1);
            this.validation[type].steps.splice(index, 1);
        }

        if (item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) removeAllSettings(this.therapy[type], item.category.stepsToTake);
        removeItemSettings(this.therapy[type].flow, item);

        this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
        this.handleItemsChanged();
    };

    public pasteItem = async (index: number = -1, type: 'plan' | 'execution'): Promise<void> => {
        let copyItem: CopyTherapyPlanItem | CopyTherapyExecutionItem;
        let typeToUse: 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem';

        switch (type) {
            case 'plan':
                copyItem = this.state.clipboard.therapyPlanItem;
                typeToUse = 'MedicalTherapyPlanItem';
                break;
            case 'execution':
                copyItem = this.state.clipboard.therapyExecutionItem;
                typeToUse = 'MedicalTherapyExecutionItem';
                break;
        }

        if (isNotDefined(copyItem)) return;

        await pasteItem(
            this.store, //
            this.state,
            this.therapy[type].stepsToTake,
            this.validation[type].steps,
            index,
            this.therapy[type].flow,
            typeToUse
        );
    };

    public collapseOrExpandAll(command: 'collapse' | 'expand', type: 'plan' | 'execution'): void {
        const collapseOrExpandAction = (items: MedicalExaminationActionItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    setTimeout(() => (item.attributes.expanded = command === 'expand'));
                    setTimeout(() => collapseOrExpandAction(item.category.stepsToTake, command));
                } else setTimeout(() => (item.attributes.expanded = command === 'expand'));
            }
        };
        const collapseOrExpandEvaluation = (items: MedicalTherapyEvaluationItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                switch (item.type) {
                    case MedicalTherapyEvaluationItemTypes.Action:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => collapseOrExpandAction(item.attributes.action.stepsToTake, command));
                        break;
                    case MedicalTherapyEvaluationItemTypes.Question:
                        item.attributes.expanded = command === 'expand';
                        break;
                    case MedicalTherapyEvaluationItemTypes.Questionnaire:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => {
                            for (const step of item.attributes.questionnaire.questions) //
                                (step as any).attributes.expanded = command === 'expand';
                        });
                        break;
                }
            }
        };

        const collapseOrExpandExecution = (items: MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    item.attributes.expanded = command === 'expand';
                    collapseOrExpandExecution(item.category.stepsToTake, command);
                } else item.attributes.expanded = command === 'expand';
            }
        };

        collapseOrExpandExecution(this.therapy.execution.stepsToTake, command);
        collapseOrExpandExecution(this.therapy.plan.stepsToTake, command);
        collapseOrExpandEvaluation(this.therapy.evaluation.stepsToTake, command);
    }

    public openSettingsForPlanOrExecution = async (step: MedicalTherapyPlanItem | MedicalTherapyExecutionItem, categoryId: string, type: 'plan' | 'execution'): Promise<void> => {
        const label = step.type === MedicalTherapyPlanItemTypes.Category || step.type === MedicalTherapyExecutionItemTypes.Category ? 'global.labels.category' : 'global.labels.step';
        const name = step.type === MedicalTherapyPlanItemTypes.Category || step.type === MedicalTherapyExecutionItemTypes.Category ? step.category.name[this.language] : step.step.name[this.language];

        const settings = ['required', 'columns', 'visibility', 'categories', 'steps'];
        if (step instanceof MedicalTherapyPlanItem) settings.push('prices');

        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.therapy[type].flow,
            item: step,
            active: this.therapy[type],
            settings,
            label,
            name,
            categoryId
        }).whenClosed(
            async (
                result: PartialViewResults,
                response: {
                    breakpoints: ColumnBreakpoint[];
                    required: StringBooleanKeyValuePair;
                    repeatDuringEvaluation: StringBooleanKeyValuePair;
                    visibilityRequirements: MedicalExaminationStepVisibilityRequirement[];
                    ddRequirements: DifferentialDiagnosesRequirements[];
                    actionRequirements: MedicalExaminationActionRequirements[];
                    modelBox: StringMedicalExaminationModelBoxKeyValuePair;
                    categories: StringStringKeyValuePair[];
                    connectedSteps: StringStringKeyValuePair[];
                    prices: HealthcarePriceRequirements[];
                }
            ) => {
                if (result === PartialViewResults.Ok) {
                    // Remove old breakpoints and add new ones.
                    this.therapy[type].flow.breakpoints = [
                        ...this.therapy[type].flow.breakpoints.filter((x) => x.id !== step.id), //
                        ...response.breakpoints.map(ColumnBreakpoint.fromJS)
                    ];

                    // Remove old requirements and and new one if present.
                    this.therapy[type].flow.required = this.therapy[type].flow.required.filter((x) => x.key !== step.id);
                    if (isDefined(response.required))
                        this.therapy[type].flow.required.push(
                            StringBooleanKeyValuePair.fromJS(response.required) //
                        );

                    // Remove old visibilityRequirements and add new ones.
                    this.therapy[type].flow.visibilityRequirements = [
                        ...this.therapy[type].flow.visibilityRequirements.filter((x) => x.id !== step.id), //
                        ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                    ];

                    // Remove old connected categories and add new ones.
                    this.therapy[type].flow.connectedCategories = [
                        ...this.therapy[type].flow.connectedCategories.filter((x) => x.key !== step.id), //
                        ...response.categories.map(StringStringKeyValuePair.fromJS)
                    ];

                    // Remove old connected steps and add new ones.
                    this.therapy[type].flow.connectedSteps = [
                        ...this.therapy[type].flow.connectedSteps.filter((x) => x.key !== step.id), //
                        ...response.connectedSteps.map(StringStringKeyValuePair.fromJS)
                    ];

                    // Remove old prices and add new ones.
                    if (type === 'plan')
                        (this.therapy[type].flow as MedicalTherapyPlanFlow).prices = [
                            ...(this.therapy[type].flow as MedicalTherapyPlanFlow).prices.filter((x) => x.stepId !== step.id), //
                            ...response.prices
                        ];

                    this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
                }
            }
        );

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public openSettingsForEvaluation = async (step: MedicalTherapyEvaluationItem, categoryId: string): Promise<void> => {
        let label: string;
        let name: string;

        if (isDefined(step.action)) {
            label = 'global.labels.action';
            name = step.action.translations[this.language];
        }
        if (isDefined(step.question)) {
            label = 'global.labels.question';
            name = step.question.translations[this.language];
        }
        if (isDefined(step.questionnaire)) {
            label = 'global.labels.questionnaire';
            name = step.questionnaire.translations[this.language];
        }

        if (isNotDefined(this.therapy.evaluation.flow.required)) this.therapy.evaluation.flow.required = [];
        if (isNotDefined(this.therapy.evaluation.flow.breakpoints)) this.therapy.evaluation.flow.breakpoints = [];

        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.therapy.evaluation.flow,
            item: step,
            active: this.therapy.evaluation,
            settings: ['required', 'columns', 'visibility'],
            label,
            name,
            categoryId
        }).whenClosed(
            async (
                result: PartialViewResults,
                response: {
                    breakpoints: ColumnBreakpoint[];
                    required: StringBooleanKeyValuePair;
                    repeatDuringEvaluation: StringBooleanKeyValuePair;
                    visibilityRequirements: MedicalExaminationStepVisibilityRequirement[];
                    ddRequirements: DifferentialDiagnosesRequirements[];
                    actionRequirements: MedicalExaminationActionRequirements[];
                    modelBox: StringMedicalExaminationModelBoxKeyValuePair;
                }
            ) => {
                if (result === PartialViewResults.Ok) {
                    // Remove old breakpoints and add new ones.
                    this.therapy.evaluation.flow.breakpoints = [
                        ...this.therapy.evaluation.flow.breakpoints.filter((x) => x.id !== step.id), //
                        ...response.breakpoints.map(ColumnBreakpoint.fromJS)
                    ];

                    // Remove old requirements and and new one if present.
                    this.therapy.evaluation.flow.required = this.therapy.evaluation.flow.required.filter((x) => x.key !== step.id);
                    if (isDefined(response.required))
                        this.therapy.evaluation.flow.required.push(
                            StringBooleanKeyValuePair.fromJS(response.required) //
                        );

                    // Remove old visibilityRequirements and add new ones.
                    this.therapy.evaluation.flow.visibilityRequirements = [
                        ...this.therapy.evaluation.flow.visibilityRequirements.filter((x) => x.id !== step.id), //
                        ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                    ];

                    this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
                }
            }
        );

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public addEvaluationStep = (index: number): void => {
        addToSpecificIndex(
            this.therapy.evaluation.stepsToTake, //
            new MedicalTherapyEvaluationItem({
                id: guid()
            }),
            index
        );

        addToSpecificIndex(
            this.validation.evaluation.steps, //
            {
                type: true,
                valid: true,
                action: true,
                question: true,
                widget: true,
                questionnaire: true
            },
            index
        );
    };

    public removeEvaluationStep = (index: number): void => {
        const item = this.therapy.evaluation.stepsToTake[index];

        this.therapy.evaluation.stepsToTake.splice(index, 1);
        this.validation.evaluation.steps.splice(index, 1);

        removeItemSettings(this.therapy.evaluation.flow, item);

        this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
    };

    public moveEvaluationStep = (direction: 'up' | 'down', currentIndex: number): void => {
        let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex > this.therapy.evaluation.stepsToTake.length - 1) newIndex = this.therapy.evaluation.stepsToTake.length - 1;

        const stepToMove = this.therapy.evaluation.stepsToTake[currentIndex];
        const validationToMove = this.validation.evaluation.steps[currentIndex];

        this.therapy.evaluation.stepsToTake.splice(currentIndex, 1);
        this.validation.evaluation.steps.splice(currentIndex, 1);

        this.therapy.evaluation.stepsToTake.splice(newIndex, 0, stepToMove);
        this.validation.evaluation.steps.splice(newIndex, 0, validationToMove);
    };

    public selectType(type: MedicalTherapyEvaluationItemTypes, index: number): void {
        this.therapy.evaluation.stepsToTake[index].type = type;
    }

    public copyOrCut = async (command: 'copy' | 'cut', index: number): Promise<void> => {
        await this.store.dispatch(
            copyTherapyEvaluationItem,
            new CopyTherapyEvaluationItem({
                item: this.therapy.evaluation.stepsToTake[index],
                array: this.therapy.evaluation.stepsToTake,
                index: index,
                validationItem: this.validation.evaluation.steps[index],
                validation: this.validation.evaluation.steps,
                command
            })
        );
    };

    public pasteEvaluationItem = async (index: number = -1): Promise<void> => {
        const itemToCopy = this.state.clipboard.therapyEvaluationItem;
        if (isNotDefined(itemToCopy)) return;
        // If the command is 'cut' remove the item and validation
        // from their old positions.
        if (itemToCopy.command === 'cut') {
            itemToCopy.array.splice(itemToCopy.index, 1);
            itemToCopy.validation.splice(itemToCopy.index, 1);
        }

        // If we paste after the current item e.g.
        // the provided index is bigger than the current
        // index, substract 1 from the index to get the item
        // on the correct spot.
        if (index > itemToCopy.index) index--;

        // Create a copy without any references.
        // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
        const parsed = JSON.parse(JSON.stringify(itemToCopy.item));
        const copy = MedicalTherapyEvaluationItem.fromJS(Object.assign({}, parsed));
        // If we're copying, make sure the item ids are unique.
        if (itemToCopy.command === 'copy') copy.id = guid();

        // Wait for the old array to update.
        setTimeout(async () => {
            addToSpecificIndex(this.therapy.evaluation.stepsToTake, copy, index);
            // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
            const parsed = JSON.parse(JSON.stringify(itemToCopy.validationItem));
            addToSpecificIndex(this.validation.evaluation.steps, Object.assign({}, parsed), index);
            // Remove the copied item from the state.
            await this.store.dispatch(clearTherapyEvaluationItem);
        });
    };

    public handleQuestionSelected = (question: GetMedicalQuestionResponse, index: number): void => {
        if (isNotDefined(this.therapy.evaluation.stepsToTake[index].attributes)) this.therapy.evaluation.stepsToTake[index].attributes = {};
        this.therapy.evaluation.stepsToTake[index].attributes.question = question;
        this.therapy.evaluation.stepsToTake[index].question = new MedicalQuestionEntityReference({
            id: question.id,
            translations: question.name
        });
    };

    public handleActionSelected = (action: GetMedicalExaminationActionResponse, index: number): void => {
        if (isNotDefined(this.therapy.evaluation.stepsToTake[index].attributes)) this.therapy.evaluation.stepsToTake[index].attributes = {};
        this.therapy.evaluation.stepsToTake[index].attributes.action = action;
        this.therapy.evaluation.stepsToTake[index].action = new MedicalExaminationActionEntityReference({
            id: action.id,
            translations: action.name
        });
    };

    public handleWidgetSelected = (widget: GetMedicalWidgetResponse, index: number): void => {
        if (isNotDefined(this.therapy.evaluation.stepsToTake[index].attributes)) this.therapy.evaluation.stepsToTake[index].attributes = {};
        this.therapy.evaluation.stepsToTake[index].attributes.widget = widget;
        this.therapy.evaluation.stepsToTake[index].widget = new MedicalWidgetEntityReference({
            id: widget.id,
            translations: widget.name
        });
    };

    public handleQuestionnaireSelected = (questionnaire: GetMedicalQuestionnaireResponse, index: number): void => {
        if (isNotDefined(this.therapy.evaluation.stepsToTake[index].attributes)) this.therapy.evaluation.stepsToTake[index].attributes = {};
        this.therapy.evaluation.stepsToTake[index].attributes.questionnaire = questionnaire;
        this.therapy.evaluation.stepsToTake[index].questionnaire = new MedicalQuestionnaireEntityReference({
            id: questionnaire.id,
            translations: questionnaire.name
        });
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.therapy.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.therapy.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-therapies.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-therapies.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.therapy.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.therapiesApi.delete(this.therapyId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-therapies.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-therapies.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.therapy.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-therapies-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public async createOrEditPrice(index: number = -1): Promise<void> {
        const price = index > -1 ? this.therapy.prices[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.therapy.prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.therapy.prices[data.index] = data.price;
                    else this.therapy.prices.push(data.price);

                    this.therapy.prices = [
                        ...(this.therapy.prices.length > 0 ? [this.therapy.prices.shift()] : []), //
                        ...this.therapy.prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removePrice(index: number): Promise<void> {
        this.therapy.prices.splice(index, 1);
    }

    public formatDescription(index: number): string {
        let description = '';
        if (isDefined(this.therapy.prices[index].periodStart)) description += format(this.therapy.prices[index].periodStart, 'dd-MM-yyyy');
        if (isDefined(this.therapy.prices[index].periodStart)) description += ' - ';
        if (isDefined(this.therapy.prices[index].periodEnd)) description += format(this.therapy.prices[index].periodEnd, 'dd-MM-yyyy');

        if (isDefined(this.therapy.prices[index].insurer)) description += ` (${this.therapy.prices[index].insurer.translations[this.language]})`;

        return description;
    }

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.therapy.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.therapy.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.therapy.codes[data.index] = data.code;
                    this.therapy.codes = [
                        ...(this.therapy.codes.length > 0 ? [this.therapy.codes.shift()] : []), //
                        ...this.therapy.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.therapy.codes.splice(index, 1);
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.therapy.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.therapy.displayOrder) && isOfType(Number(this.therapy.displayOrder), 'number');
        // this.validation.any = isDefined(this.therapy.stepsToTake) && this.therapy.stepsToTake.length > 0;

        // Validation for the execution steps.
        let valid: {
            plan: boolean;
            execution: boolean;
        } = {
            plan: true,
            execution: true
        };
        // Recursively validate each step to take.
        const validateSteps = (items: MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], validation: any[], type: 'plan' | 'execution'): void => {
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                // Make sure all properties are set to 'true'
                resetValidation(validation[index]);
                // Validate the object.
                if (item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    validation[index].name = validateTranslation(item.category.name, this.language);
                    validateSteps(item.category.stepsToTake, validation[index].steps, type);
                    // After validation children check if all are valid.
                    validation[index].childrenValid = validation[index].steps.every((x: any) => {
                        if (isDefined(x.childrenValid)) return x.valid && x.childrenValid;
                        return x.valid;
                    });
                } else {
                    // The reset is validation on the view 'partial-medical-therapies-edit-item'.
                    validation[index].name = validateTranslation(item.step.name, this.language);
                }

                // Set validation status of the current item.
                validation[index].valid = validateState(validation[index]) && validation[index].choices.every(validateState);
                // // Only mutate the 'validSteps' property if it is still valid.
                if (valid[type]) valid[type] = validation[index].valid;
            }
        };

        validateSteps(this.therapy.plan.stepsToTake, this.validation.plan.steps, 'plan');
        this.validation.plan.valid = this.validation.plan.validSteps = valid.plan;

        validateSteps(this.therapy.execution.stepsToTake, this.validation.execution.steps, 'execution');
        this.validation.execution.valid = this.validation.execution.validSteps = valid.execution;

        // Validate for the evaluation steps.
        for (let sI = 0; sI < this.therapy.evaluation.stepsToTake.length; sI++) {
            const element = this.therapy.evaluation.stepsToTake[sI];

            let validItem: boolean;
            this.validation.evaluation.steps[sI].type = isDefined(element.type);
            if (this.validation.evaluation.steps[sI].type) {
                switch (element.type) {
                    case MedicalTherapyEvaluationItemTypes.Action:
                        this.validation.evaluation.steps[sI].action = validItem = isDefined(element.action);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Question:
                        this.validation.evaluation.steps[sI].question = validItem = isDefined(element.question);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Widget:
                        this.validation.evaluation.steps[sI].widget = validItem = isDefined(element.widget);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Questionnaire:
                        this.validation.evaluation.steps[sI].questionnaire = validItem = isDefined(element.questionnaire);
                        break;
                }
            }
            this.validation.evaluation.steps[sI].valid = validItem && this.validation.evaluation.steps[sI].type;
        }
        this.validation.evaluation.valid = this.validation.evaluation.steps.every((x: any) => x.valid);

        return validateState(this.validation) && this.validation.plan.valid && this.validation.execution.valid && this.validation.evaluation.valid;
    }

    private setValidationAndTranslations(): void {
        this.resetTranslations();
        const setValidations = (items: MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], validation: any[]): void => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    validation.push({
                        valid: true,
                        childrenValid: true,
                        name: true,
                        steps: [],
                        choices: [],
                        labels: []
                    });
                    setValidations(item.category.stepsToTake, validation[validation.length - 1].steps);
                } else {
                    const choices: any[] = [];
                    for (const choice of item.step.choices) {
                        // Because the ID property was added later, we have to
                        // make sure all items have an ID property.
                        if (isNotDefined(choice.id)) choice.id = guid();
                        choices.push({
                            id: choice.id,
                            value: true,
                            score: true,
                            numeric: true,
                            unique: true
                        });
                    }

                    const labels: any[] = [];
                    for (const _ of item.step.slider.labels) {
                        labels.push({
                            value: true,
                            valueValid: true,
                            unique: true,
                            label: true
                        });
                    }

                    validation.push({
                        valid: true,
                        name: true,
                        displayOrder: true,
                        question: true,
                        norm: true,
                        norms: true,
                        inputAmount: true,
                        inputAmountValid: true,
                        filesAmount: true,
                        choices,
                        defaultValue: true,
                        numericChoices: true,
                        choice: true,
                        minRange: true,
                        maxRange: true,
                        minValue: true,
                        maxValue: true,
                        startValue: true,
                        endValue: true,
                        endValueValid: true,
                        initialValue: true,
                        initialValueValid: true,
                        majorIncrement: true,
                        majorIncrementValid: true,
                        minorIncrement: true,
                        minorIncrementValid: true,
                        labels
                    });
                }
            }
        };

        for (const _ of this.therapy.evaluation.stepsToTake) {
            this.validation.evaluation.steps.push({
                type: true,
                valid: true,
                action: true,
                question: true,
                widget: true,
                questionnaire: true
            });
        }

        setValidations(this.therapy.plan.stepsToTake, this.validation.plan.steps);
        setValidations(this.therapy.execution.stepsToTake, this.validation.execution.steps);
    }

    private resetTranslations(): void {
        if (isNotDefined(this.therapy.description)) this.therapy.description = setTranslation({}, this.language);
        const setTranslations = (items: MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    // Category doesn't have non-required translation values.
                    setTranslations(item.category.stepsToTake);
                } else {
                    if (isNotDefined(item.step.description)) item.step.description = setTranslation({}, this.language);
                    if (isNotDefined(item.step.placeholder)) item.step.placeholder = setTranslation({}, this.language);
                    if (isNotDefined(item.step.question)) item.step.question = setTranslation({}, this.language);
                }
            }
        };
        setTranslations(this.therapy.execution.stepsToTake);
        setTranslations(this.therapy.plan.stepsToTake);

        // for (const _ of this.therapy.plan.stepsToTake)
        //     this.validation.plan.steps.push({
        //         type: true,
        //         valid: true,
        //         action: true,
        //         question: true,
        //         widget: true,
        //         questionnaire: true
        //     });

        // for (const _ of this.therapy.evaluation.stepsToTake)
        //     this.validation.evaluation.steps.push({
        //         type: true,
        //         valid: true,
        //         action: true,
        //         question: true,
        //         widget: true,
        //         questionnaire: true
        //     });
    }
}
