<import from="components/component-manage-rights/component-manage-rights" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <ux-header>
                <div class="flex items-center justify-between px-3">
                    <p class="font-medium text-gray-500" t="translation:partial-views.manage-users.labels.edit-user-configuration"></p>
                    <ux-dropdown if.bind="!isOwner" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                        <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                <path
                                    fill="currentColor"
                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                            </svg>
                        </div>
                        <ux-dropdown-option click.trigger="delete()">
                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                        </ux-dropdown-option>
                    </ux-dropdown>
                </div>
            </ux-header>
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="grid grid-cols-12 gap-3 mt-4">
                        <div class="col-span-6">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.first-name"></span>
                            </ux-label>
                            <ux-input value.two-way="user.firstName" valid.bind="validation.firstName"> </ux-input>
                        </div>
                        <div class="col-span-6">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.last-name"></span>
                            </ux-label>
                            <ux-input value.two-way="user.lastName" valid.bind="validation.lastName"> </ux-input>
                        </div>
                        <div class="col-span-12">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.email"></span>
                            </ux-label>
                            <ux-input disabled.bind="true" value.two-way="user.email"> </ux-input>
                        </div>
                        <div class="col-span-12">
                            <ux-alert color="info">
                                <div class="mt-0.5 flex flex-col gap-1">
                                    <span class="text-xs font-bold" t="translation:partial-views.manage-practice.labels.update-notice"></span>
                                    <div class="flex flex-col gap-3">
                                        <span class="text-xs" t="translation:partial-views.manage-practice.messages.update-notice"></span>
                                    </div>
                                </div>
                            </ux-alert>
                        </div>
                        <div if.bind="!isOwner" class="flex flex-col col-span-12 pb-5">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.rights"></span>
                            </ux-label>
                            <component-manage-rights roles.two-way="roles"></component-manage-rights>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <ux-footer>
                <div class="flex justify-end w-full gap-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading || deleting"
                        loading.bind="isLoading || deleting"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
