<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="converters/join" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden gap-x-2" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between">
                <p class="text-sm font-medium" t="translation:partial-views.connect-therapies.labels.header"></p>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full p-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <div class="flex flex-col">
                    <ux-multi-selector
                        options.bind="toSet()"
                        show-footer.bind="false"
                        force-remove.bind="true"
                        autocomplete.bind="true"
                        item-template.bind="getExtra"
                        values.two-way="selected"
                        t="[placeholder]translation:partial-views.connect-therapies.placeholders.select-diagnoses">
                    </ux-multi-selector>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-4 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <ux-footer>
            <div class="flex items-center justify-end flex-1">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button size="sm" color="primary" disabled.bind="isLoading || deleting || selected.length === 0" loading.bind="isLoading" click.trigger="link()">
                        <span class="pointer-events-none" t="global.buttons.link"></span>
                    </ux-button>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
