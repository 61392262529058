<import from="converters/translate" />

<ux-combobox
    init="false"
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    onselect.trigger="handleSelect($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="question of questions" value.bind="question.id">${ question.name | translate:language }</ux-combobox-option>
</ux-combobox>
