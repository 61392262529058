import { GetAuthenticatedResponse } from '@wecore/sdk-core';
import { Label, LabelEntityReference, LabelRegistration, PriorityTypes, UserEntityReference } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';

@containerless
export class ComponentLabelsSelector {
    @bindable() public registrations: LabelRegistration[];
    @bindable() public validation: any[];
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public currentUser: GetAuthenticatedResponse;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public PriorityTypes: typeof PriorityTypes = PriorityTypes;

    public attached(): void {
        if (this.registrations.empty()) this.addItem();
    }

    public addItem(): void {
        this.validation.push({
            valid: true,
            label: true
        });

        this.registrations.push(
            new LabelRegistration({
                createdAt: new Date(),
                createdBy: new UserEntityReference({
                    id: this.currentUser.id,
                    name: this.currentUser.displayName
                }),
                comments: {},
                priority: PriorityTypes.Low
            })
        );
    }

    public deleteItem(index: number): void {
        this.registrations.splice(index, 1);
        this.validation.splice(index, 1);

        if (this.registrations.empty()) this.addItem();
    }

    public handleLabelSelected = async (label: Label, index: number): Promise<void> => {
        this.registrations[index].label = new LabelEntityReference({
            id: label.id,
            translations: label.translations
        });
    };

    public manageTranslationsFor(index: number): void {
        this.manageTranslations(
            this.registrations[index].comments, //
            (translations: any) => (this.registrations[index].comments = translations),
            'textarea',
            false
        );
    }
}
