<section class="confirm-modal min-h-64 overflow-hidden">
    <ux-dialog class="flex w-screen flex-col overflow-hidden rounded-none md:h-auto md:rounded-lg">
        <div class="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex min-h-screen items-end justify-center p-4 text-center md:items-center">
                <div class="fixed inset-0 bg-gray-500/50 transition-opacity" aria-hidden="true"></div>
                <span class="hidden h-full sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
                <div class="inline-block w-full transform overflow-hidden rounded-lg bg-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle">
                    <div class="absolute right-0 top-0 hidden pr-4 pt-5 sm:block">
                        <button
                            click.trigger="cancel()"
                            type="button"
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2">
                            <span class="sr-only">Close</span>
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div
                            class="${ options.type === 'danger' ? 'bg-red-100' : 'bg-yellow-100' } mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                class="${ options.type === 'danger' ? 'text-red-600' : 'text-yellow-600' } h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">${ options.title }</h3>
                            <div class="mt-2">
                                <p class="pr-3 text-sm text-gray-500" innerhtml.bind="options.message"></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 flex flex-col gap-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                        <ux-button
                            color.bind="options.type === 'danger' ? 'danger' : 'primary'"
                            loading.bind="isLoading"
                            click.trigger="ok()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none text-sm">${options.btnOk}</span>
                        </ux-button>
                        <ux-button if.bind="options.canCancel" disabled.bind="isLoading" class="sm:mr-2" mode="outline" color="dark" click.trigger="cancel()">
                            <span class="pointer-events-none text-sm">${options.btnCancel}</span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </ux-dialog>
</section>
