<import from="converters/translate" />

<div class="${columns} flex flex-col">
    <div
        bg-gray-200.class="validation[index].valid &&  state.clipboard.actionItem && state.clipboard.actionItem.item.id === actionItem.id"
        bg-gray-100.class="validation[index].valid && !state.clipboard.actionItem || state.clipboard.actionItem.item.id !== actionItem.id"
        bg-red-100.class="!validation[index].valid"
        class="${ editMode ? 'gap-4' : '' } flex items-center justify-between rounded-lg pr-3 text-sm font-medium text-gray-800">
        <template if.bind="editMode">
            <div class="flex items-center flex-1 py-2 pl-3">
                <ux-input
                    rounded="left"
                    autofocus="true"
                    onkeydown.trigger="handleInputChanged($event)"
                    action.bind="c => manageTranslations('step:name', true, index, array)"
                    value.two-way="actionItem.step.name[language]"
                    valid.bind="validation[index].name"
                    t="[placeholder]translation:partial-views.medical-examination-actions.placeholders.step-name">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                    </svg>
                </ux-input>
                <button
                    class.bind="validation[index].name ? 'border-gray-300 text-green-500 focus:ring-gray-400' : 'border-red-500 text-red-500 focus:ring-red-400'"
                    class="inline-flex h-[38px] items-center rounded-r-lg border-y border-r bg-white transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-offset-1 sm:px-2"
                    click.trigger="toggleEditMode()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </div>
        </template>
        <a else href="javascript:" click.trigger="handleClick($event, index, array, validation)" class="flex items-center flex-1 min-w-0 py-2 pl-3 pr-2 gap-x-1">
            <div class="flex items-center flex-1 min-w-0 gap-x-3">
                <!-- <span class="text-gray-500" t="translation:global.labels.step"></span> -->
                <span if.bind="actionItem.step.name[language]" class="block truncate">${ actionItem.step.name | translate:language }</span>
                <span else class="block text-red-500 truncate" t="translation:global.labels.no-name"></span>
            </div>
            <div class="flex gap-0.5">
                <div if.bind="isRequired" class="h-2 w-2 rounded-sm bg-[#F25366]"></div>
                <div if.bind="hasConnectedSteps" class="w-2 h-2 bg-pink-400 rounded-sm"></div>
                <div if.bind="hasVisibilityRequirements" class="w-2 h-2 rounded-sm bg-primary"></div>
                <div if.bind="hasDdRequirements" class="h-2 w-2 rounded-sm bg-[#46ADF9]"></div>
                <div if.bind="hasActions" class="w-2 h-2 bg-black rounded-sm"></div>
            </div>
        </a>
        <ux-dropdown border.bind="false" transparent.bind="true" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                    <path
                        fill="currentColor"
                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                </svg>
            </div>
            <ux-dropdown-option if.bind="index !== 0" click.trigger="moveItem('up')">
                <span class="text-sm" t="global.buttons.move-up"></span>
            </ux-dropdown-option>
            <ux-dropdown-option if.bind="index < array.length - 1" click.trigger="moveItem('down')">
                <span class="text-sm" t="global.buttons.move-down"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="copyOrCut('copy')">
                <span class="text-sm" t="global.buttons.copy"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="copyOrCut('cut')">
                <span class="text-sm" t="global.buttons.cut"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addCategory(index)">
                <span class="text-sm" t="translation:partial-views.medical-examination-actions.buttons.add-action-category-before"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addCategory(index + 1)">
                <span class="text-sm" t="translation:partial-views.medical-examination-actions.buttons.add-action-category-after"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addStep(index)">
                <span class="text-sm" t="translation:partial-views.medical-examination-actions.buttons.add-action-step-before"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addStep(index + 1)">
                <span class="text-sm" t="translation:partial-views.medical-examination-actions.buttons.add-action-step-after"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="openSettings(actionItem, parent?.id, parent?.name[language])">
                <span class="text-sm" t="global.buttons.settings"></span>
            </ux-dropdown-option>
            <template if.bind="state.clipboard.actionItem && (state.clipboard.actionItem.item.id !== actionItem.id || state.clipboard.actionItem.command === 'copy')">
                <ux-dropdown-option click.trigger="pasteItem('before')">
                    <span class="text-sm" t="translation:global.buttons.paste-before"></span>
                </ux-dropdown-option>
                <ux-dropdown-option click.trigger="pasteItem('after')">
                    <span class="text-sm" t="translation:global.buttons.paste-after"></span>
                </ux-dropdown-option>
            </template>
            <ux-dropdown-divider></ux-dropdown-divider>
            <ux-dropdown-option click.trigger="remove(index, parent, validation)">
                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
            </ux-dropdown-option>
        </ux-dropdown>
    </div>
</div>
