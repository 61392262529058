import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { DeclarationStatusLog, DeclarationStatusLogsApiClient, GetDeclarationResponse } from '@wecore/sdk-healthcare';
import { isDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { VecozoApiClient } from '@wecore/sdk-integrations';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { Broadcasts } from '../../../infra/broadcasts/broadcasts';
import { VecozoBroadcaster } from '../../../infra/broadcasts/vecozo-broadcaster';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, DeclarationStatusLogsApiClient, VecozoApiClient, VecozoBroadcaster, ModalService)
export class PartialDeclarationsLogs extends BasePartialView {
    public logs: DeclarationStatusLog[] = [];
    public query: string;
    public isChecking: boolean = false;

    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly logsApi: DeclarationStatusLogsApiClient,
        private readonly vecozoApi: VecozoApiClient,
        private readonly broadcaster: VecozoBroadcaster,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                await Promise.all([
                    this.getData(false, true), //
                    this.broadcaster.start(this.cache.workspace)
                ]);

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(Broadcasts.DeclarationStatusLog, (data: { log: DeclarationStatusLog }) => {
                        this.logs = [data.log, ...this.logs];
                    })
                ];

                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarationLogs.attached', x));
    }

    public detaching(): void {
        this.broadcaster.stop();
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async edit(declaration: GetDeclarationResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditDeclaration.with({ id: declaration.id, closeable: true }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl })
        });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true, false, 250);

        element.isLoading = false;
    }

    public async check(): Promise<void> {
        this.isChecking = true;
        try {
            await this.vecozoApi.checkStatusOfSentDeclarations(this.authenticated.workspace.id);
            this.events.publish(CustomEvents.HealthcareInvoicesUpdated);
        } catch (e) {
            this.errorHandler.handle('declarations/logs', e);
        }
        this.isChecking = false;
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok,
            updateUrl: true
        });
    }

    public async clear(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declarations.questions.clear-logs.title'),
                message: this.t.tr('translation:partial-views.declarations.questions.clear-logs.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        await this.removeChildViews();
                        this.deleting = true;
                        try {
                            await this.logsApi.clearAllLogs(this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.declarations.notifications.clear-successfully.title'),
                                this.t.tr('translation:partial-views.declarations.notifications.clear-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            await this.getData(true);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[declarations-logs/clear]', e);
                        }
                    }
                }
            })
        );
    }

    private async getData(reset: boolean = false, initial: boolean = false, timeout: number = 0): Promise<void> {
        return new Promise(async (resolve) => {
            if (reset) {
                this.skip = 0;
                this.logs = [];
                this.endOfList = false;
                if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
            }

            this.isLoading = true;
            setTimeout(async () => {
                const response = await this.logsApi.search(this.authenticated.workspace.id, isNotEmpty(this.query) ? this.query : undefined, this.pageSize, this.skip);
                if (!reset && response.data.empty() && !initial) {
                    this.endOfList = true;
                    this.isLoading = false;
                    resolve();
                    return;
                }

                this.skip += Number(this.pageSize);
                this.logs = [...this.logs, ...response.data];
                this.isLoading = false;
                resolve();
            }, timeout);
        });
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
