<import from="./ux-dropdown.scss" />

<template class="relative inline-block text-left">
    <ux-button
        component.ref="trigger"
        border.bind="border"
        transparent.bind="transparent"
        padding.bind="padding"
        click.trigger="toggleVisibility($event)"
        disabled.bind="disabled"
        color.bind="color"
        size.bind="size"
        rounded.bind="rounded"
        type.bind="type"
        mode.bind="mode">
        <au-slot name="label" />
    </ux-button>
    <div
        if.bind="isVisible"
        bottom-[45px].class="placement === 'top-left' || placement === 'top-right'"
        left-0.class="placement === 'top-left' || placement === 'bottom-left'"
        right-0.class="placement === 'top-right' || placement === 'bottom-right'"
        ref="dropdown"
        class="${width} absolute z-50 mt-2 flex origin-top-right flex-col rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1">
        <au-slot slotchange.trigger="handleSlotChange($event.target.assignedNodes())" />
    </div>
</template>
