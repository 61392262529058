import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    ColumnBreakpoint,
    GetMedicalExaminationActionResponse,
    GetMedicalExaminationModelResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalQuestionResponse,
    GetMedicalQuestionnaireResponse,
    GetMedicalWidgetResponse,
    HealthcareCode,
    HealthcarePrice,
    InformationSheet,
    MedicalExaminationActionEntityReference,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationPhasesApiClient,
    MedicalExaminationStepVisibilityRequirement,
    MedicalExaminationTemplateItem,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalExaminationsApiClient,
    MedicalQuestionEntityReference,
    MedicalQuestionnaireEntityReference,
    MedicalWidgetEntityReference,
    StringBooleanKeyValuePair,
    StringMedicalExaminationModelBoxKeyValuePair,
    UpdateMedicalExaminationRequest
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { clearTemplateItem, copyTemplateItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import { addToSpecificIndex, cleanTranslatables, cloneDeep, removeItemSettings, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { CopyTemplateItem } from '../../../models/copy-template-item';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationsApiClient, ModalService, MedicalExaminationPhasesApiClient)
export class PartialMedicalExaminationsEdit extends BasePartialView {
    public examinationId: string;
    public examination: UpdateMedicalExaminationRequest;
    public active: MedicalExaminationTemplateItem;
    public activeIndex: number = 0;
    public phases: { [key: string]: GetMedicalExaminationPhaseResponse } = {};
    public MedicalExaminationTemplateItemStepTypes: typeof MedicalExaminationTemplateItemStepTypes = MedicalExaminationTemplateItemStepTypes;
    public validation = {
        name: true,
        displayOrder: true,
        any: true,
        validPhases: true,
        phases: []
    };
    public editWidth: boolean = false;
    public widthInput: UxInput;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly examinationsApi: MedicalExaminationsApiClient,
        private readonly modalService: ModalService,
        private readonly phasesApi: MedicalExaminationPhasesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.examinationId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [examination] = await Promise.all([
                    this.examinationsApi.getById(this.examinationId, this.authenticated.workspace.id, true) //
                ]);
                this.examination = examination;

                const phaseIds = this.examination.template.phases.map((x) => x.phase.id);
                const phases = await this.phasesApi.search(this.authenticated.workspace.id, '', phaseIds.length, 0, undefined, undefined, undefined, phaseIds);
                for (const phase of phases.data) this.phases[phase.id] = phase;

                if (isNotDefined(this.examination.description)) this.examination.description = setTranslation({}, this.language);
                if (isNotDefined(this.examination.prices)) this.examination.prices = [];
                if (isNotDefined(this.examination.codes)) this.examination.codes = [];

                this.setValidation();
                this.setActive(0);

                // Make sure all existing steps have a width.
                for (const step of this.examination.template.phases) {
                    if (step.width < 1278) step.width = 1278;
                }

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalExaminationsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
        this.store.dispatch(clearTemplateItem);
    }

    public async cancel(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async addPhase(): Promise<void> {
        await this.removeChildViews();
        this.examination.template.phases.push(
            new MedicalExaminationTemplateItem({
                id: guid(),
                stepsToTake: [],
                width: 1278
            })
        );
        this.validation.phases.push({
            phase: true,
            valid: true,
            steps: [],
            width: true
        });

        // Activate last item.
        this.setActive(this.examination.template.phases.length - 1);
    }

    public async removePhase(): Promise<void> {
        await this.removeChildViews();

        const step = this.examination.template.phases[this.activeIndex];
        delete this.phases[step.phase.id];

        this.examination.template.phases.splice(this.activeIndex, 1);
        this.validation.phases.splice(this.activeIndex, 1);

        // Activate last item if any.
        if (this.examination.template.phases.any()) this.setActive(this.examination.template.phases.length - 1);
        await this.removeChildViews();
    }

    public movePhase(direction: 'up' | 'down'): void {
        let newIndex = direction === 'up' ? this.activeIndex - 1 : this.activeIndex + 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex > this.examination.template.phases.length - 1) newIndex = this.examination.template.phases.length - 1;

        const phaseToMove = this.examination.template.phases[this.activeIndex];
        const validationToMove = this.validation.phases[this.activeIndex];

        this.examination.template.phases.splice(this.activeIndex, 1);
        this.validation.phases.splice(this.activeIndex, 1);

        this.examination.template.phases.splice(newIndex, 0, phaseToMove);
        this.validation.phases.splice(newIndex, 0, validationToMove);

        this.active = phaseToMove;
        this.activeIndex = newIndex;

        this.examination.template.phases = [...(this.examination.template.phases.length > 0 ? [this.examination.template.phases.shift()] : []), ...this.examination.template.phases];
        this.validation.phases = [...(this.validation.phases.length > 0 ? [this.validation.phases.shift()] : []), ...this.validation.phases];
    }

    public async setActive(index: number): Promise<void> {
        this.active = this.examination.template.phases[index];
        this.activeIndex = index;
        await this.removeChildViews();

        this.setViewWidth();
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.active.phase = phase;
        this.phases[phase.id] = phase;
    };

    public handleModelSelected = (model: GetMedicalExaminationModelResponse): void => {
        this.examination.model = model;
    };

    public addStep = (index: number): void => {
        addToSpecificIndex(
            this.active.stepsToTake, //
            new MedicalExaminationTemplateItemStep({
                id: guid(),
                attributes: {}
            }),
            index
        );

        addToSpecificIndex(
            this.validation.phases[this.activeIndex].steps, //
            {
                type: true,
                action: true,
                question: true,
                widget: true,
                questionnaire: true,
                breakpoints: [],
                valid: true,
                hasItemInAttributes: true
            },
            index
        );
    };

    public removeStep = async (index: number): Promise<void> => {
        await this.removeChildViews();

        const item = this.active.stepsToTake[index];

        this.active.stepsToTake.splice(index, 1);
        this.validation.phases[this.activeIndex].steps.splice(index, 1);

        removeItemSettings(this.examination.flow, item);

        this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
    };

    public moveStep = async (direction: 'up' | 'down', currentIndex: number): Promise<void> => {
        await this.removeChildViews();

        let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex > this.examination.template.phases[this.activeIndex].stepsToTake.length - 1) newIndex = this.examination.template.phases[this.activeIndex].stepsToTake.length - 1;

        const stepToMove = this.examination.template.phases[this.activeIndex].stepsToTake[currentIndex];
        const validationToMove = this.validation.phases[this.activeIndex].steps[currentIndex];

        this.examination.template.phases[this.activeIndex].stepsToTake.splice(currentIndex, 1);
        this.validation.phases[this.activeIndex].steps.splice(currentIndex, 1);

        this.examination.template.phases[this.activeIndex].stepsToTake.splice(newIndex, 0, stepToMove);
        this.validation.phases[this.activeIndex].steps.splice(newIndex, 0, validationToMove);
    };

    public toggleSetWidth(): void {
        this.validation.phases[this.activeIndex].width = true;
        if (this.editWidth) {
            const width = Number(this.widthInput.value);
            if (width < 1278) {
                this.validation.phases[this.activeIndex].width = false;
                return;
            }
            this.active.width = width;
        } else {
            if (isNotDefined(this.active.width) || this.active.width < 1278) this.active.width = 1278;
        }

        this.setViewWidth();
        this.editWidth = !this.editWidth;

        setTimeout(() => {
            if (isDefined(this.widthInput)) this.widthInput.setValue(this.active.width);
        });
    }

    public async handleWidthInputChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.toggleSetWidth();
    }

    public selectType(type: MedicalExaminationTemplateItemStepTypes, index: number): void {
        this.active.stepsToTake[index].type = type;
    }

    public copyOrCut = async (command: 'copy' | 'cut', index: number): Promise<void> => {
        await this.removeChildViews();
        await this.store.dispatch(
            copyTemplateItem,
            new CopyTemplateItem({
                item: this.examination.template.phases[this.activeIndex].stepsToTake[index],
                array: this.examination.template.phases[this.activeIndex].stepsToTake,
                index: index,
                validationItem: this.validation.phases[this.activeIndex].steps[index],
                validation: this.validation.phases[this.activeIndex].steps,
                command
            })
        );
    };

    public pasteItem = async (index: number = -1): Promise<void> => {
        await this.removeChildViews();
        const itemToCopy = this.state.clipboard.templateItem;
        if (isNotDefined(itemToCopy)) return;
        // If the command is 'cut' remove the item and validation
        // from their old positions.
        if (itemToCopy.command === 'cut') {
            itemToCopy.array.splice(itemToCopy.index, 1);
            itemToCopy.validation.splice(itemToCopy.index, 1);
        }

        // If we paste after the current item e.g.
        // the provided index is bigger than the current
        // index, substract 1 from the index to get the item
        // on the correct spot.
        if (index > itemToCopy.index) index--;

        // Create a copy without any references.
        // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
        const parsed = JSON.parse(JSON.stringify(itemToCopy.item));
        const copy = MedicalExaminationTemplateItemStep.fromJS(Object.assign({}, parsed));
        // If we're copying, make sure the item ids are unique.
        if (itemToCopy.command === 'copy') copy.id = guid();

        // Wait for the old array to update.
        setTimeout(async () => {
            addToSpecificIndex(this.examination.template.phases[this.activeIndex].stepsToTake, copy, index);
            const parsed = JSON.parse(JSON.stringify(itemToCopy.validationItem));
            addToSpecificIndex(this.validation.phases[this.activeIndex].steps, Object.assign({}, parsed), index);
            // Remove the copied item from the state.
            await this.store.dispatch(clearTemplateItem);
        });
    };

    public handleQuestionSelected = (question: GetMedicalQuestionResponse, index: number): void => {
        this.active.stepsToTake[index].question = new MedicalQuestionEntityReference({
            id: question.id,
            translations: question.name
        });
        this.active.stepsToTake[index].attributes.question = question;
    };

    public handleWidgetSelected = (widget: GetMedicalWidgetResponse, index: number): void => {
        this.active.stepsToTake[index].widget = new MedicalWidgetEntityReference({
            id: widget.id,
            translations: widget.name
        });
        this.active.stepsToTake[index].attributes.widget = widget;
    };

    public handleActionSelected = (action: GetMedicalExaminationActionResponse, index: number): void => {
        this.active.stepsToTake[index].action = new MedicalExaminationActionEntityReference({
            id: action.id,
            translations: action.name
        });
        this.active.stepsToTake[index].attributes.action = action;
    };

    public handleQuestionnaireSelected = (questionnaire: GetMedicalQuestionnaireResponse, index: number): void => {
        this.active.stepsToTake[index].questionnaire = new MedicalQuestionnaireEntityReference({
            id: questionnaire.id,
            translations: questionnaire.name
        });
        this.active.stepsToTake[index].attributes.questionnaire = questionnaire;
    };

    public openSettings = async (step: MedicalExaminationTemplateItemStep | MedicalExaminationActionItem): Promise<void> => {
        const settings = ['required', 'columns'];
        if (
            step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire ||
            step.type === MedicalExaminationTemplateItemStepTypes.Question ||
            step.type === MedicalExaminationTemplateItemStepTypes.Widget
        )
            settings.push('visibility');

        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.examination.flow,
            item: step,
            model: this.examination.model,
            active: this.active,
            settings
        }).whenClosed(
            async (
                result: PartialViewResults,
                response: {
                    breakpoints: ColumnBreakpoint[];
                    required: StringBooleanKeyValuePair;
                    hasVisibilityRequirements: StringBooleanKeyValuePair;
                    repeatDuringEvaluation: StringBooleanKeyValuePair;
                    visibilityRequirements: MedicalExaminationStepVisibilityRequirement[];
                    modelBox: StringMedicalExaminationModelBoxKeyValuePair;
                }
            ) => {
                if (result === PartialViewResults.Ok) {
                    // Remove old breakpoints and add new ones.
                    this.examination.flow.breakpoints = [
                        ...this.examination.flow.breakpoints.filter((x) => x.id !== step.id), //
                        ...response.breakpoints.map(ColumnBreakpoint.fromJS)
                    ];

                    // Remove old requirements and and new one if present.
                    this.examination.flow.required = this.examination.flow.required.filter((x) => x.key !== step.id);
                    if (isDefined(response.required))
                        this.examination.flow.required.push(
                            StringBooleanKeyValuePair.fromJS(response.required) //
                        );

                    // Remove old repeats and and new one if present.
                    this.examination.flow.repeatDuringEvaluation = this.examination.flow.repeatDuringEvaluation.filter((x) => x.key !== step.id);
                    if (isDefined(response.repeatDuringEvaluation))
                        this.examination.flow.repeatDuringEvaluation.push(
                            StringBooleanKeyValuePair.fromJS(response.repeatDuringEvaluation) //
                        );

                    // Remove old visibilityRequirements and add new ones.
                    this.examination.flow.visibilityRequirements = [
                        ...this.examination.flow.visibilityRequirements.filter((x) => x.id !== step.id), //
                        ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                    ];

                    this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
                }
            }
        );

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public collapseOrExpandAll(command: 'collapse' | 'expand'): void {
        const collapseOrExpandAction = (items: MedicalExaminationActionItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    setTimeout(() => (item.attributes.expanded = command === 'expand'));
                    setTimeout(() => collapseOrExpandAction(item.category.stepsToTake, command));
                } else setTimeout(() => (item.attributes.expanded = command === 'expand'));
            }
        };

        const collapseOrExpand = (items: MedicalExaminationTemplateItemStep[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                switch (item.type) {
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        item.attributes.expanded = command === 'expand';
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => {
                            for (const step of item.attributes.questionnaire.questions) //
                                (step as any).attributes.expanded = command === 'expand';
                        });
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => {
                            for (const step of item.attributes.questionnaire.questions) //
                                (step as any).attributes.expanded = command === 'expand';
                        });
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => collapseOrExpandAction(item.attributes.action.stepsToTake, command));
                        break;
                }
            }
        };
        collapseOrExpand(this.active.stepsToTake, command);
    }

    public async save(close: boolean = true): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.examination, 1);

                await this.examinationsApi.update(this.examinationId, this.authenticated.workspace.id, this.examination);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-actions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-actions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.examination.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                if (close) setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                else {
                    if (isNotDefined(this.examination.description)) this.examination.description = setTranslation({}, this.language);
                    this.isLoading = false;
                }
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.examination.description)) this.examination.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[edit-medical-examination]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-examinations.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-examinations.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.examination.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        await this.removeChildViews();
                        this.deleting = true;
                        try {
                            await this.examinationsApi.delete(this.examinationId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-examination-actions.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-examination-actions.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.examination.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-examination-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.examination[property],
            callback: (updatedTranslations: any) => {
                this.examination[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.examination.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.examination.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-examinations.questions.copy.title'),
                message: this.t.tr('partial-views.medical-examinations.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.examinationsApi.copy(this.examinationId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-examinations.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.medical-examinations.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-medical-examination]', e);
                        }
                    }
                }
            })
        );
    }

    public async createOrEditPrice(index: number = -1): Promise<void> {
        const price = index > -1 ? this.examination.prices[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.examination.prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.examination.prices[data.index] = data.price;
                    else this.examination.prices.push(data.price);

                    this.examination.prices = [
                        ...(this.examination.prices.length > 0 ? [this.examination.prices.shift()] : []), //
                        ...this.examination.prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removePrice(index: number): Promise<void> {
        this.examination.prices.splice(index, 1);
    }

    public formatDescription(index: number): string {
        let description = '';
        if (isDefined(this.examination.prices[index].periodStart)) description += format(this.examination.prices[index].periodStart, 'dd-MM-yyyy');
        if (isDefined(this.examination.prices[index].periodStart)) description += ' - ';
        if (isDefined(this.examination.prices[index].periodEnd)) description += format(this.examination.prices[index].periodEnd, 'dd-MM-yyyy');

        if (isDefined(this.examination.prices[index].insurer)) description += ` (${this.examination.prices[index].insurer.translations[this.language]})`;

        return description;
    }

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.examination.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.examination.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.examination.codes[data.index] = data.code;
                    this.examination.codes = [
                        ...(this.examination.codes.length > 0 ? [this.examination.codes.shift()] : []), //
                        ...this.examination.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.examination.codes.splice(index, 1);
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.examination.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.examination.displayOrder) && isOfType(Number(this.examination.displayOrder), 'number');
        this.validation.any = isDefined(this.examination.template.phases) && this.examination.template.phases.any();

        // For each phase
        for (let phaseIndex = 0; phaseIndex < this.examination.template.phases.length; phaseIndex++) {
            const template = this.examination.template.phases[phaseIndex];

            this.validation.phases[phaseIndex].phase = isDefined(template.phase);
            this.validation.phases[phaseIndex].width = isDefined(template.width) && template.width >= 1278;

            // For each step
            for (let stepIndex = 0; stepIndex < template.stepsToTake.length; stepIndex++) {
                const element = template.stepsToTake[stepIndex];
                this.validation.phases[phaseIndex].steps[stepIndex].type = isDefined(element.type);

                let item: boolean;
                let hasItemInAttributes: boolean;
                if (this.validation.phases[phaseIndex].steps[stepIndex].type) {
                    switch (element.type) {
                        case MedicalExaminationTemplateItemStepTypes.Action:
                            this.validation.phases[phaseIndex].steps[stepIndex].action = item = isDefined(element.action);
                            this.validation.phases[phaseIndex].steps[stepIndex].hasItemInAttributes = hasItemInAttributes = isDefined(element.attributes.action);
                            break;
                        case MedicalExaminationTemplateItemStepTypes.Question:
                            this.validation.phases[phaseIndex].steps[stepIndex].question = item = isDefined(element.question);
                            this.validation.phases[phaseIndex].steps[stepIndex].hasItemInAttributes = hasItemInAttributes = isDefined(element.attributes.question);
                            break;
                        case MedicalExaminationTemplateItemStepTypes.Widget:
                            this.validation.phases[phaseIndex].steps[stepIndex].widget = item = isDefined(element.widget);
                            this.validation.phases[phaseIndex].steps[stepIndex].hasItemInAttributes = hasItemInAttributes = isDefined(element.attributes.widget);
                            break;
                        case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                            this.validation.phases[phaseIndex].steps[stepIndex].questionnaire = item = isDefined(element.questionnaire);
                            this.validation.phases[phaseIndex].steps[stepIndex].hasItemInAttributes = hasItemInAttributes = isDefined(element.attributes.questionnaire);
                            break;
                    }
                }

                this.validation.phases[phaseIndex].steps[stepIndex].valid = this.validation.phases[phaseIndex].steps[stepIndex].type && item && hasItemInAttributes;
            }

            this.validation.phases[phaseIndex].valid =
                this.validation.phases[phaseIndex].phase && //
                this.validation.phases[phaseIndex].width &&
                this.validation.phases[phaseIndex].steps.every((x: any) => x.valid);
        }

        this.validation.validPhases = this.validation.phases.every((x) => x.valid);

        return validateState(this.validation) && this.validation.validPhases;
    }

    private setValidation(): void {
        for (const phase of this.examination.template.phases) {
            const steps: any[] = [];

            for (let index = 0; index < phase.stepsToTake.length; index++) {
                steps.push({
                    type: true,
                    action: true,
                    question: true,
                    widget: true,
                    questionnaire: true,
                    valid: true,
                    treatment: true
                });
            }

            this.validation.phases.push({
                phase: true,
                valid: true,
                steps,
                width: true
            });
        }
    }

    private setViewWidth(): void {
        const viewWidth = Number(this.active?.width || 1278) + 600;
        this.changeStyle({
            width: `${viewWidth}px`,
            minWidth: `${viewWidth}px`
        });
        this.scrollTo(this.partial);
    }
}
