<template>
    <div border-b.class="borderBottom" border-t.class="borderTop" rounded-b-lg.class="roundedBottom" class="border-x border-gray-300">
        <input change.trigger="handleFileSelected($event)" class="hidden" type="file" ref="picker" multiple />
        <ul if.bind="attachments.length > 0">
            <li border-b.class="$index !== attachments.length - 1" class="flex justify-between gap-5 border-gray-300 py-2 pl-2 pr-4" repeat.for="attachment of attachments">
                <div class="flex flex-1 justify-between gap-3">
                    <ux-button click.trigger="download($index)" padding.bind="false" border.bind="false" mode="outline" color="dark" class="flex flex-1">
                        <div class="flex items-center gap-1 px-1 text-gray-500 hover:text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                            </svg>
                            <span class="text-sm">${attachment.name}${attachment.extension}</span>
                        </div>
                    </ux-button>
                </div>
                <button
                    if.bind="canDelete"
                    click.trigger="deleteAttachment($index)"
                    class="flex h-5 w-5 items-center justify-center rounded-lg text-left ring-red-500 ring-offset-1 focus:outline-none focus:ring-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-red-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </li>
        </ul>
        <ul if.bind="uploads.length > 0" border-t.class="attachments.length > 0 " class="border-gray-300">
            <li border-b.class="$index !== uploads.length - 1" class="flex justify-between gap-5 border-gray-300 py-2 pl-2 pr-4" repeat.for="upload of uploads">
                <div class="flex flex-1 justify-between gap-3">
                    <div class="flex flex-1 items-center gap-1 text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                        </svg>
                        <p class="text-sm">${upload.name}${upload.extension}</p>
                    </div>
                    <p class="text-sm" if.bind="upload.isUploading" text-yellow-600.class="upload.progress < 100" text-green-500.class="upload.progress === 100">${upload.progress}%</p>
                </div>
                <button click.trigger="deleteFromUploads($index)" class="flex h-5 w-5 items-center justify-center rounded-lg text-left ring-red-500 ring-offset-1 focus:outline-none focus:ring-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-red-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </li>
        </ul>
    </div>
</template>
