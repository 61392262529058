import { MedicalExaminationActionFlow, MedicalExaminationActionItem, MedicalExaminationActionItemTypes } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { generateColumns } from '../../../../../infra/utilities';

@containerless
@inject(IEventAggregator)
export class TemplateActionCategory {
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public language: string;

    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public columns: string;
    private subscription: any;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.setColumns();
        this.subscription = this.events.subscribe(CustomEvents.ExaminationStepSettingsChanged, this.setColumns);
        if (isNotDefined(this.item.attributes)) this.item.attributes = {};
    }

    public detached(): void {
        if (isDefined(this.subscription)) this.subscription.dispose();
    }

    public collapseOrExpand(): void {
        this.item.attributes.expanded = !this.item.attributes.expanded;
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.id) || [] //
        );
    };
}
