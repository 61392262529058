<import from="converters/sort-by-language" />

<section class="overflow-hidden send-email-modal min-h-64 md:overflow-visible">
    <ux-dialog class="flex flex-col w-screen h-auto overflow-hidden rounded-none md:overflow-visible md:rounded-lg">
        <div class="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen p-4 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity bg-gray-500/50" aria-hidden="true"></div>
                <span class="hidden h-full sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
                <div class="inline-block w-full p-6 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle">
                    <div>
                        <div class="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-green-600">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                            </svg>
                        </div>
                        <div class="mt-3 sm:mt-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <div class="flex flex-col" repeat.for="lan of languages | sortByLanguage:language">
                                    <ux-label class="${ language !== lan ? 'mb-1' : '' } leading-tight" t="global.languages.${lan}"></ux-label>
                                    <p class="mb-1 font-medium leading-tight text-gray-400 text-xxs" if.bind="language === lan" t="partial-views.templates.messages.current-language"></p>
                                    <ux-input
                                        allow-clear.bind="lan !== language"
                                        onkeyup.trigger="handleKeydown($event)"
                                        value.two-way="translations[lan]"
                                        valid.bind="language === lan ? validation[lan]: true"
                                        t="[placeholder]global.placeholders.add-translation">
                                    </ux-input>
                                </div>
                                <p if.bind="!validation[language]" class="mt-2 text-xs text-red-500" t="translation:modals.custom-dropdown-value.messages.required-value"></p>
                            </div>
                        </div>
                    </div>
                    <div class="grid gap-3 mt-5 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2">
                        <ux-button disabled.bind="isLoading" type="full" mode="outline" color="dark" click.trigger="cancel()">
                            <span class="pointer-events-none" t="translation:global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            type="full"
                            color.bind="primary"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="translation:global.buttons.use"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </ux-dialog>
</section>
