<import from="converters/translate" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="mb-3 flex items-center justify-between">
                <p class="text-sm font-medium" t="partial-views.medical-questionnaires.list.title"></p>
                <ux-button click.trigger="create()" padding.bind="false" size="xs">
                    <div class="flex p-1">
                        <svg class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </ux-button>
            </div>
            <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>
        </ux-header>
        <div ref="scrollContainer" class="flex h-full flex-1 flex-col overflow-y-auto scroll-smooth px-6 pb-6">
            <template if.bind="baseLoaded">
                <ul class="flex flex-1 flex-col">
                    <li repeat.for="questionnaire of questionnaires" class="group">
                        <a click.trigger="edit(questionnaire)" id.bind="questionnaire.id" href="javascript:" class="flex items-center justify-between border-b border-gray-200 py-3">
                            <div class="flex gap-x-2">
                                <div class="flex flex-col">
                                    <p class="text-sm font-semibold group-hover:text-gray-600">${ questionnaire.name | translate:language }</p>
                                    <!-- <span class="text-xs text-gray-500">Onbekend klacht / Geen therapie </span> -->
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 text-gray-500 group-hover:text-black">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="questionnaires.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-1 flex-col gap-y-2 pt-4">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex w-full flex-col space-y-5 py-1">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
