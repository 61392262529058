<import from="converters/translate" />
<import from="converters/date" />
<import from="converters/email-status-to-string" />
<import from="converters/get-to-address" />
<import from="bx/bx-email-template-selector/bx-email-template-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-hidden">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-40 flex justify-between pt-8 pb-3 bg-white border-gray-200">
                        <div class="grid w-full grid-cols-12 gap-3">
                            <div class="flex flex-col col-span-4">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.send-email-to-patient.labels.email-address"></span>
                                </ux-label>
                                <p class="flex h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 bg-gray-50 px-3 text-sm">${log | getToAddress}</p>
                            </div>
                            <div class="flex flex-col col-span-8">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.send-email-to-patient.labels.subject"></span>
                                </ux-label>
                                <p class="flex min-h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 bg-gray-50 px-3 text-sm">${log.subject}</p>
                            </div>
                            <div class="col-span-6">
                                <p class="flex min-h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 bg-gray-50 px-3 text-sm">
                                    <span if.bind="log.template">${log.template.translations | translate:language}</span>
                                    <span else>N/A</span>
                                </p>
                            </div>
                            <div class="col-span-6 flex h-[38px] min-h-[38px] flex-1 items-center gap-x-2 rounded-lg border border-gray-300 bg-gray-50 px-3 py-2 text-sm">
                                <div if.bind="log.status === EmailLogStatuses.New" class="w-1 h-full transition-all duration-300 ease-in-out bg-blue-500 rounded-full group-hover:ring-2"></div>
                                <div if.bind="log.status === EmailLogStatuses.Failed" class="w-1 h-full transition-all duration-300 ease-in-out bg-red-500 rounded-full group-hover:ring-2"></div>
                                <div if.bind="log.status === EmailLogStatuses.Sent" class="w-1 h-full transition-all duration-300 ease-in-out bg-green-500 rounded-full group-hover:ring-2"></div>
                                <p class="flex truncate">${log.status | emailStatusToString | t}</p>
                            </div>
                            <div class="col-span-12 flex min-h-[38px] flex-1 items-center gap-x-2 rounded-lg border border-gray-300 bg-gray-50 px-3 py-2 text-sm">
                                <div if.bind="log.status === EmailLogStatuses.New" class="flex justify-between flex-1">
                                    <p class="text-sm">
                                        <span t="translation:partial-views.patient-contact-moments.labels.created-at"></span>
                                        <span>${ log.createdAt | date:'dd-MM-yyyy HH:mm:ss' }</span>
                                    </p>
                                    <span class="text-primary" t="translation:partial-views.patient-contact-moments.labels.waiting-for-send"></span>
                                </div>
                                <p if.bind="log.status === EmailLogStatuses.Sent" class="text-sm">
                                    <span t="translation:partial-views.patient-contact-moments.labels.sent-at"></span>
                                    <span>${ log.sentAt | date:'dd-MM-yyyy HH:mm:ss' }</span>
                                </p>
                                <div if.bind="log.status === EmailLogStatuses.Failed" class="flex justify-between flex-1 space-x-5">
                                    <p class="text-sm">
                                        <span t="translation:partial-views.patient-contact-moments.labels.failed-at"></span>
                                        <span>${ log.failedAt | date:'dd-MM-yyyy HH:mm:ss' }</span>
                                    </p>
                                    <p><span class="text-red-500" t="translation:partial-views.patient-contact-moments.labels.reason"></span><span class="text-red-500">: ${ log.reason }</span></p>
                                </div>
                            </div>
                            <div if.bind="log.attachments.length > 0" class="col-span-12 flex min-h-[38px] flex-1 items-center gap-x-2 rounded-lg border border-gray-300 bg-gray-50 px-3 py-2 text-sm">
                                <div class="flex">
                                    <span t="translation:global.labels.attachments"></span>
                                    <span>:</span>
                                </div>
                                <a repeat.for="item of log.attachments" click.trigger="showDocument(item)" href="javascript:" class="text-primary hover:underline">${item.name}</a>
                            </div>
                        </div>
                    </div>
                    <div ref="scrollContainer" class="mb-3 flex min-h-[38px] flex-1 flex-col gap-x-2 overflow-y-auto rounded-lg border border-gray-300 bg-gray-50 px-8 py-7 text-sm">
                        <div class="w-full" innerhtml.bind="log.content"></div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10 pb-5">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <ux-footer>
            <div class="flex justify-between w-full py-4 gap-x-2">
                <div class="flex items-center space-x-1">
                    <template if.bind="log.useZorgmail">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-primary">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <p if.bind="log.status === EmailLogStatuses.New" class="text-sm text-primary" t="translation:partial-views.patient-contact-moments.labels.send-via-zorgmail"></p>
                        <p else class="text-sm text-primary" t="translation:partial-views.patient-contact-moments.labels.sent-via-zorgmail"></p>
                    </template>
                </div>
                <div class="flex gap-x-3">
                    <ux-button size="sm" color="dark" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
