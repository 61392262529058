<import from="converters/file-name" />

<ux-card>
    <div class="flex items-center gap-5 rounded-lg border border-gray-200 py-3 pl-3 pr-5">
        <div class="flex min-w-0 flex-1 flex-col">
            <div class="flex min-w-0 items-center justify-between gap-5">
                <button click.trigger="rename()" class="flex min-w-0 rounded-full px-3 py-1 text-left hover:bg-gray-100 focus:outline-none active:bg-gray-200">
                    <template if.bind="upload">
                        <span class="block truncate text-sm font-semibold">${ upload.name | fileName }</span>
                        <span class="text-sm font-semibold">${ upload.extension }</span>
                    </template>
                    <template else>
                        <span class="block truncate text-sm font-semibold">${ attachment.name }</span>
                        <span class="text-sm font-semibold">${ attachment.extension }</span>
                    </template>
                </button>
                <div class="min-w-[90px] whitespace-nowrap rounded-full bg-gray-100 px-3 py-0.5 text-center text-xs">${current} / ${duration}</div>
            </div>
            <div class="mt-1 flex items-center justify-between gap-3 pl-3">
                <div class="flex gap-1">
                    <button
                        bg-green-500.class="state === 'ready' || state === 'pauzed'"
                        active:bg-green-600.class="state === 'ready' || state === 'pauzed'"
                        bg-yellow-500.class="state === 'playing'"
                        active:bg-yellow-600.class="state === 'playing'"
                        click.trigger="toggleStartOrPauze()"
                        class="flex h-7 w-7 items-center justify-center rounded-full text-white hover:cursor-pointer">
                        <svg if.bind="state === 'playing'" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z"
                                clip-rule="evenodd" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 24 24" fill="currentColor">
                            <path
                                fill-rule="evenodd"
                                d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                    <button click.trigger="handleDelete()" class="flex h-7 w-7 items-center justify-center rounded-full bg-red-500 text-white hover:cursor-pointer active:bg-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-3 w-3">
                            <path
                                fill-rule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <label class="flex items-center gap-3 text-xs font-medium text-gray-400 dark:text-white">
                    0.25x
                    <input
                        class="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 accent-blue-500 dark:bg-gray-700"
                        change.trigger="handleSpeedChange($event)"
                        type="range"
                        min="0"
                        max="5"
                        step="1"
                        value.two-way="speed" />
                    3x
                </label>
            </div>
        </div>
        <div id="recording-${id}" class="flex flex-1 flex-col rounded-lg border border-gray-200 px-5 py-2"></div>
    </div>
</ux-card>
