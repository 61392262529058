<import from="converters/translate" />

<div class="col-span-12 flex flex-col">
    <div class="flex items-center justify-between rounded-lg bg-gray-100 px-3 py-1.5 text-sm font-medium text-gray-800">
        <div class="flex min-w-0 flex-col">
            <div class="flex min-w-0 items-center gap-x-3">
                <span class="text-gray-500" t="translation:global.labels.question"></span>
                <span if.bind="nested" class="block truncate">${ step.question.name | translate:language }</span>
                <span else class="block truncate">${ step.attributes.question.name | translate:language }</span>
            </div>
            <template if.bind="!nested">
                <div class="mt-1 flex gap-x-1 pl-1 text-xs text-gray-500">
                    <p t="translation:partial-views.medical-suggestions.labels.will-be-added-to-phase"></p>
                    <p class="font-bold">${step.attributes.question.phase.translations | translate:language}</p>
                </div>
            </template>
        </div>
    </div>
</div>
