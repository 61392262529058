import { isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

export class MarkWordsValueConverter {
    public toView(words: string[], text: string, color: string = 'text-indigo-500'): string {
        // If words is not defined or empty, return empty string
        if (isNotDefined(words) || words.empty()) return '';

        const joined = words.join(', ');
        if (isNotDefined(text)) text = '';

        const escapeRegExp = (text: string) => {
            // Remove special characters using regex
            let cleanedText = text.replace(/[^\w\s]/gi, '');
            // Remove line breaks
            cleanedText = cleanedText.replace(/\n/g, ' ');
            // Remove double spaces
            cleanedText = cleanedText.replace(/\s{2,}/g, ' ');
            // Return the cleaned text
            return cleanedText;
        };

        const groups = text
            .split(' ')
            .filter((word) => isNotEmpty(word))
            .map((word) => `(${escapeRegExp(word.trim())})`)
            .join('|');

        return `<span class="${color}">` + joined.replace(new RegExp(groups, 'gi'), (match) => `<span class="bg-yellow-300 text-black">${match}</span>`) + '<span>';
    }
}
