import { MedicalTherapyPlanItem } from '@wecore/sdk-healthcare';

export interface ICopyTherapyPlanItem {
    item: MedicalTherapyPlanItem;
    array: MedicalTherapyPlanItem[];
    index: number;
    validation: any[];
    validationItem: any;
    command: 'copy' | 'cut';
}

export class CopyTherapyPlanItem {
    public item: MedicalTherapyPlanItem;
    public array: MedicalTherapyPlanItem[];
    public index: number;
    public validation: any[];
    public validationItem: any;
    public command: 'copy' | 'cut';

    public constructor(data: ICopyTherapyPlanItem) {
        this.item = data?.item;
        this.array = data?.array;
        this.index = data?.index;
        this.validation = data?.validation;
        this.validationItem = data?.validationItem;
        this.command = data?.command;
    }
}
