import { UserRoles } from '@wecore/sdk-core';
import { GetAppointmentResponse } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { format, getHours, getMinutes, hoursToMinutes, intervalToDuration, secondsToMinutes, setHours, setMinutes } from 'date-fns';
import { CustomEvents } from '../../infra/events';
import { getTotalAmountOfSlots } from '../../infra/utilities';
import { SchedulerSettings } from '../../models/scheduler-settings';
import { SchedulerState } from '../../models/scheduler-state';

@containerless
@inject(IEventAggregator)
export class BxSchedulerAppointment {
    @bindable() public settings: SchedulerSettings;
    @bindable() public appointment: GetAppointmentResponse;
    @bindable() public columnDate: Date;
    @bindable() public columnHeight: number;
    @bindable() public columnIndex: number;
    @bindable() public layers: { [key: string]: { zIndex: number } } = {};
    @bindable() public zIndexParent: number = 0;
    @bindable() public state: SchedulerState;
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public onDragStart: (e: Event, appointment: GetAppointmentResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDrag: (e: Event, appointment: GetAppointmentResponse) => Promise<void>;
    @bindable() public onDragEnd: (e: Event, appointment: GetAppointmentResponse) => Promise<void>;
    @bindable() public onDragCanceled: (e: KeyboardEvent, appointment: GetAppointmentResponse) => Promise<void>;
    @bindable() public onResizeStart: (e: Event, side: 'top' | 'bottom', appointment: GetAppointmentResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onResize: (e: Event, side: 'top' | 'bottom', appointment: GetAppointmentResponse) => Promise<void>;
    @bindable() public onResizeEnd: (e: Event, side: 'top' | 'bottom', appointment: GetAppointmentResponse) => Promise<void>;
    @bindable() public onResizeCanceled: (e: KeyboardEvent, side: 'top' | 'bottom', appointment: GetAppointmentResponse) => Promise<void>;
    @bindable() public onClick: (appointment: GetAppointmentResponse) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onEdit: (appointment: GetAppointmentResponse) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onDelete: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onPatientCard: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onConfirmAppointment: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;

    public container: HTMLDivElement;
    public content: HTMLDivElement;
    public styles: any;
    public height: number;
    public rounding: 'rounded-lg' | 'rounded-t-lg' | 'rounded-b-lg' | 'rounded-none';
    public UserRoles: typeof UserRoles = UserRoles;
    public paddingTop: number = 0;

    private subscriptions: IDisposable[] = [];
    private moveCb: (e: MouseEvent) => void;
    private moveEndCb: (e: MouseEvent) => void;
    private moveKeyCb: (e: KeyboardEvent) => void;
    private resizeCb: (e: MouseEvent) => void;
    private resizeEndCb: (e: MouseEvent) => void;
    private resizeKeyCb: (e: KeyboardEvent) => void;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.render();

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.SchedulerAppointmentCreated, () => this.render()),
            this.events.subscribe(CustomEvents.SchedulerAppointmentDragged, () => this.render()),
            this.events.subscribe(CustomEvents.SchedulerAppointmentResized, () => this.render()),
            this.events.subscribe(CustomEvents.SchedulerAppointmentRefresh, () => this.render()),
            this.events.subscribe(CustomEvents.SchedulerAppointmentPasted, () => this.render())
        ];
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
    }

    public handleContextMenu(e: MouseEvent): void {
        e.preventDefault();

        this.container.classList.add('loading');

        const clones = document.querySelectorAll('[data-type="context-menu-clone"]');
        clones.forEach((c) => c.remove());

        const doc = document.querySelector('[data-type="app"]');
        const menu = this.container.querySelector('[data-type="context-menu"]');

        const clone = menu.cloneNode(true) as HTMLDivElement;
        clone.classList.remove('hidden');
        clone.setAttribute('data-type', 'context-menu-clone');

        clone.style.left = `${e.clientX}px`;
        clone.style.top = `${e.clientY}px`;
        clone.style.zIndex = '1000';

        const deleteBtn = clone.querySelector('[data-function="delete"]');
        if (isDefined(deleteBtn)) deleteBtn.addEventListener('mousedown', () => this.delete());

        const editBtn = clone.querySelector('[data-function="edit"]');
        if (isDefined(editBtn)) editBtn.addEventListener('mousedown', () => this.edit());

        const cutBtn = clone.querySelector('[data-function="cut"]');
        if (isDefined(cutBtn)) cutBtn.addEventListener('mousedown', () => this.cut());

        const patientCard = clone.querySelector('[data-function="patient-card"]');
        if (isDefined(patientCard)) patientCard.addEventListener('mousedown', () => this.patientCard());

        const confirmAppointment = clone.querySelector('[data-function="appointment-confirmation"]');
        if (isDefined(confirmAppointment)) confirmAppointment.addEventListener('mousedown', () => this.confirmAppointment());

        doc.appendChild(clone);
    }

    public async delete(): Promise<void> {
        if (isFunction(this.onDelete)) {
            const originalWidth = this.container.style.width;
            const originalZIndex = this.container.style.zIndex;
            const originalLeft = this.container.style.left;

            this.container.classList.add('loading');
            this.container.style.width = '100%';
            this.container.style.left = '0px';
            this.container.style.zIndex = '10000';

            const { success } = await this.onDelete(this.appointment);
            if (success)
                this.events.publish(CustomEvents.SchedulerAppointmentDeleted, {
                    columnDate: this.columnDate,
                    appointment: this.appointment
                });
            else {
                this.container.style.width = originalWidth;
                this.container.style.zIndex = originalZIndex;
                this.container.style.left = originalLeft;
                this.container.classList.remove('loading');
            }
        }
    }

    public async edit(): Promise<void> {
        if (isFunction(this.onEdit)) {
            const originalWidth = this.container.style.width;
            const originalZIndex = this.container.style.zIndex;
            const originalLeft = this.container.style.left;

            this.container.classList.add('loading');
            this.container.style.width = '100%';
            this.container.style.left = '0px';
            this.container.style.zIndex = '10000';

            const { success, appointment } = await this.onEdit(this.appointment);
            if (success) {
                this.appointment = appointment;
                this.events.publish(CustomEvents.SchedulerAppointmentUpdated, {
                    columnDate: this.columnDate,
                    appointment: this.appointment
                });
                this.render();
            }

            if (isDefined(this.container)) {
                this.container.style.width = originalWidth;
                this.container.style.zIndex = originalZIndex;
                this.container.style.left = originalLeft;
                this.container.classList.remove('loading');
            }
        }
    }

    public async cut(): Promise<void> {
        this.state.cut = {
            appointment: this.appointment,
            columnIndex: this.columnIndex
        };
        this.container.classList.remove('loading');
    }

    public async patientCard(): Promise<void> {
        await this.onPatientCard(this.appointment);
        this.container.classList.remove('loading');
    }

    public async confirmAppointment(): Promise<void> {
        await this.onConfirmAppointment(this.appointment);
        this.container.classList.remove('loading');
    }

    public async handleMoveMouseDown(e: MouseEvent): Promise<void> {
        // Only left click.
        if (e.button !== 0) return;
        // We must distinguish between a click and a drag.
        // Record the initial mouse position.
        const startX = e.clientX;
        const startY = e.clientY;
        const handleMouseMove = async (event: MouseEvent): Promise<void> => {
            // Calculate the current mouse position.
            const currentX = event.clientX;
            const currentY = event.clientY;
            // Check if the mouse has moved more than 5 pixels in either direction
            if (Math.abs(currentX - startX) > 5 || Math.abs(currentY - startY) > 5) {
                this.state.isAppointmentDragging = true;
                // Remove the event listener that listens for the initial mouse down event.
                // Otherwise the event listener will be called multiple times.
                document.removeEventListener('mousemove', handleMouseMove);
                // Lets start the dragging process.
                this.container.classList.remove('hover:cursor-pointer');
                this.container.classList.add('cursor-move');
                await this.onDragStart(e, this.appointment, this.container);
                this.container.classList.add('opacity-50');
                // Save the callbacks otherwise removeEventListener won't work.
                this.moveCb = (e) => this.handleMoveMouseMove(e);
                this.moveEndCb = (e) => this.handleMoveMouseUp(e);
                this.moveKeyCb = (e) => this.handleMoveKeyEvent(e);
                // Add the event listeners.
                document.addEventListener('mousemove', this.moveCb);
                document.addEventListener('mouseup', this.moveEndCb, { once: true });
                document.addEventListener('keydown', this.moveKeyCb, { once: true });
            }
        };

        const handleMouseUp = async () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);

            // Check if the appointment is already being dragged
            // Otherwise the appointment is clicked.
            if (this.state.isAppointmentDragging) return;
            if (isFunction(this.onClick)) {
                const originalWidth = this.container.style.width;
                const originalZIndex = this.container.style.zIndex;
                const originalLeft = this.container.style.left;

                this.container.classList.add('loading');
                this.container.style.width = '100%';
                this.container.style.left = '0px';
                this.container.style.zIndex = '10000';

                const { success, appointment } = await this.onClick(this.appointment);
                if (success) {
                    this.appointment = appointment;
                    this.events.publish(CustomEvents.SchedulerAppointmentUpdated, {
                        columnDate: this.columnDate,
                        appointment: this.appointment
                    });
                    this.render();
                }

                this.container.style.width = originalWidth;
                this.container.style.zIndex = originalZIndex;
                this.container.style.left = originalLeft;
                this.container.classList.remove('loading');
            }
        };

        // Lets listen for the mouse move event.
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    public async handleResizeMouseDown(e: MouseEvent, side: 'top' | 'bottom'): Promise<void> {
        // Only left click.
        if (e.button !== 0) return;

        this.state.isAppointmentResizing = true;
        await this.onResizeStart(e, side, this.appointment, this.container);
        this.container.classList.add('opacity-50');

        // Save the callback otherwise removeEventListener won't work.
        this.resizeCb = (e) => this.handleResizeMouseMove(e, side);
        this.resizeEndCb = (e) => this.handleResizeMouseUp(e, side);
        this.resizeKeyCb = (e) => this.handleResizeKeyEvent(e, side);

        document.addEventListener('mousemove', this.resizeCb);
        document.addEventListener('mouseup', this.resizeEndCb, { once: true });
        document.addEventListener('keydown', this.resizeKeyCb, { once: true });
    }

    private async handleMoveMouseMove(e: MouseEvent): Promise<void> {
        if (this.state.isAppointmentDragging) await this.onDrag(e, this.appointment);
    }

    private async handleMoveMouseUp(e: MouseEvent): Promise<void> {
        this.state.isAppointmentDragging = false;
        this.container.classList.add('hover:cursor-pointer');
        this.container.classList.remove('cursor-move');
        await this.onDragEnd(e, this.appointment);
        if (isDefined(this.container)) this.container.classList.remove('opacity-50');

        document.removeEventListener('mousemove', this.moveCb);
        document.removeEventListener('mouseup', this.moveEndCb);
        document.removeEventListener('keydown', this.moveKeyCb);
    }

    private async handleMoveKeyEvent(e: KeyboardEvent): Promise<void> {
        if (!this.state.isAppointmentDragging) return;

        await this.onDragCanceled(e, this.appointment);

        if (e.key === 'Escape' && isDefined(this.container)) {
            this.container.classList.add('hover:cursor-pointer');
            this.container.classList.remove('opacity-50');
            this.container.classList.remove('cursor-move');

            document.removeEventListener('mousemove', this.moveCb);
            document.removeEventListener('mouseup', this.moveEndCb);
            document.removeEventListener('keydown', this.moveKeyCb);
        }
    }

    private async handleResizeMouseMove(e: MouseEvent, side: 'top' | 'bottom'): Promise<void> {
        if (this.state.isAppointmentResizing) await this.onResize(e, side, this.appointment);
    }

    private async handleResizeMouseUp(e: MouseEvent, side: 'top' | 'bottom'): Promise<void> {
        this.state.isAppointmentResizing = false;
        await this.onResizeEnd(e, side, this.appointment);
        if (isDefined(this.container)) this.container.classList.remove('opacity-50');

        document.removeEventListener('mousemove', this.resizeCb);
        document.removeEventListener('mouseup', this.resizeEndCb);
        document.removeEventListener('keydown', this.resizeKeyCb);
    }

    private async handleResizeKeyEvent(e: KeyboardEvent, side: 'top' | 'bottom'): Promise<void> {
        if (!this.state.isAppointmentResizing) return;

        await this.onResizeCanceled(e, side, this.appointment);

        if (e.key === 'Escape' && isDefined(this.container)) {
            this.container.classList.remove('opacity-50');
            document.removeEventListener('mousemove', this.resizeCb);
            document.removeEventListener('mouseup', this.resizeEndCb);
            document.removeEventListener('keydown', this.resizeKeyCb);
        }
    }

    private render(): void {
        const { start, height } = this.calculateStartAndEndPercentage(this.appointment);
        this.setRoundedCorners();
        // Check if the appointment is overlapping with any other appointments.
        const layer = this.layers[this.appointment.id];

        // Reduce the width of the appointment based on the the z-index.
        // So that overlapping appointments stays visible.
        const widthBlockPercentage = 10;
        const width = 100 - widthBlockPercentage * layer.zIndex;

        if (isNotDefined(this.content) && isNotDefined(this.container)) return;

        // Set styles.
        this.container.style.height = `${height}%`;
        this.container.style.right = '0px';
        this.container.style.minHeight = '22px';
        this.container.style.top = `${start}%`;
        this.container.style.width = `${width}%`;
        this.container.style.left = `${100 - width}%`;
        this.container.style.zIndex = layer.zIndex.toString();

        this.height = this.container.clientHeight;
        this.paddingTop = this.height < 25 ? 1 : this.height < 44 ? 5 : 10;
    }

    private calculateStartAndEndPercentage(appointment: GetAppointmentResponse): { start: number; height: number } {
        // First calculate the total amount of slots and the percentage per slot.
        const totalAmountOfSlots = getTotalAmountOfSlots(this.columnDate, this.settings);
        const percentagePerSlot = 100 / totalAmountOfSlots;

        const getStartingPosition = (start: Date): number => {
            // First get the duration from the start of the day to the start of the appointment.
            const durationBeforeStart = intervalToDuration({
                start: setMinutes(setHours(this.columnDate, this.settings.startHour), this.settings.startMinute),
                end: start
            });
            // Calculate how many slots we need for the duration before the start of the appointment.
            const slotsForTheHours = durationBeforeStart.hours * (60 / this.settings.slotSize);
            const slotsForTheMinutes = durationBeforeStart.minutes / this.settings.slotSize;
            // Calculate the percentage for the duration before the start of the appointment.
            return (slotsForTheHours + slotsForTheMinutes) * percentagePerSlot;
        };

        const getHeight = (appointment: GetAppointmentResponse, isContinuingFromPreviousDay: boolean = false): { height: number; continueOnNextDay?: boolean } => {
            let durationInMinutes = secondsToMinutes(appointment.duration);
            // This should only be done when the start date is on the previous day.
            if (isContinuingFromPreviousDay) {
                // We need to substract the amount of time from the duration that is already used on the previous day.
                // E.g. when the appointment starts on the previous day at 23:15,
                // we need to substract 45 minutes from the appointment duration.
                // First we calculate the amount of hours that are used on the previous day.
                durationInMinutes = hoursToMinutes(getHours(appointment.end)) + getMinutes(appointment.end);
            }

            let continueOnNextDay = false;
            // First calculate how many blocks the appointment takes by first parsing the duration (which is in seconds) to minutes.
            // Then devide the duration in minuts it by the minutes per block.
            let height =
                (durationInMinutes / this.settings.slotSize) *
                // Then multiply the amount of blocks by the percentage to get the height for the appointment in percentage.
                percentagePerSlot;

            // We need to check if the height doesn't exceed the total height of the column.
            // If it does, we need to set the height to the maximum height.
            if (appointmentStartPosition + height > 100) {
                // We calculate the height by subtracting the start percentage from the total height of the column.
                height = 100 - appointmentStartPosition;
                // Mark the appointment as 'continuing on the next day'.
                continueOnNextDay = true;
            }

            return { height, continueOnNextDay };
        };

        let appointmentStartPosition: number;
        let appointmentHeight: number = 20;

        // When both of the appointment start and end dates are on the day of the column.
        if (
            format(appointment.start, 'yyyyMMdd') === format(this.columnDate, 'yyyyMMdd') && //
            format(appointment.end, 'yyyyMMdd') === format(this.columnDate, 'yyyyMMdd')
        ) {
            appointmentStartPosition = getStartingPosition(appointment.start);
            const { height } = getHeight(appointment);
            appointmentHeight = height;
        }
        // When only the start date is on the day of the column.
        else if (format(appointment.start, 'yyyyMMdd') === format(this.columnDate, 'yyyyMMdd')) {
            appointmentStartPosition = getStartingPosition(appointment.start);
            const { height, continueOnNextDay } = getHeight(appointment);
            appointmentHeight = height;
            this.appointment.attributes.continueOnNextDay = continueOnNextDay;
        }
        // When only the end date is on the day of the column.
        else if (format(appointment.end, 'yyyyMMdd') === format(this.columnDate, 'yyyyMMdd')) {
            appointmentStartPosition = 0;
            const { height } = getHeight(appointment, true);
            appointmentHeight = height;
            this.appointment.attributes.continueFromPreviousDay = true;
        }
        // When both the start and end date are not on the day of the column.
        // In this case the appointment is continuing from the previous day and continuing on the next day.
        // Which means that the appointment start is before the current date and the appointment end is after the
        // current date. This also means that this appointment is taking the full height of the column.
        else {
            appointmentStartPosition = 0;
            appointmentHeight = 100;
            this.appointment.attributes.continueOnNextDay = true;
            this.appointment.attributes.continueFromPreviousDay = true;
        }

        return { start: appointmentStartPosition, height: appointmentHeight };
    }

    private setRoundedCorners(): void {
        if (isNotDefined(this.appointment.attributes)) this.appointment.attributes = {};

        if (this.appointment.attributes.continueOnNextDay && this.appointment.attributes.continueFromPreviousDay) this.rounding = 'rounded-none';
        else if (this.appointment.attributes.continueOnNextDay) this.rounding = 'rounded-t-lg';
        else if (this.appointment.attributes.continueFromPreviousDay) this.rounding = 'rounded-b-lg';
        else this.rounding = 'rounded-lg';
    }
}
