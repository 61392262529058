import { BodySides, MedicalHistoryApiClient, MedicalHistoryItem, MedicalHistoryItemTypes } from '@wecore/sdk-healthcare';
import { guid, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';

@containerless
@inject(MedicalHistoryApiClient, ErrorHandler)
export class BxMedicalHistorySelector {
    @bindable() public registrations: MedicalHistoryItem[];
    @bindable() public validation: any[];
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public options: { [key: string]: string }[];
    public MedicalHistoryItemTypes: typeof MedicalHistoryItemTypes = MedicalHistoryItemTypes;
    public BodySides: typeof BodySides = BodySides;

    public constructor(
        private readonly historyApi: MedicalHistoryApiClient, //
        private readonly errorHandler: ErrorHandler
    ) {}

    public async attached(): Promise<void> {
        try {
            this.historyApi.search(this.workspace, undefined, 30, 0).then((response) => {
                this.options = response.data;
            });
        } catch (e) {
            this.errorHandler.handle('BxMedicalHistorySelector.attached', e);
        }
    }

    public async handleSelected(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        const option = e.detail.values as UxComboboxOption;
        this.registrations.push(
            new MedicalHistoryItem({
                id: guid(),
                name: option.data || {
                    [`${this.language}`]: option.value
                },
                type: MedicalHistoryItemTypes.Disorder
            })
        );

        this.refresh();
    }

    public getLength(items: MedicalHistoryItem[], type: MedicalHistoryItemTypes) {
        return items.filter((x: MedicalHistoryItem) => x.type === type).length;
    }

    public move(direction: 'up' | 'down', id: string, typeIndex: number): void {
        const types = Object.keys(MedicalHistoryItemTypes);

        const newIndex = direction === 'up' ? typeIndex - 1 : typeIndex + 1;

        const index = this.registrations.findIndex((x: MedicalHistoryItem) => x.id === id);
        if (index > -1) this.registrations[index].type = MedicalHistoryItemTypes[types[newIndex]];

        this.refresh();
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            const query = e.detail.values;

            const options = await this.historyApi.search(this.workspace, isNotEmpty(query) ? query : undefined, 30, 0);
            this.options = options.data;
        } catch (e) {
            this.errorHandler.handle('BxMedicalHistorySelector.handleSearch', e);
        }
    }

    public handleYearChanged(): void {
        this.refresh();
    }

    public handleMonthSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        const index = this.registrations.findIndex((x: MedicalHistoryItem) => x.id === e.detail.data);
        this.registrations[index].month = Number(e.detail.values.value);
        this.refresh();
    }

    public deleteItem(id: string): void {
        const index = this.registrations.findIndex((x: MedicalHistoryItem) => x.id === id);
        this.registrations.splice(index, 1);

        this.refresh();
    }

    private refresh(): void {
        this.registrations = [
            ...(this.registrations.length > 0 ? [this.registrations.shift()] : []), //
            ...this.registrations
        ];
    }
}
