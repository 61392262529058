import { bindable, containerless } from 'aurelia';
import { getSchedulerColumnHeight } from '../../infra/utilities';
import { SchedulerPeriod } from '../../models/scheduler-period';
import { SchedulerSettings } from '../../models/scheduler-settings';

@containerless
export class BxSchedulerTimeline {
    @bindable() public settings: SchedulerSettings;
    @bindable() public periods: SchedulerPeriod[];

    public column: HTMLDivElement;

    public bound(): void {
        this.column.style.height = `${getSchedulerColumnHeight(this.settings, this.periods, this.settings.periodHeight)}px`;
    }
}
