import { MedicalExaminationActionTypes } from '@wecore/sdk-healthcare';

export class ActionTypeToStringValueConverter {
    public toView(type: MedicalExaminationActionTypes): string {
        switch (type) {
            case MedicalExaminationActionTypes.Test:
                return 'global.labels.test';
            case MedicalExaminationActionTypes.Observation:
                return 'global.labels.observation';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
