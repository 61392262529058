<import from="converters/sort" />

<section class="flex flex-col">
    <div class="flex items-center justify-between" class="mb-1">
        <div class="flex items-center space-x-1">
            <ux-label>${ label }</ux-label>
            <span if.bind="required" class="flex items-center h-3 text-red-500">*</span>
        </div>
        <ux-button if.bind="choices.length > 0" click.trigger="add()" padding.bind="false" size="xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </ux-button>
    </div>
    <div if.bind="choices.length > 0" ref="container" class="flex flex-col">
        <div
            ref="elements[$index]"
            data-order.two-way="choice.displayOrder"
            class="group/list item draggable-list relative mt-2 flex flex-col items-start justify-between gap-y-2 before:absolute before:left-[-25px] before:h-full before:w-[25px] before:content-[''] sm:flex-row sm:space-x-2 sm:space-y-0"
            repeat.for="choice of choices | sort:'displayOrder'">
            <let choiceindex.bind="validation.findIndex((x) => x.id === choice.id)"></let>
            <button
                mousedown.trigger="handleMoveMouseDown($event, $index)"
                class="absolute left-[-30px] z-50 hidden h-[38px] w-12 items-center justify-center text-gray-500 group-hover/list:flex hover:cursor-move">
                <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5 6C12.5 6.27614 12.2761 6.5 12 6.5C11.7239 6.5 11.5 6.27614 11.5 6C11.5 5.72386 11.7239 5.5 12 5.5C12.2761 5.5 12.5 5.72386 12.5 6Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M18.5 6C18.5 6.27614 18.2761 6.5 18 6.5C17.7239 6.5 17.5 6.27614 17.5 6C17.5 5.72386 17.7239 5.5 18 5.5C18.2761 5.5 18.5 5.72386 18.5 6Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M18.5 12C18.5 12.2761 18.2761 12.5 18 12.5C17.7239 12.5 17.5 12.2761 17.5 12C17.5 11.7239 17.7239 11.5 18 11.5C18.2761 11.5 18.5 11.7239 18.5 12Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M6.5 12C6.5 12.2761 6.27614 12.5 6 12.5C5.72386 12.5 5.5 12.2761 5.5 12C5.5 11.7239 5.72386 11.5 6 11.5C6.27614 11.5 6.5 11.7239 6.5 12Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M12.5 18C12.5 18.2761 12.2761 18.5 12 18.5C11.7239 18.5 11.5 18.2761 11.5 18C11.5 17.7239 11.7239 17.5 12 17.5C12.2761 17.5 12.5 17.7239 12.5 18Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M18.5 18C18.5 18.2761 18.2761 18.5 18 18.5C17.7239 18.5 17.5 18.2761 17.5 18C17.5 17.7239 17.7239 17.5 18 17.5C18.2761 17.5 18.5 17.7239 18.5 18Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    <path
                        d="M6.5 18C6.5 18.2761 6.27614 18.5 6 18.5C5.72386 18.5 5.5 18.2761 5.5 18C5.5 17.7239 5.72386 17.5 6 17.5C6.27614 17.5 6.5 17.7239 6.5 18Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
            </button>
            <div class="flex flex-1">
                <ux-textarea
                    rows="1"
                    valid.bind="validation[choiceindex].value && validation[choiceindex].numeric && validation[choiceindex].unique"
                    type.bind="choice.valueType === ResultTypes.Number ? 'number' : 'text'"
                    value.two-way="choice.value"
                    rounded.bind="showScores ? 'full' : 'left'"
                    oninput.trigger="handleChoiceChanged()"
                    t="[placeholder]translation:components.choices-selector.placeholders.enter-value">
                </ux-textarea>
                <button
                    if.bind="!showScores"
                    class.bind="validation[choiceindex].value && validation[choiceindex].numeric && validation[choiceindex].unique ? 'border-gray-300' : 'border-red-500'"
                    class="relative -left-[1px] inline-flex items-center rounded-r-lg border bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                    click.trigger="remove($index)">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button>
            </div>
            <div class="flex flex-1" if.bind="showScores">
                <ux-input
                    valid.bind="validation[choiceindex].score"
                    type="number"
                    value.two-way="choice.score"
                    rounded="left"
                    t="[placeholder]translation:components.choices-selector.placeholders.enter-score"></ux-input>
                <button
                    class.bind="validation[choiceindex].value && validation[choiceindex].numeric && validation[choiceindex].unique ? 'border-gray-300' : 'border-red-500'"
                    class="relative -left-[1px] inline-flex h-[38px] items-center rounded-r-lg border bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                    click.trigger="remove($index)">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <!-- <div else class="flex flex-col items-center justify-center py-5 gap-y-2">
            <p class="text-xs text-gray-500" t="translation:components.choices-selector.messages.no-options-selected"></p>
            <button click.trigger="add()" class="flex items-center px-4 py-2 text-gray-800 border border-gray-300 rounded-full ring-gray-400 ring-offset-2 focus:outline-none focus:ring-1 active:text-gray-500">
                <span class="text-xs" t="translation:components.choices-selector.buttons.add-an-option"></span>
            </button>
        </div> -->
</section>
