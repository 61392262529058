<import from="components/component-duplicator/component-duplicator" />

<div class="flex flex-col">
    <component-duplicator
        t="[label]translation:components.widgets.patient-phones.labels.enter-phones"
        type="phone"
        descriptions.bind="descriptions"
        items.two-way="registration.widget.result.value"
        mask="000000000"
        language.bind="language"
        validation.two-way="validation.values">
    </component-duplicator>
</div>
