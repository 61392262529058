import { PriorityTypes } from '@wecore/sdk-healthcare';

export class PriorityBackgroundColorValueConverter {
    public toView(type: PriorityTypes, label: boolean = false): string {
        switch (type) {
            case PriorityTypes.Low:
                return label ? 'bg-blue-100' : 'bg-blue-500';
            case PriorityTypes.Medium:
                return label ? 'bg-yellow-100' : 'bg-yellow-500';
            case PriorityTypes.High:
                return label ? 'bg-orange-100' : 'bg-orange-500';
            case PriorityTypes.Urgent:
                return label ? 'bg-red-100' : 'bg-red-500';
            default:
                return 'bg-gray-100';
        }
    }
}
