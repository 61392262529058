<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="./templates/template-action/template-action" />
<import from="./templates/template-question/template-question" />
<import from="./templates/template-questionnaire/template-questionnaire" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex" css.bind="styles">
        <ux-bar-loader if.bind="isLoading"></ux-bar-loader>
        <template if.bind="baseLoaded">
            <div ref="scrollContainer" class="flex flex-1 flex-col overflow-y-auto px-8">
                <div class="${ hasScrolled ? 'border-b' : ''} sticky top-0 z-10 flex flex-col border-gray-200 bg-white pb-1 pt-5">
                    <p class="font-medium text-gray-800" t="translation:partial-views.clinical-pathways.queue.title"></p>
                    <p class="mt-1 text-sm text-gray-600" t="translation:partial-views.clinical-pathways.messages.queue"></p>
                </div>
                <div class="flex-1 pb-5">
                    <template repeat.for="item of items">
                        <let queueitem.bind="queue.find((x) => x.entityId === item.diagnosis.id && x.side === item.side)"></let>
                        <div class="my-2 flex flex-col rounded-lg border border-gray-200 p-4">
                            <div class="flex flex-col border-gray-200 bg-white pb-2">
                                <div class="flex items-start justify-between">
                                    <div class="flex flex-1 flex-col">
                                        <span class="text-xs text-gray-500" t="translation:global.labels.differential-diagnosis"></span>
                                        <p class="font-medium text-gray-800">
                                            ${item.suggestion.name | translate:language}
                                            <span class="text-sm" if.bind="queueitem.side">(${queueitem.side | sideToString | t})</span>
                                        </p>
                                        <p if.bind="queueitem.action === MedicalRecordQueueItemActions.Add" class="text-xs font-medium text-green-500" t="translation:global.labels.add"></p>
                                        <p if.bind="queueitem.action === MedicalRecordQueueItemActions.Remove" class="text-xs font-medium text-red-500" t="translation:global.labels.delete"></p>
                                        <p if.bind="queueitem.error" class="mt-1 text-xs text-red-500" t="translation:partial-views.clinical-pathways.messages.queue-error"></p>
                                        <!-- <p if.bind="queueitem.error" class="mt-1 text-xs text-red-500">${queueitem.error}</p> -->
                                    </div>
                                    <div class="flex flex-col items-end gap-1">
                                        <ux-button mode="outline" color="dark" padding.bind="false" click.trigger="toggleSteps($index)">
                                            <div -rotate-180.class="!item.expanded" class="flex items-center justify-center p-1 transition-all duration-150">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                </svg>
                                            </div>
                                        </ux-button>
                                    </div>
                                </div>
                            </div>
                            <div if.bind="item.expanded" class="mt-2 flex flex-col">
                                <template if.bind="item.steps.length > 0">
                                    <div class="grid w-full grid-cols-12 gap-1">
                                        <template repeat.for="step of item.steps">
                                            <template-action if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Action" step.bind="step" language.bind="language"> </template-action>
                                            <template-question if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Question" step.bind="step" language.bind="language"> </template-question>
                                            <template-questionnaire if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire" step.bind="step" language.bind="language">
                                            </template-questionnaire>
                                        </template>
                                    </div>
                                </template>
                                <template else>
                                    <div>
                                        <div class="rounded-lg bg-red-100 p-3">
                                            <p class="text-xs text-red-800" t="translation:partial-views.clinical-pathways.messages.no-steps-to-add"></p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div else class="mt-2 flex items-center gap-1 rounded-lg bg-gray-100 px-3 py-1.5 text-sm">
                                <div class="flex flex-1 items-center gap-1">
                                    <p class="font-medium text-gray-800">${item.steps.length}</p>
                                    <p if.bind="item.steps.length === 1" class="font-medium lowercase text-gray-800" t="translation:global.labels.step"></p>
                                    <p else class="font-medium lowercase text-gray-800" t="translation:global.labels.steps"></p>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="sticky bottom-0 z-50 flex justify-between gap-x-2 border-t border-gray-200 bg-white py-4">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button size="sm" color="primary" disabled.bind="isLoading || steps.length === 0" loading.bind="isLoading" click.trigger="processQueue()">
                            <span class="pointer-events-none" t="translation:partial-views.clinical-pathways.buttons.process-queue"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </template>
        <template else>
            <div class="flex w-full p-10">
                <ux-skeleton show-background.bind="false" type="container">
                    <div class="flex w-full flex-col space-y-5 py-1">
                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                    </div>
                </ux-skeleton>
            </div>
        </template>
    </section>
</template>
