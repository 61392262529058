import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { BlobStorageAttachment } from '@wecore/sdk-crm';
import { InformationSheet } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import Quill from 'quill';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { languages } from '../../infra/languages';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cleanTranslatables, refreshAttachmentsUrls, setTranslation } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ModalService, MediaLibraryApiClient)
export class PartialInformationSheet extends BasePartialView {
    public sheet: InformationSheet;
    public contentLanguage: string;
    public languages: string[];
    public view: 'editor' | 'preview' = 'editor';
    public container: HTMLDivElement;
    public hasScrolled: boolean = false;
    public mode: 'edit' | 'view';
    public noContent: boolean = false;
    public editor: HTMLDivElement;
    public toolbar: HTMLDivElement;

    private quill: Quill;
    private entityId: string;
    private entityType: AttachmentEntities;
    private config: {
        videos: boolean;
        images: boolean;
        mode: 'edit' | 'view';
        language: string;
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly modalService: ModalService,
        private readonly mediaLibrary: MediaLibraryApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.sheet = view.data.sheet || new InformationSheet();

        this.entityId = view.data.entityId;
        this.entityType = view.data.entityType;
        this.config = {
            videos: true,
            images: true,
            mode: 'view',
            language: 'en',
            ...(view.data.config || {})
        };

        this.mode = this.config.mode;
        this.contentLanguage = this.config.language;

        this.languages = languages().sort((a, _) => {
            if (a === this.contentLanguage) return -1;
            return 0;
        });

        // Check if we have information for çonfigured language.
        if (this.mode === 'view' && isNotDefined(this.sheet.value[this.contentLanguage])) {
            // If not, fall back on the first language that is available
            for (const key in this.sheet.value)
                if (isDefined(this.sheet.value[key])) {
                    this.contentLanguage = key;
                    break;
                }
            // If still no content is available, mark as no content.
            if (isNotDefined(this.sheet.value[this.contentLanguage])) this.noContent = true;
        }

        if (isNotDefined(this.sheet.value)) this.sheet.value = setTranslation({}, this.contentLanguage);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
                setTimeout(async () => {
                    this.container.addEventListener('scroll', (e) => this.handleScroll(e));
                    if (this.mode === 'edit') {
                        if (isDefined(this.editor)) await this.initEditor();
                        this.setContents();

                        if (isDefined(this.quill)) this.quill.focus();
                    }
                });
            })
            .catch((x) => this.errorHandler.handle('PartialInformationSheet.attached', x));
    }

    public detaching(): void {
        this.container.removeEventListener('scroll', (e) => this.handleScroll(e));

        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public changeLanguage(language: string): void {
        this.sheet.value[this.contentLanguage] = this.getContents();
        this.changeView('editor');
        this.contentLanguage = language;
        this.setContents();
    }

    public changeView(view: 'editor' | 'preview'): void {
        this.sheet.value[this.contentLanguage] = this.getContents();
        this.view = view;
    }

    public save(): void {
        try {
            this.sheet.value[this.contentLanguage] = this.getContents();
            cleanTranslatables(['value'], this.sheet, 1);
            this.remove({
                result: PartialViewResults.Ok,
                data: this.sheet
            });
        } catch (e) {
            if (isNotDefined(this.sheet.value)) this.sheet.value = setTranslation({}, this.contentLanguage);
            this.errorHandler.handle('[information-sheet]', e);
        }
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Canceled
        });
    }

    public async clearHtml(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.information-sheet.questions.delete.title'),
                message: this.t.tr('translation:partial-views.information-sheet.questions.delete.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        if (isDefined(this.quill)) this.quill.setText('');
                        this.sheet.value[this.contentLanguage] = null;
                    }
                }
            })
        );
    }

    private async setContents(): Promise<void> {
        let content = this.sheet.value[this.contentLanguage] || {};
        content = await refreshAttachmentsUrls(content, this.mediaLibrary, this.authenticated.workspace.id);
        this.quill.setContents(content);
    }

    private getContents(): any {
        return this.quill.getContents();
    }

    private initEditor(): Promise<void> {
        return new Promise((resolve) => {
            this.quill = new Quill(this.editor, {
                modules: {
                    // toolbar: [
                    //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    //     ['blockquote', 'code-block'],
                    //     [{ list: 'ordered' }, { list: 'bullet' }],
                    //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    //     [{ direction: 'rtl' }], // text direction
                    //     [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    //     // [{ font: [] }],
                    //     [{ align: [] }],
                    //     ['link', 'image'],
                    //     ['clean'] // remove formatting button
                    // ]
                    toolbar: {
                        container: '#toolbar',
                        handlers: {
                            image: this.handleImageSelected,
                            video: this.handleVideoSelected
                        }
                    }
                },
                theme: 'snow'
            });

            resolve();
        });
    }

    private handleVideoSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.entityId,
                entityType: this.entityType,
                language: this.contentLanguage,
                type: 'single',
                contentTypes: ['video/x-flv', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnails: boolean }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'videoAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0].attachment?.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px'
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private handleImageSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.entityId,
                entityType: this.entityType,
                language: this.contentLanguage,
                type: 'single',
                contentTypes: ['image/png', 'image/jpg', 'image/jpeg']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnail: boolean; language: string }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'imageAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0]?.attachment.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px',
                            thumbnail: response.thumbnail
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private handleScroll(event: Event): void {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;
    }
}

let BlockEmbed = Quill.import('blots/block/embed');
class ImageAttachmentBlot extends BlockEmbed {
    static create(data: any) {
        const node = super.create(data);
        node.setAttribute('alt', data.alt);
        node.setAttribute('src', data.src);
        node.setAttribute('width', data.width);
        node.setAttribute('height', data.height);
        node.setAttribute('data-attachment', data.attachment);
        node.setAttribute('data-thumbnail', data.thumbnail);
        return node;
    }

    static value(node: any) {
        return {
            alt: node.getAttribute('alt'),
            src: node.getAttribute('src'),
            width: node.getAttribute('width'),
            height: node.getAttribute('height'),
            attachment: node.getAttribute('data-attachment'),
            thumbnail: node.getAttribute('data-thumbnail') === 'true' ? true : false
        };
    }
}
ImageAttachmentBlot.blotName = 'imageAttachment';
ImageAttachmentBlot.tagName = 'img';

class VideoAttachmentBlot extends BlockEmbed {
    static create(data: any) {
        if (isNotDefined(data.attachment) || isNotDefined(data.src)) return null;

        const node = super.create(data);
        if (isDefined(data.attachment)) {
            const source = document.createElement('source');
            source.setAttribute('src', data.src);
            source.setAttribute('type', 'video/mp4');

            const video = document.createElement('video');
            video.setAttribute('data-attachment', data.attachment);
            video.setAttribute('width', data.width);
            video.setAttribute('height', data.height);
            video.setAttribute('alt', data.alt);

            // video.setAttribute('autoplay', '1');
            video.setAttribute('controls', '1');
            video.setAttribute('loop', '0');
            video.appendChild(source);

            node.appendChild(video);
        } else if (isDefined(data.src)) {
            var frame = document.createElement('iframe');

            const controls: number = 0,
                loop: number = 0,
                branding: number = 1,
                autoplay: number = 0,
                fullscreen: number = 0;

            frame.setAttribute('src', `${data.src}?controls=${controls}&loop=${loop}&modestbranding=${branding}&autoplay=${autoplay}&output=embed`);
            frame.setAttribute('width', data.width);
            frame.setAttribute('height', data.height);
            frame.setAttribute('frameborder', '0');
            frame.setAttribute('allowfullscreen', `${fullscreen}`);
            frame.setAttribute('data-allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');

            node.appendChild(frame);
        }
        return node;
    }

    static value(parent: HTMLDivElement) {
        let node: HTMLVideoElement | HTMLIFrameElement = parent.querySelector('video');
        if (isDefined(node)) {
            const source = node.querySelector('source');
            if (isNotDefined(source)) return {};
            return {
                alt: node.getAttribute('alt'),
                src: source.getAttribute('src'),
                width: node.getAttribute('width'),
                height: node.getAttribute('height'),
                attachment: node.getAttribute('data-attachment')
            };
        } else {
            node = parent.querySelector('iframe');
            if (isNotDefined(node)) return {};
            return {
                alt: node.getAttribute('alt'),
                src: node.getAttribute('src'),
                width: node.getAttribute('width'),
                height: node.getAttribute('height'),
                attachment: node.getAttribute('data-attachment')
            };
        }
    }
}
VideoAttachmentBlot.blotName = 'videoAttachment';
VideoAttachmentBlot.tagName = 'span';

Quill.register(ImageAttachmentBlot);
Quill.register(VideoAttachmentBlot);
