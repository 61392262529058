<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/action-type-to-string" />
<import from="converters/get-ids" />
<import from="converters/currency" />
<import from="converters/get-property" />
<import from="converters/operator-to-string" />
<import from="bx/bx-anatomical-region-selector/bx-anatomical-region-selector" />
<import from="bx/bx-keywords-selector/bx-keywords-selector" />
<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />
<import from="bx/bx-examination-phase-selector/bx-examination-phase-selector" />
<import from="bx/bx-action-category-multi-selector/bx-action-category-multi-selector" />

<import from="../templates/template-action-category/template-action-category" />
<import from="../templates/template-action-step/template-action-step" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 pr-8 overflow-hidden pl-7">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 pt-8 overflow-hidden gap-x-2">
                        <div ref="scrollContainer" class="flex w-[450px] flex-col overflow-y-auto pb-5 pl-1 pr-4">
                            <div class.bind="hasScrolled ? 'border-b' : ''" class="sticky top-0 z-40 flex justify-between pt-1 pb-3 bg-white border-gray-200">
                                <p class="font-medium text-gray-400" t="translation:partial-views.medical-examination-actions.edit.title"></p>
                                <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                    <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                            <path
                                                fill="currentColor"
                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option click.trigger="informationSheet()">
                                        <span class="text-sm" t="global.buttons.information-sheet"></span>
                                    </ux-dropdown-option>
                                    <ux-dropdown-option click.trigger="copy()">
                                        <span class="text-sm" t="global.buttons.copy"></span>
                                    </ux-dropdown-option>
                                    <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteMedicalExaminationActions)"></ux-dropdown-divider>
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteMedicalExaminationActions)" click.trigger="delete()">
                                        <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                            </div>
                            <div class="flex flex-col col-span-5 mb-3">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="global.labels.name"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('name', true)"
                                    rows="1"
                                    value.two-way="action.name[language]"
                                    valid.bind="validation.name"
                                    t="[placeholder]partial-views.medical-examination-actions.placeholders.name">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex flex-col col-span-5 mb-3">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-examination-actions.labels.select-examination-phase"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <bx-examination-phase-selector
                                    on-select.bind="handlePhaseSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]global.placeholders.select-examination-phase"
                                    valid.bind="validation.phase"
                                    value.two-way="action.phase.id">
                                </bx-examination-phase-selector>
                            </div>
                            <div class="flex flex-col col-span-4 mb-3">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-examination-actions.labels.select-action-type"></span>
                                </ux-label>
                                <ux-select t="[placeholder]partial-views.medical-examination-actions.placeholders.select-action-type" value.two-way="action.type">
                                    <ux-select-option repeat.for="type of MedicalExaminationActionTypes | keys" value.bind="type"> ${ type | actionTypeToString | t } </ux-select-option>
                                </ux-select>
                            </div>
                            <div class="flex flex-col col-span-8 mb-3">
                                <ux-label class="mb-1">
                                    <span t="global.labels.keywords"></span>
                                </ux-label>
                                <bx-keywords-selector workspace.bind="authenticated.workspace.id" values.two-way="action.keywords"></bx-keywords-selector>
                            </div>
                            <div class="flex flex-col col-span-12 mb-3">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-examination-actions.labels.enter-synonyms"></span>
                                </ux-label>
                                <ux-tags tags.two-way="action.synonyms" t="[placeholder]global.placeholders.enter-synonyms-with-enter"></ux-tags>
                            </div>
                            <div class="flex flex-col mb-3">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-examination-actions.labels.select-region"></span>
                                </ux-label>
                                <bx-anatomical-region-selector
                                    on-select.bind="handleRegionSelected"
                                    language.bind="language"
                                    allow-clear="true"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]partial-views.medical-examination-actions.placeholders.select-region"
                                    value.two-way="action.anatomicalRegion.id">
                                </bx-anatomical-region-selector>
                            </div>
                            <div class="flex flex-col col-span-12 mb-3">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-examination-actions.labels.select-differential-diagnoses"></span>
                                </ux-label>
                                <bx-diagnosis-multi-selector
                                    workspace.bind="authenticated.workspace.id"
                                    on-change.bind="handleDiagnosesChanged"
                                    language.bind="language"
                                    t="[placeholder]partial-views.medical-examination-actions.placeholders.select-differential-diagnoses"
                                    values.bind="action.differentialDiagnoses | getIds">
                                </bx-diagnosis-multi-selector>
                            </div>
                            <div class="flex flex-col col-span-12 mb-3">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-examination-actions.labels.select-categories"></span>
                                </ux-label>
                                <bx-action-category-multi-selector
                                    workspace.bind="authenticated.workspace.id"
                                    on-change.bind="handleCategoriesChanged"
                                    language.bind="language"
                                    t="[placeholder]partial-views.medical-examination-actions.placeholders.select-categories"
                                    values.bind="action.categories | getIds">
                                </bx-action-category-multi-selector>
                            </div>
                            <div class="flex flex-col mb-3">
                                <ux-label class="mb-1">
                                    <span t="global.labels.healthcare-sector"></span>
                                </ux-label>
                                <ux-multi-selector values.two-way="action.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                            </div>
                            <div class="flex flex-col col-span-12 mb-3">
                                <ux-label class="mb-1">
                                    <span t="global.labels.description"></span>
                                </ux-label>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('description')"
                                    value.two-way="action.description[language]"
                                    rows="8"
                                    t="[placeholder]partial-views.medical-examination-actions.placeholders.description">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex flex-col mb-3">
                                <div mb-2.class="action.prices.length > 0" mb-1.class="action.prices.length === 0" class="flex items-center justify-between">
                                    <ux-label>
                                        <span t="translation:partial-views.medical-examination-actions.labels.prices"></span>
                                    </ux-label>
                                    <ux-button if.bind="action.prices.length > 0" click.trigger="createOrEditPrice()" padding.bind="false" size="xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </ux-button>
                                </div>
                                <div class="flex" if.bind="action.prices.length === 0">
                                    <button
                                        click.trigger="createOrEditPrice()"
                                        type="button"
                                        class="relative flex items-center justify-center w-full gap-1 p-3 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none active:border-gray-500">
                                        <svg class="w-5 h-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                        <span class="block text-sm font-semibold text-gray-900" t="translation:partial-views.medical-examination-actions.buttons.add-price"></span>
                                    </button>
                                </div>
                                <ul else class="flex flex-col gap-1">
                                    <li class="flex" repeat.for="price of action.prices">
                                        <ux-card>
                                            <div class="relative flex items-center border-t border-l border-gray-200 rounded-lg">
                                                <button
                                                    click.trigger="createOrEditPrice($index)"
                                                    class="flex items-center flex-1 h-full gap-6 px-4 py-1 rounded-l-lg hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                    <div class="flex items-center text-sm">
                                                        <p class="mr-3 text-gray-800">${price.netValue | currency}</p>
                                                        <p class="text-xs text-gray-500">${formatDescription($index)}</p>
                                                    </div>
                                                </button>
                                                <button
                                                    click.trigger="removePrice($index)"
                                                    class="flex items-center justify-center w-10 p-2 text-red-500 bg-white border-l border-gray-200 rounded-r-lg ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100"
                                                    click.trigger="delete($index)">
                                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </ux-card>
                                    </li>
                                </ul>
                            </div>
                            <div class="flex flex-col">
                                <div mb-2.class="action.codes.length > 0" mb-1.class="action.codes.length === 0" class="flex items-center justify-between">
                                    <ux-label>
                                        <span t="translation:global.labels.healthcare-codes"></span>
                                    </ux-label>
                                    <ux-button if.bind="action.codes.length > 0" click.trigger="addCode()" padding.bind="false" size="xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </ux-button>
                                </div>
                                <ul if.bind="action.codes.length > 0" class="flex flex-col space-y-1">
                                    <li repeat.for="code of action.codes" class="flex w-full">
                                        <div class="relative flex items-center flex-1 rounded-lg bg-gray-50">
                                            <button
                                                click.trigger="editCode($index)"
                                                class="flex items-center flex-1 h-full gap-6 px-4 py-1 border-l border-gray-200 rounded-l-lg border-y hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                <div class="flex items-center text-sm">${code.system}/${code.value}</div>
                                            </button>
                                            <button
                                                click.trigger="removeCode($index)"
                                                class="flex items-center justify-center w-10 p-2 text-red-500 border border-gray-200 rounded-r-lg bg-gray-50 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                                <button
                                    else
                                    click.trigger="addCode($index)"
                                    type="button"
                                    class="text-gray-800.class relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                                    </svg>

                                    <span class="block text-sm font-semibold" t="translation:partial-views.medical-step-settings.buttons.add-code"></span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col flex-1 pb-5 overflow-hidden">
                            <div class="flex flex-col flex-1 px-10 pb-10 overflow-y-auto bg-gray-100 rounded-lg">
                                <div class="sticky top-0 z-[999] flex w-full items-center justify-end border-b border-gray-300 bg-gray-100/50 pb-2 pt-5 backdrop-blur-xl">
                                    <ux-dropdown border.bind="false" transparent.bind="true" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                        <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                            </svg>
                                        </div>
                                        <ux-dropdown-option click.trigger="collapseOrExpandAll('expand')">
                                            <span class="text-sm" t="global.buttons.expand-all"></span>
                                        </ux-dropdown-option>
                                        <ux-dropdown-option click.trigger="collapseOrExpandAll('collapse')">
                                            <span class="text-sm" t="global.buttons.collapse-all"></span>
                                        </ux-dropdown-option>
                                    </ux-dropdown>
                                </div>
                                <div class="flex flex-col flex-1 px-1">
                                    <div class="flex flex-col items-center pt-5">
                                        <ux-card>
                                            <div class.bind="action.stepsToTake.length > 0 ? 'space-y-10' : ''" class="flex flex-col p-10">
                                                <div class="grid grid-cols-12 gap-1">
                                                    <template repeat.for="item of action.stepsToTake">
                                                        <template if.bind="item.type">
                                                            <template if.bind="item.type === MedicalExaminationActionItemTypes.Category">
                                                                <template-action-category
                                                                    action.bind="action"
                                                                    array.bind="action.stepsToTake"
                                                                    action-item.bind="item"
                                                                    toplevel="true"
                                                                    manage-translations.bind="manageTranslationsFor"
                                                                    remove.bind="removeActionItem"
                                                                    open-settings.bind="openSettings"
                                                                    index.bind="$index"
                                                                    language.bind="language"
                                                                    validation.two-way="validation.steps"
                                                                    edit.bind="editAction">
                                                                </template-action-category>
                                                            </template>
                                                            <template else>
                                                                <template-action-step
                                                                    action.bind="action"
                                                                    array.bind="action.stepsToTake"
                                                                    action-item.bind="item"
                                                                    manage-translations.bind="manageTranslationsFor"
                                                                    remove.bind="removeActionItem"
                                                                    open-settings.bind="openSettings"
                                                                    index.bind="$index"
                                                                    toplevel="true"
                                                                    language.bind="language"
                                                                    validation.two-way="validation.steps"
                                                                    edit.bind="editAction">
                                                                </template-action-step>
                                                            </template>
                                                        </template>
                                                        <template else>
                                                            <div class="flex flex-col items-start col-span-12 p-5 bg-gray-100 rounded-lg">
                                                                <ux-label class="mb-1">
                                                                    <span t="translation:partial-views.medical-examination-actions.labels.select-linked-question"></span>
                                                                </ux-label>
                                                                <div class="flex w-full">
                                                                    <ux-combobox
                                                                        data.bind="$index"
                                                                        rounded="left"
                                                                        used.bind="questionnaire.questions | getIds:'question'"
                                                                        valid.bind="validation.questions[$index].question"
                                                                        onselect.trigger="handleQuestionSelected($event)"
                                                                        t="[placeholder]translation:partial-views.medical-examination-actions.placeholders.select-linked-question"
                                                                        value.two-way="questionnaire.questions[$index].question.id">
                                                                        <ux-combobox-option repeat.for="question of questions" value.bind="question.id">
                                                                            ${ question.name | translate:language }
                                                                        </ux-combobox-option>
                                                                    </ux-combobox>
                                                                    <button
                                                                        class.bind="validation.questions[$index].question ? 'border-gray-300' : 'border-red-500'"
                                                                        class="inline-flex h-[38px] items-center rounded-r-lg border-y border-r bg-white text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1 sm:px-2"
                                                                        click.trigger="removeItem($index)">
                                                                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                stroke-width="2"
                                                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </div>
                                                <div class="flex items-center justify-center flex-1 gap-x-2">
                                                    <ux-button if.bind="state.clipboard.actionItem" click.trigger="pasteItem(-1)" color="dark" mode="outline">
                                                        <span class="text-xs" t="global.buttons.paste"></span>
                                                    </ux-button>
                                                    <ux-button click.trigger="addCategory()" color="dark" mode="outline">
                                                        <span class="text-xs" t="translation:partial-views.medical-examination-actions.buttons.add-category"></span>
                                                    </ux-button>
                                                    <ux-button click.trigger="addStep()" color="dark" mode="outline">
                                                        <span class="text-xs" t="translation:partial-views.medical-examination-actions.buttons.add-step"></span>
                                                    </ux-button>
                                                </div>
                                                <div if.bind="!validation.any" class="flex justify-center">
                                                    <p class="mt-4 text-sm text-red-500" t="translation:partial-views.medical-examination-actions.messages.steps-amount"></p>
                                                </div>
                                            </div>
                                        </ux-card>
                                        <ux-card class="mt-5">
                                            <div class="p-5">
                                                <div class="flex flex-col col-item-12">
                                                    <div class="flex items-start justify-between">
                                                        <div class="flex flex-col pr-20">
                                                            <ux-label class="leading-tight" t="translation:partial-views.medical-examination-actions.labels.select-result-checks"></ux-label>
                                                            <p class="text-xs leading-tight text-gray-500" t="translation:partial-views.medical-examination-actions.messages.positive-steps"></p>
                                                        </div>
                                                        <ux-button click.trigger="addResultCheckItem()" padding.bind="false" size="xs">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </ux-button>
                                                    </div>
                                                    <ul class.bind="action.resultCheck.items.length > 0 ? 'p-5' : 'py-3 px-5'" class="flex flex-col mt-2 space-y-1 bg-gray-100 rounded-lg">
                                                        <li if.bind="action.resultCheck.items.length === 0">
                                                            <p class="text-sm" t="translation:partial-views.medical-examination-actions.labels.no-result-checks-selected"></p>
                                                        </li>
                                                        <li repeat.for="item of action.resultCheck.items" class="flex space-x-1">
                                                            <template if.bind="$index > 0">
                                                                <div class="flex w-[100px]">
                                                                    <ux-select value.two-way="item.operator">
                                                                        <ux-select-option value.bind="Operators.And">${ Operators.And | operatorToString | t }</ux-select-option>
                                                                        <ux-select-option value.bind="Operators.Or">${ Operators.Or | operatorToString | t }</ux-select-option>
                                                                    </ux-select>
                                                                </div>
                                                            </template>
                                                            <div class="flex flex-1">
                                                                <ux-combobox
                                                                    data.bind="$index"
                                                                    rounded="left"
                                                                    valid.bind="validation.items[$index].step"
                                                                    onselect.trigger="handleStepSelected($event)"
                                                                    used.bind="action.resultCheck.items | getProperty:'stepId'"
                                                                    t="[placeholder]translation:partial-views.medical-examination-actions.notifications.select-step-for-check"
                                                                    value.bind="item.stepId">
                                                                    <ux-combobox-option repeat.for="itemToCompare of itemsToCompare" value.bind="itemToCompare.value">
                                                                        ${ itemToCompare.text }
                                                                    </ux-combobox-option>
                                                                </ux-combobox>
                                                                <button
                                                                    class="inline-flex items-center rounded-r-lg border-y border-r border-gray-300 bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                                                    click.trigger="removeResultCheckItem($index)">
                                                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ux-card>
                                    </div>
                                </div>
                                <div class="flex flex-col items-center mt-5 gap-y-1">
                                    <p class="text-xs text-gray-400" t="translation:global.labels.possible-step-settings"></p>
                                    <div class="flex flex-wrap items-center justify-center lowercase gap-x-2 gap-y-1">
                                        <div class="flex items-center gap-1">
                                            <div class="h-2 w-2 rounded-sm bg-[#F4C237]"></div>
                                            <span class="text-xs lower" t="translation:global.labels.has-connected-categories-setting"></span>
                                        </div>
                                        <div class="flex items-center gap-1">
                                            <div class="w-2 h-2 bg-pink-400 rounded-sm"></div>
                                            <span class="text-xs lower" t="translation:global.labels.has-connected-steps-setting"></span>
                                        </div>
                                        <div class="flex items-center gap-1">
                                            <div class="h-2 w-2 rounded-sm bg-[#F25366]"></div>
                                            <span class="text-xs" t="translation:global.labels.has-required-setting"></span>
                                        </div>
                                        <div class="flex items-center gap-1">
                                            <div class="w-2 h-2 rounded-sm bg-primary"></div>
                                            <span class="text-xs" t="translation:global.labels.has-display-requirements-setting"></span>
                                        </div>
                                        <div class="flex items-center gap-1">
                                            <div class="h-2 w-2 rounded-sm bg-[#46ADF9]"></div>
                                            <span class="text-xs" t="translation:global.labels.has-connected-dd-setting"></span>
                                        </div>
                                        <div class="flex items-center gap-1">
                                            <div class="w-2 h-2 bg-black rounded-sm"></div>
                                            <span class="text-xs" t="translation:global.labels.has-connected-action-setting"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 pt-8 pb-5 space-x-6 overflow-hidden">
                        <div class="flex w-[400px]">
                            <div class="flex flex-col flex-1">
                                <div class="grid grid-cols-2 gap-5">
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-center justify-center flex-1 bg-gray-100 rounded-lg">
                            <div class="w-[600px] rounded-lg bg-white p-5 shadow-sm">
                                <div class="grid grid-cols-2 gap-5">
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                </div>
                            </div>
                            <div class="flex items-center justify-center py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                                </svg>
                            </div>
                            <div class="w-[600px] rounded-lg bg-white p-5 shadow-sm">
                                <div class="grid grid-cols-2 gap-5">
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex items-center justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex flex-col items-center">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                        <p if.bind="!validation.validSteps" class="text-sm text-red-500" t="partial-views.medical-examination-actions.messages.invalid-steps"></p>
                    </div>
                    <div class="flex items-center gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button-options placement="top-right" size="sm" color="success" disabled.bind="isLoading">
                            <ux-button size="sm" color="success" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save(true)">
                                <span class="pointer-events-none" t="global.buttons.save"></span>
                            </ux-button>
                            <ux-dropdown-option au-slot="options" click.trigger="save(false)">
                                <span t="translation:global.buttons.save-and-continue-edit"></span>
                            </ux-dropdown-option>
                        </ux-button-options>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
