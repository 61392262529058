import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { BlobStorageAttachment, EmailEntityTypes, EmailTemplatesApiClient, GetEmailTemplateTokenResponse, UpdateEmailTemplateRequest } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import Quill from 'quill';
import { BxEmailTemplateTokenSelector } from '../../../bx/bx-email-template-token-selector/bx-email-template-token-selector';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { languages } from '../../../infra/languages';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';
import { UxTextarea } from '../../../ux/ux-textarea/ux-textarea';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, EmailTemplatesApiClient, MediaLibraryApiClient, ModalService)
export class PartialManageEmailTemplatesEdit extends BasePartialView {
    public templateId: string;
    public template: UpdateEmailTemplateRequest;
    public validation = {
        name: true,
        subject: true,
        content: true
    };
    public contentLanguage: string;
    public languages: string[];
    public editor: HTMLDivElement;
    public subject: UxTextarea;
    public EmailEntityTypes: typeof EmailEntityTypes = EmailEntityTypes;
    public tokenSelector: BxEmailTemplateTokenSelector;

    private quill: Quill;
    private entityId: string;
    private entityType: AttachmentEntities;
    private addTokenTo: 'subject' | 'editor';
    private subjectCursorStart: number;
    private subjectCursorEnd: number;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly templatesApi: EmailTemplatesApiClient,
        private readonly mediaLibrary: MediaLibraryApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.templateId = view.data.id;

        this.contentLanguage = this.language;
        this.languages = languages().sort((a, _) => {
            if (a === this.contentLanguage) return -1;
            return 0;
        });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [template] = await Promise.all([
                    this.templatesApi.getById(this.templateId, this.authenticated.workspace.id) //
                ]);

                this.template = template;
                if (isNotDefined(this.template.description)) this.template.description = setTranslation({}, this.language);

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();

                    setTimeout(async () => {
                        if (isDefined(this.editor)) await this.initEditor();
                        this.setContents();

                        if (isDefined(this.quill)) this.quill.focus();
                    });
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManageEmailTemplatesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        // Make sure the content is updated before validating.
        this.template.content[this.contentLanguage] = this.getContents();

        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description', 'content', 'subject'], this.template, 1);
                await this.templatesApi.update(this.templateId, this.authenticated.workspace.id, this.template);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-email-templates.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-email-templates.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.template.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.template.description)) this.template.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[edit-email-templates]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-email-templates.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-email-templates.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.template.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.templatesApi.delete(this.templateId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-email-templates.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-email-templates.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.template.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-differential-diagnosis-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public changeLanguage(language: string): void {
        this.template.content[this.contentLanguage] = this.getContents();
        this.contentLanguage = language;
        this.setContents();
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.template[property],
            callback: (updatedTranslations: any) => {
                this.template[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleSubjectBlur(e: CustomEvent<EventDetails<UxTextarea, any>>): void {
        this.subjectCursorStart = (e.detail.element as any).textarea.selectionStart || 0;
        this.subjectCursorEnd = (e.detail.element as any).textarea.selectionEnd || 0;
        this.addTokenTo = 'subject';
    }

    public handleTokenSelected = (token: GetEmailTemplateTokenResponse): void => {
        if (isNotDefined(token)) return;

        if (this.addTokenTo === 'subject') {
            const value = this.template.subject || '';
            this.template.subject =
                value.substring(0, this.subjectCursorStart) + //
                token.value +
                value.substring(this.subjectCursorEnd, value.length);
        } else {
            const selection = this.quill.getSelection(true);
            this.quill.insertText(selection.index, token.value);
        }
    };

    public handleTypeSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        const type = e.detail.values.value as EmailEntityTypes;
        if (isDefined(this.tokenSelector)) this.tokenSelector.refresh([EmailEntityTypes.Other, EmailEntityTypes.Patient, type]);
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-email-templates.questions.copy.title'),
                message: this.t.tr('partial-views.manage-email-templates.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.templatesApi.copy(this.templateId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-email-templates.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.manage-email-templates.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-email-template]', e);
                        }
                    }
                }
            })
        );
    }

    public async clearHtml(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.information-sheet.questions.delete.title'),
                message: this.t.tr('translation:partial-views.information-sheet.questions.delete.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        if (isDefined(this.quill)) this.quill.setText('');
                        this.template.content[this.contentLanguage] = null;
                    }
                }
            })
        );
    }

    private getContents(): any {
        return this.quill.getContents();
    }

    private async setContents(): Promise<void> {
        let content = this.template.content[this.contentLanguage] || {};
        content = await this.refreshAttachmentsUrls(content);
        this.quill.setContents(content);
    }

    private async refreshAttachmentsUrls(content: any): Promise<any> {
        if (isNotDefined(content) || isNotDefined(content.ops)) return content;
        const checks = content.ops
            .filter((item: any) => isDefined(item.insert.imageAttachment) || (isDefined(item.insert.videoAttachment) && isDefined(item.insert.videoAttachment.attachment)))
            .map((item: any) => {
                return new Promise<void>(async (resolve) => {
                    const container = item.insert.imageAttachment || item.insert.videoAttachment;
                    // Check if URL still has correct authorization.
                    var request = new XMLHttpRequest();
                    request.open('GET', container.src, true);
                    request.send();
                    request.onload = async () => {
                        if (request.status !== 200) {
                            const url = await this.mediaLibrary.getUrl(container.attachment, this.authenticated.workspace.id, container.thumbnail || true);
                            container.src = url;
                        }
                        resolve();
                    };
                });
            });

        await Promise.all(checks);
        return content;
    }

    private initEditor(): Promise<void> {
        return new Promise((resolve) => {
            this.quill = new Quill(this.editor, {
                modules: {
                    // toolbar: [
                    //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    //     ['blockquote', 'code-block'],
                    //     [{ list: 'ordered' }, { list: 'bullet' }],
                    //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    //     [{ direction: 'rtl' }], // text direction
                    //     [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    //     // [{ font: [] }],
                    //     [{ align: [] }],
                    //     ['link', 'image'],
                    //     ['clean'] // remove formatting button
                    // ]
                    toolbar: {
                        container: '#toolbar',
                        handlers: {
                            image: this.handleImageSelected,
                            video: this.handleVideoSelected
                        }
                    }
                },
                theme: 'snow'
            });

            this.quill.on('selection-change', (range) => {
                if (isDefined(range)) this.addTokenTo = 'editor';
            });

            resolve();
        });
    }

    private handleVideoSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.entityId,
                entityType: this.entityType,
                language: this.contentLanguage,
                type: 'single',
                contentTypes: ['video/x-flv', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnails: boolean }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'videoAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0].attachment?.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px'
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private handleImageSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.entityId,
                entityType: this.entityType,
                language: this.contentLanguage,
                type: 'single',
                contentTypes: ['image/png', 'image/jpg', 'image/jpeg']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnail: boolean; language: string }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'imageAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0]?.attachment.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px',
                            thumbnail: response.thumbnail
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private validate(): boolean {
        this.validation.name = validateTranslation(this.template.name, this.language);
        this.validation.subject = isDefined(this.template.subject) && isNotEmpty(this.template.subject);
        return validateState(this.validation);
    }
}
