<ux-combobox
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    show-footer.bind="true"
    set-value-on-select.bind="setValueOnSelect"
    onselect.trigger="handleSelect($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="contact of contacts" value.bind="contact.id">${ contact.displayName }</ux-combobox-option>
    <div au-slot="footer" class="flex w-full items-center gap-x-1 px-3 py-2">
        <svg class="h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
        <a click.trigger="addNew()" class="text-xs text-gray-500 underline hover:text-blue-500" href="javascript:" t="translation:components.contact-selector.messages"></a>
    </div>
</ux-combobox>
