import { I18N } from '@aurelia/i18n';
import { AppointmentTypesApiClient, GetAppointmentTypeResponse } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(AppointmentTypesApiClient, I18N, ErrorHandler)
export class BxAppointmentTypeSelector {
    @bindable() public value: string;
    @bindable() public selected: GetAppointmentTypeResponse;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public setValueOnSelect: boolean = true;
    @bindable() public onSelect: (type: GetAppointmentTypeResponse, data: any) => void;
    @bindable() public open: () => void;

    public combobox: UxCombobox;
    public types: GetAppointmentTypeResponse[];

    public constructor(
        private readonly typesApi: AppointmentTypesApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = this.t.tr('global.placeholders.search');
    }

    public async attached(): Promise<void> {
        try {
            let type: GetAppointmentTypeResponse;
            if (isNotEmpty(this.value)) {
                type = await this.typesApi.getById(this.value, this.workspace);
                // If the selected item is not found, use the selected object which can
                // be provided by the user and used to display the object name in the combobox.
                // This can be handy in case the item has been deleted.
                if (isNotDefined(type)) type = this.selected;
            }

            const response = await this.typesApi.search(this.workspace, '', 100, 0);
            const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
            this.types = isDefined(type) ? [type].concat(data.filter((x) => x.id !== this.value)) : data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxAppointmentTypeSelector.attached', e);
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.typesApi.search(this.workspace, isNotEmpty(query) ? query : '', 100, 0);
            this.types = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxAppointmentTypeSelector.handleSearch', e);
        }
    }

    public handleSelect(e: CustomEvent<EventDetails<UxCombobox, UxComboboxOption>>): void {
        const option = e.detail.values;
        const type = this.types.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(type, this.data);
    }

    public addNew(): void {
        if (isFunction(this.open)) this.open();
    }
}
