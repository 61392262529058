import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse } from '@wecore/sdk-core';
import { DayOfWeek, PracticeLocationsApiClient, ScheduleTimeSlot, ScheduleTimeSlotParticulars, UserEntityReference } from '@wecore/sdk-healthcare';
import { guid, isDefined, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { addMinutes, areIntervalsOverlapping, format, intervalToDuration, isAfter, isBefore, isEqual, setHours, setMinutes, startOfDay } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { State } from '../../../infra/store/state';
import { dayAndTimeToDate, validateTime } from '../../../infra/utilities';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { SchedulerSettings } from '../../../models/scheduler-settings';
import { ModalService } from '../../../services/service.modals';
import { UxDatepicker } from '../../../ux/ux-datepicker/ux-datepicker';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PracticeLocationsApiClient, ModalService)
export class PartialManageSchedulesTimeslot extends BasePartialView {
    public slot: ScheduleTimeSlot;
    public parent: number;
    public ScheduleTimeSlotParticulars: typeof ScheduleTimeSlotParticulars = ScheduleTimeSlotParticulars;
    public index: number;
    public settings: SchedulerSettings;
    public room: string;
    public validation = {
        start: true,
        end: true,
        range: true,
        overlap: true,
        practitioner: true,
        valid: true
    };

    private day: DayOfWeek;
    private otherSlots: ScheduleTimeSlot[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.parent = view.data.parent;
        this.index = view.data.index;
        this.room = view.data.room;
        this.otherSlots = view.data.otherSlots;
        this.day = view.data.day;
        this.slot =
            view.data.slot ??
            new ScheduleTimeSlot({
                id: guid(),
                start: '08:00',
                end: '17:00'
            });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.settings = this.state.schedulerSettings;
                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManageSchedulesTimeslot.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        this.events.publish(CustomEvents.ScheduleOverlappingTimeslot, { mode: 'reset' });
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            if (isDefined(this.slot.validFrom)) this.slot.validFrom = startOfDay(this.slot.validFrom);
            if (isDefined(this.slot.validUntil)) this.slot.validUntil = startOfDay(this.slot.validUntil);

            // Set duration between start and end time.
            const start = dayAndTimeToDate(this.day, this.slot.start);
            const end = dayAndTimeToDate(this.day, this.slot.end);
            const duration = intervalToDuration({ start, end });

            // In seconds.
            this.slot.duration = duration.hours * 60 * 60 + duration.minutes * 60 + duration.seconds;

            this.isLoading = true;
            this.events.publish(CustomEvents.ScheduleOverlappingTimeslot, { mode: 'reset' });
            this.remove({ result: PartialViewResults.Ok, data: { slot: this.slot, parent: this.parent, index: this.index } });
        }
    }

    public handleUserSelected = async (user: GetUserResponse): Promise<void> => {
        this.slot.practitioner = new UserEntityReference({
            id: user.id,
            name: user.displayName
        });
    };

    public handleTimeInput(e: CustomEvent<EventDetails<UxInput, any>>): void {
        const value = e.detail.values.value as string;
        const type = e.detail.data as 'start' | 'end';

        let [hour, minutes] = value.split(':');

        // Make sure the hour is valid e.g checking the following:
        // 1: The hour is fully filled.
        // 2: The hour is not less than the start hour of the scheduler settings.
        // 3: The hour is not greater than the end hour of the scheduler settings.
        let startHourCorrected = false;
        let endHourCorrected = false;
        if (!hour.includes('_')) {
            if (type === 'start') {
                // The entered hour can never be before the start hour.
                if (Number(hour) < Number(this.settings.startHour)) {
                    hour = this.settings.startHour.toString();
                    startHourCorrected = true;
                }
                if (Number(hour) >= Number(this.settings.endHour)) {
                    // The entered start time can never begin at the end hour.
                    // For example if 20:00 is entered end hour is 20 and slot size is 5, we change the start time to 19:55.
                    // So we subtract one hour from the end hour and set the minutes to 60 - slotsize later on.
                    hour = (this.settings.endHour - 1).toString();
                    endHourCorrected = true;
                }
            } else if (type === 'end') {
                // The entered hour can never be before the start hour + slotsize.
                // For example if 08:00 between 08:{slotsize - 1} is entered and the start hour is 8 and slot size is 5, we change the end time to 8:05.
                if (Number(hour) === Number(this.settings.startHour) && Number(minutes) < Number(this.settings.slotSize)) {
                    hour = this.settings.startHour.toString();
                    startHourCorrected = true;
                }
                // The entered hour can never be before the start hour.
                // For example if 07:45 is entered and the start hour is 8 , we change the end time to 8:00.
                if (Number(hour) < Number(this.settings.startHour)) {
                    hour = this.settings.startHour.toString();
                    startHourCorrected = true;
                }
                if (Number(hour) >= Number(this.settings.endHour)) {
                    // The entered time can never be after the end hour.
                    //  For example if 21:00 is entered end hour is 20, we change the start time to 20:00.
                    hour = this.settings.endHour.toString();
                    endHourCorrected = true;
                }
            }
        }

        // When start or end hour is corrected.
        if (startHourCorrected || endHourCorrected) {
            // For example if 20:00 is entered and the end hour is 20 and slot size is 5, we change the start time to 19:55.
            if (type === 'start' && endHourCorrected) minutes = (60 - this.settings.slotSize).toString();
            // For example if 07:45 is entered and start hour is 8  and slot size is 5, we change the end time to 8:00.
            if (type === 'start' && startHourCorrected) minutes = '0';
            // For example if 21:45 is entered and end hour is 20, we change the end time to 20:00.
            if (type === 'end' && endHourCorrected) minutes = '0';
            // For example if 07:45 or 08:00 is entered and the start hour is 8 and slot size is 5, we change the end time to 8:05.
            if (type === 'end' && startHourCorrected) minutes = this.settings.slotSize.toString();
        }
        // Else when all the minutes are properly filled in, make sure the minutes are valid.
        else if (!minutes.includes('_')) {
            // Make sure the minutes are not greater than 59 and
            // not less than 0. This has to be done before the next step.
            minutes = Number(minutes) > 59 ? '59' : minutes.toString();
            minutes = Number(minutes) < 0 ? '0' : minutes.toString();
            // Make sure the minutes are a multiple of the slot size.
            // but we don't want to round the minutes up when they come
            // close to the next hour. For example, if the slot size is 15
            // and the minutes are 50, we don't want to round it up to 60.
            minutes = (Math.round(Number(minutes) / this.settings.slotSize) * this.settings.slotSize).toString();
            if (Number(minutes) >= 60) minutes = (Number(minutes) - this.settings.slotSize).toString();
        }

        // Always make sure the hours and minutes are 2 digits.
        const formatted = `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

        // Only update the value if the input is valid.
        // We put the check here and not earlier because we want
        // to allow the hours and minutes to be corrected even though
        // if either one is not yet valid. Code below can only be done
        // if hours and minutes are valid.
        if (formatted.includes('_')) return;

        let start = dayAndTimeToDate(this.day, this.slot.start);
        let end = dayAndTimeToDate(this.day, this.slot.end);

        // Update the value
        if (type === 'start') {
            start = setMinutes(setHours(start, Number(hour)), Number(minutes));
            this.slot.start = formatted;

            if (isBefore(end, start) || isEqual(end, start)) {
                end = addMinutes(start, Number(this.settings.slotSize));
                this.slot.end = format(end, 'HH:mm');
            }
        } else {
            end = setMinutes(setHours(end, Number(hour)), Number(minutes));
            this.slot.end = formatted;
        }
    }

    public handleDateCleared(_: CustomEvent<EventDetails<UxDatepicker, any>>, which: 'from' | 'until') {
        if (which === 'from') this.slot.validFrom = null;
        else this.slot.validUntil = null;
    }

    private validate(): boolean {
        resetValidation(this.validation);

        const start = dayAndTimeToDate(this.day, this.slot.start);
        const end = dayAndTimeToDate(this.day, this.slot.end);

        this.validation.start = validateTime(this.slot.start);
        this.validation.end = validateTime(this.slot.end);
        this.validation.practitioner = isDefined(this.slot.practitioner);
        this.validation.range = !isEqual(start, end) && isAfter(end, start);

        if (this.validation.range && this.validation.practitioner) {
            // Check if overlaps and return the index of the period that overlaps
            const overlaps = this.otherSlots.findIndex((slotToCompare: ScheduleTimeSlot) => {
                // Don't compare it to itself.
                if (slotToCompare.id === this.slot.id) return false;

                // First check if they overlap based on their timestamps.
                const doTheyOverlapOnTime = areIntervalsOverlapping(
                    { start, end }, //
                    { start: dayAndTimeToDate(this.day, slotToCompare.start), end: dayAndTimeToDate(this.day, slotToCompare.end) }
                );

                // Of course the same practitioner can't have overlapping periods.
                // When reaching this check we assume both slots have a practitioner selected.
                const matchingPractitioners = slotToCompare.practitioner.id === this.slot.practitioner.id;

                const slot1HasParticulars = isDefined(this.slot.particulars);
                const slot2HasParticulars = isDefined(slotToCompare.particulars);

                // Now we have to check if they have a valid or invalid particular combination.
                // There are three valid combinations, namely:
                // 1: Both have no particulars.
                // 2: One of the slots has EvenWeek and the other has OddWeek (or visa versa).
                // 3: One of the slots has LastOfMonth and the other has FirstOfMonth (or visa versa).
                // All other combinations are invalid.
                let hasInvalidParticulars = false;
                if ((slot1HasParticulars && !slot2HasParticulars) || (!slot1HasParticulars && slot2HasParticulars)) {
                    // One slot has particulars, but the other does not. This is invalid.
                    hasInvalidParticulars = true;
                } else if (slot1HasParticulars && slot2HasParticulars) {
                    // Both slots have particulars, so we need to check if the combination is valid.
                    // First define what combinations are valid combinations.
                    const validCombinations = [
                        [ScheduleTimeSlotParticulars.EvenWeek, ScheduleTimeSlotParticulars.OddWeek],
                        [ScheduleTimeSlotParticulars.OddWeek, ScheduleTimeSlotParticulars.EvenWeek],
                        [ScheduleTimeSlotParticulars.LastOfMonth, ScheduleTimeSlotParticulars.FirstOfMonth],
                        [ScheduleTimeSlotParticulars.FirstOfMonth, ScheduleTimeSlotParticulars.LastOfMonth]
                    ];

                    // Now check if the current combination matches one of the valid combinations.
                    const slot1Particular = this.slot.particulars;
                    const slot2Particular = slotToCompare.particulars;

                    // If the current combination matches one of the valid combinations, then
                    // the 'some' check will return true. So we have to reverse the result,
                    // meaning this combination does NOT have invalid particulars.
                    hasInvalidParticulars = !validCombinations.some(([firstOfValidCombo, secondOfValidCombo]) => {
                        // We don't have to reverse (visa versa) the combination because we added both
                        // options to the valid combinations array.
                        return firstOfValidCombo === slot1Particular && secondOfValidCombo === slot2Particular;
                    });
                } else {
                    // Both slots have no particulars. This is valid.
                    // Mark it as true so it will always pass the check in the return value.
                    // Meaning in this case we ignore the 'hasInvalidParticulars' flag.
                    hasInvalidParticulars = true;
                }

                return doTheyOverlapOnTime
                    ? // Return true if they have invalid particulars OR if they have the same practitioner.
                      hasInvalidParticulars || matchingPractitioners
                    : // If they do not overlap on time. Don't bother checking the rest.
                      false;
            });

            // Mark the period as overlapping.
            this.validation.overlap = overlaps === -1;
            // Mark the overlapping period as overlapping.
            if (!this.validation.overlap)
                this.events.publish(CustomEvents.ScheduleOverlappingTimeslot, {
                    mode: 'mark',
                    index: overlaps,
                    room: this.parent,
                    day: this.day
                });
        }

        return validateState(this.validation);
    }
}
