import { isDefined, isNotDefined } from '@wecore/sdk-utilities';

export class FilterDocumentVersionsValueConverter {
    public toView(versions: string[], documentType: 'eulas' | 'termsAndConditions' | 'processingAgreements', entity: { legal: any }): string[] {
        if (isNotDefined(versions)) return [];
        let versionsToShow = [];
        const newestVersion = versions[versions.length - 1];
        for (const version of versions) {
            // If the document is signed. Always show it.
            if (isDefined(entity.legal) && isDefined(entity.legal[documentType]) && entity.legal[documentType].some((x: any) => x.version === version)) versionsToShow = [...versionsToShow, version];
            // If the version is the newest one. Show it.
            else if (version === newestVersion) versionsToShow = [...versionsToShow, version];
            // Ignore everything else.
        }
        return versionsToShow;
    }
}
