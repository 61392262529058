import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    BodySides,
    ColumnBreakpoint,
    CreateMedicalExaminationActionRequest,
    CreateMedicalTherapyRequest,
    DifferentialDiagnosesRequirements,
    DifferentialDiagnosisEntityReference,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationActionResponse,
    GetMedicalQuestionnaireResponse,
    GetMedicalTherapyResponse,
    HealthcareCode,
    HealthcarePrice,
    HealthcarePriceRequirements,
    MedicalExaminationActionEntityReference,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationActionRequirements,
    MedicalExaminationActionStep,
    MedicalExaminationActionStepEntityReference,
    MedicalExaminationFlow,
    MedicalExaminationModel,
    MedicalExaminationStepVisibilityRequirement,
    MedicalExaminationStepVisibilityRequirementTypes,
    MedicalExaminationTemplateItem,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalQuestion,
    MedicalQuestionEntityReference,
    MedicalQuestionnaireFlow,
    MedicalResult,
    MedicalTherapyEvaluation,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem,
    MedicalTherapyExecution,
    MedicalTherapyExecutionFlow,
    MedicalTherapyExecutionItem,
    MedicalTherapyExecutionItemTypes,
    MedicalTherapyPlan,
    MedicalTherapyPlanFlow,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemTypes,
    Operators,
    StringBodySidesKeyValuePair,
    StringBooleanKeyValuePair,
    StringMedicalExaminationModelBoxKeyValuePair,
    StringStringKeyValuePair
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';
import { UxToggle } from '../../ux/ux-toggle/ux-toggle';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialMedicalStepSettings extends BasePartialView {
    public container: MedicalExaminationFlow | MedicalTherapyEvaluationFlow | MedicalExaminationActionFlow | MedicalQuestionnaireFlow;
    public item: MedicalExaminationTemplateItemStep | MedicalExaminationActionItem | MedicalTherapyExecutionItem;
    public model: MedicalExaminationModel;
    public validation: any = {
        breakpoints: [],
        visibilityRequirements: [],
        ddRequirements: [],
        actionRequirements: [],
        prices: []
    };
    public index: number;
    public breakpoints: ColumnBreakpoint[] = [];
    public modelBox: StringMedicalExaminationModelBoxKeyValuePair;
    public side: StringBodySidesKeyValuePair;
    public required: StringBooleanKeyValuePair;
    public repeatDuringEvaluation: StringBooleanKeyValuePair;
    public visibilityRequirements: MedicalExaminationStepVisibilityRequirement[] = [];
    public ddRequirements: DifferentialDiagnosesRequirements[] = [];
    public actionRequirements: MedicalExaminationActionRequirements[] = [];
    public connectedCategories: StringStringKeyValuePair[] = [];
    public connectedSteps: StringStringKeyValuePair[] = [];
    public prices: HealthcarePriceRequirements[] = [];
    public Operators: typeof Operators = Operators;
    public resultSelectors: UxCombobox[] = [];
    public settings: string[];
    public name: string;
    public label: string;
    public isAction: boolean;
    public isStep: boolean;
    public isTherapyPlanStep: boolean;
    public isCategory: boolean;
    public isQuestion: boolean;
    public isQuestionnaire: boolean;
    public stepChoices: MedicalResult[];
    public categoriesOptions: { value: string; text: string }[] = [];
    public stepOptions: { value: string; text: string }[] = [];
    public tabs: any;
    public stepCategoryName: string;
    public BodySides: typeof BodySides = BodySides;

    private active: GetMedicalExaminationActionResponse | MedicalTherapyExecution | MedicalExaminationTemplateItem | MedicalTherapyEvaluation | GetMedicalQuestionnaireResponse;
    private itemsToCompare: any[] = [];
    private categoryId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });

        this.container = view.data.container;
        this.item = view.data.item;
        this.model = view.data.model;
        this.index = view.data.index;
        this.active = view.data.active;
        this.label = view.data.label;
        this.name = view.data.name;
        this.stepCategoryName = view.data.stepCategoryName;
        this.settings = view.data.settings || [
            'model', //
            'required',
            'columns',
            'visibility',
            'repeatDuringEvaluation',
            'differentialDiagnosis',
            'actions',
            'categories',
            'steps',
            'sides',
            'prices'
        ];
        this.categoryId = view.data.categoryId;

        this.isAction = this.item instanceof MedicalExaminationTemplateItemStep && this.item.type === MedicalExaminationTemplateItemStepTypes.Action;
        this.isStep =
            (this.item instanceof MedicalExaminationActionItem && this.item.type === MedicalExaminationActionItemTypes.Step) ||
            (this.item instanceof MedicalTherapyPlanItem && this.item.type === MedicalTherapyPlanItemTypes.Step) ||
            (this.item instanceof MedicalTherapyExecutionItem && this.item.type === MedicalTherapyExecutionItemTypes.Step);
        this.isTherapyPlanStep = this.item instanceof MedicalTherapyPlanItem && this.item.type === MedicalTherapyPlanItemTypes.Step;
        this.isCategory =
            (this.item instanceof MedicalExaminationActionItem && this.item.type === MedicalExaminationActionItemTypes.Category) ||
            (this.item instanceof MedicalTherapyPlanItem && this.item.type === MedicalTherapyPlanItemTypes.Category) ||
            (this.item instanceof MedicalTherapyExecutionItem && this.item.type === MedicalTherapyExecutionItemTypes.Category);
        this.isQuestion = this.item instanceof MedicalExaminationTemplateItemStep && this.item.type === MedicalExaminationTemplateItemStepTypes.Question;
        this.isQuestionnaire = this.item instanceof MedicalExaminationTemplateItemStep && this.item.type === MedicalExaminationTemplateItemStepTypes.Questionnaire;
        if (this.isStep && (this.item instanceof MedicalExaminationActionItem || this.item instanceof MedicalTherapyPlanItem || this.item instanceof MedicalTherapyExecutionItem))
            this.stepChoices = this.item.step?.choices || [];
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                if (
                    this.container instanceof MedicalExaminationActionFlow || //
                    this.container instanceof MedicalTherapyExecutionFlow ||
                    this.container instanceof MedicalExaminationFlow ||
                    this.container instanceof MedicalTherapyPlanFlow ||
                    this.container instanceof MedicalTherapyEvaluationFlow ||
                    this.container instanceof MedicalQuestionnaireFlow
                ) {
                    this.visibilityRequirements = this.container.visibilityRequirements?.filter((x) => x.id === this.item.id) || [];

                    if (this.container instanceof MedicalExaminationActionFlow || this.container instanceof MedicalTherapyPlanFlow || this.container instanceof MedicalTherapyExecutionFlow) {
                        this.connectedCategories = this.container.connectedCategories.filter((x) => x.key === this.item.id);
                        this.connectedSteps = this.container.connectedSteps.filter((x) => x.key === this.item.id);
                    }

                    if (this.container instanceof MedicalExaminationActionFlow) {
                        this.ddRequirements = this.container.differentialDiagnosesRequirements?.filter((x) => x.id === this.item.id) || [];
                        this.actionRequirements = this.container.medicalExaminationActionRequirements?.filter((x) => x.id === this.item.id) || [];
                        this.modelBox = this.container.modelBoxes?.find((x) => x.key === this.item.id);
                        this.side = this.container.sides?.find((x) => x.key === this.item.id);
                    }

                    if (this.container instanceof MedicalTherapyPlanFlow) {
                        this.prices = this.container.prices?.filter((x) => x.stepId === this.item.id) || [];
                    }
                }
                if (this.container instanceof MedicalExaminationFlow) {
                    this.repeatDuringEvaluation = this.container.repeatDuringEvaluation.find((x) => x.key === this.item.id);
                }

                this.breakpoints = this.container.breakpoints.filter((x) => x.id === this.item.id);
                this.required = this.container.required.find((x) => x.key === this.item.id);

                for (const _ of this.breakpoints) {
                    this.validation.breakpoints.push({
                        screenSize: true,
                        width: true
                    });
                }

                for (const _ of this.visibilityRequirements) {
                    this.validation.visibilityRequirements.push({
                        step: true,
                        result: true
                    });
                }

                for (const _ of this.ddRequirements) {
                    this.validation.ddRequirements.push({
                        answer: true,
                        dd: true
                    });
                }

                for (const _ of this.actionRequirements) {
                    this.validation.actionRequirements.push({
                        answer: true,
                        action: true
                    });
                }

                for (const price of this.prices) {
                    this.validation.prices.push({
                        valid: true,
                        answer: true,
                        invoiceDescription: true,
                        values: true
                    });
                }

                if (isDefined(this.active)) this.setItemsToCompare();
                if (this.isCategory) this.setCategories();

                // This is needed for the visibility requirements.
                // so for steps AND categories.
                this.setSteps();

                // Set all choices into the attributes.
                this.visibilityRequirements.forEach((requirement) => {
                    const step = this.itemsToCompare.find((x) => x.item.id === requirement.stepId);
                    requirement.attributes = {
                        choices: step.choices
                    };
                });

                this.tabs = {
                    general: {
                        name: this.t.tr('translation:partial-views.medical-step-settings.labels.tab-general'),
                        valid: true,
                        active: true,
                        configured: true
                    },
                    visibility: {
                        name: this.t.tr('translation:partial-views.medical-step-settings.labels.tab-visibility'),
                        valid: true,
                        active: false,
                        configured: this.settings.includes('visibility')
                    },
                    dds: {
                        name: this.t.tr('translation:partial-views.medical-step-settings.labels.tab-dds'),
                        active: false,
                        valid: true,
                        configured: this.settings.includes('differentialDiagnosis') && this.isStep && this.stepChoices.length > 0
                    },
                    actions: {
                        name: this.t.tr('translation:partial-views.medical-step-settings.labels.tab-actions'),
                        active: false,
                        valid: true,
                        configured: this.settings.includes('actions') && this.isStep && this.stepChoices.length > 0
                    },
                    prices: {
                        name: this.t.tr('translation:partial-views.medical-step-settings.labels.tab-prices'),
                        active: false,
                        valid: true,
                        configured: this.settings.includes('prices') && this.isTherapyPlanStep
                    }
                };

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalStepSettings.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public setActiveTab(key: string): void {
        for (const prop in this.tabs) this.tabs[prop].active = false;
        this.tabs[key].active = true;
    }

    public async handleRequiredChanged(e: CustomEvent<EventDetails<UxToggle, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (checked) {
            this.required = new StringBooleanKeyValuePair({
                key: this.item.id,
                value: true
            });
        } else this.required = null;
    }

    public async handleRepeatDuringEvaluationChanged(e: CustomEvent<EventDetails<UxToggle, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (checked) {
            this.repeatDuringEvaluation = new StringBooleanKeyValuePair({
                key: this.item.id,
                value: true
            });
        } else this.repeatDuringEvaluation = null;
    }

    public async handleModelBoxSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value;
        const box = this.model.boxes.find((x) => x.id === value);
        if (isDefined(this.modelBox)) this.modelBox = null;
        if (isDefined(box))
            this.modelBox = new StringMedicalExaminationModelBoxKeyValuePair({
                key: this.item.id,
                value: box
            });
    }

    public async handleSideSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value as BodySides;
        this.side = null;

        if (isDefined(value)) this.side = new StringBodySidesKeyValuePair({ key: this.item.id, value });
    }

    public addVisiblityRequirement(): void {
        this.validation.visibilityRequirements.push({
            step: true,
            result: true
        });
        this.visibilityRequirements.push(
            new MedicalExaminationStepVisibilityRequirement({
                id: this.item.id,
                operator: this.visibilityRequirements.any() ? Operators.And : null,
                attributes: {}
            })
        );
    }

    public addDdRequirement(): void {
        this.validation.ddRequirements.push({
            answer: true,
            dd: true
        });
        this.ddRequirements.push(
            new DifferentialDiagnosesRequirements({
                id: this.item.id,
                differentialDiagnoses: []
            })
        );
    }

    public addActionRequirement(): void {
        this.validation.actionRequirements.push({
            answer: true,
            action: true
        });
        this.actionRequirements.push(
            new MedicalExaminationActionRequirements({
                id: this.item.id,
                actions: []
            })
        );
    }

    public removeVisibilityRequirement(index: number) {
        this.visibilityRequirements.splice(index, 1);
        this.validation.visibilityRequirements.splice(index, 1);
    }

    public removeDdRequirement(index: number) {
        this.ddRequirements.splice(index, 1);
        this.validation.ddRequirements.splice(index, 1);
    }

    public removeActionRequirement(index: number) {
        this.actionRequirements.splice(index, 1);
        this.validation.actionRequirements.splice(index, 1);
    }

    public async handleStepToCompareSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        const value = e.detail.values.value;

        setTimeout(() => {
            if (isDefined(this.resultSelectors[index])) this.resultSelectors[index].clear();
            const selected = this.itemsToCompare.find((x) => x.value === value);

            if (isDefined(selected.item.question)) {
                const question: MedicalQuestion = selected.item.attributes?.question || selected.item.question;
                this.visibilityRequirements[index].type = MedicalExaminationStepVisibilityRequirementTypes.Question;
                this.visibilityRequirements[index].attributes.choices = question.choices;
                this.visibilityRequirements[index].questionToCompare = new MedicalQuestionEntityReference({
                    id: question.id,
                    translations: question.name
                });
                this.visibilityRequirements[index].stepId = selected.item.id;
            } else {
                const step: MedicalExaminationActionStep = selected.item.attributes?.step || selected.item.step;
                this.visibilityRequirements[index].type = MedicalExaminationStepVisibilityRequirementTypes.Action;
                this.visibilityRequirements[index].attributes.choices = step.choices;
                this.visibilityRequirements[index].stepToCompare = new MedicalExaminationActionStepEntityReference({
                    id: step.id,
                    translations: step.name
                });
                this.visibilityRequirements[index].stepId = selected.item.id;
            }
        });
    }

    public async handleDdAnswerSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        const value = e.detail.values.value;

        const selected = this.stepChoices.find((x) => x.value === value);
        this.ddRequirements[index].resultToCompare = selected;
    }

    public async handlePriceAnswerSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        const value = e.detail.values.value;

        const selected = this.stepChoices.find((x) => x.value === value);
        this.prices[index].resultToCompare = selected;
    }

    public async handleActionAnswerSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        const value = e.detail.values.value;

        const selected = this.stepChoices.find((x) => x.value === value);
        this.actionRequirements[index].resultToCompare = selected;
    }

    public handleDdsChanged = (diagnoses: GetDifferentialDiagnosisResponse[], _: 'added' | 'deleted', __: GetDifferentialDiagnosisResponse, index: any): void => {
        this.ddRequirements[index].differentialDiagnoses = diagnoses
            .distinct((x: GetDifferentialDiagnosisResponse) => x.id)
            .map(
                (d) =>
                    new DifferentialDiagnosisEntityReference({
                        id: d.id,
                        translations: d.name
                    })
            );
    };

    public handleActionsChanged = (actions: GetMedicalExaminationActionResponse[], index: any): void => {
        this.actionRequirements[index].actions = actions
            .distinct((x: GetDifferentialDiagnosisResponse) => x.id)
            .map(
                (a) =>
                    new MedicalExaminationActionEntityReference({
                        id: a.id,
                        translations: a.name
                    })
            );
    };

    public handleCategorySelected = (option: { value: string; text: string }) => {
        this.connectedCategories.push(
            new StringStringKeyValuePair({
                key: this.item.id,
                value: option.value
            })
        );
    };

    public handleCategoryRemoved = (_: { value: string; text: string }, index: number) => {
        this.connectedCategories.splice(index, 1);
    };

    public handleStepSelected = (option: { value: string; text: string }) => {
        this.connectedSteps.push(
            new StringStringKeyValuePair({
                key: this.item.id,
                value: option.value
            })
        );
    };

    public handleStepRemoved = (_: { value: string; text: string }, index: number) => {
        this.connectedSteps.splice(index, 1);
    };

    public async handleResultSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        const value = e.detail.values.value;

        const result = this.visibilityRequirements[index].attributes.choices.find((x: MedicalResult) => x.value === value);
        this.visibilityRequirements[index].resultToCompare = MedicalResult.fromJS(result);
    }

    public hasSetting(setting: 'model' | 'required' | 'columns' | 'visibility' | 'repeatDuringEvaluation' | 'differentialDiagnosis' | 'actions'): boolean {
        return this.settings.some((s) => s === setting);
    }

    public save(): void {
        const valid = this.validate();
        if (valid) {
            this.remove({
                result: PartialViewResults.Ok,
                data: {
                    breakpoints: this.breakpoints,
                    required: this.required,
                    modelBox: this.modelBox,
                    side: this.side,
                    repeatDuringEvaluation: this.repeatDuringEvaluation,
                    visibilityRequirements: this.visibilityRequirements.map((x) => {
                        // Clean up the attributes.
                        delete x.attributes.choices;
                        return x;
                    }),
                    ddRequirements: this.ddRequirements,
                    actionRequirements: this.actionRequirements,
                    categories: this.connectedCategories,
                    connectedSteps: this.connectedSteps,
                    prices: this.prices
                }
            });
        }
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Canceled
        });
    }

    public toSet(array: any[], type: 'ItemsToCompare' | 'MedicalResult'): { value: string; text: string; data?: any }[] {
        if (isNotDefined(array)) return [];
        if (type === 'ItemsToCompare') return array.map((x) => ({ value: x.value, text: x.text }));
        if (type === 'MedicalResult') return array.map((x: MedicalResult) => ({ value: x.value, text: x.value }));
    }

    public addPrice(): void {
        this.prices.push(
            new HealthcarePriceRequirements({
                id: guid(),
                stepId: this.item.id,
                values: [],
                codes: []
            })
        );
        this.validation.prices.push({
            valid: true,
            answer: true,
            values: true,
            invoiceDescription: true
        });
    }

    public removePrice(index: number) {
        this.prices.splice(index, 1);
        this.validation.prices.splice(index, 1);
    }

    public removePriceValue(parentIndex: number, index: number) {
        this.prices[parentIndex].values.splice(index, 1);
    }

    public async createOrEditPrice(parentIndex: number, index: number = -1): Promise<void> {
        const price =
            index > -1
                ? this.prices[parentIndex].values[index]
                : new HealthcarePrice({
                      vatValue: 0
                  });
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.prices[parentIndex].values
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.prices[parentIndex].values[data.index] = data.price;
                    else this.prices[parentIndex].values.push(data.price);

                    this.prices[parentIndex].values = [
                        ...(this.prices[parentIndex].values.length > 0 ? [this.prices[parentIndex].values.shift()] : []), //
                        ...this.prices[parentIndex].values
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public formatDescription(item: HealthcarePrice): string {
        let description = '';
        if (isDefined(item.periodStart)) description += format(item.periodStart, 'dd-MM-yyyy');
        if (isDefined(item.periodStart)) description += ' - ';
        if (isDefined(item.periodEnd)) description += format(item.periodEnd, 'dd-MM-yyyy');

        if (isDefined(item.insurer)) description += ` (${item.insurer.translations[this.language]})`;

        return description;
    }

    public addCode(index: number): void {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.prices[index].codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(parentIndex: number, index: number): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.prices[parentIndex].codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.prices[parentIndex].codes[data.index] = data.code;
                    this.prices[parentIndex].codes = [
                        ...(this.prices[parentIndex].codes.length > 0 ? [this.prices[parentIndex].codes.shift()] : []), //
                        ...this.prices[parentIndex].codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(parentIndex: number, index: number): Promise<void> {
        await this.removeChildViews();
        this.prices[parentIndex].codes.splice(index, 1);
    }

    private validate(): boolean {
        // For each breakpoint
        for (let i = 0; i < this.validation.breakpoints.length; i++) {
            const breakpoint = this.breakpoints[i];
            this.validation.breakpoints[i].screenSize = isDefined(breakpoint.screenSize) && isNotEmpty(breakpoint.screenSize);
            this.validation.breakpoints[i].width = isNotEmpty(breakpoint.width) && isOfType(Number(breakpoint.width), 'number') && breakpoint.width >= 1 && breakpoint.width <= 12;
        }

        for (let rI = 0; rI < this.visibilityRequirements.length; rI++) {
            resetValidation(this.validation.visibilityRequirements[rI]);

            const requirement = this.visibilityRequirements[rI];
            this.validation.visibilityRequirements[rI].step = isDefined(requirement.questionToCompare) || isDefined(requirement.stepToCompare);
            if (this.validation.visibilityRequirements[rI].step) {
                this.validation.visibilityRequirements[rI].result =
                    isDefined(requirement.resultToCompare) && isDefined(requirement.resultToCompare.value) && isNotEmpty(requirement.resultToCompare.value);
            }
        }

        for (let rI = 0; rI < this.ddRequirements.length; rI++) {
            resetValidation(this.validation.ddRequirements[rI]);

            const requirement = this.ddRequirements[rI];
            this.validation.ddRequirements[rI].answer = isDefined(requirement.resultToCompare);
            this.validation.ddRequirements[rI].dd = isDefined(requirement.differentialDiagnoses) && requirement.differentialDiagnoses.any();
        }

        for (let rI = 0; rI < this.actionRequirements.length; rI++) {
            resetValidation(this.validation.actionRequirements[rI]);

            const requirement = this.actionRequirements[rI];
            this.validation.actionRequirements[rI].answer = isDefined(requirement.resultToCompare);
            this.validation.actionRequirements[rI].action = isDefined(requirement.actions) && requirement.actions.any();
        }

        for (let pI = 0; pI < this.prices.length; pI++) {
            const price = this.prices[pI];
            this.validation.prices[pI].answer = isDefined(price.resultToCompare);
            this.validation.prices[pI].invoiceDescription = isDefined(price.invoiceDescription) && isNotEmpty(price.invoiceDescription);
            this.validation.prices[pI].values = price.values.any();
            this.validation.prices[pI].valid = this.validation.prices[pI].answer && this.validation.prices[pI].invoiceDescription && this.validation.prices[pI].values;
        }

        this.tabs.visibility.valid = this.validation.visibilityRequirements.every((x: any) => x.step && x.result);
        this.tabs.dds.valid = this.validation.ddRequirements.every((x: any) => x.answer && x.dd);
        this.tabs.actions.valid = this.validation.actionRequirements.every((x: any) => x.answer && x.action);
        this.tabs.prices.valid = this.validation.prices.every((x: any) => x.valid);

        return (
            this.validation.breakpoints.every((x: any) => x.width && x.screenSize) &&
            this.validation.visibilityRequirements.every((x: any) => x.step && x.result) &&
            this.validation.ddRequirements.every((x: any) => x.answer && x.dd) &&
            this.validation.actionRequirements.every((x: any) => x.answer && x.action) &&
            this.validation.prices.every((x: any) => x.valid)
        );
    }

    private setItemsToCompare(): void {
        const itemsToCompare = [];

        const push = (text: string, value: string, choices: MedicalResult[], item: any) => {
            itemsToCompare.push({ text, value, choices, item });
        };

        const find = (items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], render: boolean = false) => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    // We only show the options that are in the same
                    // category as the current step we're configuring the settings for.
                    find(item.category.stepsToTake, item.category.id === this.categoryId);
                } else if (render) {
                    let name = item.step.name[this.language];
                    push(name, item.step.id, item.step.choices, item);
                }
            }
        };

        if (
            this.active instanceof GetMedicalExaminationActionResponse || //
            this.active instanceof CreateMedicalExaminationActionRequest ||
            this.active instanceof MedicalTherapyPlan ||
            this.active instanceof MedicalTherapyExecution
        ) {
            find(this.active.stepsToTake, isNotDefined(this.categoryId));
        } else if (
            this.active instanceof MedicalExaminationTemplateItem || //
            this.active instanceof MedicalTherapyPlan ||
            this.active instanceof MedicalTherapyEvaluation
        ) {
            for (const step of this.active.stepsToTake) {
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        push(step.attributes.question.name[this.language], step.attributes.question.id, step.attributes.question.choices, step);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        for (const item of step.attributes.questionnaire.questions) {
                            push(item.question.name[this.language], item.question.id, item.question.choices, item);
                        }
                        break;
                }
            }
        } else if (this.active instanceof GetMedicalQuestionnaireResponse) {
            for (const item of this.active.questions) {
                push(item.question.name[this.language], item.question.id, item.question.choices, item);
            }
        }

        // We only show the steps that have choices to selected from.
        this.itemsToCompare = itemsToCompare.filter((x) => x.choices.any());
    }

    private setCategories(): void {
        if (this.active instanceof MedicalExaminationTemplateItem || this.active instanceof GetMedicalQuestionnaireResponse) return;

        const categories = [];
        const container = this.container as MedicalExaminationActionFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow;
        let connection: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;

        const findStep = (
            items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
            id: string
        ): MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem => {
            let match: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    if (item.id === id || item.category.id === id) match = item;
                    // Only override if no match is yet found.
                    if (isNotDefined(match)) match = findStep(item.category.stepsToTake, id);
                }
            }
            return match;
        };

        if (!(this.active instanceof MedicalTherapyEvaluation)) {
            const parentStep = findStep(this.active.stepsToTake, this.categoryId);
            if (isDefined(parentStep)) {
                const parentSettings = container.connectedCategories.filter((x) => x.key === parentStep.id);
                if (parentSettings.any()) connection = findStep(this.active.stepsToTake, parentSettings[0].value);
            }
        }

        const find = (
            items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[] | MedicalTherapyEvaluationItem[],
            render: boolean = false,
            parent: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem
        ) => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    if (render && item.id !== this.item.id) {
                        categories.push({
                            value: item.id, //
                            text: `${isDefined(parent) ? `${parent.category.name[this.language]} - ` : ''}${item.category.name[this.language]}`
                        });
                    }

                    const match = isDefined(connection) //
                        ? item.category.id === this.categoryId || item.category.id === connection.category.id
                        : item.category.id === this.categoryId;

                    find(item.category.stepsToTake, match, item);
                }
            }
        };

        if (
            this.active instanceof GetMedicalExaminationActionResponse ||
            this.active instanceof CreateMedicalExaminationActionRequest ||
            this.active instanceof GetMedicalTherapyResponse ||
            this.active instanceof CreateMedicalTherapyRequest ||
            this.active instanceof MedicalTherapyPlan ||
            this.active instanceof MedicalTherapyExecution
        ) {
            find(this.active.stepsToTake, isNotDefined(this.categoryId), null);
        }

        this.categoriesOptions = categories;
    }

    private setSteps(): void {
        const steps = [];

        const findStep = (
            items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
            id: string
        ): MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem => {
            let match: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    if (item.id === id || item.category.id === id) match = item;
                    // Only override if no match is yet found.
                    if (isNotDefined(match)) match = findStep(item.category.stepsToTake, id);
                }
            }
            return match;
        };

        // When we have a category id provided try to look up any connected categories.
        // Because we want to render the steps of connected categories as well.
        let parentCategories: string[];
        if (
            isDefined(this.categoryId) &&
            (this.container instanceof MedicalExaminationActionFlow || //
                this.container instanceof MedicalTherapyPlanFlow ||
                this.container instanceof MedicalTherapyExecutionFlow)
        ) {
            const categoryStep = findStep((this.active as any).stepsToTake, this.categoryId);
            const parentSettings = this.container.connectedCategories.filter((x) => x.key === categoryStep.id);

            const connectedCategories = parentSettings.map((x) => findStep((this.active as any).stepsToTake, x.value).id);
            parentCategories = [categoryStep.id, ...connectedCategories];
        }

        const find = (
            items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
            parent: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem = null
        ) => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    if (isDefined(this.categoryId)) find(item.category.stepsToTake, item);
                } else {
                    // If we have a category id and no parent, we don't render the step.
                    // Also when the item is the same as the item we're settings the settings for, we don't render it.
                    if ((isDefined(this.categoryId) && isNotDefined(parent)) || item.id === this.item.id) continue;

                    // When a parent is defined.
                    if (isDefined(parent) && isDefined(parentCategories) && parentCategories.every((x) => x !== parent.id)) continue;

                    const name = isDefined(parent) ? `${parent.category.name[this.language]} - ${item.step.name[this.language]}` : item.step.name[this.language];
                    steps.push({ value: item.id, text: name });
                }
            }
        };

        if (
            this.active instanceof GetMedicalExaminationActionResponse || //
            this.active instanceof CreateMedicalExaminationActionRequest ||
            this.active instanceof MedicalTherapyPlan ||
            this.active instanceof MedicalTherapyExecution
        ) {
            find(this.active.stepsToTake);
        }

        this.stepOptions = steps;
    }
}
