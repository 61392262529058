<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header padding="py-5 px-8">
            <div class="flex items-center justify-between">
                <div class="flex flex-col">
                    <p class="font-semibold leading-tight" t="translation:partial-views.practice-integrations.labels.title"></p>
                    <span class="text-sm leading-tight text-gray-500" t="translation:partial-views.practice-integrations.messages.select-integration"></span>
                </div>
            </div>
        </ux-header>
        <div class="flex h-full w-full flex-col overflow-y-auto px-6 pb-6">
            <ul class="flex flex-1 flex-col gap-1 pt-6">
                <li class="group flex w-full" repeat.for="setting of settings">
                    <a
                        href="javascript:"
                        bg-gray-100.class="setting.view.name === active.name"
                        click.trigger="changeToView(setting.view)"
                        class="flex flex-1 items-center justify-between rounded-xl px-3 py-2 group-hover:bg-gray-100">
                        <div class="flex items-center gap-3 text-gray-700">
                            <p class="text-sm" t.bind="setting.title"></p>
                        </div>
                        <svg
                            if.bind="setting.view.name === active.name"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4 text-gray-500 group-hover:text-black">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>
