<import from="converters/keys" />
<import from="converters/currency" />
<import from="converters/translate" />
<import from="converters/date" />
<import from="converters/declaration-send-method-to-string" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-col overflow-hidden">
            <template if.bind="baseLoaded">
                <div class="flex flex-1 flex-col gap-y-3 overflow-y-auto px-8 pb-5 pt-6">
                    <div class="flex flex-col justify-between border-b border-gray-200 pb-3">
                        <div class="flex items-center justify-between">
                            <p class="font-medium text-gray-800" t="translation:partial-views.declaration-performances.create.title"></p>
                            <div class="flex items-center gap-5">
                                <p class="text-primary">${patient.displayName} (${patient.number})</p>
                                <ux-button if.bind="invoice.type === HealthcareInvoiceTypes.Debit" size="xs" click.trigger="covCheck()">
                                    <span t="translation:partial-views.patients.buttons.cov"></span>
                                </ux-button>
                            </div>
                        </div>
                        <div if.bind="invoice.type === HealthcareInvoiceTypes.Debit" class="mt-3 flex flex-col border-t border-gray-200 pt-2">
                            <ux-label class="mb-1" if.bind="patient.insurances && patient.insurances.length > 0">
                                <span t="translation:partial-views.declaration-performances.labels.insurances"></span>
                            </ux-label>
                            <div if.bind="patient.insurances && patient.insurances.length > 0" class="grid flex-1 grid-cols-12 gap-1">
                                <button
                                    repeat.for="insurance of patient.insurances"
                                    click.trigger="selectInsurance(insurance)"
                                    class="col-span-12 flex items-center gap-3 rounded-lg bg-gray-50 px-3 py-2 hover:bg-gray-100 disabled:hover:cursor-not-allowed">
                                    <div if.bind="insurance.status === InsuranceStatuses.Active" class="h-[40px] w-1 rounded-full bg-green-500"></div>
                                    <div if.bind="insurance.status === InsuranceStatuses.Inactive" class="h-[40px] w-1 rounded-full bg-red-500"></div>
                                    <div if.bind="insurance.status === InsuranceStatuses.Unauthorized" class="h-[40px] w-1 rounded-full bg-red-500"></div>
                                    <div class="flex flex-col items-start">
                                        <p class="text-sm">${insurance.name} (${insurance.insurer.translations | translate:language} - ${insurance.insurerReference})</p>
                                        <div class="text-xs text-gray-500">
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Supplementary"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.SupplementaryPlusDental"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary-dental">
                                            </span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Awbz"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-awbz"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Basic"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-basic"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.MainInsurance"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-main"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Dental"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-dental"></span>
                                            <span>-</span>
                                            <span t="translation:partial-views.declaration-performances.labels.last-checked"></span>
                                            <span>${ insurance.addedAt | date:'dd-MM-yyyy HH:mm'}</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <p else class="text-sm text-red-500" t="translation:partial-views.declaration-performances.messages.no-insurances"></p>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-2">
                        <div class="col-span-12">
                            <ux-label if.bind="patient.insurances && patient.insurances.length > 0">
                                <span t="translation:global.labels.performances"></span>
                            </ux-label>
                        </div>
                        <div repeat.for="item of request.items" class="col-span-12 flex flex-col rounded-lg bg-gray-50 px-5 py-3">
                            <let line.bind="invoice.lines.find((x) => x.id === item.lineId)"></let>
                            <let charge.bind="line.additionalCharges.find((x) => x.id === item.merges[0])"></let>
                            <let charges.bind="line.additionalCharges.filter((x) => item.merges.includes(x.id))"></let>
                            <let mergedline.bind="item.merges.includes(item.lineId)"></let>
                            <div class="flex w-full items-start justify-between">
                                <div class="flex flex-col">
                                    <div class="flex flex-1 gap-1 text-sm font-semibold">
                                        <span if.bind="charge">${charge.description}</span>
                                        <span else>${line.description}</span>
                                    </div>
                                    <div class="flex gap-1 text-xs">
                                        <span
                                            class="${groups[charge.groupId] ? groups[charge.groupId] : '' } ${groups[line.groupId] ? groups[line.groupId] : '' }"
                                            t="translation:partial-views.healthcare-invoices.labels.join-number"></span>
                                        <span if.bind="charge" class="${groups[charge.groupId] ? groups[charge.groupId] : '' }">${charge.groupId}</span>
                                        <span else class="${groups[line.groupId] ? groups[line.groupId] : '' }">${line.groupId}</span>
                                    </div>
                                </div>
                                <div class="flex items-center gap-1">
                                    <span if.bind="charge" repeat.for="code of charge.codes" class="text-sm"></span>
                                    <span else repeat.for="code of line.codes" class="text-sm">${code.system}/${code.value}</span>
                                    <span class="text-sm">(${line.performedBy.name})</span>
                                </div>
                            </div>
                            <div class="col-span-6 mt-2 grid grid-cols-12 gap-1">
                                <div class="col-span-12 flex items-center">
                                    <div class="flex-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.type"></span>
                                        </ux-label>
                                    </div>
                                    <div class="flex-1">
                                        <div
                                            bg-white.class="invoice.type === HealthcareInvoiceTypes.Debit"
                                            bg-gray-100.class="invoice.type === HealthcareInvoiceTypes.Credit"
                                            class="h-[38px] w-full rounded-lg border border-gray-300 px-3 py-2 text-sm text-gray-800">
                                            <span if.bind="line.type === HealthcareInvoiceLineTypes.Credit" t="translation:global.labels.credit"></span>
                                            <span else t="translation:global.labels.debit"></span>
                                        </div>
                                    </div>
                                </div>
                                <div if.bind="invoice.type === HealthcareInvoiceTypes.Debit" class="col-span-12 flex items-center">
                                    <div class="flex-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.declaration-performances.labels.performance-date"></span>
                                        </ux-label>
                                    </div>
                                    <div class="flex-1">
                                        <ux-datepicker
                                            disabled.bind="invoice.type === HealthcareInvoiceTypes.Credit"
                                            value.to-view="item.startedAt"
                                            onselect.trigger="handleDateChanged($event)"
                                            data.bind="$index">
                                        </ux-datepicker>
                                    </div>
                                </div>
                                <div class="col-span-12 flex items-center">
                                    <div class="flex-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:partial-views.declaration-performances.labels.send-method"></span>
                                        </ux-label>
                                    </div>
                                    <div class="flex-1">
                                        <ux-select
                                            onselect.trigger="handleSingleMethodSelected($event)"
                                            disabled.bind="invoice.type === HealthcareInvoiceTypes.Credit"
                                            value.two-way="item.sendMethod"
                                            data.bind="item">
                                            <ux-select-option repeat.for="method of DeclarationSendMethods | keys" value.bind="method">
                                                <span>${method | declarationSendMethodToString | t} </span>
                                            </ux-select-option>
                                        </ux-select>
                                    </div>
                                </div>
                                <div if.bind="invoice.type === HealthcareInvoiceTypes.Debit" class="col-span-12 flex items-center">
                                    <div class="flex-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.insurance"></span>
                                        </ux-label>
                                    </div>
                                    <div class="flex-1">
                                        <ux-select
                                            disabled.bind="invoice.type === HealthcareInvoiceTypes.Credit"
                                            valid.bind="validation.items[$index].insurance"
                                            disabled.bind="!patient.insurances || patient.insurances.length === 0"
                                            value.two-way="item.insuranceId"
                                            data.bind="{ index: $index, item}"
                                            onselect.trigger="handleInsuranceSelected($event)"
                                            t="[placeholder]translation:partial-views.declaration-performances.placeholders.select-insurance">
                                            <ux-select-option repeat.for="insurance of patient.insurances" value.bind="insurance.id">
                                                <span>${insurance.name} (${insurance.insurer.translations | translate:language} - ${insurance.insurerReference})</span>
                                            </ux-select-option>
                                        </ux-select>
                                    </div>
                                </div>
                                <div class="col-span-12 flex items-center">
                                    <div class="flex-1">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.total"></span>
                                        </ux-label>
                                    </div>
                                    <div class="flex-1">
                                        <div
                                            bg-white.class="invoice.type === HealthcareInvoiceTypes.Debit"
                                            bg-gray-100.class="invoice.type === HealthcareInvoiceTypes.Credit"
                                            class="h-[38px] w-full rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-800">
                                            <template if.bind="charges && charges.length > 1">
                                                <span
                                                    >${charges.reduce((total, charge) => total + charge.price.netValue * charge.quantity, 0) + (mergedline ? line.productPrice.netValue * line.quantity
                                                    : 0 ) | currency}</span
                                                >
                                            </template>
                                            <template else>
                                                <span if.bind="!charge">${(line.productPrice.netValue * line.quantity) | currency}</span>
                                                <span if.bind="charge">${(charge.price.netValue * charge.quantity) | currency}</span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div if.bind="charges.length > 1" class="col-span-12 mt-3 flex items-center pb-2">
                                    <ux-alert color="warning">
                                        <p class="font-semibold" t="translation:partial-views.declaration-performances.messages.merged-performances"></p>
                                        <ul class="mt-1 list-disc pl-2">
                                            <li if.bind="mergedline">
                                                <p>${line.description}</p>
                                            </li>
                                            <li repeat.for="item of charges">
                                                <p>${item.description}</p>
                                            </li>
                                        </ul>
                                    </ux-alert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-1 flex-col px-8 pt-8">
                    <div class="flex justify-between border-b border-gray-200 pb-3">
                        <p class="text-xl font-medium text-gray-800" t="translation:partial-views.declaration-performances.labels.title"></p>
                    </div>
                    <div class="mt-5 grid grid-cols-1 gap-5">
                        <div class="flex flex-1 flex-col space-y-3">
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </template>
            <ux-footer>
                <div class="flex flex-1 items-center justify-between gap-x-2">
                    <div class="flex-1">
                        <ux-select
                            if.bind="invoice.type === HealthcareInvoiceTypes.Debit"
                            set-value-on-select.bind="false"
                            onselect.trigger="handleMethodSelected($event)"
                            t="[placeholder]translation:partial-views.declaration-performances.placeholders.select-type">
                            <ux-select-option repeat.for="method of DeclarationSendMethods | keys" value.bind="method">
                                <span>${method | declarationSendMethodToString | t} </span>
                            </ux-select-option>
                        </ux-select>
                    </div>
                    <div class="flex flex-1 justify-end gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.submit"></span>
                        </ux-button>
                    </div>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
