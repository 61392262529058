import { BlobStorageAttachment } from '@wecore/sdk-crm';
import { MedicalWidgetTypes, WidgetResult } from '@wecore/sdk-healthcare';

export interface IWidgetRegistration {
    stepId: string;
    type: MedicalWidgetTypes;
    onSave: () => Promise<void>;
    validate: (result: WidgetResult, validation: any) => boolean;
    refresh: () => Promise<void>;
    onFileUploaded: (attachment: BlobStorageAttachment) => Promise<void>;
}

export class WidgetRegistration {
    public stepId: string;
    public type: MedicalWidgetTypes;
    public onSave: () => Promise<void>;
    public validate: (result: WidgetResult, validation: any) => boolean;
    public refresh: () => Promise<void>;
    public onFileUploaded: (attachment: BlobStorageAttachment) => Promise<void>;

    public constructor(data: IWidgetRegistration) {
        this.stepId = data?.stepId;
        this.type = data?.type;
        this.onSave = data?.onSave;
        this.validate = data?.validate;
        this.refresh = data?.refresh;
        this.onFileUploaded = data?.onFileUploaded;
    }
}
