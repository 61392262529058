<import from="bx/bx-address-selector/bx-address-selector" />

<section class="flex flex-col flex-1 p-5 overflow-y-auto">
    <bx-address-selector
        descriptions.bind="descriptions"
        language.bind="language"
        mode="vertical"
        addresses.bind="patient.addresses"
        t="[label]translation:components.widgets.patient-addresses.labels.enter-addresses">
    </bx-address-selector>
</section>
