import { IDialogController } from '@aurelia/dialog';
import { isEmpty, isFunction, isNotDefined, resetValidation } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { AuthenticationService } from '../../services/service.authentication';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(IDialogController, ErrorHandler, AuthenticationService)
export class ModalConfirmWithPassword {
    public options: ConfirmationOptions;
    public isLoading: boolean = false;
    public password: string;
    public validation: any = {
        password: true,
        validPassword: true
    };

    public constructor(
        private readonly controller: IDialogController, //
        private readonly errorHandler: ErrorHandler,
        private readonly auth: AuthenticationService
    ) {}

    public activate(params: ConfirmationOptions): void {
        this.options = params;
    }

    public async ok(): Promise<void> {
        resetValidation(this.validation);

        if (isNotDefined(this.password) || isEmpty(this.password)) {
            this.validation.password = false;
            return;
        }

        this.isLoading = true;
        const isValidPassword = await this.auth.validatePassword(this.password, (error) => {
            this.errorHandler.handle('validate-password', error);
            this.isLoading = false;
        });

        this.isLoading = false;
        if (!isValidPassword) {
            this.validation.validPassword = false;
            return;
        }

        if (this.options.showLoading) this.isLoading = true;
        try {
            if (isFunction(this.options.callback)) await this.options.callback(true, this.password);
            await this.controller.ok();
        } catch (e) {
            await this.errorHandler.handle(`[confirm/callback] ${this.options.title}`, e);
            if (this.options.showLoading) this.isLoading = false;

            throw e;
        }
    }

    public async handleKeydown(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.ok();
    }

    public async cancel(): Promise<void> {
        if (isFunction(this.options.callback)) await this.options.callback(false, this.password);
        await this.controller.cancel();
    }
}
