import { I18N } from '@aurelia/i18n';
import { watch } from '@aurelia/runtime-html';
import { HealthcareSectors } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { HealthcareSectorToStringValueConverter } from '../../converters/healthcare-sector-to-string';
import { EventDetails } from '../../models/event-details';
import { UxCombobox } from '../ux-combobox/ux-combobox';
import { UxTags } from '../ux-tags/ux-tags';

@containerless
@inject(I18N)
export class UxMultiSelector {
    @bindable() public values: any[] = [];
    @bindable() public options: { value: string; text: string; data?: any }[];
    @bindable() public mode: 'selection' | 'input' = 'selection';
    @bindable() public type: 'custom' | 'healthcare-sectors' = 'custom';
    @bindable() public autocomplete: boolean = true;
    @bindable() public background: 'gray' | 'white' = 'gray';
    @bindable() public disabled: boolean;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public language: string;
    @bindable() public data: any;
    @bindable() public isLoading: boolean = false;
    @bindable() public allowClearWhileDisabled: boolean = false;
    @bindable() public debounce: number = 500;
    @bindable() public onSelected: (option: { value: string; text: string; data?: any }, data: any) => Promise<void>;
    @bindable() public onRemoved: (option: { value: string; text: string; data?: any }, index: number, data: any) => Promise<void>;
    @bindable() public onSearch: (query: string, data: any) => Promise<void>;
    @bindable() public onValueClick: (option: { value: string; text: string; data?: any }, data: any) => Promise<void>;
    @bindable() public itemTemplate: (item: any) => string;
    @bindable() public showNoResults: boolean = false;
    @bindable() public showFooter: boolean = true;
    @bindable() public used: string[] = [];
    @bindable() public forceRemove: boolean = false;

    public focused: number = -1;
    public selector: UxCombobox;
    public loading: boolean = false;
    private allOptions: { value: string; text: string; data?: any }[];
    private selected: { value: string; text: string; data?: any }[] = [];

    public constructor(
        public t: I18N //
    ) {
        this.placeholder = t.tr('translation:global.placeholders.select-healthcare-sectors');
    }

    public attached() {
        this.initOptions(this.options);
    }

    @watch('values.length')
    public refreshOptions(): void {
        if (!this.autocomplete) return;

        // this.selected = this.allOptions;
        // // .filter((x) => this.values.some((v) => x.value === v));
        // this.options = this.allOptions;
        // // .filter((x) => this.values.every((v) => x.value !== v));
    }

    public async setSelected(values: string[]): Promise<void> {
        // await (this.selector as AuTags).setSelected(values);
    }

    public refresh(options: { value: string; text: string; data?: any }[] = null): void {
        this.initOptions(options ?? this.allOptions);
    }

    public async clear(): Promise<void> {
        this.values = [];
        this.selected = [];
        this.options = this.allOptions;
        this.selector.clear();
    }

    public getOption(value: string): { value: string; text: string; data?: any } {
        return this.allOptions?.find((option) => option.value === value);
    }

    public getText(value: string): string {
        if (this.mode === 'input' || isNotDefined(value)) return value;
        const option = this.allOptions?.find((option) => option.value === value);
        return option?.text || this.selected.find((option) => option.value === value)?.text || this.t.tr('translation:global.messages.option-not-found');
    }

    public async handleSelected(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        const option = e.detail.values;
        if (this.selected.some((o) => o.value === option.value)) {
            const index = this.selected.findIndex((o) => o.value === option.value);
            this.remove(index, option.value);
        } else {
            // Make sure to first push the option to the selected array
            // before pushing the value to the values array.
            // This way the text of the selected option will be available.
            this.selected.push(option);
            // Add value to the values array.
            this.values.push(option.value);
            // Fire the callback if it exists.
            if (isFunction(this.onSelected))
                this.onSelected(
                    option,
                    // Why from all options and not just the selected option?
                    // If known, update this comment.
                    // this.allOptions.find((o) => o.value === option.value), //
                    this.data
                );
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        const query = e.detail.values;
        if (this.autocomplete) {
            this.options = isDefined(query) //
                ? this.allOptions.filter((x) => x.text.toLowerCase().includes(query.toLowerCase()))
                : this.allOptions;
        } else if (isFunction(this.onSearch)) this.onSearch(query, this.data);
    }

    public handleValueClicked(value: string): void {
        const option = this.selected.find((x) => x.value === value);
        if (isFunction(this.onValueClick)) this.onValueClick(option, this.data);
    }

    public handleChange(e: CustomEvent<EventDetails<UxTags, any>>): void {
        const tags = e.detail.values.tags as string[];
        this.values = tags;
        if (this.autocomplete) {
            this.options = null;
            this.options = tags.map((tag) => ({ value: tag, text: tag, data: null }));
        }
    }

    public remove(index: number, value: string): void {
        const sI = this.selected.findIndex((x) => x.value === value);
        if (isFunction(this.onRemoved)) {
            this.onRemoved(this.selected[sI], index, this.data);
            this.selected.splice(sI, 1);
            if (!this.forceRemove) return;
        } else this.selected.splice(sI, 1);
        this.values.splice(index, 1);
    }

    public handleClose(): void {
        if (this.autocomplete) {
            this.options = null;
            setTimeout(async () => {
                this.options = this.allOptions;
                // .filter((x) => this.values.every((value) => value !== x.value));
            });
        }
    }

    private initOptions(optionsToUse: { value: string; text: string; data?: any }[]): void {
        const options = [];
        if (this.type === 'healthcare-sectors') {
            const formatter = new HealthcareSectorToStringValueConverter();
            for (const sector in HealthcareSectors)
                options.push({
                    text: this.t.tr(formatter.toView(sector as HealthcareSectors)),
                    value: sector
                });
        }

        if (this.mode === 'input') {
            // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
            this.allOptions = Object.assign([], JSON.parse(JSON.stringify(this.values.map((tag) => ({ value: tag, text: tag, data: null })))));
        } else this.allOptions = Object.assign([], JSON.parse(JSON.stringify(this.type === 'healthcare-sectors' ? options : optionsToUse)));

        // Set the selected options.
        this.selected = this.allOptions.filter((x) => this.values.some((value) => x.value === value));

        // Filter out already selected options.
        if (this.type === 'healthcare-sectors') this.options = options.filter((x) => this.values.every((value) => value !== x.value));
        else this.options = optionsToUse;
        // .filter((x) => this.values.every((value) => value !== x.value));
    }
}
