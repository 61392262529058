import { guid, isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject } from 'aurelia';
import { addMonths, addYears, format, getDaysInMonth, getMonth, getWeek, getYear, isValid, parse, setDayOfYear, setMonth, setYear, subMonths, subYears } from 'date-fns';
import IMask, { InputMask, MaskedRange } from 'imask';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(IEventAggregator, Element)
export class UxDatepicker {
    @bindable() public value: Date | undefined;
    @bindable() public data: any;
    @bindable() public debounce: number = 400;
    @bindable() public disabled: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public allowClear: boolean = false;
    @bindable() public rounded: 'left' | 'right' | 'full' = 'full';
    @bindable() public padding: 'small' | 'default' = 'default';
    @bindable() public isLoading: boolean = false;
    @bindable() public placeholder: string = 'Selecteer een optie';
    @bindable() public showFooter: boolean = false;
    @bindable() public allowCustomInput: boolean = false;
    @bindable() public focused: number = -1;
    @bindable() public offset: number = 250;
    @bindable() public used: string[] = [];
    @bindable() public mode: 'display' | 'input' = 'display';
    @bindable() public autofocus: boolean = false;
    @bindable() public focusDelay: number = 500;

    public isVisible: boolean = false;
    public hasFocus: boolean = false;
    public container: HTMLDivElement;
    public label: string;
    public format: string = 'dd-MM-yyyy';
    public placement: 'top' | 'bottom' = 'bottom';
    public currentDate: Date = new Date();
    public trigger: HTMLButtonElement;
    public daysOfPreviousMonth: Date[];
    public daysOfCurrentMonth: Date[];
    public daysOfNextMonth: Date[];
    public rows: Date[][] = [];
    public today: Date = new Date();
    public weeks: number[];
    public labels: string[] = [
        'translation:global.days.short.week', //
        'translation:global.days.short.monday',
        'translation:global.days.short.tuesday',
        'translation:global.days.short.wednesday',
        'translation:global.days.short.thursday',
        'translation:global.days.short.friday',
        'translation:global.days.short.saturday',
        'translation:global.days.short.sunday'
    ];

    private id: string = guid();
    private subscriptions: IDisposable[];
    private mask: InputMask<any>;
    private onClick: (e: MouseEvent) => void;

    public constructor(
        private readonly events: IEventAggregator,
        private readonly host: HTMLElement
    ) {}

    public bound(): void {
        if (isDefined(this.value)) {
            this.currentDate = this.value;
            if (this.mode !== 'input') this.label = format(this.value, this.format);
        }

        setTimeout(() => {
            if (this.autofocus && isDefined(this.trigger) && this.trigger instanceof HTMLInputElement) setTimeout(() => this.trigger.focus(), this.focusDelay);

            if (isDefined(this.value)) if (this.mode === 'input') this.trigger.value = format(this.value, this.format);
            this.renderPicker();

            if (this.mode === 'input' && isDefined(this.trigger)) {
                this.mask = IMask(this.trigger, {
                    mask: 'd-m-Y',
                    lazy: false,
                    overwrite: true,
                    autofix: true,
                    blocks: {
                        d: {
                            mask: MaskedRange,
                            placeholderChar: 'd',
                            from: 1,
                            to: 31,
                            maxLength: 2
                        },
                        m: {
                            mask: MaskedRange,
                            placeholderChar: 'm',
                            from: 1,
                            to: 12,
                            maxLength: 2
                        },
                        Y: {
                            mask: MaskedRange,
                            placeholderChar: 'y',
                            from: 1900,
                            to: 2999,
                            maxLength: 4
                        }
                    }
                });
                this.mask.on('complete', this.maskCompleted);
            }
        });

        // Listen to all select option clicks and
        // hide the select if one the option, which
        // is a child of this select, is clicked.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(UxEvents.UxDatepickerOpened, (data: { id: string }) => {
                if (data.id === this.id) return;
                // Hide other select.
                this.isVisible = false;
            })
        ];

        // Listen to all global clicks and hide the select
        // if the click is not on this select.
        this.onClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const picker = target.closest('ux-datepicker');
            if (isNotDefined(picker)) this.isVisible = false;
        };
        document.addEventListener('click', this.onClick);
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        document.removeEventListener('click', this.onClick);
        if (isDefined(this.mask)) this.mask.destroy();
    }

    public async open(): Promise<void> {
        this.isVisible = true;
    }

    public async close(): Promise<void> {
        this.isVisible = false;
    }

    public async clear(): Promise<void> {
        this.value = null;
        this.label = null;
        if (isDefined(this.trigger)) this.trigger.value = '';
        this.emit(
            UxEvents.OnClear,
            new EventDetails<UxDatepicker, void>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: null
            })
        );
        this.renderPicker();
    }

    public handleFocus(): void {
        this.hasFocus = true;
        if (this.mode === 'input') (this.trigger as HTMLInputElement).select();
    }

    public handleBlur(): void {
        this.hasFocus = false;
    }

    public toggleVisibility(_: MouseEvent): void {
        if (isDefined(this.container)) {
            const pos = this.container.getBoundingClientRect();
            const offsetBottom = window.innerHeight - pos.bottom;
            // Decide whether the dropdown should place on the top or bottom.
            if (offsetBottom < this.offset) this.placement = 'top';
            else this.placement = 'bottom';
        } else this.placement = 'bottom';

        this.isVisible = !this.isVisible;
        if (!this.isVisible) return;

        this.events.publish(UxEvents.UxDatepickerOpened, { id: this.id });
    }

    public handleClear(): void {
        this.clear();
    }

    public isToday(day: Date): boolean {
        return format(day, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
    }

    public isSelected(day: Date): boolean {
        if (isNotDefined(this.value)) return false;
        return format(day, 'yyyyMMdd') === format(this.value, 'yyyyMMdd');
    }

    public isInCurrentMonth(day: Date): boolean {
        return format(day, 'yyyy-MM') === format(this.currentDate, 'yyyy-MM');
    }

    public handleNextYear(): void {
        this.currentDate = addYears(this.currentDate, 1);
        this.renderPicker();
    }

    public handlePreviousYear(): void {
        this.currentDate = subYears(this.currentDate, 1);
        this.renderPicker();
    }

    public handleNextMonth(): void {
        this.currentDate = addMonths(this.currentDate, 1);
        this.renderPicker();
    }

    public handlePreviousMonth(): void {
        this.currentDate = subMonths(this.currentDate, 1);
        this.renderPicker();
    }

    public handleSelect(e: MouseEvent, date: Date, hide: boolean = true): void {
        if (this.disabled) return;

        if (isDefined(e)) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }

        if (isDefined(date)) {
            this.label = format(date, this.format);
            this.value = date;
            this.currentDate = date;
        } else this.value = null as Date;

        this.emit(
            UxEvents.OnSelect,
            new EventDetails<UxDatepicker, any>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: { date }
            })
        );

        if (hide) this.close();
        this.renderPicker();
    }

    public isDefined(value: any): boolean {
        return isDefined(value);
    }

    public isNotDefined(value: any): boolean {
        return isNotDefined(value);
    }

    private generateRow(rowNumber: number): Date[] {
        const amountOfDaysPerWeek = 7;

        // Check if we need days from the previous month, by checking which
        // day (mo, tue, wed etc.) the first of the current month is. Substract
        // one because we need the amount of days BEFORE the first day of the month.
        const firstDayOfCurrentMonth = parse(`${this.currentDate.getFullYear()}-${this.currentDate.getMonth() + 1}-1`, 'yyyy-MM-dd', new Date());
        let amountOfDaysToTake = firstDayOfCurrentMonth.getDay() === 0 ? 6 : firstDayOfCurrentMonth.getDay() - 1;

        // Make sure we don't have negative numbers.
        if (amountOfDaysToTake < 0) amountOfDaysToTake = 0;

        if (rowNumber === 0) {
            // Fetch the days we need from the end of the previous month by using the amount of days to take variable.
            const daysOfPreviousMonth = this.daysOfPreviousMonth.slice(this.daysOfPreviousMonth.length - amountOfDaysToTake, this.daysOfPreviousMonth.length);
            // Hoin the days of the previous month we took, with the current month for the first row.
            // Note that the days of the previous month need to come BEFORE the days of the current month.
            return daysOfPreviousMonth.concat(this.daysOfCurrentMonth.slice(0, amountOfDaysPerWeek - daysOfPreviousMonth.length));
        }

        // Because we might have some days of the previous month we could have an offset
        // we need to use when calculating the days for the other rows.
        const offset = 7 - amountOfDaysToTake;

        if (rowNumber === 1)
            // These row days needs to be calculated based on the amount of days
            // we took from the previous month.
            return this.daysOfCurrentMonth.slice(offset, offset + amountOfDaysPerWeek);

        if (rowNumber > 1 && rowNumber < 4) {
            // These rows always contain days from the current month.
            // Calculate which ones we need and add them to the row.
            const amountOfDays = amountOfDaysPerWeek * rowNumber;
            return this.daysOfCurrentMonth.slice(amountOfDays - amountOfDaysToTake, offset + amountOfDays);
        }

        if (rowNumber === 4) {
            const amountOfDays = amountOfDaysPerWeek * rowNumber - amountOfDaysToTake;
            const lastDaysOfCurrentMonth = this.daysOfCurrentMonth.slice(amountOfDays, this.daysOfCurrentMonth.length);

            if (lastDaysOfCurrentMonth.length > amountOfDaysPerWeek)
                // We still have 7 days left of the current month so add them to the fifth row.
                return lastDaysOfCurrentMonth.slice(0, amountOfDaysPerWeek);
            else {
                // We have some days (less than 7) of the current month left to add, so calculate which
                // days we need from the next month and add them
                const firstDaysOfNextMonth = this.daysOfNextMonth.slice(0, amountOfDaysPerWeek - lastDaysOfCurrentMonth.length);
                return lastDaysOfCurrentMonth.concat(firstDaysOfNextMonth);
            }
        }

        if (rowNumber === 5) {
            const amountOfDays = amountOfDaysPerWeek * rowNumber - amountOfDaysToTake;
            const lastDaysOfCurrentMonth = this.daysOfCurrentMonth.slice(amountOfDays, this.daysOfCurrentMonth.length);

            if (lastDaysOfCurrentMonth.length === 0) {
                // We have no days of the current month left. Check if we already used some
                // days of the next month and continue on the last row.
                const amountOfDaysAlreadyTakenFromNextMonth = amountOfDays - this.daysOfCurrentMonth.length;
                return this.daysOfNextMonth.slice(amountOfDaysAlreadyTakenFromNextMonth, amountOfDaysAlreadyTakenFromNextMonth + amountOfDaysPerWeek);
            } else {
                // We have some days of the current month left to add, so calculate which
                // days we need from the next month and add them
                const firstDaysOfNextMonth = this.daysOfNextMonth.slice(0, amountOfDaysPerWeek - lastDaysOfCurrentMonth.length);
                return lastDaysOfCurrentMonth.concat(firstDaysOfNextMonth);
            }
        }
    }

    private setWeeks(): void {
        this.weeks = [
            getWeek(this.rows[0][0]), //
            getWeek(this.rows[1][0]),
            getWeek(this.rows[2][0]),
            getWeek(this.rows[3][0]),
            getWeek(this.rows[4][0]),
            getWeek(this.rows[5][0])
        ];
    }

    private setDaysOfPreviousMonth(): void {
        this.daysOfPreviousMonth = [];
        let month = getMonth(this.currentDate);
        let year = getYear(this.currentDate);

        // Check if the current month is the first month of the year,
        // if so switch to the last month of the previous year.
        if (month === 0) {
            month = 11;
            year = year - 1;
        } else month = month - 1;

        const previousMonth = setYear(setMonth(setDayOfYear(new Date(), 1), month), year);
        // setDayOfYear(new Date(), 1).set('month', month).set('year', year);
        const totalDays = getDaysInMonth(previousMonth);
        for (let i = 1; i <= totalDays; i++) {
            const day = setYear(setMonth(setDayOfYear(previousMonth, i), month), year);
            this.daysOfPreviousMonth.push(day);
        }
    }

    private setDaysOfCurrentMonth(): void {
        this.daysOfCurrentMonth = [];
        const month = getMonth(this.currentDate);
        const year = getYear(this.currentDate);

        const currentMonth = setYear(setMonth(setDayOfYear(new Date(), 1), month), year);
        const totalDays = getDaysInMonth(this.currentDate);
        for (let i = 1; i <= totalDays; i++) {
            const day = setYear(setMonth(setDayOfYear(currentMonth, i), month), year);
            this.daysOfCurrentMonth.push(day);
        }
    }

    private setDaysOfNextMonth(): void {
        this.daysOfNextMonth = [];
        let month = getMonth(this.currentDate);
        let year = getYear(this.currentDate);

        // Check if the current month is the last of the year,
        // if so swtich to the first month of the next year.
        if (month === 11) {
            month = 0;
            year = year + 1;
        } else month = month + 1;

        const nextMonth = setYear(setMonth(setDayOfYear(new Date(), 1), month), year);
        const totalDays = getDaysInMonth(nextMonth);
        for (let i = 1; i <= totalDays; i++) {
            const day = setYear(setMonth(setDayOfYear(nextMonth, i), month), year);
            this.daysOfNextMonth.push(day);
        }
    }

    private renderPicker(): void {
        this.setDaysOfPreviousMonth();
        this.setDaysOfNextMonth();
        this.setDaysOfCurrentMonth();
        this.rows = [
            this.generateRow(0), //
            this.generateRow(1),
            this.generateRow(2),
            this.generateRow(3),
            this.generateRow(4),
            this.generateRow(5)
        ];

        this.setWeeks();
    }

    private maskCompleted = () => {
        const parsed = parse(this.mask.value, this.format, new Date());
        if (!isValid(parsed)) return;

        this.currentDate = parse(this.mask.value, this.format, new Date());

        if (isDefined(this.currentDate)) {
            this.label = format(this.currentDate, this.format);
            this.value = this.currentDate;
            this.currentDate = this.currentDate;
        } else this.value = null as Date;

        this.emit(
            UxEvents.OnSelect,
            new EventDetails<UxDatepicker, any>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: { date: this.value }
            })
        );

        this.renderPicker();
    };

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }

    /**
     * Fired when @value changes.
     */
    public valueChanged(): void {
        if (isDefined(this.value)) {
            this.currentDate = this.value;
            if (this.mode !== 'input') this.label = format(this.value, this.format);
        }
    }
}
