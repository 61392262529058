import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, AttachmentsApiClient, FileResponse } from '@wecore/sdk-attachments';

import { UsersApiClient, WorkspacesApiClient } from '@wecore/sdk-management';
import { isDefined, serveBlob } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AttachmentsApiClient, WorkspacesApiClient, UsersApiClient)
export class PartialDocumentsPreview extends BasePartialView {
    public src: string;
    public failed: boolean = false;
    public error: string;
    public attachmentExtension: string;
    public attachmentName: string;
    public blob: FileResponse;
    public blobCallback: () => Promise<[string, string, FileResponse]>;
    public blobFilename: string;
    public canDownload: boolean;

    private attachmentId: string;
    private entityId: string;
    private entityType: AttachmentEntities;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly usersApi: UsersApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        this.entityId = view.data.entityId;
        this.entityType = view.data.entityType;
        this.attachmentId = view.data.attachmentId;
        this.attachmentName = view.data.attachmentName;
        this.attachmentExtension = view.data.attachmentExtension;
        this.blobCallback = view.data.blobCallback;
        this.canDownload = view.data.canDownload ?? true;

        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                try {
                    if (isDefined(this.blobCallback)) {
                        const [filename, extension, blob] = await this.blobCallback();
                        this.blob = blob;
                        this.blobFilename = filename;
                        this.attachmentExtension = extension;
                        this.src = URL.createObjectURL(this.blob.data);
                    } else {
                        let response: string;
                        switch (this.entityType) {
                            case AttachmentEntities.Workspaces:
                                response = await this.workspacesApi.getAttachmentUrl(this.entityId, this.attachmentId);
                                break;
                            case AttachmentEntities.Users:
                                response = await this.usersApi.getAttachmentUrl(this.entityId, this.attachmentId);
                                break;
                            default:
                                response = await this.attachmentsApi.getUrl(this.entityId, this.attachmentId, this.authenticated.workspace.id, this.entityType);
                                break;
                        }
                        this.src = response;
                    }
                } catch (e) {
                    this.failed = true;
                    this.error = e;
                }
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialsDocumentsPreview.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok
        });
    }

    public async download(): Promise<void> {
        this.isLoading = true;
        try {
            let blob: FileResponse;
            let filename: string;

            if (isDefined(this.blob)) {
                blob = this.blob;
                filename = this.blobFilename;
            } else {
                switch (this.entityType) {
                    case AttachmentEntities.Workspaces:
                        blob = await this.workspacesApi.downloadAttachment(this.entityId, this.attachmentId);
                        filename = `${this.authenticated.workspace.id}-${this.attachmentName}${this.attachmentExtension}`;
                        break;
                    default:
                        blob = await this.attachmentsApi.download(this.entityId, this.attachmentId, this.authenticated.workspace.id, this.entityType);
                        filename = `${this.attachmentName}${this.attachmentExtension}`;
                        break;
                }
            }

            serveBlob(blob.data, filename);
        } catch (e) {
            this.errorHandler.handle('document-detail-download', e);
        }
        this.isLoading = false;
    }
}
