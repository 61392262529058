import { ResultTypes } from '@wecore/sdk-healthcare';

export class ResultTypeToStringValueConverter {
    public toView(type: ResultTypes): string {
        switch (type) {
            case ResultTypes.Number:
                return 'global.labels.number';
            case ResultTypes.Text:
                return 'global.labels.text';
            case ResultTypes.Date:
                return 'global.labels.date';
            case ResultTypes.File:
                return 'global.labels.file';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
