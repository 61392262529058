<import from="converters/translate" />
<import from="converters/delta-to-html" />
<import from="converters/time-ago" />
<import from="../template-post-input/template-post-input" />
<import from="../template-post-reply/template-post-reply" />
<import from="../template-post-attachments/template-post-attachments" />

<template>
    <div class="flex flex-col">
        <div
            border-b.class="editMode"
            rounded-b-lg.class="editMode && replies.length === 0 && uploads.length === 0 && post.attachments.length === 0"
            class="flex flex-col rounded-t-lg border-x border-t border-gray-300 p-5">
            <div class="flex items-center justify-between gap-3">
                <div class="flex items-center gap-2">
                    <ux-avatar
                        size="sm"
                        name.bind="post.createdBy.name"
                        email.bind="post.createdBy.data.email"
                        color.bind="post.createdBy.data.avatarColor"
                        shade.bind="post.createdBy.data.avatarShade"></ux-avatar>
                    <p class="text-sm text-gray-800">${post.createdBy.name}</p>
                </div>
                <div class="flex items-center gap-2">
                    <div class="flex items-center gap-1 text-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p class="w-6 text-xs">${post.createdAt | timeAgo:true:false:true & signal:'update-timeago'}</p>
                    </div>
                    <ux-dropdown border.bind="false" if.bind="authenticated.id === post.createdBy.id" padding.bind="false" mode="outline" color="dark" placement="bottom-right">
                        <div au-slot="label" class="flex gap-1 text-gray-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                            </svg>
                        </div>
                        <ux-dropdown-option if.bind="authenticated.id === post.createdBy.id" click.trigger="edit()">
                            <span t="translation:global.buttons.edit"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-option if.bind="authenticated.id === post.createdBy.id && replies.length === 0" click.trigger="delete()">
                            <span class="text-red-500" t="translation:global.buttons.delete"></span>
                        </ux-dropdown-option>
                    </ux-dropdown>
                </div>
            </div>
            <div class="mt-5 flex flex-col">
                <template-post-input
                    if.bind="hasRole(UserRoles.WriteHealthcareTasks)"
                    component.ref="input"
                    show.bind="editMode"
                    is-visible.bind="editMode"
                    edit.bind="true"
                    content.bind="post.content"
                    on-shift-enter.bind="handleSave"
                    on-escape.bind="handleCancel"
                    is-reacting.bind="true"
                    workspace.bind="workspace"
                    language.bind="language">
                </template-post-input>
                <div if.bind="editMode || uploads.length > 0" class="my-2 flex justify-between gap-2">
                    <div class="flex justify-start gap-2">
                        <ux-button click.trigger="selectFile()" if.bind="authenticated.id === post.createdBy.id" size="xs" color="dark" mode="outline" padding.bind="false" border.bind="false">
                            <div class="flex items-center gap-1 px-1 text-gray-500 hover:text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span class="text-xs" t="translation:global.buttons.add-attachment"></span>
                            </div>
                        </ux-button>
                    </div>
                    <div class="flex justify-end gap-2">
                        <ux-button click.trigger="handleCancel()" size="xs" color="dark" mode="outline">
                            <span t="translation:global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button click.trigger="handleSave()" size="xs" color="primary">
                            <span t="translation:global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
            <div
                if.bind="!editMode"
                class="prose flex min-w-full flex-1 text-sm prose-headings:my-0 prose-p:my-0 prose-blockquote:mb-0 prose-pre:mb-0 prose-ol:mb-0 prose-ul:mb-0 prose-li:my-0 prose-img:my-0 prose-video:my-0">
                <div if.bind="post" class="flex-1" innerhtml.bind="post.content | deltaToHtml"></div>
            </div>
        </div>
        <template-post-attachments
            show.bind="post.attachments.length > 0 || uploads.length > 0"
            component.ref="picker"
            post.bind="post"
            border-top.bind="!editMode "
            border-bottom.bind="editMode"
            rounded-bottom.bind="(editMode && post.attachments.length === 0 && replies.length === 0) || (editMode && replies.length === 0)"
            uploads.two-way="uploads"
            attachments.bind="post.attachments"
            workspace.bind="workspace"
            on-attachment-delete.bind="handleAttachmentDelete"
            can-delete.bind="authenticated.id === post.createdBy.id">
        </template-post-attachments>
        <div if.bind="!editMode" rounded-b-lg.class="!replyMode && replies.length === 0 && baseLoaded" class="flex border border-gray-300 hover:bg-gray-50 active:bg-gray-100">
            <button if.bind="hasRole(UserRoles.WriteHealthcareTasks)" click.trigger="addReply()" class="flex flex-1 items-center justify-center gap-2 py-2.5 text-sm text-gray-500">
                <template if.bind="loading">
                    <ux-spinner size="xs"></ux-spinner>
                </template>
                <template else>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.75 11L11.25 4.75V9.25C19.25 9.25 19.25 15 19.25 19.25C17 13 11.25 12.75 11.25 12.75V17.25L4.75 11Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                    <span t="translation:global.buttons.reply"></span>
                </template>
            </button>
            <div else class="py-5"></div>
        </div>
        <div if.bind="replyMode || replies.length > 0 || !baseLoaded" class="flex flex-col rounded-b-lg border-x border-b border-gray-300 p-5">
            <div if.bind="baseLoaded" class="flex flex-col">
                <div class="flex flex-col gap-5">
                    <template-post-reply
                        repeat.for="reply of replies"
                        edit-mode.two-way="isEditingReply[$index]"
                        post.bind="reply"
                        authenticated.bind="authenticated"
                        on-delete.bind="handleDeleteReply"
                        workspace.bind="workspace"
                        language.bind="language">
                    </template-post-reply>
                </div>
                <div show.bind="isEditingReply.every((x) => !x)" mt-2.class="replies.length > 0" class="flex flex-col">
                    <div mt-3.class="replies.length > 0" class="mb-2 flex items-start gap-3">
                        <ux-avatar
                            size="sm"
                            name.bind="authenticated.name"
                            email.bind="authenticated.email"
                            color.bind="authenticated.avatar.color"
                            shade.bind="authenticated.avatar.shade"></ux-avatar>
                        <div class="flex w-full flex-col gap-0.5">
                            <template-post-input
                                on-shift-enter.bind="saveReply"
                                on-escape.bind="handleCancelReply"
                                component.ref="replyInput"
                                valid.bind="validation.reply"
                                content.two-way="reply"
                                show-toolbar.bind="false"
                                is-reacting.two-way="isReplying"
                                workspace.bind="workspace"
                                language.bind="language">
                            </template-post-input>
                        </div>
                    </div>
                    <div class="flex justify-end gap-1" if.bind="isReplying">
                        <ux-button click.trigger="handleCancelReply()" size="xs" color="dark" mode="outline">
                            <span t="translation:global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button click.trigger="saveReply()" disabled.bind="!reply" size="xs" color="primary">
                            <span t="translation:global.buttons.reply"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
            <div class="flex flex-col" else>
                <ux-skeleton show-background.bind="false" type="container">
                    <div class="flex w-full flex-col gap-3 px-2">
                        <ux-skeleton type="line"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    </div>
                </ux-skeleton>
            </div>
        </div>
    </div>
</template>
