import { MedicalExaminationActionFlow, MedicalExaminationActionItem, MedicalExaminationActionItemTypes } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { generateColumns } from '../../../../../infra/utilities';

@containerless
@inject(IEventAggregator)
export class TemplateActionCategory {
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public language: string;
    @bindable() public index: string;

    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public columns: string;

    private subscriptions: any[];

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.setColumns();

        if (isNotDefined(this.item.attributes)) this.item.attributes = {};

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepSettingsChanged, () => {
                this.setColumns();
            }),
            this.events.subscribe(CustomEvents.ActionCategoryChanged, (data: { stepId: string; expand: boolean }) => {
                const shouldChange = this.flow.connectedCategories //
                    .filter((x) => x.key === this.item.id)
                    .some((x) => x.value === data.stepId);
                if (shouldChange) this.item.attributes.expanded = data.expand;
            })
        ];
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.item.attributes.expanded = !this.item.attributes.expanded;
        this.events.publish(CustomEvents.ActionCategoryChanged, {
            stepId: this.item.id, //
            expand: this.item.attributes.expanded
        });
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.id) || [] //
        );
    };
}
