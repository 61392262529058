import { isFunction, isNotEmpty } from '@wecore/sdk-utilities';
import { bindable } from 'aurelia';

export class UxImage {
    @bindable() public src: string;

    @bindable() public getImage: (data: any) => Promise<string>;
    @bindable() public type: 'img' | 'background' = 'background';
    @bindable() public alt: string;
    @bindable() public color: 'primary' | 'success' | 'info' | 'warning' | 'gray' = 'gray';
    @bindable() public data: any;
    @bindable() public border: boolean = false;
    @bindable() public class: string;
    @bindable() public height: string = '200px';
    @bindable() public width: string = '200px';
    @bindable() public bgPosition: string = 'center center';
    @bindable() public bgSize: 'cover' | 'contain' = 'cover';
    @bindable() public bgRepeat: string = 'no-repeat';

    public bound(): void {
        this.load(null);
    }

    public async load(src: string): Promise<void> {
        // If we have an async request available
        // use it to load the src property.
        if (isNotEmpty(src)) this.src = src;
        else if (isFunction(this.getImage)) this.src = await this.getImage(this.data);
    }
}
