import { PriorityTypes } from '@wecore/sdk-healthcare';

export class PriorityTextColorValueConverter {
    public toView(type: PriorityTypes): string {
        switch (type) {
            case PriorityTypes.Low:
                return 'text-blue-800';
            case PriorityTypes.Medium:
                return 'text-yellow-800';
            case PriorityTypes.High:
                return 'text-orange-800';
            case PriorityTypes.Urgent:
                return 'text-red-800';
            default:
                return 'text-gray-800';
        }
    }
}
