import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { CreateVoxelcareOrderRequest, VoxelcareApiClient } from '@wecore/sdk-integrations';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../../../../infra/error-handler';
import { CustomEvents } from '../../../../../infra/events';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { ModalService } from '../../../../../services/service.modals';
import { NotificationService } from '../../../../../services/service.notifications';

@containerless
@inject(VoxelcareApiClient, ModalService, I18N, ErrorHandler, IEventAggregator, NotificationService)
export class WidgetVoxelcare {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public orderId: string;
    private referenceKey: string = 'voxelcare.order.id';

    public constructor(
        private readonly voxelcare: VoxelcareApiClient, //
        private readonly modalService: ModalService,
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler,
        private events: IEventAggregator,
        private notifications: NotificationService
    ) {}

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        this.registration.widget.result.type = WidgetResultTypes.None;
        this.orderId = this.record.externalReferences[this.referenceKey];
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.VoxelcareOrder,
                onSave: async (): Promise<void> => {},
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }

    public async startOrder(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:components.widgets.voxelcare.questions.create-order.title'),
                message: this.t.tr('translation:components.widgets.voxelcare.questions.create-order.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.start'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            const response = await this.voxelcare.createOrder(
                                this.workspace,
                                new CreateVoxelcareOrderRequest({
                                    patient: this.patient.id,
                                    medicalRecord: this.record.id
                                })
                            );

                            // Store the order id in the medical record.
                            this.record.externalReferences[this.referenceKey] = response.id;
                            this.notifications.show(
                                this.t.tr('translation:components.widgets.voxelcare.notifications.order-started-successfully.title'),
                                this.t
                                    .tr('translation:components.widgets.voxelcare.notifications.order-started-successfully.message') //
                                    .replace('{id}', `<span>'${response.id}'</span>`),
                                { type: 'success', duration: 3000 }
                            );

                            this.events.publish(CustomEvents.MedicalRecordUpdated, {
                                record: this.record
                            });
                        } catch (e) {
                            this.errorHandler.handle('[widget-voxelcare-order]', e);
                        }
                    }
                }
            })
        );
    }
}
