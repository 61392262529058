<import from="converters/keys" />
<import from="bx/bx-address-selector/bx-address-selector" />
<import from="converters/healthcare-sector-to-string" />
<import from="converters/filter-document-versions" />
<import from="converters/file-size" />
<import from="converters/currency" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex h-full flex-1 flex-col">
            <template if.bind="baseLoaded">
                <section class="flex h-full flex-col">
                    <ux-header padding="py-5 px-8">
                        <div class="flex items-center justify-between">
                            <div class="flex flex-col">
                                <h6 class="mb-0 leading-tight" t="translation:partial-views.manage-practice.labels.title"></h6>
                                <span class="block text-sm leading-tight text-gray-500" t="translation:partial-views.manage-practice.messages.manage-settings"></span>
                            </div>
                        </div>
                    </ux-header>
                    <div class="flex h-full overflow-hidden pl-3">
                        <div class="flex h-full flex-1 flex-col overflow-hidden pr-5 sm:flex-row sm:pr-0">
                            <div class="flex w-44 overflow-hidden border-r border-gray-200 py-6 pl-5">
                                <ul class="flex w-full items-center gap-x-3 sm:flex-col sm:items-start sm:space-x-0 sm:space-y-3">
                                    <li class="group w-full pr-3" repeat.for="key of tabs | keys">
                                        <a
                                            class="${ tabs[key].valid ? tabs[key].active ? 'text-gray-900 font-semibold': 'text-gray-500 group-hover:text-gray-900': tabs[key].active ? 'text-red-500 font-semibold':'text-red-500 group-hover:text-red-600' } relative"
                                            href="javascript:"
                                            click.trigger="setActiveTab(key)">
                                            <div class="flex items-center space-x-1">
                                                <span class="text-sm">${ tabs[key].name }</span>
                                                <svg if.bind="!tabs[key].valid" class="h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                                </svg>
                                                <div
                                                    if.bind="tabs[key].valid && key === 'legal' && unsigned.companies.find((x) => x.id === workspace.id).versions.length > 0"
                                                    class="absolute right-0 ml-1 flex size-[18px] items-center justify-center rounded-lg bg-red-500 text-xxs leading-none text-white">
                                                    <p class="relative leading-none">${unsigned.companies.find((x) => x.id === workspace.id).versions.length}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="flex flex-1 flex-col gap-8 overflow-hidden">
                                <!-- General tab -->
                                <div show.bind="tabs.general.active" class="flex flex-col gap-8 overflow-y-auto px-10 py-6">
                                    <!-- Practice settings -->
                                    <div class="border-b border-gray-900/10 pb-12">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.practice"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.practice-settings"></p>
                                        <div class="mt-6 grid grid-cols-12 gap-6">
                                            <div class="col-span-12 flex flex-col gap-2">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.name"></label>
                                                <div class="flex flex-col gap-1">
                                                    <ux-input value.two-way="workspace.name" valid.bind="validation.name"></ux-input>
                                                    <p class="pl-1 text-xs text-gray-500">
                                                        <span t="translation:partial-views.manage-practice.labels.workspace-id"></span>
                                                        <span> ${workspace.id}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-span-full">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.logo"></label>
                                                <div class="mt-2 flex items-center gap-x-5">
                                                    <ux-image if.bind="logoSrc" bg-size="contain" width="135px" height="135px" border.bind="true" src.bind="logoSrc"></ux-image>
                                                    <div else class="flex h-[130px] w-[130px] min-w-[130px] items-center justify-center rounded-sm border border-gray-100 bg-gray-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-gray-300">
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                                        </svg>
                                                    </div>
                                                    <div class="flex flex-col gap-2">
                                                        <input change.trigger="handleFilesSelected($event)" type="file" class="hidden" ref="fileInput" accept=".jpg,.jpeg,.png" multiple="0" />
                                                        <div class="flex gap-2">
                                                            <ux-button color="primary" click.trigger="selectLogo()">
                                                                <span t="translation:global.labels.change"></span>
                                                            </ux-button>
                                                            <ux-button if.bind="logoSrc" color="danger" click.trigger="deleteLogo()">
                                                                <span t="translation:global.buttons.delete"></span>
                                                            </ux-button>
                                                        </div>
                                                        <p class="text-xs text-gray-500" t="translation:partial-views.manage-practice.messages.logo"></p>
                                                        <ux-button mode="outline" color="dark" click.trigger="showExample()">
                                                            <span t="translation:partial-views.manage-practice.labels.show-example"></span>
                                                        </ux-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.customer-number"></label>
                                                <div class="mt-2">
                                                    <div class="flex h-[38px] w-full items-center rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-800">
                                                        <span>${workspace.externalId}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.chamber-of-commerce"></label>
                                                <div class="mt-2">
                                                    <ux-input value.two-way="workspace.coc" valid.bind="validation.coc"></ux-input>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.sector"></label>
                                                <div class="mt-2">
                                                    <ux-select value.two-way="workspace.extensions.healthcare.sector" allow-clear.bind="true">
                                                        <ux-select-option repeat.for="sector of HealthcareSectors | keys" value.bind="sector">
                                                            <span>${sector | healthcareSectorToString | t} </span>
                                                        </ux-select-option>
                                                    </ux-select>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.agb-code"></label>
                                                <div class="mt-2">
                                                    <ux-input value.two-way="workspace.extensions.healthcare.agbCode" valid.bind="validation.agbCode"></ux-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Contact information -->
                                    <div class="border-gray-900/10">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:partial-views.manage-practice.labels.contact-info"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.contact-information"></p>

                                        <div class="mt-10 grid grid-cols-12 gap-6">
                                            <div class="col-span-4">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:partial-views.manage-practice.labels.primary-contact-firstname"></label>
                                                <div class="mt-2">
                                                    <ux-input value.two-way="workspace.primaryContactFirstName" valid.bind="validation.primaryContactFirstName"></ux-input>
                                                </div>
                                            </div>
                                            <div class="col-span-4">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:partial-views.manage-practice.labels.primary-contact-lastname"></label>
                                                <div class="mt-2">
                                                    <ux-input value.two-way="workspace.primaryContactLastName" valid.bind="validation.primaryContactLastName"></ux-input>
                                                </div>
                                            </div>
                                            <div class="col-span-4">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.phone-number"></label>
                                                <div class="mt-2">
                                                    <ux-input mask="0000000000" value.two-way="workspace.phone" valid.bind="validation.phone && validation.phoneValid"> </ux-input>
                                                    <p if.bind="!validation.phone || !validation.phoneValid" class="mt-1 text-xs text-red-500" t="translation:global.errors.invalid-phonenumber"></p>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.email"></label>
                                                <div class="mt-2">
                                                    <ux-input value.two-way="workspace.email" valid.bind="validation.email && validation.emailValid"></ux-input>
                                                    <p if.bind="!validation.email || !validation.emailValid" class="mt-1 text-xs text-red-500" t="translation:global.errors.invalid-email"></p>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:partial-views.manage-practice.labels.email-invoices"></label>
                                                <div class="mt-2">
                                                    <ux-input value.two-way="workspace.invoiceEmail" valid.bind="validation.invoiceEmail && validation.invoiceEmailValid"></ux-input>
                                                    <p
                                                        if.bind="!validation.invoiceEmail || !validation.invoiceEmailValid"
                                                        class="mt-1 text-xs text-red-500"
                                                        t="translation:global.errors.invalid-email"></p>
                                                </div>
                                            </div>
                                            <div class="col-span-12">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.address"></label>
                                                <div class="mt-2">
                                                    <bx-address-selector
                                                        valid.bind="validation.address"
                                                        descriptions.bind="descriptions"
                                                        language.bind="language"
                                                        on-select.bind="handleAddressSelected"
                                                        max-amount.bind="1"
                                                        use-labels.bind="false"
                                                        addresses.bind="addresses"
                                                        t="[label]translation:partial-views.manage-practice-locations.labels.address">
                                                    </bx-address-selector>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Templates tab -->
                                <div show.bind="tabs.templates.active" class="flex flex-col gap-8 overflow-y-auto px-10 py-6">
                                    <div class="flex flex-col">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.templates"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.templates"></p>

                                        <div class="mt-6 grid grid-cols-12 gap-6">
                                            <div class="col-span-12 grid grid-cols-12 gap-6">
                                                <div class="col-span-12">
                                                    <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.invoices"></label>
                                                    <div class="mt-2">
                                                        <ux-input
                                                            value.two-way="workspace.extensions.healthcare.invoiceTemplate"
                                                            valid.bind="validation.invoiceTemplate"
                                                            onblur.trigger="handleInvoiceTemplateBlur($event)"></ux-input>
                                                        <div class="mt-1 flex items-center gap-1">
                                                            <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.manage-practice.messages.templates-details"></p>
                                                            <div class="flex text-xs">
                                                                <a
                                                                    href="javascript:"
                                                                    click.trigger="addTag('id', 'invoiceTemplate')"
                                                                    class="text-primary underline"
                                                                    t="translation:global.labels.unique-tracking-number"></a>
                                                                <span class="mr-1 block">,</span>
                                                                <a
                                                                    href="javascript:"
                                                                    click.trigger="addTag('year', 'invoiceTemplate')"
                                                                    class="text-primary underline"
                                                                    t="translation:global.labels.year"></a>
                                                            </div>
                                                        </div>
                                                        <p
                                                            if.bind="!validation.invoiceTemplate"
                                                            class="mt-2 text-xs text-red-500"
                                                            t="translation:partial-views.manage-practice.errors.invalid-template"></p>
                                                    </div>
                                                </div>
                                                <div class="col-span-12">
                                                    <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.declarations"></label>
                                                    <div class="mt-2 flex flex-col">
                                                        <ux-input
                                                            value.two-way="workspace.extensions.healthcare.declarationTemplate"
                                                            valid.bind="validation.declarationTemplate"
                                                            onblur.trigger="handleDeclarationTemplateBlur($event)"></ux-input>
                                                        <div class="mt-1 flex items-center gap-1">
                                                            <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.manage-practice.messages.templates-details"></p>
                                                            <div class="flex text-xs">
                                                                <a
                                                                    href="javascript:"
                                                                    click.trigger="addTag('id', 'declarationTemplate')"
                                                                    class="text-primary underline"
                                                                    t="translation:global.labels.unique-tracking-number"></a>
                                                                <span class="mr-1 block">,</span>
                                                                <a
                                                                    href="javascript:"
                                                                    click.trigger="addTag('year', 'declarationTemplate')"
                                                                    class="text-primary underline"
                                                                    t="translation:global.labels.year"></a>
                                                            </div>
                                                        </div>
                                                        <p
                                                            if.bind="!validation.declarationTemplate"
                                                            class="mt-1 text-xs text-red-500"
                                                            t="translation:partial-views.manage-practice.errors.invalid-template"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Financial tab -->
                                <div show.bind="tabs.financial.active" class="flex flex-col gap-5 overflow-y-auto px-10 py-6">
                                    <!-- Financial details -->
                                    <div class="border-b border-gray-900/10 pb-6">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.financial"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.financial"></p>

                                        <div class="mt-6 grid grid-cols-12 gap-6">
                                            <div class="col-span-12 grid grid-cols-12 gap-x-6 gap-y-2">
                                                <div class="col-span-6">
                                                    <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.account-number"></label>
                                                    <div class="mt-2">
                                                        <ux-input value.two-way="workspace.bankAccountNumber" valid.bind="validation.accountNumber"></ux-input>
                                                    </div>
                                                </div>
                                                <div class="col-span-6">
                                                    <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.vat-number"></label>
                                                    <div class="mt-2">
                                                        <ux-input value.two-way="workspace.vatNumber" valid.bind="validation.vatNumber"></ux-input>
                                                    </div>
                                                </div>
                                                <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.manage-practice.messages.financial-details"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Invoices -->
                                    <div class="border-gray-900/10">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.invoices"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.invoices"></p>

                                        <div class="mt-3 grid grid-cols-12 gap-3">
                                            <ul class="col-span-12">
                                                <li repeat.for="invoice of invoices" class="flex justify-between border-b border-gray-300 px-1 py-2 text-sm">
                                                    <div class="grid w-full grid-cols-12">
                                                        <a href="javascript:" click.trigger="showInvoice(invoice)" class="col-span-2 text-left underline decoration-primary decoration-2">
                                                            ${invoice.invoiceNumber}
                                                        </a>
                                                        <p class="col-span-3">${invoice.total | currency}</p>
                                                        <template if.bind="invoice.status === 'paid'">
                                                            <p class="col-span-4 flex gap-1">
                                                                <span t="translation:global.labels.paid-on"></span>
                                                                <span>${invoice.paidAt | date}</span>
                                                            </p>
                                                            <div class="col-span-3 flex justify-end">
                                                                <ux-badge type="success">
                                                                    <span t="translation:global.labels.paid"></span>
                                                                </ux-badge>
                                                            </div>
                                                        </template>
                                                        <template if.bind="invoice.status === 'late' || invoice.status === 'reminded'">
                                                            <p class="col-span-4 flex gap-1">
                                                                <span t="translation:global.labels.due-on"></span>
                                                                <span>${invoice.dueDate | date}</span>
                                                            </p>
                                                            <div class="col-span-3 flex justify-end">
                                                                <ux-badge type="danger">
                                                                    <span t="translation:global.labels.expired"></span>
                                                                </ux-badge>
                                                            </div>
                                                        </template>
                                                        <template if.bind="invoice.status === 'pending_payment' || invoice.status === 'open'">
                                                            <p class="col-span-4 flex gap-1">
                                                                <span t="translation:global.labels.due-on"></span>
                                                                <span>${invoice.dueDate | date}</span>
                                                            </p>
                                                            <div class="col-span-3 flex justify-end">
                                                                <ux-badge type="warning">
                                                                    <span t="translation:global.labels.outstanding"></span>
                                                                </ux-badge>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </li>
                                                <li if.bind="invoices.length === 0" class="flex items-center justify-between border-b border-gray-300 px-1 py-2 text-sm">
                                                    <p t="translation:global.labels.no-results"></p>
                                                </li>
                                            </ul>
                                            <div class="col-span-12">
                                                <ux-pagination
                                                    show.bind="invoices.length > 0"
                                                    max-page.bind="maxPage"
                                                    display="input"
                                                    current-page.bind="currentPage"
                                                    onnext.trigger="handleNextPage($event)"
                                                    onprevious.trigger="handlePreviousPage($event)"
                                                    ref="pagination"
                                                    is-loading.bind="fetching">
                                                </ux-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Legal tab -->
                                <div show.bind="tabs.legal.active" class="flex flex-col gap-8 overflow-y-auto px-10 py-6">
                                    <div class="flex flex-col">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.agreements"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.legal"></p>
                                        <div class="mt-8 grid grid-cols-12 gap-6">
                                            <div if.bind="versions" class="col-span-12 flex w-full flex-col">
                                                <ul class="pl-0">
                                                    <li class="flex flex-col">
                                                        <div class="flex w-full flex-col">
                                                            <p class="text-sm font-semibold">
                                                                <span t="translation:partial-views.manage-practice.labels.terms-and-conditions"></span>
                                                            </p>
                                                            <span class="text-sm text-gray-500" t="translation:partial-views.manage-practice.messages.terms-and-conditions"> </span>
                                                        </div>
                                                        <ul class="mt-2 pl-0">
                                                            <li
                                                                repeat.for="version of versions.termsAndConditions | filterDocumentVersions:'termsAndConditions':workspace"
                                                                class="mt-2 flex justify-between">
                                                                <a
                                                                    if.bind="hasSignedVersion('termsAndConditions', version)"
                                                                    href="javascript:"
                                                                    click.trigger="showDocument('terms-and-conditions', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <a
                                                                    else
                                                                    href="javascript:"
                                                                    click.trigger="sign('terms-and-conditions', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <ux-badge if.bind="hasSignedVersion('termsAndConditions', version)" type="success">
                                                                    <span t="translation:global.labels.signed"></span>
                                                                </ux-badge>
                                                                <ux-badge else type="danger">
                                                                    <span t="translation:partial-views.manage-practice.labels.still-to-sign"></span>
                                                                </ux-badge>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="flex flex-col">
                                                        <ux-body-divider margin="mt-5 mb-4"></ux-body-divider>
                                                        <div class="flex w-full flex-col">
                                                            <p class="text-sm font-semibold">
                                                                <span t="translation:partial-views.manage-practice.labels.processing-agreement"></span>
                                                            </p>
                                                            <span class="text-sm text-gray-500" t="translation:partial-views.manage-practice.messages.processing-agreement"> </span>
                                                        </div>
                                                        <ul class="mt-2 pl-0">
                                                            <li
                                                                repeat.for="version of versions.processingAgreement | filterDocumentVersions:'processingAgreements':workspace"
                                                                class="mt-2 flex justify-between">
                                                                <a
                                                                    if.bind="hasSignedVersion('processingAgreements', version)"
                                                                    href="javascript:"
                                                                    click.trigger="showDocument('processing-agreement', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <a
                                                                    else
                                                                    href="javascript:"
                                                                    click.trigger="sign('processing-agreement', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <ux-badge if.bind="hasSignedVersion('processingAgreements', version)" type="success">
                                                                    <span t="translation:global.labels.signed"></span>
                                                                </ux-badge>
                                                                <ux-badge else type="danger">
                                                                    <span t="translation:partial-views.manage-practice.labels.still-to-sign"></span>
                                                                </ux-badge>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div else class="col-span-12 flex">
                                                <ux-skeleton show-background.bind="false" type="container">
                                                    <div class="flex w-full flex-col space-y-5 py-1">
                                                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                                        <ux-skeleton type="line"></ux-skeleton>
                                                    </div>
                                                </ux-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Storage tab -->
                                <div show.bind="tabs.storage.active" class="flex flex-col gap-8 overflow-y-auto px-10 py-6">
                                    <div class="flex flex-col">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.storage"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.manage-practice.messages.storage"></p>

                                        <div class="mt-6 flex w-full flex-1 flex-col">
                                            <p
                                                class="text-sm"
                                                t="translation:partial-views.manage-practice.labels.storage"
                                                t-params.bind="{ percentage: `${(storage.databasePercentage + storage.filesPercentage).toFixed(2)}%`, total: fileSize.toView(storage.maxStorageInBytes) }"></p>
                                            <div class="mt-4 flex items-center justify-between">
                                                <div class="mb-2 flex gap-x-2">
                                                    <ux-badge type="warning">
                                                        <div class="flex space-x-1">
                                                            <span t="translation:global.labels.database"></span>
                                                            <span>-</span>
                                                            <span> ${storage.databaseInBytes | fileSize}</span>
                                                        </div>
                                                    </ux-badge>
                                                    <ux-badge type="danger">
                                                        <div class="flex gap-x-1">
                                                            <span t="translation:global.labels.files"></span>
                                                            <span>-</span>
                                                            <span>${storage.filesInBytes | fileSize}</span>
                                                        </div>
                                                    </ux-badge>
                                                </div>
                                                <ux-badge type="none">
                                                    <div class="flex gap-x-1">
                                                        <span>${storage.maxStorageInBytes | fileSize}</span>
                                                    </div>
                                                </ux-badge>
                                            </div>
                                            <div class="mb-6 flex">
                                                <div class="relative flex h-5 flex-1 rounded-full bg-gray-200">
                                                    <div
                                                        class.bind="storage.filesInBytes <= storage.databaseInBytes ? 'z-10': ''"
                                                        css="width: ${storage.databasePercentage}%;"
                                                        class="absolute h-5 rounded-l-full bg-yellow-400">
                                                        <div if.bind="storage.databasePercentage > 7" class="flex h-full items-center justify-center text-xs text-yellow-100">
                                                            ${storage.databasePercentage}%
                                                        </div>
                                                    </div>
                                                    <div
                                                        class.bind="storage.databaseInBytes < storage.filesInBytes ? 'z-10': ''"
                                                        css="width: ${storage.filesPercentage}%; left: ${storage.databasePercentage}%"
                                                        class="absolute h-5 rounded-r-full bg-[#F25366]">
                                                        <div if.bind="storage.filesPercentage > 7" class="flex h-full items-center justify-center text-xs text-red-100">
                                                            ${storage.filesPercentage}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ux-alert type="info">
                                                <span class="text-sm" t="translation:partial-views.manage-practice.messages.storage-info"></span>
                                            </ux-alert>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ux-footer if.bind="baseLoaded">
                        <div class="flex flex-1 justify-end">
                            <div class="flex gap-x-2">
                                <ux-button
                                    size="sm"
                                    color="success"
                                    disabled.bind="isLoading"
                                    loading.bind="isLoading"
                                    click.trigger="save()"
                                    t="[text-when-loading]translation:global.labels.loading-states.default">
                                    <span class="pointer-events-none" t="global.buttons.save"></span>
                                </ux-button>
                            </div>
                        </div>
                    </ux-footer>
                </section>
            </template>
            <template else>
                <div class="flex p-20">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex w-full flex-col space-y-5 py-1">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
