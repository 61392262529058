import { DeclarationPerformanceTypes } from '@wecore/sdk-healthcare';

export class DeclarationPerformanceTypeToStringValueConverter {
    public toView(type: DeclarationPerformanceTypes): string {
        switch (type) {
            case DeclarationPerformanceTypes.Credit:
                return 'translation:global.labels.credit';
            case DeclarationPerformanceTypes.Debit:
                return 'translation:global.labels.debit';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
