import { LegalEntities, UsersApiClient } from '@wecore/sdk-core';
import { GetUserResponse, LegalApiClient, SignEulaRequest, WorkspacesApiClient } from '@wecore/sdk-management';
import { isNotEmpty, validateState } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { base64ToFile } from '../../../../infra/utilities';

@containerless
@inject(UsersApiClient, WorkspacesApiClient, LegalApiClient)
export class TemplateEula {
    @bindable() public isLoading: boolean = false;
    @bindable() public version: LegalEntities;

    public firstName: string;
    public lastName: string;
    public today: Date = new Date();
    public location: string;
    public readDocument: boolean = false;

    private currentUser: GetUserResponse;

    public validation: any = {
        firstName: true,
        lastName: true,
        readDocument: true,
        location: true
    };

    public constructor(
        private readonly usersApi: UsersApiClient, //
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly legalApi: LegalApiClient
    ) {}

    public async bound(): Promise<void> {
        // Fetch the full user and workspace
        this.currentUser = await this.usersApi.getAuthenticated();
        this.firstName = this.currentUser.firstName;
        this.lastName = this.currentUser.lastName;
    }

    public validate(): boolean {
        this.validation.firstName = isNotEmpty(this.firstName);
        this.validation.lastName = isNotEmpty(this.lastName);
        this.validation.readDocument = this.readDocument;
        this.validation.location = isNotEmpty(this.location);
        return validateState(this.validation);
    }

    public async signAndSave(base64: any): Promise<void> {
        // First save the signature.
        const filename = `signature-eula-${this.version.replace(/\./g, '')}.png`;
        const signature = base64ToFile(base64, filename);

        // Make sure the signature does not exists already.
        if (this.currentUser.attachments.some((x) => x.name === filename.split('.')[0])) {
            const attachment = this.currentUser.attachments.find((x) => x.name === filename.split('.')[0]);
            await this.usersApi.deleteAttachment(this.currentUser.id, attachment.id);
        }

        // Save the signature to the current user.
        await this.usersApi.uploadAttachment(this.currentUser.id, false, {
            data: signature,
            fileName: filename
        });

        await this.legalApi.createUela(
            new SignEulaRequest({
                name: `${this.firstName} ${this.lastName}`,
                accepted: this.readDocument,
                when: this.today,
                location: this.location,
                version: this.version
            })
        );
    }
}
