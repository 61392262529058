import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { Email, Link, Phone } from '@wecore/sdk-crm';
import { GetPatientResponse, LabelRegistration, MedicalEquipmentRegistration, MedicationRegistration, PatientsApiClient, ProfessionRegistration, SportRegistration } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, ModalService)
export class PartialPatientsEdit extends BasePartialView {
    public tabs: any;
    public lockType: boolean;
    public type: string;
    public patient: GetPatientResponse;
    public validation: any = {
        lastName: true,
        headPractitioner: true,
        bsn: true,
        dob: true,
        emails: [],
        phones: [],
        links: [],
        sports: [],
        medicalEquipment: [],
        medicalHistory: [],
        medications: [],
        labels: [],
        professions: []
    };
    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.type = view.data.type;
        this.patientId = view.data.id;
        this.lockType = view.data.lockType || false;

        this.tabs = {
            general: {
                name: this.t.tr('translation:partial-views.patients.labels.general'),
                active: true,
                valid: true
            },
            medical: {
                name: this.t.tr('translation:partial-views.patients.labels.medical'),
                active: false,
                valid: true
            },
            addresses: {
                name: this.t.tr('translation:partial-views.patients.labels.addresses'),
                active: false,
                valid: true
            },
            connections: {
                name: this.t.tr('translation:global.labels.connections'),
                active: false,
                valid: true
            }
        };
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.patient = await this.patientsApi.getById(this.patientId, this.authenticated.workspace.id);

                this.validation.emails = this.patient.emails?.map((_: Email) => ({ email: true, phone: true, valid: true })) || [];
                this.validation.phones = this.patient.phones?.map((_: Phone) => ({ phone: true, email: true, valid: true })) || [];
                this.validation.links = this.patient.phones?.map((_: Phone) => ({ phone: true, email: true, valid: true })) || [];
                this.validation.labels = this.patient.labels?.map((_: LabelRegistration) => ({ label: true, valid: true })) || [];
                this.validation.sports = this.patient.sports?.map((_: SportRegistration) => ({ sport: true, valid: true })) || [];
                this.validation.professions = this.patient.professions?.map((_: ProfessionRegistration) => ({ profession: true, valid: true })) || [];
                this.validation.medications = this.patient.medications?.map((_: MedicationRegistration) => ({ medication: true, valid: true })) || [];
                this.validation.medicalEquipment = this.patient.medicalEquipment?.map((_: MedicalEquipmentRegistration) => ({ equipment: true, valid: true })) || [];

                // Check for old links, emails, phones and addresses and update them.
                this.patient.phones =
                    this.patient.phones?.map((phone: Phone) => {
                        if (isNotDefined(phone.translations)) phone.translations = { [this.language]: phone.description };
                        if (isNotDefined(phone.callingCode)) phone.callingCode = '31';
                        return phone;
                    }) || [];

                this.patient.emails =
                    this.patient.emails?.map((email: Email) => {
                        if (isNotDefined(email.translations)) email.translations = { [this.language]: email.description };
                        return email;
                    }) || [];

                this.patient.links =
                    this.patient.links?.map((link: Link) => {
                        if (isNotDefined(link.translations)) link.translations = { [this.language]: link.description };
                        if (isNotDefined(link.protocol)) link.protocol = 'http://';
                        return link;
                    }) || [];

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPatientsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public setActiveTab(key: string): void {
        for (const prop in this.tabs) this.tabs[prop].active = false;
        this.tabs[key].active = true;
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;

            if (isNotEmpty(this.patient.firstName)) this.patient.firstName?.trim();
            if (isNotEmpty(this.patient.middleName)) this.patient.middleName?.trim();
            this.patient.lastName?.trim();

            // Clean values that are not valid.
            this.patient.emails = this.patient.emails.filter((email: Email) => {
                const cleaned = email.value?.replace(/_/g, '');
                return isDefined(cleaned) && isNotEmpty(cleaned);
            });

            this.patient.phones = this.patient.phones.filter((phone: Phone) => {
                const cleaned = phone.value?.replace(/_/g, '');
                return isDefined(cleaned) && isNotEmpty(cleaned);
            });

            this.patient.links = this.patient.links.filter((link: Link) => {
                return isDefined(link.value) && isNotEmpty(link.value);
            });

            this.patient.weight = Number(this.patient.weight?.toString().replace(/,/g, '.'));
            this.patient.length = Number(this.patient.length?.toString().replace(/,/g, '.'));

            this.patient.labels = this.patient.labels.filter((registration: LabelRegistration) => isDefined(registration.label));
            this.patient.sports = this.patient.sports.filter((registration: SportRegistration) => isDefined(registration.sport));
            this.patient.professions = this.patient.professions.filter((registration: ProfessionRegistration) => isDefined(registration.profession));
            this.patient.medicalEquipment = this.patient.medicalEquipment.filter((registration: MedicalEquipmentRegistration) => isDefined(registration.equipment));
            this.patient.medications = this.patient.medications.filter((registration: MedicationRegistration) => isDefined(registration.medication));

            try {
                const patient = await this.patientsApi.update(this.patientId, this.authenticated.workspace.id, this.patient);
                this.remove({ result: PartialViewResults.Ok, data: patient, updateUrl: true });
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[patients/edit]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patients.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.patients.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.patient.displayName}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.patientsApi.delete(this.patientId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.patients.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.patients.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.patient.displayName}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[patients/delete]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor = (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean = false): void => {
        this.manageTranslations({ translations, callback, required, type });
    };

    private validate(): boolean {
        this.validation.lastName = isNotEmpty(this.patient.lastName);
        this.validation.headPractitioner = isDefined(this.patient.headPractitioner);
        // this.validation.bsn = isNotEmpty(this.patient.citizensServiceNumber);
        this.validation.dob = isDefined(this.patient.dateOfBirth);

        for (let index = 0; index < this.patient.emails.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.emails[index]);

            const email = this.patient.emails[index].value;
            // Only validate if user entered something.
            if (isNotDefined(email) || isEmpty(email)) continue;
            // Validate the phone number
            this.validation.emails[index].email =
                isNotEmpty(email) &&
                isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);
            this.validation.emails[index].valid = this.validation.emails[index].email;
        }

        for (let index = 0; index < this.patient.phones.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.phones[index]);
            // Remove mask placeholder characters.
            const phone = this.patient.phones[index].value?.replace(/_/g, '') || undefined;
            // Only validate if user entered something.
            if (isNotDefined(phone) || isEmpty(phone)) continue;
            // Validate the phone number
            this.validation.phones[index].phone = phone.length === 9;
            this.validation.phones[index].valid = this.validation.phones[index].phone;
        }

        this.tabs.general.valid =
            validateState(this.validation) && //
            this.validation.emails.every((e: any) => e.valid) &&
            this.validation.phones.every((e: any) => e.valid);

        return this.tabs.general.valid;
    }
}
