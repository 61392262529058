import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetMedicalExaminationActionResponse, GetMedicalExaminationPhaseResponse, MedicalExaminationActionsApiClient, MedicalExaminationPhasesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationActionsApiClient, ModalService, MedicalExaminationPhasesApiClient)
export class PartialAddMedicalActions extends BasePartialView {
    public actions: GetMedicalExaminationActionResponse[] = [];
    public selected: GetMedicalExaminationActionResponse[] = [];
    public alreadyAddedActions: any[];
    public phases: { [key: string]: GetMedicalExaminationPhaseResponse } = {};

    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly actionsApi: MedicalExaminationActionsApiClient,
        private readonly modalService: ModalService,
        private readonly phasesApi: MedicalExaminationPhasesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.alreadyAddedActions = view.data;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [phases] = await Promise.all([
                    this.phasesApi.search(this.authenticated.workspace.id, '', 100, 0, undefined, undefined, undefined), //
                    await this.getData(false, true)
                ]);
                for (const phase of phases.data) this.phases[phase.id] = phase;

                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialAddMedicalActions.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true, false, 250);

        element.isLoading = false;
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public select(action: GetMedicalExaminationActionResponse): void {
        if (this.selected.some((x) => x.id === action.id)) this.selected = this.selected.filter((x) => x.id !== action.id);
        else this.selected.push(action);

        this.actions = [
            ...(this.actions.length > 0 ? [this.actions.shift()] : []), //
            ...cloneDeep(this.actions)
        ];
    }

    public async add(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.add-medical-actions.questions.copy.title'),
                message: this.t.tr('translation:partial-views.add-medical-actions.questions.copy.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.add'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.remove({
                            result: PartialViewResults.Ok,
                            data: this.selected
                        });
                    }
                }
            })
        );
    }

    public isAlreadyAdded(action: GetMedicalExaminationActionResponse): any {
        return this.alreadyAddedActions.find((x) => x.action === action.id);
    }

    private async getData(reset: boolean = false, initial: boolean = false, timeout: number = 0): Promise<void> {
        return new Promise(async (resolve) => {
            if (reset) {
                this.skip = 0;
                this.actions = [];
                this.endOfList = false;
                if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
            }

            this.isLoading = true;
            setTimeout(async () => {
                const response = await this.actionsApi.search(this.authenticated.workspace.id, isNotEmpty(this.query) ? this.query : undefined, this.pageSize, this.skip);

                if (!reset && response.data.empty() && !initial) {
                    this.endOfList = true;
                    this.isLoading = false;
                    resolve();
                    return;
                }

                this.skip += Number(this.pageSize);
                this.actions = [...this.actions, ...response.data];
                this.isLoading = false;
                resolve();
            }, timeout);
        });
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
