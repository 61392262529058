import { I18N } from '@aurelia/i18n';
import { Label, LabelProperties, LabelTypes, LabelsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(LabelsApiClient, I18N, ErrorHandler)
export class BxLabelSelector {
    @bindable() public value: string;
    @bindable() public selected: Label;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public types: string[];
    @bindable() public type: 'Patient' | 'Task' = 'Patient';
    @bindable() public onSelect: (label: Label, data: any) => void;

    public combobox: UxCombobox;
    public labels: Label[];

    public constructor(
        private readonly labelsApi: LabelsApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public handleClear(): void {
        if (isFunction(this.onSelect)) this.onSelect(null, this.data);
    }

    public async attaching(): Promise<void> {
        try {
            if (isNotEmpty(this.value)) {
                this.labelsApi.getById(this.value, this.workspace).then((label) => {
                    // If the selected item is not found, use the selected object which can
                    // be provided by the user and used to display the object name in the combobox.
                    // This can be handy in case the item has been deleted.
                    if (isNotDefined(label)) label = this.selected;
                    this.labelsApi.search(this.workspace, '', 50, 0, undefined, undefined, undefined, this.types).then((response) => {
                        const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
                        this.labels = isDefined(label) ? [label].concat(data.filter((x) => x.id !== this.value)) : data;
                        if (isDefined(this.combobox)) this.combobox.isLoading = false;
                    });
                });
            } else {
                this.labelsApi.search(this.workspace, '', 50, 0, undefined, undefined, undefined, this.types).then((response) => {
                    this.labels = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
                    if (isDefined(this.combobox)) this.combobox.isLoading = false;
                });
            }
        } catch (e) {
            this.errorHandler.handle('BxLabelSelector.attached', e);
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.labelsApi.search(this.workspace, isNotEmpty(query) ? query : '', 50, 0, undefined, undefined, undefined, this.types);
            this.labels = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxLabelSelector.handleSearch', e);
        }
    }

    public handleSelect(e: CustomEvent<EventDetails<UxCombobox, any>>): void {
        if (e.detail.values.customInput as boolean) return;
        const option = e.detail.values as UxComboboxOption;

        const label = this.labels.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(label, this.data);
    }

    public async create(): Promise<void> {
        this.combobox.isLoading = true;
        const text = await this.combobox.getText();

        if (isEmpty(text)) {
            this.combobox.isLoading = false;
            return;
        }

        const label = await this.labelsApi.create(
            this.workspace,
            new Label({
                translations: {
                    [this.language]: text
                },
                properties: new LabelProperties({
                    type:
                        this.type === 'Patient' //
                            ? LabelTypes.Patient
                            : LabelTypes.Task
                })
            })
        );

        const response = await this.labelsApi.search(this.workspace, '', 50, 0, undefined, undefined, undefined, this.types);
        const filtered = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
        this.labels = [label, ...filtered.filter((x) => x.id !== label.id)];

        this.value = label.id;

        if (isFunction(this.onSelect)) this.onSelect(label, this.data);
        this.combobox.isLoading = false;
        this.combobox.close();
    }
}
