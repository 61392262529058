<template class="flex items-center">
    <button
        click.trigger="handleClick($event)"
        type="button"
        disabled.bind="disabled"
        class="relative inline-flex h-6 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2"
        w-11.class="size == 'sm'"
        w-16.class="size == 'md'"
        w-20.class="size == 'lg'"
        w-[7.5rem].class="size == 'xl'"
        cursor-not-allowed.class="disabled"
        opacity-60.class="disabled"
        !bg-gray-200.class="!isChecked"
        !bg-gray-800.class="isChecked && color == 'primary'"
        !bg-green-500.class="isChecked && color == 'success'"
        !bg-red-500.class="isChecked && color == 'danger'"
        !bg-yellow-500.class="isChecked && color == 'warning'"
        !bg-blue-500.class="isChecked && color == 'info'"
        !bg-primary.class="isChecked && color == 'secondary'"
        focus:ring-gray-400.class="color == 'primary'"
        focus:ring-green-400.class="color == 'success'"
        focus:ring-red-400.class="color == 'danger'"
        focus:ring-yellow-400.class="color == 'warning'"
        focus:ring-blue-400.class="color == 'info'"
        focus:ring-primary.class="color == 'secondary'"
        role="switch"
        aria-checked.bind="isChecked">
        <span if.bind="labelActive" class="absolute left-2 text-sm transition-opacity duration-100 ease-out" text-white.class="isChecked" opacity-0.class="!isChecked" opacity-100.class="isChecked">
            ${labelActive}
        </span>
        <span
            class="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            translate-x-0.class="!isChecked"
            translate-x-5.class="isChecked && size == 'sm'"
            translate-x-10.class="isChecked && size == 'md'"
            translate-x-14.class="isChecked && size == 'lg'"
            translate-x-24.class="isChecked && size == 'xl'">
            <span
                class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-100 ease-out"
                opacity-0.class="!isChecked"
                opacity-100.class="isChecked"
                aria-hidden="true">
                <svg
                    class="h-3 w-3"
                    text-gray-800.class="color == 'primary'"
                    text-green-500.class="color == 'success'"
                    text-red-500.class="color == 'danger'"
                    text-yellow-500.class="color == 'warning'"
                    text-blue-500.class="color == 'info'"
                    text-primary.class="color == 'secondary'"
                    fill="currentColor"
                    viewBox="0 0 12 12">
                    <path
                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
            </span>
            <span
                aria-hidden="true"
                class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in"
                opacity-100.class="!isChecked"
                opacity-0.class="isChecked">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        </span>
        <span
            if.bind="labelInactive"
            class="absolute right-1.5 text-sm transition-opacity duration-100 ease-out"
            text-gray-800.class="!isChecked"
            opacity-0.class="isChecked"
            opactiy-100.class="!isChecked"
            delay-100.class="!isChecked">
            ${labelInactive}
        </span>
    </button>
</template>
