<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header padding="py-5 px-8">
            <div class="flex items-center justify-between">
                <div class="flex flex-col">
                    <p class="font-semibold leading-tight" t="translation:partial-views.master-data.list.title"></p>
                    <span class="text-sm leading-tight text-gray-500" t="translation:partial-views.master-data.messages.select-category"></span>
                </div>
            </div>
        </ux-header>
        <div class="flex h-full w-full flex-1 flex-col overflow-y-auto px-6">
            <ul class="flex flex-col gap-1 py-3">
                <li class="group flex w-full" repeat.for="item of items">
                    <a
                        href="javascript:"
                        bg-gray-100.class="item.type === active.type"
                        click.trigger="manage({ item })"
                        class="flex flex-1 items-center justify-between gap-2 rounded-xl px-4 py-2 group-hover:bg-gray-100">
                        <div class="${item.type === active.type ? 'text-primary' : 'text-gray-800'} flex flex-col">
                            <p class="text-sm font-semibold">${item.name | t}</p>
                            <span class="text-xs text-gray-500">${item.description | t}</span>
                        </div>
                        <div class="flex w-10 items-center justify-center">
                            <svg
                                if.bind="item.type === active.type"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-4 w-4 text-gray-500 group-hover:text-black">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>
