import { MedicalTherapyExecutionItem } from '@wecore/sdk-healthcare';

export interface ICopyTherapyExecutionItem {
    item: MedicalTherapyExecutionItem;
    array: MedicalTherapyExecutionItem[];
    index: number;
    validation: any[];
    validationItem: any;
    command: 'copy' | 'cut';
}

export class CopyTherapyExecutionItem {
    public item: MedicalTherapyExecutionItem;
    public array: MedicalTherapyExecutionItem[];
    public index: number;
    public validation: any[];
    public validationItem: any;
    public command: 'copy' | 'cut';

    public constructor(data: ICopyTherapyExecutionItem) {
        this.item = data?.item;
        this.array = data?.array;
        this.index = data?.index;
        this.validation = data?.validation;
        this.validationItem = data?.validationItem;
        this.command = data?.command;
    }
}
