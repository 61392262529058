import { Sport, SportEntityReference, SportRegistration } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';

@containerless
export class ComponentSportsSelector {
    @bindable() public registrations: SportRegistration[];
    @bindable() public validation: any[];
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public attached(): void {
        if (this.registrations.empty()) this.addItem();
    }

    public addItem(): void {
        this.registrations.push(
            new SportRegistration({
                comments: {}
            })
        );

        this.validation.push({
            valid: true,
            sport: true
        });
    }

    public deleteItem(index: number): void {
        this.registrations.splice(index, 1);
        this.validation.splice(index, 1);

        if (this.registrations.empty()) this.addItem();
    }

    public handleSportSelected = async (sport: Sport, index: number): Promise<void> => {
        this.registrations[index].sport = new SportEntityReference({
            id: sport.id,
            translations: sport.translations
        });
    };

    public manageTranslationsFor(index: number): void {
        this.manageTranslations(
            this.registrations[index].comments, //
            (translations: any) => (this.registrations[index].comments = translations),
            'textarea',
            false
        );
    }
}
