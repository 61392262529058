<import from="converters/invoice-status-to-color" />
<import from="converters/invoice-status-to-string" />
<import from="converters/currency" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between">
                <p class="text-sm font-medium" t="translation:partial-views.patient-invoices.title"></p>
                <div class="flex space-x-1">
                    <button class="flex items-center justify-center p-1 bg-gray-100 rounded-full hover:bg-gray-200" click.trigger="close()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                            <path
                                fill-rule="evenodd"
                                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full px-6 pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <div class="flex flex-col">
                    <button repeat.for="invoice of invoices" click.trigger="open(invoice)" class="flex flex-col gap-2 py-2 text-sm border-b border-gray-200 hover:font-medium">
                        <div class="flex flex-col w-full">
                            <div class="flex items-center justify-between gap-5">
                                <div class="flex items-center flex-1 min-w-0 gap-1">
                                    <span if.bind="invoice.type === HealthcareInvoiceTypes.Debit" t="translation:global.labels.invoice"></span>
                                    <span else t="translation:global.labels.credit-invoice"></span>
                                    <!-- <div class="${} h-2 w-2 rounded-full"></div> -->
                                    <div if.bind="invoice.trackingNumber" class="flex items-center gap-1">
                                        <span>${invoice.trackingNumber}</span>
                                    </div>
                                    <div else class="flex items-center gap-1 text-gray-600">
                                        <span>#${invoice.conceptNumber}</span>
                                        <div class="flex">
                                            <span>(</span>
                                            <span t="translation:global.labels.draft"></span>
                                            <span>)</span>
                                        </div>
                                    </div>
                                </div>
                                <ux-badge type="${invoice.status | invoiceStatusToColor}">
                                    <span>${invoice.status | invoiceStatusToString | t}</span>
                                </ux-badge>
                            </div>
                        </div>
                    </button>
                    <div if.bind="invoices.length === 0" class="flex justify-center py-5">
                        <p class="text-sm" t="translation:partial-views.patient-invoices.messages.no-results"></p>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-2 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
