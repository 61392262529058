import { GetAllHealthcarePricesResponseItem } from '@wecore/sdk-healthcare';

export class SortHealthcarePricesValueConverter {
    public toView(prices: GetAllHealthcarePricesResponseItem[]): Map<string, GetAllHealthcarePricesResponseItem[]> {
        const sortOrder = ['MedicalExaminationAction', 'MedicalTherapy', 'MedicalExamination', 'AppointmentType'];
        const sorted = prices
            .sort((a, b) => {
                const indexA = sortOrder.indexOf(a.entityType);
                const indexB = sortOrder.indexOf(b.entityType);
                return indexA - indexB;
            })
            .groupBy((x: GetAllHealthcarePricesResponseItem) => x.entityType);

        return sorted;
    }
}
