import { CopyActionItem } from '../../../models/copy-action-item';
import { CopyTherapyEvaluationItem } from '../../../models/copy-evaluation-item';
import { CopyQuestionItem } from '../../../models/copy-question-item';
import { CopyTemplateItem } from '../../../models/copy-template-item';
import { CopyTherapyExecutionItem } from '../../../models/copy-therapy-execution-item';
import { CopyTherapyPlanItem } from '../../../models/copy-therapy-plan-item';
import { State } from '../state';

/**
 * Copies a copy item into the state.
 * @param state The current state.
 * @param item The copy item to store.
 * @returns The updated state.
 */
export function copyItem(state: State, item: CopyActionItem | CopyTherapyPlanItem | CopyTherapyExecutionItem): State {
    const newState = { ...state };
    if (item instanceof CopyActionItem) newState.clipboard.actionItem = item;
    if (item instanceof CopyTherapyPlanItem) newState.clipboard.therapyPlanItem = item;
    if (item instanceof CopyTherapyExecutionItem) newState.clipboard.therapyExecutionItem = item;
    return newState;
}

/**
 *
 * Clears the copied item from state.
 * @param state The current state.
 * @returns The updated state.
 */
export function clearItem(state: State, type: 'MedicalExaminationActionItem' | 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem'): State {
    const newState = { ...state };
    if (type === 'MedicalExaminationActionItem') newState.clipboard.actionItem = null;
    if (type === 'MedicalTherapyPlanItem') newState.clipboard.therapyPlanItem = null;
    if (type === 'MedicalTherapyExecutionItem') newState.clipboard.therapyExecutionItem = null;
    return newState;
}

/**
 * Copies a copy template item into the state.
 * @param state The current state.
 * @param item The copy template to store.
 * @returns The updated state.
 */
export function copyTemplateItem(state: State, item: CopyTemplateItem): State {
    const newState = { ...state };
    newState.clipboard.templateItem = item;
    return newState;
}

/**
 *
 * Clears the copied item from state.
 * @param state The current state.
 * @returns The updated state.
 */
export function clearTemplateItem(state: State): State {
    const newState = { ...state };
    newState.clipboard.templateItem = null;
    return newState;
}

/**
 * Copies a copy question item into the state.
 * @param state The current state.
 * @param item The copy question to store.
 * @returns The updated state.
 */
export function copyQuestionItem(state: State, item: CopyQuestionItem): State {
    const newState = { ...state };
    newState.clipboard.questionItem = item;
    return newState;
}

/**
 *
 * Clears the question item from state.
 * @param state The current state.
 * @returns The updated state.
 */
export function clearQuestionItem(state: State): State {
    const newState = { ...state };
    newState.clipboard.questionItem = null;
    return newState;
}

/**
 * Copies a copy evaluation item into the state.
 * @param state The current state.
 * @param item The copy evaluation to store.
 * @returns The updated state.
 */
export function copyTherapyEvaluationItem(state: State, item: CopyTherapyEvaluationItem): State {
    const newState = { ...state };
    newState.clipboard.therapyEvaluationItem = item;
    return newState;
}

/**
 *
 * Clears the evaluation item from state.
 * @param state The current state.
 * @returns The updated state.
 */
export function clearTherapyEvaluationItem(state: State): State {
    const newState = { ...state };
    newState.clipboard.therapyEvaluationItem = null;
    return newState;
}

// store.registerAction('copyActionItem', copyActionItem);
// store.registerAction('clearActionItem', clearActionItem);
// store.registerAction('copyTemplateItem', copyTemplateItem);
// store.registerAction('clearTemplateItem', clearTemplateItem);
// store.registerAction('copyQuestionItem', copyQuestionItem);
// store.registerAction('clearQuestionItem', clearQuestionItem);
// store.registerAction('copyEvaluationItem', copyEvaluationItem);
// store.registerAction('clearEvaluationItem', clearEvaluationItem);
