import { GetPatientResponse } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';

@containerless
export class TabPatientsConnections {
    @bindable() public patient: GetPatientResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
    @bindable() public currentUser: GetPatientResponse;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
}
