<import from="converters/translate" />
<import from="converters/join" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div ref="scrollContainer" class="flex h-full flex-1 flex-col overflow-y-auto scroll-smooth px-6">
            <div class="sticky top-0 border-b border-gray-200 bg-white pb-2 pt-6">
                <div class="mb-3 flex items-center justify-between">
                    <p class="text-sm font-medium" t="translation:partial-views.add-treatment-protocols.labels.select-actions"></p>
                </div>
                <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>
            </div>
            <template if.bind="baseLoaded">
                <ul class="flex flex-1 flex-col">
                    <li repeat.for="protocol of protocols" class="group">
                        <let added.bind="isAlreadyAdded(protocol)"></let>
                        <template if.bind="added !== null && added !== undefined">
                            <div class="flex items-center justify-between border-b border-gray-200 py-3">
                                <div class="flex flex-1 flex-col">
                                    <p class="text-sm font-semibold">${ added.name | translate:language }</p>
                                    <div class="flex gap-1 text-xs text-red-500">
                                        <p t="translation:partial-views.add-treatment-protocols.labels.is-already-added-in"></p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <a else click.trigger="select(protocol)" id.bind="protocol.id" href="javascript:" class="flex items-center justify-between border-b border-gray-200 py-3">
                            <div class="flex flex-1 flex-col">
                                <p class="text-sm font-semibold group-hover:text-gray-600">${ protocol.name | translate:language }</p>
                                <div class="flex gap-1 text-xs text-gray-600">${getTherapies(protocol)}</div>
                            </div>
                            <template if.bind="selected.some((x) => x.id === protocol.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-green-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </template>
                        </a>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="templates.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-1 flex-col gap-y-2 pt-4">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex w-full flex-col space-y-5 py-1">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
            <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button size="sm" color="primary" disabled.bind="isLoading || deleting || selected.length === 0" loading.bind="isLoading" click.trigger="add()">
                        <span class="pointer-events-none" t="global.buttons.add"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
