<import from="converters/sort" />
<import from="../template-task/template-task" />
<import from="../template-task-list-sort/template-task-list-sort" />

<div if.bind="list" data-order.bind="list.displayOrder" ref="element" data-type="task-list" class="flex flex-col draggable-list">
    <div class="mb-1 flex items-center gap-1 pl-[8px]">
        <div class="flex items-center justify-center w-10">
            <button click.trigger="collapseOrExpand()" css="border-color: ${list.color}; color: ${list.color}" class="flex items-center justify-center w-6 h-6 rounded-full hover:border">
                <svg
                    -rotate-90.class="!expanded"
                    class="w-4 h-4 transition-all duration-100"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </button>
        </div>
        <button click.trigger="edit()" if.bind="canEdit && hasRole(UserRoles.WriteHealthcareTaskLists)" css="color: ${list.color};" class="hover:brightness-75">
            <p class="font-semibold">${name}</p>
        </button>
        <p else css="color: ${list.color};" class="font-semibold">${name}</p>
    </div>
    <div
        class="group/list relative grid grid-cols-12 rounded-lg border !border-y-gray-300 !border-r-gray-300 bg-white before:absolute before:left-[-25px] before:h-full before:w-[25px] before:content-['']">
        <button
            if.bind="!isPersonal"
            mousedown.trigger="handleMoveMouseDown($event)"
            class="absolute left-[-40px] z-50 hidden h-[38px] w-12 items-center justify-center text-gray-500 group-hover/list:flex hover:cursor-move">
            <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.5 6C12.5 6.27614 12.2761 6.5 12 6.5C11.7239 6.5 11.5 6.27614 11.5 6C11.5 5.72386 11.7239 5.5 12 5.5C12.2761 5.5 12.5 5.72386 12.5 6Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M18.5 6C18.5 6.27614 18.2761 6.5 18 6.5C17.7239 6.5 17.5 6.27614 17.5 6C17.5 5.72386 17.7239 5.5 18 5.5C18.2761 5.5 18.5 5.72386 18.5 6Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M18.5 12C18.5 12.2761 18.2761 12.5 18 12.5C17.7239 12.5 17.5 12.2761 17.5 12C17.5 11.7239 17.7239 11.5 18 11.5C18.2761 11.5 18.5 11.7239 18.5 12Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M6.5 12C6.5 12.2761 6.27614 12.5 6 12.5C5.72386 12.5 5.5 12.2761 5.5 12C5.5 11.7239 5.72386 11.5 6 11.5C6.27614 11.5 6.5 11.7239 6.5 12Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M12.5 18C12.5 18.2761 12.2761 18.5 12 18.5C11.7239 18.5 11.5 18.2761 11.5 18C11.5 17.7239 11.7239 17.5 12 17.5C12.2761 17.5 12.5 17.7239 12.5 18Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M18.5 18C18.5 18.2761 18.2761 18.5 18 18.5C17.7239 18.5 17.5 18.2761 17.5 18C17.5 17.7239 17.7239 17.5 18 17.5C18.2761 17.5 18.5 17.7239 18.5 18Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path
                    d="M6.5 18C6.5 18.2761 6.27614 18.5 6 18.5C5.72386 18.5 5.5 18.2761 5.5 18C5.5 17.7239 5.72386 17.5 6 17.5C6.27614 17.5 6.5 17.7239 6.5 18Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
        </button>
        <div css="background-color: ${list.color}" rounded-bl-lg.class="!expanded" class="absolute left-0 z-10 h-[41px] w-[8px] rounded-tl-lg"></div>
        <template if.bind="expanded">
            <div class="col-span-6 flex border-b border-r border-gray-300 pl-[8px]">
                <div class="flex items-center justify-center w-10 h-full border-r border-gray-300">
                    <button
                        if.bind="hasRole(UserRoles.WriteHealthcareTasks) || hasRole(UserRoles.DeleteHealthcareTasks)"
                        click.trigger="selectTasks()"
                        class="flex items-center justify-center w-full h-full min-w-0 gap-3 text-sm focus:outline-none active:bg-blue-100">
                        <input type="checkbox" checked.bind="selectedAll" />
                    </button>
                </div>
                <div class="relative flex items-center justify-center flex-1 group">
                    <template-task-list-sort
                        field="Title"
                        active-field.bind="pageState.values.sort.field"
                        direction.bind="pageState.values.sort.direction"
                        on-click.bind="sort"></template-task-list-sort>
                    <p class="text-sm font-light" t="translation:global.labels.task"></p>
                </div>
            </div>
            <div class="group relative col-span-2 flex items-center border-b border-r border-gray-300 p-2.5">
                <div class="flex items-center justify-center flex-1">
                    <p class="text-sm font-light" t="translation:global.labels.owner"></p>
                </div>
            </div>
            <div class="group relative col-span-2 flex items-center border-b border-r border-gray-300 p-2.5">
                <template-task-list-sort
                    field="Deadline"
                    active-field.bind="pageState.values.sort.field"
                    direction.bind="pageState.values.sort.direction"
                    on-click.bind="sort"></template-task-list-sort>
                <div class="flex items-center justify-center flex-1">
                    <p class="text-sm font-light" t="translation:global.labels.deadline"></p>
                </div>
            </div>
            <div class="group relative col-span-1 flex items-center border-b border-r border-gray-300 p-2.5">
                <div class="flex items-center justify-center flex-1">
                    <template-task-list-sort
                        field="Status"
                        active-field.bind="pageState.values.sort.field"
                        direction.bind="pageState.values.sort.direction"
                        on-click.bind="sort"></template-task-list-sort>
                    <p class="text-sm font-light" t="translation:global.labels.status"></p>
                </div>
            </div>
            <div class="group relative col-span-1 flex items-center border-b border-gray-300 p-2.5">
                <template-task-list-sort
                    field="Priority"
                    active-field.bind="pageState.values.sort.field"
                    direction.bind="pageState.values.sort.direction"
                    on-click.bind="sort"></template-task-list-sort>
                <div class="flex items-center justify-center flex-1">
                    <p class="text-sm font-light" t="translation:global.labels.priority"></p>
                </div>
            </div>
        </template>
        <template else>
            <div class="flex col-span-6 border-r border-gray-300">
                <div class="flex items-center gap-1 px-5 text-sm font-light lowercase">
                    <span>${tasks.length ?? 0}</span>
                    <span if.bind="tasks.length !== 1" t="translation:global.labels.tasks"></span>
                    <span else t="translation:global.labels.task"></span>
                </div>
            </div>
            <div class="col-span-2 flex items-center border-r border-gray-300 p-2.5"></div>
            <div class="col-span-2 flex items-center border-r border-gray-300 p-2.5">
                <div class="flex items-center justify-center flex-1">
                    <p class="text-sm font-light" t="translation:global.labels.deadline"></p>
                </div>
            </div>
            <div class="col-span-1 flex items-center border-r border-gray-300 p-2.5"></div>
            <div class="col-span-1 flex items-center border-gray-300 p-2.5"></div>
        </template>
        <div id.bind="list.id" ref="container" data-type="task-list-content" class="grid grid-cols-12 col-span-12">
            <template if.bind="baseLoaded && expanded" repeat.for="task of tasks">
                <template-task
                    lists.bind="lists"
                    has-role.bind="hasRole"
                    on-edit.bind="handleEditTask"
                    on-select.bind="handleTaskSelected"
                    open-user-posts.bind="handleUserPosts"
                    on-drag-start.bind="handleTaskDragStart"
                    on-drag.bind="handleTaskDrag"
                    on-drag-end.bind="handleTaskDragEnd"
                    on-drag-canceled.bind="handleTaskDragCanceled"
                    task.bind="task"
                    state.two-way="state"
                    list.bind="list"
                    selected.two-way="selected"
                    workspace.bind="workspace"
                    language.bind="language"
                    is-last.bind="$index === tasks.length - 1"
                    index.bind="$index">
                </template-task>
            </template>
            <template if.bind="!baseLoaded && expanded">
                <template-task repeat.for="task of tasks" list.bind="list" loading.bind="true"> </template-task>
            </template>
        </div>
        <template if.bind="expanded ">
            <div css="border-left-color: ${list.color}" class="flex col-span-12 border-l-8 border-gray-300 rounded-bl-lg">
                <button if.bind="hasRole(UserRoles.WriteHealthcareTasks)" click.trigger="addTask()" class="flex items-center flex-1 gap-1 px-5 py-2 text-gray-400 hover:bg-blue-50 hover:text-blue-900">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span class="text-sm font-light" t="translation:partial-views.tasks.buttons.add-task"></span>
                </button>
                <div else class="py-4"></div>
            </div>
        </template>
    </div>
</div>
