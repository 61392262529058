import { bindable } from 'aurelia';
import { EventDetails } from '../../models/event-details';
import { UxInput } from '../ux-input/ux-input';

export class UxTags {
    @bindable() public tags: any[] = [];
    @bindable() public background: 'gray' | 'white' = 'gray';
    @bindable() public disabled: boolean;
    @bindable() public placeholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public language: string;

    public input: UxInput;

    public handleOnInput(e: CustomEvent<EventDetails<UxInput, string>>): void {
        const inner = e.detail.innerEvent as KeyboardEvent;
        if (inner.key === 'Enter') {
            this.tags.push(this.input.getText());
            this.input.clear();
        }
    }

    public handleRemove(index: number): void {
        this.tags.splice(index, 1);
    }
}
