<import from="converters/translate"></import>

<div css="min-width: ${settings.minColumnWidth}px;" class="flex w-full flex-col gap-1 py-2 pr-[8px]">
    <template if.bind="baseLoaded">
        <div
            repeat.for="task of myTasks"
            click.trigger="handleTaskSelect(task, $index, true)"
            class="flex min-w-0 flex-col rounded-lg border border-l-8 border-gray-300 border-l-primary bg-white px-3 py-1 hover:cursor-pointer focus:outline-none">
            <p class="truncate text-xs">${task.title | translate:language}</p>
            <!-- <p class="truncate text-xs text-gray-500" t="translation:global.labels.personal"></p> -->
        </div>
        <div
            css="border-left-color: ${task.list.data.listColor}"
            repeat.for="task of tasks"
            click.trigger="handleTaskSelect(task, $index,false)"
            class="flex min-w-0 flex-col rounded-lg border border-l-8 border-gray-300 bg-white px-3 py-1 hover:cursor-pointer focus:outline-none">
            <p class="truncate text-xs">${task.title | translate:language}</p>
            <!-- <p class="truncate text-xs text-gray-500">${task.list.translations | translate:language}</p> -->
        </div>
        <div if.bind="myTasks.length === 0 && tasks.length === 0" class="flex w-full items-center justify-center rounded-lg border border-dashed border-gray-300 px-2 py-1">
            <p class="text-xs lowercase text-gray-600" t="translation:bx.scheduler.labels.no-tasks"></p>
        </div>
    </template>
    <template else>
        <div class="flex py-2">
            <ux-skeleton show-background="true" type="container">
                <ux-skeleton type="line"></ux-skeleton>
            </ux-skeleton>
        </div>
    </template>
</div>
