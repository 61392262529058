import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, AttachmentsApiClient, BlobStorageAttachment } from '@wecore/sdk-attachments';
import { GetProSoftwareMedicalRecordResponse, ImportsApiClient, ProSoftwareReport } from '@wecore/sdk-healthcare';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { fileIsImage } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ImportsApiClient, AttachmentsApiClient)
export class PartialProSoftwareMedicalRecord extends BasePartialView {
    public record: GetProSoftwareMedicalRecordResponse;
    public tabs: any;
    public active: string;
    public customStates: any = {};
    public urls: any = {};
    public attachments: BlobStorageAttachment[] = [];
    private recordId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly importsApi: ImportsApiClient,
        private readonly attachmentsApi: AttachmentsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.recordId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.record = await this.importsApi.getProSoftwareMedicalRecord(this.recordId, this.authenticated.workspace.id);
                const tabs = {};

                for (let index = 0; index < this.record.examinations.length; index++) {
                    tabs[`examination-${index}`] = {
                        name: `${this.t.tr('translation:global.labels.examination')}${this.record.examinations.length > 1 ? ` ${index + 1}` : ''}`
                    };
                    if (index === 0) this.active = `examination-${index}`;

                    this.customStates[`examination-${index}`] = {
                        anamnesis: false,
                        examination: false
                    };
                }

                for (let index = 0; index < this.record.reports.length; index++) {
                    const report = this.record.reports.orderByDescending<ProSoftwareReport, Date>((x) => x.createdAt)[index];
                    tabs[`report-${index}`] = {
                        name: `${this.t.tr('translation:global.labels.report')} (${format(report.createdAt, 'dd-MM-yyyy')})`
                    };
                }

                tabs['attachments'] = {
                    name: this.t.tr('translation:global.labels.attachments')
                };

                this.tabs = tabs;

                // Find previously selected attachments if any.

                await Promise.all(
                    this.record.attachments.map(async (x) => {
                        const id = isDefined(x.thumbnail) ? x.thumbnail.id : x.id;
                        const url = await this.attachmentsApi.getUrl(this.record.id, id, this.authenticated.workspace.id, AttachmentEntities.MedicalRecords);
                        this.urls[x.id] = url;
                    })
                );

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialProsoftwareMedicalRecord.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public collapseOrExpand(index: string, section: 'anamnesis' | 'examination'): void {
        this.customStates[`examination-${index}`][section] = !this.customStates[`examination-${index}`][section];
    }

    public setActiveTab(tab: string): void {
        this.active = tab;
    }

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public async show(attachment: BlobStorageAttachment): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                entityId: this.record.id,
                entityType: AttachmentEntities.MedicalRecords,
                attachmentId: attachment.id,
                attachmentName: attachment.name,
                attachmentExtension: attachment.extension
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }
}
