<import from="converters/date" />

<div class="flex flex-col">
    <div class="flex items-start gap-10">
        <div class="w-96">
            <h5 class="mb-1 text-gray-900" t="translation:partial-views.sign-document.labels.personal-data"></h5>
            <span class="w-full text-xs text-gray-500" t="translation:partial-views.sign-document.messages.personal-data"></span>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-1 gap-3">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.first-name"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.firstName" value.two-way="firstName" disabled.bind="isLoading"></ux-input>
                </div>
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.last-name"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.lastName" value.two-way="lastName" disabled.bind="isLoading"></ux-input>
                </div>
            </div>
        </div>
    </div>
    <div class="flex items-start gap-10 mt-8">
        <div class="w-96">
            <h5 class="mb-1 text-gray-900" t="translation:global.labels.signature"></h5>
            <span class="w-full text-xs text-gray-500" t="translation:partial-views.sign-document.messages.signature"></span>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-col flex-1">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.sign-document.labels.location"></span>
                </ux-label>
                <ux-input valid.bind="validation.location" value.two-way="location" disabled.bind="isLoading" t="[placeholder]translation:partial-views.sign-document.placeholders.location"></ux-input>
            </div>
            <div class="flex flex-col flex-1 mt-6">
                <div class="flex flex-col flex-1">
                    <ux-checkbox has-label="true" valid.bind="validation.readDocument" checked.two-way="readDocument" disabled.bind="isLoading" class="mr-2">
                        <span class="text-sm text-gray-800" t="translation:partial-views.sign-document.messages.read-document"></span>
                    </ux-checkbox>
                </div>
            </div>
            <div class="flex flex-col flex-1 mt-6">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.sign-document.labels.date"></span>
                    </ux-label>
                    <span class="text-sm font-medium">${ today | date }</span>
                </div>
            </div>
        </div>
    </div>
</div>
