<template class="!flex overflow-hidden bg-white border-r-1 border-gray-200" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" css.bind="styles">
        <div class="flex flex-col p-5 flex-1">
            <div class="mb-6 flex justify-end">
                <button class="flex items-center justify-center rounded-full bg-gray-100 p-1 hover:bg-gray-200" click.trigger="remove()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
                        <path
                            fill-rule="evenodd"
                            d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
            <p>Medication details</p>
        </div>
    </section>
</template>
