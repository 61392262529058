import { SliderIncrementalLabels } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { bindable } from 'aurelia';
import noUiSlider, { PipsMode } from 'nouislider';

export class UxRangeSlider {
    @bindable() public value: number;
    @bindable() public start: number = -2;
    @bindable() public end: number = 2;
    @bindable() public initial: number;
    @bindable() public majorIncrement: number = 1;
    @bindable() public minorIncrement: number = 0.1;
    @bindable() public labels: SliderIncrementalLabels[] = [];
    @bindable() public language: string;

    public slider: HTMLDivElement;
    public label: SliderIncrementalLabels;
    public used: boolean = false;
    private sliderInstance: any;

    public bound(): void {
        const generateRange = (start: number, end: number, step: number) => {
            const numbersInRange = []; // Create an empty array to store the numbers in the range

            if (step === 0) {
                return numbersInRange; // Avoid division by zero, return an empty array if step is 0
            }

            const multiplier = Math.sign(end - start); // Determine the direction of the range (positive or negative)
            const absStep = Math.abs(step); // Get the absolute value of the step

            // Determine the number of decimal places based on the step value
            const decimalPlaces = step.toString().split('.')[1]?.length || 0;

            // Loop to generate the range of numbers
            for (let num = start; multiplier * (num - end) <= 0; num += multiplier * absStep) {
                // Add each number to the array with the correct number of decimal places
                numbersInRange.push(Number(num.toFixed(decimalPlaces)));
            }

            // Include the end value in the array if it's not already included
            if (numbersInRange[numbersInRange.length - 1] !== end.toFixed(decimalPlaces)) {
                numbersInRange.push(Number(end.toFixed(decimalPlaces)));
            }

            return numbersInRange;
        };

        const matchLabel = (label: SliderIncrementalLabels, value: number): boolean => {
            if (isNotDefined(label.sliderValues)) return false;

            if (label.sliderValues.includes('/')) {
                const [start, end] = label.sliderValues
                    .split('/')
                    .map((x) => x.replace(',', '.'))
                    .map(Number);

                const range = generateRange(start, end, this.minorIncrement);
                return range.includes(Number(value));
            } else return Number(label.sliderValues) === Number(value);
        };

        if (isDefined(this.value)) this.label = this.labels.find((x) => matchLabel(x, this.value));
        else if (isDefined(this.initial)) this.label = this.labels.find((x) => matchLabel(x, this.initial));
        else this.label = this.labels.find((x) => matchLabel(x, this.start));

        if (isDefined(this.initial) && isNotDefined(this.value)) this.value = this.initial;
        if (isDefined(this.value)) this.used = true;

        this.sliderInstance = noUiSlider.create(this.slider, {
            start: this.value ?? ((this.start ?? 0) + (this.end ?? 0)) / 2,
            connect: true,
            range: {
                min: this.start,
                max: this.end
            },
            tooltips: true,
            step: this.minorIncrement,
            pips: {
                mode: PipsMode.Steps,
                density: 2,
                filter: (value: number, type: number) => {
                    return value % this.majorIncrement ? 2 : 1;
                }
                // format: (value) => {
                //     return value.toFixed(2);
                // }
            } as any
        });

        this.sliderInstance.on('set', (values: number[]) => {
            this.value = Number(values[0]);
            this.used = true;
            this.label = this.labels.find((x) => matchLabel(x, this.value));
        });
    }

    public detaching(): void {
        this.sliderInstance.destroy();
    }

    public setValue(value: string): void {
        this.value = Number(value);
        if (isDefined(this.sliderInstance)) this.sliderInstance.set(this.value);
    }
}
