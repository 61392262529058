<import from="./ux-skeleton.scss"></import>

<template>
    <div class="flex flex-col w-full h-full">
        <div if.bind="type === 'container'" rounded-full.class="roundend === 'full'" rounded-lg.class="rounded === 'lg'" bg-gray-50.class="showBackground" class="flex w-full h-full skeleton">
            <au-slot />
        </div>
        <div if.bind="type === 'line'" class="${padding}">
            <div class="w-full h-2 bg-gray-200 rounded-lg"></div>
        </div>
    </div>
</template>
