import { State } from '../state';

/**
 * Sets a new language
 * @param state The current state.
 * @param language The language to set.
 * @returns The updated state.
 */
export function setLanguage(state: State, language: string): State {
    const newState = { ...state };
    newState.language = language;
    return newState;
}
