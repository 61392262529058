export class MonthToStringValueConverter {
    public toView(month: number): string {
        switch (month) {
            case 1:
                return 'translation:global.labels.months.january';
            case 2:
                return 'translation:global.labels.months.februari';
            case 3:
                return 'translation:global.labels.months.march';
            case 4:
                return 'translation:global.labels.months.april';
            case 5:
                return 'translation:global.labels.months.may';
            case 6:
                return 'translation:global.labels.months.june';
            case 7:
                return 'translation:global.labels.months.july';
            case 8:
                return 'translation:global.labels.months.august';
            case 9:
                return 'translation:global.labels.months.september';
            case 10:
                return 'translation:global.labels.months.october';
            case 11:
                return 'translation:global.labels.months.november';
            case 12:
                return 'translation:global.labels.months.december';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
