<import from="converters/translate" />
<import from="converters/format-time" />
<import from="converters/keys" />
<import from="converters/day-to-string" />
<import from="converters/date" />
<import from="converters/schedule-particular-to-string" />
<import from="bx/bx-practice-location-selector/bx-practice-location-selector" />

<template class="relative !flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-1 gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 gap-5 overflow-hidden">
                <template if.bind="baseLoaded">
                    <div max-w-[400px].class="location" pr-8.class="!location" pr-3.class="location" class="flex flex-col overflow-auto pl-8">
                        <div class="sticky top-0 z-50 flex flex-col gap-3 border-b border-gray-200 bg-white pb-3 pt-5">
                            <div class="flex justify-between">
                                <p class="font-medium text-gray-400" t="translation:partial-views.manage-schedules.create.title"></p>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="translation:partial-views.manage-schedules.labels.select-location"></span>
                                    </ux-label>
                                    <span class="flex h-3 items-center text-red-500">*</span>
                                </div>
                                <bx-practice-location-selector
                                    on-select.bind="handleLocationSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]translation:partial-views.manage-schedules.placeholders.select-location"
                                    valid.bind="validation.location"
                                    value.bind="request.location.id"
                                    ed>
                                </bx-practice-location-selector>
                                <p class="mt-2 text-xs text-gray-500" t="translation:partial-views.manage-schedules.messages.changing-location"></p>
                            </div>
                            <template if.bind="errorCode">
                                <ux-alert color="warning">
                                    <div class="text-xs">
                                        <span if.bind="errorCode === 'S401'" t="translation:partial-views.manage-schedules.messages.error-S401"></span>
                                        <span if.bind="errorCode === 'S402'" t="translation:partial-views.manage-schedules.messages.error-S402"></span>
                                        <span if.bind="errorCode === 'S403'" t="translation:partial-views.manage-schedules.messages.error-S403"></span>
                                        <span if.bind="errorCode === 'S404'" t="translation:partial-views.manage-schedules.messages.error-S404"></span>
                                        <span if.bind="errorCode === 'S405'" t="translation:partial-views.manage-schedules.messages.error-S405"></span>
                                    </div>
                                </ux-alert>
                            </template>

                            <div class="flex flex-col text-xs text-red-500">
                                <p if.bind="!validation.any" t="translation:partial-views.manage-schedules.messages.missing-time-slots"></p>
                                <p if.bind="!validation.timeSlots" t="translation:partial-views.manage-schedules.messages.missing-time-slots"></p>
                                <div class="comma-list inline whitespace-normal" if.bind="!validation.validRegistrations">
                                    <span class="skip-comma" t="translation:partial-views.manage-schedules.messages.invalid-registrations"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[0].valid" t="translation:global.days.long.sunday"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[1].valid" t="translation:global.days.long.monday"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[2].valid" t="translation:global.days.long.tuesday"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[3].valid" t="translation:global.days.long.wednesday"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[4].valid" t="translation:global.days.long.thursday"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[5].valid" t="translation:global.days.long.friday"></span>
                                    <span class="font-semibold" if.bind="!validation.registrations[6].valid" t="translation:global.days.long.saturday"></span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-1 flex-col gap-3 pb-5 pt-3">
                            <div class="flex gap-1">
                                <ux-button padding.bind="false" disabled.bind="!request.location" click.trigger="previousDay()">
                                    <div class="flex h-[36px] w-[36px] items-center justify-center">
                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                            <path
                                                fill="currentColor"
                                                d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                                        </svg>
                                    </div>
                                </ux-button>
                                <div
                                    border-red-500.class="!validation.registrations[activeIndex].valid"
                                    text-red-500.class="!validation.registrations[activeIndex].valid"
                                    border-gray-300.class="validation.registrations[activeIndex].valid"
                                    class="flex h-[38px] flex-1 items-center justify-center truncate rounded-lg border bg-gray-50 px-3 text-sm">
                                    <span>${day | dayToString | t}</span>
                                </div>
                                <ux-button padding.bind="false" disabled.bind="!request.location" click.trigger="nextDay()">
                                    <div class="flex h-[36px] w-[36px] items-center justify-center">
                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                            <path
                                                fill="currentColor"
                                                d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                        </svg>
                                    </div>
                                </ux-button>
                            </div>
                            <div if.bind="!location" class="flex items-center justify-items-center">
                                <div
                                    class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 active:ring-primary">
                                    <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                    </svg>

                                    <span class="mt-2 block text-sm font-semibold text-gray-900" t="translation:partial-views.manage-schedules.messages.select-location"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div if.bind="location" class="flex flex-1 overflow-hidden py-5 pr-5">
                        <div class="flex h-full flex-1 overflow-y-hidden rounded-lg border border-gray-200 bg-gray-50">
                            <div border-l.class="$index !== 0" class="flex flex-1 flex-col overflow-y-auto border-gray-200 px-4" repeat.for="registration of active.roomRegistrations">
                                <div class="sticky top-0 z-10 flex min-w-0 items-center justify-between gap-4 border-b border-gray-200 bg-gray-50 py-3">
                                    <p class="truncate text-sm text-gray-800">${registration.room.name | translate:language}</p>
                                    <template if.bind="scheduleState[activeIndex].rooms[$index].loading">
                                        <ux-spinner size="xs"></ux-spinner>
                                    </template>
                                    <ux-button else click.trigger="createOrEditSlot($index)" padding.bind="false" size="xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </ux-button>
                                </div>

                                <div class="flex flex-1 gap-3 py-3">
                                    <div class="flex w-[30px] flex-col pt-1">
                                        <div repeat.for="hour of hours" class="relative h-full flex-1">
                                            <div class="-mt-2 pr-2 text-right text-xxs uppercase text-gray-400">${hour.label}</div>
                                        </div>
                                    </div>
                                    <div class="relative flex flex-1 flex-col gap-1" if.bind="!scheduleState[activeIndex].rooms[$index].loading">
                                        <div
                                            repeat.for="slot of registration.slots"
                                            class="absolute right-0 flex w-full flex-col rounded-xl border-2 border-gray-50 hover:!z-[9999]"
                                            css.bind="generateStyling(slot, $parent.$index)">
                                            <div class="flex flex-1 items-start justify-between gap-2">
                                                <button class="flex h-full min-w-0 p-4 focus:outline-none" click.trigger="createOrEditSlot($parent.$index, $index)">
                                                    <div class="flex min-w-0 flex-col text-left">
                                                        <p
                                                            text-red-500.class="!validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            text-gray-800.class="validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            class="truncate text-sm font-semibold">
                                                            ${slot.practitioner.name}
                                                        </p>
                                                        <p
                                                            text-red-500.class="!validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            text-gray-800.class="validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            class="text-sm">
                                                            ${slot.start | formatTime} - ${slot.end | formatTime}
                                                        </p>
                                                        <p
                                                            if.bind="slot.particulars"
                                                            text-red-500.class="!validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            text-gray-500.class="validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            class="text-xs">
                                                            ${slot.particulars | scheduleParticularToString | t}
                                                        </p>
                                                        <div
                                                            if.bind="slot.validFrom || slot.validUntil"
                                                            class="mt-1 flex flex-col"
                                                            text-red-500.class="!validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid"
                                                            text-gray-500.class="validation.registrations[activeIndex].rooms[$parent.$index].slots[$index].valid">
                                                            <div if.bind="slot.validFrom" class="flex gap-1 text-xs">
                                                                <p t="translation:partial-views.manage-schedules.labels.valid-from"></p>
                                                                <p class="font-semibold">${slot.validFrom | date:'dd-MM-yyyy'}</p>
                                                            </div>
                                                            <div if.bind="slot.validUntil" class="flex gap-1 text-xs">
                                                                <p t="translation:partial-views.manage-schedules.labels.valid-until"></p>
                                                                <p class="font-semibold">${slot.validUntil | date:'dd-MM-yyyy'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div class="pr-4 pt-4">
                                                    <ux-dropdown
                                                        transparent.bind="true"
                                                        border.bind="false"
                                                        type="full"
                                                        color="dark"
                                                        size="sm"
                                                        padding.bind="false"
                                                        mode="outline"
                                                        placement="bottom-right">
                                                        <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                            <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                            </svg>
                                                        </div>
                                                        <ux-dropdown-option click.trigger="removeTimeSlot($parent.$index, $index)">
                                                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                        </ux-dropdown-option>
                                                    </ux-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col px-8 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="flex justify-end gap-x-2 border-t border-gray-200 bg-white px-8 py-4">
                <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                    <span class="pointer-events-none" t="global.buttons.cancel"></span>
                </ux-button>
                <ux-button size="sm" color="success" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save()" t="[text-when-loading]translation:global.labels.loading-states.default">
                    <span class="pointer-events-none" t="global.buttons.save"></span>
                </ux-button>
            </div>
        </div>
    </section>
    <div if.bind="partial.canAdjustWidth && location" mousedown.trigger="handleResizeMouseDown($event)" class="absolute right-0 z-50 h-full w-2 hover:cursor-col-resize"></div>
</template>
