import { DeclarationPerformanceStatuses } from '@wecore/sdk-healthcare';

export class DeclarationPerformanceStatusToStringValueConverter {
    public toView(type: DeclarationPerformanceStatuses): string {
        switch (type) {
            case DeclarationPerformanceStatuses.Draft:
                return 'translation:global.labels.draft';
            case DeclarationPerformanceStatuses.IncludedInDeclaration:
                return 'translation:global.labels.declaration-statuses.included-in-declaration';
            case DeclarationPerformanceStatuses.SentToVecozo:
                return 'translation:global.labels.declaration-statuses.sent-to-vecozo';
            case DeclarationPerformanceStatuses.RejectedByVecozo:
                return 'translation:global.labels.declaration-statuses.rejected-by-vecozo';
            case DeclarationPerformanceStatuses.Rejected:
                return 'translation:global.labels.rejected';
            case DeclarationPerformanceStatuses.Accepted:
                return 'translation:global.labels.awarded';
            case DeclarationPerformanceStatuses.PartiallyAccepted:
                return 'translation:global.labels.declaration-statuses.partially-accepted';
            case DeclarationPerformanceStatuses.DownloadedForInfomedics:
                return 'translation:global.labels.declaration-statuses.downloaded-for-infomedics';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
