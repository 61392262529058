import { I18N } from '@aurelia/i18n';
import { ContactType, ContactTypesApiClient } from '@wecore/sdk-crm';
import { isDefined, isEmpty, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(ContactTypesApiClient, I18N, ErrorHandler)
export class BxContactTypeSelector {
    @bindable() public value: string;
    @bindable() public selected: ContactType;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public onSelect: (type: ContactType, data: any) => void;

    public combobox: UxCombobox;
    public types: ContactType[];

    public constructor(
        private readonly typesApi: ContactTypesApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public handleClear(): void {
        if (isFunction(this.onSelect)) this.onSelect(null, this.data);
    }

    public async attached(): Promise<void> {
        try {
            let type: ContactType;
            if (isNotEmpty(this.value)) {
                type = await this.typesApi.getById(this.value, this.workspace);
                // If the selected item is not found, use the selected object which can
                // be provided by the user and used to display the object name in the combobox.
                // This can be handy in case the item has been deleted.
                if (isNotDefined(type)) type = this.selected;
            }

            const response = await this.typesApi.search(this.workspace, '');
            const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
            this.types = isDefined(type) ? [type].concat(data.filter((x) => x.id !== this.value)) : data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxContactTypeSelector.attached', e);
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.typesApi.search(this.workspace, isNotEmpty(query) ? query : '');
            this.types = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxContactTypeSelector.handleSearch', e);
        }
    }

    public async handleSelect(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        if (e.detail.values.customInput as boolean) return;
        const option = e.detail.values as UxComboboxOption;

        const type = this.types.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(type, this.data);
    }

    public async create(): Promise<void> {
        this.combobox.isLoading = true;
        const text = await this.combobox.getText();
        if (isEmpty(text)) {
            this.combobox.isLoading = false;
            return;
        }

        const type = await this.typesApi.create(
            this.workspace,
            new ContactType({
                translations: {
                    [this.language]: text
                }
            })
        );
        if (isFunction(this.onSelect)) this.onSelect(type, this.data);
        this.combobox.isLoading = false;
        this.combobox.close();
    }
}
