import { isDefined, isEmpty, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, inject } from 'aurelia';
import { onResize } from '../../infra/utilities';

@inject(Element)
export class UxTableCell {
    @bindable() public width: string;
    @bindable() public showOn: string;
    @bindable() public fullWidth: boolean = false;
    @bindable() public location: 'body' | 'header' = 'body';

    public breakpoints: string[] = [];
    public static breakpoints: string[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];

    public constructor(
        private readonly element: HTMLElement //
    ) {}

    private timeout: any;
    private delimiter: string = ' ';
    private onResize: () => void;

    public bound(): void {
        this.setBreakpoints();

        onResize((breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl') => this.setWidthBasedOnBreakpoint(breakpoint));

        this.onResize = () => {
            // To prevent using to much computing power, delay the function by using a timeout.
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => onResize((breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl') => this.setWidthBasedOnBreakpoint(breakpoint)), 10);
        };
        document.addEventListener('resize', this.onResize);
    }

    public detaching(): void {
        document.removeEventListener('resize', this.onResize);
    }

    showOnChanged(): void {
        this.setBreakpoints();
    }

    private setBreakpoints(): void {
        if (isNotDefined(this.showOn) || isEmpty(this.showOn)) {
            this.element.classList.add('flex');
            return;
        }

        // Get all visible items.
        this.breakpoints = [].concat(this.showOn.split(this.delimiter));

        // Cleanup the original classes/breakpoints.
        this.element.classList.remove('hidden', 'flex', 'sm:flex', 'md:flex', 'lg:flex', 'xl:flex', '2xl:flex', 'sm:hidden', 'md:hidden', 'lg:hidden', 'xl:hidden', '2xl:hidden');

        // We don't have any new classes
        if (this.breakpoints.length === 0) this.element.classList.add('flex');
        else {
            this.element.classList.add('hidden');
            for (const breakpoint of this.breakpoints) {
                const isSelected = this.breakpoints.some((x) => x === breakpoint);
                if (isSelected) this.element.classList.add(`${breakpoint}:flex`);
                else this.element.classList.add(`${breakpoint}:hidden`);
            }
        }
    }

    private setWidthBasedOnBreakpoint(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'): void {
        // Small helper functions to keep things DRY.
        const format = (width: string): string => `${width}px`;
        const setFlex = (): void => this.element.classList.add('flex-1');
        const getWidthForBreakpoint = (widths: string[], brk: string): string => {
            const foundBreakpoint = widths.find((x) => x.split(':')[0] === brk);
            return isDefined(foundBreakpoint) ? foundBreakpoint.split(':')[1] : null;
        };

        let widthToSet: string;
        // Make sure any previously added flex classes are removed.
        this.element.classList.remove('flex-1');
        // Set the full width if a specific width is not provided.
        if (isNotDefined(this.width)) setFlex();
        else {
            // Try to collect all breakpoint widths by splitting the
            // provided width string on the configured delimiter.
            const widths = this.width.split(this.delimiter);
            // If only width is provided, the width value might be a single number.
            if (widths.length === 1) {
                // Check if just a single number value is provided.
                const parsed = parseInt(widths[0], 10);
                if (isNaN(parsed)) {
                    // Try to find the width for the provided breakpoint
                    const width = getWidthForBreakpoint(widths, breakpoint);
                    // If the width is found, set it. Otherwise use full width.
                    if (isDefined(width)) widthToSet = format(width);
                    else setFlex();
                    // Set the width to the single number.
                } else widthToSet = format(widths[0]);
            } else {
                // Set the correct width based on the provided breakpoint.
                const width = getWidthForBreakpoint(widths, breakpoint);
                if (isDefined(width)) widthToSet = format(width);
                else setFlex();
            }
        }

        if (isDefined(widthToSet)) this.element.style.width = widthToSet;
    }
}
