<import from="converters/translate" />

<ux-combobox
    init="false"
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    padding="small"
    onselect.trigger="handleSelect($event)"
    onclear.trigger="handleClear($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option padding="false" repeat.for="label of labels" value.bind="label.id">
        <div class="flex items-center gap-2">
            <div class="h-4 w-4 rounded-full" css="background-color: ${label.backgroundColor}; color: ${label.textColor}"></div>
            <div class="flex flex-col">
                <p class="text-sm">${label.name | translate:language}</p>
            </div>
        </div>
    </ux-combobox-option>
</ux-combobox>
