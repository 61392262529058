export interface ISchedulerPeriod {
    hour: number;
    display: string;
}

export class SchedulerPeriod {
    public hour: number;
    public display: string;

    public constructor(data: ISchedulerPeriod) {
        this.hour = data?.hour;
        this.display = data?.display;
    }
}
