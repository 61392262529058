<template hidden.class="hide" data-value.bind="value" click.trigger="handleClicked($event)">
    <a
        mouseover.trigger="handleHover()"
        data-parent.bind="parent"
        font-bold.class="selected"
        bg-gray-100.class="focused"
        class="block rounded-lg px-4 py-2 text-sm text-gray-700"
        role="menuitem"
        tabindex="-1">
        <div class="pointer-events-none flex justify-between gap-3">
            <div data-type="content" class="flex">
                <au-slot />
            </div>
            <svg if.bind="selected" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
            </svg>
        </div>
    </a>
</template>
