import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AccountsApiClient, Email, GetAccountResponse, Link, Phone } from '@wecore/sdk-crm';
import {} from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AccountsApiClient, ModalService)
export class PartialAccountsEdit extends BasePartialView {
    public tabs: any;
    public account: GetAccountResponse;
    public validation: any = {
        displayName: true,
        emails: [],
        phones: [],
        links: []
    };
    private accountId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly accountsApi: AccountsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.accountId = view.data.id;

        this.tabs = {
            general: {
                name: this.t.tr('translation:partial-views.accounts.labels.general'),
                valid: true,
                active: true
            },
            addresses: {
                name: this.t.tr('translation:partial-views.accounts.labels.addresses'),
                valid: true,
                active: false
            },
            connections: {
                name: this.t.tr('translation:global.labels.connections'),
                active: false,
                valid: true
            }
        };
    }

    public attached(): void {
        super.initView().then(async () => {
            this.account = await this.accountsApi.getById(this.accountId, this.authenticated.workspace.id);
            this.validation.emails = this.account.emails?.map((_: Email) => ({ email: true, phone: true, valid: true })) || [];
            this.validation.phones = this.account.phones?.map((_: Phone) => ({ phone: true, email: true, valid: true })) || [];
            this.validation.links = this.account.links?.map((_: Email) => ({ phone: true, email: true, valid: true })) || [];

            // Check for old links, emails, phones and addresses and update them.
            this.account.phones =
                this.account.phones?.map((phone: Phone) => {
                    if (isNotDefined(phone.translations)) phone.translations = { [this.language]: phone.description };
                    if (isNotDefined(phone.callingCode)) phone.callingCode = '31';
                    return phone;
                }) || [];

            this.account.emails =
                this.account.emails?.map((email: Email) => {
                    if (isNotDefined(email.translations)) email.translations = { [this.language]: email.description };
                    return email;
                }) || [];

            this.account.links =
                this.account.links?.map((link: Link) => {
                    if (isNotDefined(link.translations)) link.translations = { [this.language]: link.description };
                    if (isNotDefined(link.protocol)) link.protocol = 'http://';
                    return link;
                }) || [];

            this.baseLoaded = true;
        });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public setActiveTab(key: string): void {
        for (const prop in this.tabs) this.tabs[prop].active = false;
        this.tabs[key].active = true;
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;

            this.account.displayName?.trim();

            // Clean values that are not valid.
            this.account.emails = this.account.emails.filter((email: Email) => {
                const cleaned = email.value?.replace(/_/g, '');
                return isDefined(cleaned) && isNotEmpty(cleaned);
            });

            this.account.phones = this.account.phones.filter((phone: Phone) => {
                const cleaned = phone.value?.replace(/_/g, '');
                return isDefined(cleaned) && isNotEmpty(cleaned);
            });

            this.account.links = this.account.links.filter((link: Link) => {
                return isDefined(link.value) && isNotEmpty(link.value);
            });

            try {
                const account = await this.accountsApi.update(this.accountId, this.authenticated.workspace.id, this.account);
                this.remove({ result: PartialViewResults.Ok, data: account, updateUrl: true });
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[accounts/edit]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.accounts.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.accounts.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.account.displayName}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.accountsApi.delete(this.accountId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.accounts.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.accounts.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.account.displayName}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[accounts/delete]', e);
                        }
                    }
                }
            })
        );
    }

    private validate(): boolean {
        this.validation.displayName = isNotEmpty(this.account.displayName);

        for (let index = 0; index < this.account.emails.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.emails[index]);

            const email = this.account.emails[index].value;
            // Only validate if user entered something.
            if (isNotDefined(email) || isEmpty(email)) continue;
            // Validate the phone number
            this.validation.emails[index].email =
                isNotEmpty(email) &&
                isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);
            this.validation.emails[index].valid = this.validation.emails[index].email;
        }

        for (let index = 0; index < this.account.phones.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.phones[index]);
            // Remove mask placeholder characters.
            const phone = this.account.phones[index].value?.replace(/_/g, '') || undefined;
            // Only validate if user entered something.
            if (isNotDefined(phone) || isEmpty(phone)) continue;
            // Validate the phone number
            this.validation.phones[index].phone = phone.length === 9;
            this.validation.phones[index].valid = this.validation.phones[index].phone;
        }

        this.tabs.general.valid =
            validateState(this.validation) && //
            this.validation.emails.every((e: any) => e.valid) &&
            this.validation.phones.every((e: any) => e.valid);

        return this.tabs.general.valid;
    }
}
