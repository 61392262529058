import { DeclarationSendMethods } from '@wecore/sdk-healthcare';

export class DeclarationSendMethodToStringValueConverter {
    public toView(type: DeclarationSendMethods): string {
        switch (type) {
            case DeclarationSendMethods.Vecozo:
                return 'translation:global.labels.declaration-send-methods.vecozo';
            case DeclarationSendMethods.InfomedicsDirect:
                return 'translation:global.labels.declaration-send-methods.infomedics-patient';
            case DeclarationSendMethods.InfomedicsInsurance:
                return 'translation:global.labels.declaration-send-methods.infomedics-insurance';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
