import { I18N } from '@aurelia/i18n';
import { GetHealthcareInvoiceResponse, HealthcareInvoicesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotEmpty } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { UxMultiSelector } from '../../ux/ux-multi-selector/ux-multi-selector';

@containerless
@inject(HealthcareInvoicesApiClient, I18N, ErrorHandler)
export class BxInvoiceMultiSelector {
    @bindable() public values: string[];
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public onChange: (options: GetHealthcareInvoiceResponse[]) => void;
    @bindable() public onClear: () => void;

    public invoices: GetHealthcareInvoiceResponse[];
    public options: { text: string; value: string; data?: any }[];
    public selector: UxMultiSelector;
    private selection: GetHealthcareInvoiceResponse[] = [];

    public constructor(
        private readonly invoicesApi: HealthcareInvoicesApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = this.t.tr('global.labels.search');
    }

    public async attached(): Promise<void> {
        try {
            if (isDefined(this.values) && this.values.any()) {
                const response = await this.invoicesApi.search(this.workspace, undefined, this.values.length, 0, undefined, undefined, undefined, this.values);
                this.selection = response.data;
            }

            const response = await this.invoicesApi.search(this.workspace, '', 50, 0);
            // Combine the selection with the response so that the getOption()
            // in the ux-multi-selector.ts can find the correct option.
            this.invoices = [
                // Make sure the selection is sorted in the same order as the values.
                ...this.selection.sort((a, b) => {
                    const indexA = this.values.indexOf(a.id);
                    const indexB = this.values.indexOf(b.id);
                    return indexA - indexB;
                }),
                ...response.data
            ];

            this.map();
        } catch (e) {
            this.errorHandler.handle('BxInvoiceMultiSelector.attached', e);
        }
    }

    public handleSearch = async (query: string): Promise<void> => {
        try {
            const response = await this.invoicesApi.search(this.workspace, isNotEmpty(query) ? query : '', 50, 0);
            this.invoices = response.data;

            this.map();
        } catch (e) {
            this.errorHandler.handle('BxInvoiceMultiSelector.handleSearch', e);
        }
    };

    public handleSelected = async (option: { text: string; value: string; data?: any }): Promise<void> => {
        const region = this.invoices.find((x) => x.id === option.value);
        this.selection = [...this.selection, region];
        if (isFunction(this.onChange)) this.onChange(this.selection);
    };

    public handleRemoved = async (option: { text: string; value: string; data?: any }): Promise<void> => {
        const index = this.selection.findIndex((x) => x.id === option.value);
        if (index > -1) {
            this.selection.splice(index, 1);
            this.values.splice(index, 1);
        }
        if (isFunction(this.onChange)) this.onChange(this.selection);
    };

    public handleClear(): void {
        if (isFunction(this.onClear)) this.onClear();
    }

    private map(): void {
        this.options = this.invoices
            .filter((x) => isDefined(x.trackingNumber) || isDefined(x.conceptNumber))
            .map((x) => ({
                text: `${x.trackingNumber?.toString() ?? `${this.t.tr('translation:global.labels.concept')} ${x.conceptNumber.toString()}`}`,
                value: x.id
            }));
    }
}
