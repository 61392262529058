<template class="flex">
    <a
        href.bind="href"
        class="${padding} inline-flex flex-nowrap items-center whitespace-nowrap text-sm font-medium shadow-none"
        group.class="mode === 'light'"
        border-b-2.class="mode === 'light'"
        border-transparent.class="mode === 'light'"
        py-4.class="mode === 'light'"
        border-gray-600.class="active && mode === 'light' && valid"
        text-gray-700.class="active && mode === 'light' && valid"
        hover.text-gray-900.class="active && mode === 'light' && valid"
        text-gray-600.class="!active && mode === 'light' && valid"
        hover.border-gray-300.class="!active && mode === 'light' && valid"
        hover.text-gray-800.class="!active && mode === 'light' && valid"
        rounded-md.class="mode === 'dark'"
        text-sm.class="mode === 'dark'"
        font-medium.class="mode === 'dark'"
        bg-gray-200.class="active && mode === 'dark' && valid"
        bg-red-100.class="active && mode === 'dark' && !valid"
        text-gray-600.class="!active && mode === 'dark' && valid"
        hover.text-black.class="!active && mode === 'dark' && valid"
        border-red-500.class="active && !valid && mode === 'dark'"
        text-gray-800.class="mode === 'dark' && valid"
        text-red-500.class="!valid">
        <div -ml-0.5.class="hasIcon || !valid" mr-1.class="hasIcon || !valid">
            <svg class="${icon}" hidden.class="valid" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span hidden.class="!valid">
                <au-slot name="icon" />
            </span>
        </div>
        <div class="flex items-center">
            <au-slot />
        </div>
    </a>
</template>
