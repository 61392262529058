import { isNotDefined } from '@wecore/sdk-utilities';
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import { nl } from 'date-fns/locale';

export class TimeAgoValueConverter {
    public toView(value: Date, includeSeconds: boolean = false, addSuffix: boolean = false, strict: boolean = false): string {
        if (isNotDefined(value)) return '';

        // Shorten the time ago
        const formatDistanceLocale = {
            lessThanXSeconds: '{{count}}s',
            xSeconds: '{{count}}s',
            halfAMinute: '30s',
            lessThanXMinutes: '{{count}}m',
            xMinutes: '{{count}}m',
            aboutXHours: '{{count}}h',
            xHours: '{{count}}h',
            xDays: '{{count}}d',
            aboutXWeeks: '{{count}}w',
            xWeeks: '{{count}}w',
            aboutXMonths: '{{count}}m',
            xMonths: '{{count}}m',
            aboutXYears: '{{count}}y',
            xYears: '{{count}}y',
            overXYears: '{{count}}y',
            almostXYears: '{{count}}y'
        };

        const formatDistance = (token: string, count: number, options: any) => {
            options = options || {};
            const result = formatDistanceLocale[token].replace('{{count}}', count);
            if (options.addSuffix) {
                if (options.comparison > 0) return 'in ' + result;
                else return result + ' ago';
            }
            return result;
        };
        return strict
            ? formatDistanceToNowStrict(value, {
                  addSuffix,
                  locale: {
                      ...nl,
                      formatDistance
                  }
              }) //
            : formatDistanceToNow(value, { includeSeconds, addSuffix, locale: nl });
    }
}
