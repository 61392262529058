import { MedicalExaminationActionItem, MedicalQuestionRegistration } from '@wecore/sdk-healthcare';

export interface ICopyQuestionItem {
    item: MedicalQuestionRegistration;
    array: MedicalQuestionRegistration[];
    index: number;
    validation: any[];
    validationItem: any;
    command: 'copy' | 'cut';
}

export class CopyQuestionItem {
    public item: MedicalExaminationActionItem;
    public array: MedicalExaminationActionItem[];
    public index: number;
    public validation: any[];
    public validationItem: any;
    public command: 'copy' | 'cut';

    public constructor(data: ICopyQuestionItem) {
        this.item = data?.item;
        this.array = data?.array;
        this.index = data?.index;
        this.validation = data?.validation;
        this.validationItem = data?.validationItem;
        this.command = data?.command;
    }
}
