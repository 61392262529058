<import from="bx/bx-contact-selector/bx-contact-selector" />

<div class="flex flex-col">
    <ux-label class="mb-1">
        <span t="translation:components.widgets.patient-general-practitioner.labels.select-general-practitioner"></span>
    </ux-label>
    <bx-contact-selector
        if.bind="type"
        on-select.bind="handleContactSelected"
        language.bind="language"
        workspace.bind="workspace"
        types.bind="[type.id]"
        t="[placeholder]translation:components.widgets.patient-general-practitioner.placeholders.select-general-practitioner"
        open.bind="createGp"
        value.two-way="contact.id">
    </bx-contact-selector>
</div>
