<ux-combobox
    init="false"
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    padding="small"
    onselect.trigger="handleSelect($event)"
    onclear.trigger="handleClear($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option padding="false" repeat.for="user of users" value.bind="user.id">
        <div class="flex items-center space-x-1.5">
            <div class="${getBackgroundColor(user)} flex h-6 w-6 items-center justify-center rounded-full leading-none">
                <span class="${getTextColor(user)} text-xs leading-none">${getInititals(user)}</span>
            </div>
            <span>${ user.displayName }</span>
        </div>
    </ux-combobox-option>
</ux-combobox>
