import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { CreateUserPostRequest, GetUserPostResponse, IDocumentStoreEntityEntityReference, UserPostsApiClient } from '@wecore/sdk-core';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { TemplatePostAttachments } from './template-post-attachments/template-post-attachments';
import { TemplatePostInput } from './template-post-input/template-post-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, UserPostsApiClient, ModalService, AureliaConfiguration)
export class PartialUserPosts extends BasePartialView {
    public posts: GetUserPostResponse[];
    public isReplying: boolean = false;
    public input: TemplatePostInput;
    public validation: any = {
        content: true
    };
    public references: IDocumentStoreEntityEntityReference[];
    public uploads: any[] = [];
    public picker: TemplatePostAttachments;

    private entityId: string;
    private entityType: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly postsApi: UserPostsApiClient,
        private readonly modalService: ModalService,
        private readonly config: AureliaConfiguration
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.entityId = view.data.entityId;
        this.entityType = view.data.entityType;
        this.references = view.data.references ?? [];
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const response = await this.postsApi.search(this.authenticated.workspace.id, 500, 0, undefined, undefined, undefined, undefined, [this.entityId], undefined, false);
                this.posts = response.data;

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialUserPosts.attached', x));
    }

    public handleCancel = (): void => {
        if (isDefined(this.input)) this.input.clear(false);
        this.isReplying = false;
        this.uploads = [];
    };

    public handleNewReply = async (): Promise<void> => {
        const valid = this.input.validate();
        if (!valid) return;

        const content = this.input.getContents();
        try {
            const post = new CreateUserPostRequest({
                content,
                references: [
                    new IDocumentStoreEntityEntityReference({
                        id: this.entityId,
                        type: this.entityType
                    }),
                    ...this.references
                ]
            });

            let response = await this.postsApi.create(this.authenticated.workspace.id, post);

            const uploadSuccessful = await this.picker.startUploadFor(response.id);
            if (!uploadSuccessful) return;

            response = await this.postsApi.getById(response.id, this.authenticated.workspace.id);

            this.posts = [response, ...this.posts].orderByDescending((x) => x.createdAt);
            this.events.publish(CustomEvents.UserPostsCreated, response);

            this.isReplying = false;
            this.uploads = [];
            this.input.clear(false);

            this.notifications.show(
                this.t.tr('translation:partial-views.user-posts.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.user-posts.notifications.save-successful.message'),
                { type: 'success', duration: 3000 }
            );
        } catch (e) {
            this.errorHandler.handle('create-user-post', e);
        }
    };

    public handleDelete = async (post: GetUserPostResponse): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.user-posts.questions.delete.title'),
                    message: this.t.tr('translation:partial-views.user-posts.questions.delete.message'),

                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.postsApi.delete(post.id, this.authenticated.workspace.id);

                                const index = this.posts.findIndex((x) => x.id === post.id);
                                if (index > -1) this.posts.splice(index, 1);
                                this.events.publish(CustomEvents.UserPostsDeleted, post);

                                this.notifications.show(
                                    this.t.tr('translation:partial-views.user-posts.notifications.deleted-successfully.title'),
                                    this.t.tr('translation:partial-views.user-posts.notifications.deleted-successfully.message'),
                                    { type: 'success', duration: 3000 }
                                );
                            } catch (e) {
                                await this.errorHandler.handle('[delete-user-post]', e);
                            }
                        }
                    }
                })
            );
        });
    };

    public async ok(): Promise<void> {
        await this.removeChildViews();
        this.remove({ result: PartialViewResults.Ok, updateUrl: true });
    }

    public selectFile(): void {
        this.picker.select();
    }
}
