import { connectTo } from '@aurelia/store-v1';
import { GetMedicalRecordRegistrationResponse, MedicalExaminationTemplateItemStep } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';
import { State } from '../../../../../../../infra/store/state';

@containerless
@connectTo<State>()
export class TemplateQuestionnaire {
    @bindable() public stepResult: any;
    @bindable() public container: MedicalExaminationTemplateItemStep;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public containerIndex: number;
    @bindable() public itemIndex: number;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public language: string;
    @bindable() public index: number;
    @bindable() public nested: boolean = false;

    public columns: string;
    public state: State;
    public expanded: boolean = false;

    public attached(): void {
        this.setColumns();
    }

    public collapseOrExpand(): void {
        this.expanded = !this.expanded;
    }

    private setColumns = (): void => {
        // this.columns = generateColumns(
        //     this.container.breakpoints?.filter((x) => x.id === this.step.id) || [] //
        // );
    };
}
