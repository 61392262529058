import { isDefined } from '@wecore/sdk-utilities';

export interface IConfirmationOptions {
    title: string;
    message: string;
    canCancel?: boolean;
    type?: 'warning' | 'danger';
    callback: ((confirmed: boolean) => Promise<void>) | ((confirmed: boolean, password: string) => Promise<void>);
    btnOk?: string;
    btnCancel?: string;
    showLoader?: boolean;
}

export class ConfirmationOptions {
    public title: string;
    public message: string;
    public canCancel: boolean = true;
    public type: 'warning' | 'danger' = 'danger';
    public callback: ((confirmed: boolean) => Promise<void>) | ((confirmed: boolean, password: string) => Promise<void>);
    public btnOk: string = 'Verwijderen';
    public btnCancel: string = 'Annuleren';
    public showLoading: boolean = true;

    public constructor(data: IConfirmationOptions) {
        if (isDefined(data.title)) this.title = data.title;
        if (isDefined(data.message)) this.message = data.message;
        if (isDefined(data.canCancel)) this.canCancel = data.canCancel;
        if (isDefined(data.type)) this.type = data.type;
        if (isDefined(data.callback)) this.callback = data.callback;
        if (isDefined(data.btnOk)) this.btnOk = data.btnOk;
        if (isDefined(data.btnCancel)) this.btnCancel = data.btnCancel;
        if (isDefined(data.showLoader)) this.showLoading = data.showLoader;
    }
}
