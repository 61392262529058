import { LabelTypes } from '@wecore/sdk-healthcare';

export class LabelTypeToStringValueConverter {
    public toView(type: LabelTypes): string {
        switch (type) {
            case LabelTypes.Patient:
                return 'global.labels.patient';
            case LabelTypes.Task:
                return 'global.labels.task';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
