<template>
    <import from="components/component-duplicator/component-duplicator" />

    <section class="flex flex-1 flex-col overflow-y-auto px-6">
        <div class="sticky top-0 z-10 border-b border-gray-300 bg-white pb-4 pt-6">
            <div class="flex justify-center py-5" if.bind="isEdit">
                <ux-avatar size="xxl" email.bind="account.emails[0].value" name.bind="account.displayName" color.bind="account.avatar.color" shade.bind="account.avatar.shade"></ux-avatar>
            </div>
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12 flex flex-col">
                    <div class="flex items-center space-x-1">
                        <ux-label class="mb-1">
                            <span t="translation:partial-views.accounts.labels.name"></span>
                        </ux-label>
                        <span class="flex h-3 items-center text-red-500">*</span>
                    </div>
                    <ux-input value.two-way="account.displayName" valid.bind="validation.displayName" t="[placeholder]translation:partial-views.accounts.placeholders.add-name"></ux-input>
                </div>
            </div>
        </div>
        <div class="mt-4 grid grid-cols-12 gap-2 pb-5">
            <div class="col-span-6 flex flex-col">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.accounts.labels.type"></span>
                </ux-label>
                <ux-input value.two-way="account.type" t="[placeholder]translation:partial-views.accounts.placeholders.enter-type"></ux-input>
            </div>
            <div class="col-span-6 flex flex-col">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.accounts.labels.number"></span>
                </ux-label>
                <ux-input value.two-way="account.number" t="[placeholder]translation:partial-views.accounts.placeholders.number"></ux-input>
            </div>
            <div class="col-span-12">
                <ux-body-divider></ux-body-divider>
            </div>
            <div class="col-span-12 flex flex-col space-y-5" if.bind="baseLoaded">
                <component-duplicator
                    t="[label]translation:partial-views.accounts.labels.enter-emails"
                    type="email"
                    descriptions.bind="emailDescriptions"
                    items.two-way="account.emails"
                    language.bind="language"
                    validation.two-way="validation.emails">
                </component-duplicator>
                <component-duplicator
                    t="[label]translation:partial-views.accounts.labels.enter-phones"
                    type="phone"
                    descriptions.bind="phoneDescriptions"
                    items.two-way="account.phones"
                    mask="000000000"
                    language.bind="language"
                    validation.two-way="validation.phones">
                </component-duplicator>
                <component-duplicator
                    t="[label]translation:partial-views.accounts.labels.enter-links"
                    type="link"
                    descriptions.bind="linkDescriptions"
                    items.two-way="account.links"
                    language.bind="language"
                    validation.two-way="validation.links">
                </component-duplicator>
            </div>
        </div>
    </section>
</template>
