import { ResultTypes, SliderIncrementalLabels } from '@wecore/sdk-healthcare';
import { bindable, containerless } from 'aurelia';
import { setTranslation } from '../../infra/utilities';

@containerless
export class ComponentSliderLabelsSelector {
    @bindable() public labels: SliderIncrementalLabels[];
    @bindable() public validation: any[];
    @bindable() public language: string;
    @bindable() public label: string;
    @bindable() public required: boolean = false;
    @bindable() public showScores: boolean = false;
    @bindable() public type: ResultTypes;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public ResultTypes: typeof ResultTypes = ResultTypes;

    public attached(): void {
        // if (this.labels.empty()) this.add();
    }

    public add(): void {
        this.validation.push({
            value: true,
            valueValid: true,
            unique: true,
            label: true
        });
        this.labels.push(
            new SliderIncrementalLabels({
                sliderValues: null,
                value: setTranslation({}, this.language)
            })
        );
    }

    public remove(index: number): void {
        this.labels.splice(index, 1);
        this.validation.splice(index, 1);
    }

    public manageTranslationsFor(index: number, required: boolean = false): void {
        this.manageTranslations(
            this.labels[index].value, //
            (translations: any) => (this.labels[index].value = translations),
            'textarea',
            required
        );
    }

    public clear(): void {
        this.labels = [];
        this.validation = [];
    }
}
