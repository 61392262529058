import { GetAuthenticatedResponse } from '@wecore/sdk-core';
import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalTherapyEvaluationFlow,
    MedicalWidgetTypes,
    WidgetResult
} from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../infra/events';
import { generateColumns } from '../../../../../infra/utilities';
import { FlattenedExaminationStep } from '../../../../../models/flattened-examination-step';
import { PartialView } from '../../../../../models/partial-view';
import { SelectedFile } from '../../../../../models/selected-file';
import { StepState } from '../../../../../models/step-state';
import { ViewOptions } from '../../../../../models/view-options';

@containerless
@inject(IEventAggregator)
export class TemplateWidget {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public flow: MedicalExaminationFlow | MedicalTherapyEvaluationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public validation: any;
    @bindable() public allValidation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public patient: GetPatientResponse;
    @bindable() public currentUser: GetAuthenticatedResponse;
    @bindable() public xScrollContainer: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public remove: (stepId: string) => Promise<void>;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public scrollToView: (partial: PartialView) => Promise<void>;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
    @bindable() public widgets: {
        stepId: string;
        type: string;
        onSave: (record: GetMedicalRecordResponse) => Promise<GetMedicalRecordResponse>;
        validate: (result: WidgetResult, validation: any) => boolean;
        refresh: () => Promise<void>;
    }[] = [];

    public columns: string = 'col-span-12';
    public MedicalWidgetTypes: typeof MedicalWidgetTypes = MedicalWidgetTypes;
    public required: boolean;
    public shouldBeDisplayed: boolean = false;
    private subscriptions: any[] = [];

    public constructor(
        private events: IEventAggregator //
    ) {}

    public attached(): void {
        if (isNotDefined(this.step.attributes)) this.step.attributes = {};
        if (isNotDefined(this.states[this.step.id]))
            this.states[this.step.id] = new StepState({
                stepId: this.step.id,
                expanded: false,
                answered: false
            });
        // Generate the configured column width for the current question.
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.step.id) || [] //
        );

        // Subscribe to the ExaminationStepAnswered event so that we can evaluate
        // whether or not to show the current questionnaire when a step is answered.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings()) //
        ];

        // Evaluate the requirements on load of the questionnaire.
        this.evaluateSettings();
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.step.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;
        this.required = flattened?.isRequired ?? false;
    }
}
