import { I18N } from '@aurelia/i18n';
import { watch } from '@aurelia/runtime-html';
import { AttachmentEntities, AttachmentsApiClient } from '@wecore/sdk-attachments';
import { CreateUserPostRequest, GetUserPostResponse, IDocumentStoreEntityEntityReference, SortDirection, UserPostsApiClient, UserRoles } from '@wecore/sdk-core';
import { isDefined, isFunction } from '@wecore/sdk-utilities';

import { IEventAggregator, ISignaler, bindable, inject } from 'aurelia';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { ModalService } from '../../../services/service.modals';
import { NotificationService } from '../../../services/service.notifications';
import { TemplatePostAttachments } from '../template-post-attachments/template-post-attachments';
import { TemplatePostInput } from '../template-post-input/template-post-input';

@inject(UserPostsApiClient, ErrorHandler, ISignaler, I18N, IEventAggregator, ModalService, AttachmentsApiClient, NotificationService)
export class TemplatePost {
    @bindable() public post: GetUserPostResponse;
    @bindable() public entityId: string;
    @bindable() public entityType: string;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public authenticated: GetUserPostResponse;
    @bindable() public onDelete: (post: GetUserPostResponse) => Promise<void>;
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public references: IDocumentStoreEntityEntityReference[];

    public input: TemplatePostInput;
    public replyInput: TemplatePostInput;
    public baseLoaded: boolean = false;
    public editMode: boolean = false;
    public replyMode: boolean = false;
    public isReplying: boolean = true;
    public replies: GetUserPostResponse[] = [];
    public reply: any;
    public uploads: any[] = [];
    public picker: TemplatePostAttachments;
    public validation: any = {
        reply: true
    };
    public UserRoles: typeof UserRoles = UserRoles;
    public isEditingReply: boolean[];

    private interval: NodeJS.Timeout;

    public constructor(
        private readonly postsApi: UserPostsApiClient, //
        private readonly errorHandler: ErrorHandler,
        private readonly signaler: ISignaler,
        private readonly t: I18N,
        private readonly events: IEventAggregator,
        private readonly modalService: ModalService,
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly notifications: NotificationService
    ) {}

    public async bound(): Promise<void> {
        this.interval = setInterval(() => this.signaler.dispatchSignal('update-timeago'), 1000);

        const [replies] = await Promise.all([
            this.postsApi.search(this.workspace, 500, 0, SortDirection.Asc, 'CreatedAt', undefined, undefined, undefined, [this.post.id]) //
        ]);
        this.isEditingReply = replies.data.map(() => false);
        this.replies = replies.data;
        this.isReplying = this.replies.empty();
        this.baseLoaded = true;
    }

    public detaching(): void {
        clearInterval(this.interval);
    }

    @watch('isReplying')
    public isReplyingHasChanged(): void {
        if (!this.isReplying && this.replies.empty()) {
            this.replyMode = false;
            this.isReplying = true;
        }
    }

    public handleAttachmentDelete = async (index: number): Promise<void> => {
        const attachment = this.post.attachments[index];
        await this.attachmentsApi.delete(this.post.id, attachment.id, this.workspace, AttachmentEntities.UserPosts);
        this.post.attachments.splice(index, 1);
    };

    public edit(): void {
        if (this.post.createdBy.id !== this.authenticated.id) return;
        this.editMode = true;
    }

    public handleCancel = (): void => {
        this.input.setContents(this.post.content);
        this.editMode = false;
        this.uploads = [];
    };

    public handleCancelReply = (): void => {
        if (isDefined(this.replyInput)) this.replyInput.clear(false);
        this.isReplying = false;
    };

    public delete(): void {
        if (this.post.createdBy.id !== this.authenticated.id || !isFunction(this.onDelete) || this.replies.any()) return;
        this.onDelete(this.post);
    }

    public addReply(): void {
        this.replyMode = true;
        this.replyInput.focus();
    }

    public selectFile(): void {
        this.picker.select();
    }

    public handleSave = async (): Promise<boolean> => {
        const valid = this.input.validate();
        if (!valid) return false;

        const content = this.input.getContents();
        try {
            this.post.content = content;
            await this.postsApi.update(this.post.id, this.workspace, this.post);
            this.editMode = false;

            const uploadSuccessful = await this.picker.startUploadFor(this.post.id);
            if (!uploadSuccessful) return;

            this.post = await this.postsApi.getById(this.post.id, this.workspace);
            this.input.setContents(this.post.content);
            this.uploads = [];
            this.input.clear(false);

            this.notifications.show(
                this.t.tr('translation:partial-views.user-posts.notifications.save-successful.message'),
                this.t.tr('translation:partial-views.user-posts.notifications.save-successful.message'),
                { type: 'success', duration: 3000 }
            );
            return true;
        } catch (e) {
            this.errorHandler.handle('edit-user-post', e);
            return false;
        }
    };

    public saveReply = async (): Promise<void> => {
        const valid = this.validateReply();
        if (valid) {
            try {
                const post = new CreateUserPostRequest({
                    content: this.reply,
                    references: [
                        new IDocumentStoreEntityEntityReference({
                            id: this.entityId,
                            type: this.entityType
                        }),
                        ...this.references
                    ],
                    repliedTo: this.post.id
                });

                const response = await this.postsApi.create(this.workspace, post);
                this.replies = [...this.replies, response].orderBy((x) => x.createdAt);

                this.isEditingReply.push(false);
                this.events.publish(CustomEvents.UserPostsReplied, response);

                this.reply = null;
                if (isDefined(this.replyInput)) this.replyInput.clear(false);

                this.notifications.show(
                    this.t.tr('translation:partial-views.user-posts.notifications.save-successful.title'),
                    this.t.tr('translation:partial-views.user-posts.notifications.save-successful.message'),
                    { type: 'success', duration: 3000 }
                );
            } catch (e) {
                this.errorHandler.handle('reply-user-post', e);
            }
        }
    };

    private validateReply(): boolean {
        this.validation.reply = isDefined(this.reply);
        return this.validation.reply;
    }

    public handleDeleteReply = async (post: GetUserPostResponse): Promise<void> => {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.user-posts.questions.delete.title'),
                message: this.t.tr('translation:partial-views.user-posts.questions.delete.message'),

                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.postsApi.delete(post.id, this.workspace);

                            const index = this.replies.findIndex((x) => x.id === post.id);
                            if (index > -1) this.replies.splice(index, 1);
                            this.events.publish(CustomEvents.UserPostsDeleted, post);

                            this.notifications.show(
                                this.t.tr('translation:partial-views.user-posts.notifications.deleted-successfully.title'),
                                this.t.tr('translation:partial-views.user-posts.notifications.deleted-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            await this.errorHandler.handle('[delete-user-post-reply]', e);
                        }
                    }
                }
            })
        );
    };
}
