<import from="converters/keys" />
<import from="../partial-accounts-tabs/tab-accounts-general/tab-accounts-general" />
<import from="../partial-accounts-tabs/tab-accounts-addresses/tab-accounts-addresses" />
<import from="../partial-accounts-tabs/tab-accounts-connections/tab-accounts-connections" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex flex-col flex-1 h-full">
            <section class="flex flex-col h-full">
                <ux-header>
                    <div class="flex items-center justify-between">
                        <div class="flex flex-col px-3">
                            <h6 class="mb-0 leading-tight" t="translation:partial-views.accounts.labels.edit-account"></h6>
                            <span class="block text-sm leading-tight text-gray-500" t="translation:partial-views.accounts.labels.edit-existing-account"></span>
                        </div>
                        <ux-dropdown
                            if.bind="baseLoaded && hasRole(UserRoles.DeleteAccounts)"
                            border.bind="false"
                            type="full"
                            color="dark"
                            size="sm"
                            padding.bind="false"
                            mode="outline"
                            placement="bottom-right">
                            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path
                                        fill="currentColor"
                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                </svg>
                            </div>
                            <ux-dropdown-option click.trigger="delete()">
                                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </div>
                </ux-header>
                <div class="flex h-full pl-3 overflow-hidden">
                    <div if.bind="baseLoaded" class="flex flex-col flex-1 h-full pr-5 overflow-hidden sm:flex-row sm:pr-0">
                        <div class="flex w-32 py-5 pl-5 overflow-hidden border-r border-gray-200">
                            <ul class="flex items-center gap-x-3 sm:flex-col sm:items-start sm:space-x-0 sm:space-y-3">
                                <li class="group" repeat.for="key of tabs | keys">
                                    <a
                                        class="${ tabs[key].valid ? tabs[key].active ? 'text-gray-900 font-semibold': 'text-gray-500 group-hover:text-gray-900': tabs[key].active ? 'text-red-500 font-semibold':'text-red-500 group-hover:text-red-600' }"
                                        href="javascript:"
                                        click.trigger="setActiveTab(key)">
                                        <div class="flex items-center space-x-1">
                                            <span class="text-sm">${ tabs[key].name }</span>
                                            <svg if.bind="!tabs[key].valid" class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex flex-col flex-1 overflow-hidden">
                            <tab-accounts-general
                                if.bind="tabs.general.active"
                                account.two-way="account"
                                type.bind="type"
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                lock-type.bind="lockType"
                                validation.bind="validation">
                            </tab-accounts-general>
                            <tab-accounts-addresses
                                if.bind="tabs.addresses.active"
                                account.two-way="account"
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                validation.bind="validation">
                            </tab-accounts-addresses>
                            <tab-accounts-connections
                                if.bind="tabs.connections.active"
                                account.two-way="account"
                                workspace.bind="authenticated.workspace.id"
                                language.bind="language"
                                validation.bind="validation">
                            </tab-accounts-connections>
                        </div>
                    </div>
                    <div else class="flex flex-col items-center justify-center flex-1 h-full">
                        <div class="w-full max-w-xs">
                            <ux-skeleton type="card"></ux-skeleton>
                        </div>
                    </div>
                </div>
                <ux-footer>
                    <div class="flex items-center justify-end w-full gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </ux-footer>
            </section>
        </div>
    </section>
</template>
