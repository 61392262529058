<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="converters/sort-therapy-plans-by-grouped-or-other" />
<import from="./templates/template-treatment-plan-item/template-treatment-plan-item" />

<ux-label class="mb-1">
    <span t="translation:components.widgets.examination-treatment-plan.labels.treatment-plan"></span>
</ux-label>
<p if.bind="hasWorkingDiagnoses && baseLoaded" class="mb-2 text-xs text-gray-500" t="translation:components.widgets.examination-treatment-plan.messages.intro"></p>
<template if.bind="baseLoaded">
    <template if.bind="hasWorkingDiagnoses">
        <div class="grid grid-cols-12 gap-2">
            <div class="flex flex-col col-span-3 gap-2">
                <div repeat.for="item of protocolSelection" class="flex flex-col p-5 border border-gray-200 rounded-lg">
                    <span class="text-xs text-gray-500" t="translation:global.labels.differential-diagnosis"></span>
                    <div class="flex justify-between gap-2">
                        <p class="font-semibold truncate">${item.result.differentialDiagnosis.name | translate:language}</p>
                    </div>
                    <div if.bind="item.result.side || item.result.location" class="flex text-xs text-primary">
                        <span if.bind="item.result.side">${item.result.side | sideToString | t}</span>
                        <span class="mx-1" if.bind="item.result.side && item.result.location">+</span>
                        <span if.bind="item.result.location">${item.result.location.translations | translate:language}</span>
                    </div>
                    <div if.bind="item.protocols.length > 0" class="pt-1 mt-2 border-t border-gray-200">
                        <span class="text-xs text-gray-500" t="translation:global.labels.protocols"></span>
                    </div>
                    <div if.bind="item.protocols.length === 0" class="flex flex-col items-center justify-center p-1 mt-2 border border-gray-200 rounded-lg">
                        <p class="text-xs text-gray-500" t="translation:components.widgets.examination-treatment-plan.messages.no-protocols"></p>
                    </div>
                    <ul if.bind="item.protocols.length > 0" class="flex flex-col gap-1 mt-1">
                        <li repeat.for="protocol of item.protocols">
                            <button
                                click.trigger="selectProtocol(protocol, item.result)"
                                class="flex w-full items-center justify-between rounded-lg bg-gray-100 py-1 pl-3 pr-3.5 text-sm hover:bg-gray-200 focus:outline-none active:bg-gray-200">
                                <span>${protocol.value.name | translate:language}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                            </button>
                            <div class="pl-2">
                                <ul class="flex flex-col gap-1 pl-1 mt-1 border-l-2 border-gray-300">
                                    <li class="flex items-center justify-between px-3 py-1 bg-gray-100 rounded-lg" repeat.for="therapy of protocol.therapies">
                                        <p class="text-sm">${therapy.name | translate:language}</p>
                                        <svg
                                            if.bind="record.treatment.plans.some((p) => registrations[p.id].therapy.id === therapy.id && registrations[p.id].therapyPlan.results.some((y) => y.resultId === item.result.id))
                                                && registrations[record.treatment.plans.find((p) => registrations[p.id].therapy.id === therapy.id && registrations[p.id].therapyPlan.results.some((y) => y.resultId === item.result.id)).id].therapyPlan.protocols.some((x) => x.id === protocol.value.id)"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-4 h-4 text-green-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex col-span-9">
                <div class="flex flex-col items-center flex-1 h-full p-6 border border-gray-200 rounded-lg">
                    <div if.bind="record.treatment.plans.length > 1" class="flex justify-start w-full gap-2 pl-3">
                        <label for="selectall" class="flex gap-2">
                            <input id="selectall" type="checkbox" checked.two-way="selectedAll" change.trigger="handleSelectAll($event)" />
                            <p
                                class="text-xs text-primary hover:cursor-pointer hover:text-blue-800 hover:underline"
                                t="translation:components.widgets.examination-treatment-plan.buttons.select-all"></p>
                        </label>
                    </div>
                    <div if.bind="record.treatment.plans.length > 0" class="flex flex-col w-full gap-3">
                        <div repeat.for="[key, collectionIds] of record.treatment.plans | sortTherapyPlansByGroupedOrOther:registrations:record" class="flex flex-col">
                            <template if.bind="key === 'groups'">
                                <template repeat.for="[_, groupedByResultIds] of collectionIds">
                                    <!-- Find the result of the first group item. They all have the same result -->
                                    <let result.bind="record.results.find((x) => x.id === registrations[groupedByResultIds[0]].therapyPlan.results[0].resultId)"></let>
                                    <div class="flex items-center justify-between mt-2 mb-1">
                                        <p class="text-xs text-gray-500">${result.differentialDiagnosis.name | translate:language}</p>
                                        <div if.bind="result.side || result.location" class="flex text-xs text-primary">
                                            <span if.bind="result.side">${result.side | sideToString | t}</span>
                                            <span class="mx-1" if.bind="result.side && result.location">+</span>
                                            <span if.bind="result.location">${result.location.translations | translate:language}</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col gap-1">
                                        <div repeat.for="id of groupedByResultIds" class="flex flex-col">
                                            <div class="flex flex-col gap-1">
                                                <let planitem.bind="record.treatment.plans.find((x) => x.id === id)"></let>
                                                <template-treatment-plan-item
                                                    record-flattened.bind="flattened"
                                                    registrations.bind="registrations"
                                                    registration.bind="registrations[id]"
                                                    states.bind="states"
                                                    on-file-selected.bind="onFileSelected"
                                                    on-file-removed.bind="onFileRemoved"
                                                    x-scroll-container.bind="xScrollContainer"
                                                    loading.bind="loading"
                                                    validation.bind="validation"
                                                    plan-item.bind="planitem"
                                                    record.bind="record"
                                                    on-selected.bind="handleItemChecked"
                                                    on-delete.bind="handleDeleteTherapy"
                                                    on-duplicate.bind="handleDuplicate"
                                                    on-connect.bind="handleConnectTherapy"
                                                    state.bind="planStates[id]"
                                                    language.bind="language"
                                                    workspace.bind="workspace"
                                                    selection.two-way="selection"
                                                    manage-translations.bind="manageTranslations"
                                                    item.bind="registrations[id]">
                                                </template-treatment-plan-item>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template if.bind="key === 'other' && collectionIds.length > 0">
                                <p class="mb-1 text-xs text-gray-500" t="translation:global.labels.other"></p>
                                <div repeat.for="id of collectionIds" class="flex flex-col">
                                    <div class="flex flex-col gap-1 mt-1">
                                        <let planitem.bind="record.treatment.plans.find((x) => x.id === id)"></let>
                                        <template-treatment-plan-item
                                            record-flattened.bind="flattened"
                                            registrations.bind="registrations"
                                            registration.bind="registrations[id]"
                                            states.bind="states"
                                            on-file-selected.bind="onFileSelected"
                                            on-file-removed.bind="onFileRemoved"
                                            x-scroll-container.bind="xScrollContainer"
                                            loading.bind="loading"
                                            validation.bind="validation"
                                            plan-item.bind="planitem"
                                            record.bind="record"
                                            on-delete.bind="handleDeleteTherapy"
                                            on-duplicate.bind="handleDuplicate"
                                            on-connect.bind="handleConnectTherapy"
                                            state.bind="planStates[id]"
                                            language.bind="language"
                                            selection.two-way="selection"
                                            manage-translations.bind="manageTranslations"
                                            item.bind="registrations[id]">
                                        </template-treatment-plan-item>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div else class="flex flex-col items-center flex-1 h-full gap-2 px-4 py-10">
                        <p class="text-sm text-center text-gray-500" t="translation:components.widgets.examination-treatment-plan.messages.no-therapies-selected"></p>
                        <ux-button click.trigger="addTherapies()" color="primary">
                            <span t="translation:components.widgets.examination-treatment-plan.buttons.add-therapies"></span>
                        </ux-button>
                    </div>
                    <div if.bind="record.treatment.plans.length > 0" class="flex flex-col items-center mt-10">
                        <div class="flex gap-1">
                            <ux-button click.trigger="addTherapies()" color="dark" mode="outline">
                                <span t="translation:components.widgets.examination-treatment-plan.buttons.add-therapies"></span>
                            </ux-button>
                            <ux-button if.bind="selection.length > 0" disabled.bind="selection.length < 2" click.trigger="mergeTherapies()" color="dark" mode="outline">
                                <span t="translation:components.widgets.examination-treatment-plan.buttons.merge"></span>
                            </ux-button>
                            <!-- <ux-button
                                disabled.bind="record.treatment.plans.length === 0 || record.treatment.plans.every((x) => registrations[x.id].therapyPlan?.startedTreatment ?? false) "
                                loading.bind="starting"
                                click.trigger="startTreatment()"
                                color="primary">
                                <div class="flex gap-1">
                                    <span t="translation:components.widgets.examination-treatment-plan.buttons.start-treatment"></span>
                                    <span if.bind="selection.length > 0">(${selection.length})</span>
                                </div>
                            </ux-button> -->
                        </div>
                        <p if.bind="selection.length > 0" class="mt-2 text-xs text-gray-400" t="translation:components.widgets.examination-treatment-plan.messages.merging-therapies"></p>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template else>
        <div class="flex flex-col items-center justify-center gap-2 border border-gray-200 rounded-lg p-7">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
            <p class="text-sm" t="translation:components.widgets.examination-treatment-plan.messages.no-working-diagnoses"></p>
        </div>
    </template>
</template>
<template else>
    <div class="flex flex-col items-center justify-center gap-2 p-20 border border-gray-200 rounded-lg">
        <ux-spinner size="xxs"></ux-spinner>
    </div>
</template>
