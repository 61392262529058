import { I18N } from '@aurelia/i18n';
import { watch } from '@aurelia/runtime-html';
import { MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { guid, isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, inject } from 'aurelia';
import Quill from 'quill';
import { refreshAttachmentsUrls } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { ModalService } from '../../../services/service.modals';

@inject(MediaLibraryApiClient, ModalService, I18N)
export class TemplatePostInput {
    @bindable() public content: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public isReacting: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public isVisible: boolean = false;
    @bindable() public hasFiles: boolean = false;
    @bindable() public showToolbar: boolean = true;
    @bindable() public edit: boolean = false;
    @bindable() public onShiftEnter: () => void;
    @bindable() public onEscape: () => void;

    public editor: HTMLDivElement;
    public toolbar: HTMLDivElement;
    public loading: boolean = false;
    public validation: any = {
        content: true
    };
    public guid: string = guid();

    private quill: Quill;

    public constructor(
        private readonly mediaLibrary: MediaLibraryApiClient, //
        private readonly modalService: ModalService,
        private readonly t: I18N
    ) {}

    public async bound(): Promise<void> {
        setTimeout(async () => {
            if (this.isReacting) await this.initEditor();
            if (isDefined(this.content)) this.content = await this.setContents(this.content);
            if (isDefined(this.quill)) setTimeout(() => this.quill.focus(), 100);
        });
    }

    public detaching(): void {
        // this.editor?.remove();
        // this.toolbar?.remove();
    }

    @watch('isVisible')
    public isVisibleHasChanged(): void {
        if (this.isVisible)
            setTimeout(() => {
                if (isDefined(this.content)) this.quill.setContents(this.content);
                this.quill.focus();
                this.quill.setSelection(this.quill.getLength(), 0);
            }, 100);
    }

    public async clear(ask: boolean = true): Promise<void> {
        const doIt = () => {
            if (isDefined(this.quill)) this.quill.setText('');
            this.content = null;
        };

        if (ask)
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.user-posts.questions.delete-content.title'),
                    message: this.t.tr('translation:partial-views.user-posts.questions.delete-content.message'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) doIt();
                    }
                })
            );
        else doIt();
    }

    public async focus(): Promise<void> {
        this.isReacting = true;
        setTimeout(async () => {
            if (isNotDefined(this.quill)) await this.initEditor();
            setTimeout(() => this.quill.focus(), 250);
        });
    }

    public async setContents(content: any): Promise<any> {
        this.content = content;
        content = await refreshAttachmentsUrls(content, this.mediaLibrary, this.workspace);
        this.quill.setContents(content);
        return content;
    }

    public getContents(): any {
        return this.quill.getContents();
    }

    public validate(): boolean {
        this.validation.content = this.quill.getLength() > 1;
        return this.validation.content;
    }

    private initEditor(): Promise<void> {
        return new Promise((resolve) => {
            this.quill = new Quill(this.editor, {
                modules: {
                    // toolbar: [
                    //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    //     ['blockquote', 'code-block'],
                    //     [{ list: 'ordered' }, { list: 'bullet' }],
                    //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    //     [{ direction: 'rtl' }], // text direction
                    //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    //     // [{ font: [] }],
                    //     [{ align: [] }],
                    //     ['link', 'image'],
                    //     ['clean'] // remove formatting button
                    // ]
                    toolbar: this.showToolbar
                        ? {
                              container: this.toolbar,
                              handlers: {
                                  image: this.handleImageSelected,
                                  video: this.handleVideoSelected
                              }
                          }
                        : false,
                    keyboard: {
                        bindings: {
                            shift_enter: {
                                key: 13,
                                shiftKey: true,
                                handler: () => {
                                    if (isFunction(this.onShiftEnter)) this.onShiftEnter();
                                }
                            },
                            escape: {
                                key: 27,
                                handler: () => {
                                    if (isFunction(this.onEscape)) this.onEscape();
                                }
                            }
                        }
                    }
                },
                theme: 'snow'
            });

            this.quill.on('selection-change', (range) => {
                if (isNotDefined(range)) {
                    const content = this.quill.getText().trim();
                    if (content.length === 0 && !this.edit) this.isReacting = false;
                }
            });

            this.quill.on('text-change', () => {
                this.content = this.quill.getContents();
            });

            resolve();
        });
    }

    private handleVideoSelected = async (): Promise<void> => {
        // this.addPartialView({
        //     view: this.partial.base,
        //     partial: PartialViews.UploadFiles.with({
        //         entityId: this.entityId,
        //         entityType: this.entityType,
        //         language: this.contentLanguage,
        //         type: 'single',
        //         contentTypes: ['video/x-flv', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv']
        //     }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnails: boolean }) => {
        //         if (result === PartialViewResults.Ok) {
        //             this.quill.focus();
        //             const range = this.quill.getSelection();
        //             this.quill.insertEmbed(
        //                 range.index,
        //                 'videoAttachment',
        //                 {
        //                     src: response.items[0]?.url,
        //                     attachment: response.items[0].attachment?.id,
        //                     alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
        //                     width: '100%',
        //                     height: '480px'
        //                 },
        //                 Quill.sources.USER
        //             );
        //         }
        //     }),
        //     options: new ViewOptions({
        //         index: this.partial.index + 1,
        //         scrollToView: true,
        //         markItem: true,
        //         replace: true
        //     })
        // });
    };

    private handleImageSelected = async (): Promise<void> => {
        // this.addPartialView({
        //     view: this.partial.base,
        //     partial: PartialViews.UploadFiles.with({
        //         entityId: this.entityId,
        //         entityType: this.entityType,
        //         language: this.contentLanguage,
        //         type: 'single',
        //         contentTypes: ['image/png', 'image/jpg', 'image/jpeg']
        //     }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnail: boolean; language: string }) => {
        //         if (result === PartialViewResults.Ok) {
        //             this.quill.focus();
        //             const range = this.quill.getSelection();
        //             this.quill.insertEmbed(
        //                 range.index,
        //                 'imageAttachment',
        //                 {
        //                     src: response.items[0]?.url,
        //                     attachment: response.items[0]?.attachment.id,
        //                     alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
        //                     width: '100%',
        //                     height: '480px',
        //                     thumbnail: response.thumbnail
        //                 },
        //                 Quill.sources.USER
        //             );
        //         }
        //     }),
        //     options: new ViewOptions({
        //         index: this.partial.index + 1,
        //         scrollToView: true,
        //         markItem: true,
        //         replace: true
        //     })
        // });
    };
}
