import { guid } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, inject, slotted } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(Element, IEventAggregator)
export class UxRadioGroup {
    @bindable() public name: string = 'radios';
    @bindable() public rounded: 'all' | 'first-last' = 'first-last';
    @bindable() public value: string;
    @bindable() public data: any;
    @bindable() public padding: string = 'p-4';
    @bindable() public alignment: string = 'items-start';
    @bindable() public color: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' = 'primary';

    @(slotted('ux-radio') as any) children: HTMLElement[];

    public loaded: boolean = false;

    private id: string = guid();
    private subscriptions: any[] = [];

    public constructor(
        private readonly host: HTMLElement, //
        private readonly events: IEventAggregator
    ) {}

    public bound(): void {
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(UxEvents.UxRadioChanged, (data: { parent: string; value: string }) => {
                if (data.parent !== this.id) return;
                this.value = data.value;
                this.emit(
                    UxEvents.OnChange,
                    new EventDetails<UxRadioGroup, any>({
                        element: this,
                        innerEvent: null,
                        data: this.data,
                        values: this.value
                    })
                );
            })
        ];
    }

    public childrenChanged(): void {
        // Make sure all children get the correct data.
        for (let i = 0; i < this.children.length; i++) {
            const child = this.children[i];
            child.setAttribute('name', this.name);
            child.setAttribute('index', i.toString());
            child.setAttribute('rounded', this.rounded);
            child.setAttribute('last', (i == this.children.length - 1).toString());
            child.setAttribute('value', this.value);
            child.setAttribute('padding', this.padding);
            child.setAttribute('color', this.color);
            child.setAttribute('parent', this.id);
            child.setAttribute('alignment', this.alignment);
        }
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }
}
