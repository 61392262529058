import { I18N } from '@aurelia/i18n';
import { GetMedicalRecordResponse, GetPatientResponse, MedicalRecordsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(MedicalRecordsApiClient, I18N, ErrorHandler)
export class BxMedicalRecordSelector {
    @bindable() public value: string;
    @bindable() public selected: GetPatientResponse;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public patient: string;
    @bindable() public onSelect: (record: GetMedicalRecordResponse, data: any) => void;

    public select: UxCombobox;
    public records: GetMedicalRecordResponse[];

    public constructor(
        private readonly recordsApi: MedicalRecordsApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public handleClear(): void {
        if (isFunction(this.onSelect)) this.onSelect(null, this.data);
    }

    public async attached(): Promise<void> {
        try {
            let record: GetMedicalRecordResponse;
            if (isNotEmpty(this.value)) {
                record = await this.recordsApi.getById(this.value, this.workspace);
                // If the selected item is not found, use the selected object which can
                // be provided by the user and used to display the object name in the combobox.
                // This can be handy in case the item has been deleted.
                if (isNotDefined(record)) record = this.selected;
            }

            const response = await this.recordsApi.search(this.workspace, '', 250, 0, undefined, undefined, undefined, this.patient);
            const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
            this.records = isDefined(record) ? [record].concat(data.filter((x) => x.id !== this.value)) : data;

            if (isDefined(this.select)) this.select.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxMedicalRecordSelector.attached', e);
        }
    }

    public async handleSelect(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        try {
            if (e.detail.values.customInput as boolean) return;
            const option = e.detail.values as UxComboboxOption;

            const record = this.records.find((x) => x.id === option.value);
            if (isFunction(this.onSelect)) this.onSelect(record, this.data);
        } catch (e) {
            this.errorHandler.handle('BxMedicalRecordSelector.handleSearch', e);
        }
    }

    public async refresh(patient: string): Promise<void> {
        this.patient = patient;

        const response = await this.recordsApi.search(this.workspace, '', 100, 0, undefined, undefined, undefined, this.patient);
        this.records = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
    }
}
