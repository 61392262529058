<import from="converters/keys" />
<import from="converters/referral-system-to-string" />
<import from="bx/bx-contact-selector/bx-contact-selector" />
<import from="./widget-patient-intake-dropzone/widget-patient-intake-dropzone" />

<div class="flex w-full flex-col space-y-1">
    <ux-label>
        <span t="translation:components.widgets.patient-intake.labels.entry"></span>
    </ux-label>
    <ux-radio-group onchange.trigger="handleReferralTypeChanged($event)" value.two-way="referral.type" padding="px-4 py-2.5" class="flex flex-col">
        <ux-radio checked value.bind="MedicalReferralTypes.InstandAccess" before-change.bind="beforeTypeChange" class="w-full">
            <div class="ux-flex ux-flex-col w-full">
                <p class="text-sm" t="translation:components.widgets.patient-intake.labels.direct-access"></p>
                <span class="text-sm text-gray-600" t="translation:components.widgets.patient-intake.messages.came-from-own-initiative"></span>
            </div>
        </ux-radio>
        <ux-radio value.bind="MedicalReferralTypes.ByGeneralPractitioner" before-change.bind="beforeTypeChange" class="w-full">
            <div class="flex w-full flex-1 flex-col">
                <p class="text-sm" t="translation:components.widgets.patient-intake.labels.referral-gp"></p>
                <span class="text-sm text-gray-500" t="translation:components.widgets.patient-intake.messages.came-via-gp"></span>
                <div if.bind="referral.type === MedicalReferralTypes.ByGeneralPractitioner" class="mt-2 flex flex-col rounded-lg border border-gray-300 bg-white p-5">
                    <div class="flex gap-x-2">
                        <div class="flex flex-1 flex-col space-y-1 text-gray-600">
                            <ux-label>
                                <span t="translation:components.widgets.patient-intake.labels.general-practitioner"></span>
                            </ux-label>
                            <bx-contact-selector
                                on-select.bind="handleContactSelected"
                                language.bind="language"
                                workspace.bind="workspace"
                                valid.bind="validation.referredBy"
                                value.two-way="referral.referredBy.id"
                                t="[placeholder]translation:components.widgets.patient-intake.placeholders.select-gp">
                            </bx-contact-selector>
                        </div>
                        <div class="flex flex-1 flex-col space-y-1 text-gray-600">
                            <ux-label>
                                <span t="translation:components.widgets.patient-intake.labels.referral-system"></span>
                            </ux-label>
                            <ux-select
                                onselect.trigger="handleSystemChanged($event)"
                                value.two-way="referral.system"
                                valid.bind="validation.system"
                                t="[placeholder]translation:components.widgets.patient-intake.placeholders.select-system">
                                <ux-select-option repeat.for="system of MedicalReferralSystems | keys" value.bind="system"> ${system | referralSystemToString} </ux-select-option>
                            </ux-select>
                        </div>
                    </div>
                    <widget-patient-intake-dropzone
                        referral.bind="referral"
                        validation.bind="validation"
                        upload.bind="upload"
                        attachment.bind="attachment"
                        on-files-selected.bind="handleFilesSelected"
                        on-file-removed.bind="handleFileRemoved">
                    </widget-patient-intake-dropzone>
                    <div if.bind="referral.system === MedicalReferralSystems.Zorgdomein" class="mt-2">
                        <ux-label>
                            <span t="translation:components.widgets.patient-intake.labels.referral"></span>
                        </ux-label>
                        <ux-select value.two-way="referral.letter" t="[placeholder]translation:components.widgets.patient-intake.placeholders.select-referral">
                            <ux-select-option value="1">Peppel - 28/09/2022 12:21</ux-select-option>
                        </ux-select>
                    </div>
                </div>
            </div>
        </ux-radio>
        <ux-radio value.bind="MedicalReferralTypes.BySpecialist" before-change.bind="beforeTypeChange" class="w-full">
            <div class="flex w-full flex-1 flex-col">
                <p class="text-sm" t="translation:components.widgets.patient-intake.labels.referral-specialist"></p>
                <span class="text-sm text-gray-500" t="translation:components.widgets.patient-intake.messages.came-via-specialist"></span>
                <div show.bind="referral.type === MedicalReferralTypes.BySpecialist" class="mt-2 flex flex-col rounded-lg border border-gray-300 bg-white p-5">
                    <div class="flex gap-x-2">
                        <div class="flex flex-1 flex-col space-y-1 text-gray-600">
                            <ux-label>Specialist</ux-label>
                            <bx-contact-selector
                                on-select.bind="handleContactSelected"
                                valid.bind="validation.referredBy"
                                language.bind="language"
                                workspace.bind="workspace"
                                value.two-way="referral.referredBy.id"
                                t="[placeholder]translation:components.widgets.patient-intake.placeholders.select-specialist">
                            </bx-contact-selector>
                        </div>
                    </div>
                    <widget-patient-intake-dropzone
                        referral.bind="referral"
                        validation.bind="validation"
                        upload.bind="upload"
                        attachment.bind="attachment"
                        on-files-selected.bind="handleFilesSelected"
                        on-file-removed.bind="handleFileRemoved">
                    </widget-patient-intake-dropzone>
                </div>
            </div>
        </ux-radio>
        <ux-radio value.bind="MedicalReferralTypes.ByOther" before-change.bind="beforeTypeChange" class="w-full">
            <div class="flex w-full flex-col">
                <p class="text-sm" t="translation:components.widgets.patient-intake.labels.referral-other"></p>
                <span class="text-sm text-gray-500" t="translation:components.widgets.patient-intake.messages.came-via-other"></span>
                <div show.bind="referral.type === MedicalReferralTypes.ByOther" class="mt-2 flex flex-col rounded-lg border border-gray-300 bg-white p-5">
                    <div class="flex gap-x-2">
                        <div class="flex flex-1 flex-col space-y-1 text-gray-600">
                            <ux-label>
                                <span t="translation:components.widgets.patient-intake.labels.referrer"></span>
                            </ux-label>
                            <bx-contact-selector
                                on-select.bind="handleContactSelected"
                                valid.bind="validation.referredBy"
                                language.bind="language"
                                workspace.bind="workspace"
                                value.two-way="referral.referredBy.id"
                                t="[placeholder]translation:components.widgets.patient-intake.placeholders.select-referrer">
                            </bx-contact-selector>
                        </div>
                    </div>
                    <widget-patient-intake-dropzone
                        referral.bind="referral"
                        validation.bind="validation"
                        upload.bind="upload"
                        attachment.bind="attachment"
                        on-files-selected.bind="handleFilesSelected"
                        on-file-removed.bind="handleFileRemoved">
                    </widget-patient-intake-dropzone>
                </div>
            </div>
        </ux-radio>
    </ux-radio-group>
</div>
