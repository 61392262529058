<template class="flex" data-value.bind="value">
    <label
        class="${padding} relative flex flex-1 cursor-pointer border border-x focus:outline-none"
        z-[1].class="checked"
        border-indigo-500.class="checked && color === 'primary'"
        bg-blue-50.class="checked && color === 'primary'"
        border-purple-500.class="checked && color === 'secondary'"
        bg-purple-50.class="checked && color === 'secondary'"
        border-green-500.class="checked && color === 'success'"
        bg-green-50.class="checked && color === 'success'"
        border-yellow-500.class="checked && color === 'warning'"
        bg-yellow-50.class="checked && color === 'warning'"
        border-red-500.class="checked && color === 'danger'"
        bg-red-50.class="checked && color === 'danger'"
        rounded-t-lg.class="index === 0 && rounded === 'first-last'"
        rounded-b-lg.class="last && rounded === 'first-last'"
        rounded-lg.class="rounded === 'all'"
        cursor-pointer.class="!disabled"
        cursor-not-allowed.class="disabled">
        <input
            ref="input"
            type="radio"
            value.bind="value"
            disabled.bind="disabled"
            change.trigger="handleChange($event)"
            class="form-radio mt-0.5 h-4 w-4 shrink-0 border-gray-300"
            text-indigo-500.class="color === 'primary'"
            checked:bg-indigo-600.class="color === 'primary'"
            focus:ring-indigo-500.class="color === 'primary'"
            text-purple-500.class="color === 'secondary'"
            checked:bg-purple-600.class="color === 'secondary'"
            focus:ring-purple-500.class="color === 'secondary'"
            text-green-500.class="color === 'success'"
            checked:bg-green-500.class="color === 'success'"
            focus:ring-green-500.class="color === 'success'"
            text-yellow-500.class="color === 'warning'"
            checked:bg-yellow-500.class="color === 'warning'"
            focus:ring-yellow-500.class="color === 'warning'"
            text-red-500.class="color === 'danger'"
            checked:bg-red-500.class="color === 'danger'"
            focus:ring-red-500.class="color === 'danger'" />
        <span
            class="${alignment} ml-3 flex flex-1 flex-col"
            text-indigo-600.class="color === 'primary' && checked"
            text-purple-600.class="color === 'secondary' && checked"
            text-green-600.class="color === 'success' && checked"
            text-yellow-600.class="color === 'warning' && checked"
            text-red-600.class="color === 'danger' && checked">
            <au-slot />
        </span>
    </label>
</template>
