import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
export class WidgetPatientProcessingData {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];

    public bound(): void {
        if (isNotDefined(this.registration)) return;
        this.registration.widget.result.value = this.patient.agreedToProcessPersonalData || false;

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Boolean;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientAgreementProcessingData,
                onSave: async (): Promise<void> => {
                    this.patient.agreedToProcessPersonalData = this.registration.widget.result.value;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
