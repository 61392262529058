import { isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

export class TranslateValueConverter {
    public toView(translations: any, language: string, leaveEmpty: boolean = false): string {
        if (isNotDefined(translations)) return;

        const translation = translations[language];
        if (isNotEmpty(translation)) return translation;

        const key = Object.keys(translations).find((key) => isNotEmpty(translations[key]));
        return isNotEmpty(translations[key]) ? translations[key] : leaveEmpty ? '' : `Translation for language '${language}' unavailable.`;
    }
}
