<import from="components/component-labels-selector/component-labels-selector" />

<component-labels-selector
    registrations.two-way="registration.widget.result.value"
    validation.bind="validation.values"
    current-user.bind="currentUser"
    manage-translations.bind="manageTranslations"
    workspace.bind="workspace"
    language.bind="language">
</component-labels-selector>
