<import from="converters/date" />
<import from="converters/translate" />

<div class="flex flex-col pr-5 border-b border-gray-200">
    <div
        flex-col.class="viewWidth <= screenCutOff"
        gap-2.class="viewWidth <= screenCutOff"
        justify-between.class="viewWidth > screenCutOff"
        h-20.class="viewWidth > screenCutOff"
        class="flex items-start py-5 pl-3">
        <div class="relative flex flex-col" data-role="calendar">
            <button
                click.trigger="openCalendar()"
                class.bind="showCalendar ? 'bg-gray-100' : ''"
                class="flex items-center gap-2 px-3 rounded-full hover:bg-gray-100 focus:outline-none active:bg-gray-50">
                <template if.bind="selectedDate">
                    <div class="flex gap-1">
                        <p if.bind="selectedUsers.length > 1 || selectedRooms.length > 1" class="text-lg font-bold capitalize">${selectedDate | date:'E dd'}</p>
                        <p class="text-lg font-bold capitalize">${selectedDate | date:'MMMM'}</p>
                    </div>
                    <p class="text-lg font-semibold text-gray-600">${selectedDate | date:'yyyy'}</p>
                </template>
                <template else>
                    <div class="flex gap-1">
                        <p if.bind="selectedUsers.length > 1 || selectedRooms.length > 1" class="text-lg font-bold capitalize">${startDate | date:'E dd'}</p>
                        <p class="text-lg font-bold capitalize">${startDate | date:'MMMM'}</p>
                    </div>
                    <p class="text-lg font-semibold text-gray-600">${startDate | date:'yyyy'}</p>
                </template>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </button>
            <div
                show.bind="showCalendar"
                class="absolute left-0 top-[32px] z-[999] mt-1 w-[23rem] rounded-md border border-gray-100 bg-white px-2 pb-4 pt-3 shadow-lg transition duration-100 ease-out">
                <div class="flex justify-between pb-2">
                    <div class="flex items-center justify-center flex-1 h-full border-r border-gray-300">
                        <button click.trigger="handlePreviousMonth()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path
                                    fill="currentColor"
                                    d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                        </button>
                        <div class="w-16 px-2 font-bold text-center text-gray-700">${startDate | date:'MMM'}</div>
                        <button click.trigger="handleNextMonth()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path
                                    fill="currentColor"
                                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex items-center justify-center flex-1 h-full">
                        <button click.trigger="handlePreviousYear()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path
                                    fill="currentColor"
                                    d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                        </button>
                        <div class="w-16 px-2 font-bold text-center text-gray-700">${startDate | date:'yyyy'}</div>
                        <button click.trigger="handleNextYear()" class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path
                                    fill="currentColor"
                                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="flex justify-between px-3">
                    <div class="flex-1 inline-block" repeat.for="label of labels">
                        <div class="flex items-center justify-center h-full">
                            <span class.bind="$index === 0 ?'border-r' : ''" class="w-full py-2 text-sm leading-tight text-center text-gray-400 border-b border-gray-300"> ${label | t} </span>
                        </div>
                    </div>
                </div>
                <div if.bind="daysOfCurrentMonth" class="flex flex-col px-3">
                    <div repeat.for="week of weeks" class="flex justify-between w-full">
                        <div class="flex-1 inline-block">
                            <div class="flex items-center justify-center h-full text-sm leading-tight text-gray-400 border-r border-gray-300">${week}</div>
                        </div>
                        <div repeat.for="d of rows[$index]" class="inline-flex items-center justify-center flex-1 h-10">
                            <button
                                text-primary.class="!isSelected(d) && isToday(d)"
                                font-bold.class="!isSelected(d) && isToday(d)"
                                bg-primary.class="isSelected(d)"
                                text-white.class="isSelected(d)"
                                text-gray-900.class="!isSelected(d) && !isToday(d) && isInCurrentMonth(d)"
                                text-gray-300.class="!isInCurrentMonth(d)"
                                class="w-8 h-8 text-sm rounded-full ring-primary ring-offset-1 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 active:bg-blue-900"
                                click.trigger="handleDateSelected(d)"
                                tabindex="-1">
                                <div class="flex items-center justify-center h-full leading-tight pointer-events-none">${d | date:'d'}</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center w-full mt-3">
                    <button
                        tabindex="-1"
                        click.trigger="handleDateSelected()"
                        class="px-4 py-1 text-sm text-gray-600 rounded-full ring-offset-1 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 active:bg-blue-900">
                        <span t="translation:global.days.today"></span>
                    </button>
                </div>
            </div>
            <div class="flex pl-3">
                <p if.bind="selectedDate" class="text-sm leading-tight text-gray-400">
                    <span t="translation:global.labels.week"></span>
                    <span>${selectedDate | date:'w'}</span>
                </p>
                <p else class="text-sm leading-tight text-gray-400">
                    <span t="translation:global.labels.week"></span>
                    <span>${startDate | date:'w'}</span>
                </p>
            </div>
        </div>

        <div flex-col.class="viewWidth <= 870" items-center.class="viewWidth > 870" pl-5.class="viewWidth <= screenCutOff" class="flex justify-end flex-1 gap-2">
            <div flex-col.class="viewWidth <= 640" class="flex justify-end w-full gap-2">
                <div if.bind="hasRole(UserRoles.ReadPracticeLocations)" flex-1.class="viewWidth <= 870" class="relative flex">
                    <div data-role="combobox" class.bind="locationsFocused ? 'ring-1' :''" class="flex h-[40px] w-48 flex-1 flex-col rounded-lg border border-gray-200 ring-gray-400 ring-offset-1">
                        <div class="flex items-center flex-1">
                            <div if.bind="selectedLocations.length > 0" class="flex flex-1 min-w-0 gap-1 p-1 flex-nowrap">
                                <div class="flex min-w-0 flex-1 items-center space-x-1.5 rounded-lg bg-gray-100 px-2 py-1">
                                    <span class="flex-1 block text-sm truncate whitespace-nowrap">
                                        <span if.bind="selectedLocations.length !== 1">${ selectedLocations.length }</span>
                                        <template if.bind="selectedLocations.length === 1">
                                            <span if.bind="selectedLocations[0].applicationName">${selectedLocations[0].applicationName | translate}</span>
                                            <span else>${selectedLocations[0].name | translate}</span>
                                        </template>

                                        <span class="lowercase" else t="translation:bx.scheduler.labels.locations"></span>
                                    </span>
                                    <button
                                        click.trigger="clearSelectedLocations()"
                                        class="flex items-center justify-center w-5 h-5 text-left rounded-lg ring-red-500 ring-offset-1 focus:outline-none focus:ring-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-red-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <button
                                if.bind="selectedLocations.length === 0"
                                focus.trigger="handleLocationsFocus($event)"
                                blur.trigger="handleLocationsBlur($event)"
                                click.trigger="openLocations()"
                                class="flex items-center h-full px-5 text-left w-72 focus:outline-none">
                                <span class="text-sm text-gray-400" t="translation:bx.scheduler.placeholders.select-locations"></span>
                            </button>
                            <div else class="flex items-center p-1">
                                <button
                                    click.trigger="openLocations()"
                                    class="flex items-center justify-center text-left bg-white border border-gray-600 rounded-lg h-7 w-7 ring-gray-400 ring-offset-1 hover:bg-gray-50 focus:outline-none focus:ring-1 active:bg-gray-100">
                                    <span if.bind="selectedLocations.length === 0" class="text-sm text-gray-400" t="translation:bx.scheduler.placeholders.select-locations"></span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div show.bind="showLocations" ref="locationOptions" class="absolute left-0 top-[45px] z-[10000] max-h-60 w-72 overflow-auto rounded-lg border border-gray-200 bg-white">
                            <div class="sticky top-0 z-10 bg-white border-b border-gray-200">
                                <input
                                    ref="searchLocationsInput"
                                    input.trigger="handleLocationsSearch($event) & debounce:500"
                                    t="[placeholder]translation:global.placeholders.search"
                                    class="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none rounded-md outline-none"
                                    role="combobox"
                                    type="text"
                                    aria-expanded="false" />
                                <div class="p-2 border-t border-gray-200">
                                    <a click.trigger="selectAllLocations()" class="flex gap-3 px-3 py-1 text-sm rounded-lg text-primary hover:bg-gray-100" href="javascript:">
                                        <input type="checkbox" checked.bind="selectedAllLocations" />
                                        <span t="translation:global.buttons.select-all"></span>
                                    </a>
                                </div>
                            </div>
                            <ul class="flex flex-col gap-1 p-2" data-role="options">
                                <li class="flex" repeat.for="location of locations">
                                    <a
                                        href="javascript:"
                                        data-role="option"
                                        click.trigger="selectLocation(location)"
                                        mouseover.trigger="focused = $index"
                                        class.bind="$index === focused ? 'bg-gray-100 focused' : ''"
                                        class="flex items-center w-full gap-3 px-3 py-1 rounded-lg">
                                        <input type="checkbox" checked.bind="selectedLocations.some((x) => x.id === location.id)" />
                                        <div class="flex min-w-0 items-center space-x-1.5">
                                            <span if.bind="location.applicationName" class="text-sm truncate">${ location.applicationName | translate:language }</span>
                                            <span else class="text-sm truncate">${ location.name | translate:language }</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div flex-1.class="viewWidth <= 870" class="relative flex">
                    <div data-role="combobox" class.bind="usersFocused ? 'ring-1' :''" class="flex h-[40px] w-48 flex-1 flex-col rounded-lg border border-gray-200 ring-gray-400 ring-offset-1">
                        <div class="flex items-center flex-1">
                            <div if.bind="selectedUsers.length > 0" class="flex flex-1 min-w-0 gap-1 p-1 flex-nowrap">
                                <div class="flex min-w-0 flex-1 items-center space-x-1.5 rounded-lg bg-gray-100 px-2 py-1">
                                    <span class="flex-1 block text-sm truncate whitespace-nowrap">
                                        <span if.bind="selectedUsers.length !== 1">${ selectedUsers.length }</span>
                                        <span if.bind="selectedUsers.length === 1">${selectedUsers[0].displayName}</span>
                                        <span class="lowercase" else t="translation:bx.scheduler.labels.users"></span>
                                    </span>
                                    <button
                                        click.trigger="clearSelectedUsers()"
                                        class="flex items-center justify-center w-5 h-5 text-left rounded-lg ring-red-500 ring-offset-1 focus:outline-none focus:ring-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-red-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <button
                                if.bind="selectedUsers.length === 0"
                                focus.trigger="handleUsersFocus($event)"
                                blur.trigger="handleUsersBlur($event)"
                                click.trigger="openUsers()"
                                class="flex items-center h-full px-5 text-left w-72 focus:outline-none">
                                <span class="text-sm text-gray-400" t="translation:bx.scheduler.placeholders.select-users"></span>
                            </button>
                            <div else class="flex items-center p-1">
                                <button
                                    click.trigger="openUsers()"
                                    class="flex items-center justify-center text-left bg-white border border-gray-600 rounded-lg h-7 w-7 ring-gray-400 ring-offset-1 hover:bg-gray-50 focus:outline-none focus:ring-1 active:bg-gray-100">
                                    <span if.bind="selectedUsers.length === 0" class="text-sm text-gray-400" t="translation:bx.scheduler.placeholders.select-users"></span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div show.bind="showUsers" ref="userOptions" class="absolute left-0 top-[45px] z-[10000] max-h-60 w-72 overflow-auto rounded-lg border border-gray-200 bg-white">
                            <div class="sticky top-0 z-10 bg-white border-b border-gray-200">
                                <input
                                    ref="searchUsersInput"
                                    input.trigger="handleUsersSearch($event) & debounce:500"
                                    t="[placeholder]translation:global.placeholders.search"
                                    class="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-none rounded-md outline-none"
                                    role="combobox"
                                    type="text"
                                    aria-expanded="false" />
                                <div class="p-2 border-t border-gray-200">
                                    <a click.trigger="selectAllUsers()" class="flex gap-3 px-3 py-1 text-sm rounded-lg text-primary hover:bg-gray-100" href="javascript:">
                                        <input type="checkbox" checked.bind="selectedAllUsers" />
                                        <span t="translation:global.buttons.select-all"></span>
                                    </a>
                                </div>
                            </div>
                            <ul class="flex flex-col gap-1 p-2" data-role="options">
                                <li class="flex" repeat.for="user of users">
                                    <a
                                        href="javascript:"
                                        data-role="option"
                                        click.trigger="selectUser(user)"
                                        mouseover.trigger="focused = $index"
                                        class.bind="$index === focused ? 'bg-gray-100 focused' : ''"
                                        class="flex items-center w-full gap-3 px-3 py-1 rounded-lg">
                                        <input type="checkbox" checked.bind="selectedUsers.some((x) => x.id === user.id)" />
                                        <div class="flex min-w-0 items-center space-x-1.5">
                                            <span class="text-sm truncate">${ user.displayName }</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center gap-2">
                <ux-button click.trigger="handleToToday()" if.bind="showToday" color="secondary" padding.bind="false">
                    <div class="flex h-[38px] items-center justify-center px-3">
                        <p t="translation:global.days.today"></p>
                    </div>
                </ux-button>
                <div class="flex gap-1">
                    <ux-button click.trigger="handlePreviousWeekOrDay()" mode="outline" color="dark" padding.bind="false">
                        <div class="flex h-[38px] w-[38px] items-center justify-center">
                            <svg class="w-4 h-4 text-gray-800" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                                <path
                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                            </svg>
                        </div>
                    </ux-button>
                    <ux-button click.trigger="handleNextWeekOrDay()" mode="outline" color="dark" padding.bind="false">
                        <div class="flex h-[38px] w-[38px] items-center justify-center">
                            <svg class="w-4 h-4 text-gray-800" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                                <path
                                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                            </svg>
                        </div>
                    </ux-button>
                </div>
                <ux-button if.bind="hasRole(UserRoles.WriteAppointments)" color="primary" padding.bind="false" click.trigger="handleNewAppointment()">
                    <div class="flex h-[38px] w-[38px] items-center justify-center">
                        <svg class="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" stroke="currentColor">
                            <path
                                fill="currentColor"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                    </div>
                </ux-button>
                <div
                    text-green-800.class="state === 'OnConnected' || state === 'OnReconnected'"
                    bg-green-200.class="state === 'OnConnected' || state === 'OnReconnected'"
                    text-red-800.class="state === 'OnDisconnected'"
                    bg-red-200.class="state === 'OnDisconnected'"
                    text-yellow-800.class="state === 'OnConnecting' || state === 'OnReconnecting'  || state === 'OnDisconnecting'"
                    bg-yellow-200.class="state === 'OnConnecting' || state === 'OnReconnecting'  || state === 'OnDisconnecting'"
                    class="flex h-[40px] flex-1 items-center gap-2 truncate rounded-lg border-gray-300 px-3">
                    <p class="text-xs" t="translation:bx.scheduler.labels.auto-sync"></p>
                    <div if.bind="state === 'OnConnected'" class="w-2 h-2 bg-green-500 rounded-full animation-pulse-green"></div>
                    <div if.bind="state === 'OnDisconnected'" class="w-2 h-2 bg-red-500 rounded-full animation-pulse-red"></div>
                    <div if.bind="state === 'OnConnecting' || state === 'OnReconnecting' || state === 'OnDisconnecting'" class="w-2 h-2 bg-yellow-500 rounded-full animation-pulse-yellow"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div h-11.class="hasSelectedLocations" h-9.class="hasSelectedUsers || (!hasSelectedUsers && !hasSelectedLocations)" class="flex">
        <div class="w-[60px]"></div>
        <au-slot />
    </div> -->
</div>
