import { bindable } from 'aurelia';
import tippy from 'tippy.js';

export class UxTooltip {
    @bindable() public content: string;

    public container: HTMLDivElement;

    public bound(): void {
        tippy(this.container, {
            content: this.content
        });
    }
}
