import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AnatomicalRegionEntityReference,
    BodySides,
    DifferentialDiagnosesApiClient,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalRecordResponse,
    GetMedicalTherapyResponse,
    GetMedicalTreatmentProtocolResponse,
    MedicalExaminationActionEntityReference,
    MedicalExaminationActionsApiClient,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalQuestionEntityReference,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient,
    MedicalRecordQueueItem,
    MedicalRecordQueueItemActions,
    MedicalRecordQueueItemTypes,
    MedicalRecordsApiClient,
    MedicalTherapiesApiClient,
    MedicalTreatmentProtocolsApiClient,
    ProcessMedicalRecordQueueRequest,
    SuggestDifferentialDiagnosesRequest,
    SuggestDifferentialDiagnosesResponseItem
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { UxInput } from '../../ux/ux-input/ux-input';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';

@inject(
    CacheService,
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    DifferentialDiagnosesApiClient,
    MedicalExaminationActionsApiClient,
    MedicalQuestionsApiClient,
    MedicalQuestionnairesApiClient,
    MedicalRecordsApiClient,
    MedicalTreatmentProtocolsApiClient,
    MedicalTherapiesApiClient,
    ModalService
)
export class PartialMedicalSuggestions extends BasePartialView {
    public text: string;
    public suggestions: SuggestDifferentialDiagnosesResponseItem[] = [];
    public isCollecting: boolean = false;
    public MedicalExaminationTemplateItemStepTypes: typeof MedicalExaminationTemplateItemStepTypes = MedicalExaminationTemplateItemStepTypes;
    public BodySides: typeof BodySides = BodySides;
    public items: {
        diagnosis: GetDifferentialDiagnosisResponse;
        suggestion: SuggestDifferentialDiagnosesResponseItem;
        steps: MedicalExaminationTemplateItemStep[];
        expanded: boolean;
        location: AnatomicalRegionEntityReference;
        side: BodySides;
        protocols: {
            protocol: GetMedicalTreatmentProtocolResponse;
            therapies: GetMedicalTherapyResponse[];
        }[];
    }[] = [];

    private record: GetMedicalRecordResponse;
    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient,
        private readonly actionsApi: MedicalExaminationActionsApiClient,
        private readonly questionsApi: MedicalQuestionsApiClient,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient,
        private readonly recordsApi: MedicalRecordsApiClient,
        private readonly protocolsApi: MedicalTreatmentProtocolsApiClient,
        private readonly therapiesApi: MedicalTherapiesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.text = view.data.text;
        this.record = view.data.record;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                await this.getData(false, true);

                this.subscriptions.push(
                    this.events.subscribe(CustomEvents.MedicalRecordsCourseOfComplaintsChanged, async (complaints: string) => {
                        this.removeChildViews();

                        const escapeRegExp = (text: string) => {
                            // Remove special characters using regex
                            let cleanedText = text.replace(/[^\w\s]/gi, '');
                            // Remove line breaks
                            cleanedText = cleanedText.replace(/\n/g, ' ');
                            // Remove double spaces
                            cleanedText = cleanedText.replace(/\s{2,}/g, ' ');
                            // Return the cleaned text
                            return cleanedText;
                        };

                        this.text = escapeRegExp(complaints);
                        this.isLoading = true;
                        const [diagnoses] = await Promise.all([
                            this.diagnosesApi.suggest(this.authenticated.workspace.id, 100, 0, new SuggestDifferentialDiagnosesRequest({ text: this.text })) //
                        ]);

                        this.suggestions = diagnoses;
                        this.isLoading = false;
                    })
                );

                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalSuggestions.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public isAlreadyAdded(suggestion: SuggestDifferentialDiagnosesResponseItem): boolean {
        // Find the results for the current suggestions by differential diagnosis id.
        const results = this.record.results.filter((x) => x.differentialDiagnosis.id === suggestion.id);
        // If no results are found, then the suggestion is not added yet.
        if (results.length === 0) return false;
        // If only one result is found, but the side is not defined, then the suggestion is added already.
        if (results.length === 1 && isNotDefined(results[0].side)) return true;
        // If more than one result is found, then the suggestion is added already.
        // Because we can have a max of 2 results per differential diagnosis (left and right sides).
        if (results.length > 1) return true;
        // We have one result and the side is defined, so the other side is not added yet.
        return false;
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true);

        element.isLoading = false;
    }

    public async handleSuggestionSelected(suggestion: SuggestDifferentialDiagnosesResponseItem): Promise<void> {
        this.setWidth(1200);
        this.scrollTo(this.partial);

        this.isCollecting = true;

        // Fetch the full data of the selected diagnoses and their protocols.
        const [diagnoses, protocols] = await Promise.all([
            this.diagnosesApi.search(this.authenticated.workspace.id, '', 1, 0, undefined, undefined, undefined, [suggestion.id]),
            this.protocolsApi.search(this.authenticated.workspace.id, '', 25, 0, undefined, undefined, undefined, [suggestion.id])
        ]);

        // Get all the full data of the therapies linked in the protocols
        const therapyIds = protocols.data //
            .selectMany((x: GetMedicalTreatmentProtocolResponse) => x.therapies)
            .map((x) => x.therapy.id);
        const therapies = await this.therapiesApi.search(this.authenticated.workspace.id, '', therapyIds.length, 0, undefined, undefined, undefined, therapyIds);

        /**
         * Fetches the full data of all the linked steps.
         * @param suggestion The suggested suggestion holding the linked ids.
         */
        const prepareSuggestions = async (suggestion: SuggestDifferentialDiagnosesResponseItem): Promise<void> => {
            // Get the full data of the diagnosis.
            const diagnosis = diagnoses.data.find((x) => x.id === suggestion.id);
            // Filter out the different type of linked ids.
            const actionIds = suggestion.linked.filter((x) => x.type === 'MedicalExaminationAction').map((x) => x.id);
            const questionIds = suggestion.linked.filter((x) => x.type === 'MedicalQuestion').map((x) => x.id);
            const questionnaireIds = suggestion.linked.filter((x) => x.type === 'MedicalQuestionnaire').map((x) => x.id);
            // Fetch the full data of each of the linked ids.
            const [actions, questions, questionnaires] = await Promise.all([
                this.actionsApi.search(this.authenticated.workspace.id, undefined, actionIds.length, 0, undefined, undefined, undefined, actionIds),
                this.questionsApi.search(this.authenticated.workspace.id, undefined, questionIds.length, 0, undefined, undefined, undefined, undefined, undefined, questionIds),
                this.questionnairesApi.search(this.authenticated.workspace.id, undefined, questionnaireIds.length, 0, undefined, undefined, undefined, questionnaireIds)
            ]);

            const item: {
                diagnosis: GetDifferentialDiagnosisResponse;
                suggestion: SuggestDifferentialDiagnosesResponseItem;
                steps: MedicalExaminationTemplateItemStep[];
                expanded: boolean;
                location: AnatomicalRegionEntityReference;
                side: BodySides;
                protocols: {
                    protocol: GetMedicalTreatmentProtocolResponse;
                    therapies: GetMedicalTherapyResponse[];
                }[];
            } = {
                diagnosis, //
                suggestion,
                expanded: false,
                side: null,
                location: null,
                steps: [],
                protocols: protocols.data //
                    .filter((protocol) => protocol.differentialDiagnosis.id === diagnosis.id)
                    .map((protocol) => ({
                        protocol,
                        therapies: protocol.therapies.map((item) => therapies.data.find((x) => x.id === item.therapy.id))
                    }))
            };

            item.steps = [
                // First add the previously added steps.
                ...item.steps, //
                // Map each fetched action to an examination step.
                ...actions.data.map(
                    (action) =>
                        new MedicalExaminationTemplateItemStep({
                            id: guid(),
                            attributes: { action },
                            type: MedicalExaminationTemplateItemStepTypes.Action,
                            action: new MedicalExaminationActionEntityReference({
                                id: action.id,
                                translations: action.name
                            }),
                            isDynamicallyAdded: true
                        })
                ),
                // Map each fetched question to an examination step.
                ...questions.data.map(
                    (question) =>
                        new MedicalExaminationTemplateItemStep({
                            id: guid(),
                            attributes: { question },
                            type: MedicalExaminationTemplateItemStepTypes.Question,
                            question: new MedicalQuestionEntityReference({
                                id: question.id,
                                translations: question.name
                            }),
                            isDynamicallyAdded: true
                        })
                ),
                // Map each fetched quesionnaire to an examination step.
                ...questionnaires.data.map(
                    (questionnaire) =>
                        new MedicalExaminationTemplateItemStep({
                            id: guid(),
                            attributes: { questionnaire },
                            type: MedicalExaminationTemplateItemStepTypes.Questionnaire,
                            questionnaire: new MedicalQuestionEntityReference({
                                id: questionnaire.id,
                                translations: questionnaire.name
                            }),
                            isDynamicallyAdded: true
                        })
                )
            ];

            this.items = [item, ...this.items];
        };

        await prepareSuggestions(suggestion);
        this.isCollecting = false;
    }

    public async addToExamination(): Promise<void> {
        if (this.items.empty()) return;

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.medical-suggestions.questions.add.title'),
                message: this.t.tr('translation:partial-views.medical-suggestions.questions.add.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.add'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        // Because updating the record will trigger a re-render
                        // we need to make sure that the record is not being updated by this view
                        // while we are adding the steps to the record. This was causing the widgets
                        // references being added more than once in the widgets array.
                        // So by providing this as a callback to the event the adding of the suggested steps
                        // is done when medical-record-details view is processing all changes.
                        const doWhenSaving = async (record: GetMedicalRecordResponse, registrations: any): Promise<[GetMedicalRecordResponse, any]> => {
                            this.isLoading = true;

                            const queue = this.items.map(
                                (x) =>
                                    new MedicalRecordQueueItem({
                                        id: guid(),
                                        entityId: x.diagnosis.id,
                                        entityType: MedicalRecordQueueItemTypes.DifferentialDiagnosis,
                                        action: MedicalRecordQueueItemActions.Add,
                                        side: x.side,
                                        location: x.location,
                                        manuallyAdded: true
                                    })
                            );

                            const response = await this.recordsApi.processQueue(
                                this.record.id, //
                                this.authenticated.workspace.id,
                                new ProcessMedicalRecordQueueRequest({ queue })
                            );

                            for (const registration of response.newRegistrations) registrations[registration.stepId] = registration;
                            for (const registration of response.deletedRegistrations) delete registrations[registration.stepId];

                            record = response.record;

                            return [record, registrations];
                        };

                        this.events.publish(CustomEvents.MedicalRecordUpdated, {
                            record: this.record,
                            callback: doWhenSaving
                        });
                        this.remove({ result: PartialViewResults.Ok });
                    }
                }
            })
        );
    }

    public handleRegionSelected = async (region: GetAnatomicalRegionResponse, index: number): Promise<void> => {
        if (isNotDefined(region)) this.items[index].location = null;
        else
            this.items[index].location = new AnatomicalRegionEntityReference({
                id: region.id,
                translations: region.name
            });
    };

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public sideIsAvailable(side: BodySides, index: number): boolean {
        const diagnosis = this.items[index].diagnosis;
        const results = this.record.results.filter((x) => x.differentialDiagnosis.id === diagnosis.id);

        if (results.length === 0) return true;
        if (results.length > 1) return false;

        const result = results[0];
        return result.side !== side;
    }

    public toggleSteps(index: number): void {
        this.items[index].expanded = !this.items[index].expanded;
    }

    public async handleSideSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const value = e.detail.values?.value as BodySides;
        const index = e.detail.data as number;
        if (isDefined(value)) this.items[index].side = value;
    }

    public removeStep(index: number): void {
        this.items.splice(index, 1);
        if (this.items.empty()) this.setWidth(500);
    }

    private async getData(reset: boolean = false, initial: boolean = false): Promise<void> {
        if (reset) {
            this.skip = 0;
            this.suggestions = [];
            this.endOfList = false;
            if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
        }

        this.isLoading = true;

        let response: SuggestDifferentialDiagnosesResponseItem[];
        if (isDefined(this.query) && isNotEmpty(this.query)) {
            response = await this.diagnosesApi.suggest(
                this.authenticated.workspace.id,
                this.pageSize,
                this.skip,
                new SuggestDifferentialDiagnosesRequest({ query: this.query }) //
            );
        } else {
            // Always refert back to the suggestion by text;
            response = await this.diagnosesApi.suggest(this.authenticated.workspace.id, this.pageSize, this.skip, new SuggestDifferentialDiagnosesRequest({ text: this.text })); //
        }

        if (!reset && response.empty() && !initial) {
            this.endOfList = true;
            this.isLoading = false;
            return;
        }

        this.skip += Number(this.pageSize);
        this.suggestions = [...this.suggestions, ...response];
        this.isLoading = false;
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
