import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject()
export class PartialMedicationDetails extends BasePartialView {
    public medication: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public loading(partial: PartialView): void {
        super.setView({ view: partial });
        this.medication = partial.data?.medication;
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialMedicationDetails.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }
}
