<import from="converters/translate" />
<import from="converters/get-ids" />
<import from="converters/get-used-widget-ids" />
<import from="converters/template-type-to-string" />
<import from="converters/currency" />
<import from="bx/bx-examination-phase-selector/bx-examination-phase-selector" />
<import from="bx/bx-examination-model-selector/bx-examination-model-selector" />
<import from="bx/bx-medical-question-selector/bx-medical-question-selector" />
<import from="bx/bx-medical-questionnaire-selector/bx-medical-questionnaire-selector" />
<import from="bx/bx-medical-action-selector/bx-medical-action-selector" />
<import from="bx/bx-medical-widget-selector/bx-medical-widget-selector" />

<import from="../templates/template-action/template-action" />
<import from="../templates/template-question/template-question" />
<import from="../templates/template-questionnaire/template-questionnaire" />
<import from="../templates/template-widget/template-widget" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-1 gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div class="flex flex-1 flex-col overflow-hidden pl-7 pr-8">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 space-x-5 overflow-hidden pb-5 pt-7">
                        <div class="flex w-[400px] min-w-[400px] flex-col gap-y-3 pl-1">
                            <div class="flex justify-between">
                                <p class="font-medium text-gray-400" t="translation:partial-views.medical-examinations.edit.title"></p>
                                <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                    <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                            <path
                                                fill="currentColor"
                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option click.trigger="informationSheet()">
                                        <span class="text-sm" t="global.buttons.information-sheet"></span>
                                    </ux-dropdown-option>
                                    <ux-dropdown-option click.trigger="copy()">
                                        <span class="text-sm" t="global.buttons.copy"></span>
                                    </ux-dropdown-option>
                                    <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteMedicalExaminations)"></ux-dropdown-divider>
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteMedicalExaminations)" click.trigger="delete()">
                                        <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="global.labels.name"></span>
                                    </ux-label>
                                    <span class="flex h-3 items-center text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('name', true)"
                                    rows="1"
                                    value.two-way="examination.name[language]"
                                    valid.bind="validation.name"
                                    t="[placeholder]partial-views.medical-examinations.placeholders.name">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="global.labels.description"></span>
                                </ux-label>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('description')"
                                    value.two-way="examination.description[language]"
                                    rows="8"
                                    t="[placeholder]partial-views.medical-examinations.placeholders.description">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.medical-examinations.labels.select-model"></span>
                                </ux-label>
                                <bx-examination-model-selector
                                    on-select.bind="handleModelSelected"
                                    language.bind="language"
                                    allow-clear="true"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]translation:partial-views.medical-examinations.placeholders.select-model"
                                    value.bind="examination.model.id">
                                </bx-examination-model-selector>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="global.labels.healthcare-sector"></span>
                                </ux-label>
                                <ux-multi-selector used.bind="examination.healthcareSectors" values.two-way="examination.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                            </div>
                            <div class="flex flex-col">
                                <div mb-2.class="examination.prices.length > 0" mb-1.class="examination.prices.length === 0" class="flex items-center justify-between">
                                    <ux-label>
                                        <span t="translation:partial-views.manage-appointment-types.labels.prices"></span>
                                    </ux-label>
                                    <ux-button if.bind="examination.prices.length > 0" click.trigger="createOrEditPrice()" padding.bind="false" size="xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </ux-button>
                                </div>
                                <div class="flex" if.bind="examination.prices.length === 0">
                                    <button
                                        click.trigger="createOrEditPrice()"
                                        type="button"
                                        class="relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                        <svg class="h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                        <span class="block text-sm font-semibold text-gray-900" t="translation:partial-views.medical-examinations.buttons.add-price"></span>
                                    </button>
                                </div>
                                <ul else class="flex flex-col gap-1">
                                    <li class="flex" repeat.for="price of examination.prices">
                                        <ux-card>
                                            <div class="relative flex items-center rounded-lg border-l border-t border-gray-200">
                                                <button
                                                    click.trigger="createOrEditPrice($index)"
                                                    class="flex h-full flex-1 items-center gap-6 rounded-l-lg px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                    <div class="flex items-center text-sm">
                                                        <p class="mr-3 text-gray-800">${price.netValue | currency}</p>
                                                        <p class="text-xs text-gray-500">${formatDescription($index)}</p>
                                                    </div>
                                                </button>
                                                <button
                                                    click.trigger="removePrice($index)"
                                                    class="flex w-10 items-center justify-center rounded-r-lg border-l border-gray-200 bg-white p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100"
                                                    click.trigger="delete($index)">
                                                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </ux-card>
                                    </li>
                                </ul>
                            </div>

                            <div class="flex flex-col">
                                <div mb-2.class="examination.codes.length > 0" mb-1.class="examination.codes.length === 0" class="flex items-center justify-between">
                                    <ux-label>
                                        <span t="translation:global.labels.healthcare-codes"></span>
                                    </ux-label>
                                    <ux-button if.bind="examination.codes.length > 0" click.trigger="addCode()" padding.bind="false" size="xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </ux-button>
                                </div>
                                <ul if.bind="examination.codes.length > 0" class="flex flex-col space-y-1">
                                    <li repeat.for="code of examination.codes" class="flex w-full">
                                        <div class="relative flex flex-1 items-center rounded-lg bg-gray-50">
                                            <button
                                                click.trigger="editCode($index)"
                                                class="flex h-full flex-1 items-center gap-6 rounded-l-lg border-y border-l border-gray-200 px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                <div class="flex items-center text-sm">${code.system}/${code.value}</div>
                                            </button>
                                            <button
                                                click.trigger="removeCode($index)"
                                                class="flex w-10 items-center justify-center rounded-r-lg border border-gray-200 bg-gray-50 p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                                <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                                <button
                                    else
                                    click.trigger="addCode($index)"
                                    type="button"
                                    class="text-gray-800.class relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                                    </svg>

                                    <span class="block text-sm font-semibold" t="translation:partial-views.medical-step-settings.buttons.add-code"></span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-1 flex-col">
                            <div class="mb-1 p-1">
                                <ul if.bind="examination.template.phases.length > 0" class="flex items-center space-x-1">
                                    <li class="flex flex-col whitespace-nowrap" repeat.for="template of examination.template.phases">
                                        <button
                                            click.trigger="setActive($index)"
                                            class="${ validation.phases[$index].phase && validation.phases[$index].valid ? template.id === active.id ? 'border-blue-500 ring-blue-400' : 'border-gray-200 ring-gray-400' : 'border-red-500 ring-red-500' } flex h-[25px] flex-col items-center justify-center rounded border px-4 text-xs ring-offset-2 hover:bg-gray-50 focus:outline-none focus:ring-1 active:text-gray-500">
                                            <span if.bind="template.phase" class="${ validation.phases[$index].phase && validation.phases[$index].valid ? '' : 'text-red-500' }">
                                                <span if.bind="phases[template.phase.id]">${ phases[template.phase.id].name | translate:language }</span>
                                                <span else>${ template.phase.name | translate:language }</span>
                                            </span>
                                            <span
                                                else
                                                class="${ validation.phases[$index].phase && validation.phases[$index].valid ? 'text-gray-500' : 'text-red-500' }"
                                                t="partial-views.medical-examinations.placeholders.no-phase">
                                            </span>
                                        </button>
                                    </li>
                                    <li class="sticky right-0 z-10 bg-white">
                                        <button
                                            click.trigger="addPhase()"
                                            class="flex h-[25px] items-center justify-center rounded border border-gray-200 px-4 text-xs ring-gray-400 ring-offset-2 hover:bg-gray-50 focus:outline-none focus:ring-1 active:text-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="flex flex-1 flex-col overflow-hidden px-1">
                                <div class="flex flex-1 flex-col overflow-y-auto rounded-lg bg-gray-100 px-10 pb-10">
                                    <div if.bind="examination.template.phases.length > 0" class="flex flex-1 flex-col">
                                        <div class="${ active.phase ? 'flex-1' : '' } flex flex-col items-center">
                                            <div class="sticky top-0 z-[999] flex w-full items-center justify-center bg-gray-100/50 pt-5 backdrop-blur-xl">
                                                <div style="width: ${active.width}px" class="flex flex-1 flex-col items-center border-b border-gray-200 pb-5">
                                                    <p if.bind="active.phase && phases[active.phase.id]" class="text-sm text-gray-800">${ phases[active.phase.id].name | translate:language }</p>
                                                    <p if.bind="active.phase && !phases[active.phase.id]" class="text-sm text-gray-800">${ active.phase.name | translate:language }</p>
                                                    <template if.bind="active.phase">
                                                        <template if.bind="editWidth">
                                                            <div class="mt-2 flex flex-col items-center">
                                                                <div class="item-center flex">
                                                                    <ux-input
                                                                        component.ref="widthInput"
                                                                        valid.bind="validation.phases[activeIndex].width"
                                                                        autofocus="true"
                                                                        oninput.trigger="handleWidthInputChanged($event)"
                                                                        select-text-on-focus="true"
                                                                        class="w-[100px]"
                                                                        rounded="left"
                                                                        type="number">
                                                                    </ux-input>
                                                                    <button
                                                                        class.bind="validation.phases[activeIndex].width ? 'border-gray-300' : 'border-red-500'"
                                                                        class="inline-flex h-[38px] items-center rounded-r-lg border-y border-r bg-white p-2 transition duration-150 ease-in-out focus:z-30 focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                                                                        click.trigger="toggleSetWidth($index)">
                                                                        <svg
                                                                            class.bind="validation.phases[activeIndex].width ? 'text-green-500' : 'text-red-500'"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke-width="1.5"
                                                                            stroke="currentColor"
                                                                            class="h-5 w-5">
                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <p
                                                                    class.bind="validation.phases[activeIndex].width ? 'text-gray-500' : 'text-red-500'"
                                                                    class="mt-3 text-xs"
                                                                    t="translation:partial-views.medical-examinations.messages.screen-width"></p>
                                                            </div>
                                                        </template>
                                                        <template else>
                                                            <a href="javascript:" click.trigger="toggleSetWidth()" class="flex items-center gap-x-1 text-sm text-gray-400 hover:text-gray-700">
                                                                <p t="translation:partial-views.medical-examinations.labels.screen-width"></p>
                                                                <p>${ active.width || 1278 }px</p>
                                                            </a>
                                                        </template>
                                                    </template>
                                                </div>
                                                <div class="absolute right-0 top-10">
                                                    <ux-dropdown
                                                        transparent.bind="true"
                                                        border.bind="false"
                                                        type="full"
                                                        color="dark"
                                                        size="sm"
                                                        padding.bind="false"
                                                        mode="outline"
                                                        placement="bottom-right">
                                                        <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                            <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                            </svg>
                                                        </div>
                                                        <template if.bind="examination.template.phases.length > 1">
                                                            <ux-dropdown-option click.trigger="collapseOrExpandAll('expand')">
                                                                <span class="text-sm" t="global.buttons.expand-all"></span>
                                                            </ux-dropdown-option>
                                                            <ux-dropdown-option click.trigger="collapseOrExpandAll('collapse')">
                                                                <span class="text-sm" t="global.buttons.collapse-all"></span>
                                                            </ux-dropdown-option>
                                                            <ux-dropdown-option if.bind="activeIndex !== 0" click.trigger="movePhase('up')">
                                                                <span class="text-sm" t="global.buttons.move-up"></span>
                                                            </ux-dropdown-option>
                                                            <ux-dropdown-option if.bind="activeIndex < examination.template.phases.length - 1" click.trigger="movePhase('down')">
                                                                <span class="text-sm" t="global.buttons.move-down"></span>
                                                            </ux-dropdown-option>
                                                            <ux-dropdown-divider></ux-dropdown-divider>
                                                        </template>
                                                        <ux-dropdown-option click.trigger="removePhase()">
                                                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                        </ux-dropdown-option>
                                                    </ux-dropdown>
                                                </div>
                                            </div>
                                            <template if.bind="active.phase">
                                                <div class="mt-5 flex-1" css="width: ${active.width}px">
                                                    <ux-card>
                                                        <div class.bind="active.stepsToTake.length > 0 ? 'space-y-10' : ''" class="flex flex-col p-10">
                                                            <div class="grid grid-cols-12 gap-1">
                                                                <template repeat.for="step of active.stepsToTake">
                                                                    <template if.bind="step.type">
                                                                        <template if.bind="step.question || step.questionnaire || step.action || step.widget">
                                                                            <template-action
                                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Action"
                                                                                step.bind="step"
                                                                                index.bind="$index"
                                                                                amount-of-steps.bind="active.stepsToTake.length"
                                                                                flow.bind="examination.flow"
                                                                                add-step.bind="addStep"
                                                                                remove-step.bind="removeStep"
                                                                                open-settings.bind="openSettings"
                                                                                move-step.bind="moveStep"
                                                                                copy-or-cut.bind="copyOrCut"
                                                                                paste-item.bind="pasteItem"
                                                                                language.bind="language">
                                                                            </template-action>
                                                                            <template-question
                                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Question"
                                                                                step.bind="step"
                                                                                index.bind="$index"
                                                                                amount-of-steps.bind="active.stepsToTake.length"
                                                                                flow.bind="examination.flow"
                                                                                add-step.bind="addStep"
                                                                                remove-step.bind="removeStep"
                                                                                open-settings.bind="openSettings"
                                                                                move-step.bind="moveStep"
                                                                                copy-or-cut.bind="copyOrCut"
                                                                                paste-item.bind="pasteItem"
                                                                                language.bind="language">
                                                                            </template-question>
                                                                            <template-questionnaire
                                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire"
                                                                                step.bind="step"
                                                                                index.bind="$index"
                                                                                amount-of-steps.bind="active.stepsToTake.length"
                                                                                flow.bind="examination.flow"
                                                                                add-step.bind="addStep"
                                                                                remove-step.bind="removeStep"
                                                                                open-settings.bind="openSettings"
                                                                                move-step.bind="moveStep"
                                                                                copy-or-cut.bind="copyOrCut"
                                                                                paste-item.bind="pasteItem"
                                                                                language.bind="language">
                                                                            </template-questionnaire>
                                                                            <template-widget
                                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Widget"
                                                                                step.bind="step"
                                                                                index.bind="$index"
                                                                                amount-of-steps.bind="active.stepsToTake.length"
                                                                                flow.bind="examination.flow"
                                                                                add-step.bind="addStep"
                                                                                remove-step.bind="removeStep"
                                                                                open-settings.bind="openSettings"
                                                                                move-step.bind="moveStep"
                                                                                copy-or-cut.bind="copyOrCut"
                                                                                paste-item.bind="pasteItem"
                                                                                language.bind="language">
                                                                            </template-widget>
                                                                        </template>
                                                                        <template else>
                                                                            <div class="col-span-12 flex flex-col items-center rounded-lg bg-gray-100 p-5">
                                                                                <div class="grid w-full max-w-md grid-cols-12 gap-1">
                                                                                    <!-- Template for medical actions -->
                                                                                    <template if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Action">
                                                                                        <div class="col-span-12 flex flex-col">
                                                                                            <ux-label
                                                                                                class="mb-1 text-center"
                                                                                                t="translation:partial-views.medical-examinations.labels.select-medical-action"></ux-label>
                                                                                            <bx-medical-action-selector
                                                                                                on-select.bind="handleActionSelected"
                                                                                                language.bind="language"
                                                                                                data.bind="$index"
                                                                                                valid.bind="validation.phases[activeIndex].steps[$index].action"
                                                                                                workspace.bind="authenticated.workspace.id"
                                                                                                t="[placeholder]translation:partial-views.medical-examinations.placeholders.select-medical-action">
                                                                                            </bx-medical-action-selector>
                                                                                        </div>
                                                                                    </template>
                                                                                    <!-- Template for medical questions -->
                                                                                    <template if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Question">
                                                                                        <div class="col-span-12 flex flex-col">
                                                                                            <ux-label
                                                                                                class="mb-1 text-center"
                                                                                                t="translation:partial-views.medical-examinations.labels.select-medical-question"></ux-label>
                                                                                            <bx-medical-question-selector
                                                                                                on-select.bind="handleQuestionSelected"
                                                                                                language.bind="language"
                                                                                                data.bind="$index"
                                                                                                valid.bind="validation.phases[activeIndex].steps[$index].question"
                                                                                                workspace.bind="authenticated.workspace.id"
                                                                                                t="[placeholder]translation:partial-views.medical-examinations.placeholders.select-medical-question">
                                                                                            </bx-medical-question-selector>
                                                                                        </div>
                                                                                    </template>
                                                                                    <!-- Template for widgets -->
                                                                                    <template if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Widget">
                                                                                        <div class="col-span-12 flex flex-col">
                                                                                            <ux-label
                                                                                                class="mb-1 text-center"
                                                                                                t="translation:partial-views.medical-examinations.labels.select-widget"></ux-label>
                                                                                            <bx-medical-widget-selector
                                                                                                on-select.bind="handleWidgetSelected"
                                                                                                language.bind="language"
                                                                                                data.bind="$index"
                                                                                                workspace.bind="authenticated.workspace.id"
                                                                                                valid.bind="validation.phases[activeIndex].steps[$index].widget"
                                                                                                used.bind="examination.template.phases | getUsedWidgetIds:'examination'"
                                                                                                t="[placeholder]translation:partial-views.medical-examinations.placeholders.select-widget">
                                                                                            </bx-medical-widget-selector>
                                                                                        </div>
                                                                                    </template>
                                                                                    <!-- Template for medical questionnaires -->
                                                                                    <template if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire">
                                                                                        <div class="col-span-12 flex flex-col">
                                                                                            <ux-label
                                                                                                class="mb-1 text-center"
                                                                                                t="translation:partial-views.medical-examinations.labels.select-medical-questionnaire">
                                                                                            </ux-label>
                                                                                            <bx-medical-questionnaire-selector
                                                                                                on-select.bind="handleQuestionnaireSelected"
                                                                                                language.bind="language"
                                                                                                data.bind="$index"
                                                                                                valid.bind="validation.phases[activeIndex].steps[$index].questionnaire"
                                                                                                workspace.bind="authenticated.workspace.id"
                                                                                                t="[placeholder]translation:partial-views.medical-examinations.placeholders.select-medical-questionnaire">
                                                                                            </bx-medical-questionnaire-selector>
                                                                                        </div>
                                                                                    </template>
                                                                                </div>
                                                                                <ux-button class="mt-3" click.trigger="removeStep($index)" color="danger" mode="outline">
                                                                                    <div class="pointer-events-none text-xs">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                            stroke-width="1.5"
                                                                                            stroke="currentColor"
                                                                                            class="h-4 w-4">
                                                                                            <path
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"
                                                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </ux-button>
                                                                            </div>
                                                                        </template>
                                                                    </template>
                                                                    <template else>
                                                                        <div
                                                                            class.bind="validation.phases[activeIndex].steps[$index].valid ? '' : 'border-red-500 border'"
                                                                            class="col-span-12 flex flex-col items-center rounded-lg bg-gray-100 p-5">
                                                                            <ux-label class="mb-1">
                                                                                <span t="translation:partial-views.medical-examinations.labels.select-template-type"></span>
                                                                            </ux-label>
                                                                            <div class="mt-2 flex gap-2">
                                                                                <ux-button
                                                                                    click.trigger="selectType(MedicalExaminationTemplateItemStepTypes.Action, $index)"
                                                                                    color="dark"
                                                                                    mode="outline">
                                                                                    <span class="pointer-events-none text-xs">
                                                                                        ${ MedicalExaminationTemplateItemStepTypes.Action | templateTypeToString | t }
                                                                                    </span>
                                                                                </ux-button>
                                                                                <ux-button
                                                                                    click.trigger="selectType(MedicalExaminationTemplateItemStepTypes.Question, $index)"
                                                                                    color="dark"
                                                                                    mode="outline">
                                                                                    <span class="pointer-events-none text-xs">
                                                                                        ${ MedicalExaminationTemplateItemStepTypes.Question | templateTypeToString | t }
                                                                                    </span>
                                                                                </ux-button>
                                                                                <ux-button
                                                                                    click.trigger="selectType(MedicalExaminationTemplateItemStepTypes.Questionnaire, $index)"
                                                                                    color="dark"
                                                                                    mode="outline">
                                                                                    <span class="pointer-events-none text-xs">
                                                                                        ${ MedicalExaminationTemplateItemStepTypes.Questionnaire | templateTypeToString | t }
                                                                                    </span>
                                                                                </ux-button>
                                                                                <ux-button
                                                                                    click.trigger="selectType(MedicalExaminationTemplateItemStepTypes.Widget, $index)"
                                                                                    color="dark"
                                                                                    mode="outline">
                                                                                    <span class="pointer-events-none text-xs">
                                                                                        ${ MedicalExaminationTemplateItemStepTypes.Widget | templateTypeToString | t }
                                                                                    </span>
                                                                                </ux-button>
                                                                            </div>
                                                                            <ux-button class="mt-5" click.trigger="removeStep($index)" color="danger" mode="outline">
                                                                                <div class="pointer-events-none text-xs">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke-width="1.5"
                                                                                        stroke="currentColor"
                                                                                        class="h-4 w-4">
                                                                                        <path
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                                    </svg>
                                                                                </div>
                                                                            </ux-button>
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                            </div>
                                                            <div class="flex flex-1 flex-col items-center justify-center gap-y-2">
                                                                <ux-button click.trigger="addStep(-1)" color="dark" mode="outline">
                                                                    <span class="text-xs" t="partial-views.medical-examinations.buttons.add-step"></span>
                                                                </ux-button>
                                                                <ux-button if.bind="state.clipboard.templateItem" click.trigger="pasteItem(-1)" color="dark" mode="outline">
                                                                    <span class="text-xs" t="global.buttons.paste"></span>
                                                                </ux-button>
                                                            </div>
                                                        </div>
                                                    </ux-card>
                                                </div>
                                                <div class="mt-5 flex flex-col items-center gap-y-1">
                                                    <p class="text-xs text-gray-400" t="translation:global.labels.possible-step-settings"></p>
                                                    <div class="flex items-center gap-2 lowercase">
                                                        <div class="flex items-center gap-1">
                                                            <div class="h-2 w-2 rounded-sm bg-[#F25366]"></div>
                                                            <span class="text-xs" t="translation:global.labels.has-required-setting"></span>
                                                        </div>
                                                        <div class="flex items-center gap-1">
                                                            <div class="h-2 w-2 rounded-sm bg-primary"></div>
                                                            <span class="text-xs" t="translation:global.labels.has-display-requirements-setting"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div if.bind="!active.phase" class="flex w-full flex-1 items-center justify-center">
                                            <div class="flex w-72">
                                                <bx-examination-phase-selector
                                                    on-select.bind="handlePhaseSelected"
                                                    language.bind="language"
                                                    set-value-on-select.bind="false"
                                                    workspace.bind="authenticated.workspace.id"
                                                    t="[placeholder]global.placeholders.select-examination-phase"
                                                    valid.bind="validation.phases[activeIndex].phase"
                                                    used.bind="examination.template.phases | getIds:'phase'">
                                                </bx-examination-phase-selector>
                                            </div>
                                        </div>
                                    </div>
                                    <div else class="flex h-full w-full flex-col items-center justify-center">
                                        <p if.bind="!validation.any" class="mb-10 text-xs text-red-500" t="partial-views.medical-examinations.messages.phase-amount"></p>
                                        <div class="flex flex-col items-center gap-y-2">
                                            <ux-button click.trigger="addPhase()" color="dark" mode="outline">
                                                <span class="text-xs" t="partial-views.medical-examinations.buttons.add-phase"></span>
                                            </ux-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1">
                        <div class="flex flex-1 space-x-6 overflow-hidden pb-5 pt-8">
                            <div class="flex w-[400px]">
                                <div class="flex flex-1 flex-col">
                                    <div class="grid grid-cols-2 gap-5">
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-1 flex-col items-center justify-center rounded-lg bg-gray-100">
                                <div class="w-[700px] rounded-lg bg-white p-5 shadow-sm">
                                    <div class="grid grid-cols-2 gap-5">
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>

                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-between gap-x-2 border-t border-gray-200 bg-white py-4">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button-options placement="top-right" size="sm" color="success" disabled.bind="isLoading">
                            <ux-button size="sm" color="success" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save(true)">
                                <span class="pointer-events-none" t="global.buttons.save"></span>
                            </ux-button>
                            <ux-dropdown-option au-slot="options" click.trigger="save(false)">
                                <span t="translation:global.buttons.save-and-continue-edit"></span>
                            </ux-dropdown-option>
                        </ux-button-options>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
