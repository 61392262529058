import { AffixPositions } from '@wecore/sdk-healthcare';

export class AffixPositionToStringValueConverter {
    public toView(position: AffixPositions): string {
        switch (position) {
            case AffixPositions.Prefix:
                return 'global.labels.prefix';
            case AffixPositions.Suffix:
                return 'global.labels.suffix';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
