import { DialogController, IDialogController } from '@aurelia/dialog';
import { inject } from 'aurelia';

@inject(IDialogController)
export class ModalChooseLinkOrVideoUpload {
    public link: string;

    public constructor(
        private readonly controller: DialogController //
    ) {}

    public async ok(): Promise<void> {
        await this.controller.ok(this.link);
    }

    public async cancel(): Promise<void> {
        await this.controller.cancel();
    }
}
