import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    CreateMedicalTreatmentProtocolRequest,
    DifferentialDiagnosisEntityReference,
    GetDifferentialDiagnosisResponse,
    GetMedicalTherapyResponse,
    InformationSheet,
    MedicalTherapyEntityReference,
    MedicalTherapyProtocol,
    MedicalTreatmentProtocolsApiClient,
    TherapyDefaultValues
} from '@wecore/sdk-healthcare';
import { guid, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { clearTherapyEvaluationItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalTreatmentProtocolsApiClient)
export class PartialMedicalTreatmentProtocolsCreate extends BasePartialView {
    public request: CreateMedicalTreatmentProtocolRequest = new CreateMedicalTreatmentProtocolRequest({
        healthcareSectors: [],
        therapies: [],
        defaultTherapyValues: []
    });
    public validation = {
        name: true,
        purpose: true,
        diagnosis: true,
        therapies: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly treatmentsApi: MedicalTreatmentProtocolsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.purpose = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalTreatmentProtocolsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
        this.store.dispatch(clearTherapyEvaluationItem);
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public handleDiagnosisSelected = (diagnosis: GetDifferentialDiagnosisResponse, index: number): void => {
        this.request.differentialDiagnosis = new DifferentialDiagnosisEntityReference({
            id: diagnosis.id,
            translations: diagnosis.name
        });
    };

    public handleTherapySelected = (_: GetMedicalTherapyResponse[], action: 'added' | 'deleted', therapy: GetMedicalTherapyResponse): void => {
        if (action === 'added') {
            this.request.therapies.push(
                new MedicalTherapyProtocol({
                    id: guid(),
                    therapy: new MedicalTherapyEntityReference({
                        id: therapy.id,
                        translations: therapy.name
                    })
                })
            );
        } else {
            const index = this.request.therapies.findIndex((x) => x.therapy.id === therapy.id);
            if (index >= 0) this.request.therapies.splice(index, 1);
            // Also remove the default values.
            this.request.defaultTherapyValues = this.request.defaultTherapyValues.filter((x) => x.therapy.id !== therapy.id);
        }
    };

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'purpose'], this.request, 1);
                await this.treatmentsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-treatment-protocols.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-treatment-protocols.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-treatment]', e);
            }
        }
    }

    public setDefaultValues = async (option: { value: string; text: string; data?: any }): Promise<void> => {
        await this.removeChildViews();
        const defaultValues =
            this.request.defaultTherapyValues.find((x) => x.therapy.id === option.value) ?? //
            new TherapyDefaultValues({
                therapy: new MedicalTherapyEntityReference({
                    id: option.value
                }),
                defaultValues: []
            });

        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.MedicalTreatmentProtocolDefaultValues.with({ id: option.value, defaultValues }).whenClosed(async (result: PartialViewResults, values: TherapyDefaultValues) => {
                if (result !== PartialViewResults.Ok) return;
                const index = this.request.defaultTherapyValues.findIndex((x) => x.therapy.id === values.therapy.id);
                if (index > -1) this.request.defaultTherapyValues[index] = TherapyDefaultValues.fromJS(values);
                else this.request.defaultTherapyValues.push(TherapyDefaultValues.fromJS(values));
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: this.PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.purpose = validateTranslation(this.request.purpose, this.language);
        // this.validation.diagnosis = isDefined(this.request.differentialDiagnosis);
        // this.validation.therapies = this.request.therapies.any();

        return validateState(this.validation);
    }
}
