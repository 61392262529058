<import from="converters/translate" />
<import from="./template-post/template-post" />
<import from="./template-post-input/template-post-input" />
<import from="./template-post-attachments/template-post-attachments" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div ref="scrollContainer" class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class="top sticky top-0 z-50 flex flex-col border-b border-gray-200 bg-white pb-3 pt-8">
                        <div class="flex justify-between">
                            <p t="translation:partial-views.user-posts.title" class="font-medium text-gray-400"></p>
                        </div>
                        <template-post-input
                            if.bind="hasRole(UserRoles.WriteHealthcareTasks)"
                            has-files.bind="uploads.length > 0"
                            component.ref="input"
                            on-shift-enter.bind="handleNewReply"
                            on-escape.bind="handleCancel"
                            is-reacting.two-way="isReplying"
                            workspace.bind="authenticated.workspace.id"
                            language.bind="language">
                        </template-post-input>
                        <template-post-attachments
                            show.bind="isReplying && uploads.length > 0"
                            component.ref="picker"
                            uploads.two-way="uploads"
                            workspace.bind="authenticated.workspace.id"
                            can-delete.bind="authenticated.user.id === post.createdBy.id">
                        </template-post-attachments>

                        <div if.bind="isReplying || uploads.length > 0" class="my-2 flex justify-between gap-2">
                            <div class="flex gap-2">
                                <ux-button click.trigger="selectFile()" if.bind="authenticated.id === post.createdBy.id" size="xs" color="dark" mode="outline" padding.bind="false" border.bind="false">
                                    <div class="flex items-center gap-1 px-1 text-gray-500 hover:text-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                        </svg>
                                        <span class="text-xs" t="translation:global.buttons.add-attachment"></span>
                                    </div>
                                </ux-button>
                            </div>
                            <div class="flex gap-2">
                                <ux-button click.trigger="handleCancel()" size="xs" color="dark" mode="outline">
                                    <span t="translation:global.buttons.cancel"></span>
                                </ux-button>
                                <ux-button click.trigger="handleNewReply()" size="xs" color="primary">
                                    <span t="translation:global.buttons.save"></span>
                                </ux-button>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-1 flex-col gap-4 pb-5 pt-3">
                        <template-post
                            repeat.for="post of posts"
                            post.bind="post"
                            has-role.bind="hasRole"
                            references.bind="references"
                            authenticated.bind="authenticated.user"
                            entity-id.bind="entityId"
                            entity-type.bind="entityType"
                            on-delete.bind="handleDelete"
                            workspace.bind="authenticated.workspace.id"
                            language.bind="language">
                        </template-post>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="px-8">
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="primary" disabled.bind="isLoading || deleting" click.trigger="ok()">
                            <span class="pointer-events-none" t="global.buttons.close"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
