import { bindable } from 'aurelia';
import Prism from 'prismjs';

export class UxHighlight {
    @bindable() public language: 'xml' = 'xml';
    @bindable() public content: string;

    public element: HTMLElement;

    public bound(): void {
        setTimeout(() => {
            Prism.highlightElement(this.element, false);
        });
    }
}
