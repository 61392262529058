import { CopyActionItem } from './copy-action-item';
import { CopyTherapyEvaluationItem } from './copy-evaluation-item';
import { CopyQuestionItem } from './copy-question-item';
import { CopyTemplateItem } from './copy-template-item';
import { CopyTherapyExecutionItem } from './copy-therapy-execution-item';
import { CopyTherapyPlanItem } from './copy-therapy-plan-item';

export interface IClipboard {
    actionItem: CopyActionItem;
    therapyExecutionItem: CopyTherapyExecutionItem;
    therapyPlanItem: CopyTherapyPlanItem;
    templateItem: CopyTemplateItem;
    questionItem: CopyQuestionItem;
    therapyEvaluationItem: CopyTherapyEvaluationItem;
}

export class Clipboard {
    public actionItem: CopyActionItem;
    public therapyExecutionItem: CopyTherapyExecutionItem;
    public therapyPlanItem: CopyTherapyPlanItem;
    public templateItem: CopyTemplateItem;
    public questionItem: CopyQuestionItem;
    public therapyEvaluationItem: CopyTherapyEvaluationItem;

    public constructor(data?: IClipboard) {
        this.actionItem = data?.actionItem;
        this.therapyExecutionItem = data?.therapyExecutionItem;
        this.therapyPlanItem = data?.therapyPlanItem;
        this.templateItem = data?.templateItem;
        this.questionItem = data?.questionItem;
        this.therapyEvaluationItem = data?.therapyEvaluationItem;
    }
}
