import { I18N } from '@aurelia/i18n';
import { EmailEntityTypes, EmailTemplatesApiClient, GetEmailTemplateResponse } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(EmailTemplatesApiClient, I18N, ErrorHandler)
export class BxEmailTemplateSelector {
    @bindable() public value: string;
    @bindable() public selected: GetEmailTemplateResponse;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public types: EmailEntityTypes[];
    @bindable() public onSelect: (template: GetEmailTemplateResponse, data: any) => void;

    public combobox: UxCombobox;
    public templates: GetEmailTemplateResponse[];

    public constructor(
        private readonly templatesApi: EmailTemplatesApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public async attached(): Promise<void> {
        try {
            let template: GetEmailTemplateResponse;
            if (isNotEmpty(this.value)) {
                template = await this.templatesApi.getById(this.value, this.workspace);
                // If the selected item is not found, use the selected object which can
                // be provided by the user and used to display the object name in the combobox.
                // This can be handy in case the item has been deleted.
                if (isNotDefined(template)) template = this.selected;
            }

            const response = await this.templatesApi.search(this.workspace, '', 100, 0, undefined, undefined, undefined, this.types);
            const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
            this.templates = isDefined(template) ? [template].concat(data.filter((x) => x.id !== this.value)) : data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxEmailTemplateSelector.attached', e);
        }
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.templatesApi.search(this.workspace, isNotEmpty(query) ? query : '', 100, 0, undefined, undefined, undefined, this.types);
            this.templates = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxEmailTemplateSelector.handleSearch', e);
        }
    }

    public handleSelect(e: CustomEvent<EventDetails<UxCombobox, UxComboboxOption>>): void {
        const option = e.detail.values;
        const template = this.templates.find((x) => x.id === option?.value);
        if (isFunction(this.onSelect)) this.onSelect(template, this.data);
    }
}
