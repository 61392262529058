import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import { GetAuthenticatedResponse } from '@wecore/sdk-core';
import {
    GetDifferentialDiagnosisResponse,
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalHistoryItem,
    MedicalHistoryItemTypes,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@containerless
export class WidgetKetenzorg {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public currentUser: GetAuthenticatedResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public diagnoses: GetDifferentialDiagnosisResponse[] = [];
    public deleted: GetDifferentialDiagnosisResponse[] = [];

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        this.diagnoses = this.registration?.widget?.result?.value || [];

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Array;

        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.Ketenzorg,
                onSave: async (): Promise<void> => {
                    for (const value of this.diagnoses) {
                        // Check if the patient already has this medical history item.
                        if (this.patient.medicalHistory.some((x) => x.name[this.language] === value.name[this.language])) continue;
                        this.patient.medicalHistory.push(
                            new MedicalHistoryItem({
                                id: guid(),
                                type: MedicalHistoryItemTypes.Disorder,
                                name: value.name
                            })
                        );
                    }

                    for (const value of this.deleted) {
                        const index = this.patient.medicalHistory.findIndex((x) => x.name[this.language] === value.name[this.language]);
                        if (index > -1) this.patient.medicalHistory.splice(index, 1);
                    }

                    this.registration.widget.result.value = this.diagnoses;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[], mutation: 'added' | 'deleted', item: GetDifferentialDiagnosisResponse): Promise<void> => {
        if (mutation === 'deleted') this.deleted = [...this.deleted, item];
        this.diagnoses = diagnoses;
    };
}
