import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { FileTypesApiClient, GetFileTypeResponse } from '@wecore/sdk-core';
import {
    AffixPositions,
    FileType,
    InputRules,
    InputTypes,
    MedicalExaminationActionItem,
    MedicalResult,
    MedicalTherapyExecutionItem,
    Operators,
    ResultMatcher,
    ResultMatcherTypes,
    ResultTypes,
    SliderSettings
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { ComponentChoicesSelector } from '../../components/component-choices-selector/component-choices-selector';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { cloneDeep, getFileTypeTranslation, validateTranslation } from '../../infra/utilities';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';
import { UxMultiSelector } from '../../ux/ux-multi-selector/ux-multi-selector';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, FileTypesApiClient)
export class PartialEditMedicalItem extends BasePartialView {
    public item: MedicalExaminationActionItem | MedicalTherapyExecutionItem;
    public baseLoaded: boolean = false;
    public expanded: boolean = true;
    public AffixPositions: typeof AffixPositions = AffixPositions;
    public ResultTypes: typeof ResultTypes = ResultTypes;
    public InputTypes: typeof InputTypes = InputTypes;
    public ResultMatcherTypes: typeof ResultMatcherTypes = ResultMatcherTypes;
    public Operators: typeof Operators = Operators;
    public inputTypesToShow: InputTypes[] = [InputTypes.FreeEntry, InputTypes.Selector];
    public inputTypeSelector: UxSelect;
    public fileTypeSelector: UxCombobox;
    public norm: UxSelect;
    public norms: UxMultiSelector;
    public defaultValue: UxSelect;
    public defaultValues: UxMultiSelector;
    public type: 'action' | 'therapy-plan' | 'therapy-execution';

    public fileTypes: GetFileTypeResponse[];
    public choicesSelector: ComponentChoicesSelector;
    public validation: any;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly fileTypesApi: FileTypesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.item = view.data.item;
        this.validation = view.data.validation;
        this.type = view.data.type ?? 'action';
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [fileTypes] = await Promise.all([
                    this.fileTypesApi.getAll(250) //
                ]);
                this.fileTypes = fileTypes.data;
                this.selectInputType(this.item.step.resultType, false);

                if (isDefined(this.item.step)) {
                    if (isNotDefined(this.item.step.norm)) this.item.step.norm = new MedicalResult();
                    if (isNotDefined(this.item.step.defaultValue)) this.item.step.defaultValue = new MedicalResult();
                    if (isNotDefined(this.item.step.resultMatcher))
                        this.item.step.resultMatcher = new ResultMatcher({
                            type: ResultMatcherTypes.SimpleComparison
                        });
                    if (isNotDefined(this.item.step.inputRules)) this.item.step.inputRules = new InputRules();
                    if (isNotDefined(this.item.step.slider))
                        this.item.step.slider = new SliderSettings({
                            minorIncrement: 0,
                            labels: []
                        });
                }

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialEditMedicalItem.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.item.step[property],
            callback: (updatedTranslations: any) => {
                this.item.step[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid)
            this.remove({
                result: PartialViewResults.Ok,
                data: {
                    item: this.item,
                    validation: this.validation
                }
            });
    }

    public async handleInputAmountChanged(): Promise<void> {
        // Reset the expected changes when input amount changes.
        this.item.step.norm.value = null;
        this.item.step.norms = [];
        this.item.step.defaultValue.value = null;
        this.item.step.defaultValues = [];

        // Reset validation
        this.validation.norm = true;
        this.validation.norms = true;
        this.validation.norm = true;
        this.validation.norms = true;
        this.validation.inputAmount = true;
        this.validation.inputAmountValid = true;
        this.validation.defaultValue = true;

        if (isDefined(this.norms)) {
            await this.norms.clear();
            this.norms.refresh(
                this.toSet(this.item.step.choices, 'Default') //
            );
        }
        if (isDefined(this.defaultValues)) {
            await this.defaultValues.clear();
            this.defaultValues.refresh(
                this.toSet(this.item.step.choices, 'Default') //
            );
        }
    }

    public handleChoicesChanged = async (): Promise<void> => {
        this.item.step.norm.value = null;
        this.item.step.norms = [];
        this.item.step.defaultValue.value = null;
        this.item.step.defaultValues = [];

        setTimeout(async () => {
            if (isDefined(this.norms)) {
                await this.norms.clear();
                this.norms.refresh(
                    this.toSet(this.item.step.choices, 'Default') //
                );
            }
            if (isDefined(this.defaultValues)) {
                await this.defaultValues.clear();
                this.defaultValues.refresh(
                    this.toSet(this.item.step.choices, 'Default') //
                );
            }
        }, 250);
    };

    public handleNormsSelected = (option: { value: string; text: string }) => {
        const value = this.item.step.choices.find((x) => x.value === option.value);
        this.item.step.norms.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleNormsRemoved = (_: { value: string; text: string }, index: number) => {
        this.item.step.norms.splice(index, 1);
    };

    public async handleNormSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const details = e.detail.values;
        if (isNotDefined(details)) this.item.step.norm.value = null;
        else
            this.item.step.norm = MedicalResult.fromJS(
                cloneDeep(this.item.step.choices.find((x) => x.value === details.value)) //
            );
    }

    public handleDefaultValueSelected = (option: { value: string; text: string }) => {
        const value = this.item.step.choices.find((x) => x.value === option.value);
        this.item.step.defaultValues.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleDefaultValueRemoved = (_: { value: string; text: string }, index: number) => {
        this.item.step.defaultValues.splice(index, 1);
    };

    public async handleDefaultChoiceSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const details = e.detail.values;
        if (isNotDefined(details)) this.item.step.defaultValue.value = null;
        else
            this.item.step.defaultValue = MedicalResult.fromJS(
                cloneDeep(this.item.step.choices.find((x) => x.value === details.value)) //
            );
    }

    public async handleResultTypeSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const type = e.detail.values.value as ResultTypes;

        this.item.step.choices = [];

        if (isDefined(this.norms)) {
            await this.norms.clear();
            this.norms.refresh(
                this.toSet(this.item.step.choices, 'Default') //
            );
        }
        if (isDefined(this.defaultValues)) {
            await this.defaultValues.clear();
            this.defaultValues.refresh(
                this.toSet(this.item.step.choices, 'Default') //
            );
        }

        // Reset certain certain when were switching the result type.
        this.inputTypesToShow = null;
        this.item.step.filesAmount = 0;
        this.item.step.allowedFileTypes = [];
        this.item.step.inputAmount = 1;
        this.item.step.norm.value = null;
        this.item.step.norms = [];
        this.item.step.defaultValue.value = null;
        this.item.step.defaultValues = [];
        this.item.step.affix = null;
        this.item.step.affixPosition = AffixPositions.Suffix;
        this.item.step.resultMatcher.type = ResultMatcherTypes.SimpleComparison;
        this.item.step.resultMatcher.maxRange = null;
        this.item.step.resultMatcher.minRange = null;
        this.item.step.inputRules = new InputRules();
        this.item.step.slider = new SliderSettings({
            minorIncrement: 0,
            labels: []
        });
        this.item.step.isMultipleChoice = false;

        this.selectInputType(type, true);

        setTimeout(async () => {
            if (isDefined(this.fileTypeSelector)) await this.fileTypeSelector.clear();
        }, 100);
    }

    public async handleInputTypeSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const type = e.detail.values.value;
        // Reset certain values when were switching the input type.
        this.item.step.filesAmount = 0;
        this.item.step.allowedFileTypes = [];
        this.item.step.choices = [];
        this.item.step.inputAmount = 1;
        this.item.step.norm.value = null;
        this.item.step.norms = [];
        this.item.step.defaultValue.value = null;
        this.item.step.defaultValues = [];
        this.item.step.affix = null;
        this.item.step.affixPosition = AffixPositions.Suffix;
        this.item.step.resultMatcher.type = ResultMatcherTypes.SimpleComparison;
        this.item.step.resultMatcher.maxRange = null;
        this.item.step.resultMatcher.minRange = null;
        this.item.step.inputRules = new InputRules();
        this.item.step.slider = new SliderSettings({
            minorIncrement: 0,
            labels: []
        });
        this.item.step.isMultipleChoice = false;

        if (type === InputTypes.Selector) {
            setTimeout(() => {
                if (isDefined(this.choicesSelector) && this.item.step.choices.empty()) this.choicesSelector.add();
            }, 150);
        } else this.item.step.choices = [];
    }

    public handleFileTypeSelected = (option: { value: string; text: string }): void => {
        const type = this.fileTypes.find((x) => x.id === option.value);
        this.item.step.allowedFileTypes.push(type);
    };

    public handleFileTypeRemoved = (option: { value: string; text: string }): void => {
        const index = this.item.step.allowedFileTypes.findIndex((x) => x.id === option.value);
        if (index > -1) this.item.step.allowedFileTypes.splice(index, 1);
    };

    public async handleResultMatcherSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        this.item.step.resultMatcher.minRange = null;
        this.item.step.resultMatcher.maxRange = null;
        this.item.step.norm.value = null;
        this.item.step.norms = [];
        this.item.step.defaultValue.value = null;
        this.item.step.defaultValues = [];
    }

    public async removeFileType(type: GetFileTypeResponse): Promise<void> {
        const index = this.item.step.allowedFileTypes.findIndex((x) => x.id === type.id);
        this.item.step.allowedFileTypes.splice(index, 1);

        // Force the 'used' binding to update.
        this.item.step.allowedFileTypes = [
            ...(this.item.step.allowedFileTypes.length > 0 ? [this.item.step.allowedFileTypes.shift()] : []), //
            ...this.item.step.allowedFileTypes
        ];
        // this.actionItem.step.allowedFileTypes = this.actionItem.step.allowedFileTypes.changed();
    }

    public toSet(choices: FileType[] | MedicalResult[], type: 'Default' | 'FileTypes'): { value: string; text: string; data?: any }[] {
        if (type === 'FileTypes') return choices.map((x: FileType) => ({ value: x.id, text: getFileTypeTranslation(x.name) }));
        return choices.map((x: MedicalResult) => ({ value: x.value, text: isDefined(x.value) ? x.value : this.t.tr('translation:global.messages.missing-value') }));
    }

    private selectInputType(type: ResultTypes, change: boolean): void {
        let inputType: InputTypes = InputTypes.FreeEntry;

        switch (type) {
            case ResultTypes.Text:
                this.inputTypesToShow = [InputTypes.FreeEntry, InputTypes.Selector];
                inputType = InputTypes.FreeEntry;
                break;
            case ResultTypes.Number:
                this.inputTypesToShow = [InputTypes.FreeEntry, InputTypes.Selector, InputTypes.RangeSlider];
                inputType = InputTypes.FreeEntry;
                break;
            case ResultTypes.File:
                this.inputTypesToShow = [InputTypes.Filepicker];
                inputType = InputTypes.Filepicker;
                break;
            case ResultTypes.Date:
                this.inputTypesToShow = [InputTypes.Datepicker];
                inputType = InputTypes.Datepicker;
                break;
        }

        if (change) this.item.step.inputType = inputType;
        if (isDefined(this.inputTypeSelector)) this.inputTypeSelector.setValue(inputType);
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.name = validateTranslation(this.item.step.name, this.language);
        this.validation.question = validateTranslation(this.item.step.question, this.language);
        this.validation.inputAmount = isNotEmpty(this.item.step.inputAmount) && isOfType(Number(this.item.step.inputAmount), 'number');
        this.validation.filesAmount = isNotEmpty(this.item.step.filesAmount) && isOfType(Number(this.item.step.filesAmount), 'number');

        if (this.item.step.resultMatcher.type === ResultMatcherTypes.BetweenRange) {
            this.validation.minRange = isNotEmpty(this.item.step.resultMatcher.minRange) && isOfType(Number(this.item.step.resultMatcher.minRange), 'number');
            this.validation.maxRange = isNotEmpty(this.item.step.resultMatcher.maxRange) && isOfType(Number(this.item.step.resultMatcher.maxRange), 'number');
        }

        if (this.item.step.inputType === InputTypes.Selector) {
            for (let i = 0; i < this.item.step.choices.length; i++) {
                // resetValidation(this.validation.choices[i]);
                const choice = this.item.step.choices[i];

                // Match the choice with the correct validation object by matching the id.
                // We do this because the user can change the order of the choices and so
                // we have to make sure the validation object matches the correct choice.
                const vIndex = this.validation.choices.findIndex((x: any) => x.id === choice.id);

                this.validation.choices[vIndex].value = isNotEmpty(choice.value);
                this.validation.choices[vIndex].unique = this.item.step.choices.filter((x) => x.value?.toLowerCase() === choice.value?.toLowerCase()).length === 1;
                this.validation.choices[vIndex].numeric =
                    this.item.step.resultType === ResultTypes.Number //
                        ? !isNaN(Number(choice.value))
                        : true;
                if (this.item.step.calculateScore) this.validation.choices[vIndex].score = isDefined(choice.score) && !isNaN(Number(choice.score));
            }

            this.validation.numericChoices = this.validation.choices.every((x) => x.numeric);

            // if (Number(this.action.inputAmount) === 1) this.validation.expectedAnswer = isNotEmpty(this.action.expectedAnswer);
            this.validation.inputAmountValid =
                // Only validate the input amount when more than 1 choices are given.
                Number(this.item.step.inputAmount) < 2 //
                    ? true
                    : Number(this.item.step.inputAmount) < this.item.step.choices.length;

            if (isDefined(this.item.step.norms) && this.item.step.norms.any()) {
                this.validation.norms =
                    isDefined(this.item.step.norms) &&
                    (Number(this.item.step.inputAmount) >= 2 //
                        ? this.item.step.norms.length === Number(this.item.step.inputAmount)
                        : true);
            }
        }

        if (this.item.step.inputType === InputTypes.RangeSlider) {
            this.validation.startValue = isNotEmpty(this.item.step.slider.startValue) && isOfType(Number(this.item.step.slider.startValue), 'number');
            this.validation.endValue = isNotEmpty(this.item.step.slider.endValue) && isOfType(Number(this.item.step.slider.endValue), 'number');
            this.validation.majorIncrement = isNotEmpty(this.item.step.slider.majorIncrement) && isOfType(Number(this.item.step.slider.majorIncrement), 'number');

            if (this.validation.majorIncrement) {
                this.validation.majorIncrementValid = Number(this.item.step.slider.majorIncrement) < Number(this.item.step.slider.endValue);
            }

            if (this.validation.startValue && this.validation.endValue) {
                this.validation.endValueValid = Number(this.item.step.slider.endValue) > Number(this.item.step.slider.startValue);

                if (isDefined(this.item.step.slider.initialValue)) {
                    this.validation.initialValue = isNotEmpty(this.item.step.slider.initialValue) && isOfType(Number(this.item.step.slider.initialValue), 'number');
                    this.validation.initialValueValid =
                        Number(this.item.step.slider.initialValue) >= Number(this.item.step.slider.startValue) && Number(this.item.step.slider.initialValue) <= Number(this.item.step.slider.endValue);
                }
            }

            if (isDefined(this.item.step.slider.majorIncrement)) {
                this.validation.minorIncrement = isNotEmpty(this.item.step.slider.minorIncrement) && isOfType(Number(this.item.step.slider.minorIncrement), 'number');

                if (this.validation.minorIncrement) {
                    this.validation.minorIncrementValid = Number(this.item.step.slider.minorIncrement) < Number(this.item.step.slider.majorIncrement);
                }

                for (let index = 0; index < this.item.step.slider.labels.length; index++) {
                    const label = this.item.step.slider.labels[index];

                    this.validation.labels[index].label = validateTranslation(label.value, this.language);
                    this.validation.labels[index].value = isDefined(label.sliderValues);

                    // if (this.validation.labels[index].value) {
                    //     this.validation.labels[index].unique = this.item.step.slider.labels.filter((x) => x.sliderValue === label.sliderValue).length === 1;
                    //     this.validation.labels[index].valueValid =
                    //         Number(label.sliderValue) >= Number(this.item.step.slider.startValue) && Number(label.sliderValue) <= Number(this.item.step.slider.endValue);
                    // }
                }
            }
        }

        this.validation.valid = validateState(this.validation) && this.validation.choices.every(validateState) && this.validation.labels.every(validateState);
        return this.validation.valid;
    }
}
