import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationTemplateItemStep
} from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../../infra/events';
import { generateColumns } from '../../../../../../infra/utilities';
import { FlattenedExaminationStep } from '../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../models/selected-file';
import { StepState } from '../../../../../../models/step-state';

@containerless
@inject(IEventAggregator)
export class TemplateActionCategory {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public category: MedicalExaminationActionItem;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public level: number;

    public columns: string = 'col-span-12';
    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public shouldBeDisplayed: boolean = false;
    private subscriptions: any[] = [];

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.copiedFrom) || [] //
        );

        if (isNotDefined(this.item.attributes)) this.item.attributes = {};
        if (isNotDefined(this.states[this.item.id])) {
            this.states[this.item.id] = new StepState({
                stepId: this.item.id,
                expanded: false,
                answered: false
            });
        }

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings()),
            this.events.subscribe(CustomEvents.ExaminationActionCategoryChangedExpanded, (data: { stepId: string; expand: boolean; container: string }) => {
                // Find all connected categories where the key matches the id of
                // this current step and where the values matches the id of the
                // the category that is clicked.
                const shouldChange =
                    // Only categories in the same container should be affected.
                    data.container === this.step.id &&
                    this.flow.connectedCategories //
                        .filter((x) => x.key === this.item.copiedFrom)
                        .some((x) => x.value === data.stepId);
                // If we have any settings, we know that the expanded and height state
                // of the  current category needs to change.
                if (shouldChange) this.states[this.item.id].expanded = data.expand;
            })
        ];

        // Evaluate the requirements on load of the action step.
        this.evaluateSettings();
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.states[this.item.id].expanded = !this.states[this.item.id].expanded;

        // Give the browser some time to render the new height
        // when expanding the div.
        this.events.publish(CustomEvents.ExaminationActionCategoryChangedExpanded, {
            stepId: this.item.copiedFrom, //
            expand: this.states[this.item.id].expanded,
            // Make sure we only affect categories in the same container.
            container: this.step.id
        });

        if (this.states[this.item.id].expanded) {
            // Lets collapse all other categories on the same level.
            for (const prop in this.states) {
                if (
                    // Ignore steps without a level value.
                    isNotDefined(this.states[prop].level) ||
                    // Don't collapse the current category.
                    this.states[prop].level.stepId === this.item.id ||
                    // Don't collapse categories in other containers.
                    this.states[prop].level.container !== this.step.id ||
                    // Only collapse categories that are on the same level.
                    this.states[prop].level.value != this.level
                )
                    continue;

                // Ignore categories that are connected to the current category.
                const connected = this.flow.connectedCategories.filter(
                    (x) =>
                        x.key === this.item.copiedFrom && //
                        x.value === this.states[prop].level.copiedFrom
                );
                if (connected.any()) continue;

                this.states[prop].expanded = false;
            }
        }
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.item.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;

        // TODO: Delete step values of the steps below this category (including any files)
        // when the category is hidden for example by a display rule.
    }
}
