<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div ref="scrollContainer" class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class.bind="hasScrolled ? 'border-b' : ''" class="sticky top-0 z-40 flex justify-between border-gray-200 bg-white pb-3 pt-8">
                        <p class="font-medium text-gray-400" t="translation:partial-views.medical-examination-models.create.title"></p>
                        <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                            <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path
                                        fill="currentColor"
                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                </svg>
                            </div>
                            <ux-dropdown-option click.trigger="informationSheet()">
                                <span class="text-sm" t="global.buttons.information-sheet"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </div>
                    <div class="flex flex-1 flex-col gap-y-3 pb-5">
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-input
                                action.bind="c => manageTranslationsFor('name', true)"
                                value.two-way="request.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.medical-examination-models.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-input>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="request.description[language]"
                                rows="8"
                                t="[placeholder]partial-views.medical-examination-models.placeholders.description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.healthcare-sector"></span>
                            </ux-label>
                            <ux-multi-selector values.two-way="request.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex justify-between">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-examination-models.labels.boxes"></span>
                                </ux-label>
                                <ux-button click.trigger="addBox()" padding.bind="false" size="xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </ux-button>
                            </div>
                            <ul class="mt-2 flex flex-col space-y-3">
                                <li repeat.for="box of request.boxes" class="group flex flex-col gap-y-3 rounded-lg bg-gray-50 p-5">
                                    <div class="flex flex-col">
                                        <div class="flex items-center space-x-1">
                                            <ux-label class="mb-1">
                                                <span t="global.labels.name"></span>
                                            </ux-label>
                                            <span class="flex h-3 items-center text-red-500">*</span>
                                        </div>
                                        <div class="flex w-full">
                                            <div class="flex flex-1 flex-col">
                                                <ux-input
                                                    rounded="left"
                                                    action.bind="c => manageTranslationsFor('box:name', true, $index)"
                                                    value.two-way="box.name[language]"
                                                    valid.bind="validation.boxes[$index].name"
                                                    t="[placeholder]partial-views.medical-examination-models.placeholders.box-name">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                                    </svg>
                                                </ux-input>
                                            </div>
                                            <button
                                                click.trigger="toggleExpanded($index)"
                                                class="${ validation.boxes[$index].name ? 'border-gray-300 text-gray-800' : 'border-red-500 text-red-500' } ${ validation.boxes[$index].name ? 'ring-gray-400' : 'ring-red-500' } relative -left-px flex h-[38px] items-center justify-center border bg-white px-2.5 ring-offset-1 focus:z-[30] focus:outline-none focus:ring-1">
                                                <svg
                                                    if.bind="box.attributes.expanded"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="h-[1.2rem] w-[1.2rem]">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
                                                </svg>
                                                <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-[1.2rem] w-[1.2rem]">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                                                </svg>
                                            </button>
                                            <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                                <div
                                                    slot="label"
                                                    class="${ validation.boxes[$index].name ? 'border-gray-300 text-gray-800' : 'border-red-500 text-red-500' } ${ validation.boxes[$index].name ? 'ring-gray-400' : 'ring-red-500' } relative -left-[2px] flex h-[38px] items-center justify-center rounded-r-lg border bg-white px-2.5 ring-offset-1 focus:z-[30] focus:outline-none focus:ring-1">
                                                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                    </svg>
                                                </div>
                                                <ux-dropdown-option click.trigger="removeBox($index)">
                                                    <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                </ux-dropdown-option>
                                            </ux-dropdown>
                                        </div>
                                    </div>
                                    <div if.bind="box.attributes.expanded" class="flex flex-col">
                                        <ux-label class="mb-1">
                                            <span t="global.labels.description"></span>
                                        </ux-label>
                                        <ux-textarea
                                            action.bind="c => manageTranslationsFor('box:description', false, $index)"
                                            value.two-way="box.description[language]"
                                            rows="8"
                                            t="[placeholder]partial-views.medical-examination-models.placeholders.box-description">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                            </svg>
                                        </ux-textarea>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-end gap-x-2 border-t border-gray-200 bg-white py-4">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
