import { EmailLogStatuses } from '@wecore/sdk-healthcare';

export class EmailStatusToStringValueConverter {
    public toView(type: EmailLogStatuses): string {
        switch (type) {
            case EmailLogStatuses.New:
                return 'translation:partial-views.patient-contact-moments.labels.new';
            case EmailLogStatuses.Failed:
                return 'translation:partial-views.patient-contact-moments.labels.failed';
            case EmailLogStatuses.Sent:
                return 'translation:partial-views.patient-contact-moments.labels.sent';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
