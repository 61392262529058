<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-col overflow-y-auto px-6 pb-6">
            <div class="flex w-full flex-1 items-center justify-center">
                <div class="flex flex-col items-center gap-1">
                    <p>Zorgdomein</p>
                    <ux-badge type="warning">coming soon</ux-badge>
                </div>
            </div>
        </div>
    </section>
</template>
