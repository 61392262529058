<ux-multi-selector
    component.ref="selector"
    filter-used.bind="false"
    mode="input"
    debounce="200"
    placeholder.bind="placeholder"
    component.ref="selector"
    search-placeholder.bind="searchPlaceholder"
    on-search.bind="handleSearch"
    language.bind="language"
    autocomplete.bind="false"
    disabled.bind="disabled"
    valid.bind="valid"
    options.two-way="options"
    values.two-way="values">
</ux-multi-selector>
