import { isDefined } from '@wecore/sdk-utilities';

import { bindable, inject } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(Element)
export class UxToggle {
    @bindable() public checked: any[] | boolean | 'true';
    @bindable() public model: any;
    @bindable() public color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' = 'primary';
    @bindable() public data: any;
    @bindable() public class: string = 'flex';
    @bindable() public size: 'sm' | 'md' | 'lg' | 'xl' = 'sm';
    @bindable() public labelActive: string;
    @bindable() public disabled: boolean = false;
    @bindable() public labelInactive: string;

    public isChecked: boolean;

    public constructor(
        public host: HTMLElement //
    ) {}

    public bound() {
        this.isChecked = this.checkIfChecked();
    }

    public checkedChanged(newValue: any): void {
        if (Array.isArray(this.checked))
            this.isChecked = this.checked.some((a: any) => {
                if (isDefined(this.model.id) && isDefined(a.id)) return this.model.id === a.id;
                return a === this.model;
            });
        else {
            this.isChecked = newValue === true || newValue === 'true';
            this.checked = newValue;
        }
    }

    public handleClick(e: MouseEvent): void {
        if (this.disabled) return;
        this.isChecked = !this.isChecked;

        if (Array.isArray(this.checked)) {
            const index = this.checked.findIndex((a) => {
                if (isDefined(this.model.id) && isDefined(a.id)) return this.model.id === a.id;
                return a === this.model;
            });

            if (this.isChecked && index === -1) this.checked.push(this.model);
            else if (!this.isChecked && index !== -1) this.checked.splice(index, 1);
        } else this.checked = this.isChecked;

        this.emit(
            UxEvents.OnChange,
            new EventDetails<UxToggle, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    model: this.model,
                    checked: this.isChecked
                }
            })
        );
    }

    private checkIfChecked(): boolean {
        if (Array.isArray(this.checked))
            return this.checked.some((a: any) => {
                if (isDefined(this.model.id) && isDefined(a.id)) return this.model.id === a.id;
                return a === this.model;
            });

        return this.checked === true || this.checked === 'true';
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }
}
