import { MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { isDefined } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';

@containerless
@inject(MediaLibraryApiClient)
export class ComponentDeltaContentViewer {
    @bindable() public content: any;
    @bindable() public workspace: string;

    public baseLoaded: boolean = false;

    public constructor(
        private readonly mediaLibrary: MediaLibraryApiClient //
    ) {}

    public async attached(): Promise<void> {
        const checks = this.content.ops
            .filter((item: any) => isDefined(item.insert.imageAttachment) || (isDefined(item.insert.videoAttachment) && isDefined(item.insert.videoAttachment.attachment)))
            .map((item: any) => {
                return new Promise<void>(async (resolve) => {
                    const container = item.insert.imageAttachment || item.insert.videoAttachment;
                    // Check if URL still has correct authorization.
                    var request = new XMLHttpRequest();
                    request.open('GET', container.src, true);
                    request.send();
                    request.onload = async () => {
                        if (request.status !== 200) {
                            const url = await this.mediaLibrary.getUrl(container.attachment, this.workspace, container.thumbnail || true);
                            container.src = url;
                        }
                        resolve();
                    };
                });
            });

        // .filter((item: any) => isDefined(item.insert.imageAttachment) || (isDefined(item.insert.videoAttachment) && isDefined(item.insert.videoAttachment.attachment)))
        // .map((item: any) => {
        //     return new Promise<void>(async (resolve) => {
        //         const container = item.insert.imageAttachment || item.insert.videoAttachment;
        //         // Check if URL still has correct authorization.
        //         var request = new XMLHttpRequest();
        //         request.open('GET', container.src, true);
        //         request.send();
        //         request.onload = async () => {
        //             if (request.status !== 200) {
        //                 const url = await this.mediaLibrary.getUrl(container.attachment, this.authenticated.workspace.id, container.thumbnail || true);
        //                 container.src = url;
        //             }
        //             resolve();
        //         };
        //     });
        // });

        await Promise.all(checks);
        this.baseLoaded = true;
    }
}
