<div class="flex justify-between">
    <ux-label>
        <span t="translation:components.widgets.patient-processing-data.labels.agree-to-medical-reporting"></span>
    </ux-label>
    <ux-toggle
        checked.two-way="registration.widget.result.value"
        label-active.bind="'translation:global.labels.yes' | t"
        label-inactive.bind="'translation:global.labels.no' | t"
        size="md"
        color="success">
    </ux-toggle>
</div>
