import { Clipboard } from '../../models/clipboard';
import { SchedulerSettings } from '../../models/scheduler-settings';
import { ViewEngine } from '../../models/view-engine';

export interface State {
    viewEngine: { [key: string]: ViewEngine };
    language: string;
    clipboard: Clipboard;
    route: string;
    schedulerSettings: SchedulerSettings;
}

export const initialState: State = {
    viewEngine: {},
    language: null,
    clipboard: new Clipboard(),
    route: null,
    schedulerSettings: new SchedulerSettings({
        startHour: 8,
        startMinute: 0,
        endHour: 20,
        endMinute: 0,
        slotSize: 15,
        days: [0, 1, 2, 3, 4, 5, 6],
        minColumnWidth: 210
    })
};
