import { MedicalExaminationTemplateItemStepTypes } from '@wecore/sdk-healthcare';

export class TemplateTypeToStringValueConverter {
    public toView(type: MedicalExaminationTemplateItemStepTypes): string {
        switch (type) {
            case MedicalExaminationTemplateItemStepTypes.Action:
                return 'translation:partial-views.medical-examinations.labels.action';
            case MedicalExaminationTemplateItemStepTypes.Question:
                return 'translation:partial-views.medical-examinations.labels.question';
            case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                return 'translation:partial-views.medical-examinations.labels.questionnaire';
            case MedicalExaminationTemplateItemStepTypes.Widget:
                return 'translation:global.labels.widget';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
