import { bindable, containerless } from 'aurelia';
import { getSchedulerPeriodHeight } from '../../infra/utilities';
import { SchedulerPeriod } from '../../models/scheduler-period';
import { SchedulerSettings } from '../../models/scheduler-settings';

@containerless
export class BxSchedulerTimelineItem {
    @bindable() public settings: SchedulerSettings;
    @bindable() public period: SchedulerPeriod;

    public pointers: { top: number; label: string }[] = [];
    public container: HTMLDivElement;
    public height: number;

    public bound(): void {
        this.height = getSchedulerPeriodHeight(this.settings, this.period, this.settings.periodHeight);
        this.container.style.height = `${this.height}px`;
        if (this.period.hour === this.settings.startHour && this.settings.startMinute > 0) {
            let second = false;
            if (this.settings.startMinute >= 15 && this.settings.startMinute < 30) {
                second = true;
                this.pointers.push({ top: 33.33333, label: '30' });
            }
            if (this.settings.startMinute >= 15 && this.settings.startMinute < 45) {
                this.pointers.push({ top: second ? 66.66666 : 50, label: '45' });
            }
        } else if (this.period.hour === this.settings.endHour && this.settings.endMinute > 0) {
            let first = false;
            if (this.settings.endMinute > 15 && this.settings.endMinute > 30) {
                first = true;
                this.pointers.push({ top: 66.66666, label: '30' });
            }
            if (this.settings.endMinute > 15 && this.settings.endMinute <= 45) {
                this.pointers.push({ top: first ? 33.33333 : 50, label: '15' });
            }
        } else {
            this.pointers.push({ top: 25, label: '15' });
            this.pointers.push({ top: 50, label: '30' });
            this.pointers.push({ top: 75, label: '45' });
        }
    }
}
