import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient, BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, serveBlob } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { format } from 'date-fns';
import intervalToDuration from 'date-fns/esm/fp/intervalToDuration';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { SelectedFile } from '../../../../../models/selected-file';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { ModalService } from '../../../../../services/service.modals';

@containerless
@inject(I18N, AttachmentsApiClient, ModalService)
export class WidgetVoiceMemos {
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public xScrollContainer: string;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile, attachment: BlobStorageAttachment) => void;

    public available: boolean = true;
    public isRecording: boolean = false;
    public isPauzed: boolean = false;
    public player: HTMLAudioElement;
    public microphones: MediaDeviceInfo[];
    public error: string;
    public uploads: SelectedFile[] = [];
    public attachments: BlobStorageAttachment[] = [];
    public permission: 'granted' | 'denied' | 'prompt';
    public urls: any = {};
    public mic: HTMLDivElement;
    public duration: string = '0:00';

    private plugin: WaveSurfer;
    private recorder: RecordPlugin;
    private timer: NodeJS.Timeout;

    public constructor(
        private readonly t: I18N, //
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly modalService: ModalService
    ) {}

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;
        if (isNotDefined(this.registration.widget.result.value))
            this.registration.widget.result.value = {
                attachmentIds: []
            };

        const attachments =
            this.registration.widget.result.value.attachmentIds //
                .map((id: string) => this.record.attachments.find((y) => y.id === id))
                .filter((x: BlobStorageAttachment) => isDefined(x)) || [];
        Promise.all(
            attachments.map(async (x: BlobStorageAttachment) => {
                const id = isDefined(x.thumbnail) ? x.thumbnail.id : x.id;
                const url = await this.attachmentsApi.getUrl(this.record.id, id, this.workspace, AttachmentEntities.MedicalRecords);
                this.urls[x.id] = url;
            })
        ).then(() => (this.attachments = attachments));

        this.registration.widget.result.type = WidgetResultTypes.File;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.VoiceMemos,
                onSave: async (): Promise<void> => {},
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (attachment: BlobStorageAttachment): Promise<void> => {
                    this.registration.widget.result.value.attachmentIds.push(attachment.id);
                }
            })
        );

        const permissions = await navigator.permissions.query({ name: 'microphone' as PermissionName });
        this.permission = permissions.state;

        this.available = this.checkAvailability();
        if (this.available && this.permission === 'granted') {
            const devices = await navigator.mediaDevices.enumerateDevices();
            this.microphones = devices.filter((device) => device.kind === 'audioinput');
        }

        setTimeout(() => {
            this.plugin = WaveSurfer.create({
                container: this.mic,
                waveColor: '#324AD8',
                height: 60,
                barWidth: 2,
                barGap: 1,
                barHeight: 4,
                barRadius: 2
            });
        });
    }

    public async start(): Promise<void> {
        try {
            // Initialize the Record plugin
            this.recorder = this.plugin.registerPlugin(RecordPlugin.create());

            this.recorder.on('record-start', () => {
                // Start a timer to record how long the user is recording
                const start = new Date();
                this.timer = setInterval(() => {
                    const time = Math.round((new Date().getTime() - start.getTime()) / 1000);
                    this.duration = this.formatTimestamp(time);
                }, 1000);
            });

            this.recorder.on('record-end', (blob: Blob) => {
                if (this.isRecording && this.isPauzed) return;

                clearInterval(this.timer);
                const name = `audio-opname-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}`;
                const upload = new SelectedFile({
                    id: guid(),
                    step: this.step.id,
                    type: 'widget',
                    file: blob,
                    progress: 0,
                    loader: null,
                    statusLabel: this.t
                        .tr('translation:partial-views.clinical-pathways.labels.status-waiting') //
                        .replace('{entity}', `<span class="font-medium block mx-1">${name}</span>`),
                    extension: `.wav`,
                    name: `${name}.wav`,
                    registration: this.registration.id,
                    isLoading: false
                });
                this.uploads.push(upload);
                this.onFileSelected(upload);
            });

            await this.recorder.startMic();
            await this.recorder.startRecording();
            this.isRecording = true;
        } catch (e) {
            if (e.message === 'Permission denied' || e.message === 'Permission dismissed') {
                this.error = e.message;
                return;
            }
        }
    }

    public async togglePauze(): Promise<void> {
        if (this.isPauzed) {
            this.isPauzed = false;
            this.recorder.startRecording();
        } else {
            this.recorder.stopRecording();
            this.isPauzed = true;
        }
    }

    public async stop(): Promise<void> {
        this.recorder.stopRecording();
        this.recorder.stopMic();
        this.isPauzed = false;
        this.isRecording = false;
        this.recorder = null;
    }

    public async openMenu(event: MouseEvent, index: number, type: 'attachment' | 'upload'): Promise<void> {
        await this[`${type}Menus`][index].show(event);
    }

    public async open(attachment: BlobStorageAttachment): Promise<void> {
        const url = await this.attachmentsApi.getUrl(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
        window.open(url, '_blank');
    }

    public async download(attachment: BlobStorageAttachment): Promise<void> {
        if (isDefined(attachment)) {
            const blob = await this.attachmentsApi.download(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
        }
    }

    public deleteFromUploads = (upload: SelectedFile): void => {
        const index = this.uploads.findIndex((x) => x.id === upload.id);
        this.uploads.splice(index, 1);

        this.onFileRemoved(upload, null);
    };

    public deleteAttachment = async (attachment: BlobStorageAttachment): Promise<void> => {
        const index = this.attachments.findIndex((x) => x.id === attachment.id);
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const attachment = this.attachments[index];
                        const i = this.registration.widget.result.value.attachmentIds.findIndex((id: string) => id === attachment.id);
                        this.registration.widget.result.value.attachmentIds.splice(i, 1);

                        this.attachments.splice(index, 1);

                        this.onFileRemoved(null, attachment);
                    }
                }
            })
        );
    };

    private checkAvailability(): boolean {
        return isDefined(navigator.mediaDevices) && isDefined(navigator.mediaDevices.getUserMedia);
    }

    private formatTimestamp(time: number): string {
        // Format the current time to mm:ss. In case of hours, format to HH:mm:ss
        const duration = intervalToDuration({
            start: 0,
            end: time * 1000
        });
        const formatString = duration.hours > 0 ? 'H:mm:ss' : 'm:ss';
        return format(new Date(0, 0, 0, duration.hours, duration.minutes, duration.seconds, 0), formatString);
    }
}
