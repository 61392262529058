<ux-combobox
    init="false"
    view-model="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear && !disabled"
    disabled.bind="disabled"
    data.bind="data"
    onselect.trigger="handleSelect($event)"
    onclear.trigger="handleClear($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="patient of patients" value.bind="patient.id">${ patient.displayName }</ux-combobox-option>
</ux-combobox>
