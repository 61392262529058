export class Stopwatch {
    private startTime: number;
    private running: boolean;
    private elapsed: number;

    constructor() {
        this.startTime = 0;
        this.running = false;
        this.elapsed = 0;
    }

    start(): void {
        if (!this.running) {
            this.startTime = performance.now();
            this.running = true;
        }
    }

    stop(): void {
        if (this.running) {
            this.elapsed += performance.now() - this.startTime;
            this.running = false;
        }
    }

    reset(): void {
        this.elapsed = 0;
    }

    getTime(): number {
        if (this.running) {
            return this.elapsed + (performance.now() - this.startTime);
        } else {
            return this.elapsed;
        }
    }
}
