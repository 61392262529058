import { AppTypes, GetAuthenticatedResponse, GetMyTeamResponseItem, GetRedactedWorkspaceResponse, UserRoles } from '@wecore/sdk-core';
import { GetWorkspaceResponse } from '@wecore/sdk-management';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable } from 'aurelia';
import { NotificationService } from '../services/service.notifications';
import { CacheService } from './cache-service';
import { ErrorHandler } from './error-handler';

export class BaseComponent {
    protected UserRoles: typeof UserRoles = UserRoles;
    protected AppTypes: typeof AppTypes = AppTypes;
    protected authenticated: {
        user: GetAuthenticatedResponse;
        workspace: GetWorkspaceResponse | GetRedactedWorkspaceResponse;
        teams: GetMyTeamResponseItem[];
    } = {} as any;
    protected workspaces: GetWorkspaceResponse[] | GetRedactedWorkspaceResponse[];
    protected baseLoaded: boolean = false;
    protected notifications: NotificationService;
    protected amountOfMessages: number = 0;
    protected subscriptions: IDisposable[] = [];

    public constructor(
        protected cache: CacheService, //
        protected errorHandler: ErrorHandler
    ) {}

    protected async init(): Promise<void> {
        this.notifications = new NotificationService();

        const user = await this.cache.getAuthenticatedUser();
        this.authenticated.user = user;

        const workspaces = await this.cache.getAllWorkspaces();
        this.workspaces = workspaces.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (b.name > a.name) return 1;
            return 0;
        });

        if (workspaces.any()) {
            const workspace = await this.cache.getAuthenticatedWorkspace();
            this.authenticated.workspace = workspace;

            const teams = await this.cache.getAuthenticateTeams();
            this.authenticated.teams = teams;
        } else this.authenticated.teams = [];
    }

    protected hasRole = (role: UserRoles): boolean => {
        if (isNotDefined(this.authenticated.user) && isNotDefined(this.authenticated.teams)) return false;
        // Get the user configuration for the current user.
        const configuration = this.authenticated.workspace.userConfiguration.find((x) => x.user.id === this.authenticated.user.id);
        // Always allow owner and admin.
        if (
            configuration.roles.some((r) => r === UserRoles.Owner || r === UserRoles.Admin) ||
            this.authenticated.teams.some((x) => x.roles.some((y) => y === UserRoles.Owner || y === UserRoles.Admin))
        )
            return true;
        // Check if the user has the role or if the user is part of a team that has the role.
        return (
            configuration.roles.some((x) => x === role) || //
            this.authenticated.teams.some((x) => x.roles.some((y) => y === role))
        );
    };

    protected integrationEnabled = (name: string): boolean => {
        const integration = this.authenticated.workspace.integrations[name];
        if (isNotDefined(integration)) return false;
        return integration.enabled;
    };

    protected async onUserUpdated(): Promise<void> {
        this.authenticated.user = undefined;
        this.authenticated.user = await this.cache.getAuthenticatedUser();
    }

    protected async refreshAuthenticated(): Promise<void> {
        this.authenticated.user = await this.cache.getAuthenticatedUser(true);
        this.authenticated.workspace = await this.cache.getAuthenticatedWorkspace(true);
        this.workspaces = await this.cache.getAllWorkspaces(true);
    }
}
