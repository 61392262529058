<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section pt-[5px].class="!isLoading" if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-bar-loader if.bind="isLoading"></ux-bar-loader>
        <div class="flex h-full w-full flex-col overflow-y-auto p-5">
            <div class="sticky top-0 border-b border-gray-200 bg-white pb-2">
                <p class="text-sm font-medium" t="translation:partial-views.healthcare-invoices.labels.select-options"></p>
            </div>
            <ul class="mt-3 flex flex-col gap-2">
                <li repeat.for="choice of choices">
                    <button click.trigger="select(choice)" class="w-full flex-1 rounded-lg bg-gray-50 px-5 py-2 text-left hover:bg-gray-100 focus:outline-none active:bg-gray-200">
                        <div class="flex flex-col">
                            <p class="text-sm">${choice.label}</p>
                            <p class="text-xs text-primary">${choice.groupId}</p>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
        <ux-footer>
            <div class="flex flex-1 items-center justify-end gap-x-2">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
