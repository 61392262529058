<import from="converters/translate" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="flex flex-col flex-1 pt-8 pb-5 gap-y-3">
                        <div class="flex justify-between">
                            <p if.bind="request.type === HealthcareTaskLabelTypes.Priority" class="font-medium text-gray-400" t="translation:partial-views.tasks.priorities.create.title"></p>
                            <p if.bind="request.type === HealthcareTaskLabelTypes.Status" class="font-medium text-gray-400" t="translation:partial-views.tasks.statuses.create.title"></p>
                            <p if.bind="request.type === HealthcareTaskLabelTypes.Label" class="font-medium text-gray-400" t="translation:partial-views.tasks.labels.create.title"></p>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="request.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]translation:partial-views.tasks.labels.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="request.description[language]"
                                rows="8"
                                t="[placeholder]translation:partial-views.tasks.placeholders.description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.display-order"></span>
                            </ux-label>
                            <ux-input
                                select-text-on-focus="true"
                                type="number"
                                value.two-way="request.displayOrder"
                                valid.bind="validation.displayOrder"
                                t="[placeholder]translation:global.placeholders.enter-numeric-value">
                            </ux-input>
                        </div>
                        <div class="flex items-center justify-center h-8 rounded-lg" css="background-color: ${request.backgroundColor}; color: ${request.textColor}">
                            <p class="text-sm">${request.name[language]}</p>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.tasks.labels.background-color"></span>
                            </ux-label>
                            <ux-color-picker show-palette.bind="false" value.two-way="request.backgroundColor"></ux-color-picker>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.tasks.labels.text-color"></span>
                            </ux-label>
                            <ux-color-picker show-palette.bind="false" value.two-way="request.textColor"></ux-color-picker>
                        </div>
                        <div if.bind="request.type === HealthcareTaskLabelTypes.Status" class="flex flex-col gap-1">
                            <p class="text-xs text-gray-500" t="translation:partial-views.tasks.messages.can-close-task"></p>
                            <div class="flex items-center justify-between">
                                <ux-label>
                                    <span t="translation:partial-views.tasks.labels.can-close-tasks"></span>
                                </ux-label>
                                <ux-toggle
                                    checked.two-way="request.shouldCloseTask"
                                    label-active.bind="'translation:global.labels.yes' | t"
                                    label-inactive.bind="'translation:global.labels.no' | t"
                                    size="md"
                                    color="primary">
                                </ux-toggle>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <ux-footer>
                <div class="flex justify-between flex-1 gap-x-2">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading || deleting"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
