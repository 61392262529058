import { containerless } from 'aurelia';
// import * as d3 from 'd3';

@containerless
export class KpiPercentagePerStep {
    public svg: SVGElement;

    private data = [{ name: 'Step 1', step1: 15, step2: 50, step3: 20 }];

    public attached(): void {
        const height = 300;
        const width = 300;

        // const svg = d3
        //     .select(this.svg) //
        //     .attr('width', width)
        //     .attr('height', height);

        // const xScale = d3.scaleLinear().domain([0, width]).range([0, width]);

        // const colors = ['#F4C237', '#F25366', '#324AD8', '#46ADF9'];

        // svg.selectAll('rect') //
        //     .data(this.data)
        //     .enter()
        //     .append('rect')
        //     .attr('y', 0)
        //     .attr('x', 0)
        //     .attr('width', (d) => d.percentage)
        //     .attr('height', 50)
        //     .attr('fill', (_, i) => colors[i])
        //     .attr('transform', (d) => {
        //         const translate = [d.percentage, 0];
        //         return 'translate(' + translate + ')';
        //     });
    }
}
