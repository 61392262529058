<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/medical-history-type-to-string" />
<import from="converters/month-to-string" />
<import from="converters/sort-medical-history" />
<import from="converters/side-to-string" />
<import from="components/component-duplicator/component-duplicator" />

<div class="flex flex-col">
    <ux-label>
        <span t="translation:components.medical-history-selector.labels.medical-history"></span>
    </ux-label>
    <div class="mt-2">
        <ux-combobox
            set-value-on-select.bind="false"
            t="[placeholder]translation:components.medical-history-selector.placeholders.type-or-select-history-item"
            autocomplete.bind="false"
            show-footer.bind="true"
            allow-custom-input.bind="true"
            onselect.trigger="handleSelected($event)"
            onsearch.trigger="handleSearch($event)">
            <ux-combobox-option repeat.for="option of options" value.bind="option[language]" data.bind="option">${option | translate:language}</ux-combobox-option>
            <div au-slot="footer" class="flex items-center w-full px-3 py-2 gap-x-1">
                <svg class="w-5 h-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                <p if.bind="focused !== -1 && options.length > 0" class="text-xs text-gray-500" t="translation:global.messages.press-enter-to-add-option"></p>
                <p else class="text-xs text-gray-500" t="translation:global.messages.press-enter-to-add-text"></p>
            </div>
        </ux-combobox>
    </div>
    <template repeat.for="type of MedicalHistoryItemTypes | keys">
        <div class="mt-2">
            <p class="mb-1 text-sm text-gray-600">${ type | medicalHistoryTypeToString | t}</p>
            <ul class="mt-1 space-y-1">
                <li if.bind="getLength(registrations, type) === 0" class="p-3 bg-gray-100 rounded-lg">
                    <p class="text-sm" t="translation:components.medical-history-selector.messages.no-medical-history"></p>
                </li>
                <li repeat.for="item of registrations | sortMedicalHistory:MedicalHistoryItemTypes[type]:language" class="grid min-w-0 grid-cols-12 gap-1">
                    <div class="flex col-span-4 space-x-1">
                        <button
                            if.bind="$parent.$index == 0 || $parent.$index === 1"
                            class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-blue-600"
                            click.trigger="move('down', item.id, $parent.$index)">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                            </svg>
                        </button>
                        <button
                            if.bind="$parent.$index > 0"
                            class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-blue-500"
                            click.trigger="move('up', item.id, $parent.$index)">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
                            </svg>
                        </button>
                        <div class="flex h-[38px] min-w-0 flex-1 items-center rounded-lg border border-gray-300 bg-gray-50 px-3 text-sm">
                            <p class="truncate">${item.name | translate:language}</p>
                        </div>
                    </div>
                    <div class="col-span-3">
                        <ux-select allow-clear.bind="true" value.two-way="item.side" t="[placeholder]translation:components.medical-history-selector.placeholders.select-side">
                            <ux-select-option repeat.for="side of BodySides | keys" value.bind="side"> ${ side | sideToString | t } </ux-select-option>
                        </ux-select>
                    </div>
                    <div class="col-span-3">
                        <ux-select
                            onselect.trigger="handleMonthSelected($event)"
                            data.bind="item.id"
                            value.two-way="item.month"
                            t="[placeholder]translation:components.medical-history-selector.placeholders.select-month">
                            <ux-select-option value="1">${1 | monthToString | t}</ux-select-option>
                            <ux-select-option value="2">${2 | monthToString | t}</ux-select-option>
                            <ux-select-option value="3">${3 | monthToString | t}</ux-select-option>
                            <ux-select-option value="4">${4 | monthToString | t}</ux-select-option>
                            <ux-select-option value="5">${5 | monthToString | t}</ux-select-option>
                            <ux-select-option value="6">${6 | monthToString | t}</ux-select-option>
                            <ux-select-option value="7">${7 | monthToString | t}</ux-select-option>
                            <ux-select-option value="8">${8 | monthToString | t}</ux-select-option>
                            <ux-select-option value="9">${9 | monthToString | t}</ux-select-option>
                            <ux-select-option value="10">${10 | monthToString | t}</ux-select-option>
                            <ux-select-option value="11">${11 | monthToString | t}</ux-select-option>
                            <ux-select-option value="12">${12 | monthToString | t}</ux-select-option>
                        </ux-select>
                    </div>
                    <div class="flex items-center col-span-2 space-x-1">
                        <ux-input
                            oninput.trigger="handleYearChanged($event)"
                            value.two-way="item.year"
                            placeholder-mask="y"
                            mask="0000"
                            select-text-on-focus.bind="true"
                            t="[placeholder]translation:global.labels.year">
                        </ux-input>
                        <button
                            class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500 ring-gray-300 ring-offset-1 hover:bg-gray-50 focus:ring-1 active:bg-gray-100"
                            click.trigger="deleteItem(item.id)">
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </template>
</div>
