<import from="./template-recording/template-recording" />

<ux-card>
    <div pt-2.class="isRecording" pt-5.class="!isRecording" class="flex flex-col gap-y-2 rounded-lg border border-gray-200 pb-5">
        <div show.bind="isRecording" ref="mic" class="h-16"></div>
        <div if.bind="permission === 'granted' || permission === 'prompt'" class="flex flex-col items-center">
            <button
                if.bind="isRecording"
                click.trigger="stop()"
                class="flex h-12 w-12 items-center justify-center rounded-full border border-gray-200 text-red-500 hover:cursor-pointer hover:bg-red-200 active:bg-red-300">
                <svg class="pointer-events-none h-6 w-6" fill="none" viewBox="0 0 24 24">
                    <rect width="12.5" height="12.5" x="5.75" y="5.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" rx="1" />
                </svg>
            </button>
            <button
                if.bind="!isRecording"
                click.trigger="start()"
                class="flex h-12 w-12 items-center justify-center rounded-full border border-gray-200 text-green-500 hover:cursor-pointer hover:bg-green-100 active:bg-green-300">
                <svg class="pointer-events-none h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
                </svg>
            </button>
            <p class="mt-2 text-xs text-gray-400" t="translation:components.widgets.voice-memos.messages.info"></p>
            <div if.bind="isRecording" class="mt-4 min-w-[50px] whitespace-nowrap rounded-full bg-gray-100 px-3 py-0.5 text-center text-xs">${duration}</div>
            <!-- <button
            if.bind="isRecording "
            click.trigger="togglePauze()"
            class="flex items-center justify-center w-12 h-12 text-yellow-500 border border-gray-200 rounded-full hover:cursor-pointer hover:bg-yellow-200 active:bg-yellow-300">
            <svg if.bind="isPauzed" class="w-6 h-6 pointer-events-none" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
            </svg>
            <svg else class="w-6 h-6 pointer-events-none" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path
                    fill="currentColor"
                    d="M48 96c-8.8 0-16 7.2-16 16V400c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H48zM0 112C0 85.5 21.5 64 48 64H96c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112zM224 96c-8.8 0-16 7.2-16 16V400c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H224zm-48 16c0-26.5 21.5-48 48-48h48c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H224c-26.5 0-48-21.5-48-48V112z" />
            </svg>
        </button> -->
        </div>
        <div else class="flex flex-col items-center">
            <div class="flex h-12 w-12 items-center justify-center rounded-full border border-red-500 text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                </svg>
            </div>
            <p class="mt-1 px-5 text-center text-xs text-red-500" t="translation:components.widgets.voice-memos.messages.missing-permission"></p>
        </div>
    </div>
</ux-card>

<div if.bind="uploads.length > 0 || attachments.length > 0" class="mt-1 flex flex-col gap-1">
    <template-recording
        repeat.for="upload of uploads"
        id.bind="upload.id"
        record.bind="record"
        workspace.bind="workspace"
        upload.bind="upload"
        on-upload-delete.bind="deleteFromUploads"></template-recording>
    <template-recording
        repeat.for="attachment of attachments"
        id.bind="attachment.id"
        record.bind="record"
        workspace.bind="workspace"
        attachment.bind="attachment"
        url.bind="urls[attachment.id]"
        on-attachment-delete.bind="deleteAttachment">
    </template-recording>
</div>
