import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { HealthcareCode, HealthcareCodeTypes } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { cleanTranslatables, setTranslation } from '../../infra/utilities';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialHealthcareCodes extends BasePartialView {
    public HealthcareCodeTypes: typeof HealthcareCodeTypes = HealthcareCodeTypes;
    public code: HealthcareCode;
    public validation: any = {
        system: true,
        value: true,
        type: true,
        list: true
    };

    private index: number;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.language = view.data.language;
        this.index = view.data.index;
        this.code = view.data.code || new HealthcareCode();

        if (isNotDefined(this.code.description)) this.code.description = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialHealthcareCodes.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.code[property],
            callback: (updatedTranslations: any) => {
                this.code[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public save(): void {
        const valid = this.validate();
        if (valid) {
            try {
                cleanTranslatables(['description'], this.code, 1);
                this.remove({
                    result: PartialViewResults.Ok,
                    data: {
                        code: this.code,
                        index: this.index
                    }
                });
            } catch (e) {
                if (isNotDefined(this.code.description)) this.code.description = setTranslation({}, this.language);
                this.errorHandler.handle('[healthcare-codes]', e);
            }
        }
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Canceled
        });
    }

    private validate(): boolean {
        this.validation.system = isDefined(this.code.system) && isNotEmpty(this.code.system);
        this.validation.value = isDefined(this.code.value) && isNotEmpty(this.code.value);
        this.validation.list = isDefined(this.code.list) && isNotEmpty(this.code.list);
        this.validation.type = isDefined(this.code.type) && isNotEmpty(this.code.type);

        return validateState(this.validation);
    }
}
