<import from="converters/translate" />
<import from="converters/get-ids" />
<import from="converters/sort" />
<import from="bx/bx-examination-phase-selector/bx-examination-phase-selector" />
<import from="components/component-result-range-selector/component-result-range-selector" />

<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 pr-8 overflow-hidden pl-7">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 overflow-hidden gap-x-1 pt-7">
                        <div ref="scrollContainer" class="flex w-[500px] flex-col overflow-y-auto pb-5 pl-1 pr-5 pt-1">
                            <div class.bind="hasScrolled ? 'border-b' : ''" class="sticky top-0 z-40 flex justify-between pb-3 bg-white border-gray-200">
                                <div class="flex justify-between flex-1">
                                    <p class="font-medium text-gray-400" t="translation:partial-views.medical-questionnaires.edit.title"></p>
                                    <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                        <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                            </svg>
                                        </div>
                                        <ux-dropdown-option click.trigger="informationSheet()">
                                            <span class="text-sm" t="global.buttons.information-sheet"></span>
                                        </ux-dropdown-option>
                                        <ux-dropdown-option click.trigger="refreshTemplate()">
                                            <span class="text-sm" t="partial-views.medical-questionnaires.labels.refresh-templates"></span>
                                        </ux-dropdown-option>
                                        <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteMedicalQuestionnaires)"></ux-dropdown-divider>
                                        <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteMedicalQuestionnaires)" click.trigger="delete()">
                                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                        </ux-dropdown-option>
                                    </ux-dropdown>
                                </div>
                            </div>
                            <div class="flex flex-col mb-3">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="global.labels.name"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('name', true)"
                                    rows="1"
                                    value.two-way="questionnaire.name[language]"
                                    valid.bind="validation.name"
                                    t="[placeholder]partial-views.medical-questionnaires.placeholders.name">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex flex-col mb-3">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-questionnaires.labels.select-examination-phase"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <bx-examination-phase-selector
                                    on-select.bind="handlePhaseSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]global.placeholders.select-examination-phase"
                                    valid.bind="validation.phase"
                                    value.two-way="questionnaire.phase.id">
                                </bx-examination-phase-selector>
                            </div>
                            <div class="flex flex-col mb-3">
                                <ux-label class="mb-1">
                                    <span t="global.labels.description"></span>
                                </ux-label>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('description')"
                                    value.two-way="questionnaire.description[language]"
                                    rows="8"
                                    t="[placeholder]partial-views.medical-questionnaires.placeholders.description">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex items-center justify-between mb-3">
                                <ux-label>
                                    <span t="translation:partial-views.medical-questionnaires.labels.calculate-scores"></span>
                                </ux-label>
                                <ux-toggle
                                    onchange.trigger="handleCalculateChanged($event)"
                                    checked.two-way="questionnaire.calculateScoreTotal"
                                    label-active.bind="'translation:global.labels.yes' | t"
                                    label-inactive.bind="'translation:global.labels.no' | t"
                                    size="md"
                                    color="primary">
                                </ux-toggle>
                            </div>
                            <template if.bind="questionnaire.calculateScoreTotal">
                                <div class="flex flex-col mb-3">
                                    <component-result-range-selector
                                        manage-translations-for.bind="manageTranslationsFor"
                                        language.bind="language"
                                        validation.two-way="validation.results"
                                        t="[label]translation:partial-views.medical-questionnaires.labels.select-result-ranges"
                                        results.two-way="questionnaire.scoreResultRanges">
                                    </component-result-range-selector>
                                </div>
                            </template>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questionnaires.labels.select-differential-diagnoses"></span>
                                </ux-label>
                                <bx-diagnosis-multi-selector
                                    values.bind="questionnaire.differentialDiagnoses | getIds"
                                    workspace.bind="authenticated.workspace.id"
                                    language.bind="language"
                                    on-change.bind="handleDiagnosesChanged"
                                    t="[placeholder]partial-views.medical-questionnaires.placeholders.select-differential-diagnoses">
                                </bx-diagnosis-multi-selector>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="global.labels.healthcare-sector"></span>
                                </ux-label>
                                <ux-multi-selector values.two-way="questionnaire.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                            </div>
                        </div>
                        <div class="flex flex-col flex-1 pb-5 overflow-hidden">
                            <div class="flex flex-col flex-1 px-10 pb-10 overflow-y-auto bg-gray-100 rounded-lg">
                                <div class="sticky top-0 z-[999] flex w-full items-center justify-between border-b border-gray-300 bg-gray-100/50 pb-2 pt-5 backdrop-blur-xl">
                                    <p class="text-sm text-gray-800" t="translation:partial-views.medical-questionnaires.labels.selected-questions"></p>
                                </div>
                                <div class="flex flex-col flex-1 px-1">
                                    <div class="flex flex-col items-center flex-1 pt-5">
                                        <ux-card>
                                            <div class.bind="questionnaire.questions.length > 0 ? 'space-y-10' : ''" class="flex flex-col p-10">
                                                <div class="grid grid-cols-12 gap-1">
                                                    <template repeat.for="item of questionnaire.questions">
                                                        <template if.bind="item.question">
                                                            <div class="${setColumns(item)} flex flex-col">
                                                                <div
                                                                    class.bind="state.clipboard.questionItem && state.clipboard.questionItem.item.id === item.id ? 'bg-gray-200' : 'bg-gray-100'"
                                                                    class="flex flex-col rounded-lg px-3 py-1.5 text-sm font-medium text-gray-800">
                                                                    <div class="flex items-center justify-between">
                                                                        <a href="javascript:" click.trigger="toggleExpansion(item.id)" class="flex items-center flex-1 min-w-0 gap-3 pr-2">
                                                                            <div class="flex flex-1 min-w-0 gap-3">
                                                                                <span class="text-gray-500" t="translation:global.labels.medical-question"></span>
                                                                                <span class="block truncate">${ item.question.name | translate:language }</span>
                                                                            </div>
                                                                            <div class="flex gap-0.5">
                                                                                <div if.bind="item.attributes.isRequired" class="h-2 w-2 rounded-sm bg-[#F25366]"></div>
                                                                                <div if.bind="item.attributes.hasVisibilityRequirements" class="w-2 h-2 rounded-sm bg-primary"></div>
                                                                            </div>
                                                                        </a>
                                                                        <ux-dropdown
                                                                            border.bind="false"
                                                                            transparent.bind="true"
                                                                            type="full"
                                                                            color="dark"
                                                                            size="sm"
                                                                            padding.bind="false"
                                                                            mode="outline"
                                                                            placement="bottom-right">
                                                                            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                                                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                                    <path
                                                                                        fill="currentColor"
                                                                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                                                </svg>
                                                                            </div>
                                                                            <template if.bind="questionnaire.questions.length > 1">
                                                                                <ux-dropdown-option if.bind="$index !== 0" click.trigger="moveItem('up', $index)">
                                                                                    <span class="text-sm" t="global.buttons.move-up"></span>
                                                                                </ux-dropdown-option>
                                                                                <ux-dropdown-option if.bind="$index < questionnaire.questions.length - 1" click.trigger="moveItem('down', $index)">
                                                                                    <span class="text-sm" t="global.buttons.move-down"></span>
                                                                                </ux-dropdown-option>
                                                                            </template>
                                                                            <ux-dropdown-option click.trigger="copyOrCut('cut', $index)">
                                                                                <span class="text-sm" t="global.buttons.cut"></span>
                                                                            </ux-dropdown-option>
                                                                            <ux-dropdown-option click.trigger="addItem($index)">
                                                                                <span class="text-sm" t="translation:global.buttons.add-before"></span>
                                                                            </ux-dropdown-option>
                                                                            <ux-dropdown-option click.trigger="addItem($index + 1)">
                                                                                <span class="text-sm" t="translation:global.buttons.add-after"></span>
                                                                            </ux-dropdown-option>
                                                                            <ux-dropdown-option
                                                                                click.trigger="pasteItem(getIndex($index, 'before'))"
                                                                                if.bind="state.clipboard.questionItem && state.clipboard.questionItem.item.id !== step.id">
                                                                                <span class="text-sm" t="translation:global.buttons.paste-before"></span>
                                                                            </ux-dropdown-option>
                                                                            <ux-dropdown-option
                                                                                click.trigger="pasteItem(getIndex($index, 'after'))"
                                                                                if.bind="state.clipboard.questionItem && state.clipboard.questionItem.item.id !== step.id">
                                                                                <span class="text-sm" t="translation:global.buttons.paste-after"></span>
                                                                            </ux-dropdown-option>
                                                                            <ux-dropdown-option click.trigger="openSettings(item)">
                                                                                <span class="text-sm" t="global.buttons.settings"></span>
                                                                            </ux-dropdown-option>
                                                                            <ux-dropdown-divider></ux-dropdown-divider>
                                                                            <ux-dropdown-option click.trigger="removeItem($index)">
                                                                                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                                            </ux-dropdown-option>
                                                                        </ux-dropdown>
                                                                    </div>
                                                                    <div class="flex flex-col px-4 py-3 mt-2 mb-2 border border-gray-200 rounded-lg" if.bind="states[item.id].expanded">
                                                                        <div class="flex items-center gap-1 text-sm">
                                                                            <p class="flex text-gray-500">
                                                                                <span class="" t="translation:global.labels.question"></span>
                                                                                <span>:</span>
                                                                            </p>
                                                                            <p>${ item.question.content | translate:language }</p>
                                                                        </div>
                                                                        <div if.bind="item.question.choices.length > 0" class="flex items-start gap-1 text-sm">
                                                                            <p class="flex text-gray-500">
                                                                                <span class="" t="translation:global.labels.options"></span>
                                                                                <span>:</span>
                                                                            </p>
                                                                            <div class="flex flex-col">
                                                                                <span repeat.for="choice of item.question.choices | sort:'displayOrder'">${choice.value}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template else>
                                                            <div class="flex flex-col items-start col-span-12 p-5 bg-gray-100 rounded-lg">
                                                                <ux-label class="mb-1">
                                                                    <span t="translation:partial-views.medical-questionnaires.labels.select-linked-question"></span>
                                                                </ux-label>
                                                                <div class="flex w-full">
                                                                    <ux-combobox
                                                                        data.bind="$index"
                                                                        rounded="left"
                                                                        used.bind="questionnaire.questions | getIds:'question'"
                                                                        valid.bind="validation.questions[$index].question"
                                                                        onselect.trigger="handleQuestionSelected($event)"
                                                                        t="[placeholder]partial-views.medical-questionnaires.placeholders.select-linked-question"
                                                                        value.bind="questionnaire.questions[$index].question.id">
                                                                        <ux-combobox-option repeat.for="question of questions" value.bind="question.id">
                                                                            ${ question.name | translate:language }
                                                                        </ux-combobox-option>
                                                                    </ux-combobox>
                                                                    <button
                                                                        class.bind="validation.questions[$index].question ? 'border-gray-300' : 'border-red-500'"
                                                                        class="inline-flex h-[38px] items-center rounded-r-lg border-y border-r bg-white text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1 sm:px-2"
                                                                        click.trigger="removeItem($index)">
                                                                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                stroke-width="2"
                                                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </div>
                                                <div class="flex flex-col items-center justify-center flex-1 gap-y-2">
                                                    <p if.bind="!validation.any" class="text-sm text-red-500" t="partial-views.medical-questionnaires.messages.missing-items"></p>
                                                    <button
                                                        click.trigger="addItem()"
                                                        class="flex items-center px-4 py-2 text-gray-800 border border-gray-300 rounded-full ring-gray-400 ring-offset-2 focus:outline-none focus:ring-1 active:text-gray-500">
                                                        <span class="text-xs" t="partial-views.medical-questionnaires.buttons.add-question"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </ux-card>
                                    </div>
                                    <div class="flex flex-col items-center mt-5 gap-y-1">
                                        <p class="text-xs text-gray-400" t="translation:global.labels.possible-step-settings"></p>
                                        <div class="flex items-center gap-2 lowercase">
                                            <div class="flex items-center gap-1">
                                                <div class="h-2 w-2 rounded-sm bg-[#F25366]"></div>
                                                <span class="text-xs" t="translation:global.labels.has-required-setting"></span>
                                            </div>
                                            <div class="flex items-center gap-1">
                                                <div class="w-2 h-2 rounded-sm bg-primary"></div>
                                                <span class="text-xs" t="translation:global.labels.has-display-requirements-setting"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1">
                        <div class="flex flex-1 pt-8 pb-5 space-x-6 overflow-hidden">
                            <div class="flex w-[400px]">
                                <div class="flex flex-col flex-1">
                                    <div class="grid grid-cols-2 gap-5">
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col items-center justify-center flex-1 bg-gray-100 rounded-lg">
                                <div class="w-[500px] rounded-lg bg-white p-5 shadow-sm">
                                    <div class="grid grid-cols-2 gap-5">
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </div>
                                </div>
                                <div class="flex items-center justify-center py-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                                    </svg>
                                </div>
                                <div class="w-[500px] rounded-lg bg-white p-5 shadow-sm">
                                    <div class="grid grid-cols-2 gap-5">
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton class="col-span-2" show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="sticky bottom-0 z-50 flex justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading || deleting"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
