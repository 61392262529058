import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { SortDirection } from '@wecore/sdk-crm';
import { DeclarationSendMethods, DeclarationStatusLog, DeclarationStatuses, DeclarationsApiClient, GetDeclarationResponse, GetInsurerResponse, InsurersApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, savePageState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { Broadcasts } from '../../infra/broadcasts/broadcasts';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { checkForFilters, cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { KeyValuePair } from '../../models/key-value-pair';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, DeclarationsApiClient, ModalService, InsurersApiClient)
export class PartialDeclarations extends BasePartialView {
    public declarations: GetDeclarationResponse[] = [];
    public total: number = 0;
    public sorting: any = {};
    public SortDirection: typeof SortDirection = SortDirection;
    public noResults: boolean = false;
    public initialized: boolean = false;
    public DeclarationStatuses: typeof DeclarationStatuses = DeclarationStatuses;
    public insurers: GetInsurerResponse[];

    private pageSize: number = 500;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private fetching: boolean = false;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly declarationsApi: DeclarationsApiClient,
        private readonly modalService: ModalService,
        private readonly insurersApi: InsurersApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                if (isNotDefined(this.pageState.values.filters))
                    this.pageState.values.filters = {
                        practitioners: [],
                        invoices: [],
                        declarationSendMethods: [],
                        patients: [],
                        performanceStatuses: [],
                        declarationStatuses: [],
                        insurers: [],
                        start: undefined,
                        end: undefined
                    };

                this.loadViewsFromUrl({
                    open: async (view: string, entityId: string) => {
                        if (isNotDefined(view)) return;

                        if (view.includes('Edit') && isNotEmpty(entityId)) this.edit(new GetDeclarationResponse({ id: entityId }), false);
                        if (view.includes('Log')) this.logs(false);
                    }
                });

                const [insurers] = await Promise.all([
                    this.insurersApi.getAll(this.authenticated.workspace.id, 100, 0), //
                    this.fetch()
                ]);
                this.insurers = insurers.data;

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(CustomEvents.DeclarationsUpdated, (data: { declaration: GetDeclarationResponse }) => {
                        const index = this.declarations.findIndex((d) => d.id === data.declaration.id);
                        if (index > -1) {
                            this.declarations[index] = data.declaration;
                            this.declarations = [
                                ...(this.declarations.length > 0 ? [this.declarations.shift()] : []), //
                                ...cloneDeep(this.declarations)
                            ];
                        }
                    }),
                    this.events.subscribe(Broadcasts.DeclarationStatusLog, (data: { log: DeclarationStatusLog }) => {
                        const index = this.declarations.findIndex((d) => d.id === data.log.declaration.id);
                        if (index > -1) {
                            this.declarations[index].status = data.log.newStatus;
                            this.declarations = [
                                ...(this.declarations.length > 0 ? [this.declarations.shift()] : []), //
                                ...cloneDeep(this.declarations)
                            ];
                        }
                    })
                ];

                this.baseLoaded = true;

                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarations.attached', x));
    }

    public async edit(declaration: GetDeclarationResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditDeclaration.with({ id: declaration.id, closeable: true }) //
                .whenClosed(async (result: PartialViewResults, _: any) => {
                    if (result === PartialViewResults.Deleted) this.fetch(true);
                }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl })
        });
    }

    public async openFilters(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.Filters.with({
                filters: this.pageState.values.filters,
                settings: {
                    declarationSendMethods: true,
                    declarationStatuses: true,
                    insurers: true,
                    start: true,
                    end: true
                }
            }) //
                .whenClosed(async (result: PartialViewResults, filters: any) => {
                    if (result === PartialViewResults.Ok) {
                        this.pageState.values.hasFilters = checkForFilters(filters);
                        this.pageState.values.filters = filters;
                        savePageState(this.pageState);
                        await this.fetch(true);
                    }
                }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public async fetch(reset: boolean = false, timeout: number = 0): Promise<void> {
        if (this.fetching) return;
        this.fetching = true;
        return new Promise(async (resolve, reject) => {
            this.noResults = false;
            if (reset) {
                this.skip = 0;
                this.declarations = [];
                this.endOfList = false;
                this.fetching = false;
                if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
            }

            this.baseLoaded = false;
            setTimeout(async () => {
                try {
                    const response = await this.declarationsApi.search(
                        this.authenticated.workspace.id, //
                        '',
                        this.pageSize,
                        this.skip,
                        this.sorting.direction,
                        this.sorting.field,
                        undefined,
                        this.pageState.values.filters.insurers?.any() ? this.pageState.values.filters.insurers.map((i: KeyValuePair) => i.key) : undefined,
                        this.pageState.values.filters.declarationStatuses?.any() ? this.pageState.values.filters.declarationStatuses.map((i: KeyValuePair) => i.key as DeclarationStatuses) : undefined,
                        this.pageState.values.filters.declarationSendMethods?.any()
                            ? this.pageState.values.filters.declarationSendMethods.map((i: KeyValuePair) => i.key as DeclarationSendMethods)
                            : undefined,
                        isDefined(this.pageState.values.filters.start) ? this.pageState.values.filters.start : undefined,
                        isDefined(this.pageState.values.filters.end) ? this.pageState.values.filters.end : undefined
                    );

                    this.total = response.total;
                    if (response.data.empty()) {
                        this.endOfList = true;
                        this.noResults = this.declarations.empty();
                        this.baseLoaded = true;
                        this.fetching = false;
                        resolve();
                        return;
                    }

                    this.skip += Number(this.pageSize);
                    this.declarations = [...this.declarations, ...response.data];

                    this.baseLoaded = true;
                    this.initialized = true;
                    this.fetching = false;

                    resolve();
                } catch (e) {
                    reject(e);
                }
            }, timeout);
        });
    }

    public async sort(field: 'CreatedAt'): Promise<void> {
        if (this.sorting.field === field && isNotDefined(this.sorting.direction)) this.sorting.direction = SortDirection.Asc;
        else if (this.sorting.field === field && this.sorting.direction === SortDirection.Asc) this.sorting.direction = SortDirection.Desc;
        else if (this.sorting.field === field && this.sorting.direction === SortDirection.Desc) this.sorting.direction = undefined;
        else {
            this.sorting.field = field;
            this.sorting.direction = SortDirection.Asc;
        }
        await this.fetch(true);
    }

    public removeFilter = (type: string, index: number): void => {
        if (type === 'start' || type === 'end') {
            this.pageState.values.filters[type] = undefined;
        } else this.pageState.values.filters[type].splice(index, 1);

        this.pageState.values.hasFilters = checkForFilters(this.pageState.values.filters);
        savePageState(this.pageState);
        this.fetch(true);
    };

    public async delete(declaration: GetDeclarationResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declarations.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.declarations.questions.delete.message') //
                    .replace('{entity}', `<span>'${declaration.trackingNumber ?? declaration.conceptNumber}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        await this.removeChildViews();
                        this.deleting = true;
                        try {
                            await this.declarationsApi.delete(declaration.id, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.declarations.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.declarations.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${declaration.trackingNumber ?? declaration.conceptNumber}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            await this.fetch(true);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[declarations/delete]', e);
                        }
                    }
                }
            })
        );
    }

    public async archive(declaration: GetDeclarationResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declarations.questions.archive.title'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.archive'),
                message: this.t
                    .tr('translation:partial-views.declarations.questions.archive.message') //
                    .replace('{entity}', `<span>'${declaration.trackingNumber ?? declaration.conceptNumber}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        await this.removeChildViews();
                        try {
                            await this.declarationsApi.archive(declaration.id, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.declarations.notifications.archived-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.declarations.notifications.archived-successfully.message') //
                                    .replace('{entity}', `<span>'${declaration.trackingNumber ?? declaration.conceptNumber}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            await this.fetch(true);
                        } catch (e) {
                            await this.errorHandler.handle('[declarations/archive]', e);
                        }
                    }
                }
            })
        );
    }

    public async logs(updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.DeclarationsLogs.whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok) await this.fetch(true);
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl
            })
        });
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading && !this.fetching) {
            await this.fetch();
        }
    }
}
