<import from="./ux-avatar.scss" />

<div
    if.bind="type === 'image'"
    css="background-image: url('${src}'); background-position: ${bgPosition}; background-repeat: ${bgRepeat}; background-size: ${bgSize};"
    border.class="border"
    class="${size} ${border ? 'ring-4 ring-gray-100' : ''} flex items-center justify-center rounded-full border-gray-200 bg-white"></div>
<div if.bind="type === 'text'" class="${size} ${getBackgroundColor()} ${border ? 'ring-4 ring-purple-50' : ''} flex items-center justify-center rounded-full">
    <p class="${getTextColor()} leading-none">${label ?? src}</p>
</div>
<div if.bind="type !== 'image' && type !== 'text'" class="${size} ${getBackgroundColor()} ${border ? 'ring-4 ring-purple-50' : ''} flex items-center justify-center rounded-full">?</div>
