<import from="converters/currency" />
<import from="converters/keys" />
<import from="converters/declaration-performance-type-to-string" />
<import from="converters/declaration-mutation-to-string" />
<import from="bx/bx-patient-selector/bx-patient-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex flex-col w-full h-full overflow-hidden">
            <template if.bind="baseLoaded">
                <div class="flex flex-col flex-1 px-8 pb-5 overflow-y-auto">
                    <div class="sticky top-0 z-10 flex flex-col justify-between pt-8 pb-3 bg-white border-b border-gray-200">
                        <div class="flex items-center gap-1 pb-2">
                            <p t="translation:partial-views.declarations.edit.title"></p>
                            <p class="font-medium text-gray-800">${declaration.trackingNumber}</p>
                        </div>
                        <div class="flex flex-col">
                            <div class="grid grid-cols-12 gap-1 border-gray-200">
                                <div class="col-span-3">
                                    <bx-patient-selector
                                        on-select.bind="handlePatientSelected"
                                        language.bind="language"
                                        workspace.bind="authenticated.workspace.id"
                                        allow-clear.bind="true"
                                        t="[placeholder]translation:partial-views.declarations.placeholders.filter-on-patient">
                                    </bx-patient-selector>
                                </div>
                                <div class="col-span-3">
                                    <ux-select
                                        onselect.trigger="handleTypeSelected($event)"
                                        allow-clear.bind="true"
                                        t="[placeholder]translation:partial-views.declarations.placeholders.filter-on-type">
                                        <ux-select-option repeat.for="type of DeclarationPerformanceTypes | keys" value.bind="type">
                                            ${ type | declarationPerformanceTypeToString | t }
                                        </ux-select-option>
                                    </ux-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="flex flex-col gap-1 mt-2">
                        <li repeat.for="item of request.items" hidden.class="!itemStates[item.performanceId].visible" class="px-4 py-3 border-gray-200 rounded-lg bg-gray-50">
                            <let performance.bind="performances.find((x) => x.id === item.performanceId)"></let>
                            <div class="grid grid-cols-12 gap-x-1">
                                <div class="flex flex-col col-span-5 text-sm">
                                    <p>${performance.description}</p>
                                    <p class="text-primary">${performance.code.list}/${performance.code.value}</p>
                                </div>
                                <div class="flex flex-col col-span-2 text-sm">
                                    <p>${performance.patient.name}</p>
                                    <p class="text-primary">${performance.patient.data.number}</p>
                                </div>
                                <div class="flex flex-col col-span-1 text-sm">
                                    <p>${item.awarded | currency}</p>
                                    <p class="text-primary">${performance.type | declarationPerformanceTypeToString | t}</p>
                                </div>
                                <div class="col-span-4">
                                    <ux-select value.two-way="item.mutation">
                                        <ux-select-option value.bind="ProcessDeclarationRequestItemMutation.Accept">
                                            <span class="text-green-600">${ProcessDeclarationRequestItemMutation.Accept | declarationMutationToString | t}</span>
                                        </ux-select-option>
                                        <ux-select-option value.bind="ProcessDeclarationRequestItemMutation.PartiallyAccept">
                                            <span class="text-yellow-600">${ProcessDeclarationRequestItemMutation.PartiallyAccept | declarationMutationToString | t}</span>
                                        </ux-select-option>
                                        <ux-select-option value.bind="ProcessDeclarationRequestItemMutation.Reject">
                                            <span class="text-red-600">${ProcessDeclarationRequestItemMutation.Reject | declarationMutationToString | t}</span>
                                        </ux-select-option>
                                    </ux-select>
                                </div>
                                <div if.bind="item.mutation === ProcessDeclarationRequestItemMutation.PartiallyAccept" class="col-span-12 mt-2 mb-1">
                                    <ux-label>
                                        <span t="translation:partial-views.declaration-performances.labels.awarded"></span>
                                    </ux-label>
                                </div>
                                <div if.bind="item.mutation === ProcessDeclarationRequestItemMutation.PartiallyAccept" class="flex flex-col col-span-3">
                                    <ux-input value.two-way="item.awarded" valid.bind="validation.items[$index].awardedLower && validation.items[$index].awardedNotZero" type="number"></ux-input>
                                </div>
                                <div
                                    if.bind="item.mutation !== ProcessDeclarationRequestItemMutation.Accept"
                                    col-span-12.class="item.mutation === ProcessDeclarationRequestItemMutation.Reject"
                                    mt-2.class="item.mutation === ProcessDeclarationRequestItemMutation.Reject"
                                    col-span-9.class="item.mutation === ProcessDeclarationRequestItemMutation.PartiallyAccept"
                                    class="flex">
                                    <ux-textarea
                                        rows.bind="item.mutation === ProcessDeclarationRequestItemMutation.Reject ? 3 : 1"
                                        value.two-way="item.comments"
                                        t="[placeholder]translation:partial-views.declarations.placeholders.comments"></ux-textarea>
                                </div>
                                <p
                                    if.bind="item.mutation === ProcessDeclarationRequestItemMutation.PartiallyAccept && !validation.items[$index].awardedLower"
                                    class="col-span-12 mt-1 text-xs text-red-500"
                                    t="translation:partial-views.declaration-performances.messages.awarded-lower"></p>
                                <p
                                    if.bind="item.mutation === ProcessDeclarationRequestItemMutation.PartiallyAccept && !validation.items[$index].awardedNotZero"
                                    class="col-span-12 mt-1 text-xs text-red-500"
                                    t="translation:partial-views.declaration-performances.messages.awarded-not-zero"></p>
                            </div>
                        </li>
                        <li if.bind="request.items.every((x) => !itemStates[x.performanceId].visible)" class="flex items-center justify-center py-5 text-sm">
                            <span t="translation:global.labels.no-results"></span>
                        </li>
                    </ul>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-8 pt-8">
                    <div class="flex justify-between pb-3 border-b border-gray-200">
                        <p class="text-xl font-medium text-gray-800" t="translation:partial-views.declarations.edit.title"></p>
                    </div>
                    <div class="grid grid-cols-1 gap-5 mt-5">
                        <div class="flex flex-col flex-1 space-y-3">
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </template>
            <div justify-end.class="validation.valid" justify-between.class="!validation.valid" class="flex items-center px-5 py-3 bg-white border-t border-gray-200 gap-x-2">
                <p if.bind="!validation.valid" class="text-sm text-red-500" t="translation:partial-views.declaration-performances.messages.invalid-performances"></p>
                <div class="flex justify-end flex-1 gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button loading.bind="isLoading" size="sm" color="success" disabled.bind="isLoading" click.trigger="process()">
                        <span class="pointer-events-none" t="global.buttons.process"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
