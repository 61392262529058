<import from="components/component-duplicator/component-duplicator" />
<import from="components/component-sports-selector/component-sports-selector" />
<import from="components/component-professions-selector/component-professions-selector" />
<import from="components/component-labels-selector/component-labels-selector" />
<import from="bx/bx-user-selector/bx-user-selector" />

<section class="flex flex-col flex-1 px-6 overflow-y-auto">
    <div class="sticky top-0 z-10 pt-6 pb-4 bg-white border-b border-gray-300">
        <div class="flex justify-center py-5" if.bind="isEdit">
            <ux-avatar size="xxl" email.bind="patient.emails[0].value" name.bind="patient.displayName" color.bind="patient.avatar.color" shade.bind="patient.avatar.shade"></ux-avatar>
        </div>
        <div class="grid grid-cols-12 gap-2">
            <div class="flex flex-col col-span-2">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.initials"></span>
                </ux-label>
                <ux-input value.two-way="patient.initials" t="[placeholder]translation:partial-views.patients.placeholders.add-initials"></ux-input>
            </div>
            <div class="flex flex-col col-span-4">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.firstname"></span>
                </ux-label>
                <ux-input value.two-way="patient.firstName" t="[placeholder]translation:partial-views.patients.placeholders.add-firstname"></ux-input>
            </div>
            <div class="flex flex-col col-span-2">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.lastname-affix"></span>
                </ux-label>
                <ux-input value.two-way="patient.lastNameAffix" t="[placeholder]translation:partial-views.patients.placeholders.add-lastname-affix"></ux-input>
            </div>
            <div class="flex flex-col col-span-4">
                <div class="flex items-center space-x-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.patients.labels.lastname"></span>
                    </ux-label>
                    <span class="flex items-center h-3 text-red-500">*</span>
                </div>
                <ux-input valid.bind="validation.lastName" value.two-way="patient.lastName" t="[placeholder]translation:partial-views.patients.placeholders.add-lastname"></ux-input>
            </div>
            <div class="flex flex-col col-span-6">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.middlename"></span>
                </ux-label>
                <ux-input value.two-way="patient.middleName" t="[placeholder]translation:partial-views.patients.placeholders.add-middlename"></ux-input>
            </div>
            <div class="flex flex-col col-span-6">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.birthname"></span>
                </ux-label>
                <ux-input value.two-way="patient.birthName" t="[placeholder]translation:partial-views.patients.placeholders.add-birthname"></ux-input>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-2 pb-6 mt-4">
        <div class="flex flex-col col-span-6">
            <div class="flex items-center space-x-1">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.head-practitioner"></span>
                </ux-label>
                <span class="flex items-center h-3 text-red-500">*</span>
            </div>
            <bx-user-selector
                on-select.bind="handleUserSelected"
                language.bind="language"
                workspace.bind="workspace"
                t="[placeholder]translation:global.placeholders.select-user;[placeholder-search]translation:global.placeholders.search"
                valid.bind="validation.headPractitioner"
                selected.bind="patient.headPractitioner"
                value.two-way="patient.headPractitioner.id">
            </bx-user-selector>
        </div>
        <div class="flex flex-col flex-1 col-span-6">
            <div class="flex items-center space-x-1">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.patients.labels.citizens-service-number"></span>
                </ux-label>
                <!-- <span class="flex items-center h-3 text-red-500">*</span> -->
            </div>
            <ux-input value.two-way="patient.citizensServiceNumber" valid.bind="validation.bsn" t="[placeholder]translation:partial-views.patients.placeholders.citizens-service-number"></ux-input>
        </div>
        <div class="flex flex-col col-span-6">
            <ux-label class="mb-1">
                <span t="translation:partial-views.patients.labels.sex"></span>
            </ux-label>
            <ux-select value.two-way="patient.gender">
                <ux-select-option value.bind="Genders.Male">
                    <span t="translation:global.labels.male"></span>
                </ux-select-option>
                <ux-select-option value.bind="Genders.Female">
                    <span t="translation:global.labels.female"></span>
                </ux-select-option>
            </ux-select>
        </div>
        <div class="flex flex-col col-span-6">
            <div class="flex items-center space-x-1">
                <ux-label class="mb-1">
                    <span t="translation:global.labels.dob"></span>
                </ux-label>
                <span class="flex items-center h-3 text-red-500">*</span>
            </div>
            <ux-datepicker value.two-way="patient.dateOfBirth" valid.bind="validation.dob" mode="input" t="[placeholder]translation:partial-views.patients.placeholders.select-dob"> </ux-datepicker>
        </div>
        <div class="flex flex-col flex-1 col-span-6">
            <ux-label class="mb-1">
                <span t="translation:global.labels.weight"></span>
            </ux-label>
            <ux-input value.two-way="patient.weight" type="number" t="[placeholder]translation:global.placeholders.enter-weight-in-kg"></ux-input>
        </div>
        <div class="flex flex-col flex-1 col-span-6">
            <ux-label class="mb-1">
                <span t="translation:global.labels.length"></span>
            </ux-label>
            <ux-input value.two-way="patient.length" type="number" t="[placeholder]translation:global.placeholders.enter-length-in-cm"></ux-input>
        </div>
        <div class="col-span-12">
            <ux-body-divider></ux-body-divider>
        </div>
        <div class="flex flex-col col-span-12 space-y-5">
            <component-duplicator
                t="[label]translation:partial-views.patients.labels.enter-emails"
                type="email"
                descriptions.bind="emailDescriptions"
                items.two-way="patient.emails"
                language.bind="language"
                validation.two-way="validation.emails">
            </component-duplicator>
            <component-duplicator
                t="[label]translation:partial-views.patients.labels.enter-phones"
                type="phone"
                descriptions.bind="phoneDescriptions"
                items.two-way="patient.phones"
                mask="000000000"
                language.bind="language"
                validation.two-way="validation.phones">
            </component-duplicator>
            <component-duplicator
                t="[label]translation:partial-views.patients.labels.enter-links"
                type="link"
                descriptions.bind="linkDescriptions"
                items.two-way="patient.links"
                language.bind="language"
                validation.two-way="validation.links">
            </component-duplicator>
        </div>
        <div class="col-span-12">
            <ux-body-divider></ux-body-divider>
        </div>
        <div class="flex flex-col flex-1 col-span-12 space-y-5">
            <component-labels-selector
                registrations.two-way="patient.labels"
                validation.bind="validation.labels"
                manage-translations.bind="manageTranslations"
                workspace.bind="workspace"
                current-user.bind="currentUser"
                language.bind="language">
            </component-labels-selector>
            <component-sports-selector
                registrations.two-way="patient.sports"
                validation.bind="validation.sports"
                manage-translations.bind="manageTranslations"
                workspace.bind="workspace"
                language.bind="language">
            </component-sports-selector>
            <component-professions-selector
                registrations.two-way="patient.professions"
                validation.bind="validation.professions"
                manage-translations.bind="manageTranslations"
                workspace.bind="workspace"
                language.bind="language">
            </component-professions-selector>
        </div>
    </div>
</section>
