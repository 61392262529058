<div if.bind="orderId" class="flex items-center justify-between gap-10 rounded-lg border border-gray-200 py-2 pl-5 pr-2">
    <div class="flex flex-1 flex-col">
        <p class="text-sm text-gray-800" t="translation:components.widgets.voxelcare.messages.order-already-started-for-record"></p>
        <div class="flex gap-0.5 text-xs text-gray-500">
            <p t="translation:components.widgets.voxelcare.labels.voxelcare-order"></p>
            <p class="font-semibold">${orderId}</p>
        </div>
    </div>
    <ux-button disabled="true" color="dark">
        <span t="translation:components.widgets.voxelcare.buttons.create-order"></span>
    </ux-button>
</div>
<div else class="flex items-center justify-between rounded-lg border border-gray-200 py-2 pl-5 pr-2">
    <div class="flex flex-col">
        <ux-label>
            <span t="translation:components.widgets.voxelcare.labels.create-order"></span>
        </ux-label>
        <p class="text-xs text-gray-500" t="translation:components.widgets.voxelcare.messages.one-order-per-record"></p>
    </div>
    <ux-button click.trigger="startOrder()" color="dark">
        <span t="translation:components.widgets.voxelcare.buttons.create-order"></span>
    </ux-button>
</div>
