import { DialogService } from '@aurelia/dialog';
import { I18N } from '@aurelia/i18n';
import { Email, Link, Phone } from '@wecore/sdk-healthcare';
import { guid, isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, inject } from 'aurelia';
import { Countries } from '../../infra/countries';
import { ModalCustomDropdownValue } from '../../modals/modal-custom-dropdown-value/modal-custom-dropdown-value';
import { EventDetails } from '../../models/event-details';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';

@inject(DialogService, I18N)
export class ComponentDuplicator {
    @bindable() public mode: 'dropdown' | 'input' = 'dropdown';
    @bindable() public descriptions: { [key: string]: string }[];
    @bindable() public type: 'email' | 'link' | 'phone';
    @bindable() public label: string;
    @bindable() public mask: string;
    @bindable() public items: Email[] | Link[] | Phone[];
    @bindable() public placeholder: string;
    @bindable() public validation: any[];
    @bindable() public language: string;
    @bindable() public maxAmount: number = -1;
    @bindable() public useLabels: boolean = true;
    @bindable() public onValueChanged: (item: Email | Phone | Link, index: number) => void;

    public guid: string = guid();
    public countries: any[] = Countries.getAll();
    public set: any[];
    public selector: UxSelect;

    public constructor(
        private readonly dialogService: DialogService, //
        public t: I18N
    ) {
        this.set = this.countries.map((x) => ({ text: `+${x.callingCode} (${t.tr(`translation:global.countries.${x.code}`)})`, value: x.callingCode }));
        if (isNotDefined(this.placeholder)) this.placeholder = this.t.tr('translation:global.placeholders.value');
    }

    public attached(): void {
        if (this.items.empty()) this.add();
    }

    public add(): void {
        let item: any;

        switch (this.type) {
            case 'email':
                item = new Email({
                    translations: {
                        nl: this.t.tr('translation:global.labels.private', { lng: 'nl' }),
                        en: this.t.tr('translation:global.labels.private', { lng: 'en' })
                    }
                });
                break;
            case 'phone':
                item = new Phone({
                    translations: {
                        nl: this.t.tr('translation:global.labels.private', { lng: 'nl' }),
                        en: this.t.tr('translation:global.labels.private', { lng: 'en' })
                    },
                    callingCode: '31'
                });
                break;
            case 'link':
                item = new Link({
                    translations: {
                        nl: this.t.tr('translation:global.labels.website', { lng: 'nl' }),
                        en: this.t.tr('translation:global.labels.website', { lng: 'en' })
                    },
                    protocol: 'http://'
                });
                break;
        }

        this.items.push(item);
        this.validation.push({
            valid: true,
            email: true,
            phone: true
        });
    }

    public delete(index: any): void {
        this.items.splice(index, 1);
        this.validation.splice(index, 1);
        if (this.items.length === 0) this.add();
    }

    public async handleChange(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const option = e.detail.values;
        const index = e.detail.data;

        if (option.value === this.t.tr('translation:global.labels.custom', { lng: this.language })) {
            const response = await this.dialogService.open({
                component: () => ModalCustomDropdownValue,
                model: {
                    language: this.language
                },
                overlayDismiss: true
            });

            if (response.wasCancelled) return;

            const result = await response.dialog.closed;

            if (result.status === 'cancel') return;
            if (result.status === 'abort') return;

            const newValue = result.value as { [key: string]: any };
            const existingValue = this.descriptions.find((x) => x[this.language].toLowerCase() === newValue[this.language].toLowerCase());

            // Make sure the entered value is not the same as one
            // of the existing values.
            if (isDefined(existingValue)) {
                this.items[index].translations = existingValue;
                return;
            }

            this.descriptions = [newValue, ...this.descriptions];
            if (isDefined(this.selector)) this.selector.setValue(newValue[this.language]);
            this.items[index].translations = newValue;
        }
        // else this.items[index].translations = option.data;
    }

    public async handleBlur(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const index = e.detail.data;
        if (isFunction(this.onValueChanged)) this.onValueChanged(this.items[index], index);
        if (this.items.length === this.maxAmount) return;

        // If current value has a value and current item is the last item, add a new item.
        const value = this.items[index].value?.replace(/_/g, '');
        if (isNotEmpty(value) && index === this.items.length - 1) this.add();
    }
}
