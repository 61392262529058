<import from="converters/date" />

<div class="flex flex-col">
    <div class="flex items-start gap-10">
        <div class="w-96">
            <h5 class="mb-1 text-gray-900" t="translation:partial-views.sign-document.labels.personal-data"></h5>
            <span class="w-full text-xs text-gray-500" t="translation:partial-views.sign-document.messages.personal-data"></span>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-1 gap-3">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.first-name"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.firstName" value.two-way="firstName" disabled.bind="isLoading"></ux-input>
                </div>
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.last-name"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.lastName" value.two-way="lastName" disabled.bind="isLoading"></ux-input>
                </div>
            </div>
            <div class="flex flex-col flex-1 mt-3">
                <ux-label class="mb-1">
                    <span t="translation:global.labels.job-title"></span>
                </ux-label>
                <ux-input
                    valid.bind="validation.jobTitle"
                    value.two-way="jobTitle"
                    disabled.bind="isLoading"
                    t="[placeholder]translation:partial-views.sign-document.placeholders.job-title"></ux-input>
            </div>
        </div>
    </div>
    <div class="flex items-start gap-10 mt-8">
        <div class="w-96">
            <h5 class="mb-1 text-gray-900" t="translation:partial-views.sign-document.labels.company-details"></h5>
            <span class="w-full text-xs text-gray-500" t="translation:partial-views.sign-document.messages.company-details"></span>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-col flex-1">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.sign-document.labels.company-name"></span>
                </ux-label>
                <ux-input
                    valid.bind="validation.companyName"
                    value.two-way="companyName"
                    disabled.bind="isLoading"
                    t="[placeholder]translation:partial-views.sign-document.placeholders.company-name"></ux-input>
            </div>
            <div class="flex flex-1 gap-2 mt-3">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.chamber-of-commerce"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.coc" value.two-way="coc" disabled.bind="isLoading" t="[placeholder]translation:partial-views.sign-document.placeholders.coc"></ux-input>
                </div>
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.sign-document.labels.legal-form"></span>
                    </ux-label>
                    <ux-select value.two-way="legalForm" valid.bind="validation.legalForm" disabled.bind="isLoading" t="[placeholder]translation:partial-views.sign-document.placeholders.legal-form">
                        <ux-select-option value="Eenmanszaak">Eenmanszaak</ux-select-option>
                        <ux-select-option value="Maatschap">Maatschap</ux-select-option>
                        <ux-select-option value="VOF">VOF</ux-select-option>
                        <ux-select-option value="BV">BV</ux-select-option>
                        <ux-select-option value="CV">CV</ux-select-option>
                        <ux-select-option value="NV">NV</ux-select-option>
                        <ux-select-option value="Vereniging">Vereniging</ux-select-option>
                        <ux-select-option value="Stichting">Stichting</ux-select-option>
                        <ux-select-option value="Cooperatie">Cooperatie</ux-select-option>
                    </ux-select>
                </div>
            </div>
            <div class="flex flex-1 gap-2 mt-3">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.street"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.address" value.two-way="address" disabled.bind="isLoading"></ux-input>
                </div>
                <div class="flex flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:global.labels.zip-code"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.zipCode" value.two-way="zipCode" disabled.bind="isLoading"></ux-input>
                </div>
            </div>
            <div class="flex flex-1 gap-2 mt-3">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.sign-document.labels.city-statutory"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.statutoryCity" value.two-way="statutoryCity" disabled.bind="isLoading"></ux-input>
                </div>
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.sign-document.labels.city-office"></span>
                    </ux-label>
                    <ux-input valid.bind="validation.city" value.two-way="city" disabled.bind="isLoading"></ux-input>
                </div>
            </div>
        </div>
    </div>
    <div class="flex items-start gap-10 mt-8">
        <div class="w-96">
            <h5 class="mb-1 text-gray-900" t="translation:global.labels.signature"></h5>
            <span class="w-full text-xs text-gray-500" t="translation:partial-views.sign-document.messages.signature"></span>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-col flex-1">
                <ux-label class="mb-1">
                    <span t="translation:partial-views.sign-document.labels.location"></span>
                </ux-label>
                <ux-input valid.bind="validation.location" value.two-way="location" disabled.bind="isLoading" t="[placeholder]translation:partial-views.sign-document.placeholders.location"></ux-input>
            </div>
            <div class="flex flex-col flex-1 mt-6">
                <ux-checkbox has-label="true" valid.bind="validation.isAuthorized" checked.two-way="isAuthorized" disabled.bind="isLoading" class="mr-2">
                    <span class="text-sm text-gray-800">Ik verklaar dat ik gemachtigd ben om dit document te ondertekenen.</span>
                </ux-checkbox>
            </div>
            <div class="flex flex-col flex-1 mt-2">
                <div class="flex flex-col flex-1">
                    <ux-checkbox has-label="true" valid.bind="validation.readDocument" checked.two-way="readDocument" disabled.bind="isLoading" class="mr-2">
                        <span class="text-sm text-gray-800" t="translation:partial-views.sign-document.messages.read-document"></span>
                    </ux-checkbox>
                </div>
            </div>
            <div class="flex flex-col flex-1 mt-6">
                <div class="flex flex-col flex-1">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.sign-document.labels.date"></span>
                    </ux-label>
                    <span class="text-sm font-medium">${ today | date }</span>
                </div>
            </div>
        </div>
    </div>
</div>
