<import from="converters/keys" />
<import from="converters/currency" />
<import from="converters/translate" />
<import from="converters/time-ago" />
<import from="converters/date" />
<import from="converters/declaration-send-method-to-string" />
<import from="converters/declaration-performance-type-to-string" />
<import from="converters/declaration-status-to-string" />
<import from="converters/declaration-status-to-color" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex flex-col w-full h-full overflow-hidden">
            <template if.bind="baseLoaded">
                <div class="flex flex-col flex-1 px-8 pb-5 overflow-y-auto gap-y-3">
                    <div class="sticky top-0 z-10 flex flex-col justify-between pt-8 pb-3 bg-white border-b border-gray-200">
                        <div class="flex items-center justify-between">
                            <p class="font-medium text-gray-800" t="translation:partial-views.declarations.edit.title"></p>
                            <ux-badge type="${declaration.status | declarationStatusToColor}">
                                <span>${declaration.status | declarationStatusToString | t}</span>
                            </ux-badge>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-col pb-3 border-b border-gray-200">
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.method"></span>
                                <span>${declaration.sendMethod | declarationSendMethodToString | t}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.insurer"></span>
                                <div class="flex">
                                    <span if.bind="declaration.insurer">${declaration.insurer.translations | translate:language} (${declaration.insurerReference}) </span>
                                    <span else>${declaration.insurerReference}</span>
                                </div>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.invoice-number"></span>
                                <span if.bind="declaration.trackingNumber">${declaration.trackingNumber}</span>
                                <div else class="flex items-center gap-1 text-gray-600">
                                    <span t="translation:global.labels.draft"></span>
                                    <span>#${declaration.conceptNumber}</span>
                                </div>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.created-at"></span>
                                <span>${declaration.createdAt | date:'dd-MM-yyyy HH:mm'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.created-by"></span>
                                <span>${declaration.createdBy.name}</span>
                            </div>
                            <div class="my-1.5 flex flex-col rounded-lg bg-[#2d2d2d] p-4 text-[#ccc]">
                                <div class="flex justify-between gap-3 text-sm">
                                    <span t="translation:global.labels.total-debit"></span>
                                    <span>${declaration.debitTotalInclVat | currency:false}</span>
                                </div>
                                <div class="flex justify-between gap-3 text-sm">
                                    <span t="translation:global.labels.total-credit"></span>
                                    <span>${(0 - declaration.creditTotalInclVat) | currency:false}</span>
                                </div>
                                <div if.bind="declaration.debitTotalAmountAwardedInclVatFinancial > 0" class="border-gray-[#ccc] mt-1.5 flex justify-between gap-3 border-t pt-1 text-sm">
                                    <span t="translation:global.labels.total-awarded"></span>
                                    <span class="font-medium text-green-500">${declaration.debitTotalAmountAwardedInclVatFinancial | currency:false}</span>
                                </div>
                                <div if.bind="declaration.creditTotalAmountAwardedInclVatFinancial > 0" class="border-gray-[#ccc] mt-1.5 flex justify-between gap-3 border-t pt-1 text-sm">
                                    <span t="translation:global.labels.total-awarded"></span>
                                    <span class="font-medium text-red-500">${(0 - declaration.creditTotalAmountAwardedInclVatFinancial) | currency:false}</span>
                                </div>
                            </div>
                            <div if.bind="declaration.attachments.length > 0" class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.attachments"></span>
                                <div class="flex flex-col">
                                    <a click.trigger="openAttachment(attachment)" repeat.for="attachment of declaration.attachments" class="flex-auto underline decoration-primary" href="javascript:">
                                        <span t="translation:global.labels.attachment"></span>
                                        <span>#${$index + 1}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col pb-3 mt-3 border-b border-gray-200">
                            <ux-label class="mb-3">
                                <span t="translation:global.labels.content"></span>
                            </ux-label>
                            <a href="javascript:" click.trigger="toggleState('insured')" class="flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-lg">
                                <template if.bind="viewState.insured">
                                    <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                                    </svg>
                                </template>
                                <template else>
                                    <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                            d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                                    </svg>
                                </template>
                                <div class="flex items-center gap-1 text-sm">
                                    <p t="translation:partial-views.declarations.labels.insured"></p>
                                    <p>(${declaration.insured.length})</p>
                                </div>
                            </a>
                            <div if.bind="viewState.insured" class="flex flex-col pl-1 mt-1 ml-1 border-l-2 border-gray-200">
                                <template repeat.for="person of declaration.insured">
                                    <a href="javascript:" click.trigger="toggleState('person', $index)" mt-1.class="$index !== 0" class="flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-lg">
                                        <template if.bind="viewState.items[$index].open">
                                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                                            </svg>
                                        </template>
                                        <template else>
                                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.5"
                                                    d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                                            </svg>
                                        </template>
                                        <div class="flex items-center gap-1 text-sm">
                                            <p>${person.patient.name} (${person.patientNumber})</p>
                                        </div>
                                    </a>
                                    <div if.bind="viewState.items[$index].open" class="pl-1 mt-1 ml-1 border-l-2 border-gray-200">
                                        <div class="pl-2">
                                            <div class="flex justify-between gap-3 text-sm">
                                                <span class="text-gray-500" t="translation:global.labels.citizens-service-number"></span>
                                                <span>${person.citizensServiceNumber}</span>
                                            </div>
                                            <div class="flex justify-between gap-3 text-sm">
                                                <span class="text-gray-500" t="translation:global.labels.dob"></span>
                                                <span>${person.dateOfBirth | date}</span>
                                            </div>
                                            <!-- <div class="flex justify-between gap-3 mt-1 mb-1 text-sm">
                                                <span t="translation:global.labels.performances"></span>
                                            </div> -->
                                        </div>
                                        <div class="flex flex-col gap-1 mt-1">
                                            <template repeat.for="id of person.performances">
                                                <let performance.bind="getPerformance(id)"></let>
                                                <div class="flex items-center gap-1 pl-2 pr-3 bg-gray-100 rounded-lg">
                                                    <a href="javascript:" click.trigger="toggleState('performance', $parent.$index, $index)" class="flex items-center flex-1 gap-1 py-1">
                                                        <template if.bind="viewState.items[$parent.$index].performances[$index].open">
                                                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                                                            </svg>
                                                        </template>
                                                        <template else>
                                                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                                                <path
                                                                    stroke="currentColor"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="1.5"
                                                                    d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                                                            </svg>
                                                        </template>
                                                        <div class="flex items-center gap-1 text-sm">
                                                            <p class="text-gray-500">${performance.type | declarationPerformanceTypeToString | t}</p>
                                                            <p>${performance.code.value}</p>
                                                        </div>
                                                    </a>
                                                    <ux-button
                                                        click.trigger="openPerformance(performance.id)"
                                                        transparent.bind="true"
                                                        mode="outline"
                                                        size="sm"
                                                        color="info"
                                                        border.bind="false"
                                                        padding.bind="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                                                        </svg>
                                                    </ux-button>
                                                </div>

                                                <div if.bind="viewState.items[$parent.$index].performances[$index].open" class="pl-2 ml-1 border-l-2 border-gray-200">
                                                    <div class="pl-1">
                                                        <div class="flex justify-between gap-3 text-sm">
                                                            <span class="text-gray-500" t="translation:global.labels.practitioner-code"></span>
                                                            <span>${performance.practitionerCode}</span>
                                                        </div>
                                                        <div class="flex justify-between gap-3 text-sm">
                                                            <span class="text-gray-500" t="translation:partial-views.declarations.labels.treatment-start"></span>
                                                            <span>${performance.recordStart | date }</span>
                                                        </div>
                                                        <div class="flex justify-between gap-3 text-sm">
                                                            <span class="text-gray-500" t="translation:global.labels.performance-date"></span>
                                                            <span>${performance.performanceDate | date }</span>
                                                        </div>
                                                        <div class="flex justify-between gap-3 text-sm">
                                                            <span class="text-gray-500" t="translation:global.labels.vat-percentage"></span>
                                                            <span>${performance.vatPercentage}%</span>
                                                        </div>
                                                        <div class="flex justify-between gap-3 text-sm">
                                                            <span class="text-gray-500" t="translation:global.labels.total-excl-vat"></span>
                                                            <span
                                                                text-green-600.class="performance.type === DeclarationPerformanceTypes.Debit"
                                                                text-red-600.class="performance.type === DeclarationPerformanceTypes.Credit">
                                                                ${performance.totalExclVat | currency:false}
                                                            </span>
                                                        </div>
                                                        <div class="flex justify-between gap-3 text-sm">
                                                            <span class="text-gray-500" t="translation:global.labels.total-incl-vat"></span>
                                                            <span
                                                                text-green-600.class="performance.type === DeclarationPerformanceTypes.Debit"
                                                                text-red-600.class="performance.type === DeclarationPerformanceTypes.Credit">
                                                                ${performance.totalInclVat | currency:false}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="flex flex-col mt-5">
                            <ux-label class="mb-3">
                                <span t="translation:global.labels.timeline"></span>
                            </ux-label>
                            <ul role="list" class="space-y-3">
                                <li class="relative flex flex-col gap-x-4">
                                    <div class="flex gap-x-4">
                                        <div if.bind="declaration.events.length > 0" class="absolute top-0 left-0 flex justify-center w-6 -bottom-6">
                                            <div class="w-px bg-gray-200"></div>
                                        </div>
                                        <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                        </div>
                                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span class="font-medium text-gray-900">${declaration.createdBy.name}</span>
                                            <span t="translation:partial-views.declarations.messages.created-the-declaration"></span>
                                        </p>
                                        <ux-tooltip content="${declaration.createdAt | date:'dd-MM-yyyy HH:mm'}">
                                            <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${declaration.createdAt | timeAgo:false:false:true}</time>
                                        </ux-tooltip>
                                    </div>
                                    <div class="flex flex-col gap-2 pl-10 mt-2"></div>
                                </li>
                                <li repeat.for="event of declaration.events" class="relative flex flex-col gap-x-4">
                                    <div class="flex gap-x-4">
                                        <div if.bind="!$last" class="absolute top-0 left-0 flex justify-center w-6 -bottom-6">
                                            <div class="w-px bg-gray-200"></div>
                                        </div>
                                        <template
                                            if.bind="event.type === DeclarationEventTypes.RejectedByVecozo 
                                            || event.type === DeclarationEventTypes.FailedInternalValidation  
                                            || event.type === DeclarationEventTypes.FailedToSend 
                                            || event.type === DeclarationEventTypes.FailedToCheck 
                                            || event.type === DeclarationEventTypes.SentToVecozo
                                            || event.type === DeclarationEventTypes.Rejected
                                            || event.type === DeclarationEventTypes.Archived
                                            || event.type === DeclarationEventTypes.Accepted
                                            || event.type === DeclarationEventTypes.PartiallyAccepted
                                            || event.type === DeclarationEventTypes.FailedToDownload
                                            || event.type === DeclarationEventTypes.DownloadedAdditionalInformation
                                            || event.type === DeclarationEventTypes.DownloadedForInfomedics">
                                            <div
                                                if.bind="event.type === DeclarationEventTypes.RejectedByVecozo 
                                                || event.type === DeclarationEventTypes.FailedInternalValidation  
                                                || event.type === DeclarationEventTypes.FailedToCheck
                                                || event.type === DeclarationEventTypes.FailedToSend
                                                || event.type === DeclarationEventTypes.FailedToDownload
                                                || event.type === DeclarationEventTypes.Rejected"
                                                class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-red-500">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div
                                                if.bind="event.type === DeclarationEventTypes.SentToVecozo 
                                                || event.type === DeclarationEventTypes.DownloadedAdditionalInformation 
                                                || event.type === DeclarationEventTypes.DownloadedForInfomedics"
                                                class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg
                                                    rotate-180.class="event.type === DeclarationEventTypes.DownloadedAdditionalInformation"
                                                    rotate-90.class="event.type === DeclarationEventTypes.DownloadedForInfomedics"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    class="w-6 h-6 text-primary">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div if.bind="event.type === DeclarationEventTypes.Archived" class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-gray-600">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6-2.438c0-.724.588-1.312 1.313-1.312h4.874c.725 0 1.313.588 1.313 1.313v4.874c0 .725-.588 1.313-1.313 1.313H9.564a1.312 1.312 0 0 1-1.313-1.313V9.564Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div if.bind="event.type === DeclarationEventTypes.Accepted" class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-green-500">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div if.bind="event.type === DeclarationEventTypes.PartiallyAccepted" class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-yellow-500">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </template>
                                        <div else class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                        </div>
                                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span class="font-medium text-gray-900">${event.createdBy.name}</span>
                                            <span if.bind="event.type === DeclarationEventTypes.SentToVecozo" t="translation:partial-views.declarations.messages.sent-to-vecozo"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.FailedInternalValidation && declaration.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.failed-internal-validation-vecozo"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.FailedInternalValidation && declaration.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.failed-internal-validation-download"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.FailedToConfirm" t="translation:partial-views.declarations.messages.failed-to-confirm"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.RejectedByVecozo" t="translation:partial-views.declarations.messages.rejected-by-vecozo"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.FailedToSend" t="translation:partial-views.declarations.messages.failed-to-send"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.FailedToCheck" t="translation:partial-views.declarations.messages.failed-to-check"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Rejected && declaration.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.rejected"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Rejected && declaration.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.rejected-infomedics"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.Archived" t="translation:partial-views.declarations.messages.archived"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Accepted && declaration.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.accepted"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Accepted && declaration.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.accepted-infomedics"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.PartiallyAccepted && declaration.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.partially-accepted"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.PartiallyAccepted && declaration.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declarations.messages.partially-accepted-infomedics"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.FailedToDownload" t="translation:partial-views.declarations.messages.failed-to-download"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.DownloadedAdditionalInformation"
                                                t="translation:partial-views.declarations.messages.downloaded-additional-information"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.DownloadedForInfomedics"
                                                t="translation:partial-views.declarations.messages.downloaded-for-infomedics"></span>
                                        </p>
                                        <ux-tooltip content="${event.createdAt | date:'dd-MM-yyyy HH:mm'}">
                                            <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${event.createdAt | timeAgo:false:false:true}</time>
                                        </ux-tooltip>
                                    </div>
                                    <div class="flex flex-col gap-2 pl-10 mt-2">
                                        <div if.bind="event.errorMessage" class="py-4 rounded-lg bg-gray-50">
                                            <a href="javascript:" click.trigger="toggleEventMessage('error', $index)" class="flex justify-between px-6 font-semibold hover:text-primary">
                                                <span class="text-xs" t="translation:global.labels.error"></span>
                                                <svg
                                                    if.bind="viewState.events[$index].error"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                </svg>
                                                <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                            </a>
                                            <div class="px-6 mt-2 overflow-hidden" show.bind="viewState.events[$index].error">
                                                <div class="flex flex-col gap-2 overflow-x-auto rounded-lg bg-[#2d2d2d] p-3 text-xs text-[#ccc]">
                                                    <p>${event.errorMessage}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div if.bind="event.messages.length > 0" class="flex flex-col gap-2">
                                            <div repeat.for="message of event.messages" class="flex flex-col px-6 py-3 rounded-lg bg-gray-50">
                                                <p if.bind="event.messages.length > 1" class="pt-1 text-gray-400 text-xxs">
                                                    <span t="translation:global.labels.message"></span>
                                                    <span>#${$index + 1}</span>
                                                </p>
                                                <div class="flex flex-col divide-y-1">
                                                    <div if.bind="message.xmlBody" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('container', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.container"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].container"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div show.bind="viewState.events[$parent.$index].messages[$index].container">
                                                            <ux-highlight language="xml">${message.xmlBody} </ux-highlight>
                                                        </div>
                                                    </div>
                                                    <div if.bind="message.xmlPayload" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('message', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.message"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].message"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div show.bind="viewState.events[$parent.$index].messages[$index].message">
                                                            <ux-highlight language="xml">${message.xmlPayload}</ux-highlight>
                                                        </div>
                                                    </div>
                                                    <div if.bind="message.fault" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('error', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.error"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].error"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div class="mt-2" show.bind="viewState.events[$parent.$index].messages[$index].error">
                                                            <div class="flex flex-col gap-2 overflow-x-auto rounded-lg bg-[#2d2d2d] p-3 text-xs text-[#ccc]">
                                                                <p>[${message.fault.body.fault.code.value}] ${message.fault.body.fault.reason.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div if.bind="message.notifications.length > 0" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('notification', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.notifications"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].notification"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div class="flex flex-col gap-1 mt-2 overflow-hidden" show.bind="viewState.events[$parent.$index].messages[$index].notification">
                                                            <div
                                                                repeat.for="notification of message.notifications"
                                                                class="flex flex-col gap-1 overflow-x-auto rounded-lg bg-[#2d2d2d] p-3 text-xs text-[#ccc]">
                                                                <p>
                                                                    <span>[${notification.code}] ${notification.description} </span>
                                                                    <span if.bind="notification.explanation">(${notification.explanation})</span>
                                                                </p>
                                                                <div if.bind="notification.bsn || notification.performance" class="flex flex-col">
                                                                    <div if.bind="notification.bsn" class="flex items-center justify-between">
                                                                        <p class="text-gray-400" t="translation:global.labels.citizens-service-number"></p>
                                                                        <p class="text-gray-300">${notification.bsn}</p>
                                                                    </div>
                                                                    <div if.bind="notification.performance" class="flex items-center justify-between">
                                                                        <p class="text-gray-400" t="translation:global.labels.performance"></p>
                                                                        <p class="text-gray-300">${notification.performance}</p>
                                                                    </div>
                                                                    <div if.bind="notification.elements && notification.elements.length > 0" class="flex flex-col mt-2">
                                                                        <p t="translation:global.labels.elements"></p>
                                                                        <div repeat.for="element of notification.elements" class="flex items-center justify-between">
                                                                            <p class="text-gray-400">${element.name}</p>
                                                                            <p class="text-gray-300">${element.value}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-8 pt-8">
                    <div class="flex justify-between pb-3 border-b border-gray-200">
                        <p class="text-xl font-medium text-gray-800" t="translation:partial-views.declarations.edit.title"></p>
                    </div>
                    <div class="grid grid-cols-1 gap-5 mt-5">
                        <div class="flex flex-col flex-1 space-y-3">
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </template>
            <ux-footer if.bind="baseLoaded">
                <div class="flex justify-end w-full gap-x-2">
                    <div class="flex justify-end flex-1 gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || isSending || isChecking || isDownloading" click.trigger="close()">
                            <span class="pointer-events-none" t="global.buttons.close"></span>
                        </ux-button>
                        <div if.bind="hasRole(UserRoles.WriteDeclarations)" class="flex">
                            <template if.bind="declaration.sendMethod === DeclarationSendMethods.Vecozo">
                                <ux-button
                                    if.bind="declaration.status === DeclarationStatuses.Draft || declaration.status === DeclarationStatuses.FailedToSend"
                                    loading.bind="isSending"
                                    size="sm"
                                    color="success"
                                    disabled.bind="isLoading || isSending || isChecking"
                                    click.trigger="send()">
                                    <span class="pointer-events-none" t="global.buttons.send"></span>
                                </ux-button>
                                <ux-button
                                    if.bind="declaration.status === DeclarationStatuses.SentToVecozo
                                     || declaration.status === DeclarationStatuses.FailedToCheck
                                     || declaration.status === DeclarationStatuses.RejectedByVecozo
                                     || declaration.status === DeclarationStatuses.PartiallyAccepted
                                     || declaration.status === DeclarationStatuses.Accepted"
                                    loading.bind="isChecking"
                                    size="sm"
                                    color="primary"
                                    disabled.bind="isLoading || isSending || isChecking"
                                    click.trigger="checkStatus()">
                                    <span class="pointer-events-none" t="translation:global.labels.check-status"></span>
                                </ux-button>
                            </template>
                            <template if.bind="declaration.sendMethod === DeclarationSendMethods.InfomedicsDirect || declaration.sendMethod === DeclarationSendMethods.InfomedicsInsurance">
                                <ux-button
                                    if.bind="declaration.status === DeclarationStatuses.Draft || declaration.status === DeclarationStatuses.FailedToDownload"
                                    loading.bind="isDownloading"
                                    size="sm"
                                    color="primary"
                                    disabled.bind="isLoading || isSending || isChecking || isDownloading"
                                    click.trigger="download()">
                                    <span class="pointer-events-none" t="global.buttons.download"></span>
                                </ux-button>
                                <ux-button
                                    if.bind="declaration.status === DeclarationStatuses.DownloadedForInfomedics"
                                    size="sm"
                                    color="primary"
                                    disabled.bind="isLoading || isSending || isChecking || isDownloading"
                                    click.trigger="process()">
                                    <span class="pointer-events-none" t="translation:global.buttons.conclude"></span>
                                </ux-button>
                            </template>
                        </div>
                    </div>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
