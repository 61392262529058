import { I18N } from '@aurelia/i18n';
import { GetPatientResponse } from '@wecore/sdk-healthcare';
import { bindable, containerless, inject } from 'aurelia';

@containerless
@inject(I18N)
export class TabPatientsMedical {
    @bindable() public patient: GetPatientResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
    @bindable() public currentUser: GetPatientResponse;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public constructor(
        public t: I18N //
    ) {}
}
