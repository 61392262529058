<import from="converters/translate" />

<ux-combobox
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    show-footer.bind="true"
    set-value-on-select.bind="setValueOnSelect"
    onselect.trigger="handleSelect($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="type of types" value.bind="type.id">
        <div class="flex items-center gap-2">
            <div class="h-3 w-3 rounded-full" css="background-color: ${type.color.background}"></div>
            <span>${ type.name | translate:language }</span>
        </div>
    </ux-combobox-option>
</ux-combobox>
