export class Countries {
    public static getAll(): any[] {
        return [
            {
                name: 'Afghanistan',
                code: 'AF',
                callingCode: '93'
            },
            {
                name: 'Åland',
                code: 'AX',
                callingCode: '358'
            },
            {
                name: 'Albanië',
                code: 'AL',
                callingCode: '355'
            },
            {
                name: 'Algerije',
                code: 'DZ',
                callingCode: '213'
            },
            {
                name: 'Amerikaanse Maagdeneilanden',
                code: 'VI',
                callingCode: '1-340'
            },
            {
                name: 'Amerikaans-Samoa',
                code: 'AS',
                callingCode: '1-684'
            },
            {
                name: 'Andorra',
                code: 'AD',
                callingCode: '376'
            },
            {
                name: 'Angola',
                code: 'AO',
                callingCode: '244'
            },
            {
                name: 'Anguilla',
                code: 'AI',
                callingCode: '1-264'
            },
            {
                name: 'Antarctica',
                code: 'AQ',
                callingCode: '672'
            },
            {
                name: 'Antigua en Barbuda',
                code: 'AG',
                callingCode: '1-268'
            },
            {
                name: 'Argentinië',
                code: 'AR',
                callingCode: '54'
            },
            {
                name: 'Armenië',
                code: 'AM',
                callingCode: '374'
            },
            {
                name: 'Aruba',
                code: 'AW',
                callingCode: '297'
            },
            {
                name: 'Australië',
                code: 'AU',
                callingCode: '61'
            },
            {
                name: 'Azerbeidzjan',
                code: 'AZ',
                callingCode: '994'
            },
            {
                name: "Bahama's",
                code: 'BS',
                callingCode: '1-242'
            },
            {
                name: 'Bahrein',
                code: 'BH',
                callingCode: '973'
            },
            {
                name: 'Bangladesh',
                code: 'BD',
                callingCode: '880'
            },
            {
                name: 'Barbados',
                code: 'BB',
                callingCode: '1-246'
            },
            {
                name: 'België',
                code: 'BE',
                callingCode: '32'
            },
            {
                name: 'Belize',
                code: 'BZ',
                callingCode: '501'
            },
            {
                name: 'Benin',
                code: 'BJ',
                callingCode: '229'
            },
            {
                name: 'Bermuda',
                code: 'BM',
                callingCode: '1-441'
            },
            {
                name: 'Bhutan',
                code: 'BT',
                callingCode: '975'
            },
            {
                name: 'Bolivia',
                code: 'BO',
                callingCode: '591'
            },
            {
                name: 'Bonaire, Sint Eustatius en Saba',
                code: 'BQ',
                callingCode: '599'
            },
            {
                name: 'Bosnië en Herzegovina',
                code: 'BA',
                callingCode: '387'
            },
            {
                name: 'Botswana',
                code: 'BW',
                callingCode: '267'
            },
            {
                name: 'Bouvet',
                code: 'BV',
                callingCode: '47'
            },
            {
                name: 'Brazilië',
                code: 'BR',
                callingCode: '55'
            },
            {
                name: 'Britse Maagdeneilanden',
                code: 'VG',
                callingCode: '1-284'
            },
            {
                name: 'Brits Indische Oceaanterritorium',
                code: 'IO',
                callingCode: '246'
            },
            {
                name: 'Brunei',
                code: 'BN',
                callingCode: '673'
            },
            {
                name: 'Bulgarije',
                code: 'BG',
                callingCode: '359'
            },
            {
                name: 'Burkina Faso',
                code: 'BF',
                callingCode: '226'
            },
            {
                name: 'Burundi',
                code: 'BI',
                callingCode: '257'
            },
            {
                name: 'Cambodja',
                code: 'KH',
                callingCode: '855'
            },
            {
                name: 'Canada',
                code: 'CA',
                callingCode: '1'
            },
            {
                name: 'Centraal-Afrikaanse Republiek',
                code: 'CF',
                callingCode: '236'
            },
            {
                name: 'Chili',
                code: 'CL',
                callingCode: '56'
            },
            {
                name: 'China',
                code: 'CN',
                callingCode: '86'
            },
            {
                name: 'Christmaseiland',
                code: 'CX',
                callingCode: '61'
            },
            {
                name: 'Cocoseilanden',
                code: 'CC',
                callingCode: '61'
            },
            {
                name: 'Colombia',
                code: 'CO',
                callingCode: '57'
            },
            {
                name: 'Comoren',
                code: 'KM',
                callingCode: '269'
            },
            {
                name: 'Congo-Brazzaville',
                code: 'CG',
                callingCode: '242'
            },
            {
                name: 'Congo-Kinshasa',
                code: 'CD',
                callingCode: '243'
            },
            {
                name: 'Cookeilanden',
                code: 'CK',
                callingCode: '682'
            },
            {
                name: 'Costa Rica',
                code: 'CR',
                callingCode: '506'
            },
            {
                name: 'Cuba',
                code: 'CU',
                callingCode: '53'
            },
            {
                name: 'Curaçao',
                code: 'CW',
                callingCode: '599'
            },
            {
                name: 'Cyprus',
                code: 'CY',
                callingCode: '357'
            },
            {
                name: 'Denemarken',
                code: 'DK',
                callingCode: '45'
            },
            {
                name: 'Djibouti',
                code: 'DJ',
                callingCode: '253'
            },
            {
                name: 'Dominica',
                code: 'DM',
                callingCode: '1-767'
            },
            {
                name: 'Dominicaanse Republiek',
                code: 'DO',
                callingCode: '1-809,1-829,1-849'
            },
            {
                name: 'Duitsland',
                code: 'DE',
                callingCode: '49'
            },
            {
                name: 'Ecuador',
                code: 'EC',
                callingCode: '593'
            },
            {
                name: 'Egypte',
                code: 'EG',
                callingCode: '20'
            },
            {
                name: 'El Salvador',
                code: 'SV',
                callingCode: '503'
            },
            {
                name: 'Equatoriaal-Guinea',
                code: 'GQ',
                callingCode: '240'
            },
            {
                name: 'Eritrea',
                code: 'ER',
                callingCode: '291'
            },
            {
                name: 'Estland',
                code: 'EE',
                callingCode: '372'
            },
            {
                name: 'Ethiopië',
                code: 'ET',
                callingCode: '251'
            },
            {
                name: 'Faeröer',
                code: 'FO',
                callingCode: '298'
            },
            {
                name: 'Falklandeilanden',
                code: 'FK',
                callingCode: '500'
            },
            {
                name: 'Fiji',
                code: 'FJ',
                callingCode: '679'
            },
            {
                name: 'Filipijnen',
                code: 'PH',
                callingCode: '63'
            },
            {
                name: 'Finland',
                code: 'FI',
                callingCode: '358'
            },
            {
                name: 'Frankrijk',
                code: 'FR',
                callingCode: '33'
            },
            {
                name: 'Franse Zuidelijke en Antarctische Gebiede',
                code: 'TF',
                callingCode: '262'
            },
            {
                name: 'Frans-Guyana',
                code: 'GF',
                callingCode: '594'
            },
            {
                name: 'Frans-Polynesië',
                code: 'PF',
                callingCode: '689'
            },
            {
                name: 'Gabon',
                code: 'GA',
                callingCode: '241'
            },
            {
                name: 'Gambia',
                code: 'GM',
                callingCode: '220'
            },
            {
                name: 'Georgië',
                code: 'GE',
                callingCode: '995'
            },
            {
                name: 'Ghana',
                code: 'GH',
                callingCode: '233'
            },
            {
                name: 'Gibraltar',
                code: 'GI',
                callingCode: '350'
            },
            {
                name: 'Grenada',
                code: 'GD',
                callingCode: '1-473'
            },
            {
                name: 'Griekenland',
                code: 'GR',
                callingCode: '30'
            },
            {
                name: 'Groenland',
                code: 'GL',
                callingCode: '299'
            },
            {
                name: 'Guadeloupe',
                code: 'GP',
                callingCode: '590'
            },
            {
                name: 'Guam',
                code: 'GU',
                callingCode: '1-671'
            },
            {
                name: 'Guatemala',
                code: 'GT',
                callingCode: '502'
            },
            {
                name: 'Guernsey',
                code: 'GG',
                callingCode: '44'
            },
            {
                name: 'Guinee',
                code: 'GN',
                callingCode: '224'
            },
            {
                name: 'Guinee-Bissau',
                code: 'GW',
                callingCode: '245'
            },
            {
                name: 'Guyana',
                code: 'GY',
                callingCode: '592'
            },
            {
                name: 'Haïti',
                code: 'HT',
                callingCode: '509'
            },
            {
                name: 'Heard en McDonaldeilanden',
                code: 'HM',
                callingCode: '672'
            },
            {
                name: 'Honduras',
                code: 'HN',
                callingCode: '504'
            },
            {
                name: 'Hongarije',
                code: 'HU',
                callingCode: '36'
            },
            {
                name: 'Hongkong',
                code: 'HK',
                callingCode: '852'
            },
            {
                name: 'Ierland',
                code: 'IE',
                callingCode: '353'
            },
            {
                name: 'IJsland',
                code: 'IS',
                callingCode: '354'
            },
            {
                name: 'India',
                code: 'IN',
                callingCode: '91'
            },
            {
                name: 'Indonesië',
                code: 'ID',
                callingCode: '62'
            },
            {
                name: 'Irak',
                code: 'IQ',
                callingCode: '964'
            },
            {
                name: 'Iran',
                code: 'IR',
                callingCode: '98'
            },
            {
                name: 'Israël',
                code: 'IL',
                callingCode: '972'
            },
            {
                name: 'Italië',
                code: 'IT',
                callingCode: '39'
            },
            {
                name: 'Ivoorkust',
                code: 'CI',
                callingCode: '225'
            },
            {
                name: 'Jamaica',
                code: 'JM',
                callingCode: '1-876'
            },
            {
                name: 'Japan',
                code: 'JP',
                callingCode: '81'
            },
            {
                name: 'Jemen',
                code: 'YE',
                callingCode: '967'
            },
            {
                name: 'Jersey',
                code: 'JE',
                callingCode: '44'
            },
            {
                name: 'Jordanië',
                code: 'JO',
                callingCode: '962'
            },
            {
                name: 'Kaaimaneilanden',
                code: 'KY',
                callingCode: '1-345'
            },
            {
                name: 'Kaapverdië',
                code: 'CV',
                callingCode: '238'
            },
            {
                name: 'Kameroen',
                code: 'CM',
                callingCode: '237'
            },
            {
                name: 'Kazachstan',
                code: 'KZ',
                callingCode: '7'
            },
            {
                name: 'Kenia',
                code: 'KE',
                callingCode: '254'
            },
            {
                name: 'Kirgizië',
                code: 'KG',
                callingCode: '996'
            },
            {
                name: 'Kiribati',
                code: 'KI',
                callingCode: '868'
            },
            {
                name: 'Kleine afgelegen eilanden van de Verenigde Staten',
                code: 'UM',
                callingCode: '1'
            },
            {
                name: 'Koeweit',
                code: 'KW',
                callingCode: '965'
            },
            {
                name: 'Kosovo',
                code: 'XK',
                callingCode: '383'
            },
            {
                name: 'Kroatië',
                code: 'HR',
                callingCode: '385'
            },
            {
                name: 'Laos',
                code: 'LA',
                callingCode: '856'
            },
            {
                name: 'Lesotho',
                code: 'LS',
                callingCode: '266'
            },
            {
                name: 'Letland',
                code: 'LV',
                callingCode: '371'
            },
            {
                name: 'Libanon',
                code: 'LB',
                callingCode: '961'
            },
            {
                name: 'Liberia',
                code: 'LR',
                callingCode: '231'
            },
            {
                name: 'Libië',
                code: 'LY',
                callingCode: '218'
            },
            {
                name: 'Liechtenstein',
                code: 'LI',
                callingCode: '423'
            },
            {
                name: 'Litouwen',
                code: 'LT',
                callingCode: '370'
            },
            {
                name: 'Luxemburg',
                code: 'LU',
                callingCode: '352'
            },
            {
                name: 'Macau',
                code: 'MO',
                callingCode: '853'
            },
            {
                name: 'Macedonië',
                code: 'MK',
                callingCode: '389'
            },
            {
                name: 'Madagaskar',
                code: 'MG',
                callingCode: '261'
            },
            {
                name: 'Malawi',
                code: 'MW',
                callingCode: '265'
            },
            {
                name: 'Maldiven',
                code: 'MV',
                callingCode: '960'
            },
            {
                name: 'Maleisië',
                code: 'MY',
                callingCode: '60'
            },
            {
                name: 'Mali',
                code: 'ML',
                callingCode: '223'
            },
            {
                name: 'Malta',
                code: 'MT',
                callingCode: '356'
            },
            {
                name: 'Man',
                code: 'IM',
                callingCode: '44'
            },
            {
                name: 'Marokko',
                code: 'MA',
                callingCode: '212'
            },
            {
                name: 'Marshalleilanden',
                code: 'MH',
                callingCode: '692'
            },
            {
                name: 'Martinique',
                code: 'MQ',
                callingCode: '596'
            },
            {
                name: 'Mauritanië',
                code: 'MR',
                callingCode: '222'
            },
            {
                name: 'Mauritius',
                code: 'MU',
                callingCode: '230'
            },
            {
                name: 'Mayotte',
                code: 'YT',
                callingCode: '269'
            },
            {
                name: 'Mexico',
                code: 'MX',
                callingCode: '52'
            },
            {
                name: 'Micronesia',
                code: 'FM',
                callingCode: '691'
            },
            {
                name: 'Moldavië',
                code: 'MD',
                callingCode: '373'
            },
            {
                name: 'Monaco',
                code: 'MC',
                callingCode: '377'
            },
            {
                name: 'Mongolië',
                code: 'MN',
                callingCode: '976'
            },
            {
                name: 'Montenegro',
                code: 'ME',
                callingCode: '382'
            },
            {
                name: 'Montserrat',
                code: 'MS',
                callingCode: '1-164'
            },
            {
                name: 'Mozambique',
                code: 'MZ',
                callingCode: '258'
            },
            {
                name: 'Myanmar',
                code: 'MM',
                callingCode: '95'
            },
            {
                name: 'Namibië',
                code: 'NA',
                callingCode: '264'
            },
            {
                name: 'Nauru',
                code: 'NR',
                callingCode: '674'
            },
            {
                name: 'Nederland',
                code: 'NL',
                callingCode: '31'
            },
            {
                name: 'Nepal',
                code: 'NP',
                callingCode: '977'
            },
            {
                name: 'Nicaragua',
                code: 'NI',
                callingCode: '505'
            },
            {
                name: 'Nieuw-Caledonië',
                code: 'NC',
                callingCode: '687'
            },
            {
                name: 'Nieuw-Zeeland',
                code: 'NZ',
                callingCode: '64'
            },
            {
                name: 'Niger',
                code: 'NE',
                callingCode: '227'
            },
            {
                name: 'Nigeria',
                code: 'NG',
                callingCode: '234'
            },
            {
                name: 'Niue',
                code: 'NU',
                callingCode: '683'
            },
            {
                name: 'Noordelijke Marianen',
                code: 'MP',
                callingCode: '1-670'
            },
            {
                name: 'Noord-Korea',
                code: 'KP',
                callingCode: '850'
            },
            {
                name: 'Noorwegen',
                code: 'NO',
                callingCode: '47'
            },
            {
                name: 'Norfolk',
                code: 'NF',
                callingCode: '672'
            },
            {
                name: 'Oeganda',
                code: 'UG',
                callingCode: '256'
            },
            {
                name: 'Oekraïne',
                code: 'UA',
                callingCode: '380'
            },
            {
                name: 'Oezbekistan',
                code: 'UZ',
                callingCode: '998'
            },
            {
                name: 'Oman',
                code: 'OM',
                callingCode: '968'
            },
            {
                name: 'Oostenrijk',
                code: 'AT',
                callingCode: '43'
            },
            {
                name: 'Oost-Timor',
                code: 'TL',
                callingCode: '670'
            },
            {
                name: 'Pakistan',
                code: 'PK',
                callingCode: '92'
            },
            {
                name: 'Palau',
                code: 'PW',
                callingCode: '680'
            },
            {
                name: 'Palestina',
                code: 'PS',
                callingCode: '970'
            },
            {
                name: 'Panama',
                code: 'PA',
                callingCode: '507'
            },
            {
                name: 'Papua-Nieuw-Guinea',
                code: 'PG',
                callingCode: '675'
            },
            {
                name: 'Paraguay',
                code: 'PY',
                callingCode: '595'
            },
            {
                name: 'Peru',
                code: 'PE',
                callingCode: '51'
            },
            {
                name: 'Pitcairneilanden',
                code: 'PN',
                callingCode: '649'
            },
            {
                name: 'Polen',
                code: 'PL',
                callingCode: '48'
            },
            {
                name: 'Portugal',
                code: 'PT',
                callingCode: '351'
            },
            {
                name: 'Puerto Rico',
                code: 'PR',
                callingCode: '1-787,1-939'
            },
            {
                name: 'Qatar',
                code: 'QA',
                callingCode: '974'
            },
            {
                name: 'Réunion',
                code: 'RE',
                callingCode: '262'
            },
            {
                name: 'Roemenië',
                code: 'RO',
                callingCode: '40'
            },
            {
                name: 'Rusland',
                code: 'RU',
                callingCode: '7'
            },
            {
                name: 'Rwanda',
                code: 'RW',
                callingCode: '250'
            },
            {
                name: 'Saint-Barthélemy',
                code: 'BL',
                callingCode: '590'
            },
            {
                name: 'Saint Kitts en Nevis',
                code: 'KN',
                callingCode: '1-869'
            },
            {
                name: 'Saint Lucia',
                code: 'LC',
                callingCode: '1-758'
            },
            {
                name: 'Saint-Pierre en Miquelon',
                code: 'PM',
                callingCode: '508'
            },
            {
                name: 'Saint Vincent en de Grenadines',
                code: 'VC',
                callingCode: '1-784'
            },
            {
                name: 'Salomonseilanden',
                code: 'SB',
                callingCode: '677'
            },
            {
                name: 'Samoa',
                code: 'WS',
                callingCode: '685'
            },
            {
                name: 'San Marino',
                code: 'SM',
                callingCode: '378'
            },
            {
                name: 'Saoedi-Arabië',
                code: 'SA',
                callingCode: '966'
            },
            {
                name: 'Sao Tomé en Principe',
                code: 'ST',
                callingCode: '239'
            },
            {
                name: 'Senegal',
                code: 'SN',
                callingCode: '221'
            },
            {
                name: 'Servië',
                code: 'RS',
                callingCode: '381'
            },
            {
                name: 'Seychellen',
                code: 'SC',
                callingCode: '248'
            },
            {
                name: 'Sierra Leone',
                code: 'SL',
                callingCode: '232'
            },
            {
                name: 'Singapore',
                code: 'SG',
                callingCode: '65'
            },
            {
                name: 'Sint-Helena, Ascension en Tristan da Cunha',
                code: 'SH',
                callingCode: '290'
            },
            {
                name: 'Sint-Maarten',
                code: 'MF',
                callingCode: '590'
            },
            {
                name: 'Sint Maarten',
                code: 'SX',
                callingCode: '1-721'
            },
            {
                name: 'Slovenië',
                code: 'SI',
                callingCode: '386'
            },
            {
                name: 'Slowakije',
                code: 'SK',
                callingCode: '421'
            },
            {
                name: 'Soedan',
                code: 'SD',
                callingCode: '249'
            },
            {
                name: 'Somalië',
                code: 'SO',
                callingCode: '252'
            },
            {
                name: 'Spanje',
                code: 'ES',
                callingCode: '34'
            },
            {
                name: 'Spitsbergen en Jan Mayen',
                code: 'SJ',
                callingCode: '47'
            },
            {
                name: 'Sri Lanka',
                code: 'LK',
                callingCode: '94'
            },
            {
                name: 'Suriname',
                code: 'SR',
                callingCode: '597'
            },
            {
                name: 'Swaziland',
                code: 'SZ',
                callingCode: '268'
            },
            {
                name: 'Syrië',
                code: 'SY',
                callingCode: '963'
            },
            {
                name: 'Tadzjikistan',
                code: 'TJ',
                callingCode: '992'
            },
            {
                name: 'Taiwan',
                code: 'TW',
                callingCode: '886'
            },
            {
                name: 'Tanzania',
                code: 'TZ',
                callingCode: '255'
            },
            {
                name: 'Thailand',
                code: 'TH',
                callingCode: '66'
            },
            {
                name: 'Togo',
                code: 'TG',
                callingCode: '288'
            },
            {
                name: 'Tokelau',
                code: 'TK',
                callingCode: '690'
            },
            {
                name: 'Tonga',
                code: 'TO',
                callingCode: '676'
            },
            {
                name: 'Trinidad en Tobago',
                code: 'TT',
                callingCode: '1-868'
            },
            {
                name: 'Tsjaad',
                code: 'TD',
                callingCode: '235'
            },
            {
                name: 'Tsjechië',
                code: 'CZ',
                callingCode: '420'
            },
            {
                name: 'Tunesië',
                code: 'TN',
                callingCode: '216'
            },
            {
                name: 'Turkije',
                code: 'TR',
                callingCode: '90'
            },
            {
                name: 'Turkmenistan',
                code: 'TM',
                callingCode: '993'
            },
            {
                name: 'Turks- en Caicoseilanden',
                code: 'TC',
                callingCode: '1-649'
            },
            {
                name: 'Tuvalu',
                code: 'TV',
                callingCode: '688'
            },
            {
                name: 'Uruguay',
                code: 'UY',
                callingCode: '598'
            },
            {
                name: 'Vanuatu',
                code: 'VU',
                callingCode: '678'
            },
            {
                name: 'Vaticaanstad',
                code: 'VA',
                callingCode: '379'
            },
            {
                name: 'Venezuela',
                code: 'VE',
                callingCode: '58'
            },
            {
                name: 'Verenigde Arabische Emiraten',
                code: 'AE',
                callingCode: '971'
            },
            {
                name: 'Verenigde Staten',
                code: 'US',
                callingCode: '1'
            },
            {
                name: 'Verenigd Koninkrijk',
                code: 'GB',
                callingCode: '44'
            },
            {
                name: 'Vietnam',
                code: 'VN',
                callingCode: '84'
            },
            {
                name: 'Wallis en Futuna',
                code: 'WF',
                callingCode: '681'
            },
            {
                name: 'Westelijke Sahara',
                code: 'EH',
                callingCode: '212'
            },
            {
                name: 'Wit-Rusland',
                code: 'BY',
                callingCode: '375'
            },
            {
                name: 'Zambia',
                code: 'ZM',
                callingCode: '260'
            },
            {
                name: 'Zimbabwe',
                code: 'ZW',
                callingCode: '263'
            },
            {
                name: 'Zuid-Afrika',
                code: 'ZA',
                callingCode: '27'
            },
            {
                name: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
                code: 'GS',
                callingCode: '500'
            },
            {
                name: 'Zuid-Korea',
                code: 'KR',
                callingCode: '82'
            },
            {
                name: 'Zuid-Soedan',
                code: 'SS',
                callingCode: '211'
            },
            {
                name: 'Zweden',
                code: 'SE',
                callingCode: '46'
            },
            {
                name: 'Zwitserland',
                code: 'CH',
                callingCode: '41'
            }
        ];
    }
}
