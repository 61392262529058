import { I18N } from '@aurelia/i18n';
import { GetMedicalRecordRegistrationResponse, GetMedicalRecordResponse, MedicalRecordResultReference, MedicalTherapyPlanItem, MedicalTherapyPlanItemTypes } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';

import { bindable, containerless, inject } from 'aurelia';
import { SideToStringValueConverter } from '../../../../../../../converters/side-to-string';
import { TranslateValueConverter } from '../../../../../../../converters/translate';
import { EventDetails } from '../../../../../../../models/event-details';
import { FlattenedExaminationStep } from '../../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../../models/selected-file';
import { StepState } from '../../../../../../../models/step-state';
import { UxInput } from '../../../../../../../ux/ux-input/ux-input';

@containerless
@inject(I18N)
export class TemplateTreatmentPlanItem {
    @bindable() public recordFlattened: FlattenedExaminationStep[];
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public xScrollContainer: string;
    @bindable() public validation: any;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public state: StepState;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public item: GetMedicalRecordRegistrationResponse;
    @bindable() public selection: GetMedicalRecordRegistrationResponse[];
    @bindable() public planItem: GetMedicalRecordRegistrationResponse;
    @bindable() public onDelete: (item: GetMedicalRecordRegistrationResponse) => void;
    @bindable() public onDuplicate: (item: GetMedicalRecordRegistrationResponse) => void;
    @bindable() public onConnect: (item: GetMedicalRecordRegistrationResponse) => void;
    @bindable() public onSelected: () => void;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;

    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    public editLabelMode: boolean = false;
    public validationIndex: any;

    public constructor(
        private t: I18N //
    ) {}

    public bound(): void {
        this.validationIndex = this.validation.plans.findIndex((x: any) => x.id === this.item.id);
        // Make sure the default values are set for each step when loading.
        // If you do this on the step itself, it will only be set when the step is opened.
        // Then when merging, the default values are not merged properly.
        const setDefaultValues = (items: MedicalTherapyPlanItem[]) => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category) setDefaultValues(item.category.stepsToTake);
                else {
                    const defaultValues = this.registration.therapyPlan.defaultValues.find((x) => x.key === item.copiedFrom);
                    if (isDefined(defaultValues)) {
                        // Only overwrite if the current step has no value.
                        if (isDefined(defaultValues.value.result) && isNotDefined(item.step.result.value)) item.step.result = defaultValues.value.result;
                        // Only overwrite if the current step has no values.
                        if (isDefined(defaultValues.value.results) && defaultValues.value.results.any() && item.step.results.empty()) item.step.results = defaultValues.value.results;
                    }
                }
            }
        };
        setDefaultValues(this.registration.therapyPlan.value.stepsToTake);
    }

    public collapseOrExpand(): void {
        this.state.expanded = !this.state.expanded;
    }

    public delete(): void {
        if (isFunction(this.onDelete)) this.onDelete(this.item);
    }

    public duplicate(): void {
        if (isFunction(this.onDuplicate)) this.onDuplicate(this.item);
    }

    public addLabel(): void {
        this.editLabelMode = true;
    }

    public handleItemChecked(): void {
        if (isFunction(this.onSelected)) this.onSelected();
    }

    public manageTranslationsFor(): void {
        this.manageTranslations(
            this.item.therapyPlan.label, //
            (translations: any) => {
                this.item.therapyPlan.label = translations;
                this.editLabelMode = false;
            },
            'input',
            false
        );
    }

    public connect(): void {
        if (isFunction(this.onConnect)) this.onConnect(this.item);
    }

    public async handleInputChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.editLabelMode = false;
    }

    public getExtra(item: MedicalRecordResultReference): string {
        const converter = new SideToStringValueConverter();
        const translator = new TranslateValueConverter();

        const result = this.record.results.find((x) => x.id === item.resultId);

        let returnValue = translator.toView(item.differentialDiagnosis.translations, this.language);

        if (isDefined(result.side) || isDefined(result.location)) {
            returnValue += ' (';

            if (isDefined(result.side)) returnValue += `${this.t.tr(converter.toView(result.side))}`;
            if (isDefined(result.side) && isDefined(result.location)) returnValue += ` + `;
            if (isDefined(result.location)) returnValue += `${translator.toView(result.location.translations, this.language)}`;

            returnValue += ')';
        }

        return returnValue;
    }
}
