import { MedicalRecordResultStatuses } from '@wecore/sdk-healthcare';

export class MedicalResultStatusToStringValueConverter {
    public toView(type: MedicalRecordResultStatuses): string {
        switch (type) {
            case MedicalRecordResultStatuses.WorkingDiagnosis:
                return 'translation:partial-views.clinical-pathways.labels.working-diagnosis';
            case MedicalRecordResultStatuses.Rejected:
                return 'translation:global.labels.rejected';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
