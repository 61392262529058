import { PageState, getPageState, isDefined, isFunction, isNotDefined, savePageState } from '@wecore/sdk-utilities';
// import { Subscription } from '@aurelia/store-v1/node_modules/rxjs';
import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { IEventAggregator } from 'aurelia';
import { PartialViewResults } from '../enums/partial-view-results';
import { PartialView } from '../models/partial-view';
import { View } from '../models/view';
import { ViewOptions } from '../models/view-options';
import { BaseComponent } from './base-component';
import { CacheService } from './cache-service';
import { ErrorHandler } from './error-handler';
import { PartialViews } from './partial-views';
import { State } from './store/state';
import { loadViewsFromAUrl } from './utilities';
import { ViewEvents } from './view-events';

export class BasePartialView extends BaseComponent {
    protected partial: PartialView;
    protected state: State;
    protected isLoading: boolean = false;
    protected deleting: boolean = false;
    protected PartialViews: typeof PartialViews = PartialViews;
    protected language: string;
    protected scrollContainer: HTMLDivElement;
    protected hasScrolled: boolean = false;
    protected subscriptions: any[] = [];
    protected viewWidth: number;
    protected pageState: PageState;
    protected constrainViewWidth: (width: number) => number;

    private styles: any;
    private stateSubscription: any;
    private resizeCb: (e: MouseEvent) => void;
    private resizeEndCb: (e: MouseEvent) => void;
    private isResizing: boolean = false;
    private initialMouseX: number;
    private initialParentWidth: number;

    public constructor(
        protected cache: CacheService, //
        protected errorHandler: ErrorHandler,
        protected events: IEventAggregator,
        protected store: Store<State>,
        protected t: I18N
    ) {
        super(cache, errorHandler);
    }

    protected async initView(): Promise<void> {
        await super.init();
        this.stateSubscription = this.store.state.subscribe((state) => {
            this.state = state;
            this.language = state.language;
        });

        // this.subscriptions.push(
        //     this.events.subscribe(
        //         CustomEvents.NavigationButtonPressed,
        //         (body: {
        //             toDelete: { partial: PartialView; updateUrl: boolean }[]; //
        //             toAdd: { partial: PartialView; data: any; options: ViewOptions; addedBy: string }[];
        //         }) => {
        //             for (const item of body.toDelete) {
        //                 if (item.partial.id !== this.partial.id) continue;
        //                 this.remove({ result: PartialViewResults.ByBackButton, updateUrl: item.updateUrl });
        //             }

        //             for (const item of body.toAdd) {
        //                 if (item.addedBy === this.partial.name)
        //                     this.addPartialView({
        //                         partial: item.partial.with(item.data),
        //                         view: this.partial.base,
        //                         options: item.options
        //                     });
        //             }
        //         }
        //     )
        // );
    }

    protected async handleBaseLoaded(): Promise<void> {
        setTimeout(() => {
            if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScrollEvent(e));
        });
    }

    /**
     * Adds a new partial view to the views list.
     * @param partial The partial view to add.
     * @param options The optional view options.
     */
    protected async addPartialView(options: {
        view: View; //
        partial: PartialView;
        options: ViewOptions;
    }): Promise<void> {
        const defaults = { ...options };
        this.events.publish(ViewEvents.PartialsOpen, { partial: defaults.partial, addedBy: this.partial, options: defaults.options });
    }

    /**
     * Removes the current view.
     * @param result The result type to give to the parent view that created the current view.
     * @param data The optionally result data.
     * @param removeTheView A flag indiciating whether or not to delete the view from the view view engine.
     * Remarks: In a 'detached' function the view already gets removed automatically by aurelia
     * so only the subscription should be deleted here. (defaults to 'true')
     */
    protected async remove(options: {
        result: PartialViewResults; //
        data?: any;
        updateUrl?: boolean;
    }): Promise<void> {
        const defaults = {
            data: null,
            updateUrl: false,
            ...options
        };

        // If the view has a close callback, call it.
        if (isFunction(this.partial.handleWhenClosed)) void this.partial.handleWhenClosed(defaults.result, defaults.data);

        this.events.publish(ViewEvents.PartialsClose, this.partial);

        // Remove any subscriptions, if any.
        if (isDefined(this.stateSubscription)) this.stateSubscription.unsubscribe();
        this.subscriptions.forEach((x) => x.dispose());
    }

    /**
     * Loads one of more views from the URL. Every partial view is responsible
     * for opening their child views via the 'open' callback.
     * So every (child) view that is opened has to call this function
     * to open additional partial views (e.g. List > Create or List > Edit).
     * @param open A callback that proved the view that needs to be added and
     * optionally an entity ID that needs to be loaded.
     */
    protected async loadViewsFromUrl(options: {
        open: (viewToOpen: string, entityId: string) => Promise<void>; //
    }): Promise<void> {
        loadViewsFromAUrl(this.partial, options);
    }

    /**
     * Removes all previously added partial views.
     */
    protected async removeChildViews(): Promise<void> {
        this.events.publish(ViewEvents.PartialsChildrenRemove, this.partial);
    }

    /**
     * Scrolls to a specific partial view.
     */
    protected async scrollTo(partial: PartialView): Promise<void> {
        this.events.publish(ViewEvents.PartialsScrollTo, partial);
    }

    /**
     * Sets the styles for the current view.
     */
    protected changeStyle(newStyles: any): void {
        const styles = {
            ...this.styles,
            ...newStyles
        };

        this.styles = styles;
    }

    /**
     * Creates a language property for the translatable value and optionally adds a translation.
     * @param translations The translation object.
     */
    protected manageTranslations(options: {
        translations: any; //
        callback: (updatedTranslations: any) => void;
        required?: boolean;
        type?: 'input' | 'textarea';
    }): any {
        const defaults = {
            required: false,
            type: 'input',
            ...options
        };

        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.Translations.with({
                translations: defaults.translations, //
                required: defaults.required,
                type: defaults.type
            }) //
                .whenClosed(async (result: PartialViewResults, data: any) => {
                    if (result === PartialViewResults.Ok) defaults.callback(data);
                }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    protected async changeTo(view: PartialView, updateUrl: boolean = true): Promise<void> {
        if (isNotDefined(view)) return;

        // If the clicked view is already the current view, do nothing.
        // const currentView = this.state.viewEngine[this.partial.base.id].partials[this.partial.index + 1];
        // if (isDefined(currentView) && currentView.name === view.name) return;

        // Clear all previously added (child) partial views.
        await this.removeChildViews();

        // Add the new partial view.
        setTimeout(async () => {
            await this.addPartialView({
                view: this.partial.base,
                partial: view, //
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: false,
                    updateUrl: updateUrl
                })
            });
        });
    }

    protected handleResizeMouseDown(event: MouseEvent): void {
        if (!this.partial.canAdjustWidth) return;

        this.resizeCb = (e) => this.handleResize(e);
        this.resizeEndCb = (e) => this.handleResizeEnd(e);

        document.addEventListener('mousemove', this.resizeCb);
        document.addEventListener('mouseup', this.resizeEndCb, { once: true });
        this.isResizing = true;

        const { partial, parent } = this.getPartialAndParentElements();
        // Store the initial mouse position and parent width
        this.initialMouseX = event.clientX;
        this.initialParentWidth = parent.offsetWidth;
    }

    protected setWidth(width: number): void {
        this.pageState.values.width = width;
        savePageState(this.pageState);
        this.viewWidth = width;

        this.setStyles({
            view: this.partial
        });
    }

    /**
     * Sets the view data for the current view.
     * @param view The view (with data) to set.
     * @param custom A function that can transform the styles before being set.
     */
    protected setView(options: {
        view: PartialView; //
        custom?: (styles: any) => any;
    }): void {
        const defaults = {
            ...options
        };

        this.pageState =
            getPageState(options.view.name) ??
            new PageState({
                name: options.view.name,
                values: {
                    viewWidth: null
                }
            });

        this.viewWidth =
            isDefined(this.pageState.values.width) && defaults.view.canAdjustWidth //
                ? this.pageState.values.width
                : !isNaN(Number(defaults.view.width))
                  ? Number(defaults.view.width)
                  : Number(defaults.view.minWidth);

        this.pageState.values.width = this.viewWidth;
        savePageState(this.pageState);

        this.setStyles({
            view: defaults.view,
            custom: defaults.custom
        });
        this.partial = defaults.view;
    }

    private handleResize(e: MouseEvent): void {
        if (!this.isResizing) return;

        const { partial, parent } = this.getPartialAndParentElements();

        const deltaX = e.clientX - this.initialMouseX;
        let newWidth = this.initialParentWidth + deltaX;

        if (isFunction(this.constrainViewWidth)) newWidth = this.constrainViewWidth(newWidth);

        if (newWidth < this.partial.minWidth) newWidth = this.partial.minWidth;

        const setWidth = (el: HTMLElement, width: number) => {
            el.style.minWidth = `${width}px`;
            el.style.width = `${width}px`;
        };

        this.viewWidth = newWidth;
        this.pageState.values.width = newWidth;
        savePageState(this.pageState);
        setWidth(partial, newWidth);
        setWidth(parent, newWidth);
    }

    private handleResizeEnd(_: MouseEvent): void {
        this.isResizing = false;

        document.removeEventListener('mousemove', this.resizeCb);
        document.removeEventListener('mouseup', this.resizeEndCb);
    }

    private getPartialAndParentElements(): { partial: HTMLElement; parent: HTMLElement } {
        const partial = document.querySelector(`#${this.partial.name}-${this.partial.id}`) as HTMLElement;
        if (isNotDefined(partial)) return;

        const parent = partial.closest(`partial-${this.partial.name}`) as HTMLElement;
        if (isNotDefined(parent)) return;

        return { partial, parent };
    }

    /**
     * Handles the global scroll event of the scroll container.
     * @param event The scroll event.
     */
    private async handleScrollEvent(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;
    }

    /**
     * Sets the styles for the current view.
     * @param view The view to set the styles for.
     * @param custom A function that can transform the styles before being set.
     */
    private setStyles(options: {
        view: PartialView; //
        custom?: (styles: any) => any;
    }): void {
        const defaults = {
            ...options
        };

        this.styles = null;
        const styles = {
            width: `${this.pageState.values.width}px`,
            display: 'auto',
            minWidth: `${this.pageState.values.width}px`,
            maxWidth: `${this.pageState.values.width}px`
        };

        this.styles = isFunction(defaults.custom) ? defaults.custom(styles) : styles;
    }
}
