import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AppointmentsApiClient, EmailsApiClient, GetAppointmentResponse, GetHealthcareTaskResponse } from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { SchedulerBroadcaster } from '../../infra/broadcasts/scheduler-broadcaster';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AppointmentsApiClient, ModalService, SchedulerBroadcaster, EmailsApiClient)
export class PartialScheduler extends BasePartialView {
    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly appointmentsApi: AppointmentsApiClient,
        private readonly modalService: ModalService,
        private readonly schedulerBroadcaster: SchedulerBroadcaster
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
                this.schedulerBroadcaster.start(this.cache.workspace);
            })
            .catch((x) => this.errorHandler.handle('PartialScheduler.attached', x));
    }

    public detaching(): void {
        this.schedulerBroadcaster.stop();
    }

    public handleAppointmentEdit = async (appointment: GetAppointmentResponse): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.EditAppointment.with({ id: appointment.id }).whenClosed(async (result: PartialViewResults, updated: GetAppointmentResponse) => {
                    if (result === PartialViewResults.Ok) {
                        resolve({ success: true, appointment: updated });
                    } else resolve({ success: false, appointment: null });
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: true
                })
            });
        });
    };

    public handleAppointmentClick = async (appointment: GetAppointmentResponse): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return { success: true, appointment };
    };

    public handleAppointmentCreate = async (start: Date, end: Date, userId: string, roomId: string, locationId: string): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.CreateAppointment.with({ start, end, userId, roomId, locationId }).whenClosed(async (result: PartialViewResults, appointment: GetAppointmentResponse) => {
                    if (result === PartialViewResults.Ok) {
                        resolve({ success: true, appointment });
                    } else resolve({ success: false, appointment: null });
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: true
                })
            });
        });
    };

    public handleAppointmentDelete = async (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve, reject) => {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.delete-appointment.title'),
                    message: this.t
                        .tr('translation:partial-views.scheduler.questions.delete-appointment.message') //
                        .replace('{timestamps}', `<span class="font-semibold text-red-500">${format(appointment.start, 'dd-MM-yyyy HH:mm')} - ${format(appointment.end, 'HH:mm')}</span>`)
                        .replace('{patient}', `<span class="font-semibold text-red-500">${appointment.patient.name}</span>`),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.appointmentsApi.delete(appointment.id, this.authenticated.workspace.id);
                                resolve({ success: true });
                            } catch (e) {
                                await this.errorHandler.handle('[scheduler/delete]', e);
                                resolve({ success: false });
                            }
                        } else resolve({ success: false });
                    }
                })
            );
        });
    };

    public handleAppointmentResizedOrMoved = async (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        try {
            appointment.start = newStart;
            appointment.end = newEnd;

            // Get the timezone offset of the browser
            // NOTE: Get the timezone offset of the appointment date
            // and not the current date because the appointment date
            // can be in a different timezone that the current date.
            const timezoneOffset = appointment.start.getTimezoneOffset();
            const updated = await this.appointmentsApi.update(appointment.id, this.authenticated.workspace.id, timezoneOffset, appointment);

            this.notifications.show(
                this.t.tr('translation:partial-views.scheduler.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.scheduler.notifications.save-successful.message'),
                {
                    type: 'success',
                    duration: 3000
                }
            );

            return { success: true, appointment: updated };
        } catch (e) {
            this.isLoading = false;
            try {
                const error = JSON.parse(e.response);
                if (error.code === 212) {
                    const code = error.message.substring(error.message.indexOf('(') + 1, error.message.lastIndexOf(')'));
                    const message = this.t.tr(`translation:partial-views.appointments.messages.error-${code}`);
                    this.notifications.show(this.t.tr('translation:partial-views.scheduler.notifications.update-appointment-failed.title'), message, {
                        type: 'warning',
                        duration: 10000
                    });
                } else await this.errorHandler.handle('[update-appointment-resize]', e);
            } catch (err) {
                await this.errorHandler.handle('[update-appointment-resize]', { e, err });
            }
            return { success: false, appointment: null };
        }
    };

    public handlePatientCard = async (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.PatientCard.with({ id: appointment.patient.id, closeable: true, updateUrl: false }).whenClosed(
                    async (result: PartialViewResults, updated: GetAppointmentResponse) => {
                        if (result === PartialViewResults.Ok) {
                            resolve({ success: true });
                        } else resolve({ success: false });
                    }
                ),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: false
                })
            });
        });
    };

    public handleConfirmAppointment = async (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.addPartialView({
                view: this.partial.base, //
                partial: PartialViews.SendEmailToPatient.with({
                    template: appointment.type.templateForConfirmation?.id,
                    id: appointment.patient.id,
                    appointment: appointment.id
                }).whenClosed(async (result: PartialViewResults, data: { success: boolean }) => {
                    if (result === PartialViewResults.Ok) {
                        resolve({ success: true });
                    } else resolve({ success: false });
                }),
                options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true })
            });
        });
    };

    public setViewWidth = (setToOriginal: boolean, width?: number): void => {
        if (setToOriginal) this.setWidth(this.partial.width);
        else this.setWidth(width);
    };

    public handleTaskClick = async (task: GetHealthcareTaskResponse): Promise<GetHealthcareTaskResponse> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.EditTask.with({ id: task.id }).whenClosed(async (result: PartialViewResults, data: { task: GetHealthcareTaskResponse }) => {
                    if (result === PartialViewResults.Ok) {
                        resolve(data.task);
                    } else resolve(null);
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: true
                })
            });
        });
    };
}
