import { I18N } from '@aurelia/i18n';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { guid } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import mapboxgl, { LngLatBoundsLike, LngLatLike } from 'mapbox-gl';

@containerless
@inject(I18N)
export class ComponentMap {
    @bindable() public center: LngLatLike = [5.6463, 52.1009];
    @bindable() public height: number = 200;
    @bindable() public pitch: number = 0;
    @bindable() public zoom: number = 5;
    @bindable() public rounded: string;
    @bindable() public language: string;

    public mapContainer: HTMLDivElement;

    private map: mapboxgl.Map;
    private markers: string[] = [];

    public constructor(
        public t: I18N //
    ) {}

    public async attaching(): Promise<void> {
        const token = 'pk.eyJ1IjoiZHRhYWxiZXJzIiwiYSI6ImNsZzNydmlyMjBkdmUzY24xamJiMDV3djIifQ.4vCGyZF3UVkvzPZhMAMeJg';

        mapboxgl.accessToken = token;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            center: this.center,
            pitch: this.pitch,
            // style: 'mapbox://styles/dtaalbers/ck43rglw50fij1cpj5fzffpko',
            style: 'mapbox://styles/dtaalbers/ck3qwx5040vkw1cp03rkscach',
            zoom: this.zoom
        });

        this.map.addControl(
            new MapboxLanguage({
                supportedLanguages: ['nl', 'en'],
                defaultLanguage: this.language
            })
        );
        this.map.addControl(
            new mapboxgl.NavigationControl() //
        );

        this.map.once('load', () => {
            this.resize();
        });
    }

    public addMarker(options: {
        coordinates: LngLatLike; //
        id?: string;
        color?: string;
    }): Promise<void> {
        const defaultOptions = {
            color: '#F25366',
            element: undefined,
            offset: undefined,
            anchor: undefined,
            draggable: undefined,
            clickTolerance: undefined,
            rotation: undefined,
            rotationAlignment: undefined,
            pitchAlignment: undefined,
            scale: undefined,
            ...options
        };

        return new Promise((resolve) => {
            this.map.loadImage('static/map-icons/mapbox-marker-icon-red.png', (error, image) => {
                if (error) throw error;

                const id = defaultOptions.id || guid();
                this.map.addImage(`marker-${id}`, image);
                // Add a GeoJSON source with 2 points
                this.map.addSource(`marker-${id}`, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                // feature for Mapbox DC
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [defaultOptions.coordinates[0], defaultOptions.coordinates[1]]
                                },
                                properties: {
                                    id: id
                                }
                            }
                        ]
                    }
                });

                // // Add a symbol layer
                this.map.addLayer({
                    id: `marker-${id}`,
                    type: 'symbol',
                    source: `marker-${id}`,
                    layout: {
                        'icon-image': `marker-${id}`,
                        // get the title name from the source's "title" property
                        'text-field': ['get', 'title'],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                        'text-offset': [0, 1.25],
                        'text-anchor': 'top'
                    }
                });

                this.markers.push(id);

                resolve();
            });
        });
    }

    public removeMarker(id: string): void {
        this.map.removeImage(`marker-${id}`);
        this.map.removeLayer(`marker-${id}`);
        this.map.removeSource(`marker-${id}`);

        const index = this.markers.indexOf(id);
        if (index > -1) this.markers.splice(index, 1);
    }

    public flyTo(options: {
        coordinates: LngLatLike; //
        zoom?: number;
        speed?: number;
    }): void {
        const defaultOptions = {
            zoom: 11,
            curve: undefined,
            minZoom: undefined,
            speed: 2.5,
            screenSpeed: undefined,
            maxDuration: undefined,
            around: undefined,
            duration: undefined,
            easing: undefined,
            offset: undefined,
            animate: undefined,
            essential: undefined,
            ...options
        };

        this.map.flyTo({
            center: defaultOptions.coordinates,
            essential: true,
            zoom: defaultOptions.zoom,
            speed: defaultOptions.speed
        });
    }

    public fitBounds(options: {
        bounds: LngLatBoundsLike; //
        padding?: number;
        speed?: number;
        zoom?: number;
        minZoom?: number;
    }): void {
        const defaultOptions = {
            padding: 50,
            center: undefined,
            zoom: undefined,
            bearing: undefined,
            pitch: undefined,
            around: undefined,
            curve: undefined,
            minZoom: undefined,
            speed: 2.5,
            screenSpeed: undefined,
            maxDuration: undefined,
            duration: undefined,
            easing: undefined,
            offset: undefined,
            animate: undefined,
            essential: undefined,
            ...options
        };

        this.map.fitBounds(
            defaultOptions.bounds, //
            {
                padding: defaultOptions.padding,
                speed: defaultOptions.speed
            }
        );
    }

    public resize(): void {
        this.map.resize();
    }

    public flyToDefaultPosition(): void {
        this.flyTo({
            coordinates: this.center,
            zoom: this.zoom
        });
    }
}
