<import from="converters/translate" />
<import from="converters/get-values" />
<import from="converters/sort" />

<div ref="container" class="${columns} flex flex-col">
    <div id="therapy-step-grid-${item.id}" ref="grid" class="flex flex-col">
        <let flat.bind="getFlat(item.id)"></let>
        <ux-label class="mb-1">${flat.item.step.name | translate:language}</ux-label>
        <template if.bind="flat.item.step.inputType === InputTypes.FreeEntry">
            <ux-input
                type.bind="item.step.resultType === ResultTypes.Text ? 'text' : 'number'"
                value.two-way="def.defaultValues[index].value.result.value"
                t="[placeholder]partial-views.edit-medical-item.placeholders.enter-default-value"></ux-input>
        </template>
        <template if.bind="flat.item.step.inputType === InputTypes.Selector">
            <template if.bind="flat.item.step.isMultipleChoice">
                <ux-multi-selector
                    component.ref="defaultValues"
                    options.bind="toSet(flat.choices)"
                    on-selected.bind="handleDefaultValueSelected"
                    on-removed.bind="handleDefaultValueRemoved"
                    show-footer.bind="false"
                    force-remove.bind="true"
                    data.bind="index"
                    autocomplete.bind="true"
                    values.bind="def.defaultValues[index].value.results | getValues:'value'"
                    t="[placeholder]translation:partial-views.edit-medical-item.placeholders.select-default-values">
                </ux-multi-selector>
            </template>
            <template else>
                <ux-select
                    allow-clear="true"
                    data.bind="index"
                    t="[placeholder]translation:partial-views.medical-questions.placeholders.select-default-value"
                    value.two-way="def.defaultValues[index].value.result.value">
                    <ux-select-option value.bind="choice.value" repeat.for="choice of flat.choices | sort:'displayOrder'">
                        ${ choice.value || 'translation:global.messages.missing-value' | t }
                    </ux-select-option>
                </ux-select>
            </template>
        </template>
    </div>
</div>
