<import from="converters/keys" />
<import from="converters/date" />
<import from="converters/new-line-to-break" />
<import from="converters/file-name" />
<import from="converters/file-size" />
<import from="converters/blob-to-url" />
<import from="converters/file-to-icon" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex h-full flex-1 flex-col">
            <template if.bind="baseLoaded">
                <section class="flex h-full flex-col">
                    <ux-header padding="py-0 px-8">
                        <div class="flex h-[58px] items-center justify-between">
                            <div class="flex flex-col">
                                <p class="text-sm font-medium" t="translation:partial-views.clinical-pathways.labels.prosoftware-record"></p>
                            </div>
                        </div>
                    </ux-header>
                    <div class="flex h-full overflow-hidden pl-3">
                        <div class="flex h-full flex-1 flex-col overflow-hidden pr-5 sm:flex-row sm:pr-0">
                            <div w-48.class="record.reports.length > 0" w-36.class="record.reports.length === 0" class="flex overflow-hidden border-r border-gray-200 py-6 pl-5">
                                <ul class="flex w-full items-center gap-x-3 sm:flex-col sm:items-start sm:space-x-0 sm:space-y-3">
                                    <li class="group w-full pr-3" repeat.for="key of tabs | keys">
                                        <a
                                            class="${key === active ? 'text-gray-900 font-semibold': 'text-gray-500'} relative group-hover:text-gray-900"
                                            href="javascript:"
                                            click.trigger="setActiveTab(key)">
                                            <div class="flex items-center space-x-1">
                                                <span class="text-sm">${ tabs[key].name }</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="flex flex-1 flex-col gap-8 overflow-hidden">
                                <!-- Examinations -->
                                <div repeat.for="examination of record.examinations" if.bind="active === `examination-${$index}`" class="flex flex-col gap-8 overflow-y-auto px-6 py-6">
                                    <div class="flex flex-col gap-3">
                                        <div class="flex flex-col">
                                            <ux-label class="mb-1">
                                                <span t="translation:global.labels.created-at"></span>
                                            </ux-label>
                                            <div class="flex h-[38px] flex-1 items-center truncate rounded-md border border-gray-300 px-3 py-2 text-sm">
                                                ${examination.performedAt ?? record.createdAt | date}
                                            </div>
                                        </div>
                                        <div class="flex flex-col gap-1">
                                            <div class="flex flex-col">
                                                <a click.trigger="collapseOrExpand($index,  'anamnesis')" href="javascript:" class="flex items-center gap-1 rounded-md bg-gray-100 px-3 py-1.5 text-sm">
                                                    <template if.bind="customStates[`examination-${$index}`].anamnesis">
                                                        <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                                                        </svg>
                                                    </template>
                                                    <template else>
                                                        <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                                                        </svg>
                                                    </template>
                                                    <span t="translation:global.labels.anamnesis"></span>
                                                </a>
                                                <div if.bind="customStates[`examination-${$index}`].anamnesis" class="mb-2 ml-2 mt-2 flex flex-col gap-2 border-l-2 border-gray-300 pl-4">
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.motive"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.motive"
                                                            py-1.class="!examination.motive"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.motive | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.complaints-development"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.complaintsDevelopment"
                                                            py-1.class="!examination.complaintsDevelopment"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.complaintsDevelopment | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.functional-problems"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.functionalProblems"
                                                            py-1.class="!examination.functionalProblems"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.functionalProblems | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.factors"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.factors"
                                                            py-1.class="!examination.factors"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.factors | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.history"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.history"
                                                            py-1.class="!examination.history"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.history | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:global.labels.medication"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.medication"
                                                            py-1.class="!examination.medication"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.medication | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:global.labels.allergies"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.allergies"
                                                            py-1.class="!examination.allergies"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.allergies | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.expectations"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.expectations"
                                                            py-1.class="!examination.expectations"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.expectations | newLineToBreak"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col">
                                                <a
                                                    click.trigger="collapseOrExpand($index, 'examination')"
                                                    href="javascript:"
                                                    class="flex items-center gap-1 rounded-md bg-gray-100 px-3 py-1.5 text-sm">
                                                    <template if.bind="customStates[`examination-${$index}`].examination">
                                                        <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                                                        </svg>
                                                    </template>
                                                    <template else>
                                                        <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                                            <path
                                                                stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"
                                                                d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                                                        </svg>
                                                    </template>
                                                    <span t="translation:global.labels.examination"></span>
                                                </a>
                                                <div if.bind="customStates[`examination-${$index}`].examination" class="mb-2 ml-2 mt-2 flex flex-col gap-2 border-l-2 border-gray-300 pl-4">
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.inspection"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.inspection"
                                                            py-1.class="!examination.inspection"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.inspection | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.palpation"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.palpation"
                                                            py-1.class="!examination.palpation"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.palpation | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.functional"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.functional"
                                                            py-1.class="!examination.functional"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.functional | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.gait-analysis"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.gaitAnalysis"
                                                            py-1.class="!examination.gaitAnalysis"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.gaitAnalysis | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.additional-examination"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.additionalExamination"
                                                            py-1.class="!examination.additionalExamination"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.additionalExamination | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:partial-views.prosoftware-record.labels.other-tests"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.otherTests"
                                                            py-1.class="!examination.otherTests"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.otherTests | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:global.labels.conclusion"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.conclusion"
                                                            py-1.class="!examination.conclusion"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.conclusion | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:global.labels.differential-diagnosis"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.differentialDiagnosis"
                                                            py-1.class="!examination.differentialDiagnosis"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.differentialDiagnosis | newLineToBreak"></div>
                                                    </div>
                                                    <div class="flex flex-1 flex-col">
                                                        <ux-label class="mb-1">
                                                            <span t="translation:global.labels.comments"></span>
                                                        </ux-label>
                                                        <div
                                                            py-3.class="examination.comments"
                                                            py-1.class="!examination.comments"
                                                            class="flex-1 rounded-md border border-gray-300 px-4 text-sm"
                                                            innerhtml.bind="examination.comments | newLineToBreak"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Reports -->
                                <div repeat.for="report of record.reports" if.bind="active === `report-${$index}`" class="flex flex-col gap-3 overflow-y-auto px-6 py-6">
                                    <div class="flex flex-col">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.created-at"></span>
                                        </ux-label>
                                        <div class="flex h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 px-3 py-2 text-sm">${report.createdAt | date}</div>
                                    </div>
                                    <div class="flex flex-col">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.created-by"></span>
                                        </ux-label>
                                        <div class="flex h-[38px] flex-1 items-center truncate rounded-lg border border-gray-300 px-3 py-2 text-sm">${report.practitioner}</div>
                                    </div>
                                    <div class="flex flex-1 flex-col">
                                        <ux-label class="mb-1">
                                            <span t="translation:global.labels.report"></span>
                                        </ux-label>
                                        <div
                                            py-3.class="report.value"
                                            py-1.class="!report.value"
                                            class="flex-1 rounded-lg border border-gray-300 px-4 text-sm"
                                            innerhtml.bind="report.value | newLineToBreak"></div>
                                    </div>
                                </div>

                                <!-- Attachments -->
                                <div if.bind="active === 'attachments'" class="flex flex-col overflow-y-auto px-6 py-6">
                                    <ux-label if.bind="record.attachments.length > 0" class="mb-1">
                                        <span t="translation:global.labels.attachments"></span>
                                    </ux-label>
                                    <div class="grid grid-cols-12 gap-2">
                                        <a
                                            repeat.for="attachment of record.attachments"
                                            click.trigger="show(attachment)"
                                            href="javascript:"
                                            class="col-span-6 flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5 hover:bg-blue-50">
                                            <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                                                <template if.bind="isImage(attachment.contentType)">
                                                    <ux-image height="56px" width="75px" size="cover" src.bind="urls[attachment.id]"></ux-image>
                                                </template>
                                                <template else>
                                                    <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                                                        <span class="text-gray-400" innerhtml.bind="attachment.extension | fileToIcon:'w-6 h-6'"></span>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                                                <div class="flex min-w-0 flex-1 flex-col">
                                                    <p class="= pointer-events-none block truncate text-sm text-gray-900">${ attachment.name }${ attachment.extension }</p>
                                                    <p class="pointer-events-none block text-sm text-gray-500">${ attachment.size | fileSize }</p>
                                                </div>
                                            </div>
                                        </a>
                                        <div if.bind="record.attachments.length === 0" class="col-span-12 flex items-center justify-center rounded-md border border-gray-200 p-5">
                                            <span t="translation:partial-views.prosoftware-record.labels.no-attachments" class="text-sm"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ux-footer>
                        <div class="flex flex-1 justify-end">
                            <ux-button click.trigger="cancel()">
                                <span class="pointer-events-none" t="global.buttons.close"></span>
                            </ux-button>
                        </div>
                    </ux-footer>
                </section>
            </template>
            <template else>
                <div class="flex p-20">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex w-full flex-col space-y-5 py-1">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
