<div class="flex flex-col">
    <ux-label class="mb-1">
        <span t="translation:components.widgets.course-of-complaints.labels.course-of-complaints"></span>
    </ux-label>
    <ux-textarea
        component.ref="textarea"
        spellcheck.bind="true"
        language.bind="language"
        rows="4"
        value.two-way="registration.widget.result.value"
        oninput.trigger="handleCourseOfComplaintChanged($event)"
        t="[placeholder]translation:components.widgets.course-of-complaints.placeholders.course-of-complaints">
    </ux-textarea>
    <a if.bind="!suggestionsAreShown" href="javascript:" class="mt-1 text-xs text-blue-500" click.trigger="showSuggestions()">
        <span t="translation:components.widgets.course-of-complaints.buttons.show-suggestions"></span>
    </a>
</div>
