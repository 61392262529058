import { HealthcareInvoiceStatuses } from '@wecore/sdk-healthcare';

export class InvoiceStatusToStringValueConverter {
    public toView(status: HealthcareInvoiceStatuses): string {
        switch (status) {
            case HealthcareInvoiceStatuses.Draft:
                return 'translation:global.labels.draft';
            case HealthcareInvoiceStatuses.Declared:
                return 'translation:global.labels.declared';
            case HealthcareInvoiceStatuses.PartiallyDeclared:
                return 'translation:global.labels.partially-declared';
            case HealthcareInvoiceStatuses.RestitutionSent:
            case HealthcareInvoiceStatuses.RemainingSent:
            case HealthcareInvoiceStatuses.CreditSent:
                return 'translation:global.labels.sent-to-patient';
            case HealthcareInvoiceStatuses.Paid:
                return 'translation:global.labels.paid';
            case HealthcareInvoiceStatuses.Overdue:
                return 'translation:global.labels.overdue';
            case HealthcareInvoiceStatuses.Compensated:
                return 'translation:global.labels.compensated';
            case HealthcareInvoiceStatuses.PartiallyCompensated:
                return 'translation:global.labels.partially-compensated';
            case HealthcareInvoiceStatuses.CompensationRejected:
                return 'translation:global.labels.compensation-rejected';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
