<template>
    <section class="video rounded">
        <iframe
            if.bind="src && type === 'youtube'"
            width.bind="width"
            height.bind="height"
            src="https://www.youtube.com/embed/${src}?controls=${controls}&loop=${loop}&modestbranding=${branding}&autoplay=${autoplay}&playlist=${playlist}"
            frameborder="0"
            allowfullscreen="${fullscreen}"
            data-allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
        </iframe>
        <video if.bind="src && type === 'video'" autoplay="${autoplay}" controls="${controls}" loop="${loop}" css="width: ${width}px; height: ${height}px; objectfit: size">
            <source src.bind="src" type.bind="contentType" />
        </video>
        <div if.bind="!src" css="height: ${height}; width: ${width};" key.bind="guid()" class="loader flex flex-col items-center justify-center bg-black py-20">
            <ux-spinner size="md" color="text-white"></ux-spinner>
        </div>
    </section>
</template>
