import { GetMedicalRecordRegistrationResponse, GetMedicalRecordResponse, MedicalRecordRegistrationEntityReference } from '@wecore/sdk-healthcare';

export class SortTherapyPlansByGroupedOrOtherValueConverter {
    public toView(
        plans: MedicalRecordRegistrationEntityReference[],
        registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
        record: GetMedicalRecordResponse
    ): Map<string, string[] | Map<string, string[]>> {
        const resultIds = record.results.map((result) => result.id);
        const groups = plans
            .map((x) => x.id)
            // Filter out items that have a single result.
            .filter((id) => registrations[id].therapyPlan.results.length === 1)
            // Sort the items by the order of the medical record results.
            .sort((a, b) => {
                const indexA = resultIds.indexOf(a);
                const indexB = resultIds.indexOf(b);
                return indexA - indexB;
            })
            // Group the items by the medical result.
            .groupBy((id: string) => registrations[id].therapyPlan.results[0].resultId);
        // Then get the items that have multiple results. These will go in the "other" group.
        const other = plans.filter((p) => registrations[p.id].therapyPlan.results.length !== 1).map((x) => x.id);

        return new Map<string, string[] | Map<string, string[]>>([
            ['groups', groups],
            ['other', other]
        ]);
    }
}
