<import from="converters/translate" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-col overflow-y-auto">
            <div class="sticky top-0 flex items-center gap-1 border-b border-gray-200 bg-white p-5">
                <p class="text-sm font-medium" t="translation:partial-views.documents.labels.preview-document"></p>
                <p if.bind="blob" class="text-sm text-gray-800">${blobFilename}${attachmentExtension}</p>
                <p else class="text-sm text-gray-800">${attachmentName}${attachmentExtension}</p>
            </div>
            <div class="flex flex-1 justify-center bg-gray-50 p-10">
                <template if.bind="baseLoaded">
                    <template if.bind="failed">
                        <div class="flex flex-1 items-center justify-center px-8">
                            <div class="flex max-w-lg flex-col items-center rounded-sm p-10">
                                <svg
                                    class="h-40 w-40"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-name="Layer 1"
                                    width="777.00073"
                                    height="407.99846"
                                    viewBox="0 0 777.00073 407.99846"
                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <path
                                        d="M986.84973,543.39925c-4.06982,25.87-16.68017,50.98-37.49023,66.87-20.48,15.65-46.71973,21.21-72.04981,25.99q-45.56982,8.61-91.39013,15.74-6.49512,1.035-13,2H594.99963c-.18017-.24-.37011-.49-.5498-.74-.29-.42-.58008-.84-.86035-1.26-.15967-.24-.31983-.49-.48-.74H704.70959a11.96072,11.96072,0,0,0,11.9502-11.94v-148.96c.71973-2.26,1.38965-4.53,2-6.82995a180.05237,180.05237,0,0,0,5.52979-32.71c.60009-7.7.78027-15.4.6499-23.11-.36963-21.19-3.13965-42.38-6.1499-63.42-.21973-1.55-.44971-3.11-.66993-4.66-2.12011-14.62-4.0498-30.53,3.31983-43.34,8.43017-14.66,27.14013-20.77,43.99023-19.27,30.31006,2.71,55.83985,25.92,69.10987,53.31,13.26025,27.38,16.23,58.52,17.43994,88.92.64013,16.12.57031,32.96-1.67969,49.13q11.39941-26.07,22.81982-52.14c4.06006-9.27,8.33008-18.84,15.8501-25.62,15.71973-14.19,41.71973-11.33,58.50977,1.59,16.78027,12.93,25.98,33.36,32.10009,53.63C987.05969,490.9092,990.92981,517.52926,986.84973,543.39925Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#f2f2f2" />
                                    <path
                                        d="M919.79782,402.88685c10.9923,19.4683,16.03538,41.79209,15.91469,64.06956A155.08734,155.08734,0,0,1,921.38114,530.886a160.6074,160.6074,0,0,1-34.46657,48.29863A146.33182,146.33182,0,0,1,837.383,610.825a129.43749,129.43749,0,0,1-67.21077,6.38653q-4.70407-.80839-9.33861-1.95981c-1.87441-.462-2.6741,2.43033-.79752,2.89284A132.77706,132.77706,0,0,0,831.278,616.0956a146.7727,146.7727,0,0,0,52.18265-29.68634,162.9081,162.9081,0,0,0,37.4259-47.71227,159.49477,159.49477,0,0,0,17.57522-63.86338c1.3102-22.61163-2.48049-45.62617-12.25915-66.13774q-1.77741-3.72827-3.81437-7.32317a1.50113,1.50113,0,0,0-2.59042,1.51415Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M760.5882,296.62254a325.34391,325.34391,0,0,1,28.29774,70.98262,342.83439,342.83439,0,0,1,5.83225,151.56009,324.11436,324.11436,0,0,1-22.78862,72.9844c-10.41915,23.14713-25.4196,47.536-51.04216,55.64059a53.43305,53.43305,0,0,1-8.85978,1.98052c-1.90417.26134-1.09355,3.15238.79752,2.89284,26.50486-3.63773,44.92183-25.65744,56.47214-48.10346,11.95115-23.225,20.461-48.4457,26.12039-73.909a345.52584,345.52584,0,0,0-1.1801-154.12143,330.4157,330.4157,0,0,0-26.52943-72.79677q-2.19828-4.347-4.52954-8.62454c-.92274-1.69656-3.514-.184-2.59041,1.51416Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M635.13977,292.25924H455.53967a13.76779,13.76779,0,0,0-13.75,13.75v89.99h27.64014a11.87878,11.87878,0,0,1,10.10986,5.59l9.58008,15.25,9.16016,14.56994,1.71972,2.74,10.48,16.67.12012.19h138.29v-145A13.76779,13.76779,0,0,0,635.13977,292.25924Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#ccc" />
                                    <path d="M563.94983,319.76925H474.95959a8.895,8.895,0,1,0,0,17.79h88.99024a8.895,8.895,0,1,0,0-17.79Z" transform="translate(-211.49963 -246.00077)" fill="#fff" />
                                    <path d="M613.29944,363.44924H475.76965a9.71,9.71,0,0,0,0,19.42H613.29944a9.71,9.71,0,0,0,0-19.42Z" transform="translate(-211.49963 -246.00077)" fill="#fff" />
                                    <path
                                        d="M622.07971,403.01925a9.70445,9.70445,0,0,0-8.78027-5.59H475.76965c-.21,0-.41015.01-.62011.02a11.91385,11.91385,0,0,1,4.39013,4.14l9.58008,15.25H613.29944a9.69745,9.69745,0,0,0,8.78027-13.82Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M622.90979,439.80923a9.7092,9.7092,0,0,0-9.61035-8.4H498.27991l1.71972,2.74,10.48,16.67H613.29944a9.59905,9.59905,0,0,0,5.63037-1.81,9.69664,9.69664,0,0,0,4.06982-7.9A9.24451,9.24451,0,0,0,622.90979,439.80923Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M732.68958,369.75924l-14-5.47-69.79981-27.24-83.51025-32.59a13.77453,13.77453,0,0,0-17.81006,7.82l-2.91992,7.49-6.94971,17.79-10.1001,25.89-7.58008,19.42-5.68017,14.56-7.58008,19.41-5.68994,14.56994-.15967.42005-.91016,2.32,10.48,16.67.12012.19H704.70959a11.91612,11.91612,0,0,1,9.29,4.44l.81983-2.08,10.02-25.66,15.66015-40.13A13.77715,13.77715,0,0,0,732.68958,369.75924Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#6c63ff" />
                                    <path
                                        d="M656.36975,369.49923l-7.48-2.92-75.41992-29.43a8.93074,8.93074,0,0,0-11.53027,5.05,8.924,8.924,0,0,0,5.06005,11.53l24.91016,9.72,30.81006,12.02,26.16992,10.22,1.00977.39a8.89889,8.89889,0,0,0,6.47021-16.58Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M686.44983,428.13924l-37.56006-14.66-26.81006-10.46-14.32031-5.59-37.29981-14.56-12.12011-4.73a9.70982,9.70982,0,0,0-12.23,4.73c-.11963.25-.23975.51-.33985.78a9.70379,9.70379,0,0,0,5.51026,12.57l3.09961,1.21,49.72021,19.41,44.79,17.48,30.5,11.91a9.62861,9.62861,0,0,0,3.52.66,9.70595,9.70595,0,0,0,3.54-18.75Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M648.88977,449.94924l-2.41016-.94-23.56982-9.2-21.53027-8.4-37.33985-14.56994-18.06006-7.05a9.70758,9.70758,0,1,0-7.0498,18.09l9.04,3.53,45.09961,17.6,4.64013,1.81.48.19h53.41015Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M557.65979,450.81924l-4.64014-1.81-19.39013-7.57a9.69455,9.69455,0,0,0-13.12012,7.57,9.2009,9.2009,0,0,0-.10986,1.81,1.12063,1.12063,0,0,0,.01025.19h37.73975Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#fff" />
                                    <path
                                        d="M597.70959,450.81924l-4.64013-1.81H520.5094a9.2009,9.2009,0,0,0-.10986,1.81h-9.91993l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm.48.19-.48-.19h-87.23l.12012.19Zm-175.00977,200.25a11.95822,11.95822,0,0,1-11.93994-11.94v-231.37a11.96034,11.96034,0,0,1,11.93994-11.95h18.60986v-2H423.17981a13.96617,13.96617,0,0,0-13.93994,13.95v231.37a13.95043,13.95043,0,0,0,8.16992,12.68H593.58948c-.15967-.24-.31983-.49-.48-.74Zm291.63965-197.89a13.87759,13.87759,0,0,0-10.10987-4.36h-193l-10.7998-17.18-.26025-.42005-9.15967-14.56994-10.26026-16.32a13.97619,13.97619,0,0,0-11.7998-6.52h-46.25a13.96617,13.96617,0,0,0-13.93994,13.95v231.37a13.95043,13.95043,0,0,0,8.16992,12.68,13.74939,13.74939,0,0,0,5.77,1.26H704.70959a13.79793,13.79793,0,0,0,5.78028-1.26,13.95124,13.95124,0,0,0,8.16992-12.68v-176.36A13.87,13.87,0,0,0,714.81946,453.36922Zm1.84033,185.95a11.96072,11.96072,0,0,1-11.9502,11.94H423.17981a11.95822,11.95822,0,0,1-11.93994-11.94v-231.37a11.96034,11.96034,0,0,1,11.93994-11.95h46.25a11.87878,11.87878,0,0,1,10.10986,5.59l9.58008,15.25,9.16016,14.56994,1.71972,2.74,10.48,16.67.12012.19H704.70959a11.96045,11.96045,0,0,1,11.9502,11.95Zm-118.9502-188.5h-87.23l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm0,0-4.64013-1.81H511.70959l-10.7998-17.18-.91016,2.32,10.48,16.67.12012.19h87.58985Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#3f3d56" />
                                    <polygon points="156.928 287.77 151.2 299.746 102.283 283.352 110.736 265.677 156.928 287.77" fill="#ffb8b8" />
                                    <path
                                        d="M358.52955,543.80507h26.83419a0,0,0,0,1,0,0v16.1195a0,0,0,0,1,0,0h-41.721a0,0,0,0,1,0,0v-1.23264A14.88686,14.88686,0,0,1,358.52955,543.80507Z"
                                        transform="translate(-502.12206 396.57105) rotate(-64.4384)"
                                        fill="#2f2e41" />
                                    <polygon points="168.459 394.715 155.192 394.267 154.633 342.623 170.186 343.541 168.459 394.715" fill="#ffb8b8" />
                                    <path
                                        d="M355.83539,636.31276h26.83419a0,0,0,0,1,0,0v16.1195a0,0,0,0,1,0,0h-41.721a0,0,0,0,1,0,0v-1.23264A14.88686,14.88686,0,0,1,355.83539,636.31276Z"
                                        transform="translate(-189.55753 -257.83873) rotate(1.93309)"
                                        fill="#2f2e41" />
                                    <path
                                        d="M401.32408,424.5795s-40.605-10.28659-59.01257,4.8726l-10.28659,29.777-41.14638,58.47117,59.554,35.73238,10.828-27.07-25.98718-12.9936,31.40118-27.07-5.414,142.92952,20.57319,3.2484S427.31127,455.98068,401.32408,424.5795Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#2f2e41" />
                                    <path
                                        d="M339.72057,433.85626l.49487-.87978c5.217-9.27464,4.37164-24.37779,2.744-35.41409-1.36328-9.244-1.32231-25.53461-1.04791-37.57336A17.89121,17.89121,0,0,1,356.173,342.76787c12.35254-2.57059,27.51728-3.11516,35.45746-3.21139l.00661.5414-.00661-.5414a8.24494,8.24494,0,0,1,8.27538,8.04275c.24162,12.964.16654,36.60475-3.05066,49.28852-4.77479,18.82527,2.69166,31.618,2.7678,31.74485l.45046.75288Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#6c63ff" />
                                    <path
                                        d="M318.8336,485.774a10.02479,10.02479,0,0,0,3.81053-14.892l22.91578-98.81821H328.23512l-20.22438,96.87052A10.07912,10.07912,0,0,0,318.8336,485.774Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#ffb8b8" />
                                    <path
                                        d="M458.17105,388.50259l-38.2425-36.17534-10.33582,14.47013,25.83953,22.73879-33.88713,26.91034c-.24477-.01653-.48853-.03722-.73784-.03722a10.85261,10.85261,0,1,0,10.74561,12.296l.107.107Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#ffb8b8" />
                                    <path
                                        d="M353.74046,344.03808s-23.88114-3.91694-26.04674,14.49065-3.2484,18.40759-3.2484,18.40759,17.32479-3.24839,21.656,1.0828S353.74046,344.03808,353.74046,344.03808Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#6c63ff" />
                                    <path
                                        d="M389.41329,341.20394s19.49039-3.24839,28.15278,5.414,12.9936,10.828,12.9936,10.828-19.49039,10.828-11.9108,20.57319c0,0-20.57319-9.74519-23.82158-6.49679S389.41329,341.20394,389.41329,341.20394Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#6c63ff" />
                                    <path
                                        d="M372.31839,278.33911c-6.148-4.91-7.76342-14.38628-4.19638-21.39926s11.63171-11.23809,19.4934-10.92256,15.26966,4.82908,19.82422,11.24482c6.39793,9.01238,7.74052,21.53835,16.24786,28.594,7.68395,6.37273,18.69155,6.19022,28.66139,5.68324,3.36071-.1709,6.83013-.369,9.84577-1.86219s5.47946-4.64237,5.09075-7.98489c2.50462,3.96489,1.05154,9.524-2.30876,12.79543s-8.145,4.642-12.80289,5.18773a47.56885,47.56885,0,0,1-43.1514-18.93293c-3.46887-4.78542-6.146-10.3579-10.906-13.86156s-12.69667-3.74754-15.61885,1.39c-1.44527,2.541-1.30342,5.72826-2.63248,8.3319s-5.645,4.00754-6.92478,1.37932"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#2f2e41" />
                                    <circle cx="159.87891" cy="54.55562" r="30" fill="#2f2e41" />
                                    <circle cx="159.6978" cy="62.34302" r="24.56103" fill="#ffb8b8" />
                                    <path
                                        d="M348.80242,290.30517c3.338.73012,2.24571,6.32966,4.88341,8.50165,2.37937,1.95927,5.8059-.05564,8.80058-.78507,3.78081-.92091,7.73806.42815,11.22871,2.148s6.8565,3.85421,10.66447,4.65545,8.32247-.10183,10.45715-3.3554c2.04109-3.11093,1.28973-7.30611-.30712-10.66677a24.76974,24.76974,0,0,0-44.442-.61588"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#2f2e41" />
                                    <path
                                        d="M927.49963,652.99923a1.00307,1.00307,0,0,1-1,1h-714a1,1,0,0,1,0-2h714A1.00307,1.00307,0,0,1,927.49963,652.99923Z"
                                        transform="translate(-211.49963 -246.00077)"
                                        fill="#3f3d56" />
                                </svg>
                                <div class="flex flex-col items-center gap-5">
                                    <p class="text-2xl text-gray-800" t="translation:partial-views.documents.labels.file-not-found"></p>
                                    <p class="text-center text-sm text-gray-600" t="translation:partial-views.documents.messages.file-not-found"></p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template else>
                        <template if.bind="attachmentExtension === '.pdf'">
                            <ux-pdf-preview scale="1.5" src.bind="src"></ux-pdf-preview>
                        </template>
                        <template if.bind="attachmentExtension === '.jpg' || attachmentExtension === '.jpeg' || attachmentExtension === '.png'">
                            <div class="h-screen w-full rounded-md border-gray-300 bg-contain bg-center bg-no-repeat" css="background-image: url('${src}');"></div>
                        </template>
                        <template
                            if.bind="attachmentExtension !== '.jpg' 
                                && attachmentExtension !== '.jpeg'
                                && attachmentExtension !== '.png'
                                && attachmentExtension !== '.pdf'">
                            <div class="flex flex-1 items-center justify-center px-8">
                                <div class="flex max-w-2xl flex-col items-center gap-5 rounded-sm p-10">
                                    <svg
                                        class="h-52 w-52"
                                        xmlns="http://www.w3.org/2000/svg"
                                        data-name="Layer 1"
                                        width="405.94938"
                                        height="611.97003"
                                        viewBox="0 0 405.94938 611.97003"
                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <path
                                            d="M717.73,647.62c-.31.35-.62.71-.92,1.07a117.72337,117.72337,0,0,0-11.32,15.54c-.27.42-.53.85-.77,1.27A124.6175,124.6175,0,0,0,688.83,708.54q-.87,5.16-1.31,10.38-.315,4.02-.38,8.05c-.16,11.28.84,23.83-6.43,32.45a22.68877,22.68877,0,0,1-6.98,5.48,29.17928,29.17928,0,0,1-6.32,2.39H644.74c-1.14-.17-2.28-.35-3.42-.53-.03,0-.06-.01-.09-.01l-.23.08-1.37.46-.06.02v-.02a.48639.48639,0,0,0-.01-.12,1.61779,1.61779,0,0,0-.01-.22v-.01c-.01-.2-.02-.39-.02-.59l-.03-.53s-.01-.01,0-.01c-.01-.3-.03-.6-.04-.91-1.05-26.1,1.51-53.07,13.83-76.05a84.97082,84.97082,0,0,1,16.12-21.21,1.1995,1.1995,0,0,1,.12006-.11c12.81-12.22,29.37-20.21,46.85-19.94Q717.055,647.59,717.73,647.62Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#f0f0f0" />
                                        <path
                                            d="M717.78076,648.18475A105.86966,105.86966,0,0,0,671.788,694.31008,45.591,45.591,0,0,0,667.1362,707.816a26.14861,26.14861,0,0,0,1.70473,13.65589c1.57635,4.0595,3.65888,8.02937,4.221,12.40137a16.01084,16.01084,0,0,1-3.9725,12.47687c-3.63848,4.28929-8.60119,7.01542-13.547,9.51269-5.49132,2.77274-11.239,5.56575-15.05166,10.57863-.462.60738-1.37739-.14493-.91613-.75139,6.63334-8.72156,18.316-10.62379,26.2719-17.71521,3.71239-3.309,6.45007-7.75678,6.14123-12.87985-.27006-4.4799-2.41409-8.57551-4.0457-12.672a27.9025,27.9025,0,0,1-2.16435-13.29041,42.14762,42.14762,0,0,1,4.11538-13.63493,102.71225,102.71225,0,0,1,18.22871-26.42477A107.74722,107.74722,0,0,1,717.369,647.07355c.67678-.347,1.08417.76642.41181,1.1112Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M674.66522,687.73455a15.88383,15.88383,0,0,1-5.29512-19.87462c.32973-.68735,1.41333-.20726,1.08316.481a14.70809,14.70809,0,0,0,4.96335,18.47748c.62864.4314-.12635,1.34506-.75139.91613Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M667.77057,719.51882A30.61479,30.61479,0,0,0,686.86983,707.986c.46409-.60573,1.37967.1464.91614.75139a31.844,31.844,0,0,1-19.89891,11.96059c-.75324.12727-.86564-1.05254-.11649-1.17912Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M697.1669,661.56836a8.991,8.991,0,0,0,8.10555,2.66178c.75126-.13675.86286,1.04326.11649,1.17912a10.07517,10.07517,0,0,1-8.97344-2.92477.61233.61233,0,0,1-.08237-.83376.59543.59543,0,0,1,.83377-.08237Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M772.94,719.98c-.46.1-.92.2-1.39.31h-.01a119.08031,119.08031,0,0,0-18.38,5.58c-.46.18-.92.36-1.37994.55h-.01a124.76367,124.76367,0,0,0-38.44,24.68c-.04.04-.08.07-.12.11a.60671.60671,0,0,1-.08.08l-.05.05a121.67548,121.67548,0,0,0-12.31,13.56c0,.01-.01.02-.02.03-.6.78-1.21,1.56-1.81,2.36H639.63l-.06.02v-.02h-.25c0-.01.01-.02.02-.03,0-.01.01-.01.01-.02.07-.1.14-.2.2-.29v-.01a.27681.27681,0,0,1,.06-.08c.06-.1.13-.18.18-.27.4-.56.79-1.13,1.19-1.69.65-.93,1.31-1.86,1.98-2.78a.00979.00979,0,0,1,.01-.01v-.01c.01-.01.01-.02.02-.03l.02-.02c8.25-11.42,17.53-22.26,28.16-31.34.32-.28.64-.56.98-.82a114.81692,114.81692,0,0,1,15.37-10.97q4.43994-2.625,9.15-4.82a85.12642,85.12642,0,0,1,25.59-7.22h.02c.02,0,.04-.01.06995-.01.03,0,.05-.01.07-.01,17.61005-2.04,35.66,1.55,49.47,12.29C772.25,719.43,772.59,719.7,772.94,719.98Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#f0f0f0" />
                                        <path
                                            d="M639.32,767.29h.25v.02l.06-.02H655.5c-4.73-.81-9.45-1.44-14.18-.53-.11.02-.21.04-.32.07-.48.09-.96.21-1.44.34-.03.01-.07.02-.1.03a.33794.33794,0,0,0-.12.06.14932.14932,0,0,0-.07.03Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M772.65,720.47c-.37-.06-.73-.13-1.1-.18h-.01a105.41223,105.41223,0,0,0-23.35-1.18c-.39.02-.79.05-1.19.07a105.32715,105.32715,0,0,0-31.57,7.19c-.01,0-.01,0-.01.01-.11.04-.22.08-.32.12-2.35.95-4.67,1.99-6.94,3.11a45.70616,45.70616,0,0,0-11.85,7.98,25.47878,25.47878,0,0,0-6.21,9.89c-.13.38-.26.75-.38,1.13-.09.3-.18.61-.27.91-1.18,4.19-1.91,8.61-4.09,12.44a14.64929,14.64929,0,0,1-2.2,2.94,15.12454,15.12454,0,0,1-2.96,2.39h-2.52a14.53172,14.53172,0,0,0,3.79-2.39,12.545,12.545,0,0,0,2.29-2.61c2.48-3.74,3.24-8.3,4.4-12.55.19-.69.39-1.36.61005-2.03a26.6481,26.6481,0,0,1,5.67-9.88995,42.17887,42.17887,0,0,1,11.49-8.41q4.05-2.13,8.32-3.86c.37-.16.74-.3,1.11-.44a108.07871,108.07871,0,0,1,56.53-5.96c.37.05005.73.12,1.1.18C773.74,719.46,773.4,720.6,772.65,720.47Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M714.41543,726.08739a15.88384,15.88384,0,0,1,7.738-19.0568c.67711-.35029,1.25325.68544.57524,1.0362A14.70809,14.70809,0,0,0,715.567,725.8083c.2422.72293-.91071.99788-1.15152.27909Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M689.77412,747.31429a30.6148,30.6148,0,0,0,22.19327,2.29073c.73524-.20422,1.01344.94755.27909,1.15152a31.844,31.844,0,0,1-23.08926-2.43065c-.67805-.35189-.05746-1.36158.6169-1.0116Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M748.13562,718.74271a8.991,8.991,0,0,0,4.86925,7.00539c.68217.34312.06083,1.35248-.6169,1.01159a10.07517,10.07517,0,0,1-5.40388-7.73789.61235.61235,0,0,1,.43622-.71531.59544.59544,0,0,1,.71531.43622Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M713.94,321.63q.405-49.66506,4.04-100.29c.04-.67.09-1.33.14-2q1.92-26.355,4.7-52.9v-.1a11.00657,11.00657,0,0,0-11-11h-295a11.02189,11.02189,0,0,0-11,10.95q-3.84,36.555-6.02,72.75c-.06.79-.1,1.57-.14,2.36q-1.08,17.895-1.72,35.7c-.03.7-.06,1.4-.07,2.1a1478.8856,1478.8856,0,0,0,7.95,218.2,11.01977,11.01977,0,0,0,11,10.94h295a11.00658,11.00658,0,0,0,11-11v-.11q-2.175-19.305-3.82-38.86-.24-2.86506-.47-5.73-1.14-14.085-1.99-28.28-.18-2.865-.33-5.73-1.605-28.41-2.1-57.26c-.04-1.91-.07-3.81-.1-5.73q-.18-14.085-.11-28.28C713.9,325.45,713.91,323.54,713.94,321.63Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#f0f0f0" />
                                        <path
                                            d="M802.82,230.34a11.00657,11.00657,0,0,0-11-11h-295a11.02189,11.02189,0,0,0-11,10.95c-9.96,94.97-11.61,188.26-4.94,278.05q1.215,16.66506,2.84,33.16.87,8.835,1.85,17.62c.08.76.16,1.52.25,2.28a11.01977,11.01977,0,0,0,11,10.94h295a11.00658,11.00658,0,0,0,11-11v-.11c-11.93-105.77-11.93-217.06,0-330.79Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M802.82,230.34a11.00657,11.00657,0,0,0-11-11h-295a11.02189,11.02189,0,0,0-11,10.95c-9.96,94.97-11.61,188.26-4.94,278.05q1.215,16.66506,2.84,33.16.87,8.835,1.85,17.62c.08.76.16,1.52.25,2.28a11.01977,11.01977,0,0,0,11,10.94h295a11.00658,11.00658,0,0,0,11-11v-.11c-11.93-105.77-11.93-217.06,0-330.79Zm-2,331.06a9.0075,9.0075,0,0,1-9,8.94h-295a9.01353,9.01353,0,0,1-9-9v-.11c-.07-.64-.14-1.29-.21-1.93q-1.035-9.18-1.93-18.41-1.575-16.185-2.79-32.55c-6.67-89.74-5.03-182.97,4.93-277.9v-.1a9.01357,9.01357,0,0,1,9-9h295a9.00308,9.00308,0,0,1,9,8.95C788.89,344.11,788.89,455.52,800.82,561.4Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e4e4e4" />
                                        <path
                                            id="a974c3de-8d03-408b-a459-3ce6ba461bdb-849"
                                            data-name="Path 142"
                                            d="M517.1541,321.63028a2.86635,2.86635,0,0,0,0,5.73268H756.88526a2.86635,2.86635,0,0,0,0-5.73268Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="e9133026-9528-4ac3-aa69-83f109aff1e3-850"
                                            data-name="Path 143"
                                            d="M517.1541,338.83a2.86635,2.86635,0,0,0,0,5.73269H679.8988a2.86635,2.86635,0,0,0,0-5.73269Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="a406ca83-8296-4426-a4ed-8cd27cbcb96b-851"
                                            data-name="Path 142"
                                            d="M517.1541,355.64049a2.86635,2.86635,0,0,0,0,5.73269H756.88526a2.86635,2.86635,0,0,0,0-5.73269Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="be321b5a-3e39-41eb-b9b2-711d73db0143-852"
                                            data-name="Path 143"
                                            d="M517.1541,372.84017a2.86635,2.86635,0,0,0,0,5.73268H679.8988a2.86635,2.86635,0,0,0,0-5.73268Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="a5a7e885-834f-470d-9162-1a1fa51c1163-853"
                                            data-name="Path 142"
                                            d="M517.1541,418.63028a2.86635,2.86635,0,0,0,0,5.73268H756.88526a2.86635,2.86635,0,0,0,0-5.73268Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="a5fcdb43-4805-483f-9462-6a7e4850dca6-854"
                                            data-name="Path 143"
                                            d="M517.1541,435.83a2.86635,2.86635,0,0,0,0,5.73269H679.8988a2.86635,2.86635,0,0,0,0-5.73269Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="b765b6a8-7afa-43c2-95d1-39dcbde99f83-855"
                                            data-name="Path 142"
                                            d="M517.1541,452.64049a2.86635,2.86635,0,0,0,0,5.73269H756.88526a2.86635,2.86635,0,0,0,0-5.73269Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="e421dd9d-74a5-4920-9c7d-c121b8cd01e8-856"
                                            data-name="Path 143"
                                            d="M517.1541,469.84017a2.86635,2.86635,0,0,0,0,5.73268H679.8988a2.86635,2.86635,0,0,0,0-5.73268Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#e6e6e6" />
                                        <path
                                            id="ea3081fc-4e36-4189-92a3-e6d1b5b4a040-857"
                                            data-name="Path 154"
                                            d="M564.58787,286.03993h-45.788a2.23937,2.23937,0,0,1-2.23686-2.23686v-7.33618a2.23938,2.23938,0,0,1,2.23686-2.23686h45.788a2.23937,2.23937,0,0,1,2.23686,2.23686v7.33618A2.23937,2.23937,0,0,1,564.58787,286.03993Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#6c63ff" />
                                        <path
                                            d="M723.65574,522.01636l-14.02509,4.635q-3.50626,1.15874-7.01254,2.31748c-2.165.71282-4.50763,1.56067-6.82505,1.25408-1.60173-.21191-3.42762-1.13376-3.92664-2.78166-.47217-1.55926.52889-3.19054,1.74121-4.11282,3.34693-2.54616,7.57888.04548,9.95021,2.68207,2.90077,3.22526,4.54236,7.38866,7.26273,10.76459a25.91183,25.91183,0,0,0,8.9848,7.15524c6.94111,3.27752,15.40765,2.92923,21.59881-1.84663,7.39283-5.70282,8.1469-15.58664,8.18623-24.21a1.5009,1.5009,0,0,0-3,0c-.03564,7.81532-.48018,17.39559-7.606,22.28575-6.82609,4.68443-15.79715,3.442-22.08155-1.54922-3.502-2.78134-5.65633-6.40818-7.95574-10.16383-2.24445-3.66588-5.03132-7.22-9.25516-8.64559a8.36411,8.36411,0,0,0-9.986,3.4866,6.34658,6.34658,0,0,0,2.93421,9.0659c4.89777,2.33384,10.50083-.40085,15.21683-1.95938l16.59636-5.48469c1.82454-.603,1.043-3.50107-.79752-2.89284Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#6c63ff" />
                                        <polygon points="285.224 587.176 275.389 590.416 258.211 554.026 272.726 549.244 285.224 587.176" fill="#9f616a" />
                                        <path
                                            d="M689.11,750.78l-.67-2.03-1.76-5.35-1.2-3.63995-3.28,1.08-.68-.05-9.01-.61-1.2-.08-1.78-.12-1.84-.13-5.14,7.47h-.01a15.25721,15.25721,0,0,0-8.92,7.68c-.11.22-.21.44-.31.66a12.84856,12.84856,0,0,0-.63,1.66,15.21387,15.21387,0,0,0-.42,7.57995,17.39516,17.39516,0,0,0,.49,1.85l.03.1.12.37.72-.24,6.32-2.08,22.14-7.29,4.38995-1.45,1.35-.45h.01l2.62-.86Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#2f2e41" />
                                        <polygon points="192.004 595.731 181.65 595.73 176.724 555.792 192.006 555.793 192.004 595.731" fill="#9f616a" />
                                        <path
                                            d="M572.30444,575.99249l-4.59012,7.94008s-5.04361,1.37761-3.39526,17.81863c1.16394,11.60935,1.54142,63.58967,4.4719,97.359,1.21963,14.05441,2.88147,24.95445,5.22627,28.477l1.44353,1.2929,15.06687,1.43981.37991-.13288,5.53277-39.07637A65.654,65.654,0,0,0,596,670v0l-1-11,13.8729-55.69726S623,639,632,673c2.1665,8.18456,10.39581,13.38972,13.51577,20.41292,1.04534,2.35311,2.07242,4.63315,3.07449,6.82078C655.18925,714.64,658.497,725.12843,661,726h2l12-4,2.3606-5.93511L659,662l-13.74915-87.29443C619.05353,560.61945,594.47925,559.05219,572.30444,575.99249Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#2f2e41" />
                                        <path
                                            d="M647.35,458.37a40.79027,40.79027,0,0,0-3.18-5.73A29.45877,29.45877,0,0,0,633,442.43l-3.94-.87L622,440l-3.72-4.17-6.1-6.83h-21l-2.81,6.83-2.36,5.73L585,444l-17.98,8.99c-.01,0-.01,0-.02.01q.36,2.7.71,5.37.76492,5.805,1.44,11.47.345,2.88006.66,5.73,1.005,8.93994,1.73,17.57.645,7.70994,1.03,15.2a334.12564,334.12564,0,0,1,.14,34.05c-.14,2.67,2.51,5.71,2.3,8.32-.35,4.26-3.64,8.04-4.22,12.11005-.36,2.5-.2,5.16-.83,7.52a8.41131,8.41131,0,0,1-.78,1.98c0,.01-.01.01-.01.02-7.2,12.86-1.55,6.72-2.22,9.61l.32.08c.15.04.61.16,1.35.33C572.85,583.37,590.29,567,602,567c7.03,0,25.64,11.56,31.87,12.4,4.93.66,9.33,1.26,11.95-1.05a6.12509,6.12509,0,0,0,1.69-2.48l.03-.09-.01-.09-.25-3.35-.15-2-2.14-28.7a10.17472,10.17472,0,0,1-1.3-10.69l.44-.93005-1.62-21.68-.3-4.05-.11-1.37v-.01l-.78-10.47,1.88-5.72v-.01L646.86,475.57l1.88-5.73,1.18-3.6L650,466A40.93176,40.93176,0,0,0,647.35,458.37Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#6c63ff" />
                                        <path
                                            d="M665.59359,561.77947,645.43388,509.6776l-9.36331-19.025,18.005-9.94668,14.50089,25.54927,9.72047,53.38122a9.29014,9.29014,0,1,1-12.7033,2.14306Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#9f616a" />
                                        <path
                                            d="M666,490l-5.16-3.34L654.91,475.57l-3.06-5.73-1.93-3.6-.82-1.54-9.27-6.33-6.26-4.28-5.1,4.28-2.43,2.04,2.38,9.43,1.44,5.73L633,488l9.21,16.29,2.29,4.05,1.31,2.33.01.02.18.31,23.27-.97L670,510l.04-1.66.02-.6c.01-.3.01-.6.01-.9A36.95469,36.95469,0,0,0,666,490Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#6c63ff" />
                                        <path
                                            d="M489.87479,541.05921l41.241-37.68555,14.333-15.62653,15.80017,13.17079-18.54935,22.78075L496.47975,552.12a9.29014,9.29014,0,1,1-6.605-11.0608Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#9f616a" />
                                        <path
                                            d="M575.01,475.57l-.08-5.73-.15-11.47-.06006-4.45-.17-.12a7.51537,7.51537,0,0,0-3.09-1.16,8.95876,8.95876,0,0,0-2.93,0,10.47433,10.47433,0,0,0-1.51.35c-.01,0-.01,0-.02.01a17.29848,17.29848,0,0,0-7.57,5.36l-.01.01a74.68164,74.68164,0,0,0-7.94,11.47c-1.13,1.92-2.21,3.86-3.22,5.73-3.82,7.11-6.57,13.27-6.62,13.38l-11.42,14.83.16.27,2.51,4.29,9.2,15.73,1.46,2.5,3.73-1.99A21.68709,21.68709,0,0,0,557,514l1.33-5.08.49-.58,12.72-15.2,3.64-4.36Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#6c63ff" />
                                        <circle cx="599.59286" cy="406.34644" r="19.25073" transform="translate(-294.42147 777.76748) rotate(-80.78251)" fill="#9f616a" />
                                        <path
                                            d="M580.98375,393.7265c-.85566-2.99456-2.56676-5.56132-1.71118-5.56132s1.28338-2.139,1.28338-2.139.85557-.42779,1.28338,0,.85557-2.99455.85557-2.99455.42781-1.28338.85557-.42779.42781.42779.85558-.85559,2.56689-2.139,2.56689-2.139,8.12809.42779,9.41147-1.28338,15.82838-.4278,18.82295,1.71117S630,386,623,398c4,7-1.97173,19.485-3.51473,20.96637-2.43993,2.34241-4.12908,3.187-15.67422,5.48425C601.55161,424.9002,596,406,596,406S581.83941,396.72106,580.98375,393.7265Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#2f2e41" />
                                        <path
                                            d="M589.5,749.51l-.62-.25-11.14-4.54-1.71-.69-7.22,5.48h-.01a15.39806,15.39806,0,0,0-15.38,15.39v.5h39.53V749.51Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#2f2e41" />
                                        <path
                                            d="M705.69,766.09a1.195,1.195,0,0,1-1.19,1.2H501.2a1.195,1.195,0,0,1,0-2.39H704.5A1.19289,1.19289,0,0,1,705.69,766.09Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#ccc" />
                                        <path
                                            d="M495.8,155.34A134.27434,134.27434,0,0,1,397.94,277.1c-.03.7-.06,1.4-.07,2.1A136.28889,136.28889,0,0,0,497.8,155.34Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                        <path
                                            d="M465.17,155.34a134.55148,134.55148,0,0,1-65.37,83.7c-.06.79-.1,1.57-.14,2.36a136.62309,136.62309,0,0,0,67.58-86.06Z"
                                            transform="translate(-396.87063 -155.33997)"
                                            fill="#fff" />
                                    </svg>
                                    <div class="flex flex-col items-center gap-5">
                                        <p class="text-2xl text-gray-800" t="translation:partial-views.documents.labels.type-not-found"></p>
                                        <p class="text-sm text-gray-600" t="translation:partial-views.documents.messages.type-not-found"></p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
                <template else>
                    <div class="flex flex-1 items-center justify-center px-8">
                        <div class="flex w-full rounded-sm bg-white px-60 py-60">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                    <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <ux-footer>
            <div class="flex flex-1 items-center justify-end gap-x-2">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                    <ux-button if.bind="!failed && canDownload" size="sm" color="primary" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="download()">
                        <span class="pointer-events-none" t="global.buttons.download"></span>
                    </ux-button>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
