import { LegalEntities, UsersApiClient } from '@wecore/sdk-core';
import { GetUserResponse, GetWorkspaceResponse, LegalApiClient, SignProcessingAgreementRequest, WorkspacesApiClient } from '@wecore/sdk-management';
import { isNotEmpty, validateState } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { base64ToFile } from '../../../../infra/utilities';

@containerless
@inject(UsersApiClient, WorkspacesApiClient, LegalApiClient)
export class TemplateProcessingAgreement {
    @bindable() public isLoading: boolean = false;
    @bindable() public company: GetWorkspaceResponse;
    @bindable() public version: LegalEntities;

    public firstName: string;
    public lastName: string;
    public today: Date = new Date();
    public location: string;
    public isAuthorized: boolean = false;
    public readDocument: boolean = false;
    public companyName: string;
    public coc: string;
    public city: string;
    public statutoryCity: string;
    public address: string;
    public zipCode: string;
    public legalForm: string;
    public jobTitle: string;

    private currentUser: GetUserResponse;

    public validation: any = {
        firstName: true,
        lastName: true,
        readDocument: true,
        location: true,
        isAuthorized: true,
        jobTitle: true,
        companyName: true,
        coc: true,
        statutoryCity: true,
        address: true,
        city: true,
        zipCode: true,
        legalForm: true
    };

    public constructor(
        private readonly usersApi: UsersApiClient, //
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly legalApi: LegalApiClient
    ) {}

    public async bound(): Promise<void> {
        // Fetch the full user and workspace
        this.currentUser = await this.usersApi.getAuthenticated();
        this.firstName = this.currentUser.firstName;
        this.lastName = this.currentUser.lastName;
        this.companyName = this.company.name;
        this.coc = this.company.coc;
        this.city = this.company.address?.city;
        this.statutoryCity = this.company.address?.city;
        this.address = this.company.address?.street;
        this.zipCode = this.company.address?.zipCode;
    }

    public validate(): boolean {
        this.validation.firstName = isNotEmpty(this.firstName);
        this.validation.lastName = isNotEmpty(this.lastName);
        this.validation.readDocument = this.readDocument;
        this.validation.location = isNotEmpty(this.location);
        this.validation.isAuthorized = this.isAuthorized;
        this.validation.jobTitle = isNotEmpty(this.jobTitle);
        this.validation.companyName = isNotEmpty(this.companyName);
        this.validation.coc = isNotEmpty(this.coc);
        this.validation.legalForm = isNotEmpty(this.legalForm);
        this.validation.statutoryCity = isNotEmpty(this.statutoryCity);
        this.validation.address = isNotEmpty(this.address);
        this.validation.zipCode = isNotEmpty(this.zipCode);
        this.validation.city = isNotEmpty(this.city);
        return validateState(this.validation);
    }

    public async signAndSave(base64: any): Promise<void> {
        // First save the signature.
        const filename = `signature-processing-agreement-${this.version.replace(/\./g, '')}.png`;
        const signature = base64ToFile(base64, filename);

        // Make sure the signature does not exists already.
        if (this.company.attachments.some((x) => x.name === filename.split('.')[0])) {
            const attachment = this.company.attachments.find((x) => x.name === filename.split('.')[0]);
            // If it exists, delete it.
            await this.workspacesApi.deleteAttachment(this.company.id, attachment.id);
        }

        // Save the signature to the current workspace.
        await this.workspacesApi.uploadAttachment(this.company.id, false, {
            data: signature,
            fileName: filename
        });

        await this.legalApi.createProcessingAgreement(
            this.company.id,
            new SignProcessingAgreementRequest({
                name: `${this.firstName} ${this.lastName}`,
                accepted: this.readDocument,
                when: this.today,
                jobTitle: this.jobTitle,
                isAuthorizedToSign: this.isAuthorized,
                version: this.version,
                company: this.companyName,
                coc: this.coc,
                legalForm: this.legalForm,
                statutoryCity: this.statutoryCity,
                city: this.city,
                location: this.location,
                address: this.address,
                zipCode: this.zipCode
            })
        );
    }
}
