import { MedicalReferralSystems } from '@wecore/sdk-healthcare';

export class ReferralSystemToStringValueConverter {
    public toView(system: MedicalReferralSystems): string {
        switch (system) {
            case MedicalReferralSystems.Zorgdomein:
                return 'Zorgdomein ';
            case MedicalReferralSystems.VitalHealth:
                return 'Vital Health';
            case MedicalReferralSystems.VipLive:
                return 'VIP Live';
            case MedicalReferralSystems.Pdf:
                return 'PDF';
        }
    }
}
