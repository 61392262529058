<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/get-ids" />
<import from="converters/token-type-to-string" />
<import from="bx/bx-email-template-token-selector/bx-email-template-token-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex gap-x-2 overflow-hidden" css.bind="styles">
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div ref="scrollContainer" class="flex flex-1 flex-col overflow-y-auto px-8">
                <template if.bind="baseLoaded">
                    <div class.bind="hasScrolled ? 'border-b' : ''" class="sticky top-0 z-40 flex justify-between border-gray-200 bg-white pb-3 pt-8">
                        <p class="font-medium text-gray-400" t="translation:partial-views.manage-email-templates.edit.title"></p>
                        <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                            <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path
                                        fill="currentColor"
                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                </svg>
                            </div>
                            <ux-dropdown-option click.trigger="copy()">
                                <span class="text-sm" t="global.buttons.copy"></span>
                            </ux-dropdown-option>
                            <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteEmailTemplates)"></ux-dropdown-divider>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteEmailTemplates)" click.trigger="delete()">
                                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </div>
                    <div class="grid grid-cols-12 gap-3">
                        <div class="col-span-8 flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="template.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.manage-email-templates.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="col-span-4 flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-email-templates.labels.type"> </span>
                            </ux-label>
                            <ux-select onselect.trigger="handleTypeSelected($event)" value.two-way="template.type">
                                <ux-select-option repeat.for="type of EmailEntityTypes | keys" value.bind="type">
                                    <div class="flex items-center space-x-1.5">
                                        <span>${type | tokenTypeToString | t} </span>
                                    </div>
                                </ux-select-option>
                            </ux-select>
                        </div>
                        <div class="col-span-12 flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="template.description[language]"
                                rows="2"
                                t="[placeholder]partial-views.manage-email-templates.placeholders.description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="col-span-12 flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.manage-email-templates.labels.subject"></span>
                                </ux-label>
                                <span class="flex h-3 items-center text-red-500">*</span>
                            </div>
                            <ux-textarea
                                ref="subject"
                                rows="1"
                                onblur.trigger="handleSubjectBlur($event)"
                                value.two-way="template.subject"
                                valid.bind="validation.subject"
                                t="[placeholder]partial-views.manage-email-templates.placeholders.subject">
                            </ux-textarea>
                            <p class="mt-2 text-xs text-gray-500" t="translation:partial-views.manage-email-templates.messages.add-token"></p>
                        </div>
                    </div>
                    <ux-body-divider margin="my-3"></ux-body-divider>
                    <div class="flex flex-1 flex-col pb-3">
                        <div class="mb-2 flex items-center justify-between">
                            <div class="flex-1">
                                <ux-tabs>
                                    <ux-tab active.bind="lang === contentLanguage" click.trigger="changeLanguage(lang)" repeat.for="lang of languages">
                                        <span t="global.languages.${lang}"></span>
                                    </ux-tab>
                                </ux-tabs>
                            </div>
                            <div class="w-80">
                                <bx-email-template-token-selector
                                    component.ref="tokenSelector"
                                    on-select.bind="handleTokenSelected"
                                    language.bind="language"
                                    types.bind="['Other', EmailEntityTypes.Patient, request.type]"
                                    t="[placeholder]translation:partial-views.manage-email-templates.placeholders.select-template-token">
                                </bx-email-template-token-selector>
                            </div>
                        </div>
                        <div class="mt-1 flex flex-1 flex-col rounded-lg">
                            <div class.bind="validation.content ? '' : 'invalid'" id="toolbar">
                                <div id="toolbar-container">
                                    <!-- <span class="ql-formats">
                                        <select class="ql-font"></select>
                                             <select className="ql-font" defaultValue="arial">
                                                <option value="arial">Arial</option>
                                                <option value="comic-sans">Comic Sans</option>
                                                <option value="courier-new">Courier New</option>
                                                <option value="georgia">Georgia</option>
                                                <option value="helvetica">Helvetica</option>
                                                <option value="lucida">Lucida</option>
                                            </select>
                                        <select class="ql-size"></select>
                                    </span> -->
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color"></select>
                                        <select class="ql-background"></select>
                                    </span>
                                    <!-- <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span> -->
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <select class="ql-header">
                                            <option value="1">Heading 1</option>
                                            <option value="2">Heading 2</option>
                                            <option value="3">Heading 3</option>
                                            <option value="4">Heading 4</option>
                                            <option value="5">Heading 5</option>
                                            <option value="6">Heading 6</option>
                                            <option value>Default</option>
                                        </select>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction" value="rtl"></button>
                                        <select class="ql-align"></select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                        <!-- <button class="ql-image"></button>
                                        <button class="ql-video"></button> -->
                                        <!-- <button class="ql-formula"></button> -->
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-clean"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <!-- But you can also add your own -->
                                        <button class="text-red-500" click.trigger="clearHtml()">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div class.bind="validation.content ? '' : 'invalid'" ref="editor"></div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-between gap-x-2 border-t border-gray-200 bg-white py-4">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading || deleting"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
