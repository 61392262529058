<import from="converters/translate" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between mb-3">
                <p if.bind="type === HealthcareTaskLabelTypes.Priority" class="text-sm font-medium" t="translation:partial-views.tasks.priorities.list.title"></p>
                <p if.bind="type === HealthcareTaskLabelTypes.Status" class="text-sm font-medium" t="translation:partial-views.tasks.statuses.list.title"></p>
                <p if.bind="type === HealthcareTaskLabelTypes.Label" class="text-sm font-medium" t="translation:partial-views.tasks.labels.list.title"></p>
                <ux-button click.trigger="create()" padding.bind="false" size="xs">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </ux-button>
            </div>
            <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full px-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <ul class="flex flex-col flex-1 gap-2 pb-6 mt-4">
                    <li repeat.for="label of labels" class="group">
                        <a click.trigger="edit(label)" id.bind="label.id" href="javascript:" class="flex items-center gap-3 pb-2 border-b border-gray-200">
                            <div class="flex items-center justify-center px-3 py-1 rounded-full flex-nowrap" css="background-color: ${label.backgroundColor}; color: ${label.textColor}">
                                <p class="text-sm whitespace-nowrap">${label.name[language]}</p>
                            </div>
                            <p if.bind="label.description" class="text-xs text-gray-600">${label.description[language]}</p>
                            <p else class="text-xs text-gray-600" t="translation:partial-views.tasks.messages.no-description"></p>
                        </a>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="phases.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-4 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <ux-footer>
            <div class="flex justify-end flex-1 gap-x-2">
                <ux-button size="sm" color="dark" click.trigger="close()">
                    <span class="pointer-events-none" t="global.buttons.close"></span>
                </ux-button>
            </div>
        </ux-footer>
    </section>
</template>
