import { ScheduleTimeSlotParticulars } from '@wecore/sdk-healthcare';

export class ScheduleParticularToStringValueConverter {
    public toView(particular: ScheduleTimeSlotParticulars): string {
        switch (particular) {
            case ScheduleTimeSlotParticulars.EvenWeek:
                return 'translation:global.labels.schedule-particulars.even-week';
            case ScheduleTimeSlotParticulars.OddWeek:
                return 'translation:global.labels.schedule-particulars.odd-week';
            case ScheduleTimeSlotParticulars.FirstOfMonth:
                return 'translation:global.labels.schedule-particulars.first-of-month';
            case ScheduleTimeSlotParticulars.LastOfMonth:
                return 'translation:global.labels.schedule-particulars.last-of-month';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
