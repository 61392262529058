import { MedicalTherapyEvaluationItemTypes } from '@wecore/sdk-healthcare';

export class TherapyProtocolTypeToStringValueConverter {
    public toView(type: MedicalTherapyEvaluationItemTypes): string {
        switch (type) {
            case MedicalTherapyEvaluationItemTypes.Action:
                return 'translation:partial-views.medical-therapies.labels.action';
            case MedicalTherapyEvaluationItemTypes.Question:
                return 'translation:partial-views.medical-therapies.labels.question';
            case MedicalTherapyEvaluationItemTypes.Questionnaire:
                return 'translation:partial-views.medical-therapies.labels.questionnaire';
            case MedicalTherapyEvaluationItemTypes.Widget:
                return 'translation:global.labels.widget';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
