import { isDefined } from '@wecore/sdk-utilities';

export class FormatAddressValueConverter {
    public toView(fullAddress: string): string {
        const parts = fullAddress.split(',');

        let html = `<p class="font-semibold leading-tight">${parts[0]}</p>`;

        if (isDefined(parts[1])) {
            html += `<p class="text-sm leading-tight text-gray-600">${parts[1]}`;
            if (isDefined(parts[2])) html += `, ${parts[2]}`;
            html += '</p>';
        }

        return html;
    }
}
