import { DialogService } from '@aurelia/dialog';
import { I18N } from '@aurelia/i18n';
import { Address } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';

import { PdokApiClient, SuggestPdokAddressesSuggestion } from '@wecore/sdk-integrations';
import { bindable, containerless, inject } from 'aurelia';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import { ComponentMap } from '../../components/component-map/component-map';
import { Countries } from '../../infra/countries';
import { ErrorHandler } from '../../infra/error-handler';
import { ModalCustomDropdownValue } from '../../modals/modal-custom-dropdown-value/modal-custom-dropdown-value';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@containerless
@inject(DialogService, PdokApiClient, I18N, ErrorHandler)
export class BxAddressSelector {
    @bindable() public label: string;
    @bindable() public descriptions: { [key: string]: string }[];
    @bindable() public validation: any[];
    @bindable() public language: string;
    @bindable() public mapHeight: number = 400;
    @bindable() public maxAmount: number = -1;
    @bindable() public useLabels: boolean = true;
    @bindable() public valid: boolean = true;
    @bindable() public addresses: Address[];
    @bindable() public mode: 'horizontal' | 'vertical ' = 'horizontal';
    @bindable() public onSelect: (address: Address, action: 'add' | 'delete') => void;

    public map: ComponentMap;
    public countries: any[] = Countries.getAll();
    public set: any[];
    public combobox: UxCombobox;

    public suggestions: SuggestPdokAddressesSuggestion[] = [];

    public constructor(
        private readonly dialogService: DialogService, //
        private readonly pdokApi: PdokApiClient,
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {}

    public attaching(): void {
        this.set = this.countries.map((x) => ({ text: this.t.tr(`translation:global.countries.${x.code}`), value: x.code }));
    }

    public attached(): void {
        // Wait for the map to be loaded
        setTimeout(async () => {
            if (isNotDefined(this.map)) return;
            for (const address of this.addresses) {
                if (isNotDefined(address.coordinates)) continue;
                await this.map.addMarker({
                    id: address.id,
                    coordinates: [address.coordinates.longitude, address.coordinates.latitude]
                });
            }
            this.flyOrFit();
        }, 1000);
    }

    public delete(index: any): void {
        const address = this.addresses[index];
        this.addresses.splice(index, 1);

        if (isFunction(this.onSelect)) this.onSelect(address, 'delete');
        if (isNotDefined(this.map)) return;
        if (isDefined(address.coordinates)) this.map.removeMarker(address.id);

        setTimeout(() => {
            this.map.resize();
            this.flyOrFit();
        });
    }

    public async handleChange(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        const option = e.detail.values;
        const index = e.detail.data;

        if (option.value === this.t.tr('translation:global.labels.custom', { lng: this.language })) {
            const response = await this.dialogService.open({
                component: () => ModalCustomDropdownValue,
                model: {
                    language: this.language
                },
                overlayDismiss: true
            });

            const result = await response.dialog.closed;
            const newValue = result.value as { [key: string]: string };
            const existingValue = this.descriptions.find((x) => x[this.language].toLowerCase() === newValue[this.language].toLowerCase());

            // Make sure the entered value is not the
            // same as one of the existing values.
            if (isDefined(existingValue)) {
                this.addresses[index].translations = existingValue;
                return;
            }

            this.descriptions = [newValue, ...this.descriptions];
            this.addresses[index].translations = newValue;
        } else this.addresses[index].translations = option.data;
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;

            this.suggestions = isDefined(query) ? await this.pdokApi.suggest(query) : [];

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxAddressSelector.handleSearch', e);
        }
    }

    public async handleSelect(e: CustomEvent<EventDetails<UxCombobox, UxComboboxOption>>): Promise<void> {
        const id = e.detail.values.value;

        const lookup = await this.pdokApi.lookup(id);
        const address = new Address({
            id,
            type: this.t.tr('translation:global.labels.home-address'),
            street: lookup.street,
            houseNumber: lookup.houseNumber,
            houseNumberSuffix: lookup.houseNumberSuffix,
            city: lookup.city,
            province: lookup.provinceName,
            zipCode: lookup.zipCode,
            country: 'NL',
            coordinates: lookup.coordinates,
            translations: {
                nl: this.t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                en: this.t.tr('translation:global.labels.home-address', { lng: 'en' })
            }
        });
        this.addresses.push(address);

        if (isFunction(this.onSelect)) this.onSelect(address, 'add');
        if (isNotDefined(this.map)) return;

        const coordinates: LngLatLike = [address.coordinates.longitude, address.coordinates.latitude];

        await this.map.addMarker({ id: address.id, coordinates });

        this.map.resize();
        this.flyOrFit(coordinates);
    }

    public resizeMap(): void {
        if (isNotDefined(this.map)) return;
        this.map.resize();
    }

    private flyOrFit(coordinates: LngLatLike = null): void {
        if (isNotDefined(this.map)) return;

        if (this.addresses.length === 1 && isDefined(coordinates)) this.map.flyTo({ coordinates });
        else if (this.addresses.length === 1 && isNotDefined(coordinates) && isDefined(this.addresses[0].coordinates))
            this.map.flyTo({
                coordinates: [this.addresses[0].coordinates.longitude, this.addresses[0].coordinates.latitude], //
                zoom: 13
            });
        else if (this.addresses.length > 1) {
            this.map.fitBounds({
                bounds: this.addresses
                    .filter((x) => isDefined(x.coordinates))
                    .reduce((bounds, address) => {
                        bounds.extend([address.coordinates.longitude, address.coordinates.latitude]);
                        return bounds;
                    }, new LngLatBounds()),

                zoom: 5
            });
        } else this.map.flyToDefaultPosition();
    }
}
