<import from="./ux-checkbox.scss"></import>

<template>
    <div disabled.class="disabled" negative.class="useNegativeColors" invalid.class="!valid" without-label.class="!hasLabel" class="checkbox flex w-full flex-1 items-center justify-center">
        <label for="checkbox-${guid}" ref="label" class="mb-0 flex w-full flex-1">
            <input
                type="checkbox"
                disabled.bind="disabled"
                blur.trigger="handleBlur($event)"
                focus.trigger="handleFocus($event)"
                id="checkbox-${guid}"
                ref="checkbox"
                change.trigger="handleChecked($event)" />
            <div class="checkmark border" has-focus.class="hasFocus" relative.class="hasLabel" without-label.class="!hasLabel" absolute.class="!hasLabel"></div>
            <section class="ml-2 flex">
                <au-slot />
            </section>
        </label>
    </div>
</template>
