import { GetAccountResponse } from '@wecore/sdk-crm';
import { bindable } from 'aurelia';

export class TabAccountsConnections {
    @bindable() public account: GetAccountResponse;
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: any;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
}
