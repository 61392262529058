<import from="converters/translate" />
<import from="../../widgets/widget-course-of-complaints/widget-course-of-complaints" />
<import from="../../widgets/widget-examination-conclusion/widget-examination-conclusion" />
<import from="../../widgets/widget-examination-treatment/widget-examination-treatment" />
<import from="../../widgets/widget-examination-treatment-plan/widget-examination-treatment-plan" />
<import from="../../widgets/widget-ketenzorg/widget-ketenzorg" />
<import from="../../widgets/widget-patient-addresses/widget-patient-addresses" />
<import from="../../widgets/widget-patient-emails/widget-patient-emails" />
<import from="../../widgets/widget-patient-general-practitioner/widget-patient-general-practitioner" />
<import from="../../widgets/widget-patient-intake/widget-patient-intake" />
<import from="../../widgets/widget-patient-labels/widget-patient-labels" />
<import from="../../widgets/widget-patient-length/widget-patient-length" />
<import from="../../widgets/widget-patient-links/widget-patient-links" />
<import from="../../widgets/widget-patient-medical-equipment/widget-patient-medical-equipment" />
<import from="../../widgets/widget-patient-medical-history/widget-patient-medical-history" />
<import from="../../widgets/widget-patient-medical-reporting/widget-patient-medical-reporting" />
<import from="../../widgets/widget-patient-medications/widget-patient-medications" />
<import from="../../widgets/widget-patient-phones/widget-patient-phones" />
<import from="../../widgets/widget-patient-processing-data/widget-patient-processing-data" />
<import from="../../widgets/widget-patient-professions/widget-patient-professions" />
<import from="../../widgets/widget-patient-shoesize/widget-patient-shoesize" />
<import from="../../widgets/widget-patient-sports/widget-patient-sports" />
<import from="../../widgets/widget-patient-weight/widget-patient-weight" />
<import from="../../widgets/widget-voice-memos/widget-voice-memos" />
<import from="../../widgets/widget-voxelcare/widget-voxelcare" />
<import from="../../widgets/widget-zebris-treadmill/widget-zebris-treadmill" />

<!-- The widget should always be on the registration. The only time it is not
    and it is on the step, is when the widget is part of the widget
    called 'widget-examination-treatment'  -->

<let widget.bind="registration.widget || step.widget"></let>

<div class="${columns} flex flex-col" if.bind="shouldBeDisplayed && widget && registration">
    <widget-course-of-complaints
        if.bind="widget.type === MedicalWidgetTypes.CourseOfComplaints"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        record.bind="record"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        add-partial.bind="addPartial"
        scroll-to-view.bind="scrollToView"
        language.bind="language">
    </widget-course-of-complaints>
    <widget-examination-conclusion
        if.bind="widget.type === MedicalWidgetTypes.ExaminationConclusion"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        record.bind="record"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        add-partial.bind="addPartial"
        registrations.bind="registrations"
        language.bind="language"
        loading.bind="loading">
    </widget-examination-conclusion>
    <widget-examination-treatment
        if.bind="widget.type === MedicalWidgetTypes.ExaminationTreatment"
        flattened.bind="flattened"
        registration.bind="registration"
        registrations.bind="registrations"
        validation.bind="allValidation"
        states.bind="states"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        x-scroll-container.bind="xScrollContainer"
        record.bind="record"
        workspace.bind="workspace"
        on-file-selected.bind="onFileSelected"
        on-file-removed.bind="onFileRemoved"
        patient.bind="patient"
        add-partial.bind="addPartial"
        manage-translations.bind="manageTranslations"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-examination-treatment>
    <widget-examination-treatment-plan
        if.bind="widget.type === MedicalWidgetTypes.ExaminationTreatmentPlan && registrations"
        flattened.bind="flattened"
        registration.bind="registration"
        registrations.bind="registrations"
        validation.bind="allValidation"
        states.bind="states"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        x-scroll-container.bind="xScrollContainer"
        record.bind="record"
        workspace.bind="workspace"
        on-file-selected.bind="onFileSelected"
        on-file-removed.bind="onFileRemoved"
        patient.bind="patient"
        add-partial.bind="addPartial"
        manage-translations.bind="manageTranslations"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-examination-treatment-plan>
    <widget-ketenzorg
        if.bind="widget.type === MedicalWidgetTypes.Ketenzorg"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-ketenzorg>
    <widget-patient-addresses
        if.bind="widget.type === MedicalWidgetTypes.PatientAddresses"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-addresses>
    <widget-patient-emails
        if.bind="widget.type === MedicalWidgetTypes.PatientEmailAdresses"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-emails>
    <widget-patient-general-practitioner
        if.bind="widget.type === MedicalWidgetTypes.PatientGeneralPractitioner"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        add-partial.bind="addPartial"
        loading.bind="loading">
    </widget-patient-general-practitioner>
    <widget-patient-intake
        if.bind="widget.type === MedicalWidgetTypes.IntakeRefferal"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        record.bind="record"
        on-file-selected.bind="onFileSelected"
        on-file-removed.bind="onFileRemoved"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-intake>
    <widget-patient-labels
        if.bind="widget.type === MedicalWidgetTypes.PatientLabels"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        record.bind="record"
        language.bind="language"
        manage-translations.bind="manageTranslations"
        current-user.bind="currentUser"
        loading.bind="loading">
    </widget-patient-labels>
    <widget-patient-length
        if.bind="widget.type === MedicalWidgetTypes.PatientLength"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-length>
    <widget-patient-links
        if.bind="widget.type === MedicalWidgetTypes.PatientWebLinks"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-links>
    <widget-patient-medical-equipment
        if.bind="widget.type === MedicalWidgetTypes.PatientMedicalEquipment"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        manage-translations.bind="manageTranslations"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-medical-equipment>
    <widget-patient-medical-history
        if.bind="widget.type === MedicalWidgetTypes.PatientMedicalHistory"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-medical-history>
    <widget-patient-medical-reporting
        if.bind="widget.type === MedicalWidgetTypes.PatientAgreementMedicalReporting"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-medical-reporting>
    <widget-patient-medications
        if.bind="widget.type === MedicalWidgetTypes.PatientMedication"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        manage-translations.bind="manageTranslations"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-medications>
    <widget-patient-phones
        if.bind="widget.type === MedicalWidgetTypes.PatientPhoneNumbers"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-phones>
    <widget-patient-processing-data
        if.bind="widget.type === MedicalWidgetTypes.PatientAgreementProcessingData"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-processing-data>
    <widget-patient-professions
        if.bind="widget.type === MedicalWidgetTypes.PatientProfession"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        manage-translations.bind="manageTranslations"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-professions>
    <widget-patient-shoesize
        if.bind="widget.type === MedicalWidgetTypes.PatientShoeSize"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-shoesize>
    <widget-patient-sports
        if.bind="widget.type === MedicalWidgetTypes.PatientSports"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        manage-translations.bind="manageTranslations"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-sports>
    <widget-patient-weight
        if.bind="widget.type === MedicalWidgetTypes.PatientWeight"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-patient-weight>
    <widget-voice-memos
        if.bind="widget.type === MedicalWidgetTypes.VoiceMemos"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        record.bind="record"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading"
        x-scroll-container.bind="xScrollContainer"
        record.bind="record"
        on-file-selected.bind="onFileSelected"
        on-file-removed.bind="onFileRemoved">
    </widget-voice-memos>
    <widget-voxelcare
        if.bind="widget.type === MedicalWidgetTypes.VoxelcareOrder"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        workspace.bind="workspace"
        patient.bind="patient"
        record.bind="record"
        widgets.bind="widgets"
        language.bind="language"
        loading.bind="loading">
    </widget-voxelcare>
    <widget-zebris-treadmill
        if.bind="widget.type === MedicalWidgetTypes.ZebrisTreadmill"
        registration.bind="registration"
        validation.bind="validation"
        flow.bind="flow"
        step.bind="step"
        required.bind="required"
        x-scroll-container.bind="xScrollContainer"
        workspace.bind="workspace"
        patient.bind="patient"
        widgets.bind="widgets"
        record.bind="record"
        on-file-selected.bind="onFileSelected"
        on-file-removed.bind="onFileRemoved"
        language.bind="language"
        loading.bind="loading">
    </widget-zebris-treadmill>
</div>
<template if.bind="!widget">
    <div class="${columns} flex items-center justify-between rounded-lg bg-red-100 px-3 py-2 text-sm font-medium text-red-600">
        <div class="flex min-w-0 flex-1 items-center gap-x-3">
            <span class="text-gray-500" t="translation:global.labels.widget"></span>
            <div class="flex flex-1 justify-between">
                <span class="text-gray-800">${ step.widget.translations | translate:language }</span>
                <div class="flex gap-x-3">
                    <span class="block truncate" t="translation:global.messages.deleted-widget"></span>
                    <button
                        click.trigger="remove(step.id)"
                        class="flex h-5 w-5 items-center justify-center rounded-full text-red-500 ring-red-500 ring-offset-1 hover:text-red-600 focus:outline-none focus:ring-1 active:text-red-700">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
