import { I18N } from '@aurelia/i18n';
import { GetUserResponse } from '@wecore/sdk-core';
import { GetHealthcareTaskLabelResponse, HealthcareTaskLabelTypes, HealthcareTaskLabelsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@inject(HealthcareTaskLabelsApiClient, I18N, ErrorHandler)
export class BxTaskLabelSelector {
    @bindable() public value: string;
    @bindable() public selected: GetUserResponse;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public type: HealthcareTaskLabelTypes = HealthcareTaskLabelTypes.Label;
    @bindable() public onSelect: (user: GetUserResponse, data: any) => void;

    public combobox: UxCombobox;
    public labels: GetHealthcareTaskLabelResponse[];

    public constructor(
        private readonly labelsApi: HealthcareTaskLabelsApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public handleClear(): void {
        if (isFunction(this.onSelect)) this.onSelect(null, this.data);
    }

    public async attached(): Promise<void> {
        try {
            let label: GetHealthcareTaskLabelResponse;
            if (isNotEmpty(this.value)) {
                label = await this.labelsApi.getById(this.value, this.workspace);
                // If the selected item is not found, use the selected object which can
                // be provided by the user and used to display the object name in the combobox.
                // This can be handy in case the item has been deleted.
                if (isNotDefined(label)) label = this.selected;
            }

            const response = await this.labelsApi.search(this.workspace, '', 500, 0, undefined, undefined, undefined, undefined, [this.type]);
            const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
            this.labels = isDefined(label) ? [label].concat(data.filter((x) => x.id !== this.value)) : data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxTaskLabelSelector.attached', e);
        }
    }

    public setValue(value: string) {
        if (isDefined(this.combobox)) this.combobox.setValue(value);
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.labelsApi.search(this.workspace, isNotEmpty(query) ? query : '', 500, 0, undefined, undefined, undefined, undefined, [this.type]);
            this.labels = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxTaskLabelSelector.handleSearch', e);
        }
    }

    public async handleSelect(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        if (e.detail.values.customInput as boolean) return;
        const option = e.detail.values as UxComboboxOption;

        const label = this.labels.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(label, this.data);
    }
}
