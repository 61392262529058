import { GetMedicalExaminationActionResponse, MedicalExaminationActionItem, MedicalExaminationActionItemTypes, MedicalExaminationActionStepCategory } from '@wecore/sdk-healthcare';

export class FlattenActionStepsValueConverter {
    public toView(action: GetMedicalExaminationActionResponse): any[] {
        const flatten = (items: MedicalExaminationActionItem[], steps: any[], category: MedicalExaminationActionStepCategory = null): any[] => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) steps = flatten(item.category.stepsToTake, steps, item.category);
                else steps = [...steps, { category, step: item.step }];
            }
            return steps;
        };

        const flattened = flatten(action.stepsToTake, []);
        return flattened;
    }
}
