<import from="converters/replace" />

<div class="flex h-[34px] items-center gap-1 rounded-lg bg-gray-100 px-3 py-2 text-xs lowercase text-gray-700">
    <span class="whitespace-nowrap">${key}:${display(value)}</span>
    <button
        click.trigger="remove()"
        class="flex items-center justify-center text-red-500 border border-red-500 rounded-full ring-red-500 ring-offset-1 hover:bg-red-100 focus:ring-1 active:bg-red-200">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-3 h-3">
            <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clip-rule="evenodd" />
        </svg>
    </button>
</div>
