import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    DownloadCreditInvoiceRequest,
    DownloadRemainingInvoiceRequest,
    DownloadRestitutionInvoiceRequest,
    GetHealthcareInvoiceResponse,
    GetPatientResponse,
    HealthcareInvoicesApiClient,
    Insurance,
    InsuranceStatuses,
    InsuranceTypes,
    PatientsApiClient,
    PreviewCreditInvoiceRequest,
    PreviewRemainingInvoiceRequest,
    PreviewRestitutionInvoiceRequest,
    SendCreditInvoiceRequest,
    SendEmailRequest,
    SendRemainingInvoiceRequest,
    SendRestitutionInvoiceRequest
} from '@wecore/sdk-healthcare';
import { serveBlob } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareInvoicesApiClient, PatientsApiClient, ModalService)
export class PartialHealthcareInvoicesPreview extends BasePartialView {
    public patient: GetPatientResponse;
    public invoice: GetHealthcareInvoiceResponse;
    public type: 'restitution' | 'remaining' | 'credit';
    public pdf: string;
    public view: 'insurances' | 'preview' = 'insurances';
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;
    private patientId: string;
    private invoiceId: string;
    private insurance: Insurance;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly invoicesApi: HealthcareInvoicesApiClient,
        private readonly patientApi: PatientsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.type = view.data.type;
        this.patientId = view.data.patient;
        this.invoiceId = view.data.invoice;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [patient, invoice] = await Promise.all([
                    this.patientApi.getById(this.patientId, this.authenticated.workspace.id),
                    this.invoicesApi.getById(this.invoiceId, this.authenticated.workspace.id)
                ]);

                this.patient = patient;
                this.invoice = invoice;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialHealthcareInvoicesPreview.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok
        });
    }

    public select(choice: { label: string; groupId: string }): void {
        super.remove({
            result: PartialViewResults.Ok,
            data: choice.groupId
        });
    }

    public async preview(insurance: Insurance): Promise<void> {
        this.baseLoaded = false;
        try {
            const blob =
                this.type === 'restitution' //
                    ? await this.invoicesApi.previewRestitution(
                          this.invoiceId,
                          this.authenticated.workspace.id,
                          new PreviewRestitutionInvoiceRequest({
                              insurance
                          })
                      )
                    : this.type === 'remaining'
                      ? await this.invoicesApi.previewRemaining(
                            this.invoiceId,
                            this.authenticated.workspace.id,
                            new PreviewRemainingInvoiceRequest({
                                insurance
                            })
                        )
                      : await this.invoicesApi.previewCredit(
                            this.invoiceId,
                            this.authenticated.workspace.id,
                            new PreviewCreditInvoiceRequest({
                                insurance
                            })
                        );

            this.insurance = insurance;
            this.pdf = URL.createObjectURL(blob.data);
            this.view = 'preview';
        } catch (e) {
            this.errorHandler.handle('[preview-invoice]', e);
        }
        this.baseLoaded = true;
    }

    public async covCheck(): Promise<void> {
        super.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: this.PartialViews.PatientCovCheck.with({ id: this.patientId }).whenClosed(async (result: PartialViewResults) => {}),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: false })
        });
    }

    public async download(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.healthcare-invoices.questions.download.title'),
                message: this.t.tr('translation:partial-views.healthcare-invoices.questions.download.message'),
                btnOk: this.t.tr('translation:global.buttons.download'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.isLoading = true;
                        try {
                            let attachment;
                            let blob;

                            if (this.type === 'restitution') {
                                blob = await this.invoicesApi.downloadRestitution(
                                    this.invoice.id,
                                    this.authenticated.workspace.id,
                                    new DownloadRestitutionInvoiceRequest({
                                        insurance: this.insurance
                                    })
                                );
                                this.invoice = await this.invoicesApi.getById(this.invoice.id, this.authenticated.workspace.id);
                                attachment = this.invoice.attachments.find((a) => a.entityType === 'RestitutionInvoice');
                            }

                            if (this.type === 'remaining') {
                                blob = await this.invoicesApi.downloadRemaining(
                                    this.invoice.id,
                                    this.authenticated.workspace.id,
                                    new DownloadRemainingInvoiceRequest({
                                        insurance: this.insurance
                                    })
                                );
                                this.invoice = await this.invoicesApi.getById(this.invoice.id, this.authenticated.workspace.id);
                                attachment = this.invoice.attachments.find((a) => a.entityType === 'RemainingInvoice');
                            }

                            if (this.type === 'credit') {
                                blob = await this.invoicesApi.downloadCredit(
                                    this.invoice.id,
                                    this.authenticated.workspace.id,
                                    new DownloadCreditInvoiceRequest({
                                        insurance: this.insurance
                                    })
                                );
                                this.invoice = await this.invoicesApi.getById(this.invoice.id, this.authenticated.workspace.id);
                                attachment = this.invoice.attachments.find((a) => a.entityType === 'CreditInvoice');
                            }

                            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
                            this.events.publish(CustomEvents.HealthcareInvoicesUpdated, this.invoice);
                            this.remove({ result: PartialViewResults.Ok, updateUrl: true });
                        } catch (e) {
                            this.isLoading = false;
                            this.errorHandler.handle('[download-invoice]', e);
                        }
                    }
                }
            })
        );
    }

    public async send(): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.SendEmailToPatient.with({
                id: this.patient.id,
                settings: {
                    forceZorgmail: true
                },
                onSend: async (log: SendEmailRequest) => {
                    await this.modalService.confirm(
                        new ConfirmationOptions({
                            title: this.t.tr('translation:partial-views.healthcare-invoices.questions.send.title'),
                            message: this.t.tr('translation:partial-views.healthcare-invoices.questions.send.message'),
                            btnOk: this.t.tr('translation:global.buttons.send'),
                            callback: async (confirmed: boolean): Promise<void> => {
                                if (confirmed) {
                                    this.isLoading = true;
                                    try {
                                        if (this.type === 'restitution') {
                                            const request = new SendRestitutionInvoiceRequest({
                                                to: log.to,
                                                cc: log.cc,
                                                bcc: log.bcc,
                                                subject: log.subject,
                                                content: log.content,
                                                template: log.template,
                                                insurance: this.insurance
                                            });
                                            this.invoice = await this.invoicesApi.sendRestitution(this.invoice.id, this.authenticated.workspace.id, request);
                                        }

                                        if (this.type === 'remaining') {
                                            const request = new SendRemainingInvoiceRequest({
                                                to: log.to,
                                                cc: log.cc,
                                                bcc: log.bcc,
                                                subject: log.subject,
                                                content: log.content,
                                                template: log.template,
                                                insurance: this.insurance
                                            });
                                            this.invoice = await this.invoicesApi.sendRemaining(this.invoice.id, this.authenticated.workspace.id, request);
                                        }

                                        if (this.type === 'credit') {
                                            const request = new SendCreditInvoiceRequest({
                                                to: log.to,
                                                cc: log.cc,
                                                bcc: log.bcc,
                                                subject: log.subject,
                                                content: log.content,
                                                template: log.template,
                                                insurance: this.insurance
                                            });
                                            this.invoice = await this.invoicesApi.sendCredit(this.invoice.id, this.authenticated.workspace.id, request);
                                        }

                                        this.events.publish(CustomEvents.HealthcareInvoicesUpdated, this.invoice);
                                        this.remove({ result: PartialViewResults.Ok, updateUrl: true });
                                    } catch (e) {
                                        this.isLoading = false;
                                        this.errorHandler.handle('[sent-invoice]', e);
                                    }
                                }
                            }
                        })
                    );
                }
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true })
        });
    }
}
