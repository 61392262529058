<import from="converters/date" />
<import from="converters/currency" />
<import from="converters/translate" />
<import from="converters/declaration-send-method-to-string" />
<import from="converters/declaration-status-to-string" />
<import from="converters/declaration-status-to-color" />
<import from="templates/template-filter/template-filter" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <div ref="scrollContainer" class="flex flex-col flex-1 overflow-y-auto">
            <div class="sticky top-0 z-10 bg-white/50 backdrop-blur-xl">
                <ux-card-header>
                    <div class="flex flex-col flex-1 px-6 py-4">
                        <div class="flex items-center justify-between">
                            <h4 class="text-lg font-semibold text-gray-800" t="translation:partial-views.declarations.labels.title"></h4>
                            <div class="flex gap-1"></div>
                        </div>
                        <div class="grid grid-cols-3 mt-2 gap-x-3">
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                        </div>
                    </div>
                </ux-card-header>
                <ux-table-header>
                    <ux-table-row lock="false" location="header" class="border-b border-gray-200">
                        <ux-table-cell class="py-[0.3rem] pr-0">
                            <span class="text-gray-500" t="translation:global.labels.invoice-number"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="200">
                            <span class="text-gray-500" t="translation:global.labels.insurer"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="225">
                            <span class="text-gray-500" t="translation:global.labels.method"></span>
                        </ux-table-cell>
                        <!-- <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="200">
                            <span class="text-gray-500" t="translation:global.labels.created-at"></span>
                        </ux-table-cell> -->
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="115">
                            <span class="text-gray-500" t="translation:global.labels.total-debit"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="115">
                            <span class="text-gray-500" t="translation:global.labels.total-credit"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="250">
                            <span class="text-gray-500" t="translation:global.labels.status"></span>
                        </ux-table-cell>
                        <ux-table-cell class="px-0 py-[0.3rem]" width="75"></ux-table-cell>
                    </ux-table-row>
                </ux-table-header>
            </div>
            <ux-table class="flex flex-1">
                <ux-table-body if.bind="baseLoaded && insurers" class="flex-1">
                    <template repeat.for="declaration of declarations">
                        <let insurer.bind="insurers.find((x) => x.externalReference === declaration.insurerReference)"></let>
                        <ux-table-row lock.bind="false" class.bind="declarations.length === 1 ? 'border-b border-gray-200' : ''" id="row-${$index}">
                            <ux-table-cell class="py-[0.3rem]">
                                <div class="flex items-center gap-2">
                                    <a click.trigger="edit(declaration)" class="underline" href="javascript:">
                                        <span if.bind="declaration.trackingNumber">${declaration.trackingNumber}</span>
                                        <div else class="flex items-center gap-1 text-gray-600">
                                            <span t="translation:global.labels.draft"></span>
                                            <span>#${declaration.conceptNumber}</span>
                                        </div>
                                    </a>
                                </div>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="200">
                                <div class="flex min-w-0 gap-1">
                                    <template if.bind="insurer">
                                        <span class="truncate">${insurer.name | translate:language}</span>
                                        <span class="truncate">(${insurer.externalReference})</span>
                                    </template>
                                    <span else class="truncate">(${insurer.insurerReference})</span>
                                </div>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="225">${declaration.sendMethod | declarationSendMethodToString | t}</ux-table-cell>
                            <!-- <ux-table-cell class="py-[0.3rem]" width="200">${declaration.createdAt | date:'dd-MM-yyyy HH:mm'}</ux-table-cell> -->
                            <ux-table-cell class="py-[0.3rem]" width="115">
                                <span text-green-600.class="declaration.debitTotalInclVat > 0">${declaration.debitTotalInclVat | currency:false}</span>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="115">
                                <span text-red-500.class="declaration.creditTotalInclVat > 0">${(0 - declaration.creditTotalInclVat) | currency:false}</span>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="250">
                                <ux-badge type="${declaration.status | declarationStatusToColor}">
                                    <span>${declaration.status | declarationStatusToString | t}</span>
                                </ux-badge>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="75">
                                <ux-dropdown
                                    if.bind="(hasRole(UserRoles.WriteDeclarations) || hasRole(UserRoles.DeleteDeclarations))  && (declaration.status === DeclarationStatuses.Draft
                                     || declaration.status === DeclarationStatuses.FailedInternalValidation
                                     || declaration.status === DeclarationStatuses.FailedToSend
                                     || declaration.status === DeclarationStatuses.RejectedByVecozo)"
                                    transparent.bind="true"
                                    border.bind="false"
                                    type="full"
                                    color="dark"
                                    size="sm"
                                    padding.bind="false"
                                    mode="outline"
                                    placement="bottom-right">
                                    <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                            <path
                                                fill="currentColor"
                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option
                                        if.bind="hasRole(UserRoles.WriteDeclarations) && (declaration.status === DeclarationStatuses.FailedInternalValidation
                                            || declaration.status === DeclarationStatuses.FailedToSend
                                            || declaration.status === DeclarationStatuses.RejectedByVecozo)"
                                        click.trigger="archive(declaration)">
                                        <span class="text-sm" t="global.buttons.archive"></span>
                                    </ux-dropdown-option>
                                    <!-- <ux-dropdown-divider
                                        if.bind="declaration.status === DeclarationStatuses.Draft 
                                            || declaration.status === DeclarationStatuses.FailedInternalValidation
                                            || declaration.status === DeclarationStatuses.FailedToSend
                                            || declaration.status === DeclarationStatuses.RejectedByVecozo">
                                    </ux-dropdown-divider> -->
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteDeclarations) && declaration.status === DeclarationStatuses.Draft" click.trigger="delete(declaration)">
                                        <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                            </ux-table-cell>
                        </ux-table-row>
                    </template>
                    <ux-table-row lock="false" style="height: 100%" if.bind="noResults && baseLoaded && declarations.length === 0">
                        <div class="flex flex-col items-center justify-center flex-1 h-full">
                            <span class="text-sm" t="translation:global.labels.no-results"></span>
                        </div>
                    </ux-table-row>
                </ux-table-body>
                <ux-table-body else>
                    <ux-table-row repeat.for="i of 10">
                        <ux-table-cell>
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="300">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="150">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="150">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="75"></ux-table-cell>
                    </ux-table-row>
                </ux-table-body>
            </ux-table>
        </div>

        <ux-footer if.bind="baseLoaded">
            <div class="flex items-center flex-1 gap-1">
                <div class="flex items-center gap-1">
                    <ux-button if.bind="hasRole(UserRoles.WriteDeclarations)" click.trigger="logs()" padding.bind="false" size="xs" color="primary">
                        <div class="flex h-[32px] items-center gap-1 px-2 py-1">
                            <span t="translation:partial-views.declarations.buttons.return-messages"></span>
                        </div>
                    </ux-button>
                    <ux-button click.trigger="openFilters()" padding.bind="false" size="xs" mode="outline" color="dark">
                        <div class="h-[33px] p-2">
                            <svg if.bind="pageState.values.hasFilters" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-red-500">
                                <path
                                    fill-rule="evenodd"
                                    d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <svg else class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                            </svg>
                        </div>
                    </ux-button>
                </div>
                <div class="flex flex-wrap flex-1 gap-1">
                    <template-filter
                        repeat.for="item of pageState.values.filters.declarationSendMethods"
                        value.bind="item.value"
                        t="[key]translation:global.labels.method"
                        type="declarationSendMethods"
                        index.bind="$index"
                        on-remove.bind="removeFilter">
                    </template-filter>
                    <template-filter
                        repeat.for="item of pageState.values.filters.declarationStatuses"
                        value.bind="item.value"
                        t="[key]translation:global.labels.status"
                        type="declarationStatuses"
                        index.bind="$index"
                        on-remove.bind="removeFilter">
                    </template-filter>
                    <template-filter
                        repeat.for="item of pageState.values.filters.insurers"
                        value.bind="item.value"
                        t="[key]translation:global.labels.insurer"
                        type="insurers"
                        index.bind="$index"
                        on-remove.bind="removeFilter">
                    </template-filter>
                    <template-filter
                        if.bind="pageState.values.filters.start"
                        value="${pageState.values.filters.start | date:'dd-MM-yyyy'}"
                        t="[key]translation:global.labels.start"
                        type="start"
                        replace.bind="false"
                        index.bind="$index"
                        on-remove.bind="removeFilter">
                    </template-filter>
                    <template-filter
                        if.bind="pageState.values.filters.end"
                        value="${pageState.values.filters.end | date:'dd-MM-yyyy'}"
                        t="[key]translation:global.labels.end"
                        type="end"
                        replace.bind="false"
                        index.bind="$index"
                        on-remove.bind="removeFilter">
                    </template-filter>
                </div>
            </div>
            <div class="flex items-center justify-end text-sm gap-x-1">
                <p class="font-medium">${declarations.length}</p>
                <p class="text-gray-700" t="translation:global.labels.from"></p>
                <p class="font-medium">${total}</p>
            </div>
        </ux-footer>
    </section>
</template>
