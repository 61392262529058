import { I18N } from '@aurelia/i18n';
import { inject } from 'aurelia';

@inject(I18N)
export class TrValueConverter {
    public constructor(
        private readonly t: I18N //
    ) {}

    public toView(translation: string, prop: string, value: string, translateValue: boolean = true): string {
        return this.t.tr(translation).replace(prop, translateValue ? this.t.tr(value) : value);
    }
}
