import { ColumnBreakpoint, ResultTypes } from '@wecore/sdk-healthcare';
import { isFunction, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';

@containerless
export class ComponentBreakpointsSelector {
    @bindable() public breakpoints: ColumnBreakpoint[];
    @bindable() public id: string;
    @bindable() public validation: any[];
    @bindable() public onBreakpointChanged: () => void;
    @bindable() public onBreakpointRemoved: () => void;

    public ResultTypes: typeof ResultTypes = ResultTypes;

    private sizes: string[] = ['xs', 'sm', 'md', 'lg', 'xl'];

    public attached(): void {
        if (isNotDefined(this.breakpoints)) this.breakpoints = [];
        if (this.breakpoints.empty()) this.add();
    }

    public add(): void {
        const sizes = this.sizes.filter(
            (size) => this.breakpoints.every((breakpoint) => breakpoint.screenSize !== size) //
        );

        this.breakpoints.push(
            new ColumnBreakpoint({
                id: this.id,
                screenSize: sizes[0],
                width: 12
            })
        );

        this.validation.push({
            screenSize: true,
            width: true,
            valid: true
        });

        this.handleBreakpointsChanged();
    }

    public remove(index: number): void {
        this.breakpoints.splice(index, 1);
        this.validation.splice(index, 1);

        if (this.breakpoints.empty()) this.add();

        if (isFunction(this.onBreakpointRemoved)) this.onBreakpointRemoved();
    }

    public clear(): void {
        this.breakpoints = [];
        this.validation = [];

        this.handleBreakpointsChanged();
    }

    public handleBreakpointsChanged(): void {
        if (isFunction(this.onBreakpointChanged)) this.onBreakpointChanged();
    }
}
