<import from="converters/translate" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <div class="flex flex-col w-full h-full overflow-y-auto">
            <div class="sticky top-0 flex justify-between p-5 bg-white border-b border-gray-200">
                <p if.bind="type === 'restitution'" class="text-sm font-medium" t="translation:partial-views.healthcare-invoices.labels.preview-restitution"></p>
                <p if.bind="type === 'remaining'" class="text-sm font-medium" t="translation:partial-views.healthcare-invoices.labels.preview-remaining"></p>
                <p if.bind="type === 'credit'" class="text-sm font-medium" t="translation:partial-views.healthcare-invoices.labels.preview-credit"></p>
                <ux-button if.bind="view === 'insurances'" if.bind="baseLoaded" size="xs" click.trigger="covCheck()">
                    <span t="translation:partial-views.patients.buttons.cov"></span>
                </ux-button>
            </div>
            <template if.bind="baseLoaded">
                <template if.bind="view === 'insurances'">
                    <div class="flex flex-col items-center justify-center h-full p-5">
                        <div if.bind="patient.insurances && patient.insurances.length > 0" class="max-w-6xl">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.healthcare-invoices.labels.select-insurance"></span>
                            </ux-label>
                            <div class="grid flex-1 grid-cols-12 gap-2 mt-2">
                                <a
                                    href="javascript:"
                                    repeat.for="insurance of patient.insurances"
                                    click.trigger="preview(insurance)"
                                    class="flex items-center col-span-12 gap-3 px-3 py-2 rounded-lg bg-gray-50 hover:bg-gray-100">
                                    <div if.bind="insurance.status === InsuranceStatuses.Active" class="h-[40px] w-1 rounded-full bg-green-500"></div>
                                    <div if.bind="insurance.status === InsuranceStatuses.Inactive" class="h-[40px] w-1 rounded-full bg-red-500"></div>
                                    <div if.bind="insurance.status === InsuranceStatuses.Unauthorized" class="h-[40px] w-1 rounded-full bg-red-500"></div>
                                    <div class="flex flex-col">
                                        <p class="text-sm">${insurance.insurer.translations | translate:language} - ${insurance.insurerReference} (${insurance.name})</p>
                                        <div class="text-xs text-gray-500">
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Supplementary"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.SupplementaryPlusDental"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary-dental">
                                            </span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Awbz"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-awbz"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Basic"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-basic"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.MainInsurance"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-main"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Dental"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-dental"></span>
                                            <span>-</span>
                                            <span t="translation:partial-views.declaration-performances.labels.last-checked"></span>
                                            <span>${ insurance.addedAt | date:'dd-MM-yyyy HH:mm'}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <p else class="text-sm text-red-500" t="translation:partial-views.declaration-performances.messages.no-insurances"></p>
                    </div>
                </template>
                <template else>
                    <div class="flex justify-center flex-1 p-10 bg-gray-50">
                        <ux-pdf-preview scale="1.5" src.bind="pdf"></ux-pdf-preview>
                    </div>
                </template>
            </template>
            <template else>
                <div class="flex justify-center flex-1 p-10 bg-gray-50">
                    <div class="flex items-center justify-center flex-1 px-8">
                        <div class="flex w-full bg-white rounded-sm px-60 py-60">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <ux-footer>
            <div class="flex items-center justify-end flex-1 gap-x-2">
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                    <ux-dropdown disabled.bind="isLoading" if.bind="view === 'preview'" color="primary" size="sm" placement="top-right">
                        <span au-slot="label" t="translation:global.buttons.actions"></span>
                        <ux-dropdown-option click.trigger="download()">
                            <span class="text-sm" t="global.buttons.download"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-option click.trigger="send()">
                            <span class="text-sm" t="global.buttons.send-via-email"></span>
                        </ux-dropdown-option>
                    </ux-dropdown>
                </div>
            </div>
        </ux-footer>
    </section>
</template>
