<import from="converters/translate" />

<section class="duplicator">
    <div class="flex items-center justify-between">
        <ux-label>${ label }</ux-label>
        <ux-button if.bind="maxAmount === -1 || items.length < maxAmount" click.trigger="add()" padding.bind="false" size="xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </ux-button>
    </div>
    <div class="mt-2 grid grid-cols-12 gap-2">
        <template repeat.for="item of items">
            <div if.bind="useLabels" class="col-span-4 w-full min-w-0 flex-1">
                <ux-input if.bind="mode === 'input'" placeholder="Omschrijving" value.two-way="item.description"></ux-input>
                <ux-select component.ref="selector" else onselect.trigger="handleChange($event)" data.bind="$index" value.two-way="item.translations[language]">
                    <ux-select-option repeat.for="description of descriptions" skip-setting-value.bind="$index === descriptions.length - 1" value.bind="description[language]" data.bind="description">
                        ${ description | translate:language }
                    </ux-select-option>
                </ux-select>
            </div>
            <div col-span-8.class="useLabels" col-span-12.class="!useLabels" class="flex w-full flex-1 items-center">
                <template if.bind="type === 'link'">
                    <div class="mr-0.5 w-[105px]">
                        <ux-select value.two-way="item.protocol" rounded="left" id="duplicator-link-${guid}-${$index}">
                            <ux-select-option value="http://">http://</ux-select-option>
                            <ux-select-option value="https://">https://</ux-select-option>
                        </ux-select>
                    </div>
                </template>
                <template if.bind="type === 'phone'">
                    <div class="mr-0.5 w-[180px]">
                        <ux-combobox autocomplete.bind="true" value.two-way="item.callingCode" rounded="left" id="duplicator-phone-${guid}-${$index}">
                            <ux-combobox-option repeat.for="country of countries" value.bind="country.callingCode">
                                +${country.callingCode} (${ 'translation:global.countries.' + country.code | t })
                            </ux-combobox-option>
                        </ux-combobox>
                    </div>
                </template>
                <div class="flex flex-1 space-x-1">
                    <ux-input
                        rounded.bind="type === 'link' || type === 'phone' ? 'right' :'full'"
                        mask.bind="mask"
                        valid.bind="validation[$index].valid && validation[$index].email && validation[$index].phone"
                        data.bind="$index"
                        placeholder.bind="placeholder"
                        value.two-way="item.value"
                        onblur.trigger="handleBlur($event)">
                    </ux-input>
                    <button
                        if.bind="maxAmount === -1 || maxAmount > 1"
                        class.bind="validation[$index].valid && validation[$index].email && validation[$index].phone ? 'border-gray-300': 'border-red-500'"
                        class="h-[38px] items-center rounded-lg border bg-white p-2 text-red-500 transition duration-150 ease-in-out focus:z-[30] focus:outline-none focus:ring-1 focus:ring-red-400 focus:ring-offset-1"
                        click.trigger="delete($index)">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <p if.bind="!validation[$index].phone" class="col-span-12 text-xs text-red-500" t="translation:components.duplicator.messages.invalid-phone-length"></p>
            <p if.bind="!validation[$index].email" class="col-span-12 text-xs text-red-500" t="translation:components.duplicator.messages.invalid-email"></p>
        </template>
    </div>
</section>
