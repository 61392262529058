import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { UserEntityReference } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, validateState } from '@wecore/sdk-utilities';

import { GetUserResponse } from '@wecore/sdk-core';
import { TeamsApiClient, UpdateTeamRequest } from '@wecore/sdk-management';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, TeamsApiClient, ModalService)
export class PartialManageTeamsEdit extends BasePartialView {
    public teamId: string;
    public team: UpdateTeamRequest;
    public validation = {
        name: true,
        displayOrder: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly teamsApi: TeamsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.teamId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.team = await this.teamsApi.getById(this.teamId, this.authenticated.workspace.id);

                if (isNotDefined(this.team.description)) this.team.description = setTranslation({}, this.language);

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManageTeamsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.team, 1);
                await this.teamsApi.update(this.teamId, this.authenticated.workspace.id, this.team);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-teams.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-teams.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.team.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[edit-team]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-teams.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-teams.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.team.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.teamsApi.delete(this.teamId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-teams.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-teams.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.team.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-team]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.team[property],
            callback: (updatedTranslations: any) => {
                this.team[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleUserSelected = (_: GetUserResponse[], __: 'added' | 'deleted', user: GetUserResponse): void => {
        const index = this.team.members.findIndex((x) => x.id === user?.id);
        if (index > -1) this.team.members.splice(index, 1);
        else if (isDefined(user))
            this.team.members = [
                ...this.team.members,
                new UserEntityReference({
                    id: user.id,
                    name: user.displayName,
                    data: {
                        avatarColor: user.avatar.color,
                        avatarShade: user.avatar.shade.toString(),
                        email: user.email
                    }
                })
            ];
    };

    private validate(): boolean {
        // this.validation.name = validateTranslation(this.team.name, this.language);
        return validateState(this.validation);
    }
}
