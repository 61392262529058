<import from="components/component-manage-rights/component-manage-rights" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-50 flex flex-col gap-3 pt-5 pb-3 bg-white border-b border-gray-200">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-500" t="translation:partial-views.manage-users.labels.invite-user"></p>
                        </div>
                    </div>
                    <div class="flex flex-col flex-1 gap-6 pt-3 pb-5">
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.manage-users.labels.email-address"></span>
                            </ux-label>
                            <ux-input
                                type="text"
                                oninput.trigger="handleEmailAddressChanged($event)"
                                value.two-way="request.email"
                                t="[placeholder]translation:partial-views.manage-users.placeholders.email-address"></ux-input>
                        </div>
                        <div if.bind="user" class="flex-col">
                            <p class="mb-1 text-xs text-gray-500" t="translation:partial-views.manage-users.labels.user-found"></p>
                            <div class="flex flex-col p-3 bg-gray-100 rounded-lg">
                                <div class="flex items-center gap-2">
                                    <ux-avatar size="xs" email.bind="user.email" name.bind="user.displayName" color.bind="user.avatar.color" shade.bind="user.avatar.shade"></ux-avatar>
                                    <div class="flex flex-col">
                                        <p class="text-sm font-semibold leading-tight group-hover:text-gray-600">${ user.displayName }</p>
                                        <span class="text-xs leading-tight text-gray-500">${user.email}</span>
                                    </div>
                                </div>
                                <span if.bind="alreadyAdded" class="mt-1 text-red-500 pl-9 text-xxs" t="translation:partial-views.manage-users.messages.user-already-added"></span>
                            </div>
                        </div>
                        <div class="flex flex-col col-span-12 pb-5">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.rights"></span>
                            </ux-label>
                            <component-manage-rights roles.two-way="request.roles"></component-manage-rights>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <ux-footer>
                <div class="flex justify-end w-full gap-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || checking" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        size="sm"
                        color="success"
                        disabled.bind="isLoading || alreadyAdded || checking"
                        loading.bind="isLoading || checking"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="translation:partial-views.manage-users.buttons.send-invitation"></span>
                    </ux-button>
                </div>
            </ux-footer>
        </div>
    </section>
</template>
