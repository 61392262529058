import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalWidgetTypes,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { cloneDeep } from '../../../../../infra/utilities';
import { WidgetRegistration } from '../../../../../models/widget-registration';

@inject(I18N)
@containerless
export class WidgetPatientAddresses {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    public descriptions: { [key: string]: string }[];

    public constructor(
        public t: I18N //
    ) {
        this.descriptions = [
            {
                nl: t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                en: t.tr('translation:global.labels.home-address', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];
    }

    public bound(): void {
        if (isNotDefined(this.registration)) return;

        this.validation.values = this.patient.addresses.map((_) => ({
            valid: true,
            street: true,
            zipCode: true,
            city: true,
            houseNumber: true
        }));

        // Combine default descriptions with custom descriptions
        // that are used by the user.
        const descriptions = [
            ...this.descriptions, //
            ...this.patient.addresses.map((x) => x.translations)
        ] // Filter out the custom option
            .filter((x) => x[this.language] !== this.t.tr('translation:global.labels.custom', { lng: this.language }))
            .orderBy((x) => x[this.language]);

        // Re-add the custom option on the end and make
        // sure there aren't any duplicates.
        this.descriptions = [
            ...descriptions,
            {
                nl: this.t.tr('translation:global.labels.custom', { lng: 'nl' }), //
                en: this.t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ].distinct((x) => x[this.language]);

        // Add current addresses.
        this.registration.widget.result.value = cloneDeep(this.patient.addresses);
        this.registration.widget.result.type = WidgetResultTypes.Array;

        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.PatientAddresses,
                onSave: async (): Promise<void> => {
                    this.patient.addresses = this.registration.widget.result.value;
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }
}
