import { isFunction, isNotEmpty } from '@wecore/sdk-utilities';
import { bindable } from 'aurelia';

export class UxVideo {
    @bindable() public src: string;
    @bindable() public getVideo: (data: any) => Promise<string>;
    @bindable() public type: 'video' | 'youtube' = 'video';
    @bindable() public contentType: string = 'video/mp4';
    @bindable() public alt: string;
    @bindable() public data: any;
    @bindable() public controls: number = 0;
    @bindable() public loop: number = 0;
    @bindable() public branding: number = 1;
    @bindable() public autoplay: number = 0;
    @bindable() public fullscreen: number = 0;
    @bindable() public playlist: string;
    @bindable() public class: string;
    @bindable() public size: 'fit' | 'contain' = 'contain';
    @bindable() public height: string = '100%';
    @bindable() public width: string = '100%';

    public bound(): void {
        this.load(null);
    }

    public async load(src: string): Promise<void> {
        // If we have an async request available
        // use it to load the src property.
        if (isNotEmpty(src)) this.src = src;
        else if (isFunction(this.getVideo)) this.src = await this.getVideo(this.data);
    }
}
