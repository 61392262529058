import { ProcessDeclarationRequestItemMutation } from '@wecore/sdk-healthcare';

export class DeclarationMutationToStringValueConverter {
    public toView(type: ProcessDeclarationRequestItemMutation): string {
        switch (type) {
            case ProcessDeclarationRequestItemMutation.Accept:
                return 'translation:global.buttons.approve';
            case ProcessDeclarationRequestItemMutation.Reject:
                return 'translation:global.buttons.reject';
            case ProcessDeclarationRequestItemMutation.PartiallyAccept:
                return 'translation:global.buttons.partially-accept';
            default:
                return 'global.labels.missing-translation';
        }
    }
}
