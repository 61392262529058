export interface ISchedulerSettings {
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
    slotSize: 5 | 10 | 15 | 30;
    days: number[];
    minColumnWidth: number;
}

export class SchedulerSettings {
    public startHour: number;
    public startMinute: number;
    public endHour: number;
    public endMinute: number;
    public slotSize: 5 | 10 | 15 | 30;
    public days: number[];
    public periodHeight: number;
    public minColumnWidth: number;

    public constructor(data: ISchedulerSettings) {
        this.startHour = data?.startHour;
        this.startMinute = data?.startMinute;
        this.endHour = data?.endHour;
        this.endMinute = data?.endMinute;
        this.slotSize = data?.slotSize;
        this.days = data?.days;
        this.minColumnWidth = data.minColumnWidth;

        this.periodHeight = this.slotSize === 5 ? 200 : this.slotSize === 10 ? 150 : 100;
    }
}
