import { I18N } from '@aurelia/i18n';
import { GetUserResponse, UsersApiClient } from '@wecore/sdk-core';
import { isDefined, isEmpty, isFunction, isNotDefined, isNotEmpty, isZero } from '@wecore/sdk-utilities';

import { bindable, inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { EventDetails } from '../../models/event-details';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';

@inject(UsersApiClient, I18N, ErrorHandler)
export class BxUserSelector {
    @bindable() public value: string;
    @bindable() public selected: GetUserResponse;
    @bindable() public placeholder: string;
    @bindable() public searchPlaceholder: string;
    @bindable() public valid: boolean = true;
    @bindable() public disabled: boolean = false;
    @bindable() public allowClear: boolean = false;
    @bindable() public workspace: string;
    @bindable() public data: any;
    @bindable() public language: string;
    @bindable() public used: string[];
    @bindable() public onSelect: (user: GetUserResponse, data: any) => void;

    public combobox: UxCombobox;
    public users: GetUserResponse[];

    public constructor(
        private readonly usersApi: UsersApiClient, //
        private readonly t: I18N,
        private readonly errorHandler: ErrorHandler
    ) {
        this.searchPlaceholder = t.tr('global.placeholders.search');
    }

    public handleClear(): void {
        if (isFunction(this.onSelect)) this.onSelect(null, this.data);
    }

    public async attached(): Promise<void> {
        try {
            let user: GetUserResponse;
            if (isNotEmpty(this.value)) {
                user = await this.usersApi.getById(this.value);
                // If the selected item is not found, use the selected object which can
                // be provided by the user and used to display the object name in the combobox.
                // This can be handy in case the item has been deleted.
                if (isNotDefined(user)) user = this.selected;
            }

            const response = await this.usersApi.search(this.workspace, '');
            const data = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;
            this.users = isDefined(user) ? [user].concat(data.filter((x) => x.id !== this.value)) : data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxUserSelector.attached', e);
        }
    }

    public setValue(value: string) {
        if (isDefined(this.combobox)) this.combobox.setValue(value);
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxCombobox, string>>): Promise<void> {
        try {
            if (isDefined(this.combobox)) this.combobox.isLoading = true;
            const query = e.detail.values;
            const response = await this.usersApi.search(this.workspace, isNotEmpty(query) ? query : '');
            this.users = isDefined(this.used) ? response.data.filter((x) => this.used.every((id) => id !== x.id)) : response.data;

            if (isDefined(this.combobox)) this.combobox.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('BxUserSelector.handleSearch', e);
        }
    }

    public async handleSelect(e: CustomEvent<EventDetails<UxCombobox, any>>): Promise<void> {
        if (e.detail.values.customInput as boolean) return;
        const option = e.detail.values as UxComboboxOption;

        const user = this.users.find((x) => x.id === option.value);
        if (isFunction(this.onSelect)) this.onSelect(user, this.data);
    }

    public getBackgroundColor(user: GetUserResponse): string {
        if (isNotDefined(user.avatar) || isEmpty(user.avatar.color) || isNotDefined(user.avatar.shade) || isZero(user.avatar.shade)) return 'bg-gray-200 border border-solid border-gray-300';
        if (user.avatar.color === 'white') return `bg-${user.avatar.color}-${user.avatar.shade} border border-solid border-gray-300`;
        return `bg-${user.avatar.color}-${user.avatar.shade}`;
    }

    public getTextColor(user: GetUserResponse): string {
        if (isNotDefined(user.avatar) || isEmpty(user.avatar.color) || isNotDefined(user.avatar.shade) || isZero(user.avatar.shade)) return 'text-gray-700';

        if (user.avatar.shade < 500) return `text-${user.avatar.color}-900`;
        else return 'text-white';
    }

    public getInititals(user: GetUserResponse): string {
        let lastInitial = '';
        let firstInitial = '';

        if (isNotEmpty(user.displayName)) {
            const filtered = user.displayName.replace(/[^a-zA-Z ]/gm, '').trim();
            const displayNameSplit = filtered.split(' ');
            firstInitial = displayNameSplit[0].charAt(0);

            if (displayNameSplit.length > 1) lastInitial = displayNameSplit[displayNameSplit.length - 1].charAt(0);
        }

        return `${firstInitial}${lastInitial}`.toUpperCase();
    }
}
