import { I18N } from '@aurelia/i18n';
import { GetUserPostResponse, UserPostsApiClient } from '@wecore/sdk-core';
import { isFunction } from '@wecore/sdk-utilities';

import { IEventAggregator, ISignaler, bindable, inject } from 'aurelia';
import { ErrorHandler } from '../../../infra/error-handler';
import { NotificationService } from '../../../services/service.notifications';
import { TemplatePostInput } from '../template-post-input/template-post-input';

@inject(UserPostsApiClient, ErrorHandler, ISignaler, I18N, IEventAggregator, NotificationService)
export class TemplatePostReply {
    @bindable() public post: GetUserPostResponse;
    @bindable() public entityId: string;
    @bindable() public entityType: string;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public editMode: boolean = false;
    @bindable() public authenticated: GetUserPostResponse;
    @bindable() public onDelete: (post: GetUserPostResponse) => Promise<void>;

    public input: TemplatePostInput;
    public baseLoaded: boolean = false;
    public validation: any = {
        reply: true
    };

    private interval: NodeJS.Timeout;

    public constructor(
        private readonly postsApi: UserPostsApiClient, //
        private readonly errorHandler: ErrorHandler,
        private readonly signaler: ISignaler,
        private readonly t: I18N,
        private readonly events: IEventAggregator,
        private readonly notifications: NotificationService
    ) {}

    public async bound(): Promise<void> {
        this.interval = setInterval(() => this.signaler.dispatchSignal('update-timeago'), 1000);

        this.baseLoaded = true;
    }

    public detaching(): void {
        clearInterval(this.interval);
    }

    public edit(): void {
        if (this.post.createdBy.id !== this.authenticated.id) return;
        this.editMode = true;
    }

    public handleCancel = (): void => {
        this.input.setContents(this.post.content);
        this.editMode = false;
    };

    public delete(): void {
        if (this.post.createdBy.id !== this.authenticated.id || !isFunction(this.onDelete)) return;
        this.onDelete(this.post);
    }

    public handleSave = async (): Promise<boolean> => {
        const valid = this.input.validate();
        if (!valid) return false;

        const content = this.input.getContents();
        try {
            this.post.content = content;
            await this.postsApi.update(this.post.id, this.workspace, this.post);
            this.editMode = false;

            this.notifications.show(
                this.t.tr('translation:partial-views.user-posts.notifications.save-successful.message'),
                this.t.tr('translation:partial-views.user-posts.notifications.save-successful.message'),
                { type: 'success', duration: 3000 }
            );
            return true;
        } catch (e) {
            this.errorHandler.handle('edit-user-post', e);
            return false;
        }
    };
}
