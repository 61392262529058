<import from="converters/translate" />
<import from="converters/tr" />
<import from="converters/label-type-to-string" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="mb-2 flex justify-between">
                <p class="font-medium text-gray-400" t="translation:partial-views.master-data.manage.title"></p>
                <template if.bind="hasResults">
                    <ux-button click.trigger="addItem()" padding.bind="false" size="xs">
                        <div class="flex p-1">
                            <svg class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </div>
                    </ux-button>
                </template>
            </div>
            <template if.bind="hasResults">
                <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"> </ux-input>
            </template>
        </ux-header>
        <div class="flex h-full w-full flex-1 flex-col overflow-hidden">
            <div ref="scrollContainer" id="scroll-container-master-data" class="flex flex-1 flex-col overflow-y-auto px-8 pt-1">
                <template if.bind="baseLoaded">
                    <div class="flex flex-1 flex-col">
                        <template if.bind="!hasResults && !loading">
                            <div class="flex h-full flex-1 flex-col items-center justify-center rounded-lg border border-gray-200 p-10">
                                <img class="h-28" src="/static/svg/empty.svg" />
                                <p class="mt-5 text-center" t="translation:partial-views.master-data.messages.no-master-data"></p>
                                <ux-button size="sm" class="mt-5" click.trigger="addItem()">
                                    <span t="translation:partial-views.master-data.buttons.add-master-data"></span>
                                </ux-button>
                            </div>
                        </template>
                        <template else>
                            <ul class="flex-1 border-gray-200">
                                <li if.bind="isLoading" class="flex justify-center border-b border-gray-200 py-2.5">
                                    <ux-spinner size="xxs"></ux-spinner>
                                </li>
                                <li repeat.for="item of items" id="master-data-item-${$index}" class="bg-white">
                                    <template if.bind="item.editMode">
                                        <div class="flex flex-col border-b border-gray-200 py-2">
                                            <div class="flex items-center">
                                                <div class="flex flex-1 flex-col">
                                                    <ux-input
                                                        focus-delay="250"
                                                        action.bind="c => manageTranslationsFor($index)"
                                                        autofocus
                                                        select-text-on-focus.bind="true"
                                                        placeholder="Geef een naam op"
                                                        ref="input"
                                                        data.bind="$index"
                                                        onkeyup.trigger="handleKeyUp($event)"
                                                        value.two-way="item.translations[language]"
                                                        valid.bind="validation.name && validation.unique">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                                        </svg>
                                                    </ux-input>
                                                </div>
                                                <template if.bind="item.isLoading">
                                                    <div class="ml-3">
                                                        <ux-spinner size="xs"></ux-spinner>
                                                    </div>
                                                </template>
                                                <template else>
                                                    <div class="ml-1 flex space-x-1">
                                                        <button click.trigger="handleSave(item, $index)" class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-green-500">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                            </svg>
                                                        </button>
                                                        <button click.trigger="cancelEdit($index, item)" class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500">
                                                            <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="2"
                                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                            </svg>
                                                        </button>
                                                        <template if.bind="masterData.type === 'medication' || masterData.type === 'label' && item.id">
                                                            <button click.trigger="manageFields(item)" class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-gray-800">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                                                                </svg>
                                                            </button>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                            <span if.bind="!validation.unique" class="mt-1 text-xs text-red-500" t="translation:partial-views.master-data.messages.name-already-exists"></span>
                                        </div>
                                    </template>
                                    <template else>
                                        <div class="${$index !== items.length - 1 || $index === 0 ? 'border-b' : ''} flex items-center justify-between border-gray-200 py-2">
                                            <div class="flex items-start gap-x-2">
                                                <div class="flex">
                                                    <svg class="h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke="currentColor"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="1.5"
                                                            d="M19.25 10.25v-3.5a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h4.5m-2.5-10.5h6.5m-6.5 4h2.5m5.248 1.653c-.55-.715-1.467-.907-2.156-.253-.688.654-.785 1.748-.244 2.521l2.4 2.579 2.4-2.579c.542-.773.456-1.874-.244-2.52-.701-.648-1.606-.463-2.156.252Z"></path>
                                                    </svg>
                                                </div>
                                                <div class="flex flex-col leading-tight">
                                                    <p class="text-sm text-gray-800">${item.translations | translate:language}</p>
                                                    <div if.bind="masterData.type === 'label'" class="text-xxs text-gray-400">
                                                        <span t="translation:partial-views.master-data.messages.label-is-for"></span>
                                                        <span class="text-gray-700">${item.properties.type | labelTypeToString | t}</span>
                                                    </div>
                                                    <div if.bind="masterData.type === 'contactType'" class="text-xxs text-red-900">
                                                        <span if.bind="item.properties.locked" t="translation:partial-views.master-data.messages.system-type"></span>
                                                    </div>
                                                    <div if.bind="masterData.type === 'medication'" class="text-xxs text-gray-400">
                                                        <span if.bind="item.properties.length === 0" t="translation:partial-views.master-data.messages.no-side-seffects"></span>
                                                        <span if.bind="item.properties.length === 1" t="translation:partial-views.master-data.messages.medication-has-side-effect"></span>
                                                        <span if.bind="item.properties.length > 1">
                                                            ${ 'translation:partial-views.master-data.messages.medication-has-side-effects' | tr:'{amount}':item.properties.length}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <ux-dropdown
                                                    transparent.bind="true"
                                                    border.bind="false"
                                                    if.bind="!item.properties.locked"
                                                    type="full"
                                                    color="dark"
                                                    size="sm"
                                                    padding.bind="false"
                                                    mode="outline"
                                                    placement="bottom-right">
                                                    <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                            <path
                                                                fill="currentColor"
                                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                        </svg>
                                                    </div>
                                                    <ux-dropdown-option click.trigger="toggleEditMode(item, $index)">
                                                        <span t="global.buttons.edit"></span>
                                                    </ux-dropdown-option>
                                                    <ux-dropdown-option if.bind="masterData.type === 'medication' || masterData.type === 'label' && item.id" click.trigger="manageFields(item)">
                                                        <span t="translation:partial-views.master-data.buttons.manage-fields"></span>
                                                    </ux-dropdown-option>
                                                    <ux-dropdown-divider></ux-dropdown-divider>
                                                    <ux-dropdown-option click.trigger="handleDelete(item, $index)">
                                                        <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                    </ux-dropdown-option>
                                                </ux-dropdown>
                                            </div>
                                        </div>
                                    </template>
                                </li>
                                <li if.bind="items.length === 0" class="border-b border-gray-200 py-2.5">
                                    <p class="text-sm text-gray-600" t="translation:global.labels.no-results"></p>
                                </li>
                            </ul>
                        </template>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-1 flex-col pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-1 flex-col space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex w-full flex-col gap-y-3 px-5 py-4">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- <div class.bind="items.length > 0 ? 'border-t' : ''" class="sticky bottom-0 z-50 flex justify-end py-4 bg-white border-gray-200 gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </div> -->
            </div>
        </div>
    </section>
</template>
