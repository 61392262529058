<import from="converters/translate" />
<import from="converters/replace-variables" />
<import from="converters/get-values" />
<import from="converters/file-name" />
<import from="converters/file-size" />
<import from="converters/blob-to-url" />
<import from="converters/file-to-icon" />
<import from="converters/sort" />

<div id.bind="item.id" ref="container" class="${columns} flex flex-col">
    <div id="therapy-execution-step-grid-${item.copiedFrom}-${registration.id}" ref="grid" class="mt-1 flex flex-col" show.bind="shouldBeDisplayed">
        <let placeholder.bind="getPlaceholder()"></let>
        <div class="flex items-center gap-1">
            <ux-label>${ item.step.question | translate:language }</ux-label>
            <span if.bind="required" class="flex h-3 items-center text-red-500">*</span>
        </div>
        <p
            class.bind="toManyFiles ? 'text-red-500 font-medium' : 'text-indigo-700'"
            class="text-xs"
            if.bind="item.step.resultType === ResultTypes.File && item.step.filesAmount && item.step.filesAmount > 0"
            innerhtml.bind="'translation:partial-views.clinical-pathways.messages.max-files' | t | replaceVariables:item.step.filesAmount"></p>
        <p
            class.bind="wrongFileTypes ? 'text-red-500 font-medium' : 'text-indigo-700'"
            class="text-xs"
            if.bind="item.step.resultType === ResultTypes.File && item.step.allowedFileTypes && item.step.allowedFileTypes.length > 0"
            innerhtml.bind="'translation:partial-views.clinical-pathways.messages.file-types' | t | replaceVariables:getFileTypes()"></p>
        <p
            class="text-xs text-indigo-700"
            if.bind="item.step.resultType === ResultTypes.Number && item.step.inputRules.min && !item.step.inputRules.max"
            innerhtml.bind=" 'translation:partial-views.clinical-pathways.messages.min-value' | t | replaceVariables:item.step.inputRules.min"></p>
        <p
            class="text-xs text-indigo-700"
            if.bind="item.step.resultType === ResultTypes.Number && item.step.inputRules.max && !item.step.inputRules.min"
            innerhtml.bind="'translation:partial-views.clinical-pathways.messages.max-value' | t | replaceVariables:item.step.inputRules.max"></p>
        <p
            class="text-xs text-indigo-700"
            if.bind="item.step.resultType === ResultTypes.Number && item.step.inputRules.max && item.step.inputRules.min"
            innerhtml.bind="'translation:partial-views.clinical-pathways.messages.min-max-value' | t | replaceVariables:item.step.inputRules.min:item.step.inputRules.max"></p>
        <div class="mt-2 flex gap-1">
            <div class="flex min-w-0 flex-1 flex-col">
                <template if.bind="item.step.resultType === ResultTypes.Text || item.step.resultType === ResultTypes.Number">
                    <template if.bind="item.step.inputType === InputTypes.Selector">
                        <template if.bind="item.step.isMultipleChoice">
                            <ux-multi-selector
                                component.ref="multiSelector"
                                options.bind="toSet(item.step.choices)"
                                on-selected.bind="handleMultiChoiceSelected"
                                on-removed.bind="handleMultiChoiceRemoved"
                                show-footer.bind="false"
                                force-remove.bind="true"
                                autocomplete.bind="true"
                                values.bind="item.step.results | getValues:'value'"
                                placeholder.bind="placeholder">
                            </ux-multi-selector>
                        </template>
                        <template else>
                            <ux-combobox
                                component.ref="selector"
                                onselect.trigger="handleResultSelected($event)"
                                onclear.trigger="handleAnswerCleared($event)"
                                value.two-way="item.step.result.value"
                                placeholder.bind="placeholder"
                                allow-clear.bind="true"
                                autocomplete.bind="true"
                                valid.bind="validation.result && validation.min && validation.max">
                                <ux-combobox-option repeat.for="choice of item.step.choices | sort:'displayOrder'" value.bind="choice.value"> ${ choice.value }</ux-combobox-option>
                            </ux-combobox>
                        </template>
                    </template>
                    <template if.bind="item.step.inputType === InputTypes.FreeEntry">
                        <template if.bind="item.step.resultType === ResultTypes.Text">
                            <ux-textarea
                                rows="1"
                                value.two-way="item.step.result.value"
                                allow-clear.bind="true"
                                onblur.trigger="handleBlur($event)"
                                onclear.trigger="handleAnswerCleared($event)"
                                valid.bind="validation.result && validation.min && validation.max"
                                placeholder.bind="placeholder">
                            </ux-textarea>
                        </template>
                        <template if.bind="item.step.resultType === ResultTypes.Number">
                            <ux-input
                                type="number"
                                value.two-way="item.step.result.value"
                                allow-clear.bind="true"
                                onblur.trigger="handleBlur($event)"
                                onclear.trigger="handleAnswerCleared($event)"
                                valid.bind="validation.result && validation.min && validation.max"
                                placeholder.bind="placeholder">
                            </ux-input>
                        </template>
                    </template>
                    <template if.bind="item.step.inputType === InputTypes.RangeSlider">
                        <ux-card>
                            <div class="flex flex-1 rounded-lg border border-gray-200 px-6 pb-5 pt-7">
                                <ux-range-slider
                                    component.ref="slider"
                                    value.two-way="item.step.result.value"
                                    start.bind="item.step.slider.startValue"
                                    end.bind="item.step.slider.endValue"
                                    major-increment.bind="item.step.slider.majorIncrement"
                                    minor-increment.bind="item.step.slider.minorIncrement"
                                    labels.bind="item.step.slider.labels"
                                    initial.bind="item.step.slider.initialValue"
                                    language.bind="language">
                                </ux-range-slider>
                            </div>
                        </ux-card>
                    </template>
                </template>
                <template if.bind="item.step.resultType === ResultTypes.Date">
                    <template if.bind="item.step.inputType === InputTypes.Datepicker">
                        <ux-datepicker value.two-way="item.step.result.date" valid.bind="validation.result&& validation.min && validation.max" placeholder.bind="placeholder"> </ux-datepicker>
                    </template>
                </template>
                <template if.bind="item.step.resultType === ResultTypes.File">
                    <template if.bind="item.step.inputType === InputTypes.Filepicker">
                        <ux-card>
                            <div class="flex flex-col gap-2 rounded-lg border border-gray-200 p-3">
                                <ux-dropzone
                                    ref="dropzone"
                                    accepted-files.bind="contentTypes"
                                    valid.bind="validation.result && validation.filesQuantity && validation.fileTypes"
                                    max-files.bind="item.step.filesAmount > 0 ? item.step.filesAmount : 0"
                                    on-files-selected.bind="filesSelected">
                                    <div class="flex flex-1 flex-col items-center justify-center gap-1 p-6">
                                        <svg class="h-8 w-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.25 14.25C6.25 14.25 4.75 14 4.75 12C4.75 10.2869 6.07542 8.88339 7.75672 8.75897C7.88168 6.5239 9.73368 4.75 12 4.75C14.2663 4.75 16.1183 6.5239 16.2433 8.75897C17.9246 8.88339 19.25 10.2869 19.25 12C19.25 14 17.75 14.25 17.75 14.25"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M14.25 15.25L12 12.75L9.75 15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M12 19.25V13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <span class="text-center text-sm" t="translation:global.messages.drop-files"></span>
                                    </div>
                                </ux-dropzone>
                                <ul if.bind="uploads.length > 0 || attachments.length > 0" role="list" class="flex flex-col space-y-1">
                                    <li repeat.for="upload of uploads" id="upload-${item.step.id}-${$index}" class="flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5">
                                        <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                                            <template if.bind="isImage(upload.file.type)">
                                                <ux-image height="56px" width="75px" size="cover" src.bind="upload.file | blobToUrl"></ux-image>
                                            </template>
                                            <template else>
                                                <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                                                    <span class="text-gray-400" innerhtml.bind="upload.extension | fileToIcon:'w-6 h-6'"></span>
                                                </div>
                                            </template>
                                        </div>

                                        <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                                            <div class="flex min-w-0 flex-1 flex-col">
                                                <p class="= pointer-events-none block truncate text-sm text-gray-900">${ upload.name | fileName }${ upload.extension }</p>
                                                <p class="pointer-events-none block text-sm text-gray-500">${ upload.file.size | fileSize }</p>
                                            </div>
                                            <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                                <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                    </svg>
                                                </div>
                                                <ux-dropdown-option click.trigger="deleteFromUploads($index)">
                                                    <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                                                </ux-dropdown-option>
                                            </ux-dropdown>
                                        </div>
                                    </li>
                                    <li repeat.for="attachment of attachments" id="attachment-${item.step.id}-${$index}" class="flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5">
                                        <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                                            <template if.bind="isImage(attachment.contentType)">
                                                <ux-image height="56px" width="75px" size="cover" src.bind="urls[attachment.id]"></ux-image>
                                            </template>
                                            <template else>
                                                <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                                                    <span class="text-gray-400" innerhtml.bind="attachment.extension | fileToIcon:'w-6 h-6'"></span>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                                            <div class="flex min-w-0 flex-1 flex-col">
                                                <p class="= pointer-events-none block truncate text-sm text-gray-900">${ attachment.name }${ attachment.extension }</p>
                                                <p class="pointer-events-none block text-sm text-gray-500">${ attachment.size | fileSize }</p>
                                            </div>
                                            <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                                <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                    </svg>
                                                </div>
                                                <ux-dropdown-option click.trigger="open(attachment)">
                                                    <span class="pointer-events-none" t="translation:global.buttons.open"></span>
                                                </ux-dropdown-option>
                                                <ux-dropdown-option click.trigger="download(attachment)">
                                                    <span class="pointer-events-none" t="translation:global.buttons.download"></span>
                                                </ux-dropdown-option>
                                                <ux-dropdown-divider></ux-dropdown-divider>
                                                <ux-dropdown-option click.trigger="deleteAttachment($index)">
                                                    <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                                </ux-dropdown-option>
                                            </ux-dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ux-card>
                    </template>
                </template>
                <p
                    if.bind="!validation.min"
                    class="mt-1 text-xs text-red-500"
                    innerhtml.bind="'translation:partial-views.clinical-pathways.messages.min-value-error' | t | replaceVariables:item.step.inputRules.min"></p>
                <p
                    if.bind="!validation.max"
                    class="mt-1 text-xs text-red-500"
                    innerhtml.bind="'translation:partial-views.clinical-pathways.messages.max-value-error' | t | replaceVariables:item.step.inputRules.max"></p>
            </div>
            <div if.bind="hasConnections && item.step.resultType !== ResultTypes.File && buddy.isVisible" class="flex">
                <button
                    click.trigger="copyValue()"
                    class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-blue-500 ring-gray-200 ring-offset-1 hover:bg-gray-50 focus:ring-1 active:bg-gray-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>
