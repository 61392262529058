import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, inject, slotted } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';

@inject(Element, IEventAggregator)
export class UxSelectOption {
    @bindable() public value: string;
    @bindable() public data: any;

    public hide: boolean = false;
    public selected: boolean = false;
    public focused: boolean = false;

    private index: number;
    private parent: string;
    private observer: MutationObserver;
    private onKeydown: (e: KeyboardEvent) => void;

    @(slotted() as any) slot: HTMLElement[];

    public constructor(
        private readonly element: HTMLElement, //
        private readonly events: IEventAggregator
    ) {}

    public bound(): void {
        setTimeout(() => {
            this.parent = this.element.getAttribute('parent');
            this.selected = this.element.getAttribute('selected') === 'true';
            this.index = Number(this.element.getAttribute('index'));
        });

        // When the select is opened and the user is filtering the options,
        // the attribute 'hide' is added to the options that do not match the filter.
        // This observer is responsible for updating the 'hide' property of the option.
        this.observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type !== 'attributes' || isNotDefined(this.element)) return;
                if (mutation.attributeName === 'hide') this.hide = this.element.getAttribute('hide') === 'true';
                if (mutation.attributeName === 'focused') this.focused = this.element.getAttribute('focused') === 'true';
                if (mutation.attributeName === 'selected') {
                    this.selected = this.element.getAttribute('selected') === 'true';
                }
                if (mutation.attributeName === 'index' && isDefined(this.element.getAttribute('index'))) this.index = Number(this.element.getAttribute('index'));
            }
        });
        this.observer.observe(this.element, { attributes: true });

        this.onKeydown = (e: KeyboardEvent) => this.handleKeyDown(e);
        document.addEventListener('keydown', this.onKeydown);
    }

    public detaching(): void {
        this.observer.disconnect();
        document.removeEventListener('keydown', this.onKeydown);
    }

    public handleClicked(byEnter: boolean = false): void {
        this.events.publish(UxEvents.UxSelectOptionClicked, {
            parent: this.parent,
            value: this.value,
            text: this.slot[0]?.outerHTML?.trim() ?? this.slot[0]?.textContent?.trim(),
            data: this.data,
            byEnter
        });
    }

    public handleHover(): void {
        this.events.publish(UxEvents.UxSelectOptionHovered, { id: this.parent, index: this.index });
    }

    private handleKeyDown(event: KeyboardEvent): void {
        // If the 'Enter' key is pressed, select the focused item.
        if (event.key === 'Enter' && this.focused && !this.hide) this.handleClicked(true);
    }
}
