import { guid, isDefined } from '@wecore/sdk-utilities';

import { bindable, inject, slotted } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(Element)
export class UxCheckbox {
    @bindable() public checked: any[] | boolean | 'true';
    @bindable() public model: any;
    @bindable() public disabled: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public data: any;
    @bindable() public center: string;
    @bindable() public alignment: string = 'items-center';
    @bindable() public useNegativeColors: boolean = false;

    public checkbox: HTMLInputElement;
    public hasFocus: boolean = false;
    public hasLabel: boolean = false;
    public guid: string = guid();

    @(slotted() as any) slot: HTMLElement[];

    public constructor(
        private readonly host: HTMLElement //
    ) {}

    public bound() {
        if (Array.isArray(this.checked))
            this.checkbox.checked = this.checked.some((a: any) => {
                if (isDefined(this.model.id) && isDefined(a.id)) return this.model.id === a.id;
                return a === this.model;
            });
        else this.checkbox.checked = this.checked === true || this.checked === 'true';
    }

    public checkedChanged(newValue: any): void {
        if (this.disabled) return;
        if (Array.isArray(this.checked))
            this.checkbox.checked = this.checked.some((a: any) => {
                if (isDefined(this.model.id) && isDefined(a.id)) return this.model.id === a.id;
                return a === this.model;
            });
        else {
            this.checkbox.checked = newValue === true || newValue === 'true';
            this.checked = newValue;
        }
    }

    public async handleChecked(e: KeyboardEvent): Promise<void> {
        if (this.disabled) return;
        const element = e.srcElement as any;
        if (Array.isArray(this.checked)) {
            const index = this.checked.findIndex((a) => {
                if (isDefined(this.model.id) && isDefined(a.id)) return this.model.id === a.id;
                return a === this.model;
            });
            if (element.checked && index === -1) this.checked.push(this.model);
            else if (!element.checked && index !== -1) this.checked.splice(index, 1);
        } else this.checked = element.checked;

        this.emit(
            UxEvents.OnChange,
            new EventDetails<UxCheckbox, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: element.checked,
                    model: this.model,
                    checked: this.checked
                }
            })
        );
    }

    public handleBlur(e: FocusEvent): void {
        this.hasFocus = false;
        this.emit(
            UxEvents.OnBlur,
            new EventDetails<UxCheckbox, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    model: this.model,
                    checked: this.checked
                }
            })
        );
    }

    public handleFocus(e: FocusEvent): void {
        this.hasFocus = true;
        this.emit(
            UxEvents.OnFocus,
            new EventDetails<UxCheckbox, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    model: this.model,
                    checked: this.checked
                }
            })
        );
    }

    public slotChanged(): void {
        this.hasLabel = true;
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }
}
