<import from="converters/file-name" />
<import from="converters/file-size" />
<import from="converters/blob-to-url" />
<import from="converters/file-to-icon" />

<div class="mt-2 flex flex-col gap-2" if.bind="referral.system && referral.system !== MedicalReferralSystems.Zorgdomein">
    <ux-dropzone max-files.bind="1" accepted-files="application/pdf" valid.bind="validation.file" on-files-selected.bind="handleFilesSelected">
        <div class="flex flex-1 flex-col items-center justify-center gap-1 p-6 text-gray-800">
            <svg class="h-8 w-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.25 14.25C6.25 14.25 4.75 14 4.75 12C4.75 10.2869 6.07542 8.88339 7.75672 8.75897C7.88168 6.5239 9.73368 4.75 12 4.75C14.2663 4.75 16.1183 6.5239 16.2433 8.75897C17.9246 8.88339 19.25 10.2869 19.25 12C19.25 14 17.75 14.25 17.75 14.25"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
                <path d="M14.25 15.25L12 12.75L9.75 15.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12 19.25V13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <span class="text-sm" t="translation:components.widgets.patient-intake.messages.drag-referral-or-click-to-select"></span>
        </div>
    </ux-dropzone>
    <ul if.bind="upload || attachment" role="list" class="flex flex-col space-y-1">
        <li if.bind="upload" class="flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5">
            <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                <template if.bind="isImage(upload.file.type)">
                    <ux-image height="56px" width="75px" size="cover" src.bind="upload.file | blobToUrl"></ux-image>
                </template>
                <template else>
                    <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                        <span class="text-gray-400" innerhtml.bind="upload.extension | fileToIcon:'w-6 h-6'"></span>
                    </div>
                </template>
            </div>
            <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                <div class="flex min-w-0 flex-1 flex-col">
                    <p class="= pointer-events-none block truncate text-sm text-gray-900">${ upload.name | fileName }${ upload.extension }</p>
                    <p class="pointer-events-none block text-sm text-gray-500">${ upload.file.size | fileSize }</p>
                </div>
                <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                    <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                            <path
                                fill="currentColor"
                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                        </svg>
                    </div>
                    <ux-dropdown-option click.trigger="handleFileRemoved()">
                        <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                    </ux-dropdown-option>
                </ux-dropdown>
            </div>
        </li>
        <li if.bind="attachment" class="flex items-center gap-x-4 rounded-lg border border-gray-300 pr-5">
            <div class="flex rounded-l-lg border-r border-gray-300 bg-gray-50">
                <template if.bind="isImage(attachment.contentType)">
                    <ux-image height="56px" width="75px" size="cover" src.bind="urls[attachment.id]"></ux-image>
                </template>
                <template else>
                    <div class="flex h-[56px] w-[75px] items-center justify-center rounded-lg bg-transparent">
                        <span class="text-gray-400" innerhtml.bind="attachment.extension | fileToIcon:'w-6 h-6'"></span>
                    </div>
                </template>
            </div>
            <div class="flex min-w-0 flex-1 items-center justify-between py-2">
                <div class="flex min-w-0 flex-1 flex-col">
                    <p class="= pointer-events-none block truncate text-sm text-gray-900">${ attachment.name }${ attachment.extension }</p>
                    <p class="pointer-events-none block text-sm text-gray-500">${ attachment.size | fileSize }</p>
                </div>
                <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                    <div au-slot="label" class="rounded-full p-1 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                            <path
                                fill="currentColor"
                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                        </svg>
                    </div>
                    <ux-dropdown-option click.trigger="handleFileRemoved()">
                        <span class="pointer-events-none text-red-500" t="translation:global.buttons.delete"></span>
                    </ux-dropdown-option>
                </ux-dropdown>
            </div>
        </li>
    </ul>
</div>
