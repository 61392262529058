<import from="converters/translate" />
<import from="converters/get-values" />
<import from="../template-question/template-question" />

<div class="${columns} flex flex-col" if.bind="shouldBeDisplayed && registration.questionnaire">
    <div
        class="${ recent && recent.informationSheet ? 'py-1': 'py-1.5' } flex items-center justify-between gap-0.5 rounded-lg px-2"
        class.bind="validation.valid ? 'bg-gray-100 text-gray-800' : 'bg-red-100 text-red-600'">
        <a href="javascript:" click.trigger="collapseOrExpand()" class="flex min-w-0 flex-1 items-center gap-x-1 font-medium">
            <div>
                <template if.bind="states[step.id].expanded">
                    <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                    </svg>
                </template>
                <template else>
                    <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                    </svg>
                </template>
            </div>
            <div class="flex min-w-0 flex-1 items-center gap-3 pr-2 text-sm">
                <!-- <span class="text-gray-500" t="translation:global.labels.questionnaire"></span> -->
                <div class="flex min-w-0 flex-1 items-center gap-1 pr-4">
                    <span class="block truncate">${ registration.questionnaire.name | translate:language }</span>
                    <span if.bind="required" class="block truncate text-red-500">*</span>
                </div>
                <div if.bind="isAnswered" class="h-2 w-2 rounded-sm bg-green-500"></div>
            </div>
        </a>
        <button if.bind="recent && recent.informationSheet" click.trigger="openInformationSheet()" class="flex h-7 w-7 items-center rounded-full text-blue-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>
        </button>
    </div>
    <template if.bind="states[step.id].expanded">
        <div class="ml-1 mt-1 grid auto-cols-fr grid-cols-12 gap-1 border-l-2 border-gray-300 pl-4">
            <template repeat.for="stepToTake of registration.questionnaire.questions">
                <template-question
                    flattened.bind="flattened"
                    record.bind="record"
                    flow.bind="registration.questionnaire.flow"
                    registrations.bind="registrations"
                    step.bind="stepToTake"
                    states.bind="states"
                    registration.bind="stepToTake"
                    workspace.bind="workspace"
                    language.bind="language"
                    is-evaluation-step.bind="isEvaluationStep"
                    nested.bind="true"
                    validation.bind="validation.questions[$index]"
                    on-file-selected.bind="onFileSelected"
                    on-file-removed.bind="onFileRemoved"
                    loading.bind="loading">
                </template-question>
            </template>
        </div>
    </template>
</div>
<template if.bind="!registration.questionnaire">
    <div class="${columns} flex items-center justify-between rounded-lg bg-red-100 px-3 py-2 text-sm font-medium text-red-600">
        <div class="flex min-w-0 flex-1 items-center gap-x-3">
            <span class="text-gray-500" t="translation:global.labels.questionnaire"></span>
            <div class="flex min-w-0 flex-1 justify-between">
                <span class="text-gray-800">${ step.questionnaire.translations | translate:language }</span>
                <div class="flex min-w-0 gap-x-3">
                    <span class="block truncate" t="translation:global.messages.deleted-questionnaire"></span>
                    <button
                        click.trigger="remove(step.id)"
                        class="flex h-5 w-5 items-center justify-center rounded-full text-red-500 ring-red-500 ring-offset-1 hover:text-red-600 focus:outline-none focus:ring-1 active:text-red-700">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
