<import from="converters/translate" />
<import from="converters/date" />

<ux-select
    init="false"
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear && !disabled"
    disabled.bind="disabled"
    data.bind="data"
    onselect.trigger="handleSelect($event)"
    onclear.trigger="handleClear($event)">
    <ux-select-option repeat.for="record of records" disabled.bind="disabled" value.bind="record.id">${record.createdAt | date} - ${record.examination.name | translate:language}</ux-select-option>
    <ux-select-option if.bind="records.length === 0" disabled.bind="true">
        <span t="translation:global.labels.no-results"></span>
    </ux-select-option>
</ux-select>
