import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { EmailLogStatuses, EmailsApiClient, GetEmailResponse } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, EmailsApiClient)
export class PartialEntityContactMoments extends BasePartialView {
    public logs: GetEmailResponse[] = [];
    public closeable: boolean = false;
    public EmailLogStatuses: typeof EmailLogStatuses = EmailLogStatuses;

    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;
    private entityId: string;
    private entityType: 'Account' | 'Contact';

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly emailsApi: EmailsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.closeable = view.data.closeable || false;
        this.entityId = view.data.id;
        this.entityType = view.data.type;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [] = await Promise.all([
                    this.loadViewsFromUrl({
                        open: async (view: string, entityId: string) => {
                            if (isNotDefined(view)) return;
                            if (view.includes('Details') && isNotEmpty(entityId)) this.details(new GetEmailResponse({ id: entityId }), false);
                        }
                    }),
                    this.getData(false, true)
                ]);

                this.subscriptions.push(this.events.subscribe(CustomEvents.EmailSent, () => this.refreshAuthenticated()));

                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialEntityContactMoments.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async details(log: GetEmailResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.EntityContactMomentsDetails.with({ id: log.id, entityType: this.entityType }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true,
                updateUrl
            })
        });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true);

        element.isLoading = false;
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async refreshAuthenticated(): Promise<void> {
        this.getData(true);
    }

    private async getData(reset: boolean = false, initial: boolean = false, showLoading: boolean = true): Promise<void> {
        if (reset) {
            this.skip = 0;
            this.logs = [];
            this.endOfList = false;
            if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
        }

        if (showLoading) this.isLoading = true;
        const response = await this.emailsApi.search(
            this.authenticated.workspace.id, //
            isNotEmpty(this.query) ? this.query : undefined,
            this.pageSize,
            this.skip,
            undefined,
            undefined,
            undefined,
            undefined,
            this.entityType === 'Contact' ? [this.entityId] : undefined,
            this.entityType === 'Account' ? [this.entityId] : undefined
        );

        if (!reset && response.data.empty() && !initial) {
            this.endOfList = true;
            this.isLoading = false;
            return;
        }

        this.skip += Number(this.pageSize);
        this.logs = [...this.logs, ...response.data];
        if (showLoading) this.isLoading = false;
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
