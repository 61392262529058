import { GetAppointmentResponse } from '@wecore/sdk-healthcare';

export interface ISchedulerState {
    isColumnDragging: boolean;
    isAppointmentDragging: boolean;
    isAppointmentResizing: boolean;
    isSaving: boolean;
    column?: number;
    cut?: {
        appointment: GetAppointmentResponse;
        columnIndex: number;
    };
}

export class SchedulerState {
    public isColumnDragging: boolean;
    public isAppointmentDragging: boolean;
    public isAppointmentResizing: boolean;
    public isSaving: boolean;
    public column?: number;
    public cut?: {
        appointment: GetAppointmentResponse;
        columnIndex: number;
    };

    public constructor(data: ISchedulerState) {
        this.isColumnDragging = data?.isColumnDragging;
        this.isAppointmentDragging = data?.isAppointmentDragging;
        this.isAppointmentResizing = data?.isAppointmentResizing;
        this.isSaving = data?.isSaving;
        this.column = data?.column;
        this.cut = data?.cut;
    }
}
