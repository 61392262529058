<import from="bx/bx-sport-selector/bx-sport-selector" />

<div class="flex flex-col">
    <div class="flex items-center justify-between">
        <ux-label>
            <span t="translation:components.sports-selector.labels.select-sports"></span>
        </ux-label>
        <ux-button click.trigger="addItem()" padding.bind="false" size="xs">
            <div class="flex p-1">
                <svg class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </div>
        </ux-button>
    </div>
    <ul class="mt-2 flex flex-col space-y-1">
        <li class="rounded-lg bg-gray-100 p-3" if.bind="registrations.length === 0">
            <p class="text-sm" t="translation:components.sports-selector.messages.no-sports-selected"></p>
        </li>
        <li class="grid grid-cols-12 gap-1" repeat.for="item of registrations">
            <div class="col-span-12 flex space-x-1">
                <bx-sport-selector
                    on-select.bind="handleSportSelected"
                    data.bind="$index"
                    language.bind="language"
                    workspace.bind="workspace"
                    t="[placeholder]translation:components.sports-selector.placeholders.select-sport"
                    valid.bind="validation[$index].sport"
                    selected.bind="item.sport"
                    value.two-way="item.sport.id">
                </bx-sport-selector>
                <button
                    class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-red-500 ring-gray-300 ring-offset-1 hover:bg-gray-50 focus:ring-1 active:bg-gray-100"
                    click.trigger="deleteItem($index)">
                    <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button>
            </div>
            <div class="col-span-12">
                <ux-textarea
                    action.bind="c => manageTranslationsFor($index)"
                    rows="1"
                    value.two-way="item.comments[language]"
                    t="[placeholder]translation:components.sports-selector.placeholders.enter-comments">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                    </svg>
                </ux-textarea>
            </div>
        </li>
    </ul>
</div>
