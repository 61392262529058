<section class="confirm-modal min-h-64 overflow-hidden">
    <ux-dialog class="flex w-screen flex-col overflow-hidden rounded-none md:h-auto md:rounded-lg">
        <div class="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex min-h-screen items-end justify-center p-4 text-center md:items-center">
                <div class="fixed inset-0 bg-gray-500/50 transition-opacity" aria-hidden="true"></div>
                <span class="hidden h-full sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
                <div class="inline-block w-full transform overflow-hidden rounded-lg bg-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle">
                    <div class="absolute right-0 top-0 hidden pr-4 pt-5 sm:block">
                        <button
                            click.trigger="cancel()"
                            type="button"
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2">
                            <span class="sr-only">Close</span>
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-indigo-700">
                                <path
                                    stroke-linecap="round"
                                    d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-lg font-medium leading-6 text-gray-900" t="modals.choose-link-or-upload.title"></h3>
                            <div class="mt-2">
                                <p class="mb-2 text-sm text-gray-500" t="modals.choose-link-or-upload.message"></p>
                                <ux-input autofocus.bind="true" t="[placeholder]translation:modals.choose-link-or-upload.placeholders.enter-link" value.two-way="link"></ux-input>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 flex flex-col gap-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                        <ux-button color.bind="primary" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="ok()">
                            <span if.bind="link && link.length > 0 && link.trim() !== ''" class="pointer-events-none text-sm" t="global.buttons.add"></span>
                            <span else class="pointer-events-none text-sm" t="global.buttons.upload"></span>
                        </ux-button>
                        <ux-button disabled.bind="isLoading" class="sm:mr-2" mode="outline" color="dark" click.trigger="cancel()">
                            <span class="pointer-events-none text-sm" t="global.buttons.cancel"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </ux-dialog>
</section>
