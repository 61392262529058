import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetAllHealthcarePricesResponseItem, HealthcarePrice, HealthcarePricesApiClient, UpdateHealthcarePricesRequest, UpdateHealthcarePricesRequestItem } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcarePricesApiClient)
export class PartialManagePracticePrices extends BasePartialView {
    public prices: GetAllHealthcarePricesResponseItem[];
    public states: any = {};
    public validation: any = {
        valid: true,
        items: []
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly pricesApi: HealthcarePricesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.prices = await this.pricesApi.getAll(this.authenticated.workspace.id);

                for (const item of this.prices) {
                    this.states[item.entityId] = { visible: true };

                    const validation = { id: item.entityId, prices: [] };
                    for (const _ of item.prices) {
                        validation.prices.push({
                            price: true,
                            periodStart: true,
                            periodEnd: true,
                            period: true,
                            overlaps: true,
                            overlapsInsurer: true
                        });
                    }
                    this.validation.items.push(validation);
                }

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManagePracticePrices.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async createOrEditPrice(entityId: string, index: number): Promise<void> {
        const parentIndex = this.prices.findIndex((x) => x.entityId === entityId);
        const price =
            index > -1
                ? this.prices[parentIndex].prices[index]
                : new HealthcarePrice({
                      vatValue: 0
                  });
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.prices[parentIndex].prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.prices[parentIndex].prices[data.index] = data.price;
                    else this.prices[parentIndex].prices.push(data.price);

                    this.prices[parentIndex].prices = [
                        ...(this.prices[parentIndex].prices.length > 0 ? [this.prices[parentIndex].prices.shift()] : []), //
                        ...this.prices[parentIndex].prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async createOrEditSubPrice(entityId: string, stepIndex: number, index: number): Promise<void> {
        const parentIndex = this.prices.findIndex((x) => x.entityId === entityId);
        const price =
            index > -1
                ? this.prices[parentIndex].stepPrices[stepIndex].prices[index]
                : new HealthcarePrice({
                      vatValue: 0
                  });
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.prices[parentIndex].stepPrices[stepIndex].prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.prices[parentIndex].stepPrices[stepIndex].prices[data.index] = data.price;
                    else this.prices[parentIndex].stepPrices[stepIndex].prices.push(data.price);

                    this.prices[parentIndex].stepPrices[stepIndex].prices = [
                        ...(this.prices[parentIndex].stepPrices[stepIndex].prices.length > 0 ? [this.prices[parentIndex].stepPrices[stepIndex].prices.shift()] : []), //
                        ...this.prices[parentIndex].stepPrices[stepIndex].prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public removePrice(id: string, index: number): void {
        const indexOfItem = this.prices.findIndex((x) => x.entityId === id);
        this.prices[indexOfItem].prices.splice(index, 1);
    }

    public formatDescription(item: HealthcarePrice): string {
        let description = '';
        if (isDefined(item.periodStart)) description += format(item.periodStart, 'dd-MM-yyyy');
        if (isDefined(item.periodStart)) description += ' - ';
        if (isDefined(item.periodEnd)) description += format(item.periodEnd, 'dd-MM-yyyy');

        if (isDefined(item.insurer)) description += ` (${item.insurer.translations[this.language]})`;

        return description;
    }

    public async handleSearch(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = e.detail.element;
        element.isLoading = true;
        const query = e.detail.values?.value;

        for (const item of this.prices) {
            if (isNotDefined(query) || isEmpty(query)) {
                this.states[item.entityId].visible = true;
                continue;
            }

            const isVisible = item.name[this.language].toLowerCase().includes(query.toLowerCase());
            this.states[item.entityId].visible = isVisible;
        }

        element.isLoading = false;
    }

    public async save(): Promise<void> {
        try {
            this.isLoading = true;
            await this.pricesApi.update(
                this.authenticated.workspace.id,
                new UpdateHealthcarePricesRequest({
                    items: this.prices.map(
                        (x) =>
                            new UpdateHealthcarePricesRequestItem({
                                entityId: x.entityId,
                                entityType: x.entityType,
                                prices: x.prices,
                                stepPrices: x.stepPrices
                            })
                    )
                })
            );

            this.notifications.show(
                this.t.tr('translation:partial-views.manage-practice-prices.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.manage-practice-prices.notifications.save-successful.message'),
                {
                    type: 'success',
                    duration: 3000
                }
            );
            this.isLoading = false;
        } catch (e) {
            this.errorHandler.handle('manage-practice-prices', e);
            this.isLoading = false;
        }
    }
}
