import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { SortDirection } from '@wecore/sdk-crm';
import {
    CreateDeclarationsRequest,
    DeclarationPerformanceStatuses,
    DeclarationPerformanceTypes,
    DeclarationPerformancesApiClient,
    DeclarationSendMethods,
    DeclarationsApiClient,
    GetDeclarationPerformanceResponse
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, savePageState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { checkForFilters, cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { KeyValuePair } from '../../models/key-value-pair';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxCheckbox } from '../../ux/ux-checkbox/ux-checkbox';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, DeclarationPerformancesApiClient, ModalService, DeclarationsApiClient)
export class PartialDeclarationPerformances extends BasePartialView {
    public performances: GetDeclarationPerformanceResponse[] = [];
    public total: number = 0;
    public sorting: any = {};
    public SortDirection: typeof SortDirection = SortDirection;
    public noResults: boolean = false;
    public initialized: boolean = false;
    public selection: GetDeclarationPerformanceResponse[] = [];
    public selectedAll: boolean = false;
    public DeclarationPerformanceStatuses: typeof DeclarationPerformanceStatuses = DeclarationPerformanceStatuses;
    public DeclarationPerformanceTypes: typeof DeclarationPerformanceTypes = DeclarationPerformanceTypes;

    private pageSize: number = 1000;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private fetching: boolean = false;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly performancesApi: DeclarationPerformancesApiClient,
        private readonly modalService: ModalService,
        private readonly declarationsApi: DeclarationsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                if (isNotDefined(this.pageState.values.filters))
                    this.pageState.values.filters = {
                        practitioners: [],
                        invoices: [],
                        declarationSendMethods: [],
                        patients: [],
                        performanceStatuses: [],
                        declarationStatuses: [],
                        insurers: [],
                        start: undefined,
                        end: undefined
                    };

                this.loadViewsFromUrl({
                    open: async (view: string, entityId: string) => {
                        if (isNotDefined(view)) return;
                        if (view.includes('Edit') && isNotEmpty(entityId)) this.edit(new GetDeclarationPerformanceResponse({ id: entityId }), false);
                    }
                });

                const [] = await Promise.all([
                    this.fetch(true) //
                ]);

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(CustomEvents.DeclarationPerformancesUpdated, (data: { performance: GetDeclarationPerformanceResponse }) => {
                        const index = this.performances.findIndex((d) => d.id === data.performance.id);
                        if (index > -1) {
                            this.performances[index] = data.performance;
                            this.performances = [
                                ...(this.performances.length > 0 ? [this.performances.shift()] : []), //
                                ...cloneDeep(this.performances)
                            ];
                        }
                    }),
                    this.events.subscribe(CustomEvents.DeclarationPerformancesCredited, () => {
                        this.fetch(true);
                    })
                ];

                this.baseLoaded = true;

                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarationPerformances.attached', x));
    }

    public async edit(performance: GetDeclarationPerformanceResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditDeclarationPerformances.with({ id: performance.id, closeable: true }) //
                .whenClosed(async (result: PartialViewResults, _: any) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) this.fetch(true);
                }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl })
        });
    }

    public async openFilters(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.Filters.with({
                filters: this.pageState.values.filters,
                settings: {
                    practitioners: true,
                    invoices: true,
                    declarationSendMethods: true,
                    declarations: true,
                    patients: true,
                    performanceStatuses: true,
                    performanceTypes: true,
                    insurers: true,
                    start: true,
                    end: true
                }
            }) //
                .whenClosed(async (result: PartialViewResults, filters: any) => {
                    if (result === PartialViewResults.Ok) {
                        this.pageState.values.hasFilters = checkForFilters(filters);
                        this.pageState.values.filters = filters;
                        savePageState(this.pageState);
                        await this.fetch(true);
                    }
                }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public fetch = async (reset: boolean = false, timeout: number = 0): Promise<void> => {
        if (this.fetching) return;
        this.fetching = true;
        return new Promise(async (resolve) => {
            this.noResults = false;
            if (reset) {
                this.skip = 0;
                this.performances = [];
                this.endOfList = false;
                this.fetching = false;
                if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
            }

            this.baseLoaded = false;

            setTimeout(async () => {
                const response = await this.performancesApi.search(
                    this.authenticated.workspace.id, //
                    this.pageSize,
                    this.skip,
                    this.sorting.direction,
                    this.sorting.field,
                    undefined,
                    this.pageState.values.filters.invoices?.any() ? this.pageState.values.filters.invoices.map((i: KeyValuePair) => i.key) : undefined,
                    this.pageState.values.filters.patients?.any() ? this.pageState.values.filters.patients.map((i: KeyValuePair) => i.key) : undefined,
                    undefined,
                    this.pageState.values.filters.practitioners?.any() ? this.pageState.values.filters.practitioners.map((i: KeyValuePair) => i.key) : undefined,
                    this.pageState.values.filters.insurers?.any() ? this.pageState.values.filters.insurers.map((i: KeyValuePair) => i.key) : undefined,
                    this.pageState.values.filters.performanceStatuses?.any()
                        ? this.pageState.values.filters.performanceStatuses.map((i: KeyValuePair) => i.key as DeclarationPerformanceStatuses)
                        : undefined,
                    this.pageState.values.filters.declarationSendMethods?.any()
                        ? this.pageState.values.filters.declarationSendMethods.map((i: KeyValuePair) => i.key as DeclarationSendMethods)
                        : undefined,
                    isDefined(this.pageState.values.filters.start) ? this.pageState.values.filters.start : undefined,
                    isDefined(this.pageState.values.filters.end) ? this.pageState.values.filters.end : undefined,
                    this.pageState.values.filters.declarations?.any() ? this.pageState.values.filters.declarations.map((i: KeyValuePair) => i.key) : undefined,
                    this.pageState.values.filters.performanceTypes?.any() ? this.pageState.values.filters.performanceTypes.map((i: KeyValuePair) => i.key as DeclarationPerformanceTypes) : undefined
                );

                this.total = response.total;
                if (response.data.empty()) {
                    this.endOfList = true;
                    this.noResults = this.performances.empty();
                    this.baseLoaded = true;
                    this.fetching = false;
                    resolve();
                    return;
                }

                this.skip += Number(this.pageSize);
                this.performances = [...this.performances, ...response.data];

                this.baseLoaded = true;
                this.initialized = true;
                this.fetching = false;

                resolve();
            }, timeout);
        });
    };

    public async sort(field: 'CreatedAt'): Promise<void> {
        if (this.sorting.field === field && isNotDefined(this.sorting.direction)) this.sorting.direction = SortDirection.Asc;
        else if (this.sorting.field === field && this.sorting.direction === SortDirection.Asc) this.sorting.direction = SortDirection.Desc;
        else if (this.sorting.field === field && this.sorting.direction === SortDirection.Desc) this.sorting.direction = undefined;
        else {
            this.sorting.field = field;
            this.sorting.direction = SortDirection.Asc;
        }
        await this.fetch(true);
    }

    public removeFilter = (type: string, index: number): void => {
        if (type === 'start' || type === 'end') {
            this.pageState.values.filters[type] = undefined;
        } else this.pageState.values.filters[type].splice(index, 1);

        this.pageState.values.hasFilters = checkForFilters(this.pageState.values.filters);
        savePageState(this.pageState);
        this.fetch(true);
    };

    public handleSelectAll(e: CustomEvent<EventDetails<UxCheckbox, any>>): void {
        const checked = e.detail.values.checked;
        if (checked) {
            this.selection = cloneDeep(
                this.performances.filter((x) => x.status === DeclarationPerformanceStatuses.Draft) //
            );
        } else this.selection = [];
    }

    public handleItemChecked(): void {
        this.selectedAll = this.performances
            .filter((x) => x.status === DeclarationPerformanceStatuses.Draft) //
            .every((p) => this.selection.some((s) => s.id === p.id));
    }

    public async convertToMessage(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declaration-performances.questions.convert.title'),
                message: this.t.tr('translation:partial-views.declaration-performances.questions.convert.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:partial-views.declaration-performances.buttons.convert'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.declarationsApi.create(
                                this.authenticated.workspace.id,
                                new CreateDeclarationsRequest({
                                    performanceIds: this.selection.map((x) => x.id)
                                })
                            );
                            this.notifications.show(
                                this.t.tr('translation:partial-views.declaration-performances.notifications.convert-successful.title'),
                                this.t.tr('translation:partial-views.declaration-performances.notifications.convert-successful.message'),
                                { type: 'success', duration: 3000 }
                            );
                            this.selection = [];
                            this.selectedAll = false;
                            this.fetch(true);
                            this.events.publish(CustomEvents.HealthcareInvoicesUpdated);
                        } catch (e) {
                            this.isLoading = false;
                            this.errorHandler.handle('[convert-performances]', e);
                        }
                    } else this.isLoading = false;
                }
            })
        );
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading && !this.fetching) {
            await this.fetch();
        }
    }
}
