<import from="converters/keys" />
<import from="converters/currency" />
<import from="converters/translate" />
<import from="converters/time-ago" />
<import from="converters/date" />
<import from="converters/declaration-send-method-to-string" />
<import from="converters/declaration-performance-status-to-string" />
<import from="converters/declaration-performance-type-to-string" />
<import from="converters/declaration-performance-status-to-color" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex flex-col w-full h-full overflow-hidden">
            <template if.bind="baseLoaded">
                <div class="flex flex-col flex-1 px-8 pb-5 overflow-y-auto gap-y-3">
                    <div class="sticky top-0 z-10 flex flex-col justify-between pt-8 pb-3 bg-white border-b border-gray-200">
                        <div class="flex items-center justify-between">
                            <p class="font-medium text-gray-800" t="translation:partial-views.declaration-performances.edit.title"></p>
                            <ux-badge type="${performance.status | declarationPerformanceStatusToColor}">
                                <span>${performance.status | declarationPerformanceStatusToString | t}</span>
                            </ux-badge>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-col pb-3 border-b border-gray-200">
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.type"></span>
                                <span>${performance.type | declarationPerformanceTypeToString | t}</span>
                            </div>
                            <div if.bind="performance.type === DeclarationPerformanceTypes.Credit" class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.declaration-performances.labels.debit-reference"></span>
                                <span> ${performance.debitReference}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.quantity"></span>
                                <span>${performance.quantity}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.vat-percentage"></span>
                                <span>${performance.vatPercentage}%</span>
                            </div>

                            <div class="my-1.5 flex flex-col rounded-lg bg-[#2d2d2d] p-4 text-[#ccc]">
                                <div class="flex justify-between gap-3 text-sm">
                                    <span t="translation:global.labels.total-excl-vat"></span>
                                    <span if.bind="performance.type === DeclarationPerformanceTypes.Credit">${(0 - performance.totalExclVat) | currency:false}</span>
                                    <span else>${performance.totalExclVat | currency:false}</span>
                                </div>
                                <div class="flex justify-between gap-3 text-sm">
                                    <span t="translation:global.labels.total-incl-vat"></span>
                                    <span if.bind="performance.type === DeclarationPerformanceTypes.Credit">${(0 - performance.totalInclVat) | currency:false}</span>
                                    <span else>${performance.totalInclVat | currency:false}</span>
                                </div>
                                <div if.bind="performance.awardedAmountInclTaxFinancial > 0" class="border-gray-[#ccc] mt-1 flex justify-between gap-3 border-t pt-0.5 text-sm">
                                    <span t="translation:global.labels.total-awarded"></span>
                                    <span if.bind="performance.type === DeclarationPerformanceTypes.Debit" class="font-medium text-green-500">
                                        ${performance.awardedAmountInclTaxFinancial | currency:false}
                                    </span>
                                    <span else class="font-medium text-red-500"> ${(0 - performance.awardedAmountInclTaxFinancial) | currency:false} </span>
                                </div>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.created-at"></span>
                                <span>${performance.createdAt | date:'dd-MM-yyyy HH:mm'}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.created-by"></span>
                                <span>${performance.createdBy.name}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.medical-record"></span>
                                <span>${performance.record.translations | translate:language}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.description"></span>
                                <span>${performance.description}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.performance-code"></span>
                                <span>${performance.code.system}/${performance.code.value}</span>
                            </div>
                            <div if.bind="patient && hasRole(UserRoles.ReadPatients)" class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.patient"></span>
                                <span>${patient.displayName} (${patient.number})</span>
                            </div>
                            <div if.bind="hasRole(UserRoles.ReadHealthcareInvoices)" class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.invoice"></span>
                                <a href="javascript:" class="underline decoration-primary" click.trigger="openInvoice(performance.invoice.id)">#${performance.invoice.name}</a>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.practitioner"></span>
                                <span>${performance.practitioner.name}</span>
                            </div>
                            <div class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:global.labels.practitioner-code"></span>
                                <span>${performance.practitionerCode}</span>
                            </div>
                            <template if.bind="performance.status !== DeclarationPerformanceStatuses.Draft || !hasRole(UserRoles.WriteDeclarationPerformances)">
                                <div class="flex justify-between gap-3 text-sm">
                                    <span class="text-gray-500" t="translation:partial-views.declaration-performances.labels.send-via"></span>
                                    <span> ${performance.sendMethod | declarationSendMethodToString | t}</span>
                                </div>
                                <div class="flex justify-between gap-3 text-sm">
                                    <span class="text-gray-500" t="translation:global.labels.insurance"></span>
                                    <span> ${performance.insurance.name} (${performance.insurance.insurer.translations | translate:language} - ${performance.insurance.insurerReference})</span>
                                </div>
                                <div class="flex justify-between gap-3 text-sm">
                                    <span class="text-gray-500" t="translation:global.labels.performance-date"></span>
                                    <span> ${performance.performanceDate | date:'dd-MM-yyyy'}</span>
                                </div>
                                <div class="flex justify-between gap-3 text-sm">
                                    <span class="text-gray-500" t="translation:global.labels.external-reference"></span>
                                    <span> ${performance.externalReference}</span>
                                </div>
                            </template>
                            <div if.bind="declaration && hasRole(UserRoles.ReadDeclarations)" class="flex justify-between gap-3 text-sm">
                                <span class="text-gray-500" t="translation:partial-views.declaration-performances.labels.declaration-message"></span>
                                <a href="javascript:" class="underline decoration-primary" click.trigger="openDeclaration(performance.declaration.id)">
                                    <span if.bind="declaration.trackingNumber">${declaration.trackingNumber}</span>
                                    <div else class="flex items-center gap-1 text-gray-600">
                                        <span t="translation:global.labels.draft"></span>
                                        <span>#${declaration.conceptNumber}</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <template if.bind="performance.status === DeclarationPerformanceStatuses.Draft && hasRole(UserRoles.WriteDeclarationPerformances)">
                            <div class="flex flex-col mt-3">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.declaration-performances.labels.send-via"></span>
                                </ux-label>
                                <ux-select disabled.bind="performance.status === DeclarationPerformanceStatuses.RejectedByVecozo" value.two-way="performance.sendMethod">
                                    <ux-select-option repeat.for="method of DeclarationSendMethods | keys" value.bind="method">
                                        <span>${method | declarationSendMethodToString | t} </span>
                                    </ux-select-option>
                                </ux-select>
                            </div>
                            <div class="flex flex-col mt-3">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.insurance"></span>
                                </ux-label>
                                <ux-select
                                    value.two-way="performance.insurance.id"
                                    onselect.trigger="handleInsuranceSelected($event)"
                                    t="[placeholder]translation:partial-views.declaration-performances.placeholders.select-insurance">
                                    <ux-select-option repeat.for="insurance of patient.insurances" value.bind="insurance.id">
                                        <span>${insurance.name} (${insurance.insurer.translations | translate:language} - ${insurance.insurerReference})</span>
                                    </ux-select-option>
                                </ux-select>
                            </div>
                            <div class="flex flex-col mt-3">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.performance-date"></span>
                                </ux-label>
                                <ux-datepicker value.to-view="performance.performanceDate" onselect.trigger="handleDateChanged($event)" data.bind="$index"> </ux-datepicker>
                            </div>
                        </template>
                        <div class="flex flex-col mt-5">
                            <ux-label class="mb-3">
                                <span t="translation:global.labels.timeline"></span>
                            </ux-label>
                            <ul role="list" class="space-y-3">
                                <li class="relative flex flex-col gap-x-4">
                                    <div class="flex gap-x-4">
                                        <div if.bind="performance.events.length > 0" class="absolute top-0 left-0 flex justify-center w-6 -bottom-8">
                                            <div class="w-px bg-gray-200"></div>
                                        </div>
                                        <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                        </div>
                                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span class="font-medium text-gray-900">${performance.createdBy.name}</span>
                                            <span t="translation:partial-views.declaration-performances.messages.created-the-performance"></span>
                                        </p>
                                        <ux-tooltip content="${performance.createdAt | date:'dd-MM-yyyy HH:mm'}">
                                            <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${performance.createdAt | timeAgo:false:false:true}</time>
                                        </ux-tooltip>
                                    </div>
                                    <div class="flex flex-col gap-2 pl-10 mt-2"></div>
                                </li>
                                <li repeat.for="event of performance.events" class="relative flex flex-col gap-x-4">
                                    <div class="flex gap-x-4">
                                        <div if.bind="!$last" class="absolute top-0 left-0 flex justify-center w-6 -bottom-8">
                                            <div class="w-px bg-gray-200"></div>
                                        </div>
                                        <template
                                            if.bind="event.type === DeclarationEventTypes.RejectedByVecozo 
                                        || event.type === DeclarationEventTypes.FailedInternalValidation  
                                        || event.type === DeclarationEventTypes.FailedToSend 
                                        || event.type === DeclarationEventTypes.FailedToCheck 
                                        || event.type === DeclarationEventTypes.SentToVecozo 
                                        || event.type === DeclarationEventTypes.Rejected
                                        || event.type === DeclarationEventTypes.Accepted
                                        || event.type === DeclarationEventTypes.PartiallyAccepted
                                        || event.type === DeclarationEventTypes.DownloadedForInfomedics">
                                            <div
                                                if.bind="event.type === DeclarationEventTypes.RejectedByVecozo 
                                            || event.type === DeclarationEventTypes.FailedInternalValidation  
                                            || event.type === DeclarationEventTypes.FailedToCheck
                                            || event.type === DeclarationEventTypes.FailedToSend 
                                            || event.type === DeclarationEventTypes.Rejected"
                                                class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-red-500">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div
                                                if.bind="event.type === DeclarationEventTypes.SentToVecozo 
                                                    || event.type === DeclarationEventTypes.DownloadedForInfomedics"
                                                class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg
                                                    rotate-90.class="event.type === DeclarationEventTypes.DownloadedForInfomedics"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    class="w-6 h-6 text-primary">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div if.bind="event.type === DeclarationEventTypes.Accepted" class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-green-500">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div if.bind="event.type === DeclarationEventTypes.PartiallyAccepted" class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-yellow-500">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </template>
                                        <div else class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                            <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                        </div>
                                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span class="font-medium text-gray-900">${event.createdBy.name}</span>
                                            <span if.bind="event.type === DeclarationEventTypes.Updated" t="translation:partial-views.declaration-performances.messages.updated-the-peformance"></span>
                                            <span if.bind="event.type === DeclarationEventTypes.SentToVecozo" t="translation:partial-views.declaration-performances.messages.sent-to-vecozo"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.RejectedByVecozo"
                                                t="translation:partial-views.declaration-performances.messages.rejected-by-vecozo"></span>
                                            <template if.bind="event.type === DeclarationEventTypes.IncludedInDeclaration">
                                                <span t="translation:partial-views.declaration-performances.messages.added-to-declaration"></span>
                                                <span class="text-primary">${event.data.trackingNumber}</span>
                                            </template>

                                            <span
                                                if.bind="event.type === DeclarationEventTypes.RemovedFromDeclaration"
                                                t="translation:partial-views.declaration-performances.messages.removed-from-declaration"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Rejected && performance.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declaration-performances.messages.rejected"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Rejected && performance.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declaration-performances.messages.rejected-infomedics"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Archived"
                                                t="translation:partial-views.declaration-performances.messages.archived-from-declaration"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.PartiallyAccepted && performance.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declaration-performances.messages.partially-accepted"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.PartiallyAccepted && performance.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declaration-performances.messages.partially-accepted-infomedics"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Accepted && performance.sendMethod === DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declaration-performances.messages.accepted"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.Accepted && performance.sendMethod !== DeclarationSendMethods.Vecozo"
                                                t="translation:partial-views.declaration-performances.messages.accepted-infomedics"></span>
                                            <span
                                                if.bind="event.type === DeclarationEventTypes.DownloadedForInfomedics"
                                                t="translation:partial-views.declaration-performances.messages.downloaded-for-infomedics"></span>
                                        </p>
                                        <ux-tooltip content="${event.createdAt | date:'dd-MM-yyyy HH:mm'}">
                                            <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${event.createdAt | timeAgo:false:false:true}</time>
                                        </ux-tooltip>
                                    </div>
                                    <div class="flex flex-col gap-2 pl-10 mt-2">
                                        <div if.bind="event.errorMessage" class="py-4 rounded-lg bg-gray-50">
                                            <a href="javascript:" click.trigger="toggleEventMessage('error', $index)" class="flex justify-between px-6 font-semibold hover:text-primary">
                                                <span class="text-xs" t="translation:global.labels.error"></span>
                                                <svg
                                                    if.bind="viewState.events[$index].error"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                </svg>
                                                <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                            </a>
                                            <div class="px-6 mt-2 overflow-hidden" show.bind="viewState.events[$index].error">
                                                <div class="flex flex-col gap-2 overflow-x-auto rounded-lg bg-[#2d2d2d] p-3 text-xs text-[#ccc]">
                                                    <p>${event.errorMessage}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div if.bind="event.messages.length > 0" class="flex flex-col gap-2">
                                            <div repeat.for="message of event.messages" class="flex flex-col px-6 py-3 rounded-lg bg-gray-50">
                                                <p if.bind="event.messages.length > 1" class="pt-1 text-gray-400 text-xxs">
                                                    <span t="translation:global.labels.message"></span>
                                                    <span>#${$index + 1}</span>
                                                </p>
                                                <div class="flex flex-col divide-y-1">
                                                    <div if.bind="message.xmlBody" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('container', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.container"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].container"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div show.bind="viewState.events[$parent.$index].messages[$index].container">
                                                            <ux-highlight language="xml">${message.xmlBody} </ux-highlight>
                                                        </div>
                                                    </div>
                                                    <div if.bind="message.xmlPayload" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('message', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.message"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].message"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div show.bind="viewState.events[$parent.$index].messages[$index].message">
                                                            <ux-highlight language="xml">${message.xmlPayload}</ux-highlight>
                                                        </div>
                                                    </div>
                                                    <div if.bind="message.fault" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('error', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.error"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].error"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div class="mt-2" show.bind="viewState.events[$parent.$index].messages[$index].error">
                                                            <div class="flex flex-col gap-2 overflow-x-auto rounded-lg bg-[#2d2d2d] p-3 text-xs text-[#ccc]">
                                                                <p>[${message.fault.body.fault.code.value}] ${message.fault.body.fault.reason.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div if.bind="message.notifications.length > 0" class="py-3">
                                                        <a
                                                            href="javascript:"
                                                            click.trigger="toggleEventMessage('notification', $parent.$index, $index)"
                                                            class="flex justify-between font-semibold hover:text-primary">
                                                            <span class="text-xs" t="translation:global.labels.notifications"></span>
                                                            <svg
                                                                if.bind="viewState.events[$parent.$index].messages[$index].notification"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                                            </svg>
                                                            <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                            </svg>
                                                        </a>
                                                        <div class="flex flex-col gap-1 mt-2 overflow-hidden" show.bind="viewState.events[$parent.$index].messages[$index].notification">
                                                            <div
                                                                repeat.for="notification of message.notifications"
                                                                class="flex flex-col gap-1 overflow-x-auto rounded-lg bg-[#2d2d2d] p-3 text-xs text-[#ccc]">
                                                                <p>
                                                                    <span>[${notification.code}] ${notification.description} </span>
                                                                    <span if.bind="notification.explanation">(${notification.explanation})</span>
                                                                </p>
                                                                <div if.bind="notification.bsn || notification.performance" class="flex flex-col">
                                                                    <div if.bind="notification.bsn" class="flex items-center justify-between">
                                                                        <p class="text-gray-400" t="translation:global.labels.citizens-service-number"></p>
                                                                        <p class="text-gray-300">${notification.bsn}</p>
                                                                    </div>
                                                                    <div if.bind="notification.performance" class="flex items-center justify-between">
                                                                        <p class="text-gray-400" t="translation:global.labels.performance"></p>
                                                                        <p class="text-gray-300">${notification.performance}</p>
                                                                    </div>
                                                                    <div if.bind="notification.elements && notification.elements.length > 0" class="flex flex-col mt-2">
                                                                        <p t="translation:global.labels.elements"></p>
                                                                        <div repeat.for="element of notification.elements" class="flex items-center justify-between">
                                                                            <p class="text-gray-400">${element.name}</p>
                                                                            <p class="text-gray-300">${element.value}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-8 pt-8">
                    <div class="flex justify-between pb-3 border-b border-gray-200">
                        <p class="text-xl font-medium text-gray-800" t="translation:partial-views.declaration-performances.labels.title"></p>
                    </div>
                    <div class="grid grid-cols-1 gap-5 mt-5">
                        <div class="flex flex-col flex-1 space-y-3">
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </div>
            </template>
            <div class="flex justify-end px-5 py-3 bg-white border-t border-gray-200 gap-x-2">
                <div class="flex justify-end flex-1 gap-x-2">
                    <template if.bind="performance.status === DeclarationPerformanceStatuses.Draft">
                        <div class="flex justify-end flex-1 gap-x-2">
                            <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                                <span class="pointer-events-none" t="global.buttons.cancel"></span>
                            </ux-button>
                            <ux-button
                                size="sm"
                                color="success"
                                disabled.bind="isLoading"
                                loading.bind="isLoading"
                                click.trigger="save()"
                                t="[text-when-loading]translation:global.labels.loading-states.default">
                                <span class="pointer-events-none" t="global.buttons.save"></span>
                            </ux-button>
                        </div>
                    </template>
                    <template if.bind="performance.status !== DeclarationPerformanceStatuses.Draft">
                        <div class="flex justify-end flex-1 gap-x-2">
                            <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                                <span class="pointer-events-none" t="global.buttons.close"></span>
                            </ux-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
