<template class="relative inline-block w-full text-left">
    <div ref="container" class="ng flex h-[38px] w-full">
        <div
            class="relative flex flex-1 min-w-0 transition duration-150 ease-in-out border ring-offset-1 focus:outline-none sm:text-sm sm:leading-5"
            ring-1.class="hasFocus || inputFocused"
            border-gray-300.class="valid"
            ring-gray-400.class="valid"
            border-red-500.class="!valid"
            ring-red-600.class="!valid"
            hover:cursor-not-allowed.class="disabled"
            bg-gray-100.class="disabled"
            cursor-default.class="!disabled"
            bg-white.class="!disabled"
            rounded-md.class="(rounded === 'full' && (!allowClear || (allowClear && isNotDefined(value)))) || disabled"
            rounded-r-md.class="(rounded === 'full' && (!allowClear || (allowClear && isNotDefined(value)))) || disabled"
            rounded-l-md.class="(rounded === 'left' && (allowClear || !allowClear)) || (rounded === 'full' && allowClear && isDefined(value) && !disabled)">
            <button
                ref="btn"
                focus.trigger="handleFocus()"
                blur.trigger="handleBlur()"
                click.trigger="toggleVisibility()"
                disabled.bind="disabled"
                class="relative flex items-center w-full min-w-0 py-2 pl-3 pr-10 text-left rounded-md cursor-default focus:outline-none sm:text-sm"
                cursor-default.class="!disabled"
                bg-white.class="!disabled"
                bg-gray-100.class="disabled"
                hover:cursor-not-allowed.class="disabled"
                type="button"
                aria-haspopup="true"
                aria-expanded="false">
                <span class="flex-1 block truncate">
                    <span if.bind="isDefined(value)" innerhtml.bind="html"></span>
                    <span if.bind="!isDefined(value)" class="text-gray-500">${placeholder}</span>
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <span if.bind="isLoading" class="flex items-center px-1">
                        <ux-spinner size="xxs"></ux-spinner>
                    </span>
                    <svg if.bind="!isLoading && valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-5 text-gray-400">
                        <path
                            fill-rule="evenodd"
                            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <svg if.bind="!valid" class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </span>
            </button>
        </div>
        <button
            if.bind="allowClear && isDefined(value) && !disabled"
            disabled.bind="disabled"
            click.trigger="handleClear()"
            class="-ml-px h-[38px] rounded-r-md border p-2 ring-offset-1 focus:z-20 focus:outline-none focus:ring-1"
            border-gray-300.class="valid"
            ring-gray-400.class="valid"
            border-red-500.class="!valid"
            ring-red-500.class="!valid"
            bg-gray-100.class="disabled"
            hover:cursor-not-allowed.class="disabled"
            bg-white.class="!disabled">
            <svg class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
        </button>
    </div>
    <div
        show.bind="isVisible"
        scroll-py-20.class="showFooter"
        bottom-[45px].class="placement === 'top'"
        ref="dropdown"
        class="absolute z-50 flex flex-col w-full mt-2 overflow-auto origin-top-right bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1">
        <div if.bind="noResults" class="block px-4 py-2 text-sm text-gray-700 rounded-lg" role="menuitem" tabindex="-1">
            <span>${placeholderNoresults}</span>
        </div>
        <div px-1.class="!noResults" p-1.class="!noResults" pb-1.class="showFooter" class="flex flex-col">
            <au-slot />
        </div>
        <div if.bind="showFooter" class="sticky bottom-0 z-10 bg-white border-t border-gray-300">
            <au-slot name="footer" />
        </div>
    </div>
</template>
