import { isNotDefined } from '@wecore/sdk-utilities';

export class GetValuesValueConverter {
    public toView(items: any[], properties: string, replyWithUndefined: boolean = false): any[] {
        if (isNotDefined(items) || items.empty()) return replyWithUndefined ? undefined : [];

        if (properties.includes(':')) {
            const [object, property] = properties.split(':');
            return items.map((x) => x[object][property]);
        } else return items.map((x) => x[properties]);
    }
}
