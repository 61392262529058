import { IPageState, isNotDefined, savePageState } from '@wecore/sdk-utilities';
import { bindable } from 'aurelia';

export class BxSchedulerTasks {
    @bindable() private pageState: IPageState;
    @bindable() hasScrolled: boolean = false;

    public bound(): void {
        if (isNotDefined(this.pageState.values.expandedTasks)) {
            this.pageState.values.expandedTasks = true;
            savePageState(this.pageState);
        }
    }

    public collapseOrExpand(): void {
        this.pageState.values.expandedTasks = !this.pageState.values.expandedTasks;
        savePageState(this.pageState);
    }
}
